import React, { Component } from "react";
import {
  Button,
  Statistic,
  Link,
  Spin,
  List,
  InputNumber,
  Notification,
  Empty,
} from "@arco-design/web-react";
import history from "common/utils/history";
import { IconStarFill } from "@arco-design/web-react/icon";
import {
  packageproduct,
  saascreate,
  saas_cancel,
  order_detail,
  saas_detail,
} from "api/apis";
import { getRgba, formatMoney } from "common/utils";
import PaymentAmount from "components/PaymentAmount";
import ConfirmOrder from "components/ConfirmOrder";
import { sourceCancelClass } from "api/cancel-request";
import Emptydata_img from "asserts/Emptydata_img.svg";
import "./index.scss";
// 封装组件
class Promotion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingbody: true,
      productstyle: JSON.parse(localStorage.getItem("productstyle")),
      visible: false,
      status: "",
      data: {},
      packageList: [],
      type: 0,
      payment: {},
      listorder: [],
      record: JSON.parse(localStorage.getItem("record")),
      order_sn: "",
      object: [],
      total_amount: 0,
      numList: {},
      timeindex: 3,
      timer: null,
      timerindex: null,
      Selectoptions: localStorage.getItem("Selectoptions")
        ? JSON.parse(localStorage.getItem("Selectoptions"))
        : [],
      model_capability: "",
      retry_time: 10,
      payment_title: "",
      oldpay_url: "",
    };
  }
  componentDidMount() {
    if (this.state.record) {
      this.state.model_capability = this.state.record?.model_capability
        ? this.state.record?.model_capability
        : this.state.record?.id;
    }
    this.getpackageproduct();
  }
  UNSAFE_componentDidUpdate() {}

  getpackageproduct() {
    let { model_capability } = this.state;
    packageproduct({
      model_capability: model_capability,
    }).then((res) => {
      if (res.code === 200) {
        let list = res.data;
        let obj = list.product;
        this.setState({
          data: list,
          packageList: obj,
          loadingbody: false,
        });
      }
    });
  }
  CalculatedAmount() {
    let { object } = this.state;
    console.log(object, "object");
    let total = 0;
    let obg = {};
    if (object.length > 0) {
      object.map((item, index) => {
        total = parseInt(total) + parseInt(item.price) * parseInt(item.num);
        obg[item.code] = item.num;
      });
    }
    this.setState({
      total_amount: total,
      numList: obg,
    });
  }
  onNumber(res, num) {
    let obj = {
      code: res.object_id,
      num: num ? num : 0,
      price: res.price,
    };
    let list = this.state.object;

    if (list.length > 0) {
      for (var index = 0; index < list.length; index++) {
        if (obj.code === list[index].code) {
          list[index].num = num;
          this.setState({
            object: list,
          });
          this.CalculatedAmount();
          return false;
        }
      }
      this.state.object.push(obj);
      this.CalculatedAmount();
    } else {
      this.state.object.push(obj);
    }
    this.CalculatedAmount();
  }
  settlement() {
    this.onsaascreate();
  }
  onsaascreate() {
    let { model_capability, object } = this.state;
    let obj = this.removeObjFromArrayByKeyValue(object, "price");

    saascreate({
      object: JSON.stringify(obj),
      capabilities: model_capability,
    }).then((res) => {
      if (res.code === 200) {
        let data = res.data;
        var oldpay_url = res.data.pay_url;
        var time = res.data.order_time;
        if (!time) {
          time = new Date().getTime();
        } else {
          localStorage.setItem("pay_time", time);
        }
        data.pay_url = `${data.pay_url}&time=${time}`;
        this.setState({
          type: 1,
          order_sn: data.order_sn,
          pay_amount: data.pay_amount,
          payment: data,
          oldpay_url: oldpay_url,
          status: "",
          payment_title: res.data.title,
        });
        this.getsaas_detail();
      } else {
        Notification.error({ content: res.msg });
      }
    });
  }
  // 自动返回
  settimeindex() {
    this.state.timerindex = setInterval(() => {
      let index = this.state.timeindex - 1;
      this.setState({
        timeindex: index,
      });
      if (index === 0) {
        this.oncleartimer();
        this.onback();
      }
    }, 1000);
  }
  oncleartimer() {
    sourceCancelClass();
    clearInterval(this.state.timer);
    clearInterval(this.state.timerindex);
  }
  onback() {
    history.push("/payment");
    history.go();
  }
  /**
   * 从数组中删除包含特定值的对象
   * @param array	目标数组
   * @param key 键 eg: 'userId'
   */
  removeObjFromArrayByKeyValue(array, key) {
    let list = [];
    for (var i = 0; i < array.length; i++) {
      delete array[i][key];
      if (array[i].num !== 0) {
        list.push(array[i]);
      }
    }
    return list;
  }
  iconsrc(icon) {
    return "url(" + icon + ")";
  }
  optionColor(color) {
    return color ? getRgba(color, "string", 0.06) : "#ffffff";
  }
  setcancel() {
    this.setState({
      visible: true,
    });
  }
  onCancel(val) {
    if (val === 0) {
      this.getsaas_cancel();
    } else {
      this.setState({
        visible: false,
      });
    }
  }
  getsaas_cancel() {
    let { payment } = this.state;
    saas_cancel({ order_sn: payment.order_sn }).then((res) => {
      if (res.code === 200) {
        Notification.success({ content: "取消成功!" });
        this.oncleartimer();
        this.onback();
        this.setState({
          visible: false,
        });
      } else {
        Notification.error({ content: res.msg });
      }
    });
  }
  // 订单状态查询
  getorder_query() {
    if (this.state.retry_time <= 0) {
      var time = new Date().getTime();
      if (localStorage.getItem("pay_time")) {
        time = Number(localStorage.getItem("pay_time")) + 10;
        localStorage.setItem("pay_time", time);
      }
      let payment = this.state.payment;
      payment.pay_url = `${this.state.oldpay_url}&time=${time}`;
      this.setState({
        payment: payment,
        retry_time: 10,
      });
    } else {
      this.setState({ retry_time: this.state.retry_time - 1 });
    }
    let { payment } = this.state;
    order_detail({ order_sn: payment.order_sn }).then((res) => {
      if (res.code === 200) {
        // 'WAIT_PAID 待支付','PAID已支付','FINISH已完成','CANCEL已取消'
        if (res.data.status !== "WAIT_PAID" && res.data.status !== "CANCEL") {
          this.setState({
            status: "success",
          });
          this.settimeindex();
        } else {
          this.setState({
            status: "",
          });
        }
      } else {
        this.setState({
          status: "error",
        });
        this.settimeindex();
      }
    });
  }
  onpayment() {
    this.setState({
      type: 2,
    });
    this.state.timer = setInterval(() => {
      this.getorder_query();
    }, 2000);
  }
  getsaas_detail() {
    saas_detail({ order_sn: this.state.order_sn }).then((res) => {
      if (res.code === 200) {
        this.setState({
          listorder: res.data.data,
          pay_amount: res.data.price_info,
        });
      } else {
        Notification.error({ content: res.msg });
      }
    });
  }
  render() {
    const {
      loadingbody,
      productstyle,
      payment,
      type,
      data,
      packageList,
      listorder,
      status,
      visible,
      order_sn,
      total_amount,
      object,
      numList,
      pay_amount,
      timeindex,
      Selectoptions,
      model_capability,
      payment_title,
      retry_time,
    } = this.state;
    return (
      <div className="promotion">
        {loadingbody ? (
          <Spin loading={loadingbody} dot className="loadingbody"></Spin>
        ) : (
          <>
            <div className="addpadding">
              <div className="Header-init">
                <div className="Header-title">升级资源包</div>
              </div>
            </div>
            {type === 2 ? (
              <div className="addpadding promotion-payment">
                <PaymentAmount
                  status={status}
                  timeindex={timeindex}
                  data={payment}
                  title={payment_title}
                  time={retry_time}
                  onback={this.onback.bind(this)}
                />
              </div>
            ) : (
              <>
                {type === 0 ? (
                  <>
                    <div className="addpadding">
                      <div
                        className="promotion-lable rowStart"
                        style={{
                          "--backgroundImage":
                            productstyle[data.info?.group]?.lablebgcolor,
                        }}
                      >
                        <img
                          alt=""
                          src={
                            Selectoptions[model_capability]
                              ? Selectoptions[model_capability].label_icon
                              : ""
                          }
                        />
                        <div className="AlibabaPuHuiTi-Medium promotion-title">
                          {data.info?.label}
                        </div>
                        <div className="promotion-surplus rowFlexStartEnd">
                          {data.config?.is_free ? (
                            <Statistic value="限时免费" groupSeparator />
                          ) : (
                            <>
                              <span className="surplus-lable">剩余可用量</span>
                              <Statistic
                                value={data.openedPackage?.free}
                                suffix={data.config?.unit}
                                groupSeparator
                              />
                            </>
                          )}
                        </div>
                      </div>
                      <div className="payment-explain">
                        <div className="payment-explain-lable AlibabaPuHuiTi-Medium">
                          <IconStarFill />
                          购买须知
                        </div>
                        <div>
                          1.资源包购买后，不支持退款，在有效期内可不受限制使用资源包权限，超出有效期未使用完的权益不支持叠加或冻结；
                          <br />
                          2.若叠加购买多个资源包，默认上一资源包用完后，自动启用新的资源包，有效期自购买日起，1年内有效。
                        </div>
                      </div>
                    </div>
                    <div className="promotion-list">
                      <List
                        grid={{
                          sm: 24,
                          md: 12,
                          lg: 8,
                          xl: 8,
                        }}
                        bordered={false}
                        dataSource={packageList}
                        noDataElement={
                          <Empty
                            imgSrc={Emptydata_img}
                            description={"暂无升级资源包"}
                          />
                        }
                        render={(item, index) => (
                          <List.Item key={index}>
                            <div
                              className="promotion-list-item"
                              style={{
                                "--backgroundImage":
                                  numList[item.object_id] > 0
                                    ? productstyle[data.info?.group]
                                        ?.gradienticoncolor
                                    : "#FFFFFF",
                                "--borderColor":
                                  productstyle[data.info?.group]?.color,
                              }}
                            >
                              <div className="rowStart">
                                <div className="promotion-item-icon columnCenter">
                                  <img
                                    src={
                                      Selectoptions[model_capability]
                                        ?.label_icon
                                    }
                                  />
                                </div>
                                <div className="promotion-item-sum">
                                  <div>升级资源数</div>
                                  <div>
                                    <Statistic
                                      value={item.num}
                                      suffix={data.config?.unit}
                                      groupSeparator
                                    />
                                    （{item.bak}）
                                  </div>
                                  <div>
                                    <Statistic
                                      value={parseInt(item.price)}
                                      prefix="¥"
                                      precision={2}
                                      groupSeparator
                                    />
                                  </div>
                                </div>
                                <div className="promotion-InputNumber">
                                  <InputNumber
                                    mode="button"
                                    defaultValue={0}
                                    min={0}
                                    max={item.num}
                                    style={{
                                      width: 117,
                                    }}
                                    onChange={this.onNumber.bind(this, item)}
                                    formatter={(value) =>
                                      `${value}`.replace(
                                        /B(?=(d{3})+(?!d))/g,
                                        ","
                                      )
                                    }
                                    parser={(value) => value.replace(/,/g, "")}
                                  />
                                </div>
                              </div>
                            </div>
                          </List.Item>
                        )}
                      />
                    </div>
                    <div className="promotion-bottom">
                      <div className="rowFlexEndEnd promotion-End">
                        <span className="AlibabaPuHuiTi-Medium ">总金额：</span>
                        <Statistic
                          value={total_amount}
                          prefix="¥"
                          precision={2}
                          groupSeparator
                        />
                        <Button
                          type="primary"
                          className="settlement"
                          onClick={this.settlement.bind(this)}
                          disabled={
                            packageList.length === 0 || total_amount === 0
                          }
                        >
                          结算
                        </Button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <ConfirmOrder
                      pay_amount={pay_amount}
                      orderlist={listorder}
                      order_sn={order_sn}
                      onpayment={this.onpayment.bind(this)}
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    );
  }
}
export default Promotion;
