import { shareLink } from 'api/apis'
import { Message, Notification } from '@arco-design/web-react'
import copy from 'copy-to-clipboard'

export function CreateLink (data) {
  if (!data.app_id) {
    data.app_id = data.id
  }

  const urlParams = new URL(window.location.href)
  const pathname = urlParams?.pathname
  let modelName = ''
  if (pathname.indexOf('myapp') > -1) {
    modelName = pathname.split('/')[2]
    modelName = modelName[0].toUpperCase() + modelName.slice(1)
  }

  shareLink({ id: data.app_id }).then((res) => {
    if (res.code === 200) {
      let value = `${res.data}&modelName=${modelName}`
      if (copy(value)) {
        Notification.success({
          title: '分享链接复制成功！',
          content: '粘贴链接即可查看体验分享应用',
        })
      } else {
        Notification.info({
          title: '分享链接生成失败！',
          content: '无法分享应用',
        })
      }
    } else if (res.code === 3003) {
      Notification.info({
        title: '分享链接生成失败！',
        content: '当前账户余额不足，无法分享应用',
      })
    } else {
      Message.error(res.msg)
    }
  })
}
