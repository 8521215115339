import React, { Component } from "react";
import {
  Table,
  Card,
  Typography,
  Button,
  Divider,
  Select,
  Input,
  Space,
  Empty,
  Spin,
  Checkbox,
  Modal,
  Notification,
  Tag,
  Link,
  Avatar,
} from "@arco-design/web-react";
import {
  IconPlus,
  IconClose,
  IconDelete,
  IconSettings,
  IconSearch,
  IconLoading,
  IconMinusCircleFill,
} from "@arco-design/web-react/icon";
// import { Link } from 'react-router-dom'
import {
  getgroupinfo,
  deleteuser,
  getuser,
  postcalljoin,
  outAuth,
  postuseroperation,
  getuserInfo,
} from "api/apis";
import { getCurrentDate } from "common/utils";
import history from "common/utils/history";
import Emptydata_img from "asserts/Emptydata_img.svg";
import "./index.scss";
const Option = Select.Option;

const options = [
  { lable: "超级管理员", value: "SUPER_ADMIN" },
  { lable: "管理员", value: "ADMIN" },
  { lable: "普通成员", value: "STAFF" },
];
const identityList = {
  PERSONAL: "个人用户",
  SUPER_ADMIN: "超级管理员",
  ADMIN: "管理员",
  STAFF: "普通成员",
};
function permissionTable(record) {
  let enable_del = record.enable_del;
  let ismy_id =
    localStorage.getItem("userinfo") &&
    JSON.parse(localStorage.getItem("userinfo")).id === record.show_id;
  if (
    identityList[record.group_permission] !== "普通成员" &&
    identityList[record.group_permission] !== "超级管理员" &&
    ismy_id
  ) {
    enable_del = true;
  } else if (
    (identityList[record.group_permission] === "普通成员" ||
      identityList[record.group_permission] === "超级管理员") &&
    ismy_id
  ) {
    enable_del = false;
  }
  return enable_del;
}
function iscolumnsmyid(col) {
  if (localStorage.getItem("userinfo")) {
    return col === JSON.parse(localStorage.getItem("userinfo")).id;
  } else {
    return false;
  }
}
class MemberManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingbody: true,
      data: [],
      pagination: {
        sizeCanChange: false,
        showTotal: true,
        total: 0,
        pageSize: 10,
        current: 1,
        pageSizeChangeResetCurrent: true,
        showJumper: false,
      },
      loading: false,
      no_data: false,
      keyword: "",
      checkbox: false,
      selectedRowKeys: [],
      type: "checkbox",
      checkAll: false,
      indeterminate: false,
      SelectcheckAll: false,
      visibledelete: false,
      confirmLoading: false,
      Invitevisible: false,
      recordId: "",
      visibloperatio1: false,
      visibloperatio2: false,
      visibloperatio3: false,
      mobile: "",
      mobilelist: null,
      resultMsg: "",
      userID: localStorage.getItem("userinfo")
        ? JSON.parse(localStorage.getItem("userinfo")).id
        : "",
      isinvitation:
        localStorage.getItem("userinfo") &&
        identityList[
          JSON.parse(localStorage.getItem("userinfo")).group_permission
        ] !== "超级管理员" &&
        identityList[
          JSON.parse(localStorage.getItem("userinfo")).group_permission
        ] !== "管理员",
      columns: [
        {
          title: "",
          dataIndex: "is_self",
          align: "center",
          width: 60,
          headerCellStyle: {
            textAlign: "center",
          },
          render: (col, record, index) => (
            <>
              {col ? (
                <Tag
                  color="#dfeffa"
                  style={{ color: "#578EB4", borderRadius: 4 }}
                  size="small"
                >
                  当前
                </Tag>
              ) : (
                ""
              )}
            </>
          ),
        },
        {
          title: "成员昵称",
          dataIndex: "nickname",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
        {
          title: "手机号",
          dataIndex: "mobile",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
        {
          title: "身份",
          dataIndex: "group_permission",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          render: (col, record, index) => (
            <>
              {permissionTable(record) ? (
                <Select
                  placeholder="请身份选择"
                  style={{ width: 154 }}
                  onChange={(value) => this.switchIdentity(value, record)}
                  defaultValue={col}
                  value={col}
                >
                  {options.map((option, index) => (
                    <Option
                      key={index}
                      value={option.value}
                      disabled={
                        identityList[option.value] === "超级管理员" &&
                        JSON.parse(localStorage.getItem("userinfo")) &&
                        identityList[
                          JSON.parse(localStorage.getItem("userinfo"))
                            .group_permission
                        ] !== "超级管理员"
                      }
                    >
                      {option.lable}
                    </Option>
                  ))}
                </Select>
              ) : (
                <div className="SelectIdentity"> {identityList[col]}</div>
              )}
            </>
          ),
        },
        {
          title: "应用",
          dataIndex: "app_count",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
        {
          title: "模型",
          dataIndex: "model_count",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
      ],
    };
  }
  componentDidMount() {
    localStorage.removeItem("props");
    this.getgroupinfo();
  }
  switchIdentity(value, record) {
    let JSON_data = { group_permission: value };
    let params = {
      id: record.show_id,
      // type: 'ascension',
      data: JSON.stringify(JSON_data),
    };
    postuseroperation(params).then((res) => {
      if (res.code === 200) {
        // Notification.success({ content: '权限修改成功！' })
        this.userinfo();
        // this.getgroupinfo(false)
      } else {
        Notification.error({ content: res.msg });
      }
    });
  }
  userinfo() {
    getuserInfo().then((res) => {
      if (res.code === 200) {
        let userinfodata = res.data;
        localStorage.setItem("userinfo", JSON.stringify(userinfodata));
        window.location.reload();
      }
    });
  }
  onChangemobile(value) {
    this.setState({
      mobile: value,
    });
  }
  onSearch(value) {
    this.setState({
      mobile: value,
      mobilelist: null,
    });
    let params = {
      type: "mobile",
      mobile: value,
    };
    let regmobile =
      /((\d{11})|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1})|(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1}))$)/;
    if (!regmobile.test(value)) {
      Notification.warning({ content: "请输入正确的手机号码！" });
      return false;
    }
    getuser(params).then((res) => {
      if (res.code === 200) {
        let list = res.data;
        this.setState({
          mobilelist: list,
          resultMsg: "",
        });
        //console.log(list, 'list')
      } else if (res.code === 204) {
        Notification.warning({
          content: "该用户未注册！请联系注册后再邀请成员",
        });
      } else {
        Notification.warning({ content: res.msg });
        // this.setState({
        //     resultMsg: res.msg
        // })
      }
    });
    //console.log('getuser', value)
  }
  goInvitevisible(mobile) {
    let params = {
      mobile: mobile,
    };
    postcalljoin(params).then((res) => {
      if (res.code === 200) {
        Notification.success({ content: "邀请成功！" });
        this.getgroupinfo();
      } else {
        Notification.error({ content: res.msg });
      }
    });
  }
  onClickgray() {
    document.getElementById("supportBtn").click();
  }
  getgroupinfo(loading) {
    let page = {
      per_page: this.state.pagination.pageSize,
      page: this.state.pagination.current,
      keyword: this.state.keyword,
    };
    this.setState({
      loading: loading,
    });
    getgroupinfo(page).then((res) => {
      if (res.code === 200) {
        let list = res.data;
        let pagination = this.state.pagination;
        pagination.total = list.count;
        this.setState({
          pagination: pagination,
          data: list.data,
          loading: false,
          loadingbody: false,
        });
        //console.log(list, 'list')
      }
    });
  }
  onChangeTable(pagination) {
    const { current, pageSize } = pagination;
    let setPagination = this.state.pagination;
    setPagination.current = current;
    setPagination.pageSize = pageSize;
    this.setState({
      loading: false,
      pagination: setPagination,
    });
    this.getgroupinfo();
  }
  onAdd() {
    // history.replace("/enginefactory/dataset/add")
    history.push({ pathname: "/enginefactory/dataset/add" }); //跳转但不记
    window.location.reload();
  }
  onPressEnter(value) {
    //console.log(value, 'value')
    let setPagination = this.state.pagination;
    setPagination.current = 1;
    this.setState({
      keyword: value,
      pagination: setPagination,
    });
    this.state.keyword = value;
    this.getgroupinfo(true);
  }
  setSelectedRowKeys(selectedRowKeys) {
    let selectkeys = [];
    this.state.data.forEach((i, index) => {
      //console.log(i.enable_del)
      if (i.enable_del) {
        selectkeys.push(i.show_id);
      }
    });
    this.setState({
      selectedRowKeys: selectedRowKeys,
      SelectcheckAll:
        selectedRowKeys.length === selectkeys.length ? true : false,
    });
  }
  onChangeAll(checked) {
    let selectkeys = [];
    if (checked) {
      this.state.data.forEach((i, index) => {
        //console.log(i.enable_del)
        if (i.enable_del) {
          selectkeys.push(i.show_id);
        }
      });
      this.setState({
        indeterminate: false,
        SelectcheckAll: true,
        selectedRowKeys: selectkeys,
      });
    } else {
      this.setState({
        indeterminate: false,
        SelectcheckAll: false,
        selectedRowKeys: selectkeys,
      });
    }
    //console.log(selectkeys, 'selectedRowKeys')
  }
  setVisibleoperation1(value) {
    this.setState({
      visibloperatio1: value,
    });
  }
  setVisibleoperation2(value) {
    this.setState({
      visibloperatio2: value,
    });
  }
  setVisibleoperation3(value) {
    this.setState({
      visibloperatio3: value,
    });
  }
  personaloperation(item, type) {
    this.setState({
      recordId: "",
    });
    if (type === 3) {
      this.setVisibleoperation3(true);
      this.setState({
        recordId: item.show_id,
      });
    } else if (type === 1) {
      this.setVisibleoperation1(true);
    } else if (type === 2) {
      this.setVisibleoperation2(true);
      this.setState({
        recordId: item.show_id,
      });
    }
  }
  setVisibledelete(value) {
    this.setState({
      visibledelete: value,
    });
  }

  deleteuser() {
    //console.log(this.state.selectedRowKeys, 'selectedRowKeysselectedRowKeys')
    let datas = { ids: JSON.stringify(this.state.selectedRowKeys) };
    this.setState({
      confirmLoading: true,
    });
    deleteuser(datas).then((res) => {
      if (res.code === 200) {
        let setPagination = this.state.pagination;
        setPagination.current = 1;
        this.setState({
          selectedRowKeys: [],
          SelectcheckAll: false,
          confirmLoading: false,
          setPagination: setPagination,
        });
        this.onClose();
        this.setVisibledelete(false);
        this.getgroupinfo(true);
      } else {
        this.setState({
          confirmLoading: false,
        });
        Notification.error({ content: res.msg });
      }
    });
  }
  onSettings() {
    this.setState({
      checkbox: true,
    });
  }
  onClose() {
    this.setState({
      checkbox: false,
      SelectcheckAll: false,
      selectedRowKeys: [],
    });
  }
  goQuitTeam() {
    this.delTeamMember();
  }
  delTeamMember() {
    let params = { ids: JSON.stringify([this.state.recordId]) };
    this.setState({
      confirmLoading: true,
    });
    deleteuser(params).then((res) => {
      if (res.code === 200) {
        if (this.state.visibloperatio2) {
          outAuth().then((res) => {
            if (res.code === 200) {
              localStorage.clear();
              window.location.reload();
            } else {
              Notification.error({ content: res.msg });
            }
          });
        }
        this.setState({
          recordId: "",
          confirmLoading: false,
        });
        this.setVisibleoperation2(false);
        this.setVisibleoperation3(false);
        this.getgroupinfo(false);
      } else {
        Notification.error({ content: res.msg });
        this.setState({
          confirmLoading: false,
        });
      }
    });
  }
  Invitevisible(value) {
    this.setState({
      Invitevisible: value,
      mobilelist: null,
      mobile: "",
      resultMsg: "",
    });
  }
  InviteMembers() {
    this.Invitevisible(false);
  }
  render() {
    const {
      resultMsg,
      visibloperatio1,
      visibloperatio2,
      visibloperatio3,
      Invitevisible,
      keyword,
      confirmLoading,
      visibledelete,
      indeterminate,
      SelectcheckAll,
      loadingbody,
      checkbox,
      checkAll,
      type,
      selectedRowKeys,
      loading,
      columns,
      data,
      pagination,
      no_data,
    } = this.state;
    return (
      <div className="membermanagement">
        <div className="Header-init">
          <div className="Header-title">成员管理</div>
        </div>
        {
          <div className="GroupTable-fixe">
            {checkbox ? (
              <>
                <div className="custom-checkAll">
                  <Checkbox
                    onChange={this.onChangeAll.bind(this)}
                    checked={SelectcheckAll}
                    indeterminate={indeterminate}
                  >
                    {SelectcheckAll ? "取消" : "全选"}
                  </Checkbox>
                </div>
                <Space size={"medium"}>
                  <Button
                    shape="circle"
                    className="custom-White-button"
                    icon={<IconDelete />}
                    disabled={selectedRowKeys.length === 0}
                    onClick={() => this.setVisibledelete(true)}
                  />
                  <Button
                    shape="circle"
                    className="custom-White-button"
                    icon={<IconClose />}
                    onClick={this.onClose.bind(this)}
                  />
                </Space>
              </>
            ) : (
              <>
                <Input.Search
                  style={{ width: 350 }}
                  placeholder="搜索成员"
                  className="GroupTable-Search"
                  onSearch={this.onPressEnter.bind(this)}
                />
                <div>
                  <Space size={"medium"}>
                    <Button
                      type="primary"
                      className="Header-init-btn"
                      style={{ borderRadius: 10 }}
                      disabled={this.state.isinvitation}
                      onClick={() => this.Invitevisible(true)}
                    >
                      邀请成员
                    </Button>
                    {/* <Button shape='circle' className="custom-White-button" icon={<IconSettings />} onClick={this.onSettings.bind(this)} /> */}
                  </Space>
                </div>
              </>
            )}
          </div>
        }
        {loadingbody ? (
          <Spin loading={loadingbody} dot className="loadingbody"></Spin>
        ) : (
          <>
            {pagination.total === 0 && !keyword && !loading ? (
              <div className="Empty-absolute">
                <Empty
                  className="Empty-none"
                  imgSrc={Emptydata_img}
                  description={
                    <Space direction="vertical">
                      <Typography
                        style={{
                          color: "#4e5969",
                          marginTop: 30,
                          fontSize: 16,
                        }}
                      >
                        暂时没有成员
                      </Typography>
                      <Typography style={{ color: "#4e5969", fontSize: 16 }}>
                        创建数据集，支撑更多模型训练
                      </Typography>
                    </Space>
                  }
                />
                <div className="Emptydata-btns">
                  <Button
                    type="primary"
                    className="Emptydata-btn"
                    onClick={() => this.Invitevisible(true)}
                  >
                    <IconPlus />
                    邀请成员
                  </Button>
                </div>
              </div>
            ) : (
              <div>
                {loading ? (
                  <Spin dot className="table-spin-loading" />
                ) : (
                  <>
                    {pagination.total === 0 ? (
                      <Empty
                        imgSrc={Emptydata_img}
                        description={"暂无相关成员"}
                        style={{ marginTop: 200 }}
                      />
                    ) : (
                      <Card
                        bordered={false}
                        hoverable
                        style={{ marginBottom: 20, borderRadius: 20 }}
                      >
                        <Table
                          virtualized
                          no_data={no_data}
                          border={{ wrapper: false, cell: false }}
                          noDataElement={
                            <Empty
                              imgSrc={Emptydata_img}
                              description={"暂无相关成员"}
                            />
                          }
                          loading={loading}
                          columns={columns.concat({
                            title: "操作",
                            dataIndex: "operation",
                            render: (col, record, index) => (
                              <>
                                {record.show_id !== this.state.userID ? (
                                  <Button
                                    type="outline"
                                    className="outline"
                                    disabled={!record.enable_del}
                                    onClick={this.personaloperation.bind(
                                      this,
                                      record,
                                      3
                                    )}
                                  >
                                    移除成员
                                  </Button>
                                ) : identityList[record.group_permission] ===
                                  "超级管理员" ? (
                                  <Button
                                    type="outline"
                                    className="outline"
                                    onClick={this.personaloperation.bind(
                                      this,
                                      record,
                                      1
                                    )}
                                  >
                                    解散团队
                                  </Button>
                                ) : (
                                  <Button
                                    type="outline"
                                    className="outline"
                                    onClick={this.personaloperation.bind(
                                      this,
                                      record,
                                      2
                                    )}
                                  >
                                    退出团队
                                  </Button>
                                )}
                              </>
                            ),
                            fixed: "right",
                            width: 150,
                            align: "center",
                          })}
                          data={data}
                          pagination={
                            pagination.total === 0 ? false : pagination
                          }
                          onChange={this.onChangeTable.bind(this)}
                          renderPagination={(paginationNode) => (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                marginTop: 10,
                              }}
                            >
                              {paginationNode}
                            </div>
                          )}
                          rowKey="show_id"
                          rowSelection={
                            checkbox && {
                              type,
                              selectedRowKeys,
                              checkAll: checkAll,
                              onChange: (selectedRowKeys, selectedRows) => {
                                //console.log('onChange:', selectedRowKeys, selectedRows);
                                this.setSelectedRowKeys(selectedRowKeys);
                              },
                              onSelect: (selected, record, selectedRows) => {
                                //console.log('onSelect:', selected, record, selectedRows)
                              },
                              checkboxProps: (record) => {
                                return {
                                  disabled: !record.enable_del,
                                };
                              },
                            }
                          }
                        />
                      </Card>
                    )}
                  </>
                )}
              </div>
            )}
          </>
        )}
        <Modal
          style={{
            width: 472,
          }}
          className="deleteModal"
          title="设置提示"
          visible={visibledelete}
          okText="确认"
          cancelText="取消"
          confirmLoading={confirmLoading}
          onOk={this.deleteuser.bind(this)}
          onCancel={() => this.setVisibledelete(false)}
        >
          <p>是否确认删除选中内容？</p>
        </Modal>
        <Modal
          style={{
            width: 502,
          }}
          className="Invite-members"
          title="邀请成员"
          visible={Invitevisible}
          // okText='邀请加入'
          // cancelText='取消'
          footer={null}
          onOk={this.InviteMembers.bind(this)}
          onCancel={() => this.Invitevisible(false)}
        >
          <div className="item-label">查找用户</div>
          <Input.Search
            allowClear
            placeholder="请输入对方手机号"
            value={this.state.mobile}
            maxLength="11"
            onChange={this.onChangemobile.bind(this)}
            onSearch={this.onSearch.bind(this)}
          />
          <div
            className={`result-list ${
              this.state.mobilelist || resultMsg ? "move_up" : "move_fadeOut"
            }`}
          >
            <div className="item-label">查找结果</div>
            {this.state.mobilelist ? (
              <div className="item-result rowSpaceBetween">
                <Space>
                  <Avatar size={34}>
                    <span
                      className="result-avatar-image"
                      style={{
                        backgroundImage:
                          "url(" + this.state.mobilelist.avatar + ")",
                      }}
                    ></span>
                  </Avatar>
                  <Typography.Text>
                    {this.state.mobilelist.nickname}
                  </Typography.Text>
                </Space>
                {this.state.mobilelist.in_group ? (
                  <span style={{ color: "#7789c2" }}>已邀请</span>
                ) : (
                  <Link
                    icon={<IconPlus />}
                    onClick={this.goInvitevisible.bind(
                      this,
                      this.state.mobilelist.mobile
                    )}
                  >
                    邀请
                  </Link>
                )}
              </div>
            ) : (
              <div className="resultMsg">{resultMsg}</div>
            )}
          </div>
        </Modal>
        <Modal
          style={{
            width: 472,
          }}
          className="deleteModal Quit-team"
          title="退出团队"
          visible={visibloperatio2}
          okText="确认"
          cancelText="取消"
          confirmLoading={confirmLoading}
          onOk={this.goQuitTeam.bind(this)}
          onCancel={() => this.setVisibleoperation2(false)}
        >
          <p>是否确定退出当前企业团队？</p>
        </Modal>
        <Modal
          style={{
            width: 472,
          }}
          className="deleteModal Quit-team"
          title="移除成员"
          visible={visibloperatio3}
          okText="确认"
          cancelText="取消"
          confirmLoading={confirmLoading}
          onOk={this.goQuitTeam.bind(this)}
          onCancel={() => this.setVisibleoperation3(false)}
        >
          <p>确定移除该成员吗？</p>
        </Modal>
        <Modal
          style={{
            width: 472,
          }}
          className="dissolution"
          title="解散团队"
          visible={visibloperatio1}
          footer={null}
          onCancel={() => this.setVisibleoperation1(false)}
        >
          <div style={{ textAlign: "center" }}>
            <div className="serviceQRcode"></div>
            <div>您好，解散团队涉及较多信息确认程序</div>
            <div>请联系官方客服处理</div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default MemberManagement;
