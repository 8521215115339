import React, { Component } from "react";
import { IconLeft } from "@arco-design/web-react/icon";
import {
  Space,
  Divider,
  Typography,
  Image,
  Button,
  Link,
  Empty,
  Avatar,
  Checkbox,
  Tag,
} from "@arco-design/web-react";
import { getCurrentDate, getCaption } from "common/utils";
import oneRelated_img from "asserts/oneRelated-img.svg";
import user_img from "asserts/user_img.svg";
import "./colorcard.scss";
function showhtml(htmlString) {
  var html = { __html: htmlString };
  return <div dangerouslySetInnerHTML={html}></div>;
}
class ColorCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      colorcard: props.colorcard,
      useravatar: props.useravatar,
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      colorcard: nextProps.colorcard,
      useravatar: nextProps.useravatar,
    });
  }
  render() {
    const { colorcard, useravatar } = this.state;
    return (
      <div className="colorcard">
        <div className={"colorcard-header "}>
          <div className="custom-card">
            <div className="custom-table">
              <div className="custom-th AlibabaPuHuiTi-Medium">工单编号</div>
              <div className="custom-th AlibabaPuHuiTi-Medium">状态</div>
              <div className="custom-th AlibabaPuHuiTi-Medium">提交时间</div>
            </div>
            <Divider style={{ margin: "3px 0" }} />
            <div className="custom-table">
              <div className="custom-td">{colorcard.show_id}</div>
              <div className="custom-td">
                <Tag
                  color={
                    colorcard.status === 1
                      ? "orangered"
                      : colorcard.status === 2
                      ? "blue"
                      : colorcard.status === 3
                      ? "green"
                      : "gray"
                  }
                >
                  {colorcard.status === 1
                    ? "待处理"
                    : colorcard.status === 2
                    ? "处理中"
                    : colorcard.status === 3
                    ? "已完结"
                    : "深度对接"}
                </Tag>
              </div>
              <div className="custom-td">
                {getCurrentDate("-", ":", colorcard.submit_time)}
              </div>
            </div>
          </div>
        </div>
        <div className="colorcard-body">
          <div className="colorcard-title">
            <div className="colorcard-title-fix">
              详情{" "}
              {colorcard.status === 2 ? (
                <Checkbox
                  className="checkboxEnd"
                  onChange={this.props.goEnd.bind(this)}
                >
                  问题已解决
                </Checkbox>
              ) : (
                ""
              )}
            </div>
            <Divider />
          </div>

          {colorcard.Reply && colorcard.Reply.length > 0 ? (
            colorcard.Reply.map((item, index) => {
              return (
                <div
                  className={`colorcard-li ${item.is_owner ? "mystry" : ""}`}
                  key={index}
                >
                  <div className="colorcard-li-header">
                    <div className="colorcard-li-header-title">
                      <Avatar>
                        <img
                          alt="avatar"
                          src={item.is_owner ? useravatar : user_img}
                        />
                      </Avatar>
                      {item.is_owner ? item.nickname : "专属客服"}：
                    </div>
                    <div className="colorcard-li-header-time">
                      {getCurrentDate("-", ":", item.submit_time)}
                    </div>
                  </div>
                  <div className="colorcard-li-body-cover">
                    {showhtml(item.content)}
                    <Space direction="vertical">
                      {item.attachment.map((src, index) =>
                        getCaption(src.fileType, 0, "/") === "image" ? (
                          <Image
                            key={index}
                            src={src.url}
                            title={src.fileName}
                            width={200}
                          />
                        ) : (
                          <Link key={index} href={src.url} target={"_blank"}>
                            {" "}
                            {src.fileName}{" "}
                          </Link>
                        )
                      )}
                    </Space>
                    <div className="setreplies">
                      {!(!item.is_owner && colorcard.status === 2) ? (
                        ""
                      ) : (
                        <Button
                          type="text"
                          onClick={this.props.setReplies.bind(this)}
                        >
                          回复
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div>
              <Empty
                className="Empty-none"
                imgSrc={oneRelated_img}
                description={
                  <Space direction="vertical">
                    <Typography
                      style={{ color: "#4e5969", marginTop: 30, fontSize: 16 }}
                    >
                      请耐心等候
                    </Typography>
                    <Typography style={{ color: "#4e5969", fontSize: 16 }}>
                      官方正在排查您所反馈的问题，后续请留意工单处理状态
                    </Typography>
                  </Space>
                }
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ColorCard;
