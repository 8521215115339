import React from 'react'
import '../../common/style/myapp/myapp.scss'
import shareicon1 from 'asserts/shareicon1.png'
import shareicon2 from 'asserts/shareicon2.png'
import shareicon3 from 'asserts/shareicon3.png'
import shareicon4 from 'asserts/shareicon4.png'
import shareicon5 from 'asserts/shareicon5.png'
import shareline1 from 'asserts/shareline3.png'
import shareline2 from 'asserts/shareline1.png'
import shareline3 from 'asserts/shareline2.png'

import { getUrlToken } from '../../common/utils/index'
class shareBottom extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modeName: getUrlToken('modelName'),
      tipsArr: [
        {
          title: '选择/上传数据',
          tips: 'excel模板式数据',
          icon: shareicon2,
          lineIcon: shareline1,
        },
        {
          title: '自动建模',
          tips: 'AI模型的自训练与深度学习',
          icon: shareicon3,
          lineIcon: shareline2,
        },
        {
          title: '体验应用',
          tips: '在线web应用式体验',
          icon: shareicon4,
          lineIcon: shareline3,
        },
      ],
    }
  }

  goHome = () => {
    let name = this.state.modeName
    name = name ? name[0].toLowerCase() + name.slice(1) : ''
    if (!window.location.origin) {
      window.location.origin =
        window.location.protocol +
        '//' +
        window.location.hostname +
        (window.location.port ? ':' + window.location.port : '')
    }
    // if (name) {
    //   window.open(`${window.location.origin}/myapp/${name}`, '_blank')
    // } else {
    //   window.open(`${window.location.origin}`, '_blank')
    // }
    window.open('https://www.yoo-ai.com', '_blank')

  }

  render () {
    const { tipsArr } = this.state

    return (
      <div className="share-bottom" onClick={this.goHome}>
        <div className="bottom-content">
          <div className="bg-bc1"></div>
          <div className="bg-bc2"></div>
          <div className="bg-bc3"></div>
          <div className="content-box">
            <div className="bottom-title">
              韦尼克创作平台，只需3步轻松创建属于你的智能应用
            </div>
            <div className="content-box-flex content-box-flex-c ">
              {tipsArr.map((item, index) => {
                return (
                  <div className="content-box-item" key={index}>
                    <div className="content-box-flex">
                      <img src={item.icon} className="bottom-icon" alt="" />
                      <div>
                        <div className="item-title">{item.title}</div>
                        <div className="item-tips">{item.tips}</div>
                      </div>
                      {index > 1 ? (
                        ''
                      ) : (
                        <img src={shareicon5} className="bottom-arrow" alt="" />
                      )}
                    </div>
                    <div className="content-box-flex content-box-index">
                      <span>{index + 1}</span>
                      <img src={item.lineIcon} className="" alt="" />
                    </div>
                  </div>
                )
              })}
              <img src={shareicon1} className="content-box-img" alt="" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default shareBottom
