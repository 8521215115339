import React, { Component } from "react";
import {
  Typography,
  Modal,
  Button,
  List,
  Space,
  Tabs,
  Divider,
  Spin,
} from "@arco-design/web-react";
import model_img from "asserts/model_img.png";
import "./index.scss";
const TabPane = Tabs.TabPane;
class ModelPopu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Selectoptions: props.Selectoptions,
      visible: props.visible,
      activeTab: props.activeTab,
      listoff: props.listoff,
      officialList: props.officialList,
      TablLoading: props.TablLoading,
      TabscrollLoading: props.TabscrollLoading,
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      visible: nextProps.visible,
      TablLoading: nextProps.TablLoading,
      TabscrollLoading: nextProps.TabscrollLoading,
      activeTab: nextProps.activeTab,
      listoff: nextProps.listoff,
      officialList: nextProps.officialList,
      Selectoptions: nextProps.Selectoptions,
    });
  }

  onRadioGroup(value) {
    //console.log(value, this.state.GroupValue, 'onRadioGroup')
    this.setState({
      model_capability: value,
    });
  }
  render() {
    const {
      Selectoptions,
      visible,
      activeTab,
      listoff,
      officialList,
      TablLoading,
      TabscrollLoading,
    } = this.state;
    return (
      <Modal
        className="creative-model"
        title="创作模型"
        visible={visible}
        footer={null}
        onCancel={() => {
          this.props.setVisible(false);
        }}
      >
        <Tabs
          defaultActiveTab={activeTab}
          activeTab={activeTab}
          onClickTab={this.props.onClickTab.bind(this)}
        >
          {listoff.map((item, index) => {
            return (
              <TabPane
                key={index}
                title={
                  <span>
                    <img src={item.icon} alt="" /> {item.title}
                  </span>
                }
              >
                {TablLoading && officialList.length === 0 ? (
                  <Spin dot className="Spin-dot" />
                ) : (
                  <>
                    <List
                      grid={{
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                      }}
                      style={{ maxHeight: 550 }}
                      loading={TablLoading}
                      scrollLoading={TabscrollLoading}
                      onReachBottom={(currentPage) =>
                        this.props.fetchData(currentPage)
                      }
                      dataSource={officialList}
                      bordered={false}
                      render={(item, index) => (
                        <List.Item
                          key={index}
                          onClick={this.props.goOffdetails.bind(this, item)}
                        >
                          {
                            <div className="ApplicationList-item grid-item">
                              <div className="columnCenterStart apply-content">
                                <div className="model-content">
                                  <div className="rowStart label-name AlibabaPuHuiTi-Medium">
                                    <span className="dispose_icon"></span>
                                    {item.name}
                                  </div>
                                  <div
                                    className="ellipasedom model-describe"
                                    title={item.model_desc}
                                  >
                                    {item.model_desc}
                                  </div>
                                  <img
                                    src={
                                      item.model_icon
                                        ? item.model_icon
                                        : model_img
                                    }
                                    alt=""
                                    className="model_img"
                                  />
                                </div>
                              </div>
                            </div>
                          }
                        </List.Item>
                      )}
                    />
                  </>
                )}
              </TabPane>
            );
          })}
        </Tabs>
      </Modal>
    );
  }
}

export default ModelPopu;
