// 项目history.js 文件
import { createBrowserHistory } from 'history'
// const env = process.env.NODE_ENV
let options = {}
// if (env === 'production') {
//   options.basename = '/admin'
// }

setTimeout(() => {
  const urlParams = new URL(window.location.href)
  const pathname = urlParams?.pathname
  let replaceList = [
    '/myapp/addmyapp',
    '/enginefactory/model/add',
    '/enginefactory/model/management/add',
    '/enginefactory/dataset/add',
  ]
  let HistoryList = localStorage.getItem('HistoryList')
    ? JSON.parse(localStorage.getItem('HistoryList'))
    : []
  if (HistoryList.length > 0) {
    //console.log(document.title, 'document.title')
    if (pathname === HistoryList[HistoryList.length - 1].url) {
      // 相同相邻路径不记录
    } else if (replaceList.includes(pathname)) {
      if (
        (HistoryList.length > 1 &&
          HistoryList[HistoryList.length - 2].url !==
            HistoryList[HistoryList.length - 1].url) ||
        HistoryList.length === 1
      ) {
        if (pathname !== '/shareMyapp') {
          let obj = {
            title: localStorage.getItem('title'),
            url: HistoryList[HistoryList.length - 1].url,
            record: localStorage.getItem('record'),
            props: localStorage.getItem('props'),
          }
          HistoryList.push(obj)
        }
        localStorage.setItem('HistoryList', JSON.stringify(HistoryList))
      } else {
        // 相同相邻路径不记录
        // let obj = {
        //     title: HistoryList[HistoryList.length - 1].title,
        //     url: HistoryList[HistoryList.length - 1].url,
        //     record: localStorage.getItem('record'),
        //     props: localStorage.getItem('props')
        // }
        // HistoryList.push(obj)
        // localStorage.setItem('HistoryList', JSON.stringify(HistoryList))
      }
    } else {
      if (pathname !== '/shareMyapp') {
        let obj = {
          title: localStorage.getItem('title'),
          url: pathname,
          record: localStorage.getItem('record'),
          props: localStorage.getItem('props'),
        }
        HistoryList.push(obj)
      }
      localStorage.setItem('HistoryList', JSON.stringify(HistoryList))
    }
  } else {
    if (pathname !== '/shareMyapp') {
      let obj = {
        title: localStorage.getItem('title')
          ? localStorage.getItem('title')
          : '概览',
        url: pathname,
        record: '',
        props: '',
      }
      HistoryList.push(obj)
    }

    localStorage.setItem('HistoryList', JSON.stringify(HistoryList))
  }
  //console.log('历史路由记录')
  //console.log(pathname, ':pathname,HistoryList:', HistoryList)
}, 0)
setTimeout(() => {
  let Menu = JSON.parse(localStorage.getItem('Menu'))
  if (Menu) {
    const urlParams = new URL(window.location.href)
    const pathname = urlParams?.pathname
    // 在组件挂载后（插入 DOM 树中）立即调用
    Menu.current = pathname
    localStorage.setItem('Menu', JSON.stringify(Menu))
    //console.log(Menu, 'pathname', window.location.hash)
  }
}, 1000)

export default createBrowserHistory(options)
