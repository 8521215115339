import React, { Component } from 'react'
import JQ from 'jquery'
import SiteUrl from 'envconfig/config'
import {
  Layout,
  Typography,
  Button,
  Space,
  Empty,
  Notification,
  Spin,
  Modal,
  Select,
  Input,
  Divider,
  Upload,
  Progress,
} from '@arco-design/web-react'
import Emptydata_img from 'asserts/Emptydata_img.svg'
import Generating_img from 'asserts/Generating_img.gif'
import errorIcon from 'asserts/error_icon.svg'
import {
  getApiInfo,
  getRequest,
  putapp,
  putmodelversion,
  check_resource,
} from 'api/apis'
import { CreateLink } from 'common/utils/business'
import history from 'common/utils/history'
import { debounce, getRandomArrayElements, b2ValueUnit } from 'common/utils'
import UpgradeTips from 'components/UpgradeTips'
import './image2text.scss'
import {
  IconDownload,
  IconPlus,
  IconSearch,
  IconDelete,
  IconEdit,
} from '@arco-design/web-react/icon'
import upload from 'api/uploads'
const Sider = Layout.Sider
const Content = Layout.Content
const Option = Select.Option
const TextArea = Input.TextArea
const jobsArray = [
  '财务经理',
  '系统工程师',
  '硬件维护工程师',
  '机器学习工程师',
  '运维开发工程师',
  '深度学习工程师',
  '游戏开发工程师',
  '网络工程师',
  '网络信息安全工程师',
  '前台',
  'IOS开发工程师',
  '会计师',
  '安全测试工程师',
  'Javascript开发工程师',
  'IT支持工程师',
  '游戏测试工程师',
  '游戏运营经理',
  '审计专员',
  '税务主管',
  '移动开发工程师',
  '秘书',
  '审计助理',
  '税务专员',
  '软件测试工程师',
  'Node.会计助理',
  '行政主管',
  '财务主管',
  '嵌入式软件开发工程师',
  '区域销售助理',
  '交互设计师',
  '会计主管',
  'HTML5开发工程师',
  '数据分析师',
  '审计经理',
  '客户经理',
  '会计经理',
  '电商运营经理',
  '开发工程师',
  'Android开发工程师',
  '运营主管',
  'Python开发工程师',
  '财务助理',
  '新媒体运营经理',
  '平面设计师',
  '美工',
  '行政经理',
  '数据挖掘工程师',
  '算法工程师',
  '产品专员',
  '审计主管',
  '产品设计',
  '会计',
  '税务助理',
  '产品运营经理',
  '行政助理',
  '视觉设计师',
  '数据开发工程师',
  '全栈开发工程师',
  '行政专员',
  '性能测试工程师',
  '会计文员',
  '税务经理',
  '测试开发工程师',
  'UI设计师',
  'C开发工程师',
  '信息技术专员工程师',
  'ERP技术开发工程师',
  'WEB前端开发工程师',
  '用户运营经理',
  '出纳员',
  '内容运营经理',
  '网站运营经理',
  '后勤',
  'GUI设计师',
  '移动前端开发工程师',
  '移动端测试工程师',
  '产品助理',
]

// 封装组件
class Image2Text extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Selectoptions: JSON.parse(localStorage.getItem('Selectoptions')),
      no_data: false,
      selectValue: '产品运营经理',
      versions: [],
      ContinuationLoading: true,
      record: JSON.parse(localStorage.getItem('record')),
      apiInfo: {
        model_id: '',
      },
      jobDesc: null,
      jobDescString: '',
      jobs: getRandomArrayElements(jobsArray, 5),
      requestMsg: '',
      requestMsgText: '您还未输入关键信息',
      visible: false,
      loading: false,
      loadingCount: 12,
      loadingIndex: 0,
      loadingImages: [],
      hrIframeUrl: SiteUrl.styleUrl + '/iframeHr.html?v=0412-7',
      isListenIframeMessage: false,
      currentClick: 0,
      scale: 0.144,
      isBreak: false,
      waterFall: [],
      optionVlue: '',
      input_describe: '',
      visibleUpgrade: false,
      upgradetips: null,
      action: 'analyze',
      graphic: '',
      requesBtnText: '',
      fileList: [
        {
          uid: '-1',
          name: 'logo.png',
          url: 'https://image.yoojober.com/share/yoo_logo.png',
        },
      ],
      fileListcode: [
        {
          uid: '-1',
          name: 'qrcode.png',
          url: 'https://image.yoojober.com/share/yoo_qrcode.png',
        },
      ],
      logofile: {
        url: 'https://image.yoojober.com/share/yoo_logo.png',
        percent: 100,
      },
      codefile: {
        percent: 100,
        url: 'https://image.yoojober.com/share/yoo_qrcode.png',
      },
      cs: 'arco-upload-list-item',
      cscode: 'arco-upload-list-item',
      logoref: null,
      userinfo: localStorage.getItem('userinfo')
        ? JSON.parse(localStorage.getItem('userinfo'))
        : null,
      graphicWidth: 164,
      identity: JSON.parse(localStorage.getItem('userinfo'))
        ? JSON.parse(localStorage.getItem('userinfo')).group_permission
        : '',
    }
    this.isputapp = debounce(this.isputapp, 1000)
  }
  componentDidMount() {
    this.getApiInfo()
    window.addEventListener('resize', this.handleResize)
    this.setState({
      cs: `arco-upload-list-item${
        this.state.logofile && this.state.logofile.status === 'error'
          ? ' is-error'
          : ''
      }`,
      cscode: `arco-upload-list-item${
        this.state.codefile && this.state.codefile.status === 'error'
          ? ' is-error'
          : ''
      }`,
    })
  }
  componentWillUnmount() {
    localStorage.removeItem('props')
    window.removeEventListener('resize', this.handleResize)
  }
  handleResize = () => {
    var winHeight = 0
    if (window.innerHeight) {
      winHeight = window.innerHeight
    } else if (document.body && document.body.clientHeight) {
      winHeight = document.body.clientHeight
    }
    if (document.documentElement && document.documentElement.clientHeight) {
      winHeight = document.documentElement.clientHeight
    }
    let layoutHeaders_h = document.getElementById('layoutHeaders').clientHeight
    if (layoutHeaders_h > winHeight) {
      layoutHeaders_h = 64
    }
    let height =
      winHeight -
      layoutHeaders_h -
      document.getElementById('renderHeaders').clientHeight -
      60
    document.getElementById('layout_id').style.height = height + 'px'
    let height2 =
      height - document.getElementById('result_title').clientHeight - 27
    document.getElementById('continuation').style.height = height2 - 30 + 'px'
    let jobheight = document.getElementById('layout_id').clientHeight
    if (this.state.graphic === 'posters') {
      jobheight =
        document.getElementById('layout_id').clientHeight -
        document.getElementById('selectinput').clientHeight -
        document.getElementById('start_generation').clientHeight
      document.getElementById('jobdesc').style.height = jobheight - 70 + 'px'
    }
  }

  getApiInfo() {
    let params = {
      app_id: this.state.record.id,
    }
    getApiInfo(params).then((res) => {
      if (res.code === 200) {
        let list = res.data
        if (res.data.model_params) {
          history.push('/myapp/enteringGinseng')
          history.go()
        } else {
          let iframe = this.state.hrIframeUrl
          let input_describe = ''
          let requesBtnText = ''
          let jobDesc = ''
          const userinfo = this.state.userinfo
          if (list.graphic === 'posters') {
            //海报
            iframe = SiteUrl.styleUrl + '/iframeHr.html?v=0412-7'
            input_describe = '输入或搜索生成招聘海报的岗位'
            requesBtnText = '生成海报'
            jobDesc = [
              {
                require: [
                  '1.文案功底过硬，无论是一个banner的文案，还是一个活动页面的展示描述，都能有较好的呈现方式和文案思路',
                  '2.2年以上互联网产品运营经验，有成功案例优先，有公众号、小程序运营经验者优先',
                  '3.可与ui、开发沟通无障碍，能够独立完成产品运营、活动运营的方案、需求的输出，能够独立跟踪ui、开发完成运营需求的上线，并能在上线后持续跟踪数据效果，提出迭代建议和方案',
                  '4.有责任心，逻辑思维能力强，抗压能力较好',
                  '5.数据敏感度高，数据分析能力强，能够准确的通过数据分析找到产品功能、运营页面中存在的问题，并能结合数据找出解决的方法，会sql优先',
                ],
                desc: [
                  '1.个模块的运营工作，能独立规划所负责的运营模块，完成追踪上线并不断优化',
                  '2.负责水滴公司内、外部渠道的场景拉新及转化，高效利用渠道流量达成业务增长，利用多维度的ab测试等方法，找到最佳的水滴保引流转化方式，不断提升运营位以及落地页面的转化效率',
                  '3.负责水滴保线上业务的增长运营工作，包括但不限于拉新、转化、活动、复购等模块的运营工作，能够负责其中',
                  '4.与公司内部流量部门保持较好的沟通和关系，不断挖掘内部流量渠道适合的转化位置和场景，不断的增加流量开口',
                  '5.负责水滴保用户画像的建立，并能根据不同用户情况，规划整理针对不同用户的精细化运营策略和方案。',
                ],
                job: '产品运营经理',
                welfare:
                  '补充医疗保险、定期体检、年终奖、股票期权、带薪年假、员工旅游、节日福利、住房补贴',
                address: '广东珠海唐家湾镇创意云端1栋605',
                email: 'hr@yoojober.com',
                phone: '18578205080',
                qrcode: 'https://image.yoojober.com/share/yoo_qrcode.png',
                logo: 'https://image.yoojober.com/share/yoo_logo.png',
                keyword: '',
                salary: '30-60k·15薪',
              },
              {
                require: [
                  '1.3年以上产品运营经理工作经验,具备一定的项目管理能力可以负责产品运营管理',
                  '2.熟悉医疗行业,具备医疗平台类产品相关经验',
                  '3.能够快速捕获用户需求,定位产品目标,并独立产出规范的产品原型或产品文档',
                  '4.熟练掌握产品运营方法体系,具备较强的运营专业能力,包括但不限于运营策划能力、产品包装能力、数据分析能力',
                  '5.擅长分析行业趋势紧跟行业动态具备敏锐的产品见解',
                ],
                desc: [
                  '1.能够负责产品运营,熟悉处方流转环节,对医药行业有了解,具有较强的运营能力',
                  '2.负责互联网医疗的相关产品市场调研、需求分析、产品功能设计',
                  '3.独立完成产品推广、品牌和市场宣传工作',
                  '4.负责产品生命周期的各个环节,能够独立完成产品的策划、原型、产品流程和交互设计,并完成需求文档的撰写',
                ],
                job: '产品运营经理',
                welfare:
                  '五险一金、周末双休、14薪、弹性上班、免费午餐、年度旅游、年度体检、假日福利',
                address: '广东珠海唐家湾镇创意云端1栋605',
                email: 'hr@yoojober.com',
                phone: '18578205080',
                qrcode: 'https://image.yoojober.com/share/yoo_qrcode.png',
                logo: 'https://image.yoojober.com/share/yoo_logo.png',
                keyword: '',
                salary: '30-60k·15薪',
              },
            ]
          } else if (list.graphic === 'badges') {
            //工牌
            iframe = SiteUrl.styleUrl + '/iframeWC.html?v=0412-7'
            // input_describe = "输入生成企业工牌的相关信息";
            requesBtnText = '生成工牌'
            jobDesc = {
              name: '尤小优',
              job: '技术总监',
              maxim: '工匠精神的证明，隐藏在每一个值得被放大欣赏的细节中。',
              logo: 'https://image.yoojober.com/share/yoo_logo.png',
              website: 'www.yoo-ai.com',
              id: 'ID:123456',
              profile:
                'http://image.yoojober.com/user/avatar/resume-avatar.jpg',
              qrcode: 'https://image.yoojober.com/share/yoo_qrcode.png',
              slogan: 'AI让创作更简单',
              phone: '139 3435 4593',
              email: 'youxiaoyou@yoojober.com',
              address: '广东省珠海市高新区港湾一号创意云端A栋',
              company: '',
            }
          } else if (list.graphic === 'cards') {
            //名片
            iframe = SiteUrl.styleUrl + '/iframeBC.html?v=0412-7'
            // input_describe = "输入生成企业名片的相关信息";
            requesBtnText = '生成名片'
            jobDesc = {
              name: '尤小优',
              job: '技术总监',
              maxim: '工匠精神的证明，隐藏在每一个值得被放大欣赏的细节中。',
              logo: 'https://image.yoojober.com/share/yoo_logo.png',
              website: 'www.yoo-ai.com',
              id: 'ID:123456',
              profile:
                'http://image.yoojober.com/user/avatar/resume-avatar.jpg',
              qrcode: 'https://image.yoojober.com/share/yoo_qrcode.png',
              slogan: 'AI让创作更简单',
              phone: '139 3435 4593',
              email: 'youxiaoyou@yoojober.com',
              address: '广东省珠海市高新区港湾一号创意云端A栋',
              company: '',
            }
          }
          if (
            userinfo &&
            (list.graphic === 'cards' || list.graphic === 'badges')
          ) {
            jobDesc.name = `${userinfo.nickname}`
            jobDesc.company = `${
              userinfo.enterprise_name
                ? userinfo.enterprise_name
                : userinfo.company
                ? userinfo.company
                : jobDesc.company
            }`
            jobDesc.phone = `${userinfo.mobile}`
            jobDesc.email = `${userinfo.email ? userinfo.email : jobDesc.email}`
            if (userinfo.enterprise_logo) {
              jobDesc.logo = `${userinfo.enterprise_logo}`
            }
            if (userinfo.avatar) {
              jobDesc.profile = `${userinfo.avatar}`
            }
          }

          this.setState({
            apiInfo: list,
            versions: list.versions,
            graphic: list.graphic,
            hrIframeUrl: iframe,
            input_describe: list.input_describe
              ? list.input_describe
              : input_describe,
            requesBtnText: requesBtnText,
            jobDesc: jobDesc,
          })
          this.inputValue(this.state.jobDesc)
          if (list.versions.length > 0) {
            this.setState({
              optionVlue: list.versions[0].id,
            })
            this.putmodelversion(list.versions[0].id)
          }
          if (list.type === 0) {
            setTimeout(() => {
              this.setState({
                loadingIndex: 0,
                loadingImages: [],
                loading: true,
                ContinuationLoading: false,
                requestMsg: '',
              })
              this.createIframeStart()
            }, 500)
          } else {
            this.setState({
              loadingIndex: 0,
              loadingImages: [],
              loading: false,
              ContinuationLoading: false,
              requestMsg: '',
            })
          }
          setTimeout(() => {
            this.handleResize()
            this.graphicWidth()
          }, 300)
        }
      } else {
      }
    })
  }
  putmodelversion(optionVlue) {
    let params = {
      id: optionVlue,
    }
    putmodelversion(params).then((res) => {
      if (res.code === 200) {
        let list = res.data
      } else {
      }
    })
  }
  onsetPosition(value) {
    //console.log(value, "value");
    this.setState({
      optionVlue: value,
    })
    this.putmodelversion(value)
    this.isPhoneLegal()
  }
  onSearch(value) {
    this.setState({
      jobs: value ? jobsArray : getRandomArrayElements(jobsArray, 5),
    })
  }
  onChange(value) {
    this.setState({
      selectValue: value,
      jobs: getRandomArrayElements(jobsArray, 5),
    })
    setTimeout(() => {
      this.setRequest('write')
    }, 300)
  }
  isPhoneLegal = () => {
    this.setRequest('analyze')
    return null
  }
  shuffle(array) {
    let m = array.length,
      t,
      i
    while (m) {
      i = Math.floor(Math.random() * m--)
      t = array[m]
      array[m] = array[i]
      array[i] = t
    }
    return array
  }
  createIframeContent(iframe, clientId) {
    iframe.contentWindow.postMessage(
      {
        type: 'show',
        resume: this.state.jobDesc,
        styles: '',
        base64: 1,
        clientId: clientId,
      },
      '*'
    )
  }
  createIframeEnd() {
    this.setState({ loadingIndex: this.state.loadingIndex + 1 })

    let $listContainer = JQ('#Continuation-list')

    let iframe = document.createElement('iframe')

    iframe.src = this.state.hrIframeUrl
    iframe.style.transform =
      'scale(' + this.state.scale + ') translate3d(0,0,0) translateZ(0)'

    if (iframe.attachEvent) {
      iframe.attachEvent('onload', () => {
        this.createIframeContent(iframe, this.state.loadingIndex)
      })
    } else {
      iframe.onload = () => {
        this.createIframeContent(iframe, this.state.loadingIndex)
      }
    }

    $listContainer
      .find('.Continuation-item')
      .eq(this.state.loadingIndex - 1)
      .append(iframe)
  }
  createIframeStart() {
    if (!this.state.isListenIframeMessage) {
      this.setState({ isListenIframeMessage: true })
      window.addEventListener(
        'message',
        (event) => {
          if (
            event.data.data &&
            event.data.data.clientId &&
            !this.state.isBreak
          ) {
            const index = event.data.data.clientId - 1
            this.state.loadingImages[index] = event.data.data.base64
            const $container = JQ('#Continuation-list')
            let $item = $container.find('.Continuation-item').eq(index)

            let currentHeight = event.data.data.totalHeight * this.state.scale
            if (this.state.graphic === 'posters') {
              currentHeight = event.data.data.totalHeight * this.state.scale
              $item.height(currentHeight)
              $item.children('iframe').height(event.data.data.totalHeight)
            } else {
              currentHeight =
                (event.data.data.totalHeight / 2) * this.state.scale
              $item.height(currentHeight)
              $item.children('iframe').height(event.data.data.totalHeight / 2)
            }
            const [oneWidth, marginRight, marginBottom] = [
              this.state.graphicWidth,
              12,
              25,
            ]
            const containerW = $container.width()
            const colsNum = Math.floor(containerW / (oneWidth + marginRight))
            const marginRight2 = Math.floor(
              (containerW - colsNum * (oneWidth + marginRight)) / colsNum
            )
            const marginRight3 = marginRight + marginRight2

            const index2 = index % colsNum
            let css = {
              top: 0,
              left: index2 * (oneWidth + marginRight3),
              opacity: 1,
            }

            if (index >= colsNum) {
              css.top = this.state.waterFall[index - colsNum] + marginBottom
            }

            this.state.waterFall[index] = css.top + currentHeight

            if ($container.height() < this.state.waterFall[index]) {
              $container.height(this.state.waterFall[index])
            }

            $item.css(css)
            // console.log(
            //   this.state.loadingIndex,
            //   this.state.loadingCount,
            //   this.state.loadingIndex % this.state.loadingCount
            // );
            if (
              this.state.loadingIndex > 0 &&
              this.state.loadingIndex % this.state.loadingCount === 0
            ) {
              this.setState({
                loading: false,
              })
            } else {
              this.createIframeEnd()
            }
          }
        },
        false
      )
    }

    this.createIframeEnd()
  }
  onKeyDown(event) {
    //console.log(event.keyCode, "键盘事件");
    if (event.shiftKey && event.keyCode === 13) {
      var e = this.state.TextAreaValue
      this.setState({
        TextAreaValue: e,
      })
    } else if (event.ctrlKey && event.keyCode === 13) {
      event.cancelBubble = true
      event.preventDefault()
      event.stopPropagation()
      event.returnValue = false
      this.setRequest('write')
      return false
    } else if (event.keyCode === 13) {
      event.cancelBubble = true
      event.preventDefault()
      event.stopPropagation()
      this.setRequest('write')
      event.returnValue = false
      return false
    }
  }
  setRequest(action, iscalculation) {
    const { apiInfo, loadingIndex, graphic, jobDesc } = this.state
    if (
      ((action === 'write' && this.state.selectValue === '') ||
        (action === 'analyze' && this.state.jobDescString === '')) &&
      graphic === 'posters'
    ) {
      this.setState({
        // jobDesc: [],
        ContinuationLoading: false,
        loading: false,
        loadingIndex: 0,
        loadingImages: [],
        requestMsg: '',
        requestMsgText: '您还未输入关键信息',
      })
    } else if (
      graphic === 'badges' &&
      (jobDesc.job === '' ||
        jobDesc.company === '' ||
        jobDesc.logo === '' ||
        jobDesc.qrcode === '')
    ) {
      let requestMsgText = '您还未输入关键信息'
      if (jobDesc.job === '') {
        requestMsgText = '您还未输入岗位名称'
      } else if (jobDesc.company === '') {
        requestMsgText = '您还未输入公司名称'
      } else if (jobDesc.logo === '') {
        requestMsgText = '您还未上传LOGO'
      } else if (jobDesc.qrcode === '') {
        requestMsgText = '您还未上传二维码'
      }
      this.setState({
        ContinuationLoading: false,
        loading: false,
        loadingIndex: 0,
        loadingImages: [],
        requestMsg: '',
        requestMsgText: requestMsgText,
      })
    } else if (
      graphic === 'cards' &&
      (jobDesc.job === '' ||
        jobDesc.phone === '' ||
        jobDesc.email === '' ||
        jobDesc.logo === '' ||
        jobDesc.qrcode === '')
    ) {
      let requestMsgText = '您还未输入关键信息'
      if (jobDesc.job === '') {
        requestMsgText = '您还未输入岗位名称'
      } else if (jobDesc.phone === '') {
        requestMsgText = '您还未输入电话号码'
      } else if (jobDesc.email === '') {
        requestMsgText = '您还未输入邮箱'
      } else if (jobDesc.logo === '') {
        requestMsgText = '您还未上传LOGO'
      } else if (jobDesc.qrcode === '') {
        requestMsgText = '您还未上传二维码'
      }
      this.setState({
        ContinuationLoading: false,
        loading: false,
        loadingIndex: 0,
        loadingImages: [],
        requestMsg: '',
        requestMsgText: requestMsgText,
      })
    } else {
      let params = {}
      if (graphic === 'posters') {
        params = {
          app_id: this.state.record.id,
          text:
            action === 'write'
              ? this.state.selectValue
              : this.state.jobDescString,
          num: 2,
          action: action,
          format: 'true',
          count_num: apiInfo.type === 0 ? 0 : this.state.loadingCount,
        }
      } else if (graphic === 'badges') {
        params = {
          app_id: this.state.record.id,
          job: jobDesc.job,
          company: jobDesc.company,
          logo: jobDesc.logo,
          qrcode: jobDesc.qrcode,
        }
      } else if (graphic === 'cards') {
        params = {
          app_id: this.state.record.id,
          job: jobDesc.job,
          phone: jobDesc.phone,
          email: jobDesc.email,
          logo: jobDesc.logo,
          qrcode: jobDesc.qrcode,
        }
      }
      if (!iscalculation) {
        this.setState({
          loading: true,
          ContinuationLoading: true,
          action: action,
        })
      }

      let that = this
      this.getCheckResource(function (result) {
        if (result.code === 200) {
          getRequest(params).then((res) => {
            if (res.code === 200) {
              if (!iscalculation || loadingIndex === 0) {
                if (graphic === 'posters') {
                  that.inputValue(res.data)
                  that.setState({
                    jobDesc: res.data,
                  })
                }
                that.setState({
                  loadingIndex: 0,
                  loadingImages: [],
                  ContinuationLoading: false,
                  requestMsg: '',
                })
                that.createIframeStart()
              } else {
                that.setState({
                  ContinuationLoading: false,
                })
                that.createIframeEnd()
              }
            } else if (res.code === 400 || res.code === 3003) {
              that.setState({
                // jobDesc: [],
                jobDescString: '',
                ContinuationLoading: false,
                requestMsg: '',
                loadingIndex: 0,
                loadingImages: [],
                requestMsgText: res.msg,
                loading: false,
              })
            } else {
              that.setState({
                // jobDesc: [],
                jobDescString: '',
                loadingIndex: 0,
                loadingImages: [],
                ContinuationLoading: false,
                requestMsg: 'error',
                requestMsgText: res.msg,
                loading: false,
              })
            }
          })
        } else {
          that.setState({
            // jobDesc: [],
            jobDescString: '',
            loadingIndex: 0,
            loadingImages: [],
            ContinuationLoading: false,
            requestMsg: 'error',
            requestMsgText: result.msg,
            loading: false,
          })
        }
      })
    }
  }
  getCheckResource(callback) {
    let params = { model_capability: this.state.apiInfo?.model_capability }
    let PACKAGES = ['PACKAGE_FINISH', 'PACKAGE_EXPIRED']
    check_resource(params).then((res) => {
      if (res.code === 200) {
        if (res.data) {
          let new_upgradetips = res.data
          new_upgradetips.model_capability = params.model_capability
          this.setState({
            upgradetips: new_upgradetips,
          })
          if (PACKAGES[new_upgradetips.notice_type]) {
            Notification.warning({ content: new_upgradetips.message })
          } else {
            setTimeout(() => {
              this.setState({
                visibleUpgrade: true,
              })
            }, 100)
          }
        }
        callback(res)
      } else {
        callback(res)
      }
    })
  }
  goResources() {
    history.push('/myapp/resources')
    localStorage.setItem('record', JSON.stringify(this.state.record))
    window.location.reload()
  }
  goInterfacetest() {
    history.push('/myapp/interfacetest')
    localStorage.setItem('record', JSON.stringify(this.state.record))
    window.location.reload()
  }
  ChangeAbatch() {
    this.setState({
      loading: true,
      requestMsg: '',
    })
    setTimeout(() => {
      this.setRequest(this.state.action, true)
      // this.createIframeEnd();
    }, 300)
  }
  setdownload(visible) {
    this.setState({
      visible: visible,
    })
    // setTimeout(() => {
    //   this.getimgSize();
    // }, 200);
  }
  opendownload(item) {
    this.setdownload(true)
    this.setState({
      currentClick: item,
    })
  }
  getimgSize() {
    JQ('#showimg').outerHeight()
    JQ('.image2text-img').css({
      height: JQ('#showimg').outerHeight() / 2 + 'px',
      overflow: 'hidden',
    })
  }
  getUrlBase64() {
    let url = this.state.loadingImages[this.state.currentClick]

    fetch(url)
      .then(async (res) => await res.blob())
      .then((blob) => {
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = URL.createObjectURL(blob)
        a.download = '轻职图-' + Date.now() + '.jpg'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      })
  }
  isputapp = (name, desc) => {
    this.putapp(name, desc)
  }
  putapp(name, desc) {
    let params = {
      id: this.state.record.id,
    }
    if (desc !== false) {
      params.desc = desc
    } else if (name !== false) {
      params.name = name
    }
    putapp(params).then((res) => {
      if (res.code === 200) {
      } else {
        this.getApiInfo(true)
        Notification.error({ content: res.msg })
      }
    })
  }
  onTextArea(value) {
    this.setState({
      jobDescString: value,
    })
  }
  dealTextArea() {
    const lines = this.state.jobDescString.split('\n')
    let jobDesc = this.state.jobDesc
    jobDesc[0].job = ''
    jobDesc[0].require = []
    jobDesc[0].desc = []

    const matches = {
      job: /^(招聘岗位|岗位|招聘职位|职位|岗位名称)[\:：]{1}(.*)$/,
      require:
        /^(岗位要求|要求|招聘职位|职位|招聘要求|任职资格|任职要求)[\:：]{1}(.*)$/,
      desc: /^(岗位职责|岗位描述|职责|描述|工作职责|职责描述|工作内容)[\:：]{1}(.*)$/,
      item: /^\d*[、\.：\:](.+)/,
    }

    let match = []
    let key = null
    let index = 0

    lines.forEach(function (line) {
      line = JQ.trim(line)

      if (!line) {
        return null
      }

      if (matches.job.test(line)) {
        match = line.match(matches.job)
        jobDesc[0]['job'] = match[2]
        key = 'job'
      } else if (matches.require.test(line)) {
        match = line.match(matches.require)
        key = 'require'
        index = 0

        if (match[2]) {
          index = 1
          jobDesc[0]['require'].push(index + '.' + match[2])
        }
      } else if (matches.desc.test(line)) {
        match = line.match(matches.desc)
        key = 'desc'
        index = 0

        if (match[2]) {
          index = 1
          jobDesc[0]['desc'].push(index + '.' + match[2])
        }
      } else if (key) {
        if (key === 'job') {
          jobDesc[0][key] = line
        } else {
          match = line.match(matches.item)

          if (match && match[1]) {
            index++
            jobDesc[0][key].push(index + '.' + match[1])
          } else {
            index++
            jobDesc[0][key].push(index + '.' + line)
          }
        }
      }
    })

    return jobDesc
  }
  onChangetitle(type, value) {
    var apiInfo = this.state.apiInfo
    if (type === 'desc') {
      if (value.length > 100) {
        apiInfo.app_desc = value.substring(0, 100)
        Notification.warning({ content: '修改内容超过100个字！' })
        return
      }
      apiInfo.app_desc = value
      this.setState({
        apiInfo: apiInfo,
      })
      this.isputapp(false, value)
    } else if (type === 'name') {
      if (value.length > 20) {
        apiInfo.app_name = value.substring(0, 20)
        Notification.warning({ content: '修改标题超过20个字！' })
        return
      }
      apiInfo.app_name = value
      this.setState({
        apiInfo: apiInfo,
      })
      this.isputapp(value, false)
    }
  }
  createDiv(arr) {
    return '<div>' + arr.join('</div><div>') + '</div>'
  }
  goaddApp() {
    history.replace('/myapp/addmyapp')
    let state = {
      model_capability: this.state.apiInfo.model_capability,
      checkedtype: this.state.apiInfo.model_id,
      model_id: this.state.apiInfo.model_id,
      name: this.state.apiInfo.model_name,
    }
    localStorage.setItem('props', JSON.stringify(state))
    localStorage.removeItem('record')
    window.location.reload()
  }
  addRow() {
    localStorage.removeItem('props')
    localStorage.setItem('record', JSON.stringify(this.state.apiInfo))
    history.replace('/enginefactory/model/management/add')
    window.location.reload()
  }
  inputPlaceHolder() {
    return '请以标签+内容的形式，输入海报相关信息\r\n例\n招聘岗位：产品经理\n岗位要求：...\n岗位职责：...'
  }
  inputValue(jobDesc) {
    let input = ''

    if (jobDesc.length > 0) {
      if (jobDesc[0].job) {
        input += '招聘岗位：' + jobDesc[0].job + '\n'
      }

      if (jobDesc[0].require.length > 0) {
        input += '\n' + '岗位要求：' + '\n'

        jobDesc[0].require.forEach(function (value) {
          input += value + '\n'
        })
      }

      if (jobDesc[0].desc.length > 0) {
        input += '\n' + '岗位描述：' + '\n'

        jobDesc[0].desc.forEach(function (value) {
          input += value + '\n'
        })
      }
    }

    this.setState({ jobDescString: input })
  }
  setvisibleUpgrade(value) {
    this.setState({
      visibleUpgrade: value,
    })
  }
  handleUpload(option) {
    upload(option)
  }
  setFile(fileList, file) {
    let files = {
      ...file,
      url: URL.createObjectURL(file.originFile),
    }
    this.setState({
      logofile: files,
    })
    if (file.status === 'done') {
      files = {
        ...file,
        url: file.response.url,
      }
      let jobDesc = this.state.jobDesc
      jobDesc.logo = file.response.url

      this.setState({
        logofile: files,
        jobDesc: jobDesc,
      })
    }
  }
  setFile2(fileList, file) {
    let files = {
      ...file,
      url: URL.createObjectURL(file.originFile),
    }
    this.setState({
      codefile: files,
    })
    if (file.status === 'done') {
      files = {
        ...file,
        url: file.response.url,
      }
      let jobDesc = this.state.jobDesc
      jobDesc.qrcode = file.response.url
      this.setState({
        codefile: files,
        jobDesc: jobDesc,
      })
    }
  }
  beforeUpload(file) {
    var AllImgExt = '.jpg|.svg|.png|'
    var extName = file.name.substring(file.name.lastIndexOf('.')).toLowerCase() //（把路径中的所有字母全部转换为小写）
    if (AllImgExt.indexOf(extName + '|') === -1) {
      let ErrMsg =
        '该文件类型不允许上传。请上传 .jpg、.svg、.png 类型的文件，当前文件类型为' +
        extName
      Notification.error({ content: ErrMsg })
      return false
    }
    const isLt10M = !(
      b2ValueUnit(file.size)[1] === 'B' ||
      b2ValueUnit(file.size)[1] === 'KB' ||
      (b2ValueUnit(file.size)[1] === 'MB' && b2ValueUnit(file.size)[0] <= 1)
    )
    return new Promise((resolve, reject) => {
      if (isLt10M) {
        Notification.error({ content: '上传文件不能大于1M' })
        reject()
      } else {
        resolve()
      }
    })
  }
  onRemove(type, e) {
    e.stopPropagation()
    if (type === 1) {
      this.setState({
        logofile: {},
      })
    } else if (type === 2) {
      this.setState({
        codefile: {},
      })
    }
  }
  onInputDesc(name, value) {
    let jobDesc = this.state.jobDesc
    jobDesc[name] = value
    this.setState({
      jobDesc: jobDesc,
    })
  }
  graphicWidth() {
    let itemWidth = 162
    let scale = 0.144
    switch (this.state.graphic) {
      case 'posters':
        itemWidth = 162
        break
      case 'badges':
        itemWidth = 191
        scale = 0.29
        break
      case 'cards':
        itemWidth = 200
        scale = 0.305
        break
      default:
        break
    }
    // let Parent_W = JQ("#continuation").outerWidth();
    // console.log(itemWidth / Parent_W, "Parent_W");
    this.setState({
      graphicWidth: itemWidth,
      scale: scale,
    })
  }
  render() {
    const {
      visibleUpgrade,
      upgradetips,
      apiInfo,
      versions,
      optionVlue,
      input_describe,
      selectValue,
      loading,
      loadingIndex,
      graphic,
      jobDescString,
      ContinuationLoading,
      requestMsg,
      requestMsgText,
      logofile,
      codefile,
      jobDesc,
    } = this.state

    return (
      <div className="image2text-index">
        <div id="renderHeaders">
          <div className="GroupTable-fixe GroupTable-fixe-end">
            <div className="edit-title">
              <div className="Header-init">
                <div className="Header-title">
                  <Typography.Paragraph
                    className="Header-uptitle"
                    editable={
                      apiInfo.type === 0
                        ? null
                        : {
                            onChange: this.onChangetitle.bind(this, 'name'),
                          }
                    }
                  >
                    {apiInfo.app_name}
                  </Typography.Paragraph>
                </div>
              </div>
              <div className="Header-upParagraph">
                <Typography.Paragraph
                  className="Header-uptitle"
                  editable={
                    apiInfo.type === 0
                      ? null
                      : {
                          onChange: this.onChangetitle.bind(this, 'desc'),
                        }
                  }
                >
                  {apiInfo.app_desc}
                </Typography.Paragraph>
              </div>
            </div>
            <Space size={'medium'} align="center">
              {apiInfo.type === 0 ? (
                <Button
                  className="primarybtn custom-White-button"
                  onClick={this.goaddApp.bind(this)}
                >
                  保存应用
                </Button>
              ) : (
                <>
                  {versions.length === 0 ? (
                    <div></div>
                  ) : (
                    <Select
                      value={optionVlue}
                      placeholder="版本选择"
                      style={{ width: 220 }}
                      className="versionsSelect"
                      onChange={this.onsetPosition.bind(this)}
                      dropdownRender={(menu) => (
                        <div>
                          {menu}
                          <Divider
                            style={{
                              margin: 0,
                              marginTop: 6,
                              borderColor: '#f2f2f2',
                            }}
                          />
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              padding: '5px 0px',
                            }}
                          >
                            <Button
                              style={{
                                width: '100%',
                                height: 41,
                                fontSize: 14,
                                padding: '0 6px',
                                borderRadius: 10,
                              }}
                              type="text"
                              onClick={this.addRow.bind(this)}
                            >
                              <IconPlus />
                              新增模型版本
                            </Button>
                          </div>
                        </div>
                      )}
                    >
                      {versions.map((option, index) => (
                        <Option key={index} value={option.id}>
                          {apiInfo.model_name}V{option.version}
                        </Option>
                      ))}
                    </Select>
                  )}
                  {apiInfo.type === 9 ? (
                    <div className="primary_model_name">
                      <div className="primary_model_name_fix">
                        <span className="primary_model_name_title">
                          模型
                          <Divider type="vertical" />
                        </span>
                        <Typography.Paragraph ellipsis>
                          {apiInfo.model_name}
                        </Typography.Paragraph>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {/* <Button
                    shape="circle"
                    className="custom-White-button"
                    onClick={this.goResources.bind(this)}
                  >
                    应用资源
                  </Button> */}
                  <Button
                    shape="circle"
                    className="custom-White-button share_button_wnk"
                    onClick={CreateLink.bind(this, apiInfo)}
                  >
                    分享应用
                  </Button>
                  <Button
                    style={{
                      display:
                        this.state.identity &&
                        this.state.identity !== 'PERSONAL'
                          ? 'inline-block'
                          : 'none',
                    }}
                    shape="circle"
                    className="custom-White-button"
                    onClick={this.goInterfacetest.bind(this)}
                  >
                    接口调用
                  </Button>
                </>
              )}
            </Space>
          </div>
        </div>
        <Layout className="semanticprototype-Layout" id="layout_id">
          <Sider className="Sider-left">
            <div className="Sider-left-configure columnSpaceBetween">
              <div className="Continuation-input">
                {graphic === 'posters' ? (
                  <>
                    <div id="selectinput">
                      <Select
                        placeholder={input_describe}
                        defaultValue={selectValue}
                        style={{ width: '100%' }}
                        suffixIcon={<IconSearch />}
                        showSearch
                        allowClear
                        onSearch={this.onSearch.bind(this)}
                        onChange={this.onChange.bind(this)}
                      >
                        {this.state.jobs.map((option, index) => (
                          <Option key={index} value={option}>
                            {option}
                          </Option>
                        ))}
                      </Select>
                    </div>

                    <div className="jobDesc-content" id="jobdesc">
                      <TextArea
                        placeholder={this.inputPlaceHolder()}
                        style={{ width: '100%', height: '100%' }}
                        autoSize={false}
                        onChange={this.onTextArea.bind(this)}
                        onKeyDown={(event) => this.onKeyDown(event)}
                        value={jobDescString}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="newcontent-item">
                      <div className="job-title">岗位名称</div>
                      <Input
                        allowClear
                        placeholder="请输入岗位名称"
                        value={jobDesc?.job}
                        className="new-input"
                        onChange={this.onInputDesc.bind(this, 'job')}
                      />
                    </div>
                    {graphic === 'badges' ? (
                      <>
                        <div className="newcontent-item">
                          <div className="job-title">公司名称</div>
                          <Input
                            allowClear
                            placeholder="请输入公司名称"
                            className="new-input"
                            value={jobDesc?.company}
                            onChange={this.onInputDesc.bind(this, 'company')}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="newcontent-item">
                          <div className="job-title">电话号码</div>
                          <Input
                            allowClear
                            placeholder="请输入电话号码"
                            className="new-input"
                            value={jobDesc?.phone}
                            onChange={this.onInputDesc.bind(this, 'phone')}
                          />
                        </div>
                        <div className="newcontent-item">
                          <div className="job-title">邮箱</div>
                          <Input
                            allowClear
                            placeholder="请输入邮箱"
                            className="new-input"
                            value={jobDesc?.email}
                            onChange={this.onInputDesc.bind(this, 'email')}
                          />
                        </div>
                      </>
                    )}
                    <div className="newcontent-item">
                      <div className="job-title">LOGO</div>
                      <Upload
                        accept=".JPG,.PNG,.SVG"
                        fileList={this.state.logofile}
                        showUploadList={false}
                        limit={1}
                        onChange={this.setFile.bind(this)}
                        customRequest={(option) => {
                          this.handleUpload(option)
                        }}
                        beforeUpload={this.beforeUpload.bind(this)}
                        onExceedLimit={() => {
                          Notification.warning({
                            content: '超过上传数量限制！最多上传1个',
                          })
                        }}
                        ref={(ref) => (this.logoref = ref)}
                      >
                        <div className={this.state.cs}>
                          {logofile && logofile.url ? (
                            <div className="arco-upload-list-item-picture">
                              <div className="upload-demo-list">
                                <img src={logofile.url} alt="" />
                                <div className="arco-upload-mask">
                                  <IconDelete
                                    className="file-Delete "
                                    onClick={this.onRemove.bind(this, 1)}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="columnCenter trigger">
                              <span className="iconfont icon-icon_chuangjian_24"></span>
                              <div>上传LOGO</div>
                            </div>
                          )}
                        </div>
                      </Upload>
                    </div>
                    <div className="newcontent-item">
                      <div className="job-title">二维码</div>
                      <Upload
                        accept=".JPG,.PNG,.SVG"
                        showUploadList={false}
                        fileList={this.state.cscode}
                        limit={1}
                        onChange={this.setFile2.bind(this)}
                        customRequest={(option) => {
                          this.handleUpload(option)
                        }}
                        beforeUpload={this.beforeUpload.bind(this)}
                        onExceedLimit={() => {
                          Notification.warning({
                            content: '超过上传数量限制！最多上传1个',
                          })
                        }}
                        ref={(ref) => (this.coderef = ref)}
                      >
                        <div className={this.state.cscode}>
                          {codefile && codefile.url ? (
                            <div className="arco-upload-list-item-picture">
                              <div className="upload-demo-list">
                                <img src={codefile.url} alt="" />
                                <div className="arco-upload-mask">
                                  <IconDelete
                                    className="file-Delete "
                                    onClick={this.onRemove.bind(this, 2)}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="columnCenter trigger">
                              <span className="iconfont icon-icon_chuangjian_24"></span>
                              <div>上传二维码</div>
                            </div>
                          )}
                        </div>
                      </Upload>
                    </div>
                  </>
                )}
              </div>
              <div className="Upload-pictures-bottom" id="start_generation">
                <Button
                  type="primary"
                  className="Start-generation"
                  onClick={this.isPhoneLegal.bind(this)}
                >
                  {this.state.requesBtnText}
                </Button>
              </div>
            </div>
          </Sider>
          <Content>
            <div className="experience-result" id="result">
              <div id="result_title" className="rowSpaceBetween result_title">
                <Typography>生成结果</Typography>
                <Typography.Paragraph className="result-remarks">
                  (内容基于韦尼克AI创作引擎生成，仅供参考)
                </Typography.Paragraph>
              </div>
              <div className="continuation-body" id="continuation">
                {loadingIndex > 0 || ContinuationLoading ? (
                  <div className="Paragraph-generate">
                    {ContinuationLoading ? (
                      <Empty
                        className="Continuation-loding"
                        icon={<img src={Generating_img} />}
                        description={
                          <Typography
                            style={{
                              color: '#4e5969',
                              marginTop: 21,
                              fontSize: 16,
                            }}
                          >
                            正在生成中...
                          </Typography>
                        }
                      />
                    ) : (
                      <div className="Continuation-result" id="continuation">
                        <div
                          className="Continuation-list"
                          id="Continuation-list"
                        >
                          {Array(loadingIndex)
                            .fill(0)
                            .map((item, index) => {
                              return (
                                <div
                                  className="Continuation-item"
                                  style={{
                                    '--width': this.state.graphicWidth + 'px',
                                  }}
                                  key={index}
                                  onClick={this.opendownload.bind(this, index)}
                                />
                              )
                            })}
                        </div>
                        {loading ? (
                          <Spin dot />
                        ) : (
                          <Button
                            icon={<IconPlus />}
                            id="iconSync"
                            className="IconSync-btn custom-White-border-button"
                            onClick={this.ChangeAbatch.bind(this)}
                          >
                            生成更多
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                ) : requestMsg === 'error' ? (
                  <Empty
                    className="Continuation-Empty Empty-error"
                    imgSrc={errorIcon}
                    description={
                      <Space direction="vertical">
                        <Typography
                          style={{
                            color: '#4e5969',
                            marginTop: 30,
                            fontSize: 16,
                          }}
                        >
                          抱歉，服务器生成错误
                        </Typography>
                        <Typography style={{ color: '#4e5969', fontSize: 16 }}>
                          可尝试
                          <span
                            style={{ color: '#406EFF', cursor: 'pointer' }}
                            onClick={this.ChangeAbatch.bind(this)}
                          >
                            重新生成
                          </span>
                        </Typography>
                      </Space>
                    }
                  />
                ) : (
                  <Empty
                    className="Continuation-Empty Empty-none"
                    imgSrc={Emptydata_img}
                    description={
                      <Space direction="vertical">
                        <Typography
                          style={{
                            color: '#4e5969',
                            marginTop: 30,
                            fontSize: 16,
                          }}
                        >
                          暂无生成结果
                        </Typography>
                        <Typography style={{ color: '#4e5969', fontSize: 16 }}>
                          {requestMsgText}
                        </Typography>
                      </Space>
                    }
                  />
                )}
              </div>
            </div>
          </Content>
        </Layout>
        <Modal
          style={{
            background: 'transparent',
            width: 396,
          }}
          onCancel={() => this.setdownload(false)}
          className="image2text-modal-content"
          title={null}
          visible={this.state.visible}
          autoFocus={false}
          footer={null}
        >
          <div className="image2text-modal">
            <div className="image2text-img">
              {
                <img
                  id="showimg"
                  src={this.state.loadingImages[this.state.currentClick]}
                  alt={`轻职图${apiInfo?.model_name}`}
                />
              }
            </div>

            <div className="image2text-opera">
              <Button
                type="primary"
                className="download-btn"
                onClick={this.getUrlBase64.bind(this)}
              >
                <IconDownload />
                立即下载
              </Button>
            </div>
          </div>
        </Modal>
        <UpgradeTips
          visible={visibleUpgrade}
          notification_status={upgradetips?.notice_type}
          notification_message={upgradetips?.message}
          model_capability={upgradetips?.model_capability}
          onCancel={() => this.setvisibleUpgrade(false)}
        ></UpgradeTips>
      </div>
    )
  }
}
export default Image2Text
