import React, { Component } from "react";
import {
  Table,
  Card,
  Typography,
  Button,
  Divider,
  Alert,
  Input,
  Space,
  Empty,
  Spin,
  Checkbox,
  Modal,
  Notification,
  Tag,
} from "@arco-design/web-react";
import {
  IconPlus,
  IconClose,
  IconDelete,
  IconSettings,
  IconSearch,
  IconLoading,
  IconMinusCircleFill,
} from "@arco-design/web-react/icon";
import { Link } from "react-router-dom";
import { getdatumList, deletedatum } from "api/apis";
import { getCurrentDate } from "common/utils";
import history from "common/utils/history";
import Emptydata_img from "asserts/Emptydata_img.svg";
import "./dataset.scss";
class DataSet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingbody: true,
      data: [],
      pagination: {
        sizeCanChange: false,
        showTotal: true,
        total: 0,
        pageSize: 10,
        current: 1,
        pageSizeChangeResetCurrent: true,
        showJumper: false,
      },
      loading: false,
      no_data: false,
      keyword: "",
      checkbox: false,
      selectedRowKeys: [],
      type: "checkbox",
      checkAll: false,
      indeterminate: false,
      SelectcheckAll: false,
      visibledelete: false,
      confirmLoading: false,
      columns: [
        {
          title: "数据集名称",
          dataIndex: "name",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          width: "230px",
          render: (col, record, index) => (
            <Typography.Paragraph
              ellipsis={{ wrapper: "span" }}
              style={{ marginBottom: 0 }}
            >
              {col}
            </Typography.Paragraph>
          ),
        },

        {
          title: "服务选项",
          dataIndex: "service_name",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          render: (col, record, index) => <span>{col}</span>,
          width: 230,
          filters: this.filtersTable(),
          onFilter: (value, row) => row.service_name.indexOf(value) > -1,
          filterMultiple: false,
        },
        {
          title: "数据量",
          dataIndex: "line_count",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          render: (col, record, index) => (
            <>
              {" "}
              {record.load_status === "导入成功" ? (
                col
              ) : record.load_status === "导入失败" ? (
                <Tag
                  color="gray"
                  icon={<IconMinusCircleFill style={{ color: "#86909C" }} />}
                  style={{ borderRadius: 7, cursor: "pointer" }}
                  title="点击联系客服解决"
                  onClick={this.onClickgray.bind(this)}
                >
                  渲染失败
                </Tag>
              ) : (
                <Tag
                  color="arcoblue"
                  icon={<IconLoading style={{ color: "#165DFF" }} />}
                  style={{ borderRadius: 7 }}
                >
                  渲染中
                </Tag>
              )}
            </>
          ),
        },
        {
          title: "创建时间",
          dataIndex: "submit_time",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          render: (col, record, index) => (
            <span>{getCurrentDate("-", ":", col)}</span>
          ),
        },
      ],
    };
  }
  componentDidMount() {
    if (
      JSON.parse(localStorage.getItem("userinfo")) &&
      JSON.parse(localStorage.getItem("userinfo")).group_permission
    ) {
      let identity = JSON.parse(
        localStorage.getItem("userinfo")
      ).group_permission;
      if (identity && identity !== "PERSONAL") {
        let columns = this.state.columns;
        let json = {
          title: "创建者",
          dataIndex: "nickname",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          render: (col, record, index) => (
            <span
              style={{ color: record.is_deleted !== 0 ? "#8d93a1" : "#0c0c0f" }}
            >
              {col}
            </span>
          ),
        };
        this.setState({
          columns: columns.concat(json),
        });
      }
    }
    localStorage.removeItem("props");
    this.getdatumList();
  }
  filtersTable() {
    let Selectoptions = JSON.parse(localStorage.getItem("Selectoptions"));
    let productsOptions = [];
    Object.keys(Selectoptions ? Selectoptions : []).map((item, i) => {
      let Option = {
        text: Selectoptions[item].label,
        value: Selectoptions[item].label,
      };
      if (Selectoptions[item].status !== 2) {
        productsOptions.push(Option);
      }
    });
    return productsOptions;
  }
  onClickgray() {
    document.getElementById("supportBtn").click();
  }
  getdatumList(loading) {
    let page = {
      per_page: this.state.pagination.pageSize,
      page: this.state.pagination.current,
      keyword: this.state.keyword,
    };
    this.setState({
      loading: loading,
    });
    getdatumList(page).then((res) => {
      if (res.code === 200) {
        let list = res.data;
        let pagination = this.state.pagination;
        pagination.total = list.count;
        this.setState({
          pagination: pagination,
          data: list.data,
          loading: false,
          loadingbody: false,
        });
        //console.log(list, 'list')
      }
    });
  }
  onChangeTable(pagination) {
    const { current, pageSize } = pagination;
    let setPagination = this.state.pagination;
    setPagination.current = current;
    setPagination.pageSize = pageSize;
    this.setState({
      loading: false,
      pagination: setPagination,
    });
    this.getdatumList();
  }
  godetails(record) {
    history.push("/enginefactory/dataset/details");
    localStorage.setItem("record", JSON.stringify(record));
    window.location.reload();
  }
  onAdd() {
    // history.replace("/enginefactory/dataset/add")
    history.push({ pathname: "/enginefactory/dataset/add" }); //跳转但不记
    window.location.reload();
  }
  onPressEnter(value) {
    //console.log(value, 'value')
    let setPagination = this.state.pagination;
    setPagination.current = 1;
    this.setState({
      keyword: value,
      pagination: setPagination,
    });
    this.state.keyword = value;
    this.getdatumList(true);
  }
  setSelectedRowKeys(selectedRowKeys) {
    let selectkeys = [];
    this.state.data.forEach((i, index) => {
      //console.log(i.enable_del)
      if (i.enable_del) {
        selectkeys.push(i.id);
      }
    });
    this.setState({
      selectedRowKeys: selectedRowKeys,
      SelectcheckAll:
        selectedRowKeys.length === selectkeys.length ? true : false,
    });
  }
  onChangeAll(checked) {
    let selectkeys = [];
    if (checked) {
      this.state.data.forEach((i, index) => {
        //console.log(i.enable_del)
        if (i.enable_del) {
          selectkeys.push(i.id);
        }
      });
      this.setState({
        indeterminate: false,
        SelectcheckAll: true,
        selectedRowKeys: selectkeys,
      });
    } else {
      this.setState({
        indeterminate: false,
        SelectcheckAll: false,
        selectedRowKeys: selectkeys,
      });
    }
    //console.log(selectkeys, 'selectedRowKeys')
  }
  setVisibledelete(value) {
    this.setState({
      visibledelete: value,
    });
  }
  deletedatum() {
    //console.log(this.state.selectedRowKeys, 'selectedRowKeysselectedRowKeys')
    let datas = { ids: JSON.stringify(this.state.selectedRowKeys) };
    this.setState({
      confirmLoading: true,
    });
    deletedatum(datas).then((res) => {
      if (res.code === 200) {
        let setPagination = this.state.pagination;
        setPagination.current = 1;
        this.setState({
          selectedRowKeys: [],
          SelectcheckAll: false,
          confirmLoading: false,
          setPagination: setPagination,
        });
        this.setVisibledelete(false);
        this.getdatumList(true);
      } else {
        this.setState({
          confirmLoading: false,
        });
        Notification.error({ title: "Error", content: res.msg });
      }
    });
  }
  onSettings() {
    this.setState({
      checkbox: true,
    });
  }
  onClose() {
    this.setState({
      checkbox: false,
      SelectcheckAll: false,
      selectedRowKeys: [],
    });
  }
  render() {
    const {
      keyword,
      confirmLoading,
      visibledelete,
      indeterminate,
      SelectcheckAll,
      loadingbody,
      checkbox,
      checkAll,
      type,
      selectedRowKeys,
      loading,
      columns,
      data,
      pagination,
      no_data,
    } = this.state;
    return (
      <div className="dataset">
        <div className="Header-init">
          <div className="Header-title">数据集</div>
        </div>
        {pagination.total > 0 || keyword ? (
          <div className="GroupTable-fixe">
            {checkbox ? (
              <>
                <div className="custom-checkAll">
                  <Checkbox
                    onChange={this.onChangeAll.bind(this)}
                    checked={SelectcheckAll}
                    indeterminate={indeterminate}
                  >
                    {SelectcheckAll ? "取消" : "全选"}
                  </Checkbox>
                </div>
                <Space size={"medium"}>
                  <Button
                    shape="circle"
                    className="custom-White-button"
                    icon={<IconDelete />}
                    disabled={selectedRowKeys.length === 0}
                    onClick={() => this.setVisibledelete(true)}
                  />
                  <Button
                    shape="circle"
                    className="custom-White-button"
                    icon={<IconClose />}
                    onClick={this.onClose.bind(this)}
                  />
                </Space>
              </>
            ) : (
              <>
                <Input.Search
                  style={{ width: 350 }}
                  // prefix={<IconSearch />}
                  placeholder="快速搜索你的数据集"
                  className="GroupTable-Search"
                  // onPressEnter={this.onPressEnter.bind(this)}
                  onSearch={this.onPressEnter.bind(this)}
                />
                <div>
                  <Space size={"medium"}>
                    <Button
                      type="primary"
                      className="Header-init-btn"
                      style={{ borderRadius: 10 }}
                      onClick={this.onAdd.bind(this)}
                    >
                      创建数据集
                    </Button>
                    <Button
                      shape="circle"
                      className="custom-White-button"
                      icon={<IconSettings />}
                      onClick={this.onSettings.bind(this)}
                    />
                  </Space>
                </div>
              </>
            )}
          </div>
        ) : (
          ""
        )}
        {loadingbody ? (
          <Spin loading={loadingbody} dot className="loadingbody"></Spin>
        ) : (
          <>
            {pagination.total === 0 && !keyword && !loading ? (
              <div className="Empty-absolute">
                <Empty
                  className="Empty-none"
                  imgSrc={Emptydata_img}
                  description={
                    <Space direction="vertical">
                      <Typography
                        style={{
                          color: "#4e5969",
                          marginTop: 30,
                          fontSize: 16,
                        }}
                      >
                        暂时没有数据集
                      </Typography>
                      <Typography style={{ color: "#4e5969", fontSize: 16 }}>
                        创建数据集，支撑更多模型训练
                      </Typography>
                    </Space>
                  }
                />
                <div className="Emptydata-btns">
                  <Button
                    type="primary"
                    className="Emptydata-btn"
                    onClick={this.onAdd.bind(this)}
                  >
                    <IconPlus />
                    创建数据集
                  </Button>
                </div>
              </div>
            ) : (
              <div>
                {loading ? (
                  <Spin dot className="table-spin-loading" />
                ) : (
                  <>
                    {pagination.total === 0 ? (
                      <Empty
                        imgSrc={Emptydata_img}
                        description={"暂无相关数据集"}
                        style={{ marginTop: 200 }}
                      />
                    ) : (
                      <Card
                        bordered={false}
                        hoverable
                        style={{ marginBottom: 20, borderRadius: 20 }}
                      >
                        <Table
                          virtualized
                          no_data={no_data}
                          border={{ wrapper: false, cell: false }}
                          noDataElement={
                            <Empty
                              imgSrc={Emptydata_img}
                              description={"暂无相关数据集"}
                            />
                          }
                          loading={loading}
                          columns={columns.concat({
                            title: "操作",
                            dataIndex: "operation",
                            render: (col, record, index) => (
                              <Button
                                type="outline"
                                className="outline"
                                disabled={
                                  record.load_status === "待导入" ||
                                  record.load_status === "导入中" ||
                                  record.load_status === "导入失败"
                                }
                                onClick={this.godetails.bind(this, record)}
                              >
                                查看详情
                              </Button>
                            ),
                            fixed: "right",
                            width: 150,
                            align: "center",
                          })}
                          data={data}
                          pagination={
                            pagination.total === 0 ? false : pagination
                          }
                          onChange={this.onChangeTable.bind(this)}
                          renderPagination={(paginationNode) => (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                marginTop: 10,
                              }}
                            >
                              {paginationNode}
                            </div>
                          )}
                          rowKey="id"
                          rowSelection={
                            checkbox && {
                              type,
                              selectedRowKeys,
                              checkAll: checkAll,
                              onChange: (selectedRowKeys, selectedRows) => {
                                //console.log('onChange:', selectedRowKeys, selectedRows);
                                this.setSelectedRowKeys(selectedRowKeys);
                              },
                              onSelect: (selected, record, selectedRows) => {
                                //console.log('onSelect:', selected, record, selectedRows)
                              },
                              checkboxProps: (record) => {
                                return {
                                  disabled: !record.enable_del,
                                };
                              },
                            }
                          }
                        />
                      </Card>
                    )}
                  </>
                )}
              </div>
            )}
          </>
        )}
        <Modal
          style={{
            width: 472,
          }}
          className="deleteModal"
          title="设置提示"
          visible={visibledelete}
          okText="确认"
          cancelText="取消"
          confirmLoading={confirmLoading}
          onOk={this.deletedatum.bind(this)}
          onCancel={() => this.setVisibledelete(false)}
        >
          <p>是否确认删除选中内容？</p>
        </Modal>
      </div>
    );
  }
}

export default DataSet;
