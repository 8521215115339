import React from "react";
import { Result, Button } from '@arco-design/web-react';
import history from 'common/utils/history'
import './404.scss'
export default function NotFound() {
  
  return (
    <div className="notpage">
      <Result
        status='404'
        subTitle='哎呀，那一页不见了。 '
        extra={[
          <Button key='again' style={{ marginRight: 16 }} onClick={()=>window.location.reload()}>再一次</Button>,
          <Button key='back' type='primary' onClick={()=>history.goBack(-1)}>返回</Button>,
        ]}
      >
      </Result></div>
  );
}