import axios from 'axios'
import getRequestHeaders from 'api/http'
import { Message } from '@arco-design/web-react'
import ALLURL from 'envconfig/config' //自定义多环境api请求路径

axios.defaults.timeout = 100000

axios.defaults.baseURL = ALLURL.baseURL //若使用定向代理将baseURL替换成'/api'即可
export default function upload(option, model_capability) {
  const { onProgress, onError, onSuccess, file } = option

  const formData = new FormData()
  if (model_capability) {
    // formData.append("model_capability", model_capability);
    let data = {
      model_capability: model_capability,
    }
    // 假如data是额外的其他参数
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key])
      }
    }
  }
  formData.append('file', file)
  // let obj = { "model_capability": model_capability }

  const urlParams = new URL(window.location.href)
  const pathname = urlParams?.pathname
  let path = pathname === '/shareMyapp' ? `/share/file/up` : `/v2/file/up`

  console.log(pathname)

  let headers = getRequestHeaders()
  axios
    .post(path, formData, {
      headers,
      onUploadProgress: ({ total, loaded }) => {
        let percent
        if (total > 0) {
          percent = (loaded / total) * 100
        }
        onProgress(parseInt(percent, 10), file)
      },
    })
    .then(({ data: response }) => {
      if (response.code === 200) {
        onSuccess(response.data, file)
      } else {
        onError(response)
        Message.error(response.msg)
      }
    })
    .catch(onError)
  return {
    abort() {},
    option,
  }
}
