import React, { Component } from "react";
import {
  Notification,
  Checkbox,
  Table,
  Typography,
  Button,
  Modal,
  Spin,
  Radio,
  Space,
  Tag,
  Select,
  Input,
  Empty,
  Alert,
} from "@arco-design/web-react";
import {
  IconPlus,
  IconSettings,
  IconSearch,
  IconDown,
  IconUp,
  IconRight,
  IconLeft,
  IconDelete,
  IconClose,
  IconExclamationCircleFill,
  IconCheckCircleFill,
} from "@arco-design/web-react/icon";
import { Link } from "react-router-dom";
import history from "common/utils/history";
import { getmodelList, getaddlist, deletemodel } from "api/apis";
import { getCurrentDate } from "common/utils";
import Emptydata_img from "asserts/Emptydata_img.svg";
import alertimg from "asserts/model_Empty.svg";

import "./model.scss";
const Option = Select.Option;

class Model extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingbody: true,
      all_count: 0,
      data: [],
      pagination: {
        sizeCanChange: false,
        showTotal: true,
        total: 0,
        pageSize: 10,
        current: 1,
        pageSizeChangeResetCurrent: true,
        showJumper: false,
        paginationSwitch: false,
      },
      loading: true,
      no_data: false,
      visible: false,
      loadingModal: false,
      model_capability: 0,
      type_name: "1", //模型类型；1-定制，9-标准
      positionList: {
        1: "定制模型",
        9: "官方模型",
      },
      swiperloading: false,
      openFoot: false,
      isShow: false,
      footpagination: {
        total: 0,
        pageSize: 10,
        current: 1,
      },
      swiperList: [],
      scrolltype: 0,
      scrollWidth: 0,
      scrollLeft: 0,
      Selectoptions: localStorage.getItem("Selectoptions")
        ? JSON.parse(localStorage.getItem("Selectoptions"))
        : [],
      SelectoptionsModel: [],
      checkbox: false,
      selectedRowKeys: [],
      type: "checkbox",
      checkAll: false,
      indeterminate: false,
      SelectcheckAll: false,
      visibledelete: false,
      confirmLoading: false,
      order_by: "",
      columns: [
        {
          title: "模型名称",
          dataIndex: "name",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          width: "230px",
          render: (col, record, index) => (
            <Typography.Paragraph
              ellipsis={{ wrapper: "span" }}
              style={{ marginBottom: 0 }}
            >
              {col}
            </Typography.Paragraph>
          ),
        },
        {
          title: "服务选项",
          dataIndex: "service_name",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          render: (col, record, index) => (
            <span>
              {
                <div type="text" style={{ height: 28, lineHeight: "28px" }}>
                  {col}
                </div>
              }
            </span>
          ),
          width: 180,
          filters: this.filtersTable(),
          onFilter: (value, row) => row.service_name.indexOf(value) > -1,
          filterMultiple: false,
        },
        {
          title: "版本数量",
          dataIndex: "version_count",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
        {
          title: "状态",
          dataIndex: "train_status",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          render: (col, record, index) => (
            <span>
              {
                <Tag
                  color={col === "训练成功" ? "#E8FFEA" : "#FFF7E8"}
                  style={{
                    borderRadius: 7,
                    color: col === "训练成功" ? "#00B42A" : "#FF7D00",
                  }}
                  icon={
                    col === "训练成功" ? (
                      <IconCheckCircleFill style={{ color: "#00B42A" }} />
                    ) : (
                      <IconExclamationCircleFill style={{ color: "#FF7D00" }} />
                    )
                  }
                >
                  {col === "训练成功" ? "已激活" : "未激活"}
                </Tag>
              }
            </span>
          ),
        },
        {
          title: "更新时间",
          dataIndex: "updated_at",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          width: "180px",
          // sorter: true,
          render: (col, record, index) => (
            <span>{col ? getCurrentDate("-", ":", col) : ""}</span>
          ),
        },
        {
          title: "创建时间",
          dataIndex: "submit_time",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          width: "180px",
          render: (col, record, index) => (
            <span>{col ? getCurrentDate("-", ":", col) : ""}</span>
          ),
        },
      ],
      productlist: [
        "全部",
        "智能写作",
        "智能图文",
        "智能文档",
        "智能创作",
        "知识图谱",
      ],
      group_name: "全部",
    };
    this.statusLineRef = React.createRef();
  }
  componentDidMount() {
    if (
      JSON.parse(localStorage.getItem("userinfo")) &&
      JSON.parse(localStorage.getItem("userinfo")).group_permission
    ) {
      let identity = JSON.parse(
        localStorage.getItem("userinfo")
      ).group_permission;
      if (identity && identity !== "PERSONAL") {
        let columns = this.state.columns;
        let json = {
          title: "创建者",
          dataIndex: "nickname",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          render: (col, record, index) => (
            <span
              style={{ color: record.is_deleted !== 0 ? "#8d93a1" : "#0c0c0f" }}
            >
              {col}
            </span>
          ),
        };
        this.setState({
          columns: columns.concat(json),
        });
      }
    }

    this.getmodelList();
    this.getaddlist();
    // let productlist = this.state.productlist;
    // productlist.unshift("全部");
  }
  filtersTable() {
    let Selectoptions = JSON.parse(localStorage.getItem("Selectoptions"));
    let productsOptions = [];
    Object.keys(Selectoptions ? Selectoptions : []).map((item, i) => {
      let Option = {
        text: Selectoptions[item].label,
        value: Selectoptions[item].label,
      };
      if (Selectoptions[item].status !== 2) {
        productsOptions.push(Option);
      }
    });
    return productsOptions;
  }
  getaddlist() {
    getaddlist().then((res) => {
      if (res.code === 200) {
        let list = res.data;
        this.setState({
          SelectoptionsModel: list,
        });
      }
    });
  }
  getmodelListfoot() {
    let page = {
      per_page: this.state.footpagination.pageSize,
      page: this.state.footpagination.current,
      from: "commend",
      train_status: "训练成功",
    };
    getmodelList(page).then((res) => {
      if (res.code === 200) {
        let list = res.data;
        let pagination = this.state.footpagination;
        pagination.total = list.count;
        this.setState({
          footpagination: pagination,
          swiperList: list.data,
          loadingbody: false,
        });
      } else {
        this.setState({
          loadingbody: false,
        });
      }
    });
  }
  onTabs(value) {
    let pagination = this.state.pagination;
    pagination.current = 1;
    this.setState({
      type_name: value,
      paginationSwitch: true,
      pagination: pagination,
      loading: false,
    });
    this.state.type_name = value;
    this.getmodelList();
  }
  onsetPosition(value) {
    //console.log(value, 'value')
    let pagination = this.state.pagination;
    pagination.current = 1;
    this.setState({
      group_name: value,
      paginationSwitch: true,
      pagination: pagination,
      loading: false,
    });
    this.state.group_name = value;
    this.getmodelList();
  }
  onPressEnter(value) {
    //console.log(value, 'value')
    let pagination = this.state.pagination;
    pagination.current = 1;
    this.setState({
      pagination: pagination,
      keyword: value,
      paginationSwitch: true,
      loading: true,
    });
    this.state.keyword = value;
    setTimeout(() => {
      this.getmodelList(true);
    }, 500);
  }
  onChange(val) {
    this.setState({
      keyword: val,
    });
  }
  getmodelList(loading) {
    this.setState({
      loading: loading,
    });
    let page = {
      per_page: this.state.pagination.pageSize,
      page: this.state.pagination.current,
      type: this.state.type_name,
      keyword: this.state.keyword,
      from: "list",
      order_by:
        this.state.order_by === "ascend"
          ? "updated_at,asc"
          : this.state.order_by === "descend"
          ? "updated_at,desc"
          : "",
      group_name: this.state.group_name === "全部" ? "" : this.state.group_name,
    };
    getmodelList(page).then((res) => {
      if (res.code === 200) {
        let list = res.data;
        let pagination = this.state.pagination;
        pagination.total = list.count;
        this.setState({
          pagination: pagination,
          data: list.data,
          all_count: list.all_count,
          loading: false,
          paginationSwitch: list.all_count === 0 ? false : true,
          loadingbody: false,
        });
        if (list.count === 0) {
          this.onClose();
        }
      } else {
        this.setState({
          loading: false,
          loadingbody: false,
        });
      }
    });
  }
  onChangeTable(pagination, sorter) {
    const { current, pageSize } = pagination;
    let setPagination = this.state.pagination;
    setPagination.current = current;
    setPagination.pageSize = pageSize;
    this.setState({
      loading: false,
      pagination: setPagination,
      order_by: sorter.direction,
    });
    setTimeout(() => {
      this.getmodelList();
    }, 100);
  }
  godetails(record) {
    history.push("/enginefactory/model/management", {
      record: JSON.stringify(record),
    });
    localStorage.setItem("record", JSON.stringify(record));
    window.location.reload();
  }
  ModelAdd() {
    localStorage.removeItem("props");
    localStorage.removeItem("record");
    this.setState({
      visible: true,
    });
    this.loadData();
  }
  onCancel() {
    this.setState({
      visible: false,
    });
  }
  loadData() {
    this.setState({
      loadingModal: false,
    });
  }
  setVisible() {
    if (this.state.model_capability) {
      history.push("/enginefactory/model/add");
      window.location.reload();
      //console.log(history, 'history')
      localStorage.setItem("props", JSON.stringify(this.state));
      this.onCancel();
    } else {
      Notification.warning({ content: "请选择新建模型能力项！" });
    }
  }
  onRadioGroup(value) {
    //console.log(value, 'onRadioGroup')
    this.setState({
      model_capability: value,
    });
  }
  onDownUp() {
    let Width = this.statusLineRef.current.scrollWidth;
    let lastChildOff = this.statusLineRef.current.lastChild.offsetLeft;
    let scrollLeft = this.statusLineRef.current.scrollLeft;
    let scrolltype = lastChildOff + scrollLeft - 34;
    //console.log(scrolltype, Width, 'Width')
    this.setState({
      openFoot: !this.state.openFoot,
      scrolltype: scrolltype,
      scrollWidth: Width,
    });
  }
  onswiperloading(type, e) {
    //console.log('scrollLeft', this.initRef)
    let Width = this.statusLineRef.current.scrollWidth;
    let lastChildOff = this.statusLineRef.current.lastChild.offsetLeft;
    let lastChildWidth = this.statusLineRef.current.lastChild.clientWidth;

    if (type === 0) {
      this.statusLineRef.current.scrollLeft -= lastChildWidth;
    } else {
      this.statusLineRef.current.scrollLeft += lastChildWidth;
    }
    let scrollLeft = this.statusLineRef.current.scrollLeft;
    let scrolltype = lastChildOff + scrollLeft - 34;
    //console.log(scrolltype, Width, 'offsetLeft', scrollLeft, "clientWidth:" + this.statusLineRef.current.clientWidth)
    this.setState({
      scrolltype: scrolltype,
      scrollWidth: Width,
      scrollLeft: scrollLeft,
    });
  }
  setSelectedRowKeys(selectedRowKeys) {
    let selectkeys = [];
    this.state.data.forEach((i, index) => {
      //console.log(i.enable_del)
      if (i.enable_del) {
        selectkeys.push(i.id);
      }
    });
    this.setState({
      selectedRowKeys: selectedRowKeys,
      SelectcheckAll:
        selectedRowKeys.length === selectkeys.length ? true : false,
    });
  }
  onChangeAll(checked) {
    let selectkeys = [];
    if (checked) {
      this.state.data.forEach((i, index) => {
        //console.log(i.enable_del)
        if (i.enable_del) {
          selectkeys.push(i.id);
        }
      });
      this.setState({
        indeterminate: false,
        SelectcheckAll: true,
        selectedRowKeys: selectkeys,
      });
    } else {
      this.setState({
        indeterminate: false,
        SelectcheckAll: false,
        selectedRowKeys: selectkeys,
      });
    }
    //console.log(selectkeys, 'selectedRowKeys')
  }
  setVisibledelete(value) {
    this.setState({
      visibledelete: value,
    });
  }
  deletemodel() {
    //console.log(this.state.selectedRowKeys, 'selectedRowKeysselectedRowKeys')
    this.setState({
      confirmLoading: true,
    });
    let datas = { ids: JSON.stringify(this.state.selectedRowKeys) };
    deletemodel(datas).then((res) => {
      if (res.code === 200) {
        let pagination = this.state.pagination;
        pagination.current = 1;
        this.setState({
          selectedRowKeys: [],
          SelectcheckAll: false,
          confirmLoading: false,
          pagination: pagination,
        });
        this.setVisibledelete(false);
        this.getmodelList(true);
      } else {
        this.setState({
          confirmLoading: false,
        });
        Notification.error({ content: res.msg });
      }
    });
  }
  onSettings() {
    this.setState({
      checkbox: true,
    });
  }
  onClose() {
    this.setState({
      checkbox: false,
      SelectcheckAll: false,
      selectedRowKeys: [],
    });
  }
  render() {
    const {
      all_count,
      SelectoptionsModel,
      loadingbody,
      confirmLoading,
      visibledelete,
      indeterminate,
      SelectcheckAll,
      checkbox,
      checkAll,
      type,
      selectedRowKeys,
      columns,
      paginationSwitch,
      type_name,
      positionList,
      loading,
      loadingModal,
      data,
      pagination,
      no_data,
      visible,
      productlist,
      group_name,
      model_capability,
    } = this.state;
    return (
      <div className="Model">
        <div className="Header-init">
          <div className="Header-title">我的模型</div>
        </div>
        {loadingbody ? (
          <Spin loading={loadingbody} dot className="loadingbody"></Spin>
        ) : (
          <>
            {all_count === 0 && !paginationSwitch ? (
              <Alert
                showIcon={false}
                closable
                type="info"
                content={<img src={alertimg} className="alertimg" alt="" />}
                style={{ marginTop: 22 }}
                className="custom-alert"
              />
            ) : null}

            {all_count > 0 || paginationSwitch ? (
              <div className="GroupTable-fixe">
                {checkbox ? (
                  <>
                    <div className="custom-checkAll">
                      <Checkbox
                        onChange={this.onChangeAll.bind(this)}
                        checked={SelectcheckAll}
                        indeterminate={indeterminate}
                      >
                        {SelectcheckAll ? "取消" : "全选"}
                      </Checkbox>
                    </div>
                    <Space size={"medium"}>
                      <Button
                        shape="circle"
                        className="custom-White-button"
                        disabled={selectedRowKeys.length === 0}
                        icon={<IconDelete />}
                        onClick={() => this.setVisibledelete(true)}
                      />
                      <Button
                        shape="circle"
                        className="custom-White-button"
                        icon={<IconClose />}
                        onClick={this.onClose.bind(this)}
                      />
                    </Space>
                  </>
                ) : (
                  <>
                    <Radio.Group
                      type="button"
                      name="direction"
                      value={group_name}
                      onChange={this.onsetPosition.bind(this)}
                      options={productlist}
                      className="GroupTable"
                    ></Radio.Group>
                    <div>
                      <Space size={"medium"}>
                        <Input.Search
                          style={{ width: 350 }}
                          // prefix={<IconSearch />}
                          placeholder="快速搜索你的模型"
                          className="GroupTable-Search"
                          onChange={this.onChange.bind(this)}
                          // onPressEnter={this.onPressEnter.bind(this)}
                          onSearch={this.onPressEnter.bind(this)}
                        />
                        {type_name === "1" ? (
                          <Button
                            type="primary"
                            className="Header-init-btn"
                            style={{ borderRadius: 10 }}
                            onClick={this.ModelAdd.bind(this)}
                          >
                            创建模型
                          </Button>
                        ) : (
                          ""
                        )}
                        <Button
                          shape="circle"
                          className="custom-White-button"
                          icon={<IconSettings />}
                          onClick={this.onSettings.bind(this)}
                        />
                      </Space>
                    </div>
                  </>
                )}
              </div>
            ) : (
              ""
            )}
            {all_count === 0 && !loadingbody ? (
              <Empty
                style={{ marginTop: 93 }}
                imgSrc={Emptydata_img}
                description={
                  <div className="Emptydata">
                    <Typography>暂时没有模型</Typography>
                    <Typography>请点击按钮开始创建你的第一个模型</Typography>
                    <Button
                      type="primary"
                      onClick={this.ModelAdd.bind(this)}
                      style={{
                        width: 180,
                        borderRadius: 14,
                        height: 48,
                        margin: "34px 0px",
                      }}
                    >
                      <IconPlus />
                      创建模型
                    </Button>
                  </div>
                }
              />
            ) : (
              ""
            )}
            {loading ? (
              <Spin dot className="table-spin-loading" />
            ) : (
              <>
                {all_count > 0 ? (
                  <>
                    {pagination.total === 0 ? (
                      <Empty
                        imgSrc={Emptydata_img}
                        description={"暂无相关模型"}
                        style={{ marginTop: 200 }}
                      />
                    ) : (
                      <div className="table-card">
                        <div className="table-tabs rowStart">
                          {Object.keys(positionList).map((item, index) => {
                            return (
                              <div
                                className={`table-lable ${
                                  item === type_name ? `active` : ``
                                }`}
                                key={index}
                                onClick={this.onTabs.bind(this, item)}
                              >
                                <div
                                  className={`tabs-title ${
                                    item === type_name
                                      ? `AlibabaPuHuiTi-Medium`
                                      : ``
                                  }`}
                                >
                                  {positionList[item]}
                                </div>
                                <div className="table-hr"></div>
                              </div>
                            );
                          })}
                        </div>
                        <Table
                          style={{ marginTop: 10 }}
                          virtualized
                          noDataElement={
                            <Empty
                              imgSrc={Emptydata_img}
                              description={"暂无相关模型"}
                            />
                          }
                          no_data={no_data}
                          border={{ wrapper: false, cell: false }}
                          loading={loading}
                          columns={
                            type_name === "9"
                              ? columns
                              : columns.concat({
                                  title: "操作",
                                  dataIndex: "operation",
                                  render: (col, record, index) => (
                                    <Button
                                      type="outline"
                                      className="outline"
                                      disabled={record.type === 9}
                                      onClick={this.godetails.bind(
                                        this,
                                        record
                                      )}
                                    >
                                      模型管理
                                    </Button>
                                  ),
                                  fixed: "150",
                                  align: "center",
                                })
                          }
                          data={data}
                          pagination={
                            pagination.total === 0 ? false : pagination
                          }
                          onChange={this.onChangeTable.bind(this)}
                          renderPagination={(paginationNode) => (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                marginTop: 10,
                              }}
                            >
                              {paginationNode}
                            </div>
                          )}
                          rowKey="id"
                          rowSelection={
                            checkbox && {
                              type,
                              selectedRowKeys,
                              checkAll: checkAll,
                              onChange: (selectedRowKeys, selectedRows) => {
                                //console.log('onChange:', selectedRowKeys, selectedRows);
                                this.setSelectedRowKeys(selectedRowKeys);
                              },
                              onSelect: (selected, record, selectedRows) => {
                                //console.log('onSelect:', selected, record, selectedRows)
                              },
                              checkboxProps: (record) => {
                                return {
                                  disabled: !record.enable_del,
                                };
                              },
                            }
                          }
                        />
                      </div>
                    )}
                  </>
                ) : (
                  ""
                )}
              </>
            )}
            {/* {all_count === 0 && footpagination.total > 0 && isShow ? (
            <div className="myapplist-footBox">
              <div className={`myapplist-foot ${openFoot ? 'Down-open' : ''}`}>
                <Space align="center">
                  <Typography.Title heading={5} style={{ fontSize: 18 }}>标准模型</Typography.Title>
                </Space>
                <div className="swiper-list">
                  <div className="swiper-wrap" ref={this.statusLineRef}>
                    {swiperList.map((item, i) => (
                      <div className="swiper-item" key={i}>
                        <div className="state-fix">
                          <Typography.Title heading={5} className="swiper-Title">{Selectoptions[item.model_capability].label} - {item.type_name}</Typography.Title>
                          <Tag color={Selectoptions[item.model_capability].group === '智能写作' ? "#00d097" : Selectoptions[item.model_capability].group === '智能图文' ? "#FE5454" : Selectoptions[item.model_capability].group === '智能文档' ? "#2A7DFF" : Selectoptions[item.model_capability].group === '智能创作' ? "#935AFE" : Selectoptions[item.model_capability].group === '智能创作' ? "#12cbda" : ''}>{Selectoptions[item.model_capability].group}</Tag>
                        </div>
                        <Typography className="swiper-label">{Selectoptions[item.model_capability].title}</Typography>
                        <Button type='outline' className="swiper-myadd">立即体验</Button>
                      </div>
                    ))
                    }
                  </div>
                </div>
                <div className="Down-UP" onClick={this.onDownUp.bind(this)}>
                  {
                    openFoot ? (<IconDown />) : (<IconUp />)
                  }
                </div>
              </div>
              {scrollLeft > 0 && openFoot ? <Button shape='circle' className='circleloding left' icon={<IconLeft />} onClick={this.onswiperloading.bind(this, 0)} /> : ''}
              {(footpagination.total > 4 && scrolltype < scrollWidth) && openFoot ? (
                <Button shape='circle' className='circleloding' icon={<IconRight />} loading={swiperloading} onClick={this.onswiperloading.bind(this, 1)} />
              ) : null}
            </div>) : null} */}
          </>
        )}
        <Modal
          title="请选择模型能力类型"
          visible={visible}
          onOk={() => this.setVisible()}
          onCancel={() => this.onCancel()}
          className="addModal"
        >
          <Spin tip="正在加载数据" loading={loadingModal}>
            <div style={{ visibility: !loadingModal ? "visible" : "hidden" }}>
              <div className="Modaladd-title">能力类型</div>
              <Radio.Group
                onChange={this.onRadioGroup.bind(this)}
                className="custom-Group"
              >
                {Object.keys(SelectoptionsModel ? SelectoptionsModel : []).map(
                  (item, i) => {
                    return SelectoptionsModel[item].status !== 2 ? (
                      <Radio
                        key={item}
                        value={item}
                        disabled={
                          SelectoptionsModel[item].status === 0 ? true : false
                        }
                      >
                        <Space
                          align="start"
                          className={`custom-radio-card ${
                            model_capability === item
                              ? "custom-radio-card-checked"
                              : ""
                          }`}
                        >
                          {SelectoptionsModel[item].status === 0 ? (
                            <div className="custom-radio-tag-disabled"></div>
                          ) : (
                            ""
                          )}
                          <div className="custom-radio-card-mask">
                            <div className="custom-radio-card-mask-dot"></div>
                          </div>
                          <div>
                            <Typography.Title
                              heading={6}
                              ellipsis={{
                                showTooltip: false,
                                expandable: false,
                              }}
                              className="custom-radio-card-title"
                            >
                              {SelectoptionsModel[item].label}
                            </Typography.Title>
                            <Typography.Text
                              type="secondary"
                              ellipsis={{
                                rows: 1,
                                showTooltip: false,
                                expandable: false,
                              }}
                            >
                              {SelectoptionsModel[item].title}
                            </Typography.Text>
                          </div>
                        </Space>
                      </Radio>
                    ) : (
                      ""
                    );
                  }
                )}
              </Radio.Group>
            </div>
          </Spin>
        </Modal>
        <Modal
          style={{
            width: 472,
          }}
          className="deleteModal"
          title="设置提示"
          visible={visibledelete}
          okText="确认"
          cancelText="取消"
          confirmLoading={confirmLoading}
          onOk={this.deletemodel.bind(this)}
          onCancel={() => this.setVisibledelete(false)}
        >
          <p>是否确认删除选中内容？</p>
        </Modal>
      </div>
    );
  }
}

export default Model;
