import React, { Component } from "react";
import { Modal, Typography, Space, Avatar } from "@arco-design/web-react";
import { IconUser, IconRight } from "@arco-design/web-react/icon";
import JQ from "jquery";
import "./index.scss";

class IdentitySelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: props.visible,
    };
  }
  componentDidMount() {
    let leftwidth = JQ(".IdentitySelection-left").width();
    let rightwidth = JQ(".IdentitySelection-right").width();
    JQ(".IdentitySelection-left").css(
      "transform",
      "translateX(-" + leftwidth + "px)"
    );
    JQ(".IdentitySelection-right").css(
      "transform",
      "translateX(" + rightwidth + "px)"
    );
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ visible: nextProps.visible });
  }

  render() {
    const { visible } = this.state;
    return (
      <div className="IdentitySelection">
        <div className="IdentitySelection-left move_right">
          <div className="yoo_logo"></div>
          <div className="IdentitySelection-left-describe">
            <div className="IdentitySelection-left-h1">韦尼克智能创作引擎</div>
            <div className="IdentitySelection-left-text">
              - 让内容创作更简单 -
            </div>
          </div>
        </div>
        <div className="IdentitySelection-right columnCenter move_left">
          <div className="IdentitySelection-list-title">选择你要登录的账户</div>
          <Space direction="vertical" className="IdentitySelection-list">
            <div className="IdentitySelection-item arco-card-hoverable card-custom-hover-style rowSpaceBetween">
              <Avatar style={{ backgroundColor: "#3370ff" }}>
                <IconUser />
              </Avatar>
              <div>
                <div className="company-name AlibabaPuHuiTi-Medium">
                  珠海九松健康有限公司
                </div>
                <div className="user-name">客服部小九</div>
              </div>
              <IconRight />
            </div>
            <div className="IdentitySelection-item arco-card-hoverable card-custom-hover-style rowSpaceBetween">
              <Avatar style={{ backgroundColor: "#3370ff" }}>
                <img
                  alt="avatar"
                  src="//p1-arco.byteimg.com/tos-cn-i-uwbnlip3yd/3ee5f13fb09879ecb5185e440cef6eb9.png~tplv-uwbnlip3yd-webp.webp"
                />
              </Avatar>
              <div>
                <div className="company-name AlibabaPuHuiTi-Medium">
                  珠海九松健康有限公司
                </div>
                <div className="user-name">客服部小九</div>
              </div>
              <IconRight />
            </div>
          </Space>
        </div>
      </div>
    );
  }
}

export default IdentitySelection;
