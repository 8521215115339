import React, { Component } from "react";
import {
  Table,
  Card,
  Typography,
  Button,
  Divider,
  Select,
  Message,
  Progress,
  Space,
  Breadcrumb,
} from "@arco-design/web-react";
import { Link } from "react-router-dom";
import routes from "../../router/index";
import "./index.scss";

class SaasBreadcrumb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathSnippets: null,
      extraBreadcrumbItems: null,
      listMapUrl: [],
    };
  }
  componentDidMount() {}
  getPath = (pathname) => {
    let breadcrumbNameMap = this.arrtojson();
    //对路径进行切分，存放到this.state.pathSnippets中
    //console.log(pathname, 'getPathgetPath')
    this.state.pathSnippets = pathname.split("/").filter((i) => i);
    // let arr=this.state.pathSnippets;
    // let pathname=this.context.router.history.location.pathname;
    //将切分的路径读出来，形成面包屑，存放到this.state.extraBreadcrumbItems
    this.state.extraBreadcrumbItems = this.state.pathSnippets
      .map((_, index) => {
        let url = `/${this.state.pathSnippets.slice(0, index + 1).join("/")}`;
        if (breadcrumbNameMap[url]) {
          return (
            <Breadcrumb.Item key={url}>
              {url === pathname ? (
                breadcrumbNameMap[url]
              ) : (
                <Link to={url}>{breadcrumbNameMap[url]}</Link>
              )}
            </Breadcrumb.Item>
          );
        }
      })
      .filter((i) => i);
  };
  arrtojson() {
    this.formap(routes[0].children);
    let obj = {};
    this.state.listMapUrl.map((item, index) => {
      obj[item.path] = item.title;
      return obj;
    });
    return obj;
  }
  formap(list) {
    list.map((pre, item) => {
      if (!pre.children) {
        this.state.listMapUrl.push({ path: pre.path, title: pre.title });
      } else {
        this.formap(pre.children);
      }
    });
  }

  componentWillUnmount() {
    clearInterval(this.state.timeout);
  }
  UNSAFE_componentWillMount() {
    const urlParams = new URL(window.location.href);
    const pathname = urlParams?.pathname;
    this.getPath(pathname);
  }
  render() {
    return (
      <Card bordered={false} style={{ marginBottom: 18 }} id="breadcrumb_id">
        <Breadcrumb>{this.state.extraBreadcrumbItems}</Breadcrumb>
      </Card>
    );
  }
}

export default SaasBreadcrumb;
