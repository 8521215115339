import React, { Component } from 'react'
import {
  Avatar,
  Typography,
  Button,
  Space,
  Input,
  Select,
  Divider,
  Notification,
} from '@arco-design/web-react'
import { IconPlus } from '@arco-design/web-react/icon'
import user_head from 'asserts/user_head.svg'
import Xiaoyou_img from 'asserts/Xiaoyou_img.svg'
import {
  getApiInfo,
  getRequest,
  putmodelversion,
  putapp,
  check_resource,
} from 'api/apis'
import history from 'common/utils/history'
import { CreateLink } from 'common/utils/business'
import { getNewCurrentDate, debounce } from 'common/utils'
import UpgradeTips from 'components/UpgradeTips'
import './question.scss'
const Option = Select.Option
const TextArea = Input.TextArea
// 封装组件
class Question extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userinfo: JSON.parse(localStorage.getItem('userinfo')),
      Selectoptions: JSON.parse(localStorage.getItem('Selectoptions')),
      no_data: false,
      TextAreaValue: '',
      ParagraphContinuation: [],
      ContinuationLoding: false,
      ContinuationLodingTime: '',
      record: JSON.parse(localStorage.getItem('record')),
      apiInfo: {
        model_id: '',
      },
      ChatContent: [],
      optionVlue: '',
      versions: [],
      input_describe: '输入并发送你想要咨询的问题',
      visibleUpgrade: false,
      upgradetips: null,
      identity: JSON.parse(localStorage.getItem('userinfo'))
        ? JSON.parse(localStorage.getItem('userinfo')).group_permission
        : '',
    }
    this.messagesEnd = React.createRef()
    this.isputapp = debounce(this.isputapp, 1000)
  }
  componentDidMount() {
    this.getApiInfo()
    if (localStorage.getItem('ChatContent')) {
      this.setState({
        ChatContent: JSON.parse(localStorage.getItem('ChatContent')),
      })
    }
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  }
  UNSAFE_componentWillMount() {
    localStorage.removeItem('ChatContent')
  }
  componentWillUnmount() {
    localStorage.removeItem('props')
    window.removeEventListener('resize', this.handleResize)
  }
  handleResize = () => {
    var winHeight = 0
    if (window.innerHeight) {
      winHeight = window.innerHeight
    } else if (document.body && document.body.clientHeight) {
      winHeight = document.body.clientHeight
    }
    if (document.documentElement && document.documentElement.clientHeight) {
      winHeight = document.documentElement.clientHeight
    }

    setTimeout(() => {
      let layoutHeaders_h =
        document.getElementById('layoutHeaders').clientHeight
      if (layoutHeaders_h > winHeight) {
        layoutHeaders_h = 64
      }
      let height =
        winHeight -
        layoutHeaders_h -
        document.getElementById('renderHeaders').clientHeight -
        32 -
        45
      document.getElementById('result').style.height = height + 'px'

      if (document.getElementById('continuation')) {
        let height2 =
          height -
          document.getElementById('result_title').clientHeight -
          document.getElementById('continuation_input').clientHeight -
          34 -
          15
        document.getElementById('continuation').style.height =
          height2 - 30 + 'px'
      }
    }, 100)
  }
  getApiInfo(isdel) {
    let params = {
      app_id: this.state.record.id,
    }
    getApiInfo(params).then((res) => {
      if (res.code === 200) {
        let list = res.data
        if (res.data.model_params) {
          history.push('/myapp/enteringGinseng')
          history.go()
        } else {
          if (isdel) {
            this.setState({
              apiInfo: list,
            })
          } else {
            this.setState({
              apiInfo: list,
              versions: list.versions,
            })
            // document.title = list.app_name
            if (list.versions.length > 0) {
              this.setState({
                optionVlue: list.versions[0].id,
              })
              this.putmodelversion(list.versions[0].id)
            }
            let Chatarry = [
              '你好，欢迎使用韦尼克智能问答引擎，你可以向我提「xxxx」相关的问题',
              '我是尼克，可以为你解答「xxxx」的相关问题',
              '我是尼克，你的智能问答助手，你可以问我「xxxx」的相关问题',
              'Hi~我是尼克，有「xxxx」相关的问题，可以问我哦～',
            ]
            let Chatcontent = this.getRandomArrayElements(Chatarry)
            setTimeout(() => {
              let Chat = {
                type: 0,
                time: getNewCurrentDate('.', ':', new Date().getTime()),
                content: Chatcontent,
              }
              let lists = this.state.ChatContent.concat(Chat)
              this.setState({
                ChatContent: lists,
              })
            }, 300)
            this.scrollToBottom()
            if ((list.type === 0 || list.type === 9) && list.input_describe) {
              this.setState({
                TextAreaValue: list.input_describe,
              })
              setTimeout(() => {
                this.onPressEnter()
              }, 1000)
            }
          }
        }
        //console.log(res.data, "api服务");
      }
    })
  }
  // 随机取数组里的一个值
  getRandomArrayElements(arr) {
    if (arr.length < 1) {
      return ''
    }
    let result = [] //定义一个数组用于保存结果
    //打乱数组
    arr.sort(function () {
      return 0.5 - Math.random()
    })
    //取出前面num个数据
    for (let i = 0; i < arr.length; i++) {
      result[i] = arr[i]
    }
    // 随机0到i的整数
    let index = Math.floor(Math.random() * result.length)
    return result[index]
  }
  Onreplace(newstr) {
    let reg = '「xxxx」'
    let html = `「<span class='Preamble AlibabaPuHuiTi-Medium'>${this.state.apiInfo.app_name}</span>」`
    newstr = newstr.replace(reg, html)
    let htmls = { __html: newstr }
    return <div dangerouslySetInnerHTML={htmls}></div>
  }

  putmodelversion(optionVlue) {
    let params = {
      id: optionVlue,
    }
    putmodelversion(params).then((res) => {
      if (res.code === 200) {
        // let list = res.data
      } else {
        this.setState({
          ContinuationLoding: false,
        })
        Notification.warning({ content: '模型版本激活失败！' })
      }
    })
  }
  onsetPosition(value) {
    this.setState({
      optionVlue: value,
    })
    this.putmodelversion(value)
  }
  onTextArea(value) {
    this.setState({
      TextAreaValue: value,
    })
  }
  onKeyDown(event) {
    //console.log(event.keyCode, "键盘事件");
    if (event.shiftKey && event.keyCode === 13) {
      let e = this.state.TextAreaValue
      this.setState({
        TextAreaValue: e,
      })
    } else if (event.ctrlKey && event.keyCode === 13) {
      event.cancelBubble = true
      event.preventDefault()
      event.stopPropagation()
      event.returnValue = false
      this.onPressEnter(event)
      return false
    } else if (event.keyCode === 13) {
      event.cancelBubble = true
      event.preventDefault()
      event.stopPropagation()
      this.onPressEnter(event)
      event.returnValue = false
      return false
    }
  }
  onPressEnter(e) {
    const { ChatContent, TextAreaValue } = this.state
    if (e) {
      e.nativeEvent.stopImmediatePropagation()
      if (e.target.value.length === 0) {
        setTimeout(() => {
          this.setState({
            TextAreaValue: '',
          })
        }, 100)
        Notification.warning({ content: '询问的问题不能为空!' })
        return
      } else if (e.target.value.length < 2 || e.target.value.length > 200) {
        setTimeout(() => {
          this.setState({
            TextAreaValue: e.target.value.replace(/[^\u4e00-\u9fa5\w]/g, ''),
          })
        }, 100)
        Notification.warning({
          content: '提问内容 必须在 2 到 200 个字符之间!',
        })
        return
      }
    }
    let userChat = {
      type: 1,
      content: e
        ? e.target.value.replaceAll('\n', ' <br>')
        : TextAreaValue.replaceAll('\n', ' <br>'),
    }
    let list = ChatContent.concat(userChat)
    //console.log(list, "onPressEnter");
    this.setState({
      ChatContent: list,
    })
    setTimeout(() => {
      this.setState({
        ChatContent: list,
        TextAreaValue: '',
      })
      this.scrollToBottom()
    }, 100)
    this.isPhoneLegal(e ? e.target.value : TextAreaValue)
    localStorage.setItem('ChatContent', JSON.stringify(list))
  }
  isPhoneLegal = (value) => {
    this.state.ContinuationLodingTime = setTimeout(() => {
      this.setState({
        ContinuationLoding: true,
      })
    }, 400)
    this.setRequest(value)
  }
  setRequest(value) {
    let that = this
    let params = {
      app_id: this.state.record.id,
      text: value,
    }
    if (this.state.optionVlue) {
      params.version_id = this.state.optionVlue
    }
    this.getCheckResource(function (result) {
      if (result.code === 200) {
        getRequest(params).then((res) => {
          if (res.code === 200) {
            let list = res.data
            let Chatarry = [
              '抱歉，我没理解，请换个方式问我',
              '你问的问题我暂时回答不了，你可以试试别的问题',
              '我想我要再学习一下',
            ]
            let Chatcontent = that.getRandomArrayElements(Chatarry)
            let answer = list.answer
            if (answer instanceof Array) {
              answer = list.answer[0]
            }
            let Chat = {
              type: 0,
              content: answer ? answer : Chatcontent,
              time: getNewCurrentDate('.', ':', new Date().getTime()),
            }
            //console.log(list);
            clearTimeout(that.state.ContinuationLodingTime)
            that.setState({
              ChatContent: that.state.ChatContent.concat(Chat),
              ContinuationLoding: false,
            })
            that.scrollToBottom()
            localStorage.setItem(
              'ChatContent',
              JSON.stringify(that.state.ChatContent.concat(Chat))
            )
            //console.log(res.data, "结果");
          } else if (res.code === 3003) {
            let Chat = {
              type: 0,
              content: res.msg,
              time: getNewCurrentDate('.', ':', new Date().getTime()),
            }
            that.setState({
              ChatContent: that.state.ChatContent.concat(Chat),
              ContinuationLoding: false,
            })
            clearTimeout(that.state.ContinuationLodingTime)
            that.scrollToBottom()
            localStorage.setItem(
              'ChatContent',
              JSON.stringify(that.state.ChatContent.concat(Chat))
            )
          } else {
            let Chatarry = [
              '抱歉，系统出现了故障，请联系开发人员解决',
              '抱歉，服务器繁忙，请稍后再试',
            ]
            let Chatcontent = that.getRandomArrayElements(Chatarry)
            let Chat = {
              type: 0,
              content: Chatcontent,
              time: getNewCurrentDate('.', ':', new Date().getTime()),
            }
            clearTimeout(that.state.ContinuationLodingTime)
            that.setState({
              ChatContent: that.state.ChatContent.concat(Chat),
              ContinuationLoding: false,
            })
            that.scrollToBottom()
            localStorage.setItem(
              'ChatContent',
              JSON.stringify(that.state.ChatContent.concat(Chat))
            )
          }
        })
      } else {
        let Chatarry = [
          '抱歉，系统出现了故障，请联系开发人员解决',
          '抱歉，服务器繁忙，请稍后再试',
        ]
        let Chatcontent = that.getRandomArrayElements(Chatarry)
        let Chat = {
          type: 0,
          content: Chatcontent,
          time: getNewCurrentDate('.', ':', new Date().getTime()),
        }
        clearTimeout(that.state.ContinuationLodingTime)
        that.setState({
          ChatContent: that.state.ChatContent.concat(Chat),
          ContinuationLoding: false,
        })
        that.scrollToBottom()
        localStorage.setItem(
          'ChatContent',
          JSON.stringify(that.state.ChatContent.concat(Chat))
        )
      }
    })
  }
  getCheckResource(callback) {
    let params = { model_capability: this.state.apiInfo?.model_capability }
    let PACKAGES = ['PACKAGE_FINISH', 'PACKAGE_EXPIRED']
    check_resource(params).then((res) => {
      if (res.code === 200) {
        if (res.data) {
          let new_upgradetips = res.data
          new_upgradetips.model_capability = params.model_capability
          this.setState({
            upgradetips: new_upgradetips,
          })
          if (PACKAGES[new_upgradetips.notice_type]) {
            Notification.warning({ content: new_upgradetips.message })
          } else {
            setTimeout(() => {
              this.setState({
                visibleUpgrade: true,
              })
            }, 100)
          }
        }
        callback(res)
      } else {
        callback(res)
      }
    })
  }
  scrollToBottom() {
    if (this.messagesEnd) {
      const scrollHeight = this.messagesEnd.scrollHeight //里面div的实际高度  2000px
      const height = this.messagesEnd.clientHeight //网页可见高度  200px
      const maxScrollTop = scrollHeight - height
      this.messagesEnd.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0
      //如果实际高度大于可见高度，说明是有滚动条的，则直接把网页被卷去的高度设置为两个div的高度差，实际效果就是滚动到底部了。
    }
  }
  // goResources() {
  //   history.push('/myapp/resources')
  //   localStorage.setItem('record', JSON.stringify(this.state.record))
  //   window.location.reload()
  // }
  goInterfacetest() {
    history.push('/myapp/interfacetest')
    localStorage.setItem('record', JSON.stringify(this.state.record))
    window.location.reload()
  }
  isputapp = (name, desc) => {
    this.putapp(name, desc)
  }
  putapp(name, desc) {
    let params = {
      id: this.state.record.id,
    }
    if (desc !== false) {
      params.desc = desc
    } else if (name !== false) {
      params.name = name
    }
    putapp(params).then((res) => {
      if (res.code === 200) {
      } else {
        this.getApiInfo(true)
        Notification.error({ content: res.msg })
      }
    })
  }
  onChangetitle(type, value) {
    //console.log(value, "value");
    let apiInfo = this.state.apiInfo
    if (type === 'desc') {
      if (value.length > 100) {
        apiInfo.app_desc = value.substring(0, 100)
        Notification.warning({ content: '修改内容超过100个字！' })
        return
      }
      apiInfo.app_desc = value
      this.setState({
        apiInfo: apiInfo,
      })
      this.isputapp(false, value)
    } else if (type === 'name') {
      if (value.length > 20) {
        apiInfo.app_name = value.substring(0, 20)
        Notification.warning({ content: '修改标题超过20个字！' })
        return
      }
      apiInfo.app_name = value
      this.setState({
        apiInfo: apiInfo,
      })
      this.isputapp(value, false)
    }
  }
  goaddApp() {
    history.replace('/myapp/addmyapp')
    let state = {
      model_capability: this.state.apiInfo.model_capability,
      checkedtype: this.state.apiInfo.model_id,
      model_id: this.state.apiInfo.model_id,
      name: this.state.apiInfo.model_name,
    }
    localStorage.setItem('props', JSON.stringify(state))
    localStorage.removeItem('record')
    window.location.reload()
  }
  addRow() {
    localStorage.removeItem('props')
    localStorage.setItem('record', JSON.stringify(this.state.apiInfo))
    history.replace('/enginefactory/model/management/add')
    window.location.reload()
  }
  setvisibleUpgrade(value) {
    this.setState({
      visibleUpgrade: value,
    })
  }
  render() {
    const {
      visibleUpgrade,
      upgradetips,
      apiInfo,
      optionVlue,
      versions,
      input_describe,
      ContinuationLoding,
      ChatContent,
      userinfo,
      TextAreaValue,
    } = this.state
    return (
      <div className="question">
        <div id="renderHeaders">
          <div className="GroupTable-fixe GroupTable-fixe-end">
            <div className="edit-title">
              <div className="Header-init">
                <div className="Header-title">
                  <Typography.Paragraph
                    className="Header-uptitle"
                    editable={
                      apiInfo.type === 0
                        ? null
                        : {
                            onChange: this.onChangetitle.bind(this, 'name'),
                          }
                    }
                  >
                    {apiInfo.app_name}
                  </Typography.Paragraph>
                </div>
              </div>
              <div className="Header-upParagraph">
                <Typography.Paragraph
                  className="Header-uptitle"
                  editable={
                    apiInfo.type === 0
                      ? null
                      : {
                          onChange: this.onChangetitle.bind(this, 'desc'),
                        }
                  }
                >
                  {apiInfo.app_desc}
                </Typography.Paragraph>
              </div>
            </div>
            <Space size={'medium'} align="center">
              {apiInfo.type === 0 ? (
                <Button
                  className="primarybtn custom-White-button"
                  onClick={this.goaddApp.bind(this)}
                >
                  保存应用
                </Button>
              ) : (
                <>
                  {versions.length === 0 ? (
                    <div></div>
                  ) : (
                    <Select
                      value={optionVlue}
                      placeholder="版本选择"
                      style={{ width: 220 }}
                      className="versionsSelect"
                      onChange={this.onsetPosition.bind(this)}
                      dropdownRender={(menu) => (
                        <div>
                          {menu}
                          <Divider
                            style={{
                              margin: 0,
                              marginTop: 6,
                              borderColor: '#f2f2f2',
                            }}
                          />
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              padding: '5px 0px',
                            }}
                          >
                            <Button
                              style={{
                                width: '100%',
                                height: 41,
                                fontSize: 14,
                                padding: '0 6px',
                                borderRadius: 10,
                              }}
                              type="text"
                              onClick={this.addRow.bind(this)}
                            >
                              <IconPlus />
                              新增模型版本
                            </Button>
                          </div>
                        </div>
                      )}
                    >
                      {versions.map((option, index) => (
                        <Option key={index} value={option.id}>
                          {apiInfo.model_name}V{option.version}
                        </Option>
                      ))}
                    </Select>
                  )}
                  {apiInfo.type === 9 ? (
                    <div className="primary_model_name">
                      <div className="primary_model_name_fix">
                        <span className="primary_model_name_title">
                          模型
                          <Divider type="vertical" />
                        </span>
                        <Typography.Paragraph ellipsis>
                          {apiInfo.model_name}
                        </Typography.Paragraph>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {/* <Button
                    shape="circle"
                    className="custom-White-button"
                    onClick={this.goResources.bind(this)}
                  >
                    应用资源
                  </Button> */}
                  <Button
                    shape="circle"
                    className="custom-White-button share_button_wnk"
                    onClick={CreateLink.bind(this, apiInfo)}
                  >
                    分享应用
                  </Button>
                  <Button
                    style={{
                      display:
                        this.state.identity &&
                        this.state.identity !== 'PERSONAL'
                          ? 'inline-block'
                          : 'none',
                    }}
                    shape="circle"
                    className="custom-White-button"
                    onClick={this.goInterfacetest.bind(this)}
                  >
                    接口调用
                  </Button>
                </>
              )}
            </Space>
          </div>
        </div>
        <div className="experience-result" id="result">
          <div id="result_title">
            <div className="rowSpaceBetween question-title">
              <Typography.Title heading={6}>韦尼克智能QA</Typography.Title>
              <Typography.Paragraph className="question-remarks">
                (内容基于韦尼克AI创作引擎生成，仅供参考)
              </Typography.Paragraph>
            </div>
            <Divider />
          </div>
          <div className="Paragraph-generate">
            <div
              className="AC-frame"
              ref={(el) => {
                this.messagesEnd = el
              }}
              id="continuation"
            >
              {ChatContent.length > 0 &&
                ChatContent.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`AC-frame-item ${
                        item.type === 0 ? `Xiaoyou` : `user-left`
                      }`}
                    >
                      {item.type === 0 ? (
                        <div className="information rowFlexStartStart">
                          <Avatar size={60}>
                            <img
                              alt="avatar"
                              src={Xiaoyou_img}
                              className="head-portrait"
                            />
                          </Avatar>

                          <div className="information-content">
                            <div className="rowFlexStartEnd">
                              <span className="information-name">韦尼克</span>
                              <span className="information-time">
                                {item.time}
                              </span>
                            </div>
                            <div className={`custom-Steam-drum`}>
                              <div className="Steam-drum">
                                {this.Onreplace(item.content)}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="information rowFlexEndStart">
                          <div className="information-content">
                            <div
                              className={`custom-Steam-drum rowFlexEndStart`}
                            >
                              <div
                                className="Steam-drum"
                                dangerouslySetInnerHTML={{
                                  __html: item.content,
                                }}
                              ></div>
                            </div>
                          </div>
                          <Avatar size={60}>
                            <img
                              alt="avatar"
                              src={
                                userinfo.avatar ? userinfo.avatar : user_head
                              }
                              className="head-portrait"
                            />
                          </Avatar>
                        </div>
                      )}
                    </div>
                  )
                })}
            </div>

            <div className="Continuation-input" id="continuation_input">
              {ContinuationLoding ? (
                <div className="loding-text dot">
                  韦尼克正在生成回答<span className="dot-ani"></span>
                </div>
              ) : (
                ''
              )}
              <TextArea
                id="textArea_send"
                value={TextAreaValue}
                placeholder={input_describe}
                onChange={this.onTextArea.bind(this)}
                // onPressEnter={this.onPressEnter.bind(this)}
                onKeyDown={(event) => this.onKeyDown(event)}
              />
            </div>
          </div>
        </div>
        <UpgradeTips
          visible={visibleUpgrade}
          notification_status={upgradetips?.notice_type}
          notification_message={upgradetips?.message}
          model_capability={upgradetips?.model_capability}
          onCancel={() => this.setvisibleUpgrade(false)}
        ></UpgradeTips>
      </div>
    )
  }
}
export default Question
