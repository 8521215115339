import React, { Component } from "react";
// import { Link } from 'react-router-dom'

import ModalSelect from "components/ModalSelect";
import {
  Notification,
  List,
  Modal,
  Spin,
  Typography,
  Button,
  Divider,
  Space,
  Tag,
  Link,
  Checkbox,
  Empty,
  Carousel,
  Progress,
  Avatar,
} from "@arco-design/web-react";
import {
  IconRight,
  IconLeft,
  IconLoading,
  IconCheckCircleFill,
  IconExclamationCircleFill,
  IconMinusCircleFill,
} from "@arco-design/web-react/icon";
import {
  getmodelListVersion,
  getappList,
  allpackage,
  getboard,
  getconfig,
  getuserInfo,
  getmodelList,
  putapp,
  deleteapp,
  getofficialappList,
} from "api/apis";
import { getCurrentDate, Imageinit, getTimeState } from "common/utils";
import BarEcharts from "components/BarEcharts/index";
import OffModel from "components/OffModel/index";
import ModelPopu from "components/ModelPopu/index";
import SvgLine from "components/SvgLine/index";
import history from "common/utils/history";
import icon1 from "asserts/Intelligentwriting_icon.svg";
import icon2 from "asserts/Intelligentgraphic_icon.svg";
import icon3 from "asserts/Smartdocument_icon.svg";
import icon4 from "asserts/Intelligentcreation_icon.svg";
import icon5 from "asserts/knowledge_graph.svg";
import Emptydata_img from "asserts/Emptydata_img.svg";
import model_img from "asserts/model_img.png";
import home_vip_icon from "asserts/home_vip_icon.svg";
import home_vip_icon2 from "asserts/home_vip2_icon.svg";
import home_blue_icon from "asserts/home_blue_icon.svg";
import home_blue2_icon from "asserts/home_blue2_icon.svg";
import home_r_icon from "asserts/home_r_icon.svg";
import home_r_icon2 from "asserts/home_r_icon2.svg";
import home_r_icon3 from "asserts/home_r_icon3.svg";
import "./index.scss";
import JQ from "jquery";
// import { url } from "inspector";

const { Title } = Typography;

// 封装组件

class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingbody: true,
      identity: JSON.parse(localStorage.getItem("userinfo"))
        ? JSON.parse(localStorage.getItem("userinfo")).group_permission
        : "",
      userinfodata: JSON.parse(localStorage.getItem("userinfo"))
        ? JSON.parse(localStorage.getItem("userinfo"))
        : null,
      visibleUpgrade: false,
      modelProgress: [],
      data: [],
      data2: [],
      columns: [
        {
          title: "资源类型",
          dataIndex: "name",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
        {
          title: "API可调用资源数",
          dataIndex: "total",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
        {
          title: "剩余可用资源数",
          dataIndex: "balance",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
        {
          title: "已调用资源数",
          dataIndex: "used",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
        {
          title: "到期时间",
          dataIndex: "expired_at",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
      ],
      columns2: [
        {
          title: "模型名称",
          dataIndex: "model_name",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
        {
          title: "模型ID",
          dataIndex: "model_id",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
        {
          title: "版本号",
          dataIndex: "version",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
        {
          title: "服务选项",
          dataIndex: "model_capability_name",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
        {
          title: "创建时间",
          dataIndex: "submit_time",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          render: (col, record, index) => (
            <span>{getCurrentDate("-", ":", col)}</span>
          ),
        },
        {
          title: "状态",
          dataIndex: "train_status",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          render: (col, record, index) => (
            <span>
              {
                <Tag
                  color={
                    col === "训练成功"
                      ? "green"
                      : col === "训练中"
                      ? "arcoblue"
                      : col === "训练失败"
                      ? "gray"
                      : "orangered"
                  }
                  icon={
                    col === "训练成功" ? (
                      <IconCheckCircleFill style={{ color: "#00B42A" }} />
                    ) : col === "训练中" ? (
                      <IconLoading style={{ color: "#165dff" }} />
                    ) : col === "训练失败" ? (
                      <IconMinusCircleFill style={{ color: "#86909C" }} />
                    ) : (
                      <IconExclamationCircleFill style={{ color: "#ff7d00" }} />
                    )
                  }
                >
                  {col}
                </Tag>
              }
            </span>
          ),
        },
      ],
      listoff: [],
      TablLoading: false,
      TabscrollLoading: false,
      activeTab: "0",
      sceneKey: "",
      group_name: "",
      officialScroll: {
        scrolltype: "right",
        scrolltype1: 0,
        scrollWidth: 0,
        scrollLeft: 0,
        swiperloading: false,
        scrollLoading: "",
      },
      officialListTabl: [],
      officialList: [],
      officialpagination: {
        total: 0,
        pageSize: 9,
        current: 1,
      },
      pagination: {
        sizeCanChange: false,
        showTotal: true,
        total: 0,
        pageSize: 9,
        current: 1,
        pageSizeChangeResetCurrent: false,
        showJumper: false,
        sort: "train_at,desc",
      },
      modelNewScroll: {
        scrolltype: "right",
        scrolltype1: 0,
        scrollWidth: 0,
        scrollLeft: 0,
        swiperloading: false,
        scrollLoading: "",
      },
      packagePagination: {
        sizeCanChange: false,
        showTotal: true,
        total: 0,
        pageSize: 9,
        current: 1,
        pageSizeChangeResetCurrent: false,
        showJumper: false,
      },
      myappList: [],
      myapppagination: {
        total: 0,
        pageSize: 5,
        current: 1,
      },
      loading: false,
      packageLoading: false,
      no_data: false,
      no_package: false,
      isapp: 0,
      dataBarEcharts: {
        x: ["以图配文", "智能文书", "合同编辑", "文段生成", "智能文书"],
        y: [1880, 755, 866, 800, "1000"],
      },
      model_capability: "",
      swiperloading: false,
      scrollLoading: "",
      board: {
        my: { join_day: 0, app_count: 0, model_count: 0, data_count: 0 },
        all: {
          company_count: 0,
          app_count: 0,
          data_count: 0,
          model_success: 0,
        },
      },
      Selectoptions: localStorage.getItem("Selectoptions")
        ? JSON.parse(localStorage.getItem("Selectoptions"))
        : [],
      hoverindex: "",
      scrolltype: "right",
      scrolltype1: 0,
      scrollWidth: 0,
      scrollLeft: 0,
      modelScroll: {
        scrolltype: "right",
        scrolltype1: 0,
        scrollWidth: 0,
        scrollLeft: 0,
        swiperloading: false,
        scrollLoading: "",
      },
      myModelList: [],
      myomdelList: [],
      footpagination: {
        total: 0,
        pageSize: 20,
        current: 1,
      },
      enginelist: [],
      visible: false,
      selectedRowKeys: [],
      visible2: false,
      imageSrc: [],
      styleCss: JSON.parse(localStorage.getItem("productstyle")),
      ModeVisible: false,
      modelTimr: null,
    };
    this.statusLineRef = React.createRef();
    this.modelNewRef = React.createRef();
    this.modelLineRef = React.createRef();
    this.officialRef = React.createRef();
    this.initRef = React.createRef();
  }
  componentDidMount() {
    if (!localStorage.getItem("userinfo")) {
      this.setinfo();
    } else {
      this.gitconfig();
      this.getlist();
      this.getpackage();
      this.getmodelListVersion();
      clearInterval(this.state.modelTimr);
      this.state.modelTimr = setInterval(() => {
        this.getmodelListVersion();
      }, 6000);
      this.getboard();
      this.getmodelList();
      this.getofficialappList(true);
    }
    if (
      JSON.parse(localStorage.getItem("userinfo")) &&
      JSON.parse(localStorage.getItem("userinfo")).group_permission
    ) {
      let identity = JSON.parse(
        localStorage.getItem("userinfo")
      ).group_permission;
      if (identity && identity !== "PERSONAL") {
        let columns2 = this.state.columns2;
        let json = {
          title: "创建者",
          dataIndex: "nickname",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          render: (col, record, index) => (
            <span
              style={{ color: record.is_deleted !== 0 ? "#8d93a1" : "#0c0c0f" }}
            >
              {col}
            </span>
          ),
        };
        this.setState({
          columns2: columns2.concat(json),
          imageSrc: [require("asserts/vip_home_img.png")],
        });
      } else {
        this.setState({
          imageSrc: [require("asserts/user_home_img.png")],
        });
      }
    }
  }
  componentWillUnmount() {
    clearInterval(this.state.modelTimr);
  }
  setvisibleUpgrade() {
    history.push("/privilege");
    history.go();
  }
  goOffdetails(record) {
    if (record.template_name === "COMMON") {
      history.push("/myapp/globalModle");

      localStorage.setItem("record", JSON.stringify(record));
      localStorage.setItem("props", JSON.stringify(this.state));
      window.location.reload();
      return;
    }
    if (record.model_capability === "Interaction") {
      history.push("/myapp/question");
    } else if (record.model_capability === "Continuation") {
      history.push("/myapp/productExperience");
    } else if (record.model_capability === "SemanticPrototype") {
      history.push("/myapp/semanticprototype");
    } else if (record.model_capability === "Ner") {
      history.push("/myapp/entityExtraction");
    } else if (record.model_capability === "SmartResume") {
      history.push("/myapp/resumeDocument");
    } else if (record.model_capability === "textPolish") {
      history.push("/myapp/embroidery");
    } else if (
      record.model_capability === "writing" ||
      record.model_capability === "textsummarization"
    ) {
      history.push("/myapp/writing");
    } else if (record.model_capability === "SemanticWordCloud") {
      history.push("/myapp/wordCloud");
    } else if (record.model_capability === "TextWithPictures") {
      history.push("/myapp/image2text");
    } else if (record.model_capability === "KnowledgeJd") {
      history.push("/myapp/knowledge");
    } else if (record.model_capability === "ResumeEditor") {
      history.push("/myapp/smarteditor");
    } else if (record.model_capability === "Classification") {
      history.push("/myapp/wordClass");
    } else if (record.model_capability === "Correction") {
      history.push("/myapp/wordErrorReset");
    } else if (record.model_capability === "SmartContract") {
      history.push("/myapp/smartContract");
    } else if (record.model_capability === "SentimentAnalysis") {
      history.push("/myapp/emotionClass");
    } else if (record.model_capability === "SensitiveWords") {
      history.push("/myapp/sensitiveWords");
    } else if (record.model_capability === "Similarity") {
      history.push("/myapp/similarity");
    } else if (record.model_capability === "KnowledgeReasoning") {
      history.push("/myapp/knowledgereasoning");
    }
    localStorage.setItem("record", JSON.stringify(record));
    localStorage.setItem("props", JSON.stringify(this.state));
    window.location.reload();
  }
  setVisible2(value) {
    let pagination = this.state.officialpagination;
    pagination.current = 1;
    let currentTab = this.state.listoff[0];
    this.setState({
      visible2: value,
      group_name: value ? currentTab.title : "",
      sceneKey: value ? currentTab.link_key : "",
      activeTab: "0",
      officialListTabl: [],
      officialpagination: pagination,
    });
    setTimeout(() => {
      this.getofficialappList(value ? null : true);
    }, 100);
  }
  setVisibleModel(value) {
    let pagination = this.state.footpagination;
    pagination.current = 1;
    let currentTab = this.state.listoff[0];
    this.setState({
      ModeVisible: value,
      group_name: value ? currentTab.title : "",
      activeTab: "0",
      sceneKey: value ? currentTab.link_key : "",
      myModelList: [],
      footpagination: pagination,
    });
    setTimeout(() => {
      this.getModelPopuList();
    }, 100);
  }
  onClickTab(value) {
    let tablevalue = this.state.listoff[value];
    let pagination = this.state.officialpagination;
    pagination.current = 1;
    this.setState({
      activeTab: value,
      group_name: tablevalue ? tablevalue.title : "",
      officialListTabl: [],
      officialpagination: pagination,
      TabscrollLoading: true,
    });
    this.state.sceneKey = tablevalue ? tablevalue.link_key : "";
    this.state.group_name = tablevalue ? tablevalue.title : "";
    this.getofficialappList();
  }
  fetchData = (currentPage) => {
    let pagination = this.state.officialpagination;
    if (pagination.total === this.state.officialListTabl.length) {
      this.setState({
        TabscrollLoading: "没有更多",
        TablLoading: false,
      });
    } else {
      let pagination = this.state.officialpagination;
      pagination.current = currentPage;
      this.getofficialappList();
    }
  };
  getofficialappList(all, loading) {
    let page = {
      per_page: this.state.officialpagination.pageSize,
      page: this.state.officialpagination.current,
      scene: this.state.sceneKey,
    };
    // if (this.state.group_name) {
    //   page.group_name = this.state.group_name;
    // }
    this.setState({
      TablLoading: true,
    });
    getofficialappList(all ? null : page).then((res) => {
      if (res.code === 200) {
        let list = res.data;
        let pagination = this.state.officialpagination;
        pagination.total = list.count;
        let officialList = [];

        if (list.count !== officialList.length || pagination.current === 1) {
          if (this.state.visible2) {
            officialList = this.state.officialListTabl.concat(list.data);
          } else {
            officialList = list.data;
          }
          if (this.state.visible2) {
            this.setState({
              officialListTabl: officialList,
            });
          } else {
            this.setState({
              officialList: officialList,
            });
          }
          this.setState({
            officialpagination: pagination,
            TablLoading: false,
          });
        } else {
          this.setState({
            TablLoading: false,
            officialpagination: pagination,
          });
        }
        if (officialList.length > 0 && !localStorage.getItem("isSuspension")) {
          localStorage.setItem("isSuspension", true);
        }
        if (officialList.length === 0) {
          localStorage.setItem("isSuspension", false);
        }
        if (!this.state.visible2) {
          setTimeout(
            () => {
              let officialScroll = this.state.officialScroll;
              if (list.count === 0 || list.count === officialList.length) {
                officialScroll.scrollLoading = "没有更多";
                officialScroll.swiperloading = false;
                this.setState({
                  officialScroll: officialScroll,
                });
              }
              if (list.count > 0) {
                let Width = this.officialRef.current.scrollWidth;
                let clientWidth = this.officialRef.current.clientWidth;
                if (loading) {
                  this.officialRef.current.scrollLeft += clientWidth;
                }
                let scrollLeft = this.officialRef.current.scrollLeft;
                let scrolltype =
                  this.officialRef.current.clientWidth + scrollLeft;
                officialScroll.scrolltype1 = scrolltype;
                officialScroll.scrollWidth = Width;
                officialScroll.scrollLeft = scrollLeft;
                officialScroll.swiperloading = false;
                this.setState({
                  officialScroll: officialScroll,
                });
              }
            },
            loading ? 500 : 0
          );
        }
      } else {
        if (!this.state.visible2) {
          let officialScroll = this.state.officialScroll;
          officialScroll.swiperloading = false;
          this.setState({
            officialScroll: officialScroll,
          });
        }
        this.setState({
          TablLoading: false,
        });
        localStorage.setItem("isSuspension", false);
      }
    });
  }
  renderContainer(item, index) {
    const { officialListTabl } = this.state;
    return (
      <List
        grid={{
          sm: 24,
          md: 12,
          lg: 8,
          xl: 6,
        }}
        // style={{ maxHeight: 620 }}

        loading={this.state.TablLoading}
        scrollLoading={this.state.TabscrollLoading}
        onReachBottom={(currentPage) => this.fetchData(currentPage)}
        dataSource={officialListTabl}
        bordered={false}
        noDataElement={
          <Empty imgSrc={Emptydata_img} description={"暂无体验官方应用"} />
        }
        render={(item, index) => (
          <List.Item key={index} onClick={this.goOffdetails.bind(this, item)}>
            {
              <div
                className="ApplicationList-item grid-item columnSpaceBetween"
                key={index}
              >
                <div className="grid-header">
                  <div
                    className="grid-top"
                    style={{
                      backgroundColor: item.color,
                      backgroundImage: item.image_url
                        ? "url(" + item.image_url + ")"
                        : "",
                    }}
                  >
                    <Space className="grid-top-title" direction="vertical">
                      <Typography.Title heading={5} ellipsis={true}>
                        {item.name}
                      </Typography.Title>

                      <Typography.Paragraph ellipsis={true}>
                        {item.desc}
                      </Typography.Paragraph>
                    </Space>
                  </div>
                </div>

                <div>
                  <Typography.Title heading={6} ellipsis={true}>
                    <Divider
                      type="vertical"
                      className="divider-name"
                      style={{ borderColor: item.color }}
                    />

                    {item.service_options}
                  </Typography.Title>

                  <Typography.Paragraph
                    ellipsis={true}
                    className="Typography-desc"
                  >
                    {item.label}
                  </Typography.Paragraph>
                </div>

                <div className="grid-bottom">
                  <Divider />
                  <div className="rowSpaceBetween">
                    <Space>
                      <span
                        className="dispose_icon"
                        style={{
                          backgroundImage:
                            "url(" +
                            (item.group_name === "智能写作"
                              ? icon1
                              : item.group_name === "智能图文"
                              ? icon2
                              : item.group_name === "智能文档"
                              ? icon3
                              : item.group_name === "智能创作"
                              ? icon4
                              : item.group_name === "知识图谱"
                              ? icon5
                              : "") +
                            ")",
                        }}
                      ></span>

                      <span>{item.group_name}</span>
                    </Space>

                    <Button
                      type="outline"
                      onClick={this.goOffdetails.bind(this, item)}
                      className="open-now"
                    >
                      立即体验 <IconRight />{" "}
                    </Button>
                  </div>
                </div>
              </div>
            }
          </List.Item>
        )}
      />
    );
  }
  getModelPopuList() {
    let page = {
      per_page: this.state.footpagination.pageSize,
      page: this.state.footpagination.current,
      // group_name: this.state.group_name,
      from: "commend",
      train_status: "训练成功",
      scene: this.state.sceneKey,
    };
    this.setState({
      TablLoading: true,
    });
    getmodelList(page).then((res) => {
      if (res.code === 200) {
        let list = res.data;
        let pagination = this.state.footpagination;
        pagination.total = list.count;
        let datas = this.state.myModelList.concat(list.data);
        this.setState({
          footpagination: pagination,
          myModelList: datas,
          loadingbody: false,
          TablLoading: false,
        });
      } else {
        this.setState({
          TablLoading: false,
        });
      }
    });
  }
  onClickTabModel(value) {
    let tablevalue = this.state.listoff[value];
    let pagination = this.state.footpagination;
    pagination.current = 1;
    this.setState({
      activeTab: value,
      group_name: tablevalue ? tablevalue.title : "",
      myModelList: [],
      footpagination: pagination,
      TabscrollLoading: true,
      sceneKey: tablevalue ? tablevalue.link_key : "",
    });
    this.state.sceneKey = tablevalue ? tablevalue.link_key : "";
    this.state.group_name = tablevalue ? tablevalue.title : "";
    this.getModelPopuList();
  }
  fetchDataModelPopu = (currentPage) => {
    let pagination = this.state.footpagination;
    if (pagination.total === this.state.myModelList.length) {
      this.setState({
        TabscrollLoading: "没有更多",
        TablLoading: false,
      });
    } else {
      let pagination = this.state.footpagination;
      pagination.current = currentPage;
      this.getModelPopuList();
    }
  };
  setinfo() {
    getuserInfo().then((res) => {
      if (res.code === 200) {
        let userinfodata = res.data;
        localStorage.setItem("userinfo", JSON.stringify(userinfodata));
        this.setState({
          identity: userinfodata.group_permission,
          userinfodata: userinfodata,
        });
        this.gitconfig();
        this.getlist();
        this.getpackage();
        this.getmodelListVersion();
        this.getboard();
        this.getmodelList();
        this.getofficialappList(true);
        clearInterval(this.state.modelTimr);
        this.state.modelTimr = setInterval(() => {
          this.getmodelListVersion();
        }, 6000);

        if (
          userinfodata?.group_permission &&
          userinfodata?.group_permission !== "PERSONAL"
        ) {
          this.setState({
            imageSrc: [require("asserts/vip_home_img.png")],
          });
        } else {
          this.setState({
            imageSrc: [require("asserts/user_home_img.png")],
          });
        }
      }
    });
  }
  gitconfig() {
    getconfig({ key: "product-style-setting" }).then((res) => {
      if (res.code === 200) {
        let list = res.data;
        let productstyle = list.value;
        this.setState({
          styleCss: productstyle,
        });
        localStorage.setItem("productstyle", JSON.stringify(productstyle));
      }
    });
    getconfig({ key: "model-capabilities" }).then((res) => {
      if (res.code === 200) {
        let list = res.data;
        let obj = list.value;
        this.setState({
          Selectoptions: obj,
        });
        localStorage.setItem("Selectoptions", JSON.stringify(obj));
      }
    });
    getconfig({ key: "model-train-mode" }).then((res) => {
      if (res.code === 200) {
        let list = res.data;
        let PatternList = list.value;
        localStorage.setItem("PatternList", JSON.stringify(PatternList));
      }
    });
    getconfig({ key: "scene-setting" }).then((res) => {
      if (res.code === 200) {
        console.log(res);
        let list = res.data;
        let listoff = list.value;
        this.setState({
          listoff: listoff,
        });
        localStorage.setItem("Listoff", JSON.stringify(listoff));
      }
    });
    getconfig({ key: "product-list" }).then((res) => {
      let list = res.data;
      localStorage.setItem("product-list", JSON.stringify(list.value));
    });
  }
  getboard() {
    getboard().then((res) => {
      if (res.code === 200) {
        let list = res.data;
        let top_data = list.all.top_data;
        let dataBarEcharts = {
          x: [],
          y: [],
        };
        top_data.map((i, index) => {
          dataBarEcharts.x.push(i.key);
          dataBarEcharts.y.push(i.value);
        });
        this.setState({
          board: list,
          dataBarEcharts: dataBarEcharts,
        });
      }
    });
  }
  getlist(loading) {
    let page = {
      per_page: this.state.myapppagination.pageSize,
      page: this.state.myapppagination.current,
    };
    getappList(page).then((res) => {
      if (res.code === 200) {
        let list = res.data;
        let pagination = this.state.myapppagination;
        pagination.total = list.count;
        let datas = this.state.myappList.concat(list.data);
        if (list.count === 0) {
          clearInterval(this.state.modelTimr);
        }
        this.setState({
          myappList: datas,
          myapppagination: pagination,
          isapp: list.count,
          loadingbody: false,
        });
        setTimeout(
          () => {
            if (list.count === 0) {
              this.setState({
                scrollLoading: "没有更多",
                swiperloading: false,
              });
            } else if (list.count === datas.length) {
              this.setState({
                scrollLoading: "没有更多",
                swiperloading: false,
              });
            }
            if (list.count > 0) {
              let Width = this.statusLineRef.current.scrollWidth;
              // let lastChildOff =

              //   this.statusLineRef.current.lastChild.offsetLeft;

              // let lastChildWidth =

              //   this.statusLineRef.current.lastChild.clientWidth;

              let clientWidth = this.statusLineRef.current.clientWidth;
              if (loading) {
                this.statusLineRef.current.scrollLeft += clientWidth;
              }
              let scrollLeft = this.statusLineRef.current.scrollLeft;
              let scrolltype =
                this.statusLineRef.current.clientWidth + scrollLeft;
              //console.log(scrolltype, Width, "加载Width");

              this.setState({
                scrolltype1: scrolltype,
                scrollWidth: Width,
                scrollLeft: scrollLeft,
                swiperloading: false,
              });
            }
          },
          loading ? 500 : 0
        );

        //console.log(list, "list");
      } else {
        this.setState({
          swiperloading: false,
          loadingbody: false,
        });
      }
    });
  }
  onswiperloading(type, name, e) {
    if (name === "model") {
      let pagination = this.state.footpagination;
      let Width = this.modelLineRef.current.scrollWidth;
      let clientWidth = this.modelLineRef.current.clientWidth;
      let modelScroll = this.state.modelScroll;
      if (type === 0) {
        this.modelLineRef.current.scrollLeft -= clientWidth;
      } else {
        if (pagination.total !== this.state.myomdelList.length) {
          pagination.current += 1;
          modelScroll.swiperloading = true;
          this.setState({
            footpagination: pagination,
            modelScroll: modelScroll,
          });
          this.getmodelList(true);
        } else {
          this.modelLineRef.current.scrollLeft += clientWidth;
        }
      }
      let scrollLeft = this.modelLineRef.current.scrollLeft;
      let scrolltype = this.modelLineRef.current.clientWidth + scrollLeft;
      modelScroll.scrolltype1 = scrolltype;
      modelScroll.scrollWidth = Width;
      modelScroll.scrollLeft = scrollLeft;
      this.setState({
        modelScroll: modelScroll,
      });
    } else if (name === "official") {
      let pagination = this.state.officialpagination;
      let Width = this.officialRef.current.scrollWidth;
      let clientWidth = this.officialRef.current.clientWidth;
      let officialScroll = this.state.officialScroll;
      if (type === 0) {
        this.officialRef.current.scrollLeft -= clientWidth;
      } else {
        if (pagination.total !== this.state.officialList.length) {
          pagination.current += 1;
          officialScroll.swiperloading = true;
          this.setState({
            officialpagination: pagination,
            officialScroll: officialScroll,
          });
          this.getofficialappList(true, true);
        } else {
          this.officialRef.current.scrollLeft += clientWidth;
        }
      }
      let scrollLeft = this.officialRef.current.scrollLeft;
      let scrolltype = this.officialRef.current.clientWidth + scrollLeft;
      officialScroll.scrolltype1 = scrolltype;
      officialScroll.scrollWidth = Width;
      officialScroll.scrollLeft = scrollLeft;
      this.setState({
        officialScroll: officialScroll,
      });
    } else if (name === "modelnew") {
      let pagination = this.state.pagination;
      let Width = this.modelNewRef?.current.scrollWidth;
      let clientWidth = this.modelNewRef?.current.clientWidth;
      let modelNewScroll = this.state.modelNewScroll;
      console.log(
        pagination.total,
        this.state.modelProgress.length,
        "this.state.modelProgress.length"
      );

      if (type === 0) {
        if (this.modelNewRef) {
          this.modelNewRef.current.scrollLeft -= clientWidth;
          this.setState({
            pagination: pagination,
            modelNewScroll: modelNewScroll,
          });
        }
      } else {
        if (
          pagination.total !== this.state.modelProgress.length &&
          pagination.total < this.state.modelProgress.length
        ) {
          pagination.current += 1;
          modelNewScroll.swiperloading = true;
          this.setState({
            pagination: pagination,
            modelNewScroll: modelNewScroll,
          });
          this.getmodelListVersion(true);
        } else {
          this.modelNewRef.current.scrollLeft += clientWidth;
        }
      }
      let scrollLeft = this.modelNewRef.current?.scrollLeft;
      let scrolltype = this.modelNewRef.current?.clientWidth + scrollLeft;
      modelNewScroll.scrolltype1 = scrolltype;
      modelNewScroll.scrollWidth = Width;
      modelNewScroll.scrollLeft = scrollLeft;
      this.setState({
        modelNewScroll: modelNewScroll,
      });
    } else {
      let myapppagination = this.state.myapppagination;
      let Width = this.statusLineRef.current.scrollWidth;
      let clientWidth = this.statusLineRef.current.clientWidth;
      if (type === 0) {
        this.statusLineRef.current.scrollLeft -= clientWidth;
      } else {
        if (myapppagination.total !== this.state.myappList.length) {
          myapppagination.current += 1;
          this.setState({
            myapppagination: myapppagination,
            swiperloading: true,
          });
          this.getlist(true);
        } else {
          this.statusLineRef.current.scrollLeft += clientWidth;
        }
      }
      let scrollLeft = this.statusLineRef.current.scrollLeft;
      let scrolltype = this.statusLineRef.current.clientWidth + scrollLeft;
      this.setState({
        scrolltype1: scrolltype,
        scrollWidth: Width,
        scrollLeft: scrollLeft,
      });
    }
  }

  getmodelListVersion(loading) {
    let page = {
      per_page: this.state.pagination.pageSize,
      page: this.state.pagination.current,
      sort: this.state.pagination.sort,
    };
    getmodelListVersion(page).then((res) => {
      if (res.code === 200) {
        let no_data = false;
        let list = res.data;
        let pagination = this.state.pagination;
        pagination.total = list.count;
        let datas = list.data;
        // if (list.count !== datas.length) {
        //   datas = this.state.modelProgress.concat(list.data);
        // }

        if (list.count === 0) {
          no_data = true;
          clearInterval(this.state.modelTimr);
        } else {
          no_data = false;
        }
        this.setState({
          no_data: no_data,
          data2: list.data,
          modelProgress: datas,
          pagination: pagination,
          loading: false,
        });
        var time = setTimeout(
          () => {
            let modelNewScroll = this.state.modelNewScroll;
            if (list.count === 0 || list.count === datas.length) {
              modelNewScroll.scrollLoading = "没有更多";
              modelNewScroll.swiperloading = false;
              this.setState({
                modelNewScroll: modelNewScroll,
              });
            }
            if (list.count > 0) {
              setTimeout(() => {
                let Width = this.modelNewRef?.current.scrollWidth;
                let clientWidth = this.modelNewRef?.current.clientWidth;
                if (loading) {
                  this.modelNewRef.current.scrollLeft += clientWidth;
                }
                let scrollLeft = this.modelNewRef?.current.scrollLeft;
                let scrolltype =
                  this.modelNewRef.current.clientWidth + scrollLeft;
                modelNewScroll.scrolltype1 = scrolltype;
                modelNewScroll.scrollWidth = Width;
                modelNewScroll.scrollLeft = scrollLeft;
                modelNewScroll.swiperloading = false;
                this.setState({
                  modelNewScroll: modelNewScroll,
                });
              }, 100);
            }
            clearTimeout(time);
          },
          loading ? 500 : 0
        );
      } else {
        let modelNewScroll = this.state.modelNewScroll;
        modelNewScroll.swiperloading = false;
        this.setState({
          modelNewScroll: modelNewScroll,
          loadingbody: false,
        });
      }
    });
  }
  getpackage() {
    let list = [];
    allpackage().then((res) => {
      if (res.code === 200) {
        let no_package = false;
        Object.keys(res.data).forEach(function (item) {
          res.data[item].forEach(function (key) {
            if (key.status === 1) {
              list.push(key);
            }
          });
        });
        Object.keys(res.data).forEach(function (item) {
          res.data[item].forEach(function (key) {
            if (key.status === 0) {
              list.push(key);
            }
          });
        });
        list = list.slice(0, 9);
        if (list.length === 0) {
          no_package = true;
        } else {
          no_package = false;
        }
        this.setState({
          no_package: no_package,
          data: list,
          packageLoading: false,
        });
      } else {
        this.setState({
          packageLoading: false,
        });
      }
    });
  }
  onChangeTablePackage(packagePagination) {
    const { current, pageSize } = packagePagination;
    this.setState({
      packageLoading: true,
    });
    setTimeout(() => {
      let setPagination = this.state.packagePagination;
      setPagination.current = current;
      setPagination.pageSize = pageSize;
      this.setState({
        packagePagination: setPagination,
      });
      this.getpackage();
    }, 1000);
  }
  onChangeTable(pagination) {
    const { current, pageSize } = pagination;
    this.setState({
      loading: true,
    });
    setTimeout(() => {
      let setPagination = this.state.pagination;
      setPagination.current = current;
      setPagination.pageSize = pageSize;
      this.setState({
        pagination: setPagination,
      });
      this.getmodelListVersion();
    }, 1000);
  }
  godetails(record) {
    history.push("/enginefactory/model/management");
    window.location.reload();
    localStorage.setItem("record", JSON.stringify(record));
  }
  godebug(record) {
    history.push("/myapp/appdataset");
    localStorage.setItem("record", JSON.stringify(record));
    window.location.reload();
  }
  onRadioGroup(value) {
    this.setState({
      model_capability: value,
    });
    // history.push('/smarttext/modeltextgeneration/debugging')

    // window.location.reload();
  }
  onChangeGroup(e) {
    // 阻止事件冒泡，（阻止这个合成事件，往document上冒泡，因此不会触发click方法）

    e.nativeEvent.stopImmediatePropagation();
    // // 阻止合成事件间的冒泡，不会往最外层的div的test方法冒了，如果不加这句代码，就会冒泡至外层div，执行test方法。

    e.stopPropagation();
    history.push("/myapp/addmyapp");
    localStorage.setItem("props", JSON.stringify(this.state));
    localStorage.removeItem("record");
    window.location.reload();
    //console.log(this.state.model_capability, "onChangeGroup");
  }
  RadioGroupLeave() {
    this.setState({
      model_capability: "",
    });
  }
  goMoreMyApp(record, e) {
    const { Selectoptions } = this.state;
    if (record) {
      if (
        record &&
        Selectoptions[record.model_capability].type === 3 &&
        record.model_capability === "ResumeEditor"
      ) {
        history.push("/myapp/smarteditor");
      } else {
        if (record.train_status === "训练成功") {
          if (record.template_name === "COMMON") {
            history.push("/myapp/globalModle");
            localStorage.setItem("record", JSON.stringify(record));
            localStorage.setItem("props", JSON.stringify(this.state));
            window.location.reload();
            return;
          }
          if (record.model_capability === "Interaction") {
            history.push("/myapp/question");
          } else if (record.model_capability === "Continuation") {
            history.push("/myapp/productExperience");
          } else if (record.model_capability === "SemanticPrototype") {
            history.push("/myapp/semanticprototype");
          } else if (record.model_capability === "Ner") {
            history.push("/myapp/entityExtraction");
          } else if (record.model_capability === "SmartResume") {
            history.push("/myapp/resumeDocument");
          } else if (record.model_capability === "textPolish") {
            history.push("/myapp/embroidery");
          } else if (
            record.model_capability === "writing" ||
            record.model_capability === "textsummarization"
          ) {
            let moduleIdType = JSON.parse(localStorage.getItem("moduleIdType"));
            let arr = moduleIdType["writing"];
            if (arr.indexOf(record.model_id) !== -1) {
              history.push("/myapp/writingcurrency");
            } else {
              history.push("/myapp/writing");
            }
          } else if (record.model_capability === "SemanticWordCloud") {
            history.push("/myapp/wordCloud");
          } else if (record.model_capability === "TextWithPictures") {
            history.push("/myapp/image2text");
          } else if (record.model_capability === "KnowledgeJd") {
            history.push("/myapp/knowledge");
          } else if (record.model_capability === "Classification") {
            history.push("/myapp/wordClass");
          } else if (record.model_capability === "Correction") {
            history.push("/myapp/wordErrorReset");
          } else if (record.model_capability === "SmartContract") {
            history.push("/myapp/smartContract");
          } else if (record.model_capability === "SentimentAnalysis") {
            history.push("/myapp/emotionClass");
          } else if (record.model_capability === "SensitiveWords") {
            history.push("/myapp/sensitiveWords");
          } else if (record.model_capability === "Similarity") {
            history.push("/myapp/similarity");
          } else if (record.model_capability === "KnowledgeReasoning") {
            history.push("/myapp/knowledgereasoning");
          }  else if (record.model_capability === "AIGC") {
            history.push("/myapp/aigcExperience");
          }
          else if (record.model_capability === "CopydoneArticle") {
            history.push("/myapp/copydoneArticle");
          }
        } else {
          history.push("/myapp/addmyapp");
          record.train_details = "详情";
        }
      }
      localStorage.setItem("record", JSON.stringify(record));
      localStorage.setItem("props", JSON.stringify(this.state));
    } else {
      let record = {
        deployment: false,
      };
      localStorage.setItem("record", JSON.stringify(record));
      history.push("/myapp");
    }
    window.location.reload();
  }
  onMouseEnter(index) {
    this.setState({ hoverindex: index });
  }
  onMouseLeave(index) {
    this.setState({
      editTypography: "",
      editTypographydesc: "",
      hoverindex: "",
    });
  }
  goJump(value) {
    history.push(value);
    window.location.reload();
  }
  isputapp = (name, desc, id) => {
    this.putapp(name, desc, id);
  };
  putapp(name, desc, id) {
    let params = {
      id: id,
    };
    if (desc) {
      params.desc = desc;
    } else if (name) {
      params.name = name;
    }
    putapp(params).then((res) => {
      if (res.code === 200) {
        const tmpData = this.state.myappList;
        if (desc) {
          for (var i = 0; i < tmpData.length; i++) {
            if (id === tmpData[i].id) {
              tmpData[i].desc = desc;
              this.setState({
                myappList: tmpData,
              });
              return;
            }
          }
        } else if (name) {
          for (let i = 0; i < tmpData.length; i++) {
            if (id === tmpData[i].id) {
              tmpData[i].name = name;
              this.setState({
                myappList: tmpData,
              });
              return;
            }
          }
        }
      } else {
        Notification.error({ content: res.msg });
      }
    });
  }
  onChangetitle(type, id, value) {
    //console.log(type, id, value, "修改?");

    if (
      (value.length > 20 && type === "name") ||
      (value.length > 56 && type === "desc")
    ) {
      Notification.warning({ content: "修改内容超过32个字符！" });
      return;
    } else if (value === "") {
      Notification.warning({ content: "修改内容不能为空！" });
      return;
    }
    if (type === "desc") {
      this.isputapp(false, value, id);
    } else if (type === "name") {
      this.isputapp(value, false, id);
    }
  }
  onseTypographyn(item, type, e) {
    e.stopPropagation();
    this.setState({
      editTypography: type === 1 ? item.id : "",
      editTypographydesc: type === 2 ? item.id : "",
    });
    setTimeout(() => {
      if (this.state.editTypographydesc) {
        //TextArea光标位置不准确问题

        this.getFocusDom(e, item);
      } else {
        document.getElementById(item.id).focus();
      }
    }, 100);
    //console.log(e, "onseTypographyn");
  }
  getFocusDom = (e, record) => {
    var fDOM = document.getElementById(record.id);
    var len = record.desc.length;
    this.setSelectionRange(fDOM, len, len);
  };
  setSelectionRange = (input, selectionStart, selectionEnd) => {
    if (input.setSelectionRange) {
      input.focus();
      input.setSelectionRange(selectionStart, selectionEnd);
    } else if (input.createTextRange) {
      var range = input.createTextRange();
      range.collapse(true);
      range.moveEnd("character", selectionEnd);
      range.moveStart("character", selectionStart);
      range.select();
    }
  };
  onblur(id, e) {
    //console.log(e.target.value.length, "onblur");

    let value = e.target.value;
    this.onChangetitle(this.state.editTypography ? "name" : "desc", id, value);
    this.setState({
      editTypography: "",
      editTypographydesc: "",
    });
  }
  goManagement() {
    history.push("/personalcenter/membermanagement");
    history.go();
  }
  EnterTypography(id, e) {
    let value = e.target.value;
    this.onChangetitle(this.state.editTypography ? "name" : "desc", id, value);
    this.setState({
      editTypography: "",
      editTypographydesc: "",
    });
  }
  setVisible(value) {
    this.setState({
      visible: value,
    });
  }
  onlidel(key, e) {
    e.stopPropagation();
    let selectedRowKeys = [key];
    this.setState({
      selectedRowKeys: selectedRowKeys,
    });
    this.setVisible(true);
  }
  deleteapp() {
    //console.log(this.state.selectedRowKeys, "selectedRowKeysselectedRowKeys");

    this.setState({
      confirmLoading: true,
    });
    let myapppagination = this.state.myapppagination;
    let datas = { ids: JSON.stringify(this.state.selectedRowKeys) };
    deleteapp(datas).then((res) => {
      if (res.code === 200) {
        myapppagination.pageSize = 5;
        myapppagination.current = 1;
        this.setState({
          myappList: [],
          selectedRowKeys: [],
          SelectcheckAll: false,
          confirmLoading: false,
          myapppagination: myapppagination,
        });
        this.setVisible(false);
        this.getlist();
      } else {
        this.setState({
          confirmLoading: false,
        });
        Notification.error({ title: "Error", content: res.msg });
      }
    });
  }
  Imageinit(avatar, avatarid, domid) {
    if (avatar) {
      Imageinit(avatar, JQ("#" + avatarid), JQ("#" + domid), 2);
    }
  }
  listMyApp() {
    const {
      editTypographydesc,
      editTypography,
      Selectoptions,
      myappList,
      checkbox,
      styleCss,
    } = this.state;
    return myappList.map((item, index) => {
      let styleObj = styleCss[item.group_name];
      return (
        <div
          className="ApplicationList-item grid-item"
          style={{
            backgroundImage: styleObj?.gradientcolor,
            borderColor: styleObj?.color,
          }}
          onClick={
            editTypography || editTypographydesc
              ? null
              : this.goMoreMyApp.bind(this, item)
          }
          key={index}
          onMouseLeave={this.onMouseLeave.bind(this, index)}
          onMouseEnter={this.onMouseEnter.bind(this, index)}
        >
          <Checkbox
            key={index}
            value={item.id}
            className={`${checkbox ? `ischeckbox` : ``}`}
          >
            {item.avatar ? (
              <div
                className="applistAvatar"
                id={item.id + "avatardom"}
                style={{ backgroundColor: "transparent" }}
              >
                <img
                  alt="avatar"
                  src={item.avatar}
                  className="founder"
                  id={item.id + "avatar"}
                />

                {this.Imageinit(
                  item.avatar,
                  item.id + "avatar",
                  item.id + "avatardom"
                )}
              </div>
            ) : (
              ""
            )}
            <div className="rowStart apply-content">
              <div className="rowCenter apply-img">
                <img
                  alt=""
                  src={
                    Selectoptions[item.model_capability]
                      ? Selectoptions[item.model_capability].label_icon
                      : ""
                  }
                />
              </div>

              <div>
                <div className="label-name AlibabaPuHuiTi-Medium">
                  {item.name}
                </div>

                <div className="label-modelname">模型：{item.model_name}</div>
              </div>
            </div>
          </Checkbox>
        </div>
      );
    });
  }
  onSwiperinit(type, e) {
    let Width = this.initRef.current.scrollWidth;
    let lastChildOff = this.initRef.current.lastChild.offsetLeft;
    let lastChildWidth = this.initRef.current.lastChild.clientWidth;
    if (type === 0) {
      this.initRef.current.scrollLeft -= lastChildWidth;
    } else {
      this.initRef.current.scrollLeft += lastChildWidth;
    }
    let scrollLeft = this.initRef.current.scrollLeft;
    let scrolltype = lastChildOff + scrollLeft + 16;
    //console.log(scrolltype, Width, "offsetLeft", scrollLeft);

    if (scrolltype > Width) {
      this.setState({
        scrolltype: "left",
      });
    } else if (scrolltype < Width) {
      this.setState({
        scrolltype: "right",
      });
    }
  }
  getmodelList(loading) {
    let page = {
      per_page: this.state.footpagination.pageSize,
      page: this.state.footpagination.current,
      from: "commend",
      train_status: "训练成功",
    };
    getmodelList(page).then((res) => {
      if (res.code === 200) {
        let list = res.data;
        let pagination = this.state.footpagination;
        pagination.total = list.count;
        let datas = this.state.myomdelList.concat(list.data);
        this.setState({
          footpagination: pagination,
          myomdelList: datas,
          loadingbody: false,
        });
        setTimeout(
          () => {
            let modelScroll = this.state.modelScroll;
            if (list.count === 0 || list.count === datas.length) {
              modelScroll.scrollLoading = "没有更多";
              modelScroll.swiperloading = false;
              this.setState({
                modelScroll: modelScroll,
              });
            }
            if (list.count > 0) {
              let Width = this.modelLineRef.current.scrollWidth;
              let clientWidth = this.modelLineRef.current.clientWidth;
              if (loading) {
                this.modelLineRef.current.scrollLeft += clientWidth;
              }
              let scrollLeft = this.modelLineRef.current.scrollLeft;
              let scrolltype =
                this.modelLineRef.current.clientWidth + scrollLeft;
              modelScroll.scrolltype1 = scrolltype;
              modelScroll.scrollWidth = Width;
              modelScroll.scrollLeft = scrollLeft;
              modelScroll.swiperloading = false;
              this.setState({
                modelScroll: modelScroll,
              });
            }
          },
          loading ? 500 : 0
        );
      } else {
        let modelScroll = this.state.modelScroll;
        modelScroll.swiperloading = false;
        this.setState({
          modelScroll: modelScroll,
          loadingbody: false,
        });
      }
    });
  }
  goMoreModel() {
    history.push("/enginefactory/model");
    window.location.reload();
  }
  goMoreEngine() {
    history.push("/engineservices");
    window.location.reload();
  }
  // 最近模型
  listModel() {
    const { modelProgress } = this.state;
    return modelProgress.map((item, index) => {
      return (
        <div
          className="ApplicationList-item grid-item"
          onClick={this.godetails.bind(this, item)}
          key={index}
        >
          <div className="columnCenterStart apply-content">
            <div className="model-content">
              <div className="rowSpaceBetween">
                <div className="label-name AlibabaPuHuiTi-Medium">
                  {item.model_capability_name}模型
                </div>

                <span>
                  {item.train_status === "训练成功" ? (
                    <IconCheckCircleFill style={{ color: "#00B42A" }} />
                  ) : item.train_status === "训练中" ? (
                    <IconLoading style={{ color: "#165dff" }} />
                  ) : item.train_status === "训练失败" ? (
                    <IconMinusCircleFill style={{ color: "#86909C" }} />
                  ) : (
                    <IconExclamationCircleFill style={{ color: "#ff7d00" }} />
                  )}
                </span>
              </div>

              <div className="model-progress">
                <Progress
                  strokeWidth={7}
                  color={
                    item.train_status === "训练失败" ? "#999999" : "#4172FF"
                  }
                  percent={item.train_percent}
                  trailColor={
                    item.train_status === "训练失败"
                      ? "#F1F1F1"
                      : "rgba(65, 114, 255, 0.2)"
                  }
                />
              </div>
            </div>
          </div>
        </div>
      );
    });
  }
  goCreativeModel(record) {
    history.push("/myapp/addmyapp");
    record.train_details = "模型创作";
    localStorage.setItem("record", JSON.stringify(record));
    localStorage.setItem("props", JSON.stringify(this.state));
    window.location.reload();
  }
  // 创作模型
  creativeModel() {
    const { myomdelList } = this.state;
    return myomdelList.map((item, index) => {
      return (
        <div
          className="ApplicationList-item grid-item"
          onClick={this.goCreativeModel.bind(this, item)}
          key={index}
        >
          <div className="columnCenterStart apply-content">
            <div className="model-content">
              <div className="rowStart label-name AlibabaPuHuiTi-Medium">
                <span className="dispose_icon"></span>

                {item.name}
              </div>

              <div
                className="ellipasedom model-describe"
                title={item.model_desc}
              >
                {item.model_desc}
              </div>

              <img
                src={item.model_icon ? item.model_icon : model_img}
                className="model_img"
                alt=""
              />
            </div>
          </div>
        </div>
      );
    });
  }
  // 创作应用
  listOfficialList() {
    const { officialList } = this.state;
    return officialList.map((item, index) => {
      return (
        <div
          className="ApplicationList-item grid-item"
          onClick={this.goOffdetails.bind(this, item)}
          key={index}
        >
          <div className="columnCenterStart officia-content">
            <div className="model-content">
              <div
                className="apply-header"
                style={{
                  backgroundColor: item.color,
                  backgroundImage: item.image_url
                    ? "url(" + item.image_url + ")"
                    : "",
                }}
              >
                <div className="label-name AlibabaPuHuiTi-Medium">
                  {item.name}
                </div>

                <div>{item.label}</div>
              </div>

              <div className="apply-desc rowStartWrap">
                <div className="ellipaseline model-describe" title={item.desc}>
                  {item.desc}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }
  // 创作服务引擎
  engineList() {
    const { data } = this.state;
    return data.map((item, index) => {
      return (
        <div
          className="rowStart card-engine-li"
          onClick={
            item.opened_package.length === 0
              ? this.goMoreEngine.bind(this, "")
              : this.onPromotion.bind(this, item)
          }
          key={index}
        >
          <div className="rowCenter engine-li-img">
            {item.opened_package.length === 0 ? (
              <img
                className="li-img-grey"
                src={item ? item.label_icon : ""}
                alt=""
              />
            ) : (
              <img
                className="li-img-black"
                src={item ? item.label_icon : ""}
                alt=""
              />
            )}
          </div>

          {item.opened_package.length === 0 ? (
            <div>
              <div className="AlibabaPuHuiTi-Medium engine-li-title-grey">
                {item.label}
              </div>

              <div className="engine-li-text-grey">暂未开通</div>
            </div>
          ) : (
            <div>
              <div className="AlibabaPuHuiTi-Medium engine-li-title-black">
                {item.label}
              </div>

              <div>余 {item.opened_package.free} 条</div>
            </div>
          )}
        </div>
      );
    });
  }
  onPromotion(res) {
    history.push("/myapp/resources");
    localStorage.setItem("record", JSON.stringify(res));
    history.go();
  }
  onClickBtn() {
    this.setState({
      visible3: true,
      Selectoptions: JSON.parse(localStorage.getItem("Selectoptions"))
        ? JSON.parse(localStorage.getItem("Selectoptions"))
        : [],
    });
  }
  onCancel() {
    this.setState({
      visible3: false,
    });
  }
  setVisible1(event) {
    if (event.model_capability) {
      history.replace("/myapp/addmyapp");
      localStorage.setItem("props", JSON.stringify(event));
      localStorage.removeItem("record");
      window.location.reload();
      this.onCancel();
    } else {
      Notification.warning({ content: "请选择服务类型！" });
    }
  }
  goRouteJump(to) {
    history.push(to);
    history.go();
  }
  render() {
    const {
      userinfodata,
      identity,
      officialList,
      confirmLoading,
      visible3,
      visible2,
      visible,
      loadingbody,
      scrolltype1,
      scrollLeft,
      scrollWidth,
      Selectoptions,
      board,
      isapp,
      myapppagination,
      pagination,
      dataBarEcharts,
      swiperloading,
      imageSrc,
      modelScroll,
      modelNewScroll,
      officialScroll,
      ModeVisible,
    } = this.state;
    return (
      <div className="overview">
        <Typography className="hello-title">
          <Space align="center">
            <span className="weixiaoicon"></span>

            <Title
              heading={5}
              style={{ marginBottom: 0, fontSize: 22, marginTop: 0 }}
            >
              {getTimeState() ? getTimeState() + "！" : ""}
              {userinfodata ? userinfodata.nickname : ""}
              ，今天是你开启智能创作的第
              <span className="hello-title-day AlibabaPuHuiTi-Medium">
                {board.my.join_day}
              </span>
              天
            </Title>
          </Space>
        </Typography>

        <ModalSelect
          visible={visible3}
          selectoptions={Selectoptions}
          onCancel={this.onCancel.bind(this)}
          setVisible={this.setVisible1.bind(this)}
        ></ModalSelect>

        {loadingbody ? (
          <Spin loading={loadingbody} dot className="loadingbody"></Spin>
        ) : (
          <>
            <div className="rowFlexStartStart overview-modular">
              <div className="overview-modular-left">
                <Carousel
                  animation="fade"
                  showArrow="never"
                  indicatorPosition="bottom"
                  onClick={this.onClickBtn.bind(this)}
                  style={{
                    width: "100%",
                    height: 194,
                  }}
                >
                  {imageSrc?.map((item, i) => {
                    return (
                      <div key={i}>
                        <img src={item} alt="" />
                      </div>
                    );
                  })}
                </Carousel>

                {isapp === 0 ? null : (
                  <div className="overview-modular-list">
                    <>
                      <div className="modular-box">
                        <div className="rowStart AlibabaPuHuiTi-Medium modular-headline">
                          <img
                            src={
                              identity && identity !== "PERSONAL"
                                ? home_vip_icon
                                : home_blue_icon
                            }
                            alt=""
                            className="hed-icon"
                          />
                          工作台
                        </div>

                        {myapppagination.total > 0 ? (
                          <>
                            <div className="rowStart AlibabaPuHuiTi-Medium modular-title">
                              最近应用
                              <div
                                className="modular-title-more"
                                onClick={this.goMoreMyApp.bind(this, "")}
                              >
                                <Link>
                                  更多
                                  <IconRight />
                                </Link>
                              </div>
                            </div>

                            <div className="swiper-list">
                              <div
                                className="swiper-wrap"
                                ref={this.statusLineRef}
                              >
                                {this.listMyApp()}
                              </div>

                              {scrollLeft > 0 ? (
                                <Button
                                  shape="circle"
                                  className="circleloding left"
                                  icon={<IconLeft />}
                                  onClick={this.onswiperloading.bind(this, 0)}
                                />
                              ) : null}
                              {scrolltype1 < scrollWidth ? (
                                <Button
                                  shape="circle"
                                  className="circleloding"
                                  icon={<IconRight />}
                                  loading={swiperloading}
                                  onClick={this.onswiperloading.bind(this, 1)}
                                />
                              ) : null}
                            </div>
                          </>
                        ) : null}
                        {pagination.total > 0 ? (
                          <>
                            <div className="rowStart AlibabaPuHuiTi-Medium modular-title">
                              最近模型
                              <div
                                className="modular-title-more"
                                onClick={this.goMoreModel.bind(this, "")}
                              >
                                <Link>
                                  更多
                                  <IconRight />
                                </Link>
                              </div>
                            </div>

                            <div className="swiper-list swiper-model">
                              <div
                                className="swiper-wrap"
                                ref={this.modelNewRef}
                              >
                                {this.listModel()}
                              </div>

                              {modelNewScroll.scrollLeft > 0 ? (
                                <Button
                                  shape="circle"
                                  className="circleloding left"
                                  icon={<IconLeft />}
                                  onClick={this.onswiperloading.bind(
                                    this,
                                    0,
                                    "modelnew"
                                  )}
                                />
                              ) : null}
                              {modelNewScroll.scrolltype1 <
                              modelNewScroll.scrollWidth ? (
                                <Button
                                  shape="circle"
                                  className="circleloding"
                                  icon={<IconRight />}
                                  loading={modelNewScroll.swiperloading}
                                  onClick={this.onswiperloading.bind(
                                    this,
                                    1,
                                    "modelnew"
                                  )}
                                />
                              ) : null}
                            </div>
                          </>
                        ) : null}
                      </div>
                    </>
                  </div>
                )}
                <div className="overview-modular-list">
                  <>
                    <div className="modular-box">
                      <div className="rowStart AlibabaPuHuiTi-Medium modular-headline">
                        <img
                          src={
                            identity && identity !== "PERSONAL"
                              ? home_vip_icon2
                              : home_blue2_icon
                          }
                          alt=""
                          className="hed-icon"
                        />
                        智能创意集市
                      </div>

                      <div className="rowStart AlibabaPuHuiTi-Medium modular-title">
                        创作模型
                        {this.state.myomdelList.length > 4 ? (
                          <div
                            className="modular-title-more"
                            onClick={() => this.setVisibleModel(true)}
                          >
                            <Link>
                              更多
                              <IconRight />
                            </Link>
                          </div>
                        ) : null}
                      </div>

                      <div className="swiper-list creative-model">
                        <div className="swiper-wrap" ref={this.modelLineRef}>
                          {this.creativeModel()}
                        </div>

                        {modelScroll.scrollLeft > 0 ? (
                          <Button
                            shape="circle"
                            className="circleloding left"
                            icon={<IconLeft />}
                            onClick={this.onswiperloading.bind(
                              this,
                              0,
                              "model"
                            )}
                          />
                        ) : null}
                        {modelScroll.scrolltype1 < modelScroll.scrollWidth ? (
                          <Button
                            shape="circle"
                            className="circleloding"
                            icon={<IconRight />}
                            loading={modelScroll.swiperloading}
                            onClick={this.onswiperloading.bind(
                              this,
                              1,
                              "model"
                            )}
                          />
                        ) : null}
                      </div>

                      <div className="rowStart AlibabaPuHuiTi-Medium modular-title">
                        创作应用
                        {officialList.length > 4 ? (
                          <div
                            className="modular-title-more"
                            onClick={() => this.setVisible2(true)}
                          >
                            <Link>
                              更多
                              <IconRight />
                            </Link>
                          </div>
                        ) : null}
                      </div>

                      <div className="swiper-list official-model">
                        <div className="swiper-wrap" ref={this.officialRef}>
                          {this.listOfficialList()}
                        </div>

                        {officialScroll.scrollLeft > 0 ? (
                          <Button
                            shape="circle"
                            className="circleloding left"
                            icon={<IconLeft />}
                            onClick={this.onswiperloading.bind(
                              this,
                              0,
                              "official"
                            )}
                          />
                        ) : null}
                        {officialScroll.scrolltype1 <
                        officialScroll.scrollWidth ? (
                          <Button
                            shape="circle"
                            className="circleloding"
                            icon={<IconRight />}
                            loading={officialScroll.swiperloading}
                            onClick={this.onswiperloading.bind(
                              this,
                              1,
                              "official"
                            )}
                          />
                        ) : null}
                      </div>
                    </div>
                  </>
                </div>
              </div>

              <div className="overview-modular-right">
                <div className="cardmodular-right">
                  <div className="modular-head-box">
                    <div className="rowStart modular-head">
                      <Title heading={4}>
                        S{userinfodata.vip_level}
                        {userinfodata.vip_level === 3
                          ? "超级会员"
                          : userinfodata.vip_level === 2
                          ? "尊享会员"
                          : "普通会员"}
                      </Title>

                      {identity && identity !== "PERSONAL" ? (
                        <>
                          <Avatar.Group
                            size={30}
                            style={{ marginRight: 18 }}
                            onClick={this.goManagement.bind()}
                          >
                            {board?.enterprise?.group_list.map(
                              (item, avatar_key) => {
                                return (
                                  <Avatar
                                    key={avatar_key}
                                    style={{
                                      backgroundColor: "#7BC616",
                                      marginRight: -10,
                                      cursor: "pointer",
                                    }}
                                  >
                                    <img alt="avatar" src={item.avatar} />
                                  </Avatar>
                                );
                              }
                            )}
                          </Avatar.Group>

                          <div className="AlibabaPuHuiTi-Medium avatar-quantity">
                            {board?.enterprise?.group_count >= 100
                              ? "99+"
                              : board?.enterprise?.group_count}
                          </div>
                        </>
                      ) : null}
                    </div>

                    <div className="rowStart modular-desc">
                      <span>会员到期：{board.vip_expire_date}</span>

                      <div
                        className="AlibabaPuHuiTi-Medium modular-renew"
                        onClick={() => this.setvisibleUpgrade()}
                      >
                        续费
                      </div>

                      <div
                        className="AlibabaPuHuiTi-Medium modular-upgrade"
                        onClick={() => this.setvisibleUpgrade()}
                      >
                        {identity &&
                        identity !== "PERSONAL" &&
                        userinfodata.vip_level === 3
                          ? "定制"
                          : "升级"}
                      </div>
                    </div>

                    <div className="identity-level">
                      {identity && identity !== "PERSONAL" ? (
                        <div
                          className={`identity-level-bg ${
                            `svip` + userinfodata.vip_level + `_icon`
                          }`}
                        ></div>
                      ) : (
                        <div
                          className={`identity-level-bg ${
                            `svip` + userinfodata.vip_level + `_bule_icon`
                          }`}
                        ></div>
                      )}
                    </div>
                  </div>

                  <div className="rowSpaceAround svg-module-list">
                    <div className="svg-module">
                      <div className="module-content">
                        <SvgLine
                          cx={200}
                          cy={200}
                          rate={
                            identity && identity !== "PERSONAL"
                              ? board?.enterprise?.app_count.count /
                                board?.enterprise?.app_count.max
                              : board?.my.app_count / board?.my.app_max
                          }
                          bgRingColor={
                            identity && identity !== "PERSONAL"
                              ? "rgba(234, 179, 111, 0.3)"
                              : "rgba(71, 135, 255, 0.3)"
                          }
                          ringColor={
                            identity && identity !== "PERSONAL"
                              ? userinfodata.vip_level === 3
                                ? "#FFE099"
                                : "#EAB36F"
                              : "#3B80FF"
                          }
                          strokeWidth={30}
                          part={1}
                          partGap={0}
                          childrenSlot={
                            <span>
                              <span className="AlibabaPuHuiTi-Medium">
                                {identity && identity !== "PERSONAL"
                                  ? board?.enterprise?.app_count.count
                                  : board?.my.app_count}
                                /
                                {identity && identity !== "PERSONAL"
                                  ? board?.enterprise?.app_count.max
                                  : board?.my.app_max}
                              </span>
                            </span>
                          }
                        ></SvgLine>
                      </div>

                      <div className="AlibabaPuHuiTi-Medium svg-module-title">
                        {identity && identity !== "PERSONAL" ? "企业" : "我的"}
                        应用
                      </div>
                    </div>

                    <div className="svg-module">
                      <div className="module-content">
                        <SvgLine
                          cx={200}
                          cy={200}
                          rate={
                            identity && identity !== "PERSONAL"
                              ? board?.enterprise?.model_count.count /
                                board?.enterprise?.model_count.max
                              : board?.my.model_count / board?.my.model_max
                          }
                          bgRingColor={
                            identity && identity !== "PERSONAL"
                              ? "rgba(234, 179, 111, 0.3)"
                              : "rgba(71, 135, 255, 0.3)"
                          }
                          ringColor={
                            identity && identity !== "PERSONAL"
                              ? userinfodata.vip_level === 3
                                ? "#FFE099"
                                : "#EAB36F"
                              : "#3B80FF"
                          }
                          strokeWidth={30}
                          part={1}
                          partGap={0}
                          childrenSlot={
                            <span>
                              <span className="AlibabaPuHuiTi-Medium">
                                {identity && identity !== "PERSONAL"
                                  ? board?.enterprise?.model_count.count
                                  : board?.my.model_count}
                                /
                                {identity && identity !== "PERSONAL"
                                  ? board?.enterprise?.model_count.max
                                  : board?.my.model_max}
                              </span>
                            </span>
                          }
                        ></SvgLine>
                      </div>

                      <div className="AlibabaPuHuiTi-Medium svg-module-title">
                        {identity && identity !== "PERSONAL" ? "企业" : "我的"}
                        模型
                      </div>
                    </div>

                    <div className="svg-module">
                      <div className="module-content">
                        <SvgLine
                          cx={200}
                          cy={200}
                          rate={
                            identity && identity !== "PERSONAL"
                              ? board?.enterprise?.data_count > 0
                              : board?.my?.data_count > 0
                              ? 1
                              : 0
                          }
                          bgRingColor={
                            identity && identity !== "PERSONAL"
                              ? "rgba(234, 179, 111, 0.3)"
                              : "rgba(71, 135, 255, 0.3)"
                          }
                          ringColor={
                            identity && identity !== "PERSONAL"
                              ? userinfodata.vip_level === 3
                                ? "#FFE099"
                                : "#EAB36F"
                              : "#3B80FF"
                          }
                          strokeWidth={30}
                          part={1}
                          partGap={0}
                          childrenSlot={
                            <span>
                              <span className="AlibabaPuHuiTi-Medium">
                                {identity && identity !== "PERSONAL"
                                  ? board?.enterprise?.data_count > 1000000
                                    ? Math.round(
                                        board?.enterprise?.data_count / 10000
                                      ) + "W+"
                                    : board?.enterprise?.data_count
                                  : board?.my?.data_count > 1000000
                                  ? Math.round(board?.my?.data_count / 10000) +
                                    "W+"
                                  : board?.my?.data_count}
                              </span>
                            </span>
                          }
                        ></SvgLine>
                      </div>

                      <div className="AlibabaPuHuiTi-Medium svg-module-title">
                        数据集
                      </div>
                    </div>
                  </div>

                  {identity && identity !== "PERSONAL" ? (
                    <div className="rowSpaceBetween splitlist">
                      <span className="AlibabaPuHuiTi-Medium">创作资源：</span>
                      <Space
                        split={
                          <Divider type="vertical" className="splititemicon" />
                        }
                      >
                        <div
                          className="splititem"
                          onClick={this.goJump.bind(this, "/myapp")}
                        >
                          <div>
                            应用：
                            <span className="AlibabaPuHuiTi-Medium">
                              {board.my.app_count}
                            </span>
                          </div>
                        </div>

                        <div
                          className="splititem"
                          onClick={this.goJump.bind(
                            this,
                            "/enginefactory/model"
                          )}
                        >
                          <div>
                            模型：
                            <span className="AlibabaPuHuiTi-Medium">
                              {board.my.model_count}
                            </span>
                          </div>
                        </div>

                        <div
                          className="splititem"
                          onClick={this.goJump.bind(
                            this,
                            "/enginefactory/dataset"
                          )}
                        >
                          <div>
                            数据集：
                            <span className="AlibabaPuHuiTi-Medium">
                              {board.my.data_count}
                            </span>
                          </div>
                        </div>
                      </Space>
                    </div>
                  ) : (
                    <div
                      className="AlibabaPuHuiTi-Medium rowCenter skew-golden"
                      onClick={() => this.goJump("/authentication")}
                    >
                      <i></i>开通企业会员
                    </div>
                  )}

                  <div
                    className="view-permissions"
                    onClick={() => {
                      this.goRouteJump("/personalcenter");
                    }}
                  >
                    查看创作权益
                    <IconRight />
                  </div>
                </div>

                <div className="cardpaddingR overview-CardR">
                  <div className="AlibabaPuHuiTi-Medium rowStart charts-title">
                    <i className="heat_icon"></i>智能创作服务官方数据榜
                  </div>

                  <div className="BarEcharts">
                    <BarEcharts data={dataBarEcharts} title="个数" />
                  </div>

                  <div className="rowAroundAround Statistic-Space">
                    <div className="columnCenter">
                      <div className="AlibabaPuHuiTi-Medium statistic-space-title">
                        {board.all.company_count}
                      </div>

                      <div className="rowCenter statistic-space-lable">
                        <img src={home_r_icon} alt="" />
                        创作者/位
                      </div>
                    </div>

                    <div className="columnCenter">
                      <div className="AlibabaPuHuiTi-Medium statistic-space-title">
                        {board.all.app_count}
                      </div>

                      <div className="rowCenter statistic-space-lable">
                        <img src={home_r_icon2} alt="" />
                        创作应用/个
                      </div>
                    </div>

                    <div className="columnCenter">
                      <div className="AlibabaPuHuiTi-Medium statistic-space-title">
                        {board.all.data_count}
                      </div>

                      <div className="rowCenter statistic-space-lable">
                        <img src={home_r_icon3} alt="" />
                        数据集/条
                      </div>
                    </div>
                  </div>
                </div>

                <div className="cardpaddingR overview-CardR">
                  <div
                    className="rowSpaceBetween charts-title"
                    style={{ marginBottom: 4 }}
                  >
                    <div className="rowStart">
                      <i className="heat_icon2"></i>

                      <span className="AlibabaPuHuiTi-Medium">
                        创作服务引擎
                      </span>
                    </div>

                    <div
                      className="modular-title-more"
                      onClick={this.goMoreEngine.bind(this, "")}
                    >
                      <Link>
                        更多
                        <IconRight />
                      </Link>
                    </div>
                  </div>

                  <div className="rowStartWrap card-engine">
                    {this.engineList()}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <OffModel
          Selectoptions={Selectoptions}
          visible={visible2}
          TablLoading={this.state.TablLoading}
          TabscrollLoading={this.state.TabscrollLoading}
          activeTab={this.state.activeTab}
          listoff={this.state.listoff}
          officialList={this.state.officialListTabl}
          setVisible={() => {
            this.setVisible2(false);
          }}
          onClickTab={this.onClickTab.bind(this)}
          fetchData={(currentPage) => this.fetchData(currentPage)}
          goOffdetails={(item) => this.goOffdetails(item)}
        ></OffModel>

        <ModelPopu
          Selectoptions={Selectoptions}
          visible={ModeVisible}
          TablLoading={this.state.TablLoading}
          TabscrollLoading={this.state.TabscrollLoading}
          activeTab={this.state.activeTab}
          listoff={this.state.listoff}
          officialList={this.state.myModelList}
          setVisible={() => {
            this.setVisibleModel(false);
          }}
          onClickTab={this.onClickTabModel.bind(this)}
          fetchData={(currentPage) => this.fetchDataModelPopu(currentPage)}
          goOffdetails={(item) => this.goCreativeModel(item)}
        ></ModelPopu>

        <Modal
          style={{
            width: 472,
          }}
          className="deleteModal"
          title="设置提示"
          visible={visible}
          okText="确认"
          cancelText="取消"
          confirmLoading={confirmLoading}
          onOk={this.deleteapp.bind(this)}
          onCancel={() => this.setVisible(false)}
        >
          <p>是否确认删除选中内容？</p>
        </Modal>
      </div>
    );
  }
}
export default Overview;
