import React, { Component } from "react";
// import { Link } from 'react-router-dom'
import {
  Layout,
  Grid,
  Popover,
  Typography,
  Button,
  Steps,
  Divider,
  Space,
  Input,
  Message,
  Radio,
  Spin,
  Upload,
  Result,
  Empty,
  Link,
  Select,
  Notification,
} from "@arco-design/web-react";
import {
  IconCheck,
  IconArrowLeft,
  IconArrowRight,
  IconUpload,
  IconStarFill,
  IconExclamationCircleFill,
  IconCheckCircleFill,
  IconLoading,
  IconDelete,
  IconLeft,
  IconRight,
} from "@arco-design/web-react/icon";
import {
  postmodelVersionTrain,
  getmodelList,
  postapp,
  postmodel,
  getdatumList,
  postdatum,
  getmodelTrainProcess,
  postcheckName,
  putmodelversion,
  getmodelListVersion,
  deleteModelVersion,
} from "api/apis";
import upload from "api/uploads";
import { b2ValueUnit, pageCount } from "common/utils/index";
import TrainingStatus from "components/TrainingStatus";
import history from "common/utils/history";
import JQ from "jquery";
import "./addmodel.scss";
const Row = Grid.Row;
const Col = Grid.Col;
const Step = Steps.Step;
const TextArea = Input.TextArea;
const Sider = Layout.Sider;
const Content = Layout.Content;
const Option = Select.Option;
// 封装组件
class Modeladd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listTimer: "",
      isRetraining: false,
      mockData: [],
      paginationVersion: {
        total: 0,
        pageSize: 10,
        current: 1,
      },
      scrollLoading: <Spin loading={true} />,
      isToBeTrained: false,
      model_capability: "",
      current: 1,
      name: "",
      desc: "",
      Modeldesc: "",
      Modelname: "",
      cardstepitem: null,
      ModelSelsectList: null,
      checkedtype: "",
      app_id: "",
      model_id: "",
      data_id: "", //数据集id（语料配置）
      namedata: "",
      pagination: {
        total: 0,
        pageSize: 20,
        current: 1,
      },
      paginationdata: {
        total: 0,
        pageSize: 20,
        current: 1,
      },
      setingOk: false, //新建模型成功
      checkedtypeData: "",
      DataSelsectList: [],
      fileList: null,
      attachment: [],
      Code: "",
      progress: 0,
      title: "",
      setIntervalTimer: "",
      subTitle: "",
      newAddList: ["创建数据集"],
      version_id: "",
      btnOktitle: "创建应用",
      texSetList: [
        "正在进行数据分包......",
        "正在计算模型偏差......",
        "正在优化模型......",
        "已完成0次模型迭代......",
      ],
      frequency: 0,
      loading: false,
      PatternList: localStorage.getItem("PatternList")
        ? JSON.parse(localStorage.getItem("PatternList"))
        : "",
      checkedPattern: "",
    };
    this.myRef = React.createRef();
  }

  componentDidMount() {
    let props = JSON.parse(localStorage.getItem("props"));
    let record = JSON.parse(localStorage.getItem("record"));
    this.setState({
      model_capability: props.model_capability,
      current: props.current ? props.current : 1,
      Modeldesc: props.Modeldesc ? props.Modeldesc : "",
      Modelname: props.Modelname ? props.Modelname : "",
      cardstepitem: JSON.parse(localStorage.getItem("Selectoptions"))[
        props.model_capability
      ],
      checkedtype: props.checkedtype ? props.checkedtype : "",
      checkedtypeData: props.checkedtypeData ? props.checkedtypeData : "",
      fileList: props.fileList ? props.fileList : "",
      attachment: props.attachment ? props.attachment : "",
      namedata: props.namedata ? props.namedata : "",
      model_id: props.model_id ? props.model_id : "",
      data_id: props.data_id ? props.data_id : "",
      setingOk: props.setingOk ? props.setingOk : false,
      DataSelsectList: props.DataSelsectList ? props.DataSelsectList : [],
      ModelSelsectList: props.ModelSelsectList ? props.ModelSelsectList : null,
      Code: props.Code ? props.Code : "",
      subTitle: props.subTitle ? props.subTitle : "",
      title: props.title ? props.title : "",
      version_id: props.version_id ? props.version_id : "",
      btnOktitle: props.btnOktitle ? props.btnOktitle : "创建应用",
      isRetraining: props.isRetraining ? props.isRetraining : false,
      checkedPattern: props.checkedPattern ? props.checkedPattern : "",
      paginationdata: props.paginationdata
        ? props.paginationdata
        : this.state.paginationdata,
    });
    if (
      props.version_id &&
      (props.Code === "init" || props.Code === "trained")
    ) {
      this.getmodelTrainProcess(props.version_id);
      this.state.version_id = props.version_id;
      this.setintervalData();
    }
    window.addEventListener("resize", this.handleResize);
    setTimeout(() => {
      this.handleResize();
    }, 300);
  }

  handleResize = () => {
    var winHeight = 0;
    if (window.innerHeight) {
      winHeight = window.innerHeight;
    } else if (document.body && document.body.clientHeight) {
      winHeight = document.body.clientHeight;
    }
    if (document.documentElement && document.documentElement.clientHeight) {
      winHeight = document.documentElement.clientHeight;
    }
    if (document.getElementById("layoutbody")) {
      let height =
        winHeight -
        document.getElementById("layoutHeaders").clientHeight -
        document.getElementById("Headertitle").clientHeight -
        20 -
        40;
      document.getElementById("layoutbody").style.height = height + "px";
      setTimeout(() => {
        if (document.getElementById("layoutbody")) {
          let height2 =
            document.getElementById("layoutbody").clientHeight -
            document.getElementById("startParagraph").clientHeight -
            document.getElementById("selectFoot").clientHeight -
            32 -
            15;
          document.getElementById("forminput").style.height = height2 + "px";
        }
      }, 100);
    }
    document.getElementsByClassName("addmodel")[0].style.opacity = "1";
  };

  componentWillUnmount() {
    clearInterval(this.state.listTimer);
    clearInterval(this.state.setIntervalTimer);
    window.removeEventListener("resize", this.handleResize);
  }
  getmodelList() {
    let page = {
      per_page: this.state.pagination.pageSize,
      page: this.state.pagination.current,
      model_capability: this.state.model_capability,
      train_status: "训练成功",
      order_by: "type,asc",
    };
    getmodelList(page).then((res) => {
      let list = res.data;
      let pagination = this.state.pagination;
      pagination.total = list.count;
      this.setState({
        pagination: pagination,
        ModelSelsectList: list.data,
      });
      localStorage.setItem("props", JSON.stringify(this.state));
      //console.log(list, "list");
    });
  }
  getmodelTrainProcess(version_id) {
    clearInterval(this.state.setIntervalTimer);
    let page = {
      version_id: version_id,
    };
    this.setState({
      setingOk: true,
    });
    getmodelTrainProcess(page).then((res) => {
      let Code = "";
      let subTitle = "";
      if (res.code === 200) {
        if (res.data.train_status === "待训练") {
          Code = "trained";
          this.setState({
            btnOktitle: "更多模型版本",
            train_status: res.data.train_status,
          });
          this.state.setIntervalTimer = setInterval(() => {
            this.getmodelTrainProcess(version_id);
          }, 3000);
        } else if (
          res.data.train_status === "训练中" ||
          res.data.train_status === "训练成功"
        ) {
          if (
            res.data.train_percent >= 100 &&
            res.data.train_status === "训练成功"
          ) {
            clearInterval(this.state.setIntervalTimer);
            Code = "success";
            // history.push('/enginefactory/model')
            // window.location.reload();
          } else {
            Code = "init";
            this.state.setIntervalTimer = setInterval(() => {
              this.getmodelTrainProcess(version_id);
            }, 3000);
          }
          this.setState({
            progress: res.data.train_percent,
            title: res.data.model_name,
          });
        } else if (res.data.train_status === "训练失败") {
          Code = "error";
          subTitle = "服务器异常，请通过客服联系开发人员";
          // res.data.train_msg ? res.data.train_msg : res.msg
          clearInterval(this.state.setIntervalTimer);
        }
        let texSetList = this.state.texSetList;
        let count = res.data.iteration_count ? res.data.iteration_count : 0;
        let frequency = this.state.frequency + 1;
        // texSetList[0] = '正在进行第' + frequency + '次数据分包......'
        texSetList[3] = "已完成" + count + "次模型迭代......";
        this.setState({
          progress: res.data.train_percent,
          title: res.data.model_name,
          version_id: res.data.version_id,
          data_id: res.data.data_id,
          texSetList: texSetList,
          frequency: frequency,
          isRetraining: res.data.enable_edit,
        });
        localStorage.setItem("props", JSON.stringify(this.state));
      } else {
        Code = "error";
        subTitle = "服务器异常，请通过客服联系开发人员";
        // res.data.train_msg ? res.data.train_msg : res.msg
        clearInterval(this.state.setIntervalTimer);
      }
      this.setState({
        Code: Code,
        subTitle: subTitle,
      });
      localStorage.setItem("props", JSON.stringify(this.state));
    });
  }
  postapp() {
    //添加app
    let page = {
      model_capability: this.state.model_capability,
      name: this.state.name,
      desc: this.state.desc,
    };
    postapp(page).then((res) => {
      let Code = "";
      let subTitle = "";
      if (res.code === 200) {
        this.state.app_id = res.data.id;
        this.setState({
          app_id: res.data.id,
          loading: false,
        });
        if (this.state.setingOk) {
          Code = "success";
          this.setState({
            title: res.data.name,
          });
        }
      } else {
        Code = "error";
        subTitle = res.msg;
        this.setState({
          title: this.state.model_name
            ? this.state.model_name
            : this.state.name,
          setingOk: true,
          loading: false,
        });
      }
      this.setState({
        Code: Code,
        subTitle: subTitle,
      });
      localStorage.setItem("props", JSON.stringify(this.state));
    });
  }
  // 添加模型（新建）
  postmodel() {
    let page = {
      model_capability: this.state.model_capability,
      name: this.state.Modelname,
      desc: this.state.Modeldesc,
      data_id: this.state.data_id,
    };
    if (this.state.checkedPattern) {
      page.train_mode = this.state.checkedPattern;
    }
    postmodel(page).then((res) => {
      if (res.code === 200) {
        this.state.model_id = res.data.id;
        this.state.version_id = res.data.version_id;
        this.state.setingOk = true;
        this.setState({
          model_id: res.data.id,
          version_id: res.data.version_id,
          current: this.state.current + 1,
          loading: false,
        });
        this.getmodelTrainProcess(res.data.version_id);
        this.setintervalData();
      } else if (res.code === 3005) {
        Notification.error({ content: res.msg });
        history.replace("/enginefactory/model");
        window.location.reload();
        this.setState({
          loading: false,
        });
      } else if (res.code === 409) {
        Notification.error({ content: res.msg });
        this.setState({
          loading: false,
        });
      } else {
        Notification.error({ content: res.msg });
        this.setState({
          loading: false,
        });
        // history.push('/enginefactory/model')
        // window.location.reload();
      }
      localStorage.setItem("props", JSON.stringify(this.state));
    });
  }
  // 监听页面滚动
  handleOnScroll = () => {
    if (this.myRef) {
      const contentScrollTop = this.myRef.scrollTop; //滚动条距离顶部
      const clientHeight = this.myRef.clientHeight; //可视区域
      const scrollHeight = this.myRef.scrollHeight; //滚动条内容的总高度
      if (contentScrollTop + clientHeight >= scrollHeight) {
        if (
          this.state.DataSelsectList.length > 0 &&
          this.state.paginationdata.total > 0 &&
          this.state.paginationdata.total !==
            this.state.DataSelsectList.length &&
          this.state.current === 2
        ) {
          let pagination = this.state.paginationdata;
          pagination.current = this.state.paginationdata.current + 1;
          this.setState({
            paginationdata: pagination,
          });
          this.getdatumList();
        }
      }
    }
  };
  setmoreSelect(type, direction) {
    if (type === "data") {
      let pagination = this.state.paginationdata;
      if (direction === "left") {
        pagination.current = this.state.paginationdata.current - 1;
      } else {
        pagination.current = this.state.paginationdata.current + 1;
      }
      JQ(".pageGroup").addClass("Group-fadeInUp");
      this.setState({
        paginationdata: pagination,
      });
      this.getdatumList(); // 获取数据的方法
    }
  }
  // 选择数据集
  getdatumList() {
    let page = {
      per_page: this.state.paginationdata.pageSize,
      page: this.state.paginationdata.current,
      model_capability: this.state.model_capability,
      // load_status: '导入成功'
    };
    getdatumList(page).then((res) => {
      let list = res.data;
      let pagination = this.state.paginationdata;
      pagination.total = list.count;
      this.setState({
        paginationdata: pagination,
        DataSelsectList: list.data,
      });
      localStorage.setItem("props", JSON.stringify(this.state));
      setTimeout(() => {
        JQ(".pageGroup").removeClass("Group-fadeInUp");
      }, 1000);
      //console.log(list, "list");
    });
  }
  // 添加数据集
  postdatum() {
    let page = {
      model_capability: this.state.model_capability,
      name: this.state.namedata,
      attachment: JSON.stringify(this.state.attachment),
    };
    // let Code = ''
    // let subTitle = ''
    postdatum(page).then((res) => {
      if (res.code === 200) {
        this.setState({
          data_id: res.data.id,
        });
        this.postmodel();
      } else if (res.code === 409) {
        Notification.error({ content: res.msg });
        this.setState({
          loading: false,
        });
      } else {
        // Code = 'error'
        // subTitle = res.msg
        Notification.error({ content: res.msg });
        this.setState({
          loading: false,
        });
      }
      // this.setState({
      //   setingOk: true,
      //   Code: Code,
      //   subTitle: subTitle,
      //   title: this.state.Modelname
      // })
      localStorage.setItem("props", JSON.stringify(this.state));
    });
  }
  postcheckName(Modelname) {
    let page = {
      type: "model",
      name: Modelname,
    };
    postcheckName(page).then((res) => {
      if (res.code === 200) {
        let pagination = this.state.paginationdata;
        pagination.total = 0;
        pagination.current = 1;
        this.getdatumList();
        this.setState({
          current: this.state.current + 1,
          loading: false,
          paginationdata: pagination,
          DataSelsectList: [],
        });
      } else {
        Notification.error({ title: "Error", content: res.msg });
        this.setState({
          loading: false,
        });
      }
    });
  }
  onChangeIput(value) {
    const { current, checkedtypeData } = this.state;
    if (current === 1) {
      this.setState({
        Modelname: value,
      });
    } else if (current === 2 && checkedtypeData === "创建数据集") {
      this.setState({
        namedata: value,
      });
    }
  }
  onChangeTextArea(value) {
    const { current, checkedtype } = this.state;
    this.setState({
      Modeldesc: value,
    });
  }
  // 选择模型
  onSelsect(value) {
    this.setState({
      checkedtype: value,
    });
  }
  // 选择数据集
  onSelsectData(value) {
    this.setState({
      checkedtypeData: value,
    });
  }
  // 选择模型训练模式
  onSelsectPattern(value) {
    this.setState({
      checkedPattern: value,
    });
  }
  handleUpload(option) {
    upload(option, this.state.model_capability);
  }
  setFile(fileList, file) {
    this.setState({
      fileList: fileList,
    });
    if (file.status === "done") {
      let key = [];
      key.push(file.response.fileKey);
      this.setState({
        attachment: key,
      });
      if (this.state.namedata === "") {
        this.setState({
          namedata: file.name.substring(0, file.name.lastIndexOf(".")),
        });
      }
    }
    //console.log(fileList, "setFile");
  }
  onIconDelete() {
    this.setState({
      fileList: null,
      attachment: [],
    });
    localStorage.setItem("props", JSON.stringify(this.state));
  }
  beforeUpload(file) {
    //console.log(b2ValueUnit(file.size));
    var AllImgExt = ".csv|";
    var extName = file.name.substring(file.name.lastIndexOf(".")).toLowerCase(); //（把路径中的所有字母全部转换为小写）
    if (AllImgExt.indexOf(extName + "|") === -1) {
      let ErrMsg =
        "该文件类型不允许上传。请上传 .csv 类型的文件，当前文件类型为" +
        extName;
      Notification.warning({ content: ErrMsg });
      return false;
    }
    const isLt10M = !(
      b2ValueUnit(file.size)[1] === "B" ||
      b2ValueUnit(file.size)[1] === "KB" ||
      (b2ValueUnit(file.size)[1] === "MB" && b2ValueUnit(file.size)[0] <= 5)
    );
    return new Promise((resolve, reject) => {
      if (isLt10M) {
        Notification.warning({ content: "上传文件不能大于5MB" });
        reject();
      } else {
        resolve();
      }
    });
  }
  onChangeNext() {
    const {
      current,
      Modelname,
      PatternList,
      checkedPattern,
      checkedtypeData,
      namedata,
      attachment,
    } = this.state;
    if (current === 1 && Modelname === "") {
      Notification.warning({ content: "模型名称不能为空!" });
    }
    // else if (current === 1 && Modeldesc === '') {
    //   Message.error('模型简描述不能为空!')
    // }
    else if (
      current === 2 &&
      checkedtypeData === "创建数据集" &&
      namedata === ""
    ) {
      Notification.warning({ content: "数据集名称不能为空!" });
    } else if (
      current === 2 &&
      checkedtypeData === "创建数据集" &&
      attachment.length === 0
    ) {
      Notification.warning({ content: "数据集不能为空!" });
    } else if (
      current === 2 &&
      checkedtypeData === "" &&
      checkedtypeData !== "创建数据集"
    ) {
      Notification.warning({ content: "请选择新建方式！" });
    } else if (PatternList && current === 2 && checkedPattern === "") {
      Notification.warning({ content: "未选择模型训练模式 !" });
    } else {
      this.setState({
        loading: true,
      });
      if (this.state.current === 1) {
        this.postcheckName(Modelname);
      } else if (this.state.current === 2) {
        if (checkedtypeData === "创建数据集") {
          this.postdatum();
        } else {
          this.state.data_id = checkedtypeData;
          this.setState({
            data_id: checkedtypeData,
          });
          this.postmodel();
        }
      }
      localStorage.setItem("props", JSON.stringify(this.state));
    }
    //console.log(this.state, "Next");
  }
  onChangePrev() {
    const { current } = this.state;
    if (current === 1) {
      history.replace("/enginefactory/model");
      window.location.reload();
    } else {
      this.setState({
        current: current - 1,
      });
    }
  }
  onColseadd() {
    history.replace("/enginefactory/model/management");
    localStorage.removeItem("props");
    let record = {
      id: this.state.model_id,
      model_capability: this.state.model_capability,
      name: this.state.Modelname,
    };
    localStorage.setItem("record", JSON.stringify(record));
    window.location.reload();
  }
  putmodelversion(optionVlue) {
    let params = {
      id: optionVlue,
    };
    putmodelversion(params).then((res) => {
      if (res.code === 200) {
        history.replace("/myapp/addmyapp");
        let state = {
          model_capability: this.state.model_capability,
          checkedtype: this.state.model_id,
          model_id: this.state.model_id,
          name: this.state.model_name,
        };
        localStorage.setItem("props", JSON.stringify(state));
        localStorage.removeItem("record");
        window.location.reload();
      } else {
        Notification.error({ content: res.msg });
      }
    });
  }
  OpenApp() {
    if (this.state.train_status === "待训练") {
      this.onColseadd();
    } else {
      this.putmodelversion(this.state.version_id);
    }
  }
  onRetraining() {
    let params = {
      id: this.state.version_id,
    };
    if (this.state.data_id) {
      params.data_id = this.state.data_id;
    }
    postmodelVersionTrain(params).then((res) => {
      if (res.code === 409 || res.code === 200) {
        if (this.state.version_id) {
          this.getmodelTrainProcess(this.state.version_id);
          this.setintervalData();
        }
      } else {
        this.setState({
          subTitle: res.msg,
        });
      }
    });
  }
  goLink() {
    this.onColseadd();
  }
  setintervalData() {
    this.gofetchData(1);
    clearInterval(this.state.listTimer);
    this.state.listTimer = setInterval(() => {
      this.gofetchData(1);
    }, 1000);
  }
  gofetchData(currentPage) {
    if (
      this.state.mockData.length === this.state.paginationVersion.total &&
      currentPage !== 1
    ) {
      this.setState({
        scrollLoading: "没有更多",
      });
    } else {
      this.getmodelListVersion(currentPage);
    }
  }
  getmodelListVersion(currentPage) {
    const { mockData, paginationVersion } = this.state;
    let page = {
      per_page: this.state.paginationVersion.pageSize,
      page: currentPage,
      train_status: '["训练中","待训练"]',
      sort: "desc",
      // filter_id: this.state.version_id
    };
    getmodelListVersion(page).then((res) => {
      if (res.code === 200) {
        let list = res.data;
        let pagination = this.state.paginationVersion;
        pagination.total = list.count;

        // var b = [this.state.version_id];
        // var newdata = list.data.filter(item => { return !b.includes(item.id); });
        let newdata = list.data;
        if (newdata.length > 0) {
          this.setState({
            paginationVersion: pagination,
            mockData: currentPage === 1 ? newdata : mockData.concat(newdata),
            isToBeTrained: true,
            scrollLoading: false,
          });
        }
        if (newdata.length === 0 && currentPage === 1) {
          this.setState({
            isToBeTrained: false,
          });
          clearInterval(this.state.listTimer);
        }
        //console.log(list, "list");
      } else {
        this.setState({
          isToBeTrained: false,
        });
        clearInterval(this.state.listTimer);
      }
    });
  }
  openTraining(record) {
    clearInterval(this.state.listTimer);
    clearInterval(this.state.setIntervalTimer);
    record.train_details = "详情";
    localStorage.setItem("record", JSON.stringify(record));
    localStorage.setItem("props", JSON.stringify(this.state));
    history.push("/enginefactory/model/management/add");
    window.location.reload();
    //console.log(record, "record");
  }
  deleteModelVersion(e, id) {
    e.stopPropagation();
    deleteModelVersion({ ids: JSON.stringify([id]) }).then((res) => {
      if (res.code === 200) {
        clearInterval(this.state.listTimer);
        this.setintervalData();
        Notification.success({ content: "删除成功!" });
      } else {
        Notification.error({ content: "删除失败!" });
      }
    });
  }
  render() {
    const {
      loading,
      isRetraining,
      mockData,
      scrollLoading,
      isToBeTrained,
      texSetList,
      btnOktitle,
      subTitle,
      title,
      current,
      newAddList,
      desc,
      Modeldesc,
      Modelname,
      cardstepitem,
      ModelSelsectList,
      DataSelsectList,
      checkedtype,
      namedata,
      checkedtypeData,
      setingOk,
      fileList,
      Code,
      progress,
    } = this.state;
    return (
      <div className="addmodel">
        <div className="Header-init" id="Headertitle">
          <div className="Header-title">
            {setingOk ? Modelname : "创建模型"}
          </div>
        </div>
        {setingOk ? (
          <TrainingStatus
            mockDataloding={this.state.version_id}
            isRetraining={isRetraining}
            mockData={mockData}
            scrollLoading={scrollLoading}
            isToBeTrained={isToBeTrained}
            btnOktitle={btnOktitle}
            Code={Code}
            subTitle={subTitle}
            title={title}
            progress={progress}
            TextSet={texSetList}
            deleteModelVersion={(e, id) => this.deleteModelVersion(e, id)}
            openTraining={(item) => this.openTraining(item)}
            fetchData={(currentPage) => this.gofetchData(currentPage)}
            goLink={this.goLink.bind(this)}
            onRetraining={this.onRetraining.bind(this)}
            onOpenApp={this.OpenApp.bind(this)}
            onColseadd={this.onColseadd.bind(this)}
            on
          ></TrainingStatus>
        ) : (
          <div className="addmodel-box">
            <Layout className="addmodel-box" id="layoutbody">
              <Content>
                <div className="cardform">
                  <Typography.Paragraph
                    className="startParagraph"
                    id="startParagraph"
                  >
                    {cardstepitem ? cardstepitem.label : "未知"}模型
                  </Typography.Paragraph>
                  <div
                    className="forminput"
                    id="forminput"
                    ref={(ref) => (this.myRef = ref)}
                    // onScrollCapture={() => this.handleOnScroll()}
                  >
                    {current === 1 ? (
                      <Space direction="vertical">
                        <Space
                          direction="vertical"
                          className="custom-Required"
                          style={{ marginBottom: 34 }}
                        >
                          <div className="formtitle">模型名称</div>
                          <Input
                            className="inputtext forminput-item"
                            style={{ width: 498, height: 48 }}
                            allowClear
                            value={Modelname}
                            placeholder="请输入模型名称（限20字符）"
                            onChange={this.onChangeIput.bind(this)}
                            maxLength={20}
                          />
                        </Space>
                        <Space direction="vertical">
                          <div className="formtitle">模型简介</div>
                          <TextArea
                            className="forminput-item"
                            placeholder="请简单的介绍一下模型（限100字符）"
                            style={{ width: 498, height: 138, resize: "none" }}
                            value={Modeldesc}
                            onChange={this.onChangeTextArea.bind(this)}
                            maxLength={100}
                          />
                        </Space>
                      </Space>
                    ) : current === 2 ? (
                      <Space
                        direction="vertical"
                        style={{ width: "100%", textAlign: "left" }}
                      >
                        <div className="selsect-fix-label">新数据集</div>
                        <div className="Model-selsect-fix">
                          <Radio.Group
                            onChange={this.onSelsectData.bind(this)}
                            value={checkedtypeData}
                            className="custom-Group"
                          >
                            {newAddList.map((item, i) => {
                              return (
                                <Radio key={item} value={item}>
                                  <Space
                                    align="start"
                                    className={`custom-radio-card ${
                                      checkedtypeData === item
                                        ? "custom-radio-card-checked"
                                        : ""
                                    }`}
                                  >
                                    <div className="custom-radio-card-mask">
                                      <div className="custom-radio-card-mask-dot"></div>
                                    </div>
                                    <div>
                                      <Typography.Title
                                        heading={6}
                                        className="custom-radio-card-title"
                                      >
                                        {item}
                                      </Typography.Title>
                                    </div>
                                  </Space>
                                </Radio>
                              );
                            })}
                          </Radio.Group>
                        </div>
                        {checkedtypeData === "创建数据集" ? (
                          <div className="addData">
                            <div>
                              <Space
                                direction="vertical"
                                style={{ marginBottom: 24 }}
                              >
                                {/* <div className="formtitle">导入数据</div> */}
                                <Upload
                                  multiple
                                  showUploadList={false}
                                  limit={1}
                                  fileList={fileList}
                                  accept=".csv"
                                  onChange={this.setFile.bind(this)}
                                  customRequest={(option) => {
                                    this.handleUpload(option);
                                  }}
                                  beforeUpload={this.beforeUpload.bind(this)}
                                  onExceedLimit={() => {
                                    Notification.warning({
                                      content: "超过上传数量限制！最多上传1个",
                                    });
                                  }}
                                >
                                  <Space
                                    size="medium"
                                    style={{ color: "#406EFF", fontSize: 16 }}
                                    className="custom-upload-trigger-link"
                                  >
                                    点击上传数据集文件
                                    <IconUpload
                                      style={{ color: "#4E5969", fontSize: 16 }}
                                    />
                                  </Space>
                                </Upload>
                                {fileList ? (
                                  <Space
                                    align="center"
                                    style={{ fontSize: 16 }}
                                    className="upload-Link"
                                  >
                                    {fileList.map((item, index) => {
                                      return (
                                        <Link
                                          key={index}
                                          href={item.url}
                                          icon={
                                            item.status === "error" ? (
                                              <IconExclamationCircleFill
                                                style={{
                                                  color: "#ff7d00",
                                                  fontSize: 18,
                                                }}
                                              />
                                            ) : item.status === "done" ? (
                                              <IconCheckCircleFill
                                                style={{
                                                  color: "#00B42A",
                                                  fontSize: 18,
                                                }}
                                              />
                                            ) : (
                                              <IconLoading
                                                style={{
                                                  color: "#406EFF",
                                                  fontSize: 18,
                                                }}
                                              />
                                            )
                                          }
                                        >
                                          {item.name}
                                        </Link>
                                      );
                                    })}
                                    <IconDelete
                                      style={{
                                        color: "#4E5969",
                                        cursor: "pointer",
                                      }}
                                      onClick={this.onIconDelete.bind(this)}
                                    />
                                  </Space>
                                ) : null}
                                <Input
                                  className="forminput-item inputtext"
                                  style={{ width: 464, height: 48 }}
                                  allowClear
                                  value={namedata}
                                  placeholder="请输入数据集名称（限20字符）"
                                  onChange={this.onChangeIput.bind(this)}
                                  maxLength={20}
                                />
                              </Space>
                            </div>
                            <div className="TipsData">
                              <Space align="start">
                                <IconStarFill style={{ color: "#FFD726" }} />
                                <Typography>
                                  为了确保引擎的最终效果，请按以下模板提供标注数据
                                </Typography>
                              </Space>
                              <div className="TipsData-link">
                                {cardstepitem.file.map((item, index) => {
                                  return (
                                    <span key={index}>
                                      <Link href={item.url} target="_blank">
                                        {" "}
                                        附：{item.name}.{item.type}
                                      </Link>
                                      {index > 1 ? (
                                        <Link href={item.url} target="_blank">
                                          {" "}
                                          或 {item.name}.{item.type}
                                        </Link>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  );
                                })}
                                {cardstepitem.fie_img ? (
                                  <div className="fie_img">
                                    <img src={cardstepitem.fie_img} />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {DataSelsectList && DataSelsectList.length > 0 ? (
                          <Space direction="vertical">
                            <div className="selsect-fix-label">可用数据集</div>
                            <div className="Model-selsect-fix listloadMore">
                              <Radio.Group
                                onChange={this.onSelsectData.bind(this)}
                                className="custom-Group pageGroup"
                                value={checkedtypeData}
                              >
                                {DataSelsectList.length > 0 &&
                                  DataSelsectList.map((item, i) => {
                                    return (
                                      <Radio key={item.id} value={item.id}>
                                        <Popover
                                          position="bl"
                                          content={item.name}
                                          disabled={
                                            !item.name || item.name.length < 6
                                          }
                                          className="select-Popover"
                                        >
                                          <Space
                                            align="start"
                                            className={`custom-radio-card ${
                                              checkedtypeData === item.id
                                                ? "custom-radio-card-checked"
                                                : ""
                                            }`}
                                          >
                                            <div className="custom-radio-card-mask">
                                              <div className="custom-radio-card-mask-dot"></div>
                                            </div>
                                            <div>
                                              <Typography.Title
                                                heading={6}
                                                className="custom-radio-card-title"
                                              >
                                                {item.name}
                                              </Typography.Title>
                                            </div>
                                          </Space>
                                        </Popover>
                                      </Radio>
                                    );
                                  })}
                              </Radio.Group>
                              {pageCount(
                                this.state.paginationdata.total,
                                this.state.paginationdata.pageSize
                              ) > 1 &&
                              this.state.paginationdata.current !== 1 ? (
                                <div
                                  className="add-IconLeft"
                                  onClick={this.setmoreSelect.bind(
                                    this,
                                    "data",
                                    "left"
                                  )}
                                >
                                  <IconLeft />
                                </div>
                              ) : (
                                ""
                              )}
                              {pageCount(
                                this.state.paginationdata.total,
                                this.state.paginationdata.pageSize
                              ) === this.state.paginationdata.current ? (
                                ""
                              ) : (
                                <div
                                  className="add-IconRight"
                                  onClick={this.setmoreSelect.bind(
                                    this,
                                    "data",
                                    "right"
                                  )}
                                >
                                  <IconRight />
                                </div>
                              )}
                            </div>
                          </Space>
                        ) : null}
                        {/* {this.state.PatternList &&
                        this.state.PatternList.length > 0 ? (
                          <>
                            <div className="selsect-fix-label">
                              模型训练模式
                            </div>
                            <div className="Model-selsect-fix">
                              <Radio.Group
                                onChange={this.onSelsectPattern.bind(this)}
                                value={this.state.checkedPattern}
                                className="custom-Group"
                              >
                                {this.state.PatternList.map((item, i) => {
                                  return (
                                    <Radio key={item.key} value={item.key}>
                                      <Popover
                                        position="bl"
                                        content={item.desc}
                                        disabled={!item.desc}
                                        className="select-Popover"
                                      >
                                        <Space
                                          align="start"
                                          className={`custom-radio-card ${
                                            this.state.checkedPattern ===
                                            item.key
                                              ? "custom-radio-card-checked"
                                              : ""
                                          }`}
                                        >
                                          <div className="custom-radio-card-mask">
                                            <div className="custom-radio-card-mask-dot"></div>
                                          </div>
                                          <div>
                                            <Typography.Title
                                              heading={6}
                                              className="custom-radio-card-title"
                                            >
                                              {item.lable}
                                            </Typography.Title>
                                          </div>
                                        </Space>
                                      </Popover>
                                    </Radio>
                                  );
                                })}
                              </Radio.Group>
                            </div>
                          </>
                        ) : (
                          ""
                        )} */}
                      </Space>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="select-foot" id="selectFoot">
                    <Divider />
                    <div className="select-foot-btns">
                      <div>
                        {current > 1 ? (
                          <Button
                            icon={<IconArrowLeft />}
                            className="Prev custom-step-button"
                            loading={false}
                            onClick={this.onChangePrev.bind(this)}
                          >
                            上一步
                          </Button>
                        ) : (
                          ""
                        )}
                      </div>
                      <div style={{ textAlign: "right" }}>
                        <Space size={"medium"}>
                          {this.state.PatternList &&
                          this.state.PatternList.length > 0 &&
                          current === 2 ? (
                            <Select
                              placeholder="选择训练模式"
                              onChange={this.onSelsectPattern.bind(this)}
                            >
                              {this.state.PatternList.map((option, index) => (
                                <Option key={option.key} value={option.key}>
                                  {option.lable}
                                </Option>
                              ))}
                            </Select>
                          ) : null}
                          {
                            <Button
                              type="primary"
                              className="Next"
                              loading={loading}
                              onClick={this.onChangeNext.bind(this)}
                            >
                              {current >= 2 ? "开始训练" : "下一步"}
                              {loading ? "" : <IconArrowRight />}
                            </Button>
                          }
                        </Space>
                      </div>
                    </div>
                  </div>
                </div>
              </Content>
              <Sider
                style={{ borderRadius: 22, background: "#001a61" }}
                className="cardstep"
              >
                <div className="architecture_top_img"></div>
                <Space size={"large"} className="Space-large">
                  <div className="mode-Typography">
                    <Typography.Title className="text-gradient">
                      仅需2步
                    </Typography.Title>
                    <Typography.Title className="text-gradient">
                      轻松创建自有模型
                    </Typography.Title>
                    <Typography style={{ marginTop: 21 }}>
                      {"配置模型 > 导入数据 > 生成内容"}
                    </Typography>
                  </div>
                </Space>
                <div style={{ overflow: "hidden", textAlign: "center" }}>
                  <Steps
                    direction="vertical"
                    current={current}
                    style={{ marginTop: 20 }}
                  >
                    <Step icon={<IconCheck />} title="模型配置" />
                    <Step title="导入数据" />
                    <Step title="模型训练" />
                  </Steps>
                </div>
                {cardstepitem ? (
                  <div
                    className={`Health_coverages ${
                      cardstepitem.group === "智能写作"
                        ? "Health_coverage"
                        : cardstepitem.group === "智能图文"
                        ? "Health_coverage2"
                        : cardstepitem.group === "智能文档"
                        ? "Health_coverage3"
                        : cardstepitem.group === "智能创作"
                        ? "Health_coverage4"
                        : ""
                    }`}
                  ></div>
                ) : null}
              </Sider>
            </Layout>
          </div>
        )}
      </div>
    );
  }
}
export default Modeladd;
