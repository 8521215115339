import React, { Component } from "react";
import * as echarts from "echarts";

class BarEcharts extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // 挂载完成之后，因为React初始化echarts时长宽可能会获取到顶层，所以延迟200去生成，不影响视觉效果
  componentDidMount() {
    setTimeout(() => {
      this.initEchart(this.props.data);
    }, 200);
  }

  // 更新props以后调用
  UNSAFE_componentWillReceiveProps(newProps) {
    //console.log(newProps.data, "newProps.data");
    if (newProps.data !== this.props.data) {
      if (echarts.getInstanceByDom(this.echartsBox) === null) {
        setTimeout(() => {
          this.initEchart(newProps.data);
        }, 200);
      }
    }
  }

  initEchart = (data) => {
    let myEcharts = echarts.init(this.echartsBox);
    let option = {
      grid: {
        top: "15%",
        left: "15%",
        right: "2%",
        bottom: "18%",
        containLabel: false,
      },
      title: {
        text: this.props.title || "",
        left: "20",
        top: "5",
        textStyle: {
          color: "#8d93a1", //颜色
          fontStyle: "normal", //风格
          fontWeight: "400", //粗细
          fontFamily: "AlibabaPuHuiTi-Regular", //字体
          fontSize: 10, //大小
          align: "center", //水平对齐
        },
      },
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {
          // 坐标轴指示器，坐标轴触发有效
          type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          label: {
            show: true,
          },
        },
        formatter: "{b}<br/>个数：{c}",
        extraCssText: "box-shadow: 0px 6px 58px 0px rgba(196,203,214,0.10); ",
      },
      xAxis: [
        {
          name: "服务选项",
          type: "category",
          data: data.x,
          axisTick: {
            alignWithLabel: true,
            show: false,
          },
          axisLabel: {
            interval: 0, //强制显示文字
            formatter: (value, index) => {
              // 10 6 这些你自定义就行
              let v = value.substring(0, 6) + "...";
              return value.length > 9 ? v : value;
            },
            //textStyle里面写x轴下的字体的样式
            color: "#1d2129",
            fontSize: 12,
            fontFamily: "AlibabaPuHuiTi-Regular",
            padding: 4,
          },
          axisLine: {
            show: false, //这里的show用于设置是否显示x轴那一条线 默认为true
            lineStyle: {
              //lineStyle里面写x轴那一条线的样式
              color: "#8D93A1",
              width: 1, //轴线的粗细 我写的是2 最小为0，值为0的时候线隐藏
            },
          },
          nameLocation: "start",
          nameTextStyle: {
            verticalAlign: "top", // top名称显示在坐标轴外，bottom显示在坐标轴内部
            align: "left",
            padding: [15, 0, 0, -30],
            fontSize: 10,
            color: "#8D93A1",
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          min: 0,
          splitLine: {
            lineStyle: {
              type: "dashed", //设置网格线类型 dotted：虚线 solid:实线
              color: "#E6EBF5",
            },
          },
          axisLabel: {
            color: "#8D93A1",
            fontSize: 10,
            fontFamily: "AlibabaPuHuiTi-Regular",
          },
        },
      ],
      series: [
        {
          name: "用户量",
          type: "bar",
          barWidth: "18",
          data: data.y,
          itemStyle: {
            borderRadius: [10, 10, 0, 0],
            //这里是重点
            color: (params) => {
              //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
              let colorList = [
                new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#FE5454" },
                  { offset: 0.5, color: "#FFA39F" },
                  { offset: 1, color: "#FFA39F" },
                ]),
                new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#2A7DFF" },
                  { offset: 0.5, color: "#8DBBFF" },
                  { offset: 1, color: "#8DBBFF" },
                ]),
                new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#935AFE" },
                  { offset: 0.5, color: "#D6BAFF" },
                  { offset: 1, color: "#D6BAFF" },
                ]),
                new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#00D097" },
                  { offset: 0.5, color: "#D9F1E2" },
                  { offset: 1, color: "#D9F1E2" },
                ]),
                new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#2A7DFF" },
                  { offset: 0.5, color: "#8DBBFF" },
                  { offset: 1, color: "#8DBBFF" },
                ]),
              ];
              return colorList[params.dataIndex];
            },
          },
        },
      ],
      triggerEvent: false, // 设置为true后，可触发事件。实现x轴文字过长，显示省略号，hover上去显示全部的功能
    };
    myEcharts.clear();
    myEcharts.setOption(option);
    // myEcharts.on('finished', () => {
    //   myEcharts.resize()
    // })
    //当屏幕大小发生改变时，重新画图
    window.addEventListener("resize", () => {
      myEcharts.resize();
    });
  };

  render() {
    return (
      <div
        ref={(c) => {
          this.echartsBox = c;
        }}
        style={{ width: "100%", height: "100%" }}
      />
    );
  }
}

export default BarEcharts;
