import React, { Component } from 'react'
import {
  Layout,
  Typography,
  Button,
  Space,
  Empty,
  Input,
  Select,
  Notification,
  Divider,
} from '@arco-design/web-react'
import { IconPlus } from '@arco-design/web-react/icon'
import Emptydata_img from 'asserts/Emptydata_img.svg'
import Generating_img from 'asserts/Generating_img.gif'
import errorIcon from 'asserts/error_icon.svg'
import {
  getApiInfo,
  getRequest,
  putmodelversion,
  putapp,
  check_resource,
} from 'api/apis'
import history from 'common/utils/history'
import { CreateLink } from 'common/utils/business'
import { debounce } from 'common/utils'
import copy from 'copy-to-clipboard'
import { sourceCancelClass } from 'api/cancel-request'
import UpgradeTips from 'components/UpgradeTips'
import $ from 'jquery'
import './entityExtraction.scss'
const Sider = Layout.Sider
const Content = Layout.Content
const Option = Select.Option
const TextArea = Input.TextArea

// 封装组件
class WordErrorReset extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Selectoptions: JSON.parse(localStorage.getItem('Selectoptions')),
      no_data: false,
      optionVlue: '',
      TextAreaValue: '',
      versions: [],
      ParagraphContinuation: {},
      ContinuationLoding: false,
      record: JSON.parse(localStorage.getItem('record')),
      apiInfo: {
        model_id: '',
      },
      number: 6,
      requestMsg: '',
      requestMsgText: '您还未输入文段',
      Loding: false,
      input_describe: '请输入文段，开始纠错',
      settimer1: '',
      settimer2: '',
      visibleUpgrade: false,
      upgradetips: null,
      identity: JSON.parse(localStorage.getItem('userinfo'))
        ? JSON.parse(localStorage.getItem('userinfo')).group_permission
        : '',
    }
    this.isPhoneLegal = debounce(this.isPhoneLegal, 1000)
    this.isputapp = debounce(this.isputapp, 1000)
  }
  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    this.getApiInfo()
  }
  componentWillUnmount() {
    localStorage.removeItem('props')
    window.removeEventListener('resize', this.handleResize)
    clearTimeout(this.state.settimer2)
    clearTimeout(this.state.settimer1)
    sourceCancelClass()
  }
  handleResize = () => {
    var winHeight = window.innerHeight
    setTimeout(() => {
      if (window.innerHeight) {
        winHeight = window.innerHeight
      } else if (document.body && document.body.clientHeight) {
        winHeight = document.body.clientHeight
      }
      if (document.documentElement && document.documentElement.clientHeight) {
        winHeight = document.documentElement.clientHeight
      }
      let layoutHeaders_h = $('#layoutHeaders').innerHeight()
      if (layoutHeaders_h > winHeight) {
        layoutHeaders_h = 64
      }
      let height =
        winHeight -
        layoutHeaders_h -
        document.getElementById('renderHeaders').clientHeight -
        55
      document.getElementById('layout_id').style.height = height + 'px'
      let resultok = document.getElementById('resultok')

      if (resultok) {
        let result = document.getElementById('result')
        let badge = document.getElementById('badge')
        let content = document.getElementById('content')
        let height = result?.clientHeight - 125.14 - badge?.clientHeight
        content.style.height = height + 'px'
      }
      document.getElementsByClassName('entityExtraction')[0].style.opacity = '1'
      //console.log($("#layoutHeaders").innerHeight());
    }, 300)
  }
  putmodelversion(optionVlue) {
    let params = {
      id: optionVlue,
    }
    this.setState({
      ContinuationLoding: true,
      requestMsg: '',
    })
    putmodelversion(params).then((res) => {
      if (res.code === 200) {
        this.isPhoneLegal()
      } else {
        this.setState({
          ParagraphContinuation: {},
          ContinuationLoding: false,
          requestMsg: '',
          requestMsgText: '模型版本激活失败',
        })
      }
    })
  }
  getApiInfo(type) {
    let params = {
      app_id: this.state.record.id,
    }
    getApiInfo(params).then((res) => {
      if (res.code === 200) {
        let list = res.data
        if (res.data.model_params) {
          history.push('/myapp/enteringGinseng')
          history.go()
        } else {
          if (type) {
            this.setState({
              apiInfo: list,
            })
          } else {
            this.setState({
              apiInfo: list,
              versions: list.versions,
            })
            // document.title = list.app_name
            if (list.versions.length > 0) {
              this.setState({
                optionVlue: list.versions[0].id,
              })
              // this.putmodelversion(list.versions[0].id)
            }
            if (list.type === 0) {
              this.setState({
                ParagraphContinuation: {},
                ContinuationLoding: true,
                requestMsg: '',
                TextAreaValue: list.input_describe ? list.input_describe : '',
              })
              clearTimeout(this.state.settimer2)
              clearTimeout(this.state.settimer1)
              sourceCancelClass()
              this.isPhoneLegal()
            }
          }
        }
        //console.log(res.data, "api服务");
      } else {
      }
    })
  }
  onsetPosition(value) {
    //console.log(value, "value");
    clearTimeout(this.state.settimer2)
    clearTimeout(this.state.settimer1)
    sourceCancelClass()
    this.setState({
      optionVlue: value,
    })
    this.putmodelversion(value)
  }
  onTextArea(value) {
    this.setState({
      TextAreaValue: value,
    })
    if (value.length === 0) {
      this.setState({
        ParagraphContinuation: {},
        ContinuationLoding: false,
        requestMsg: '',
        requestMsgText: '您还未输入文段',
      })
    } else if (value.length > 256) {
      this.setState({
        ParagraphContinuation: {},
        ContinuationLoding: false,
        requestMsg: '',
        requestMsgText: '超出字符限制，请删减内容',
      })
    } else {
      this.setState({
        ParagraphContinuation: {},
        ContinuationLoding: false,
        requestMsg: '',
        requestMsgText: '可点击生成按钮生成结果',
      })
    }
    // this.isPhoneLegal()
    //console.log(value);
  }
  isPhoneLegal = () => {
    this.setState({
      ParagraphContinuation: {},
      ContinuationLoding: true,
      requestMsg: '',
    })
    clearTimeout(this.state.settimer2)
    clearTimeout(this.state.settimer1)
    sourceCancelClass()
    this.state.settimer2 = setTimeout(() => {
      this.setRequest()
    }, 1000)
  }
  ChangeAbatch() {
    clearTimeout(this.state.settimer2)
    clearTimeout(this.state.settimer1)
    sourceCancelClass()
    this.setState({
      ParagraphContinuation: {},
      ContinuationLoding: true,
      requestMsg: '',
    })
    this.state.settimer1 = setTimeout(() => {
      this.setRequest()
    }, 1000)
  }
  onKeyDown(event) {
    //console.log(event.keyCode, "键盘事件");
    if (event.shiftKey && event.keyCode === 13) {
      var e = this.state.TextAreaValue
      this.setState({
        TextAreaValue: e,
      })
    } else if (event.ctrlKey && event.keyCode === 13) {
      event.cancelBubble = true
      event.preventDefault()
      event.stopPropagation()
      event.returnValue = false
      this.ChangeAbatch()
      return false
    } else if (event.keyCode === 13) {
      event.cancelBubble = true
      event.preventDefault()
      event.stopPropagation()
      this.ChangeAbatch()
      event.returnValue = false
      return false
    }
  }
  setRequest() {
    if (this.state.TextAreaValue.length === 0) {
      this.setState({
        ParagraphContinuation: {},
        ContinuationLoding: false,
        requestMsg: '',
        requestMsgText: '您还未输入文段',
      })
    } else if (this.state.TextAreaValue.length > 256) {
      this.setState({
        ParagraphContinuation: {},
        ContinuationLoding: false,
        requestMsg: '',
        requestMsgText: '超出字符限制，请删减内容',
      })
    } else {
      this.getCheckResource()
    }
  }
  sendRequest() {
    let params = {
      app_id: this.state.record.id,
      text: this.filterHTMLTag(this.state.TextAreaValue),
    }
    if (this.state.optionVlue) {
      params.version_id = this.state.optionVlue
    }
    getRequest(params).then((res) => {
      const code = [400, 3003, 204]
      if (res.code === 200) {
        let list = res.data
        this.setState({
          ParagraphContinuation: list,
          ContinuationLoding: false,
          requestMsg: '',
        })
        //console.log(res.data, "结果");
      } else if (code.includes(res.code)) {
        this.setState({
          ContinuationLoding: false,
          requestMsg: '',
          requestMsgText: res.msg,
        })
      } else if (res.code === 204) {
        this.setState({
          ParagraphContinuation: {},
          ContinuationLoding: false,
          requestMsg: 204,
        })
      } else {
        this.setState({
          ContinuationLoding: false,
          requestMsg: 'error',
          requestMsgText: res.msg,
        })
      }
      this.handleResize()
    })
  }
  getCheckResource() {
    let params = { model_capability: this.state.apiInfo?.model_capability }
    let PACKAGES = ['PACKAGE_FINISH', 'PACKAGE_EXPIRED']
    check_resource(params).then((res) => {
      if (res.code === 200) {
        if (res.data) {
          let new_upgradetips = res.data
          new_upgradetips.model_capability = params.model_capability
          this.setState({
            upgradetips: new_upgradetips,
          })
          if (PACKAGES[new_upgradetips.notice_type]) {
            Notification.warning({ content: new_upgradetips.message })
          } else {
            setTimeout(() => {
              this.setState({
                visibleUpgrade: true,
              })
            }, 100)
          }
        }
        this.sendRequest()
      }
    })
  }
  goResources() {
    history.push('/myapp/resources')
    localStorage.setItem('record', JSON.stringify(this.state.record))
    window.location.reload()
  }
  goInterfacetest() {
    history.push('/myapp/interfacetest')
    localStorage.setItem('record', JSON.stringify(this.state.record))
    window.location.reload()
  }
  oncopy(item) {
    let value = this.state.TextAreaValue + item.sentence
    if (copy(value)) {
      Notification.success({ title: 'Success', content: '复制成功!' })
    } else {
      Notification.success({ title: 'Error', content: '复制失败!' })
    }
  }
  isEmptyObject = (obj) => {
    for (var n in obj) {
      return false
    }
    return true
  }
  setContinuation = () => {
    const { TextAreaValue, ParagraphContinuation } = this.state
    let sqlstring = []

    if (ParagraphContinuation?.data?.length > 0) {
      ParagraphContinuation.data.map((item, index) => {
        sqlstring.push(item)
      })
    }
    let arr = JSON.parse(JSON.stringify(sqlstring))
    // arr.sort((a, b) => a.at(-1).at(-1) - b.at(-1).at(-1));
    // .at()是ES2022的新语法，目前兼容性还不是很好，慎用！
    // .at()支持正索引和负索引
    arr.sort(function (a, b) {
      // 对sort方法进行重写，本质是冒泡排序
      let a_index = a.ids[0]
      let b_index = b.ids[0]
      return a_index - b_index
    })
    // console.log(arr, "排序",TextAreaValue);
    return <div>{this.findHighlight(arr, TextAreaValue)}</div>
  }
  //str:原始字符串，start:开始位置,end:结束位置
  changeStr = (str, start, end) => {
    return str.substr(start, end)
  }
  filterHTMLTag = (msg) => {
    var msg = msg.replace(/<\/?[^>]*>/g, '') //去除HTML Tag
    msg = msg.replace(/<([a-zA-Z]+)\s*[^><]*>/g, '<$1>')
    msg = msg.replace(/[|]*\n/, '') //去除行尾空格
    msg = msg.replace(/&npsp;/gi, '') //去掉npsp
    return msg
  }
  generateRamStr(len, charSet) {
    const chars =
      charSet ||
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let randomStr = ''
    for (var i = 0; i < len; i++) {
      randomStr += chars.charAt(Math.floor(Math.random() * chars.length))
    }
    return randomStr
  }
  // 根据下标替换字符
  oneReplace(str, index, neWords) {
    let arr = str.split('')
    arr[index] = neWords
    return arr.join('')
  }
  lengthReplace(text, start, stop, replacetext) {
    let newstr =
      text.substring(0, start) + replacetext + text.substring(stop + 1)
    return newstr
  }
  //所有sql关键字
  sqlText = (sqlstring, newstr) => {
    let reqstr = []
    // reg = /[~#^$@%&!?%<>/=:""''*]/gi
    // //console.log(sqlstring, newstr, "sqlstring, newstr, key, type");
    sqlstring.map((string, key) => {
      let reg
      let listitem = string.item
      //console.log(listitem, "listitem");
      let unique = []
      listitem.map((item) => {
        var containSpecial = RegExp(
          /[(\ )(\~)(\!)(\@)(\#)(\$)(\%)(\^)(\&)(\*)(\()(\))(\-)(\_)(\+)(\=)(\[)(\])(\{)(\})(\|)(\\)(\;)(\:)(\')(\")(\,)(\.)(\/)(\<)(\>)(\?)(\)]+/
        )
        var flag = new RegExp(
          "[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]"
        )
        let regNewstr = this.filterHTMLTag(this.state.TextAreaValue) //过滤掉所有HTML标签

        if (item[0].length === 1) {
          // //console.log(item[0]);
          if (!unique.includes(regNewstr[item[1][0]])) {
            if (
              containSpecial.test(regNewstr[item[1][0]]) ||
              flag.test(regNewstr[item[1][0]])
            ) {
              reg = ''
            } else {
              reg = regNewstr[item[1][0]]
              unique.push(reg.toLowerCase())
            }
          }
        } else {
          let endindex = item[1].length - 1
          let end = item[1][endindex] //结束下标
          let start = item[1][0] //开始小标
          if (!unique.includes(regNewstr.substring(start, end + 1))) {
            reg = regNewstr.substring(start, end + 1) //根据下标获取内容
            unique.push(reg.toLowerCase())
          }
        }

        // //console.log(regNewstr, '：过滤后标签')
        //进行内容替换操作
        if (reg !== '') {
          let regnew = reg.replace(/([\+\(\)\{\}\.\-\$\#\&\*\/]){1}/g, '\\$1')
          regnew = new RegExp(regnew + '(?![^<>]*>)', 'i')
          // //console.log(
          //   item[0] + "<-对比->",
          //   regnew + ":reg:" + reg,
          //   "总长：" + regNewstr.length
          // );
          let html = `<span style="color:red">${reg}</span>`
          newstr = newstr.replace(regnew, html)
        }
        //这里替换的前后两个变量必须一样
      })
    })
    let html = { __html: newstr.replaceAll('\n', '<br/>') }
    return (
      <div className="Continuation-item" dangerouslySetInnerHTML={html}></div>
    )
  }
  findHighlight = (sqlstring, keyWord) => {
    let startindex = 0
    let replaceMap = []
    if (sqlstring?.length > 0) {
      sqlstring.map((item, key) => {
        let reg
        keyWord = this.filterHTMLTag(this.state.TextAreaValue) //过滤掉所有HTML标签
        let endindex = item.ids.length - 1
        let end = item['ids'][endindex] //结束下标
        let start = item['ids'][0] //开始小标
        let startindexKeyWord = keyWord.substring(startindex, end + 1)
        reg = keyWord.substring(start, end + 1) //根据下标获取内容
        let html = `<span style="color: red;cursor: default;">${item.correct}</span>`
        startindexKeyWord = startindexKeyWord.replace(reg, html)
        replaceMap.push(startindexKeyWord)
        startindex = end + 1
        if (sqlstring.length === key + 1) {
          startindexKeyWord = keyWord.substring(startindex)
          replaceMap.push(startindexKeyWord)
        }
      })
    }

    let newHTML =
      replaceMap.length > 0 ? replaceMap.join('') : this.state.TextAreaValue

    let htmls = { __html: newHTML.replaceAll('\n', '<br/>') }
    return (
      <div className="Continuation-item" dangerouslySetInnerHTML={htmls}></div>
    )
  }

  isputapp = (name, desc) => {
    this.putapp(name, desc)
  }
  putapp(name, desc) {
    let params = {
      id: this.state.record.id,
    }
    if (desc !== false) {
      params.desc = desc
    } else if (name !== false) {
      params.name = name
    }
    putapp(params).then((res) => {
      if (res.code === 200) {
      } else {
        this.getApiInfo(true)
        Notification.error({ content: res.msg })
      }
    })
  }
  onChangetitle(type, value) {
    //console.log(value, "value");
    var apiInfo = this.state.apiInfo
    if (type === 'desc') {
      if (value.length > 100) {
        apiInfo.app_desc = value.substring(0, 100)
        Notification.warning({ content: '修改内容超过100个字！' })
        return
      }
      apiInfo.app_desc = value
      this.setState({
        apiInfo: apiInfo,
      })
      this.isputapp(false, value)
    } else if (type === 'name') {
      if (value.length > 20) {
        apiInfo.app_name = value.substring(0, 20)
        Notification.warning({ content: '修改标题超过20个字！' })
        return
      }
      apiInfo.app_name = value
      this.setState({
        apiInfo: apiInfo,
      })
      this.isputapp(value, false)
    }
  }
  goaddApp() {
    history.replace('/myapp/addmyapp')
    let state = {
      model_capability: this.state.apiInfo.model_capability,
      checkedtype: this.state.apiInfo.model_id,
      model_id: this.state.apiInfo.model_id,
      name: this.state.apiInfo.model_name,
    }
    localStorage.setItem('props', JSON.stringify(state))
    localStorage.removeItem('record')
    window.location.reload()
  }
  addRow() {
    localStorage.removeItem('props')
    localStorage.setItem('record', JSON.stringify(this.state.apiInfo))
    history.replace('/enginefactory/model/management/add')
    window.location.reload()
  }
  setvisibleUpgrade(value) {
    this.setState({
      visibleUpgrade: value,
    })
  }
  render() {
    const {
      visibleUpgrade,
      upgradetips,
      apiInfo,
      versions,
      input_describe,
      ParagraphContinuation,
      ContinuationLoding,
      TextAreaValue,
      optionVlue,
      requestMsg,
      requestMsgText,
    } = this.state

    return (
      <div className="entityExtraction">
        <div id="renderHeaders">
          <div className="GroupTable-fixe GroupTable-fixe-end">
            <div className="edit-title">
              <div className="Header-init">
                <div className="Header-title">
                  <Typography.Paragraph
                    className="Header-uptitle"
                    editable={
                      apiInfo.type === 0
                        ? null
                        : {
                            onChange: this.onChangetitle.bind(this, 'name'),
                          }
                    }
                  >
                    {apiInfo.app_name}
                  </Typography.Paragraph>
                </div>
              </div>
              <div className="Header-upParagraph">
                <Typography.Paragraph
                  className="Header-uptitle"
                  editable={
                    apiInfo.type === 0
                      ? null
                      : {
                          onChange: this.onChangetitle.bind(this, 'desc'),
                        }
                  }
                >
                  {apiInfo.app_desc}
                </Typography.Paragraph>
              </div>
            </div>
            <Space size={'medium'} align="center">
              {apiInfo.type === 0 ? (
                <Button
                  className="primarybtn custom-White-button"
                  onClick={this.goaddApp.bind(this)}
                >
                  保存应用
                </Button>
              ) : (
                <>
                  {versions.length === 0 ? (
                    <div></div>
                  ) : (
                    <Select
                      value={optionVlue}
                      placeholder="版本选择"
                      style={{ width: 220 }}
                      className="versionsSelect"
                      onChange={this.onsetPosition.bind(this)}
                      dropdownRender={(menu) => (
                        <div>
                          {menu}
                          <Divider
                            style={{
                              margin: 0,
                              marginTop: 6,
                              borderColor: '#f2f2f2',
                            }}
                          />
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              padding: '5px 0px',
                            }}
                          >
                            <Button
                              style={{
                                width: '100%',
                                height: 41,
                                fontSize: 14,
                                padding: '0 6px',
                                borderRadius: 10,
                              }}
                              type="text"
                              onClick={this.addRow.bind(this)}
                            >
                              <IconPlus />
                              新增模型版本
                            </Button>
                          </div>
                        </div>
                      )}
                    >
                      {versions.map((option, index) => (
                        <Option key={index} value={option.id}>
                          {apiInfo.model_name}V{option.version}
                        </Option>
                      ))}
                    </Select>
                  )}
                  {apiInfo.type === 9 ? (
                    <div className="primary_model_name">
                      <div className="primary_model_name_fix">
                        <span className="primary_model_name_title">
                          模型
                          <Divider type="vertical" />
                        </span>
                        <Typography.Paragraph ellipsis>
                          {apiInfo.model_name}
                        </Typography.Paragraph>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {/* <Button
                    shape="circle"
                    className="custom-White-button"
                    onClick={this.goResources.bind(this)}
                  >
                    应用资源
                  </Button> */}
                  <Button
                    shape="circle"
                    className="custom-White-button share_button_wnk"
                    onClick={CreateLink.bind(this, apiInfo)}
                  >
                    分享应用
                  </Button>
                  <Button
                    style={{
                      display:
                        this.state.identity &&
                        this.state.identity !== 'PERSONAL'
                          ? 'inline-block'
                          : 'none',
                    }}
                    shape="circle"
                    className="custom-White-button"
                    onClick={this.goInterfacetest.bind(this)}
                  >
                    接口调用
                  </Button>
                </>
              )}
            </Space>
          </div>
        </div>
        <Layout className="semanticprototype-Layout" id="layout_id">
          <Sider className="Sider-left">
            <div className="Sider-left-configure columnSpaceBetween">
              <div className="Continuation-input">
                <TextArea
                  placeholder={input_describe}
                  style={{ width: '100%' }}
                  maxLength={{ length: 1600, errorOnly: true }}
                  showWordLimit
                  value={TextAreaValue}
                  onChange={this.onTextArea.bind(this)}
                  onKeyDown={(event) => this.onKeyDown(event)}
                />
              </div>
              <div className="generation-bottom">
                <Button
                  type="primary"
                  className="Start-generation"
                  onClick={this.isPhoneLegal.bind(this)}
                >
                  开始纠错
                </Button>
              </div>
            </div>
          </Sider>
          <Content>
            <div className="experience-result" id="result">
              <div id="result_title" className="result_title">
                <div className="rowSpaceBetween">
                  <Typography>生成结果</Typography>
                  <Typography.Paragraph className="result-remarks">
                    内容基于韦尼克AI创作引擎生成，仅供参考
                  </Typography.Paragraph>
                </div>
                <Divider />
              </div>
              {!this.isEmptyObject(ParagraphContinuation) ||
              ContinuationLoding ||
              requestMsg === 204 ? (
                <div className="Paragraph-generate">
                  {ContinuationLoding ? (
                    <Empty
                      className="Continuation-loding"
                      icon={<img src={Generating_img} />}
                      description={
                        <Typography
                          style={{
                            color: '#4e5969',
                            marginTop: 21,
                            fontSize: 16,
                          }}
                        >
                          正在生成中...
                        </Typography>
                      }
                    />
                  ) : (
                    <div className="Continuation-result" id="resultok">
                      <div className="Continuation-list" id="content">
                        {requestMsg === 204
                          ? this.state.TextAreaValue
                          : this.setContinuation()}
                      </div>
                    </div>
                  )}
                </div>
              ) : requestMsg === 'error' ? (
                <Empty
                  className="Continuation-Empty Empty-error"
                  imgSrc={errorIcon}
                  description={
                    <Space direction="vertical">
                      <Typography
                        style={{
                          color: '#4e5969',
                          marginTop: 30,
                          fontSize: 16,
                        }}
                      >
                        抱歉，服务器生成错误
                      </Typography>
                      <Typography style={{ color: '#4e5969', fontSize: 16 }}>
                        可尝试
                        <span
                          style={{ color: '#406EFF', cursor: 'pointer' }}
                          onClick={this.ChangeAbatch.bind(this)}
                        >
                          {' '}
                          重新生成
                        </span>
                      </Typography>
                    </Space>
                  }
                />
              ) : (
                <Empty
                  className="Continuation-Empty Empty-none"
                  imgSrc={Emptydata_img}
                  description={
                    <Space direction="vertical">
                      <Typography
                        style={{
                          color: '#4e5969',
                          marginTop: 30,
                          fontSize: 16,
                        }}
                      >
                        暂无生成结果
                      </Typography>
                      <Typography style={{ color: '#4e5969', fontSize: 16 }}>
                        {requestMsgText}
                      </Typography>
                    </Space>
                  }
                />
              )}
            </div>
          </Content>
        </Layout>
        <UpgradeTips
          visible={visibleUpgrade}
          notification_status={upgradetips?.notice_type}
          notification_message={upgradetips?.message}
          model_capability={upgradetips?.model_capability}
          onCancel={() => this.setvisibleUpgrade(false)}
        ></UpgradeTips>
      </div>
    )
  }
}
export default WordErrorReset
