import React, { useState, useEffect, useRef } from "react";
import "./agreement.scss";
import { getagreement } from "api/apis";
export default function Agreementprivacy() {
  const [agreementhtml, setagreementhtml] = useState(null);
  const settagreement = (key) => {
    getagreement().then((res) => {
      if (res.code === 200) {
        var list = res.data;
        var html = "";
        for (var i = 0; i < list.length; i++) {
          if (list[i].key === key) {
            html = list[i].value;
            break;
          }
        }
        setagreementhtml(html);
      }
    });
  };
  const html2Escape = (str) => {
    //如果后台做富文本转义则前端执行富文本反转义html
    var arrEntities = { lt: "<", gt: ">", nbsp: " ", amp: "&", quot: '"' };
    return str.replace(/&(lt|gt|nbsp|amp|quot);/gi, function (all, t) {
      return arrEntities[t];
    });
  };
  useEffect(() => {
    settagreement("agreement-privacy-policy");
  }, []);
  return (
    <div className="agreement privacy">
      <div
        className="agreement-box editor-content-view"
        dangerouslySetInnerHTML={{ __html: agreementhtml }}
      ></div>
    </div>
  );
}
