import React, { Component } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import {
  Layout,
  Card,
  Space,
  Button,
  Typography,
} from "@arco-design/web-react";

import "./echnicalsupport.scss";
const Header = Layout.Header;

// 封装组件
class EchnicalSupport extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}
  UNSAFE_componentDidUpdate() {}

  render() {
    return (
      <div className="main-content echnicalsupport">
        <div className="Card-main">
          {/* <div className="Work-order-card"> */}
          <Outlet />
          {/* </div> */}
        </div>
      </div>
    );
  }
}
export default EchnicalSupport;
