import React, { Component } from "react";
import { Link } from "react-router-dom";
import routes from "router/index";
import { Menu } from "@arco-design/web-react";
import { IconUnorderedList } from "@arco-design/web-react/icon";
import "./basemenu.scss";
const MenuItem = Menu.Item;
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;
const setTitle = (title) => {
  // document.title = title

  let replaceList = [
    "/myapp/addmyapp",
    "/enginefactory/model/add",
    "/enginefactory/model/management/add",
    "/enginefactory/dataset/add",
  ];
  const urlParams = new URL(window.location.href);
  const pathname = urlParams?.pathname;
  if (!replaceList.includes(pathname)) {
    localStorage.setItem("title", title);
  }
  //console.log(title, 'titletitletitle')
};
class BaseMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: "",
      openKeys: [],
      menuList: routes[0].children,
    };
  }
  initkey(key) {
    let nowkey = this.sliceURL(key);
    this.state.openKeys = nowkey;
    this.state.current = key;
    this.setState({
      openKeys: nowkey,
    });
    this.allMapUrl(routes[0].children, nowkey, key);
    //console.log(this.state.openKeys, this.state.current)
  }
  allMapUrl(list, nowkey, key) {
    list.map((pre, item) => {
      if (pre.showchildren !== false) {
        if (pre.path === nowkey[0]) {
          if (!pre.children) {
            if (pre.path === key && pre.visual) {
              this.setState({
                current: pre.path,
              });
              setTitle(pre.title);
            }
          } else {
            this.allMapUrl(pre.children, nowkey, key);
          }
        } else {
          if (!pre.children) {
            if (pre.path === key && pre.visual) {
              this.setState({
                current: pre.path,
              });
            } else {
              if (nowkey.indexOf(pre.path) > -1) {
                nowkey.map((_, index) => {
                  if (pre.path === nowkey[index] && pre.visual) {
                    this.setState({
                      current: pre.path,
                    });
                    setTitle(pre.title);
                  }
                });
              }
            }
          } else {
            this.allMapUrl(pre.children, nowkey, key);
          }
        }
      } else {
        if (pre.path === nowkey[0]) {
          this.setState({
            current: nowkey[0],
          });
          setTitle(pre.title);
        }
      }
    });
  }
  sliceURL(key) {
    let listmap = key.split("/").filter((i) => i);
    let nowkey = listmap.map((_, index) => {
      let url = `/${listmap.slice(0, index + 1).join("/")}`;
      return url;
    });
    return nowkey;
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    // this.setState({ selectedKeys: nextProps.selectedKeys });
  }
  componentDidMount() {
    // 在组件挂载后（插入 DOM 树中）立即调用
    const urlParams = new URL(window.location.href);
    const pathname = urlParams?.pathname;
    // 防止页面刷新导致菜单栏选中失效
    this.initkey(pathname);
  }

  componentWillUnmount() {
    //在组件卸载及销毁之前直接调用
    localStorage.removeItem("Menu");
  }
  UNSAFE_componentDidUpdate() {}
  //  * 根据menu的数据数组生成对于标签数组
  //  * 使用reduce() + 递归调用
  //  *
  getMenuNodes = (menuList) => {
    const state = this.state;
    return menuList.reduce((pre, item) => {
      if (!item.children) {
        //向pre中添加<Menu.Item>
        if (item.visual) {
          pre.push(
            <MenuItem key={item.path} disabled={item.disabled}>
              <Link to={item.path} style={{ display: "block" }}>
                <span className={`arco-icon iconfont ` + item.icon}></span>
                {item.title}
              </Link>
            </MenuItem>
          );
        }
      } else {
        //向pre中添加<SubMenu>
        if (item.visual) {
          if (item.showchildren !== false) {
            // //console.log('多级', select, state.openKeys, state.current)
            pre.push(
              <SubMenu
                key={item.path}
                disabled={item.disabled}
                title={
                  <>
                    <span className={"arco-icon iconfont " + item.icon}></span>
                    {item.title}
                  </>
                }
              >
                {this.getMenuNodes(item.children)}
              </SubMenu>
            );
          } else {
            pre.push(
              <MenuItem key={item.path} disabled={item.disabled}>
                <Link to={item.path} style={{ display: "block" }}>
                  <span className={`arco-icon iconfont ` + item.icon}></span>
                  {item.title}
                </Link>
                {this.getMenuNodes(item.children)}
              </MenuItem>
            );
          }
        }
      }
      localStorage.setItem("Menu", JSON.stringify(state));
      return pre;
    }, []);
  };
  onSubMenu = (key, openKeys, keyPath) => {
    const state = this.state;
    this.setState({ openKeys: openKeys });
    // //console.log(state.openKeys, 'onSubMenu')
    localStorage.removeItem("HistoryList");
    this.setHistoryList();
  };
  onMenuItem = (key, openKeys, keyPath) => {
    const state = this.state;
    this.setState({ current: key, openKeys: keyPath });
    setTitle(openKeys.target.innerText ? openKeys.target.innerText : "");
    localStorage.removeItem("HistoryList");
    this.setHistoryList();
    // //console.log(openKeys.target.innerText, 'onMenuItem')
    // //console.log(state.openKeys,'onMenuItem')
  };
  setHistoryList() {
    const urlParams = new URL(window.location.href);
    const pathname = urlParams?.pathname;
    let HistoryList = JSON.parse(localStorage.getItem("HistoryList"))
      ? JSON.parse(localStorage.getItem("HistoryList"))
      : [];
    if (HistoryList.length > 0) {
      if (pathname === HistoryList[HistoryList.length - 1].url) {
        // 相同相邻路径不记录
      } else {
        if (pathname !== "/shareMyapp") {
          let obj = {
            title: localStorage.getItem("title"),
            url: pathname,
            record: "",
            props: "",
          };
          HistoryList.push(obj);
        }

        localStorage.setItem("HistoryList", JSON.stringify(HistoryList));
      }
    } else {
      if (pathname !== "/shareMyapp") {
        let obj = {
          title: localStorage.getItem("title"),
          url: pathname,
          record: "",
          props: "",
        };
        HistoryList.push(obj);
      }
      localStorage.setItem("HistoryList", JSON.stringify(HistoryList));
    }
    //console.log('历史路由记录')
    //console.log(pathname, ':pathname,HistoryList:', HistoryList)
  }
  render() {
    return (
      <div
        className="menu-demo"
        style={{
          width: 226,
          height: "100%",
        }}
      >
        <Menu
          theme="light"
          style={{ width: "100%", height: "100%" }}
          openKeys={this.state.openKeys}
          selectedKeys={[this.state.current]}
          onClickMenuItem={this.onMenuItem}
          onClickSubMenu={this.onSubMenu}
        >
          {
            this.getMenuNodes(this.state.menuList) //获取menu的所有子节点
          }
        </Menu>
      </div>
    );
  }
}

export default BaseMenu;
