import React, { Component } from "react";
import { Typography, Modal, Spin, Radio, Space } from "@arco-design/web-react";
import "./index.scss";
import icon1 from "asserts/Intelligentwriting_icon.svg";
import icon2 from "asserts/Intelligentgraphic_icon.svg";
import icon3 from "asserts/Smartdocument_icon.svg";
import icon4 from "asserts/Intelligentcreation_icon.svg";
import icon5 from "asserts/knowledge_graph.svg";
import icon6 from "asserts/servesIcon.svg";

class ModalSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingModal: false,
      model_capability: "",
      selectoptions: props.selectoptions,
      visible: props.visible,
      GroupValue: "",
    };
  }
  componentDidMount() {
    this.loadData();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      visible: nextProps.visible,
      loadingModal: false,
      selectoptions: nextProps.selectoptions,
    });
  }

  loadData() {
    this.setState({
      loadingModal: false,
    });
  }
  onCancel() {
    this.setState({
      visible: false,
      model_capability: "",
    });
  }
  onRadioGroup(value) {
    this.setState({
      model_capability: value,
    });
  }
  render() {
    const { selectoptions, visible, GroupValue } = this.state;
    return (
      <Modal
        title="请选择创建应用的服务类型"
        visible={visible}
        selectoptions={selectoptions ? selectoptions : []}
        onOk={() => this.props.setVisible(this.state)}
        onCancel={() => this.props.onCancel()}
        className="selectModalAdd"
      >
        <Spin tip="正在加载数据" loading={this.state.loadingModal}>
          <div
            style={{
              visibility: !this.state.loadingModal ? "visible" : "hidden",
            }}
          >
            {selectoptions ? (
              <Space direction="vertical">
                <Space align="center" className="selectModal-title">
                  <img src={icon1} />
                  <Typography.Text>智能写作</Typography.Text>
                </Space>
                <Radio.Group
                  onChange={this.onRadioGroup.bind(this)}
                  value={this.state.model_capability}
                  className="custom-Group"
                >
                  {Object.keys(selectoptions ? selectoptions : []).map(
                    (item, i) => {
                      return selectoptions[item].group === "智能写作" &&
                        selectoptions[item].status !== 2 ? (
                        <Radio
                          key={i}
                          value={item}
                          disabled={
                            selectoptions[item].status === 0 ? true : false
                          }
                        >
                          <Space
                            align="start"
                            className={`custom-radio-card ${
                              this.state.model_capability === item
                                ? "custom-radio-card-checked"
                                : ""
                            }`}
                          >
                            {selectoptions[item].status === 0 ? (
                              <div className="custom-radio-tag-disabled"></div>
                            ) : (
                              ""
                            )}
                            <div className="custom-radio-card-mask">
                              <div className="custom-radio-card-mask-dot"></div>
                            </div>
                            <div>
                              <Typography.Title
                                heading={6}
                                ellipsis={{
                                  showTooltip: false,
                                  expandable: false,
                                }}
                                className="custom-radio-card-title"
                              >
                                {selectoptions[item].label}
                              </Typography.Title>
                              <Typography.Text
                                type="secondary"
                                data-tip={selectoptions[item].title}
                                ellipsis={{
                                  rows: 1,
                                  showTooltip: false,
                                  expandable: false,
                                }}
                              >
                                {selectoptions[item].title}
                              </Typography.Text>
                            </div>
                          </Space>
                        </Radio>
                      ) : (
                        ""
                      );
                    }
                  )}
                </Radio.Group>
                <Space align="center" className="selectModal-title">
                  <img src={icon2} />
                  <Typography.Text>智能图文</Typography.Text>
                </Space>
                <Radio.Group
                  onChange={this.onRadioGroup.bind(this)}
                  value={this.state.model_capability}
                  className="custom-Group"
                >
                  {Object.keys(selectoptions ? selectoptions : []).map(
                    (item, i) => {
                      return selectoptions[item].group === "智能图文" &&
                        selectoptions[item].status !== 2 ? (
                        <Radio
                          key={item}
                          value={item}
                          disabled={
                            selectoptions[item].status === 0 ? true : false
                          }
                        >
                          <Space
                            align="start"
                            className={`custom-radio-card ${
                              this.state.model_capability === item
                                ? "custom-radio-card-checked"
                                : ""
                            }`}
                          >
                            {selectoptions[item].status === 0 ? (
                              <div className="custom-radio-tag-disabled"></div>
                            ) : (
                              ""
                            )}
                            <div className="custom-radio-card-mask">
                              <div className="custom-radio-card-mask-dot"></div>
                            </div>
                            <div>
                              <Typography.Title
                                heading={6}
                                ellipsis={{
                                  showTooltip: false,
                                  expandable: false,
                                }}
                                className="custom-radio-card-title"
                              >
                                {selectoptions[item].label}
                              </Typography.Title>
                              <Typography.Text
                                type="secondary"
                                ellipsis={{
                                  rows: 1,
                                  showTooltip: false,
                                  expandable: false,
                                }}
                              >
                                {selectoptions[item].title}
                              </Typography.Text>
                            </div>
                          </Space>
                        </Radio>
                      ) : (
                        ""
                      );
                    }
                  )}
                </Radio.Group>
                <Space align="center" className="selectModal-title">
                  <img src={icon3} />
                  <Typography.Text>智能文档</Typography.Text>
                </Space>
                <Radio.Group
                  onChange={this.onRadioGroup.bind(this)}
                  value={this.state.model_capability}
                  className="custom-Group"
                >
                  {Object.keys(selectoptions ? selectoptions : []).map(
                    (item, i) => {
                      return selectoptions[item].group === "智能文档" &&
                        selectoptions[item].status !== 2 ? (
                        <Radio
                          key={item}
                          value={item}
                          disabled={
                            selectoptions[item].status === 0 ? true : false
                          }
                        >
                          <Space
                            align="start"
                            className={`custom-radio-card ${
                              this.state.model_capability === item
                                ? "custom-radio-card-checked"
                                : ""
                            }`}
                          >
                            {selectoptions[item].status === 0 ? (
                              <div className="custom-radio-tag-disabled"></div>
                            ) : (
                              ""
                            )}
                            <div className="custom-radio-card-mask">
                              <div className="custom-radio-card-mask-dot"></div>
                            </div>
                            <div>
                              <Typography.Title
                                heading={6}
                                ellipsis={{
                                  showTooltip: false,
                                  expandable: false,
                                }}
                                className="custom-radio-card-title"
                              >
                                {selectoptions[item].label}
                              </Typography.Title>
                              <Typography.Text
                                type="secondary"
                                ellipsis={{
                                  rows: 1,
                                  showTooltip: false,
                                  expandable: false,
                                }}
                              >
                                {selectoptions[item].title}
                              </Typography.Text>
                            </div>
                          </Space>
                        </Radio>
                      ) : (
                        ""
                      );
                    }
                  )}
                </Radio.Group>
                <Space align="center" className="selectModal-title">
                  <img src={icon4} />
                  <Typography.Text>智能内容应用</Typography.Text>
                </Space>
                <Radio.Group
                  onChange={this.onRadioGroup.bind(this)}
                  value={this.state.model_capability}
                  className="custom-Group"
                >
                  {Object.keys(selectoptions ? selectoptions : []).map(
                    (item, i) => {
                      return selectoptions[item].group === "智能内容应用" &&
                        selectoptions[item].status !== 2 ? (
                        <Radio
                          key={item}
                          value={item}
                          disabled={
                            selectoptions[item].status === 0 ? true : false
                          }
                        >
                          <Space
                            align="start"
                            className={`custom-radio-card ${
                              this.state.model_capability === item
                                ? "custom-radio-card-checked"
                                : ""
                            }`}
                          >
                            {selectoptions[item].status === 0 ? (
                              <div className="custom-radio-tag-disabled"></div>
                            ) : (
                              ""
                            )}
                            <div className="custom-radio-card-mask">
                              <div className="custom-radio-card-mask-dot"></div>
                            </div>
                            <div>
                              <Typography.Title
                                heading={6}
                                ellipsis={{
                                  showTooltip: false,
                                  expandable: false,
                                }}
                                className="custom-radio-card-title"
                              >
                                {selectoptions[item].label}
                              </Typography.Title>
                              <Typography.Text
                                type="secondary"
                                ellipsis={{
                                  rows: 1,
                                  showTooltip: false,
                                  expandable: false,
                                }}
                              >
                                {selectoptions[item].title}
                              </Typography.Text>
                            </div>
                          </Space>
                        </Radio>
                      ) : (
                        ""
                      );
                    }
                  )}
                </Radio.Group>
                <Space align="center" className="selectModal-title">
                  <img src={icon5} />
                  <Typography.Text>知识图谱</Typography.Text>
                </Space>
                <Radio.Group
                  onChange={this.onRadioGroup.bind(this)}
                  value={this.state.model_capability}
                  className="custom-Group"
                >
                  {Object.keys(selectoptions ? selectoptions : []).map(
                    (item, i) => {
                      return selectoptions[item].group === "知识图谱" &&
                        selectoptions[item].status !== 2 ? (
                        <Radio
                          key={item}
                          value={item}
                          disabled={
                            selectoptions[item].status === 0 ? true : false
                          }
                        >
                          <Space
                            align="start"
                            className={`custom-radio-card ${
                              this.state.model_capability === item
                                ? "custom-radio-card-checked"
                                : ""
                            }`}
                          >
                            {selectoptions[item].status === 0 ? (
                              <div className="custom-radio-tag-disabled"></div>
                            ) : (
                              ""
                            )}
                            <div className="custom-radio-card-mask">
                              <div className="custom-radio-card-mask-dot"></div>
                            </div>
                            <div>
                              <Typography.Title
                                heading={6}
                                ellipsis={{
                                  showTooltip: false,
                                  expandable: false,
                                }}
                                className="custom-radio-card-title"
                              >
                                {selectoptions[item].label}
                              </Typography.Title>
                              <Typography.Text
                                type="secondary"
                                ellipsis={{
                                  rows: 1,
                                  showTooltip: false,
                                  expandable: false,
                                }}
                              >
                                {selectoptions[item].title}
                              </Typography.Text>
                            </div>
                          </Space>
                        </Radio>
                      ) : (
                        ""
                      );
                    }
                  )}
                </Radio.Group>

                <Space align="center" className="selectModal-title">
                  <img src={icon6} />
                  <Typography.Text>NLP基础引擎</Typography.Text>
                </Space>
                <Radio.Group
                  onChange={this.onRadioGroup.bind(this)}
                  value={this.state.model_capability}
                  className="custom-Group"
                >
                  {Object.keys(selectoptions ? selectoptions : []).map(
                    (item, i) => {
                      return selectoptions[item].group === "NLP基础引擎" &&
                        selectoptions[item].status !== 2 ? (
                        <Radio
                          key={item}
                          value={item}
                          disabled={
                            selectoptions[item].status === 0 ? true : false
                          }
                        >
                          <Space
                            align="start"
                            className={`custom-radio-card ${
                              this.state.model_capability === item
                                ? "custom-radio-card-checked"
                                : ""
                            }`}
                          >
                            {selectoptions[item].status === 0 ? (
                              <div className="custom-radio-tag-disabled"></div>
                            ) : (
                              ""
                            )}
                            <div className="custom-radio-card-mask">
                              <div className="custom-radio-card-mask-dot"></div>
                            </div>
                            <div>
                              <Typography.Title
                                heading={6}
                                ellipsis={{
                                  showTooltip: false,
                                  expandable: false,
                                }}
                                className="custom-radio-card-title"
                              >
                                {selectoptions[item].label}
                              </Typography.Title>
                              <Typography.Text
                                type="secondary"
                                ellipsis={{
                                  rows: 1,
                                  showTooltip: false,
                                  expandable: false,
                                }}
                              >
                                {selectoptions[item].title}
                              </Typography.Text>
                            </div>
                          </Space>
                        </Radio>
                      ) : (
                        ""
                      );
                    }
                  )}
                </Radio.Group>
              </Space>
            ) : (
              ""
            )}
          </div>
        </Spin>
      </Modal>
    );
  }
}

export default ModalSelect;
