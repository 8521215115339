import React, { Component } from "react";
import {
  Table,
  Card,
  Typography,
  Button,
  Divider,
  Select,
  Input,
  Message,
  Progress,
  Space,
  Empty,
  Checkbox,
  Modal,
} from "@arco-design/web-react";
import {
  IconDownload,
  IconDelete,
  IconSettings,
  IconClose,
} from "@arco-design/web-react/icon";
import { Link } from "react-router-dom";
import {
  timeConsuming,
  getCurrentDate,
  handleDownload,
} from "common/utils/index";
import { getmodelVersionDetail, deletedatumdetail } from "api/apis";
import history from "common/utils/history";
import "../model.scss";
import Emptydata_img from "asserts/Emptydata_img.svg";

const inputRef = React.createRef();
const columns = [
  {
    title: "序号",
    dataIndex: "rank_id",
    align: "center",
    width: 100,
    headerCellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "数据集",
    dataIndex: "content",
    align: "left",
    headerCellStyle: {
      textAlign: "left",
    },
    width: "calc(100% - 100px)",
    render: (col, record, index) => <div>{JSON.parse(col)}</div>,
  },
];
class VersionDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: {
        sizeCanChange: false,
        showTotal: true,
        total: 0,
        pageSize: 10,
        current: 1,
        pageSizeChangeResetCurrent: true,
        showJumper: false,
      },
      loading: false,
      no_data: false,
      addkey: 3,
      pathSnippets: null,
      extraBreadcrumbItems: null,
      keyword: "",
      record: JSON.parse(localStorage.getItem("record")),
      Selectoptions: JSON.parse(localStorage.getItem("Selectoptions")),
      model_info: {},
      downloadUrl: "",
      checkbox: false,
      selectedRowKeys: [],
      type: "checkbox",
      checkAll: false,
      indeterminate: false,
      SelectcheckAll: false,
      visibledelete: false,
      confirmLoading: false,
    };
  }
  componentDidMount() {
    this.getdatumdetail(false, true);
  }
  getdatumdetail(loading, init) {
    let page = {
      per_page: this.state.pagination.pageSize,
      page: this.state.pagination.current,
      version_id: this.state.record.id,
      search_word: this.state.keyword,
    };
    this.setState({
      loading: loading,
    });
    getmodelVersionDetail(page).then((res) => {
      if (res.code === 200) {
        let list = res.data;
        let pagination = this.state.pagination;
        pagination.total = list.count;
        this.setState({
          pagination: pagination,
          data: list.datum_list,
          loading: false,
          loadingbody: false,
          model_info: list.model_info,
          downloadUrl: list.download_url,
        });
      } else {
        this.setState({
          loadingbody: false,
        });
      }
    });
  }
  onChangeTable(pagination) {
    const { current, pageSize } = pagination;
    let setPagination = this.state.pagination;
    setPagination.current = current;
    setPagination.pageSize = pageSize;
    this.setState({
      loading: false,
      pagination: setPagination,
    });
    this.getdatumdetail();
  }
  componentWillUnmount() {
    clearInterval(this.state.timeout);
  }
  UNSAFE_componentWillMount() {
    const urlParams = new URL(window.location.href);
    const pathname = urlParams?.pathname;
    //console.log(history, 'history', pathname)
    history.listen((route) => {
      //console.log(route, 'route'); // 这个route里面有当前路由的各个参数信息
    });
  }
  godetails(record) {
    localStorage.setItem("props", JSON.stringify(record));
  }
  onPressEnter(value) {
    //console.log(value, 'value')
    let setPagination = this.state.pagination;
    setPagination.current = 1;
    this.setState({
      keyword: value,
      pagination: setPagination,
    });
    this.state.keyword = value;
    this.getdatumdetail();
  }
  onDownload = () => {
    handleDownload(this.state.downloadUrl, "数据集");
  };
  setSelectedRowKeys(selectedRowKeys) {
    let selectkeys = [];
    this.state.data.forEach((i, index) => {
      // //console.log(i.enable_del)
      // if (i.enable_del) {
      selectkeys.push(i.id);
      // }
    });
    this.setState({
      selectedRowKeys: selectedRowKeys,
      SelectcheckAll:
        selectedRowKeys.length === selectkeys.length ? true : false,
    });
  }
  onChangeAll(checked) {
    let selectkeys = [];
    if (checked) {
      this.state.data.forEach((i, index) => {
        // //console.log(i.enable_del)
        // if (i.enable_del) {
        selectkeys.push(i.id);
        // }
      });
      this.setState({
        indeterminate: false,
        SelectcheckAll: true,
        selectedRowKeys: selectkeys,
      });
    } else {
      this.setState({
        indeterminate: false,
        SelectcheckAll: false,
        selectedRowKeys: selectkeys,
      });
    }
    //console.log(selectkeys, 'selectedRowKeys')
  }
  setVisibledelete(value) {
    this.setState({
      visibledelete: value,
    });
  }
  deletedatum() {
    //console.log(this.state.selectedRowKeys, 'selectedRowKeysselectedRowKeys')
    let datas = {
      ids: JSON.stringify(this.state.selectedRowKeys),
      data_id: this.state.model_info?.data_id,
    };
    this.setState({
      confirmLoading: true,
    });
    deletedatumdetail(datas).then((res) => {
      if (res.code === 200) {
        this.setState({
          selectedRowKeys: [],
          SelectcheckAll: false,
          confirmLoading: false,
          checkbox: false,
        });
        this.setVisibledelete(false);
        this.getdatumdetail(true);
      } else {
        this.setState({
          confirmLoading: false,
        });
        Notification.error({ title: "Error", content: res.msg });
      }
    });
  }
  onSettings() {
    this.setState({
      checkbox: true,
    });
  }
  onClose() {
    this.setState({
      checkbox: false,
      SelectcheckAll: false,
      selectedRowKeys: [],
    });
  }
  render() {
    const {
      loading,
      data,
      pagination,
      no_data,
      model_info,
      checkbox,
      checkAll,
      type,
      selectedRowKeys,
      SelectcheckAll,
      indeterminate,
      visibledelete,
      confirmLoading,
    } = this.state;
    return (
      <div className="model-mangement">
        <div className="Header-init">
          <div className="Header-title">模型参数</div>
        </div>
        <div className="custom-card">
          <div className="custom-table">
            <div className="custom-th AlibabaPuHuiTi-Medium">模型版本</div>
            <div className="custom-th AlibabaPuHuiTi-Medium">模型任务</div>
            <div className="custom-th AlibabaPuHuiTi-Medium">数据集量</div>
            <div className="custom-th AlibabaPuHuiTi-Medium">训练时长</div>
            <div className="custom-th AlibabaPuHuiTi-Medium">准确率</div>
            <div className="custom-th AlibabaPuHuiTi-Medium">模型迭代次数</div>
          </div>
          <Divider style={{ margin: "3px 0" }} />
          <div className="custom-table">
            <div className="custom-td">{model_info?.version}</div>
            <div className="custom-td">{model_info?.name}</div>
            <div className="custom-td">
              {model_info?.datum_detail_count
                ? model_info?.datum_detail_count
                : 0}
              条
            </div>
            <div className="custom-td">{model_info?.train_duration}</div>
            <div className="custom-td">{model_info?.precision}</div>
            <div className="custom-td">{model_info?.iteration_count}</div>
          </div>
        </div>
        <div className="GroupTable-fixe" style={{ marginTop: 24 }}>
          {checkbox ? (
            <>
              <div className="custom-checkAll">
                <Checkbox
                  onChange={this.onChangeAll.bind(this)}
                  checked={SelectcheckAll}
                  indeterminate={indeterminate}
                >
                  {SelectcheckAll ? "取消" : "全选"}
                </Checkbox>
              </div>
              <Space size={"medium"}>
                <Button
                  shape="circle"
                  className="custom-White-button"
                  icon={<IconDelete />}
                  disabled={selectedRowKeys.length === 0}
                  onClick={() => this.setVisibledelete(true)}
                />
                <Button
                  shape="circle"
                  className="custom-White-button"
                  icon={<IconClose />}
                  onClick={this.onClose.bind(this)}
                />
              </Space>
            </>
          ) : (
            <>
              <div></div>
              <div>
                <Space size={"medium"}>
                  <Input.Search
                    style={{ width: 412 }}
                    // prefix={<IconSearch />}
                    placeholder="快速搜索你的数据集"
                    className="GroupTable-Search"
                    // onPressEnter={this.onPressEnter.bind(this)}
                    onSearch={this.onPressEnter.bind(this)}
                  />
                  <Button
                    shape="circle"
                    className="custom-White-button"
                    icon={<IconDownload />}
                    onClick={() => this.onDownload()}
                  />
                  <Button
                    shape="circle"
                    className="custom-White-button"
                    icon={<IconSettings />}
                    onClick={this.onSettings.bind(this)}
                  />
                </Space>
              </div>
            </>
          )}
        </div>
        <div className="Work-order-card" style={{ marginTop: 24 }}>
          <Table
            virtualized
            no_data={no_data}
            border={{ wrapper: false, cell: false }}
            noDataElement={
              <Empty
                imgSrc={Emptydata_img}
                description={"暂无相关模型版本详细"}
              />
            }
            loading={loading}
            columns={columns}
            data={data}
            pagination={pagination.total === 0 ? false : pagination}
            onChange={this.onChangeTable.bind(this)}
            renderPagination={(paginationNode) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 10,
                }}
              >
                {paginationNode}
              </div>
            )}
            rowKey="id"
            rowSelection={
              checkbox && {
                type,
                selectedRowKeys,
                checkAll: checkAll,
                onChange: (selectedRowKeys, selectedRows) => {
                  //console.log('onChange:', selectedRowKeys, selectedRows);
                  this.setSelectedRowKeys(selectedRowKeys);
                },
                onSelect: (selected, record, selectedRows) => {
                  //console.log('onSelect:', selected, record, selectedRows)
                },
              }
            }
          />
        </div>
        <Modal
          style={{
            width: 472,
          }}
          className="deleteModal"
          title="设置提示"
          visible={visibledelete}
          okText="确认"
          cancelText="取消"
          confirmLoading={confirmLoading}
          onOk={this.deletedatum.bind(this)}
          onCancel={() => this.setVisibledelete(false)}
        >
          <p>是否确认删除选中内容？</p>
        </Modal>
      </div>
    );
  }
}

export default VersionDetails;
