import React, { Component } from "react";
import {
  Avatar,
  Typography,
  Input,
  Divider,
  Notification,
} from "@arco-design/web-react";
import user_head from "asserts/user_head.svg";
import Xiaoyou_img from "asserts/Xiaoyou_img.svg";
import { shareAppApi } from "api/apis";
import { getNewCurrentDate, debounce } from "common/utils";
import $ from "jquery";
import "../../common/style/myapp/question.scss";
const TextArea = Input.TextArea;
// 封装组件
class Question extends Component {
  constructor(props) {
    super(props);
    this.state = {
      share_id: localStorage.getItem("share_ids"),
      userinfo: JSON.parse(localStorage.getItem("userinfo")),
      no_data: false,
      TextAreaValue: "",
      ParagraphContinuation: [],
      ContinuationLoding: false,
      ContinuationLodingTime: "",
      record: JSON.parse(localStorage.getItem("shareRecord")),
      apiInfo: {
        model_id: "",
      },
      ChatContent: [],
      optionVlue: "",
      versions: [],
      input_describe: "输入并发送你想要咨询的问题",
      visibleUpgrade: false,
      upgradetips: null,
    };
    this.messagesEnd = React.createRef();
    this.isputapp = debounce(this.isputapp, 1000);
  }
  componentDidMount() {
    this.getApiInfo();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }
  UNSAFE_componentWillMount() {
    localStorage.removeItem("ChatContent");
  }

  componentWillUnmount() {
    localStorage.removeItem("props");
    window.removeEventListener("resize", this.handleResize);
  }
  handleResize = () => {
    setTimeout(() => {
      if (document.getElementById("continuation")) {
        $("#continuation").css({ height: $("#result").height() - 230 + "px" });
      }
    }, 100);
  };

  getApiInfo(isdel) {
    let list = Object.assign({}, this.state.record);
    if (isdel) {
      this.setState({
        apiInfo: list,
      });
    } else {
      this.setState({
        apiInfo: list,
      });
      let Chatarry = [
        "你好，欢迎使用韦尼克智能问答引擎，你可以向我提「xxxx」相关的问题",
        "我是尼克，可以为你解答「xxxx」的相关问题",
        "我是尼克，你的智能问答助手，你可以问我「xxxx」的相关问题",
        "Hi~我是尼克，有「xxxx」相关的问题，可以问我哦～",
      ];
      let Chatcontent = this.getRandomArrayElements(Chatarry);
      setTimeout(() => {
        let Chat = {
          type: 0,
          time: getNewCurrentDate(".", ":", new Date().getTime()),
          content: Chatcontent,
        };
        var lists = this.state.ChatContent.concat(Chat);
        this.setState({
          ChatContent: lists,
        });
      }, 300);
      this.scrollToBottom();
      if ((list.type === 0 || list.type === 9) && list.input_describe) {
        this.setState({
          TextAreaValue: list.input_describe,
        });
        setTimeout(() => {
          this.onPressEnter();
        }, 1000);
      }
    }
  }
  // 随机取数组里的一个值
  getRandomArrayElements(arr) {
    if (arr.length < 1) {
      return "";
    }
    let result = []; //定义一个数组用于保存结果
    //打乱数组
    arr.sort(function () {
      return 0.5 - Math.random();
    });
    //取出前面num个数据
    for (let i = 0; i < arr.length; i++) {
      result[i] = arr[i];
    }
    // 随机0到i的整数
    let index = Math.floor(Math.random() * result.length);
    return result[index];
  }
  Onreplace(newstr) {
    let reg = "「xxxx」";
    let html = `「<span class='Preamble AlibabaPuHuiTi-Medium'>${this.state.apiInfo.name}</span>」`;
    newstr = newstr.replace(reg, html);
    let htmls = { __html: newstr };
    return <div dangerouslySetInnerHTML={htmls}></div>;
  }
  onTextArea(value) {
    this.setState({
      TextAreaValue: value,
    });
  }
  onKeyDown(event) {
    //console.log(event.keyCode, "键盘事件");
    if (event.shiftKey && event.keyCode === 13) {
      let e = this.state.TextAreaValue;
      this.setState({
        TextAreaValue: e,
      });
    } else if (event.ctrlKey && event.keyCode === 13) {
      event.cancelBubble = true;
      event.preventDefault();
      event.stopPropagation();
      event.returnValue = false;
      this.onPressEnter(event);
      return false;
    } else if (event.keyCode === 13) {
      event.cancelBubble = true;
      event.preventDefault();
      event.stopPropagation();
      this.onPressEnter(event);
      event.returnValue = false;
      return false;
    }
  }
  onPressEnter(e) {
    const { ChatContent, TextAreaValue } = this.state;
    if (e) {
      e.nativeEvent.stopImmediatePropagation();
      if (e.target.value.length === 0) {
        setTimeout(() => {
          this.setState({
            TextAreaValue: "",
          });
        }, 100);
        Notification.warning({ content: "询问的问题不能为空!" });
        return;
      } else if (e.target.value.length < 2 || e.target.value.length > 200) {
        setTimeout(() => {
          this.setState({
            TextAreaValue: e.target.value.replace(/[^\u4e00-\u9fa5\w]/g, ""),
          });
        }, 100);
        Notification.warning({
          content: "提问内容 必须在 2 到 200 个字符之间!",
        });
        return;
      }
    }
    let userChat = {
      type: 1,
      content: e
        ? e.target.value.replaceAll("\n", " <br>")
        : TextAreaValue.replaceAll("\n", " <br>"),
    };
    let list = ChatContent.concat(userChat);
    this.setState({
      ChatContent: list,
    });
    setTimeout(() => {
      this.setState({
        ChatContent: list,
        TextAreaValue: "",
      });
      this.scrollToBottom();
    }, 100);
    this.isPhoneLegal(e ? e.target.value : TextAreaValue);
  }
  isPhoneLegal = (value) => {
    this.state.ContinuationLodingTime = setTimeout(() => {
      this.setState({
        ContinuationLoding: true,
      });
    }, 400);
    this.setRequest(value);
  };
  setRequest(value) {
    let params = {
      app_id: this.state.record.app_id,
      text: value,
      share_id: this.state.share_id,
    };
    shareAppApi(params).then((res) => {
      if (res.code === 200) {
        let list = res.data;
        let Chatarry = [
          "抱歉，我没理解，请换个方式问我",
          "你问的问题我暂时回答不了，你可以试试别的问题",
          "我想我要再学习一下",
        ];
        let Chatcontent = this.getRandomArrayElements(Chatarry);
        let answer = list.answer;
        if (answer instanceof Array) {
          answer = list.answer[0];
        }
        let Chat = {
          type: 0,
          content: answer ? answer : Chatcontent,
          time: getNewCurrentDate(".", ":", new Date().getTime()),
        };
        clearTimeout(this.state.ContinuationLodingTime);
        this.setState({
          ChatContent: this.state.ChatContent.concat(Chat),
          ContinuationLoding: false,
        });
        this.scrollToBottom();
      } else if (res.code === 3003) {
        let Chat = {
          type: 0,
          content: res.msg,
          time: getNewCurrentDate(".", ":", new Date().getTime()),
        };
        this.setState({
          ChatContent: this.state.ChatContent.concat(Chat),
          ContinuationLoding: false,
        });
        clearTimeout(this.state.ContinuationLodingTime);
        this.scrollToBottom();
      } else {
        let Chatarry = [
          "抱歉，系统出现了故障，请联系开发人员解决",
          "抱歉，服务器繁忙，请稍后再试",
        ];
        let Chatcontent = this.getRandomArrayElements(Chatarry);

        let Chat = {
          type: 0,
          content: Chatcontent,
          time: getNewCurrentDate(".", ":", new Date().getTime()),
        };
        clearTimeout(this.state.ContinuationLodingTime);

        let that = this;
        setTimeout(() => {
          that.setState({
            ChatContent: that.state.ChatContent.concat(Chat),
            ContinuationLoding: false,
          });
          that.scrollToBottom();
        }, 100);
      }
    });
  }
  scrollToBottom() {
    if (this.messagesEnd) {
      const scrollHeight = this.messagesEnd.scrollHeight; //里面div的实际高度  2000px
      const height = this.messagesEnd.clientHeight; //网页可见高度  200px
      const maxScrollTop = scrollHeight - height;
      this.messagesEnd.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
      //如果实际高度大于可见高度，说明是有滚动条的，则直接把网页被卷去的高度设置为两个div的高度差，实际效果就是滚动到底部了。
    }
  }

  render() {
    const {
      input_describe,
      ContinuationLoding,
      ChatContent,
      userinfo,
      TextAreaValue,
    } = this.state;
    return (
      <div className="question">
        <div className="experience-result" id="result">
          <div id="result_title">
            <div className="rowSpaceBetween question-title">
              <Typography.Title heading={6}>韦尼克智能QA</Typography.Title>
              <Typography.Paragraph className="question-remarks">
                (内容基于韦尼克AI创作引擎生成，仅供参考)
              </Typography.Paragraph>
            </div>
            <Divider />
          </div>
          <div className="Paragraph-generate">
            <div
              className="AC-frame"
              ref={(el) => {
                this.messagesEnd = el;
              }}
              id="continuation"
            >
              {ChatContent.length > 0 &&
                ChatContent.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`AC-frame-item ${
                        item.type === 0 ? `Xiaoyou` : `user-left`
                      }`}
                    >
                      {item.type === 0 ? (
                        <div className="information rowFlexStartStart">
                          <Avatar size={60}>
                            <img
                              alt="avatar"
                              src={Xiaoyou_img}
                              className="head-portrait"
                            />
                          </Avatar>

                          <div className="information-content">
                            <div className="rowFlexStartEnd">
                              <span className="information-name">韦尼克</span>
                              <span className="information-time">
                                {item.time}
                              </span>
                            </div>
                            <div className={`custom-Steam-drum`}>
                              <div className="Steam-drum">
                                {this.Onreplace(item.content)}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="information rowFlexEndStart">
                          <div className="information-content">
                            <div
                              className={`custom-Steam-drum rowFlexEndStart`}
                            >
                              <div
                                className="Steam-drum"
                                dangerouslySetInnerHTML={{
                                  __html: item.content,
                                }}
                              ></div>
                            </div>
                          </div>
                          <Avatar size={60}>
                            <img
                              alt="avatar"
                              src={
                                userinfo
                                  ? userinfo.avatar
                                    ? userinfo.avatar
                                    : user_head
                                  : user_head
                              }
                              className="head-portrait"
                            />
                          </Avatar>
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>

            <div className="Continuation-input" id="continuation_input">
              {ContinuationLoding ? (
                <div className="loding-text dot">
                  韦尼克正在生成回答<span className="dot-ani"></span>
                </div>
              ) : (
                ""
              )}
              <TextArea
                id="textArea_send"
                value={TextAreaValue}
                placeholder={input_describe}
                onChange={this.onTextArea.bind(this)}
                // onPressEnter={this.onPressEnter.bind(this)}
                onKeyDown={(event) => this.onKeyDown(event)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Question;
