// //rem是根元素的字体大小，1rem = html的字体大小
var recalc = function () {
  var width = document.documentElement.clientWidth;

  var height = document.documentElement.clientHeight;

  // 分辨率大于等于1680，大部分为1920的情况下，调用此css
  if (width >= 1680) {
    // document.write('<link rel="stylesheet" href="css/index_1920.css">');
    document.documentElement.style.fontSize = 10 * (width / 1920) + "px";
  }
  // 分辨率再在1600-1680的情况下，调用此css
  else if (width >= 1600) {
    // document.write('<link rel="stylesheet" href="css/index_1600.css">');
  } else if (width <= 1300) {
    document.documentElement.style.fontSize = "6.6px";
  }
  // 分辨率小于1600的情况下，调用此css
  else {
    // document.write('<link rel="stylesheet" href="css/index.css">');
  }
  // //console.log(window.screen.width, 'window.screen.width', width)
};

var resizeEvent =
  "orientationchange" in window ? "orientationchange" : "resize";

//当屏幕反转的时候执行

window.addEventListener(resizeEvent, recalc, false);

//刚进入的时候执行

document.addEventListener("DOMContentLoaded", recalc, false);
