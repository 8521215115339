import React, { Component } from "react";
import {
  Table,
  Card,
  Typography,
  Button,
  Divider,
  Select,
  Input,
  Message,
  Progress,
  Space,
  Breadcrumb,
  Notification,
  Checkbox,
  Modal,
  Empty,
  Spin,
} from "@arco-design/web-react";
import {
  IconSettings,
  IconDelete,
  IconClose,
} from "@arco-design/web-react/icon";
import { Link } from "react-router-dom";
import { timeConsuming, getCurrentDate } from "common/utils/index";
import { getdatumdetail, deletedatumdetail } from "api/apis";
import history from "common/utils/history";
import Emptydata_img from "asserts/Emptydata_img.svg";
import "./dataset.scss";

class DatasetDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: {
        sizeCanChange: false,
        showTotal: true,
        total: 0,
        pageSize: 10,
        current: 1,
        pageSizeChangeResetCurrent: true,
        showJumper: false,
      },
      loading: false,
      no_data: false,
      addkey: 3,
      pathSnippets: null,
      extraBreadcrumbItems: null,
      keyword: "",
      record: JSON.parse(localStorage.getItem("record")),
      Selectoptions: JSON.parse(localStorage.getItem("Selectoptions")),
      checkbox: false,
      selectedRowKeys: [],
      type: "checkbox",
      checkAll: false,
      indeterminate: false,
      SelectcheckAll: false,
      visibledelete: false,
      confirmLoading: false,
      loadingbody: true,
      columns: [
        {
          title: "序号",
          align: "center",
          dataIndex: "rank_id",
          width: 100,
          headerCellStyle: {
            textAlign: "center",
          },
        },
        {
          title: "数据集",
          dataIndex: "content",
          align: "left",
          headerCellStyle: {
            textAlign: "left",
            paddingLeft: "16px",
          },
          width: "calc(100% - 100px)",
          render: (col, record, index) => (
            <div>
              {col.map((item, i) => {
                return <div key={i}>{item}</div>;
              })}
            </div>
          ),
        },
      ],
    };
  }
  componentDidMount() {
    this.getdatumdetail();
  }
  startTrain(json, index) {
    //console.log(json, index, 'json,index')

    let newdata = this.state.data;
    newdata.map((item, idx) => {
      if (item.key === index) {
        item.starttime = new Date();
        item.state = 1;
        //console.log(this.state, 'allData[0].progress')
        this.setState({
          data: newdata,
        });
        item.timer = setInterval(() => {
          let newdata = this.state.data;
          item.progress = item.progress + 10;
          if (item.progress === 100) {
            item.state = 2;
            item.endtime = new Date();
            item.Trainingduration = timeConsuming(item.starttime, item.endtime);
            clearInterval(item.timer);
          }
          this.setState({
            data: newdata,
          });
          //console.log(item.progress, 'allData[0].progress')
        }, 1000);
      }
    });
  }
  PauseTraining(json, index) {
    let newdata = this.state.data;
    newdata.map((item, idx) => {
      if (item.key === index) {
        item.state = 0;
        clearInterval(item.timer);
        this.setState({
          data: newdata,
        });
      }
    });
  }
  removeRow(key) {
    let newdata = this.state.data;
    let page = this.state.pagination;
    page.total = newdata.length - 1;
    newdata = newdata.filter((item) => item.key !== key);
    this.setState({
      data: newdata,
      pagination: page,
    });
  }
  addRow() {
    let newdata = this.state.data;
    let page = this.state.pagination;
    page.total = newdata.length + 1;
    let addaddkey = this.state.addkey + 1;
    newdata = newdata.concat({
      key: addaddkey,
      state: 0,
      WorkorderNo: "JD造句",
      Modelcapability: "只能造句",
      Submissiontime: getCurrentDate("-"),
      Relatedproducts: newdata.length + 1 + ".v",
      progress: 0,
      starttime: "",
      endtime: "",
      Trainingduration: 0,
      timer: null,
    });
    this.setState({
      data: newdata,
      pagination: page,
      addkey: addaddkey,
    });
  }
  getdatumdetail(loading) {
    let page = {
      per_page: this.state.pagination.pageSize,
      page: this.state.pagination.current,
      data_id: this.state.record.id,
      search_word: this.state.keyword,
    };
    this.setState({
      loading: loading,
    });
    getdatumdetail(page).then((res) => {
      if (res.code === 200) {
        let list = res.data;
        let pagination = this.state.pagination;
        pagination.total = list.count;
        this.setState({
          pagination: pagination,
          data: list.data,
          loading: false,
          loadingbody: false,
        });
        //console.log(list, 'list')
      } else {
        this.setState({
          loadingbody: false,
        });
      }
    });
  }
  onChangeTable(pagination) {
    const { current, pageSize } = pagination;

    //console.log(pagination, 'pagination')
    let setPagination = this.state.pagination;
    setPagination.current = current;
    setPagination.pageSize = pageSize;
    this.setState({
      loading: false,
      pagination: setPagination,
    });
    this.getdatumdetail();
  }
  componentWillUnmount() {
    clearInterval(this.state.timeout);
    localStorage.removeItem("id");
  }
  UNSAFE_componentWillMount() {
    const urlParams = new URL(window.location.href);
    const pathname = urlParams?.pathname;
    //console.log(history, 'history', pathname)
    history.listen((route) => {
      //console.log(route, 'route'); // 这个route里面有当前路由的各个参数信息
    });
  }
  onPressEnter(value) {
    //console.log(value, 'value')
    let pagination = this.state.pagination;
    pagination.current = 1;
    this.setState({
      keyword: value,
      pagination: pagination,
    });
    this.state.keyword = value;
    this.getdatumdetail(true);
  }
  setSelectedRowKeys(selectedRowKeys) {
    let selectkeys = [];
    this.state.data.forEach((i, index) => {
      // //console.log(i.enable_del)
      // if (i.enable_del) {
      selectkeys.push(i.id);
      // }
    });
    this.setState({
      selectedRowKeys: selectedRowKeys,
      SelectcheckAll:
        selectedRowKeys.length === selectkeys.length ? true : false,
    });
  }
  onChangeAll(checked) {
    let selectkeys = [];
    if (checked) {
      this.state.data.forEach((i, index) => {
        // //console.log(i.enable_del)
        // if (i.enable_del) {
        selectkeys.push(i.id);
        // }
      });
      this.setState({
        indeterminate: false,
        SelectcheckAll: true,
        selectedRowKeys: selectkeys,
      });
    } else {
      this.setState({
        indeterminate: false,
        SelectcheckAll: false,
        selectedRowKeys: selectkeys,
      });
    }
    //console.log(selectkeys, 'selectedRowKeys')
  }
  setVisibledelete(value) {
    this.setState({
      visibledelete: value,
    });
  }
  deletedatum() {
    //console.log(this.state.selectedRowKeys, 'selectedRowKeysselectedRowKeys')
    let datas = {
      ids: JSON.stringify(this.state.selectedRowKeys),
      data_id: this.state.record.id,
    };
    this.setState({
      confirmLoading: true,
    });
    deletedatumdetail(datas).then((res) => {
      if (res.code === 200) {
        this.setState({
          selectedRowKeys: [],
          SelectcheckAll: false,
          confirmLoading: false,
        });
        this.setVisibledelete(false);
        this.getdatumdetail(true);
      } else {
        this.setState({
          confirmLoading: false,
        });
        Notification.error({ title: "Error", content: res.msg });
      }
    });
  }
  onSettings() {
    this.setState({
      checkbox: true,
    });
  }
  onClose() {
    this.setState({
      checkbox: false,
      SelectcheckAll: false,
      selectedRowKeys: [],
    });
  }
  render() {
    const {
      loadingbody,
      visibledelete,
      confirmLoading,
      indeterminate,
      SelectcheckAll,
      checkbox,
      checkAll,
      type,
      selectedRowKeys,
      loading,
      data,
      columns,
      pagination,
      no_data,
      record,
      Selectoptions,
    } = this.state;
    return (
      <div className="model-mangement dataset">
        <div className="Header-init">
          <div className="Header-title">数据集详情</div>
        </div>
        {loadingbody ? (
          <Spin dot className="table-spin-loading" />
        ) : (
          <>
            <div className="custom-card">
              <div className="custom-table">
                <div className="custom-th AlibabaPuHuiTi-Medium">
                  数据集名称
                </div>
                <div className="custom-th AlibabaPuHuiTi-Medium">能力选项</div>
                <div className="custom-th AlibabaPuHuiTi-Medium">数据量</div>
              </div>
              <Divider style={{ margin: "3px 0" }} />
              <div className="custom-table">
                <div className="custom-td">{record.name}</div>
                <div className="custom-td">
                  {Selectoptions[record.model_capability].label}
                </div>
                <div className="custom-td">{record.line_count}条</div>
              </div>
            </div>
            <div className="GroupTable-fixe" style={{ marginTop: 24 }}>
              {checkbox ? (
                <>
                  <div className="custom-checkAll">
                    <Checkbox
                      onChange={this.onChangeAll.bind(this)}
                      checked={SelectcheckAll}
                      indeterminate={indeterminate}
                    >
                      {SelectcheckAll ? "取消" : "全选"}
                    </Checkbox>
                  </div>
                  <Space size={"medium"}>
                    <Button
                      shape="circle"
                      className="custom-White-button"
                      icon={<IconDelete />}
                      disabled={selectedRowKeys.length === 0}
                      onClick={() => this.setVisibledelete(true)}
                    />
                    <Button
                      shape="circle"
                      className="custom-White-button"
                      icon={<IconClose />}
                      onClick={this.onClose.bind(this)}
                    />
                  </Space>
                </>
              ) : (
                <>
                  <div></div>
                  <div>
                    <Space size={"medium"}>
                      <Input.Search
                        style={{ width: 412 }}
                        // prefix={<IconSearch />}
                        placeholder="快速搜索你的数据集"
                        className="GroupTable-Search"
                        // onPressEnter={this.onPressEnter.bind(this)}
                        onSearch={this.onPressEnter.bind(this)}
                      />
                      {/* <Button shape='circle' type='primary' icon={<IconUpload />} />
              <Button shape='circle' type='primary' icon={<IconDownload />} /> */}
                      <Button
                        shape="circle"
                        className="custom-White-button"
                        icon={<IconSettings />}
                        onClick={this.onSettings.bind(this)}
                      />
                    </Space>
                  </div>
                </>
              )}
            </div>

            {loading ? (
              <Spin dot className="table-spin-loading" />
            ) : (
              <>
                {pagination.total === 0 ? (
                  <Empty
                    imgSrc={Emptydata_img}
                    description={"暂无相关数据集"}
                    style={{ marginTop: 200 }}
                  />
                ) : (
                  <div className="Work-order-card">
                    <Table
                      virtualized
                      noDataElement={
                        <Empty
                          imgSrc={Emptydata_img}
                          description={"暂无相关数据集"}
                        />
                      }
                      no_data={no_data}
                      border={{ wrapper: false, cell: false }}
                      loading={loading}
                      columns={columns}
                      data={data}
                      pagination={pagination.total === 0 ? false : pagination}
                      onChange={this.onChangeTable.bind(this)}
                      renderPagination={(paginationNode) => (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: 10,
                          }}
                        >
                          {paginationNode}
                        </div>
                      )}
                      rowKey="id"
                      rowSelection={
                        checkbox && {
                          type,
                          selectedRowKeys,
                          checkAll: checkAll,
                          onChange: (selectedRowKeys, selectedRows) => {
                            //console.log('onChange:', selectedRowKeys, selectedRows);
                            this.setSelectedRowKeys(selectedRowKeys);
                          },
                          onSelect: (selected, record, selectedRows) => {
                            //console.log('onSelect:', selected, record, selectedRows)
                          },
                          // checkboxProps: (record) => {
                          //   return {
                          //     disabled: !record.enable_del
                          //   };
                          // },
                        }
                      }
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
        <Modal
          style={{
            width: 472,
          }}
          className="deleteModal"
          title="设置提示"
          visible={visibledelete}
          okText="确认"
          cancelText="取消"
          confirmLoading={confirmLoading}
          onOk={this.deletedatum.bind(this)}
          onCancel={() => this.setVisibledelete(false)}
        >
          <p>是否确认删除选中内容？</p>
        </Modal>
      </div>
    );
  }
}

export default DatasetDetails;
