import React, { Component } from "react";
import { Spin } from "@arco-design/web-react";
import { IconStarFill, IconRight } from "@arco-design/web-react/icon";
import { getconfig } from "api/apis";
import "./index.scss";
// 封装组件
class Billingmethod extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingbody: false,
      data: {
        heads: {
          //头部配置-width：头部对应宽度
          套餐类型: { width: "15%" },
          收费项目: { width: "20%" },
          收费计量: { width: "20%" },
          收费金额: { width: "20%" },
          资费说明: { width: "25%" },
        },
        bodys: [],
      },
    };
  }
  componentDidMount() {
    this.gitconfig();
  }
  UNSAFE_componentDidUpdate() {}
  setObject(arr) {
    let newarray = [];
    for (let key in arr) {
      newarray.push(key);
    }
    return newarray;
  }

  gitconfig() {
    getconfig({ key: "commercial-rules" }).then((res) => {
      if (res.code === 200) {
        let list = res.data;
        let obj = list.value;
        this.setState({
          data: obj,
        });
        // this.setheight("th_id00", 2, "th_id000");
      }
    });
  }
  setbackground(color) {
    return color ? color : "transparent";
  }
  setheight(e, explains_length, explains_id) {
    setTimeout(() => {
      let height = document.getElementById(e);
      if (height) {
        let new_height = height.clientHeight / explains_length + "px";
        console.log(new_height, "height.clientHeight", e);
        document.getElementById(explains_id).height = new_height;
      }
    }, 10000);
  }
  render() {
    const { loadingbody, data } = this.state;
    var widthleft = 0;
    return (
      <div className="billing-method">
        {loadingbody ? (
          <Spin loading={loadingbody} dot className="loadingbody"></Spin>
        ) : (
          <>
            <div className="Header-init">
              <div className="Header-title">计费方式</div>
            </div>
            <div className="payment-explain">
              <div className="payment-explain-lable AlibabaPuHuiTi-Medium">
                <IconStarFill />
                使用说明
              </div>
              <div>
                1.系统采用预付费和实时计费两种计费模式。预付费为购买资源包，使用时在资源包扣除对应的资源量，实时计费采用阶梯计费方式，在月底，根据当月字符调用量进行费用结算。
                <br />
                2.有商业化、大批量调用需求的用户，使用资源包性价比更高，测试验证、使用量少的用户，可使用实时计费方式。
              </div>
            </div>
            <div className="billing-list">
              <div className="billingBanner">
                <div className="AlibabaPuHuiTi-Medium">
                  韦尼克智能创作平台资源计费说明
                </div>
                <div>支持企业级定制需求，详情请联系客服</div>
              </div>
              <div className="billing-table">
                <div className="table-th table-head rowStart">
                  {this.setObject(data.heads).map((item, key) => {
                    return (
                      <div
                        className="table-td rowCenter"
                        key={key}
                        style={{ "--width": data.heads[item].width }}
                      >
                        {item}
                      </div>
                    );
                  })}
                </div>
                <div className="table-bodys">
                  {this.setObject(data.heads).map((item, key) => {
                    widthleft += parseInt(data.heads[item].width);
                    return (
                      <div
                        className="table-hr"
                        key={key}
                        style={{ "--left": widthleft + "%" }}
                      ></div>
                    );
                  })}

                  {data.bodys.map((item, key) => {
                    let id = "th_id" + key;
                    return (
                      <div className="table-th rowStart" key={key} id={id}>
                        <div
                          className="table-td columnCenter"
                          style={{
                            "--width":
                              data.heads[this.setObject(data.heads)[0]].width,
                          }}
                        >
                          <div
                            className="table-td-full columnCenter"
                            style={{
                              "--background": this.setbackground("#FAFAFA"),
                              "--width":
                                data.heads[this.setObject(data.heads)[0]].width,
                            }}
                          >
                            {item.type}
                          </div>
                        </div>
                        <div
                          className="table-td"
                          style={{
                            "--width":
                              parseInt(
                                data.heads[this.setObject(data.heads)[1]].width
                              ) +
                              parseInt(
                                data.heads[this.setObject(data.heads)[2]].width
                              ) +
                              parseInt(
                                data.heads[this.setObject(data.heads)[3]].width
                              ) +
                              "%",
                          }}
                        >
                          {item.payservice.map((item2, key2) => {
                            let ids = id + key2;
                            return (
                              <div
                                className="table-th rowCenter"
                                key={key2}
                                id={ids}
                              >
                                <div className="table-td">{item2.title}</div>

                                <div className="table-td">
                                  <div className="table-th">
                                    {item2.metering.map((item3, index) => {
                                      return (
                                        <div
                                          className="table-th columnCenter"
                                          key={index}
                                        >
                                          <div>{item3}</div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                                <div className="table-td">
                                  <div className="table-th" key={key2}>
                                    {item2.moneys.map((item3, index) => {
                                      return (
                                        <div
                                          className="table-th columnCenter"
                                          key={index}
                                        >
                                          <div>{item3}</div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <div
                          className="table-td table-explains columnCenter"
                          style={{
                            "--background": this.setbackground(),
                            "--width":
                              data.heads[this.setObject(data.heads)[4]].width,
                          }}
                        >
                          {item.explains.map((item_html, item_key) => {
                            let explains_id = id + "0" + item_key;
                            return (
                              <div
                                key={item_key}
                                className="setheight table-th columnCenter"
                                style={{
                                  "--height":
                                    item.explains.length > 1 ? "200px" : "",
                                }}
                                id={explains_id}
                                dangerouslySetInnerHTML={{
                                  __html: item_html,
                                }}
                              ></div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}
export default Billingmethod;
