import React from 'react'
import '../../common/style/myapp/myapp.scss'
import { getUrlToken } from '../../common/utils/index'

import ProductExperience from './productExperience'
import WordErrorReset from './wordErrorReset'
import WordClass from './wordClass'
import GlobalModle from './globalModle'
import Question from './question'
import Semanticprototype from './semanticprototype'
import EntityExtraction from './entityExtraction'
import ResumeDocument from './resumeDocument'
import Embroidery from './embroidery'
import Writing from './writing'
import WordCloud from './wordCloud'
import Knowledge from './knowledge'
import Image2Text from './image2text'
import ShareError from './shareError'
import SmartContract from './smartContract'

import EmotionClass from './emotionClass'

import SensitiveWords from './sensitiveWords'

import Similarity from './similarity'

import ShareBottom from './shareBottom'
import { IconLoading } from '@arco-design/web-react/icon'
import headerIcon from 'asserts/share-header-icon.svg'
import { IconRight } from '@arco-design/web-react/icon'
import { Spin } from '@arco-design/web-react'

import { getShare } from 'api/apis'
import $ from 'jquery'
class shareMyapp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modeName: getUrlToken('modelName'),
      loading: true,
      appInfo: null,
      showError: false,
      code: 0
    }
  }

  showCom = () => {
    const name = getUrlToken('modelName')
    switch (name) {
      case 'ProductExperience':
        return <ProductExperience />
      case 'WordErrorReset':
        return <WordErrorReset />
      case 'WordClass':
        return <WordClass />
      case 'GlobalModle':
        return <GlobalModle />
      case 'Question':
        return <Question />
      case 'Semanticprototype':
        return <Semanticprototype />
      case 'EntityExtraction':
        return <EntityExtraction />
      case 'ResumeDocument':
        return <ResumeDocument />
      case 'Embroidery':
        return <Embroidery />
      case 'Writing':
        return <Writing />
      case 'WordCloud':
        return <WordCloud />
      case 'Knowledge':
        return <Knowledge />
      case 'Image2text':
        return <Image2Text />
      case 'SmartContract':
        return <SmartContract />
      case 'EmotionClass':
        return <EmotionClass />
      case 'SensitiveWords':
        return <SensitiveWords />
      case 'Similarity':
        return <Similarity />
      default:
        return <GlobalModle />
    }
  }
  isClass = (value) => {
    let className = value ? value.model_capability : ''
    switch (className) {
      case 'Continuation':
        return <ProductExperience />
      case 'Interaction':
        return <Question />
      case 'SemanticPrototype':
        return <Semanticprototype />
      case 'Ner':
        return <EntityExtraction />
      case 'SmartResume':
        return <ResumeDocument />
      case 'textPolish':
        return <Embroidery />
      case 'writing' || 'textsummarization':
        return <Writing />
      case 'SemanticWordCloud':
        return <WordCloud />
      case 'TextWithPictures':
        return <Image2Text />
      case 'KnowledgeJd':
        return <Knowledge />
      case 'Classification':
        return <WordClass />
      case 'Correction':
        return <WordErrorReset />
      case 'SmartContract':
        return <SmartContract />
      case 'SentimentAnalysis':
        return <EmotionClass />
      case 'SensitiveWords':
        return <SensitiveWords />
      case 'Similarity':
        return <Similarity />
      default:
        return <ShareError code={this.state.code} />
    }
  }

  getShareInfo () {
    localStorage.removeItem('shareRecord')
    localStorage.removeItem('share_ids')
    let ids = getUrlToken('share')
    localStorage.setItem('share_ids', ids)

    getShare({ id: ids }).then((res) => {
      this.setState({
        code: res.code
      })
      if (res.code === 200) {
        this.setState({
          appInfo: res.data,
        })
        localStorage.setItem('shareRecord', JSON.stringify(res.data))
      } else {
        this.setState({
          showError: true,
        })
      }
      this.setState({
        loading: false,
      })
    })
  }

  componentDidMount () {
    localStorage.removeItem('shareRecord')
    localStorage.removeItem('share_ids')
    this.getShareInfo()
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  }

  componentWillUnmount () {
    localStorage.removeItem('share_ids')
    localStorage.removeItem('shareRecord')
  }
  handleResize = () => {
    let winHeight = window.innerHeight
    if (window.innerHeight) {
      winHeight = window.innerHeight
    } else if (document.body && document.body.clientHeight) {
      winHeight = document.body.clientHeight
    }
    if (document.documentElement && document.documentElement.clientHeight) {
      winHeight = document.documentElement.clientHeight
    }
    let height = winHeight - 240 - 61
    if (document.getElementById('shareContent')) {
      $('#shareContent').css({ height: height + 'px' })
    }
    setTimeout(() => {
      if (document.getElementById('continuation')) {
        if (this.state.modeName === 'Question') {
          $('#continuation').css({ height: $('#result').height() - 230 + 'px' })
        } else if (
          this.state.modeName === 'Writing' ||
          this.state.modeName === 'WordCloud' ||
          this.state.modeName === 'Image2text' ||
          this.state.modeName === 'SensitiveWords' ||
          this.state.modeName === 'Similarity'
        ) {
          $('#continuation').css({
            height: $('#shareContent').height() - 80 + 'px',
          })

          if (this.state.modeName === 'Image2text') {
            $('#jobdesc').css({
              height: $('#shareContent').height() - 130 + 'px',
            })
          }
        }
      }
    }, 300);
  }
  goHome = () => {
    let name = this.state.modeName
    name = name ? name[0].toLowerCase() + name.slice(1) : ''
    if (!window.location.origin) {
      window.location.origin =
        window.location.protocol +
        '//' +
        window.location.hostname +
        (window.location.port ? ':' + window.location.port : '')
    }
    // if (name) {
    //   window.open(`${window.location.origin}/myapp/${name}`, '_blank')
    // } else {
    //   window.open(`${window.location.origin}`, '_blank')
    // }
    window.open('https://www.yoo-ai.com', '_blank')
  }
  render () {
    const { loading, appInfo, showError, modeName, code } = this.state
    return (
      <div className="main-content  myapp-share">
        <div className="share-header  content-box-flex flex-sb">
          <div className="header-left content-box-flex">
            {showError ? '' : <img src={headerIcon} className="" alt="" />}
            <span className="header-title">{appInfo ? appInfo.name : ''}</span>
            <span className="header-tips">{appInfo ? appInfo.desc : ''}</span>
          </div>
          <div className='content-box-flex' style={{
            alignItems: 'center'
          }}>
            {appInfo ? <span style={{
              width: '200px',
              height: ' 20px',
              lineHeight: '20px'
            }}> 失效时间： {appInfo.expiration_time}</span> : ''}
            <h1 className="headr-btn" onClick={this.goHome}>
              韦尼克智能创作平台 <IconRight />
            </h1>
          </div>

        </div>
        <div id="shareContent">
          <Spin
            loading={loading}
            element={
              <div>
                <IconLoading spin style={{ fontSize: 66 }} />
                <p className="loding-title">分享页加载中...</p>
                <p className="loding-tips">当前页面加载中，请耐心等待</p>{' '}
              </div>
            }
          >
            <div className="wh100">
              {loading ? (
                ''
              ) : showError ? (
                <ShareError code={code} />
              ) : modeName ? (
                this.showCom()
              ) : (
                this.isClass(appInfo)
              )}
            </div>
          </Spin>
        </div>
        <ShareBottom />
      </div>
    )
  }
}

export default shareMyapp
