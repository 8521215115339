import React, { Component } from "react";
import {
  Table,
  Empty,
  Card,
  Button,
  Tag,
  Modal,
  Form,
  Radio,
  Checkbox,
  Input,
  Notification,
  Link,
  Steps,
  Typography,
} from "@arco-design/web-react";
import { addStar, validatorbgTel } from "common/utils";
import { invoice_info, add_invoiceinfo, edit_invoiceinfo } from "api/apis";
import "./index.scss";
import { type } from "@testing-library/user-event/dist/type";
const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const Step = Steps.Step;

class InvoiceinFormation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingbody: true,
      checkbox: false,
      indeterminate: false,
      SelectcheckAll: false,
      Modalvisible: false,
      Modalvisibleinfo: false,
      types: "",
      typeOptions: {
        个人: 1,
        企业: 2,
        组织: 3,
      },
      InvoiceTypeOptions: {
        增值税普通发票: 1,
        增值税专用发票: 2,
      },
      isedit: false,
      userinfo: JSON.parse(localStorage.getItem("userinfo")),
      formValue: {
        type: "个人",
        invoice_type2: "增值税普通发票",
        invoice_name: "",
      },
      formValue2: {
        type: "企业",
        invoice_type2: "增值税普通发票",
        invoice_name: "",
        invoice_taxpayer_no: "",
        invoice_bank_name: "",
        invoice_bank_no: "",
        invoice_company_address: "",
        invoice_phone: "",
      },
      formValue3: {
        type: "组织",
        invoice_type2: "增值税普通发票",
        invoice_name: "",
        invoice_taxpayer_no: "",
        invoice_bank_name: "",
        invoice_bank_no: "",
        invoice_company_address: "",
        invoice_phone: "",
      },
      modify: false,
      group_permission: localStorage.getItem("userinfo")
        ? JSON.parse(localStorage.getItem("userinfo")).group_permission
        : "",
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.getinvoice_info(true);
  }
  UNSAFE_componentDidUpdate() {}
  onTypeOptions(res) {
    const { typeOptions, types, formValue, formValue2, formValue3 } =
      this.state;
    if (res.type) {
      this.setState({
        types: res.type,
      });
      let obj = this.formRef.getFieldsValue();
      switch (typeOptions[types]) {
        case 1:
          obj.type = "个人";
          this.setState({
            formValue: obj,
          });
          break;
        case 2:
          obj.type = "企业";
          this.setState({
            formValue2: obj,
          });
          break;
        case 3:
          obj.type = "组织";
          this.setState({
            formValue3: obj,
          });
          break;
        default:
          break;
      }
      switch (typeOptions[res.type]) {
        case 1:
          obj = formValue;
          break;
        case 2:
          obj = formValue2;
          break;
        case 3:
          obj = formValue3;
          break;
        default:
          break;
      }
      this.formRef.setFieldsValue(obj);
    }
  }
  setReset() {
    this.setState({
      formValue: {
        type: "个人",
        invoice_type2: "增值税普通发票",
        invoice_name: "",
      },
      formValue2: {
        type: "企业",
        invoice_type2: "增值税普通发票",
        invoice_name: "",
        invoice_taxpayer_no: "",
        invoice_bank_name: "",
        invoice_bank_no: "",
        invoice_company_address: "",
        invoice_phone: "",
      },
      formValue3: {
        type: "组织",
        invoice_type2: "增值税普通发票",
        invoice_name: "",
        invoice_taxpayer_no: "",
        invoice_bank_name: "",
        invoice_bank_no: "",
        invoice_company_address: "",
        invoice_phone: "",
      },
    });
  }
  getinvoice_info(val) {
    console.log(this.formRef, "this.formRef");
    this.setReset();
    invoice_info().then((res) => {
      if (res.code === 200) {
        let obj = {};
        let isedit = false;
        if (res.data) {
          isedit = false;
          switch (res.data.type) {
            case 1:
              obj = this.state.formValue;
              break;
            case 2:
              obj = this.state.formValue2;
              break;
            case 3:
              obj = this.state.formValue3;
              break;
            default:
              break;
          }
          obj.type =
            res.data.type === 1
              ? "个人"
              : res.data.type === 2
              ? "企业"
              : res.data.type === 3
              ? "组织"
              : "";
          obj.invoice_type2 =
            res.data.invoice_type2 === 1
              ? "增值税普通发票"
              : res.data.invoice_type2 === 2
              ? "增值税专用发票"
              : "";
          obj.invoice_name = res.data.invoice_name;
          obj.invoice_taxpayer_no = res.data.invoice_taxpayer_no;
          obj.invoice_bank_name = res.data.invoice_bank_name;
          obj.invoice_bank_no = res.data.invoice_bank_no;
          obj.invoice_company_address = res.data.invoice_company_address;
          obj.invoice_phone = res.data.invoice_phone;
          this.setState({
            modify: true,
          });
        } else {
          if (this.state.userinfo?.group_permission === "PERSONAL") {
            obj.type = "个人";
          } else {
            obj.type = "企业";
          }
          obj.invoice_type2 = "增值税普通发票";
          if (val) {
            isedit = true;
          }
        }
        switch (this.state.typeOptions[obj.type]) {
          case 1:
            this.setState({
              formValue: obj,
            });
            break;
          case 2:
            this.setState({
              formValue2: obj,
            });
            break;
          case 3:
            this.setState({
              formValue3: obj,
            });
            break;
          default:
            break;
        }
        this.setState({
          isedit: isedit,
          loadingbody: false,
          types: obj.type,
        });
        this.formRef.setFieldsValue(obj);
      } else {
        this.setState({
          loadingbody: false,
        });
      }
    });
  }
  isedit_invoiceinfo(obj) {
    obj.type = JSON.stringify(this.state.typeOptions[obj.type]);
    obj.invoice_type2 = JSON.stringify(
      this.state.InvoiceTypeOptions[obj.invoice_type2]
    );
    if (this.state.modify) {
      this.setedit_invoiceinfo(obj);
    } else {
      this.setadd_invoiceinfo(obj);
    }
  }
  setadd_invoiceinfo(obj) {
    add_invoiceinfo(obj).then((res) => {
      if (res.code === 200) {
        Notification.success({ content: "保存成功！" });
        this.getinvoice_info();
      } else {
        Notification.error({ content: res.msg });
      }
    });
  }
  setedit_invoiceinfo(obj) {
    edit_invoiceinfo(obj).then((res) => {
      if (res.code === 200) {
        Notification.success({ content: "保存成功！" });
        this.getinvoice_info();
      } else {
        Notification.error({ content: res.msg });
      }
    });
  }
  formRefDate() {
    this.formRef
      .validate()
      .then((res) => {
        let obj = res;

        if (
          obj.invoice_bank_no &&
          !this.Bank_card_number(obj.invoice_bank_no)
        ) {
          Notification.error({ content: "请输入正确的基本开户账号" });
          return false;
        } else if (
          obj.invoice_taxpayer_no &&
          !this.Taxpayer_identification_number(obj.invoice_taxpayer_no)
        ) {
          Notification.error({ content: "请输入正确的纳税人识别号" });
          return false;
        }

        if (obj.invoice_phone && !validatorbgTel(obj.invoice_phone)) {
          Notification.error({ content: "请输入正确的企业注册电话" });
          return false;
        }
        this.setState({
          confirmLoading: true,
          isedit: false,
        });
        this.setState({
          formValue: obj,
        });
        this.isedit_invoiceinfo(obj);
        console.log(obj, "obj");
      })
      .catch((error) => {
        let errorslist = error.errors;
        let message = "";
        Object.keys(errorslist ? errorslist : []).map((item, i) => {
          if (errorslist[item].requiredError && i === 0) {
            message = errorslist[item].message;
          }
        });
        Notification.error({ content: message });
      });
  }
  formRefcancel() {
    this.setState({
      isedit: false,
    });
    this.formRef.resetFields();
    this.getinvoice_info();
  }
  formRefedit() {
    this.setState({
      isedit: true,
    });
  }
  //银行卡号
  Bank_card_number(data) {
    var regExp = /^([1-9]{1})(\d{15}|\d{18})$/;
    data = data.replace(/ /g, "");
    return regExp.test(data);
  }
  // 纳税人识别号:
  Taxpayer_identification_number(data) {
    var regExp = /[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}/;
    return regExp.test(data);
  }
  render() {
    const {
      isedit,
      typeOptions,
      InvoiceTypeOptions,
      formValue,
      formValue2,
      formValue3,
      types,
      modify,
    } = this.state;
    const formItemLayout = {
      labelCol: {
        flex: "150px",
      },
      wrapperCol: {
        flex: "auto",
      },
    };
    return (
      <div className="invoicein-formation">
        <div className="Header-init">
          <div className="Header-title">发票信息</div>
        </div>
        <div className="Payment-order mails orderinfo">
          <div className="submit-htmlType" style={{ textAlign: "right" }}>
            {isedit ? (
              <div>
                {modify ? (
                  <Button
                    className="custom-cancel-button"
                    onClick={() => {
                      this.formRefcancel();
                    }}
                    style={{ marginRight: 18 }}
                  >
                    取消
                  </Button>
                ) : null}

                <Button
                  className="custom-confirm-button"
                  onClick={this.formRefDate.bind(this)}
                >
                  保存
                </Button>
              </div>
            ) : (
              <Button
                type="outline"
                className="custom-edit-button"
                onClick={this.formRefedit.bind(this)}
              >
                修改
              </Button>
            )}
          </div>
          <div style={{ textAlign: "left" }}>
            <Form
              ref={(ref) => (this.formRef = ref)}
              layout="horizontal"
              labelAlign="left"
              initialValues={
                types === "个人"
                  ? formValue
                  : types === "企业"
                  ? formValue2
                  : types === "组织"
                  ? formValue3
                  : ""
              }
              {...formItemLayout}
              onChange={this.onTypeOptions.bind(this)}
            >
              <FormItem
                field="type"
                label="抬头类型："
                rules={[{ required: true, message: "请选择抬头类型" }]}
              >
                {(values) => {
                  return isedit ? (
                    <Radio.Group
                      options={Object.keys(typeOptions)}
                    ></Radio.Group>
                  ) : (
                    <span className="form-values">{values.type}</span>
                  );
                }}
              </FormItem>
              <Form.Item shouldUpdate noStyle>
                {(values) => {
                  return values.type === "个人" ? (
                    <>
                      <FormItem
                        field="invoice_type2"
                        label="发票类型："
                        rules={[{ required: true, message: "请选择发票类型" }]}
                      >
                        {isedit ? (
                          <Radio.Group
                            options={Object.keys(InvoiceTypeOptions)}
                          ></Radio.Group>
                        ) : (
                          <span className="form-values">
                            {values.invoice_type2}
                          </span>
                        )}
                      </FormItem>
                      <FormItem
                        label="发票抬头："
                        field="invoice_name"
                        rules={[{ required: true, message: "请输入发票抬头" }]}
                      >
                        {isedit ? (
                          <Input placeholder="" />
                        ) : (
                          <span className="form-values">
                            {values.invoice_name}
                          </span>
                        )}
                      </FormItem>
                    </>
                  ) : values.type === "企业" ? (
                    <>
                      <FormItem
                        field="invoice_type2"
                        label="发票类型："
                        rules={[{ required: true, message: "请选择发票类型" }]}
                      >
                        {isedit ? (
                          <Radio.Group
                            options={Object.keys(InvoiceTypeOptions)}
                          ></Radio.Group>
                        ) : (
                          <span className="form-values">
                            {values.invoice_type2}
                          </span>
                        )}
                      </FormItem>
                      <FormItem
                        label="发票抬头："
                        field="invoice_name"
                        rules={[{ required: true, message: "请输入发票抬头" }]}
                      >
                        {isedit ? (
                          <Input placeholder="" />
                        ) : (
                          <span className="form-values">
                            {values.invoice_name}
                          </span>
                        )}
                      </FormItem>
                      <FormItem
                        label="纳税人识别号："
                        field="invoice_taxpayer_no"
                        rules={[
                          { required: true, message: "请输入纳税人识别号" },
                        ]}
                      >
                        {isedit ? (
                          <Input placeholder="" />
                        ) : (
                          <span className="form-values">
                            {values.invoice_taxpayer_no &&
                              addStar(values.invoice_taxpayer_no, 0, 4)}
                          </span>
                        )}
                      </FormItem>
                      <FormItem
                        label="基本开户银行："
                        field="invoice_bank_name"
                        rules={[
                          {
                            required: true,
                            message: "请输入基本开户银行",
                          },
                        ]}
                      >
                        {isedit ? (
                          <Input placeholder="" />
                        ) : (
                          <span className="form-values">
                            {values.invoice_bank_name}
                          </span>
                        )}
                      </FormItem>
                      <FormItem
                        label="基本开户账号："
                        field="invoice_bank_no"
                        rules={[
                          { required: true, message: "请输入基本开户账号" },
                        ]}
                      >
                        {isedit ? (
                          <Input placeholder="" />
                        ) : (
                          <span className="form-values">
                            {values.invoice_bank_no}
                          </span>
                        )}
                      </FormItem>
                      <FormItem
                        label="企业注册地址："
                        field="invoice_company_address"
                        rules={[
                          { required: true, message: "请输入企业注册地址" },
                        ]}
                      >
                        {isedit ? (
                          <Input placeholder="" />
                        ) : (
                          <span className="form-values">
                            {values.invoice_company_address}
                          </span>
                        )}
                      </FormItem>
                      <FormItem
                        label="企业注册电话："
                        field="invoice_phone"
                        rules={[
                          { required: true, message: "请输入企业注册电话" },
                        ]}
                      >
                        {isedit ? (
                          <Input placeholder="" />
                        ) : (
                          <span className="form-values">
                            {values.invoice_phone}
                          </span>
                        )}
                      </FormItem>
                    </>
                  ) : (
                    values.type === "组织" && (
                      <>
                        <FormItem
                          field="invoice_type2"
                          label="发票类型："
                          rules={[
                            { required: true, message: "请选择发票类型" },
                          ]}
                        >
                          {isedit ? (
                            <Radio.Group
                              options={Object.keys(InvoiceTypeOptions)}
                            ></Radio.Group>
                          ) : (
                            <span className="form-values">
                              {values.invoice_type2}
                            </span>
                          )}
                        </FormItem>
                        <FormItem
                          label="发票抬头："
                          field="invoice_name"
                          rules={[
                            { required: true, message: "请输入发票抬头" },
                          ]}
                        >
                          {isedit ? (
                            <Input placeholder="" />
                          ) : (
                            <span className="form-values">
                              {values.invoice_name}
                            </span>
                          )}
                        </FormItem>
                        <FormItem
                          label="纳税人识别号："
                          field="invoice_taxpayer_no"
                          rules={[
                            { required: true, message: "请输入纳税人识别号" },
                          ]}
                        >
                          {isedit ? (
                            <Input placeholder="" />
                          ) : (
                            <span className="form-values">
                              {values.invoice_taxpayer_no &&
                                addStar(values.invoice_taxpayer_no, 0, 4)}
                            </span>
                          )}
                        </FormItem>
                        <FormItem
                          label="基本开户银行："
                          field="invoice_bank_name"
                          rules={[
                            { required: true, message: "请输入基本开户银行" },
                          ]}
                        >
                          {isedit ? (
                            <Input placeholder="" />
                          ) : (
                            <span className="form-values">
                              {values.invoice_bank_name}
                            </span>
                          )}
                        </FormItem>
                        <FormItem
                          label="基本开户账号："
                          field="invoice_bank_no"
                          rules={[
                            { required: true, message: "请输入基本开户账号" },
                          ]}
                        >
                          {isedit ? (
                            <Input placeholder="" />
                          ) : (
                            <span className="form-values">
                              {values.invoice_bank_no}
                            </span>
                          )}
                        </FormItem>
                        <FormItem
                          label="企业注册地址："
                          field="invoice_company_address"
                          rules={[
                            { required: true, message: "请输入企业注册地址" },
                          ]}
                        >
                          {isedit ? (
                            <Input placeholder="" />
                          ) : (
                            <span className="form-values">
                              {values.invoice_company_address}
                            </span>
                          )}
                        </FormItem>
                        <FormItem
                          label="企业注册电话："
                          field="invoice_phone"
                          rules={[
                            { required: true, message: "请输入企业注册电话" },
                          ]}
                        >
                          {isedit ? (
                            <Input placeholder="" />
                          ) : (
                            <span className="form-values">
                              {values.invoice_phone}
                            </span>
                          )}
                        </FormItem>
                      </>
                    )
                  );
                }}
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
export default InvoiceinFormation;
