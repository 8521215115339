import React, { Component } from "react";
import {
  Layout,
  Typography,
  Button,
  Space,
  Empty,
  Input,
  Upload,
  Message,
} from "@arco-design/web-react";
import Emptydata_img from "asserts/Emptydata_img.svg";
import Generating_img from "asserts/Generating_img.gif";
import errorIcon from "asserts/error_icon.svg";
import {
  shareAppApi,
} from "api/apis";
import { debounce, b2ValueUnit } from "common/utils";
import { sourceCancelClass } from "api/cancel-request";
import upload from "api/uploads";

import $ from "jquery";
import "../../common/style/myapp/emotionClass.scss";
const Sider = Layout.Sider;
const Content = Layout.Content;
const TextArea = Input.TextArea;
const COLORS_CUSTOM = [
  "#DC2EF8",
  "#2049FF",
  "#00C7FF",
  "#FF8700",
  "#7FCA00",
  "#57DBD4",
  "#B18533",
  "#FF3D9F",
  "#A968FF",
  "#D2CD34",
  "#0FC6C2",
  "#F53F3F",
  "#7816FF",
  "#1d5ae6",
  "#319227",
  "#a6d8ff",
  "#423535",
  "#16efff",
  "#4916ff",
  "#16ffa3",
  "#ca1364",
  "#8a1114",
  "#538a11",
  "#b6ce13",
  "#ce6d13",
  "#fc6220",
];
// 封装组件
class EmotionClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      share_id: localStorage.getItem('share_ids'),
      TextAreaValue: "",
      ParagraphContinuation: [],
      ContinuationLoding: false,
      record: JSON.parse(localStorage.getItem('shareRecord')),
      number: 6,
      requestMsg: "",
      requestMsgText: "您还未输入语句",
      input_describe: "请输入文段，或导入文本文件进行分析",
      fileList: [],
      attachment: [],
      emotionData: []
    };
    this.isPhoneLegal = debounce(this.isPhoneLegal, 1000);
  }
  componentDidMount () {
    this.getApiInfo();
    window.addEventListener('resize', this.handleResize)
    setTimeout(() => {
      this.handleResize()
    }, 300)
  }
  componentWillUnmount () {
    window.removeEventListener('resize', this.handleResize)
  }
  handleResize = () => {
    $('#globalModleBox').css({
      height: $('#shareContent').height() - 200 + 'px',
    })
    $('.text-left').css({
      height: $('#shareContent').height() - 80 + 'px',
      overflowY: 'auto',
    })

    $('#continuation').css({
      height: $('#shareContent').height() - 80 + 'px',
      overflowY: 'auto',
      position: 'relative'
    })
  }
  getApiInfo () {
    let list = Object.assign({}, this.state.record)
    if (list.type === 0) {
      this.onTextArea(
        list.input_describe
          ? list.input_describe
          : ''
      )
      this.isPhoneLegal()
    }
  }

  onTextArea (value) {
    this.setState({
      ContinuationLoding: true,
    });
    this.setState({
      TextAreaValue: value,
    });
    this.isPhoneLegal();
  }
  isPhoneLegal = () => {
    this.setState({
      ContinuationLoding: true,
      requestMsg: "",
    });
    sourceCancelClass();
    this.setRequest();
  };
  ChangeAbatch () {
    sourceCancelClass();
    this.setState({
      ContinuationLoding: true,
      requestMsg: "",
    });
    setTimeout(() => {
      this.setRequest();
    }, 1000);
  }
  setRequest () {
    if (this.state.TextAreaValue.length < 0) {
      this.setState({
        ParagraphContinuation: null,
        ContinuationLoding: false,
        requestMsg: "",
        requestMsgText: "您还未输入语句",
      });
    } else {
      this.sendRequest()
    }
  }

  sendRequest () {
    let params = {
      app_id: this.state.record.app_id,
      fileKey: JSON.stringify(this.state.attachment),
      text: this.state.TextAreaValue,
      num: this.state.number,
      share_id: this.state.share_id,
    };

    shareAppApi(params).then((res) => {
      const code = [400, 3003, 204];
      if (res.code === 200) {
        let list = res.data;
        this.setState({
          ParagraphContinuation: list,
          ContinuationLoding: false,
          requestMsg: "",
        });
        this.handleResize();
      } else if (code.includes(res.code)) {
        this.setState({
          ParagraphContinuation: null,
          ContinuationLoding: false,
          requestMsg: "",
          requestMsgText: res.msg,
        });
      } else {
        this.setState({
          ParagraphContinuation: null,
          ContinuationLoding: false,
          requestMsg: "error",
          requestMsgText: res.msg,
        });
      }
    });
  }

  setContinuation = (item) => {
    let tispArr = []
    if (item.prediction_label && item.prediction_label.length > 0) {
      item.prediction_label.forEach((e,) => {
        let obj = {}
        if (this.state.emotionData.indexOf(e) === -1) {
          this.state.emotionData.push(e)
          let index = this.state.emotionData.indexOf(e)
          obj = {
            name: e,
            color: COLORS_CUSTOM[index]
          }
        } else {
          let index = this.state.emotionData.indexOf(e)
          obj = {
            name: e,
            color: COLORS_CUSTOM[index]
          }
        }
        tispArr.push(obj)
      })
    }
    let tagsHtml = ''
    tispArr.forEach((e) => {
      tagsHtml += `<div class="rowStart tags-color"><div class="tag-color" style="--bgcolor: ${e.color}"></div>${e.name}</div>`;
    })

    let tagslist = `<div class="result-li-tag rowStartWrap">${tagsHtml}</div>`;
    let newHTML =
      `<div class="result-li">` + item.original + `</div>${tagslist}`;
    let htmls = { __html: newHTML.replaceAll("\n", "<br/>") };
    return <div className="result-ul" dangerouslySetInnerHTML={htmls}></div>;
  };

  handleUpload (option) {
    upload(option);
  }
  setFile (fileList, file) {
    this.setState({
      fileList: fileList,
    });
    if (file.status === "done") {
      let key = [];
      let fileList = this.state.fileList;
      fileList.forEach((item, index) => {
        key.push(item.response.fileKey);
      });
      this.setState({
        attachment: key,
        fileList: fileList,
      });
    }
  }
  beforeUpload (file) {
    var AllImgExt = ".csv|";
    var extName = file.name.substring(file.name.lastIndexOf(".")).toLowerCase(); //（把路径中的所有字母全部转换为小写）
    if (AllImgExt.indexOf(extName + "|") === -1) {
      let ErrMsg =
        "该文件类型不允许上传。请上传 .csv类型的文件，当前文件类型为" +
        extName;
      Message.error(ErrMsg);
      return false;
    }
    const isLt10M = !(
      b2ValueUnit(file.size)[1] === "B" ||
      b2ValueUnit(file.size)[1] === "KB" ||
      (b2ValueUnit(file.size)[1] === "MB" && b2ValueUnit(file.size)[0] <= 5)
    );
    return new Promise((resolve, reject) => {
      if (isLt10M) {
        Message.error("上传文件不能大于5MB");
        reject();
      } else {
        resolve();
      }
    });
  }
  render () {
    const {
      input_describe,
      ParagraphContinuation,
      ContinuationLoding,
      TextAreaValue,
      requestMsg,
      requestMsgText,
      fileList,
    } = this.state;
    return (
      <div className="product-emotionClass">
        <Layout className="semanticprototype-Layout" id="layout_id">
          <Sider className="Sider-left">
            <div className="upload-file">
              <div className="upload-file-title">导入批量文本</div>
              <div>
                <Upload
                  accept=".csv"
                  fileList={fileList}
                  renderUploadList={this.renderUploadList}
                  limit={1}
                  onChange={this.setFile.bind(this)}
                  customRequest={(option) => {
                    this.handleUpload(option);
                  }}
                  beforeUpload={this.beforeUpload.bind(this)}
                  onExceedLimit={() => {
                    Message.warning("超过上传数量限制！最多上传1个");
                  }}
                >
                  <div className="rowCenter trigger">
                    <span className="iconfont icon-icon_chuangjian_24"></span>
                    <div>点击此处上传文件</div>
                  </div>
                </Upload>
                <div className="Upload-pictures-tip">
                  （仅支持.CSV格式，最大文件尺寸5MB）
                </div>
              </div>
            </div>
            <div
              className="Sider-left-configure columnSpaceBetween"
              id="globalModleBox"
            >
              <div className="Continuation-input">
                <TextArea
                  placeholder={input_describe}
                  style={{ width: "100%" }}
                  value={TextAreaValue}
                  onChange={this.onTextArea.bind(this)}
                />
              </div>
            </div>
            <div className="Upload-pictures-bottom">
              <Button
                type="primary"
                className="Start-generation"
                loading={false}
                onClick={this.ChangeAbatch.bind(this, "start")}
              >
                开始分析
              </Button>
            </div>
          </Sider>
          <Content>
            <div className="experience-result" id="result">
              <div id="result_title" className="rowSpaceBetween result_title">
                <Typography>生成结果</Typography>
                <Typography.Paragraph className="result-remarks">
                  (内容基于韦尼克AI创作引擎生成，仅供参考)
                </Typography.Paragraph>
              </div>
              {ParagraphContinuation && ParagraphContinuation.length > 0 || ContinuationLoding ? (
                <div className="Paragraph-generate" id="continuation">
                  {ContinuationLoding ? (
                    <Empty
                      className="Continuation-loding"
                      icon={<img src={Generating_img} alt="" />}
                      description={
                        <Typography
                          style={{
                            color: "#4e5969",
                            marginTop: 21,
                            fontSize: 16,
                          }}
                        >
                          正在生成中...
                        </Typography>
                      }
                    />
                  ) : (
                    <div className="result-list">
                      {ParagraphContinuation.length > 0 ? (
                        <>
                          {ParagraphContinuation.map((item, i) => {
                            return (
                              <div key={i}>{this.setContinuation(item)}</div>
                            );
                          })}
                        </>
                      ) : null}
                    </div>
                  )}
                </div>
              ) : requestMsg === "error" ? (
                <Empty
                  className="Continuation-Empty Empty-error"
                  imgSrc={errorIcon}
                  description={
                    <Space direction="vertical">
                      <Typography
                        style={{
                          color: "#4e5969",
                          marginTop: 30,
                          fontSize: 16,
                        }}
                      >
                        抱歉，服务器生成错误
                      </Typography>
                      <Typography style={{ color: "#4e5969", fontSize: 16 }}>
                        可尝试
                        <span
                          style={{ color: "#406EFF", cursor: "pointer" }}
                          onClick={this.ChangeAbatch.bind(this)}
                        >
                          重新生成
                        </span>
                      </Typography>
                    </Space>
                  }
                />
              ) : (
                <Empty
                  className="Continuation-Empty Empty-none"
                  imgSrc={Emptydata_img}
                  description={
                    <Space direction="vertical">
                      <Typography
                        style={{
                          color: "#4e5969",
                          marginTop: 30,
                          fontSize: 16,
                        }}
                      >
                        暂无生成结果
                      </Typography>
                      <Typography style={{ color: "#4e5969", fontSize: 16 }}>
                        {requestMsgText}
                      </Typography>
                    </Space>
                  }
                />
              )}
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}
export default EmotionClass;
