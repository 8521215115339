import React, { Component } from 'react'
import {
  Layout,
  Typography,
  Button,
  Space,
  Empty,
  Input,
  Notification,
} from '@arco-design/web-react'
import { IconSync } from '@arco-design/web-react/icon'
import Emptydata_img from 'asserts/Emptydata_img.svg'
import Generating_img from 'asserts/Generating_img.gif'
import errorIcon from 'asserts/error_icon.svg'
import { shareAppApi } from 'api/apis'
import { debounce } from 'common/utils'
import copy from 'copy-to-clipboard'
import { sourceCancelClass } from 'api/cancel-request'
import $ from 'jquery'
import '../../common/style/myapp/experience.scss'
const Sider = Layout.Sider
const Content = Layout.Content
const TextArea = Input.TextArea

// 封装组件
class ProductExperience extends Component {
  constructor(props) {
    super(props)
    this.state = {
      share_id: localStorage.getItem('share_ids'),
      optionVlue: '',
      TextAreaValue: '',
      ParagraphContinuation: [],
      ContinuationLoding: false,
      record: JSON.parse(localStorage.getItem('shareRecord')),
      number: 6,
      requestMsg: '',
      requestMsgText: '您还未输入语句',
      input_describe: '请输入内容，开始续写',
      prompt: '',
    }
    this.isPhoneLegal = debounce(this.isPhoneLegal, 1000)
  }
  componentDidMount() {
    this.getApiInfo()
  }
  componentWillUnmount() {}

  getApiInfo() {
    let list = this.state.record
    if (list.type === 0 || list.type === 9) {
      this.onTextArea(
        list.input_describe ? list.input_describe : '负责公司全线产品'
      )
      this.isPhoneLegal()
    }
  }
  onTextArea(value) {
    console.log(value)
    this.setState({
      ContinuationLoding: true,
    })
    this.setState({
      TextAreaValue: value,
    })
    this.isPhoneLegal()
    //console.log(value);
  }
  isPhoneLegal = () => {
    this.setState({
      ContinuationLoding: true,
      requestMsg: '',
    })
    sourceCancelClass()
    this.setRequest()
  }
  ChangeAbatch() {
    sourceCancelClass()
    this.setState({
      ContinuationLoding: true,
      requestMsg: '',
    })
    setTimeout(() => {
      this.setRequest()
    }, 1000)
  }
  setRequest() {
    if (this.state.TextAreaValue.length < 0) {
      this.setState({
        ParagraphContinuation: [],
        ContinuationLoding: false,
        requestMsg: '',
        requestMsgText: '您还未输入语句',
      })
    } else {
      let params = {
        app_id: this.state.record.app_id,
        text: this.state.TextAreaValue,
        num: this.state.number,
        share_id: this.state.share_id,
      }
      if (this.state.optionVlue) {
        params.version_id = this.state.optionVlue
      }

      shareAppApi(params).then((res) => {
        if (res.code === 200) {
          let list = res.data
          this.setState({
            ParagraphContinuation: list.generations
              ? this.uniqueList(list.generations)
              : [],
            ContinuationLoding: false,
            requestMsg: '',
            prompt: list.prompt,
          })
          $('#continuation').css({
            height: $('#shareContent').height() - 180 + 'px',
          })
        } else if (res.code === 400 || res.code === 3003) {
          this.setState({
            ParagraphContinuation: [],
            ContinuationLoding: false,
            requestMsg: '',
            requestMsgText: res.msg,
          })
        } else {
          this.setState({
            ParagraphContinuation: [],
            ContinuationLoding: false,
            requestMsg: 'error',
            requestMsgText: res.msg,
          })
        }
      })
    }
  }

  // 数组中对象的某个属性值进行去重
  uniqueList = (arr) => {
    const res = new Map()
    return arr.filter((a) => !res.has(a.sentence) && res.set(a.sentence, 1))
  }

  oncopy(item) {
    let value = item.sentence
    if (copy(value)) {
      Notification.success({ content: '复制成功!' })
    } else {
      Notification.error({ content: '复制失败!' })
    }
  }
  Onreplace(newstr, finished) {
    let reg = this.state.prompt.toLowerCase()
    let html = `<span class='Preamble'>${reg}</span>`

    if (!finished) {
      let regFinished = /([^0-9a-zA-Z\u4e00-\u9fff])*$/
      newstr = newstr.replace(regFinished, '...')
    }
    newstr = newstr.replace(reg, html)
    let htmls = { __html: newstr }
    return <div dangerouslySetInnerHTML={htmls}></div>
  }

  render() {
    const {
      input_describe,
      ParagraphContinuation,
      ContinuationLoding,
      TextAreaValue,
      requestMsg,
      requestMsgText,
    } = this.state
    return (
      <div className="product-experience">
        <Layout className="semanticprototype-Layout" id="layout_id">
          <Sider className="Sider-left">
            <div className="Sider-left-configure columnSpaceBetween">
              <div className="Continuation-input">
                <TextArea
                  placeholder={input_describe}
                  style={{ width: '100%' }}
                  value={TextAreaValue}
                  onChange={this.onTextArea.bind(this)}
                />
              </div>
            </div>
          </Sider>
          <Content>
            <div className="experience-result">
              <div id="result_title" className="rowSpaceBetween result_title">
                <Typography>生成结果</Typography>
                <Typography.Paragraph className="result-remarks">
                  (内容基于韦尼克AI创作引擎生成，仅供参考)
                </Typography.Paragraph>
              </div>
              {ParagraphContinuation.length > 0 || ContinuationLoding ? (
                <div className="Paragraph-generate">
                  {ContinuationLoding ? (
                    <Empty
                      className="Continuation-loding"
                      icon={<img src={Generating_img} alt="" />}
                      description={
                        <Typography
                          style={{
                            color: '#4e5969',
                            marginTop: 21,
                            fontSize: 16,
                          }}
                        >
                          正在生成中...
                        </Typography>
                      }
                    />
                  ) : (
                    <div className="Continuation-result" id="result">
                      <div className="Continuation-list" id="continuation">
                        {ParagraphContinuation.map((item, index) => {
                          return (
                            <div
                              className="Continuation-item"
                              key={index}
                              onClick={this.oncopy.bind(this, item)}
                            >
                              {this.Onreplace(item.sentence, item.finished)}
                            </div>
                          )
                        })}
                      </div>
                      <Button
                        icon={<IconSync />}
                        className="IconSync-btn custom-White-border-button"
                        id="IconSynbtn"
                        onClick={this.ChangeAbatch.bind(this)}
                      >
                        换一批
                      </Button>
                    </div>
                  )}
                </div>
              ) : requestMsg === 'error' ? (
                <Empty
                  className="Continuation-Empty Empty-error"
                  imgSrc={errorIcon}
                  description={
                    <Space direction="vertical">
                      <Typography
                        style={{
                          color: '#4e5969',
                          marginTop: 30,
                          fontSize: 16,
                        }}
                      >
                        抱歉，服务器生成错误
                      </Typography>
                      <Typography style={{ color: '#4e5969', fontSize: 16 }}>
                        可尝试
                        <span
                          style={{ color: '#406EFF', cursor: 'pointer' }}
                          onClick={this.ChangeAbatch.bind(this)}
                        >
                          {' '}
                          重新生成
                        </span>
                      </Typography>
                    </Space>
                  }
                />
              ) : (
                <Empty
                  className="Continuation-Empty Empty-none"
                  imgSrc={Emptydata_img}
                  description={
                    <Space direction="vertical">
                      <Typography
                        style={{
                          color: '#4e5969',
                          marginTop: 30,
                          fontSize: 16,
                        }}
                      >
                        暂无生成结果
                      </Typography>
                      <Typography style={{ color: '#4e5969', fontSize: 16 }}>
                        {requestMsgText}
                      </Typography>
                    </Space>
                  }
                />
              )}
            </div>
          </Content>
        </Layout>
      </div>
    )
  }
}
export default ProductExperience
