import http from "api/http";
import ALLURL from "envconfig/config";

/**
 * 公共axios函数
 * 这里对接收的三个参数做了柯里化
 * methods和url一般不会改变，多以将这两个参数封装到一起
 * data是请求的参数，经常会有改变，单独接收
 * @param {String} methods 请求方式
 * @param {String} url 请求路径
 * @param {Object} data 接口入参
 */
function localAxios(methods, url) {
  return function (data, cancelToken = null) {
    return new Promise((resolve, reject) => {
      http(methods, url, data, cancelToken).then(
        (res) => {
          resolve(res);
        },
        (error) => {
          //console.log("网络异常~", error);
          reject(error);
        }
      );
    });
  };
}

export { localAxios };
