import React, { Component } from "react";
import {
  IconPlus,
  IconEdit,
  IconDelete,
  IconDown,
  IconUpload,
  IconCopy,
} from "@arco-design/web-react/icon";
import {
  Layout,
  Input,
  Space,
  Button,
  Typography,
  Message,
  Divider,
  Checkbox,
  Upload,
  Progress,
  Select,
  Grid,
  Image,
  Dropdown,
  Menu,
  Notification,
} from "@arco-design/web-react";
import { SketchPicker } from "react-color";
import copy from "copy-to-clipboard";
import upload from "api/uploads";
import { CreateLink } from "common/utils/business";
import { geteditor, getconfig, puteditor, putapp } from "api/apis";
import {
  getRgba,
  getHexColor,
  b2ValueUnit,
  debounce,
} from "common/utils/index";
import "./smarteditor.scss";

const Sider = Layout.Sider;
const Header = Layout.Header;
const Content = Layout.Content;
const Footer = Layout.Footer;
const CheckboxGroup = Checkbox.Group;
const Option = Select.Option;
const Row = Grid.Row;
const Col = Grid.Col;

class SmartEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: JSON.parse(localStorage.getItem("record")).id,
      title: "",
      logo: "",
      height: "",
      displayColorPicker: false,
      fileMaxSize: 60,
      color: {
        a: 1,
        b: 255,
        g: 110,
        r: 64,
      },
      setColor: "rgb(64, 110, 255)",
      loading: false,
      isHservice: false,
      record: JSON.parse(localStorage.getItem("record")),
      view_url:
        "https://test.saas-end.yoo-ai.com/saas-editor.html?id=" +
        JSON.parse(localStorage.getItem("record")).id,
      // view_url:
      //   'https://saas-end.yoo-ai.com/saas-editor.html?id=' +
      //   JSON.parse(localStorage.getItem('record')).id,
      api_prefix: "", //https://test.api.yoojober.com/outsides/editor
      options: [
        {
          label: "编辑大纲",
          value: "编辑大纲",
          disabled: false,
        },
        {
          label: "模板视图",
          value: "模板视图",
          disabled: false,
        },
      ],
      apiInfo: {},
      optionVlue: "",
      versions: [],
      CheckboxGroupValue: ["编辑大纲", "模板视图"],
      file: null,
      fileList: null,
      Selectoptions: [],
      selectfont: "微软雅黑",
      allCheckArrValue: [],
      allCheckArr: [
        { title: "智能风格", url: "", value: 0, checked: false },
        { title: "智能排序", url: "", value: 1, checked: false },
        { title: "一键翻译", url: "", value: 2, checked: false },
        { title: "智能一页", url: "", value: 3, checked: false },
      ],
    };
    this.isputapp = debounce(this.isputapp, 1000);
    this.isPhoneLegal = debounce(this.isPhoneLegal, 1000);
  }
  handleChange(value) {
    this.setState({
      color: value.rgb,
    });
  }
  onChangeComplete(value) {
    this.setState({
      color: value.rgb,
    });
    this.posteditor();
    //console.log(value, "onChangeComplete");
  }
  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };
  oncopy() {
    if (copy(this.state.view_url)) {
      Message.success({ duration: 800, content: "复制成功" });
    } else {
      Message.error({ duration: 800, content: "复制失败" });
    }
  }
  delUploadlogo(e) {
    e.stopPropagation();
    this.setState({
      fileList: [],
      file: null,
      logo: "",
    });
    setTimeout(() => {
      this.posteditor();
    }, 100);
  }
  setFile(fileList, file) {
    //console.log(fileList, "file");
    let data = Object.assign({}, file, {
      url: file.response
        ? file.response.url
        : URL.createObjectURL(file.originFile),
    });
    this.setState({
      fileList: fileList,
      file: data,
      logo: data.response ? data.response.url : "",
    });
    if (this.state.file.status === "done") {
      this.posteditor();
    }
    //console.log(this.state.file, "setFile");
  }
  onProgress(file) {
    //console.log(file, "onProgress");
  }
  handleUpload(option) {
    upload(option);
  }
  beforeUpload(file) {
    //console.log(b2ValueUnit(file.size));
    var AllImgExt = ".jpg|.png|";
    var extName = file.name.substring(file.name.lastIndexOf(".")).toLowerCase(); //（把路径中的所有字母全部转换为小写）
    if (AllImgExt.indexOf(extName + "|") === -1) {
      let ErrMsg =
        "该文件类型不允许上传。请上传 .jpg、.png 类型的文件，当前文件类型为" +
        extName;
      Message.error(ErrMsg);
      return false;
    }
    const isLt10M = !(
      b2ValueUnit(file.size)[1] === "B" ||
      b2ValueUnit(file.size)[1] === "KB" ||
      (b2ValueUnit(file.size)[1] === "MB" && b2ValueUnit(file.size)[0] <= 1)
    );
    return new Promise((resolve, reject) => {
      if (isLt10M) {
        Message.error("上传文件不能大于1MB");
        reject();
      } else {
        resolve();
      }
    });
  }
  /* checkbox单选 */
  onChangecheckedList = (e, allCheckArr, item) => {
    let list = this.state.allCheckArr;
    list[allCheckArr.value].checked = item;
    this.setState({
      allCheckArr: list,
    });
    //console.log(list, "高级选项");
    this.posteditor();
  };
  deleteimg(item, e) {
    // 阻止合成事件间的冒泡，不会往最外层的div的test方法冒了，如果不加这句代码，就会冒泡至外层div，执行test方法。
    e.stopPropagation();
    //console.log(item, e, "deleteimgdeleteimg");
    let list = this.state.allCheckArr;
    list[item.value].url = "";
    this.setState({
      allCheckArr: list,
    });
  }
  onChangecheckedUpload(item, filelist, file, e) {
    let list = this.state.allCheckArr;
    if (file.status === "done") {
      list[item.value].url = file.response.url
        ? file.response.url
        : URL.createObjectURL(file.originFile);
      this.setState({
        allCheckArr: list,
      });
      this.posteditor();
      //console.log(list, "Progress", URL.createObjectURL(file.originFile));
    }
    //console.log(file, "onChangecheckedUpload");
  }
  //取父div的height
  handleResize = (e) => {
    //获取浏览器窗口高度
    var winHeight = 0;
    if (window.innerHeight) {
      winHeight = window.innerHeight;
    } else if (document.body && document.body.clientHeight) {
      winHeight = document.body.clientHeight;
    }
    //通过深入Document内部对body进行检测，获取浏览器窗口高度
    if (document.documentElement && document.documentElement.clientHeight) {
      winHeight = document.documentElement.clientHeight;
    }
    //DIV高度为浏览器窗口的高度
    //document.getElementById("test").style.height= winHeight +"px";
    //DIV高度为浏览器窗口高度的一半
    // let height = winHeight - document.getElementById("smarteditorHeader").clientHeight - document.getElementById("smarteditorHeader").clientHeight - document.getElementById('footer').clientHeight
    // document.getElementById("rightSider").style.height = height + "px";

    setTimeout(
      function () {
        const container = document.getElementById("iframeContent");
        const height =
          document.getElementById("smarteditorbody").clientHeight - 100;
        container.style.height = height + "px";
        let s = document.getElementById("iframeContent").clientWidth / 1390;
        // let sH = document.getElementById('iframeContent').clientHeight/850
        document.getElementById("oldCommunityId").style.transform =
          "scale(" + s + ") translateX(-50%)";
        document.getElementById("oldCommunityId").style.height =
          height / s + "px";
      },
      e ? 100 : 0
    );
  };

  modulesCheckbox(value) {
    this.state.CheckboxGroupValue = value;
    this.setState({
      CheckboxGroupValue: value,
    });
    this.posteditor();
    //console.log(value);
  }
  setChangefont(value) {
    this.state.selectfont = value;
    this.setState({
      selectfont: value,
    });
    this.posteditor();
    //console.log(value, this.state.selectfont);
  }
  onApi_prefix(value) {
    this.setState({
      api_prefix: value,
    });
    this.isPhoneLegal();
  }
  isPhoneLegal = () => {
    this.posteditor();
  };
  gitgeteditor(iseditor) {
    let query = {
      id: this.state.record.id,
    };
    geteditor(query).then((res) => {
      let list = res.data;
      if (list.id) {
        if (iseditor) {
          this.setState({
            apiInfo: list,
          });
        } else {
          let setcolor = getRgba(list.color, "obj");
          //console.log(setcolor);
          this.setState({
            color: setcolor,
            id: list.id,
            title: list.title,
            logo: list.logo,
            CheckboxGroupValue: list.modules,
            allCheckArr:
              list.advanced.length > 0 ? list.advanced : this.state.allCheckArr,
            selectfont: list.font,
            file: { url: list.logo },
            apiInfo: list,
          });
          // document.title = list.app_name
        }
      }
      //console.log(list, "list", this.state.apiInfo);
    });
  }
  gitconfig() {
    getconfig({ key: "font-list" }).then((res) => {
      let list = res.data;
      this.setState({
        Selectoptions: list.value,
      });
    });
  }
  puteditor(obj) {
    puteditor(obj).then((res) => {
      if (res.code === 200) {
        let event = {
          userinfo: localStorage.getItem("userinfo"),
        };
        event.data = event;
        this.receiveMessageFromIndex(event);
      }
    });
  }
  posteditor() {
    let state = this.state;
    let trans = {
      id: this.state.record.id,
      title: state.title,
      logo: state.logo,
      color: getHexColor(
        `rgba(${state.color.r}, ${state.color.g}, ${state.color.b}, ${state.color.a})`
      ),
      font: state.selectfont,
      api_prefix: state.api_prefix,
      modules: JSON.stringify(state.CheckboxGroupValue),
      advanced:
        state.allCheckArr.length > 0 ? JSON.stringify(state.allCheckArr) : "",
    };
    let pam = {};
    for (let i in trans) {
      if (trans[i]) {
        pam[i] = trans[i];
      }
    }
    pam.logo = state.logo;
    //console.log(pam);
    this.puteditor(pam);
  }
  receiveMessageFromIndex(event) {
    //console.log("我是iframe,我接收到了：");
    let data = event.data;
    document
      .getElementById("oldCommunityId")
      .contentWindow.postMessage(data, "*");
  }
  componentDidMount() {
    let self = this;
    this.gitconfig();
    this.posteditor();
    setTimeout(() => {
      this.gitgeteditor();
    }, 50);
    setTimeout(() => {
      this.handleResize(1);
    }, 300);
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("message", this.receiveMessageFromIndex, false);

    setTimeout(() => {
      let event = {
        userinfo: localStorage.getItem("userinfo"),
        iseditor: false,
      };
      event.data = event;
      this.receiveMessageFromIndex(event);
    }, 3000);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("message", this.receiveMessageFromIndex, false);
  }
  isputapp = (name, desc) => {
    this.putapp(name, desc);
  };
  putapp(name, desc) {
    let params = {
      id: this.state.record.id,
    };
    if (desc !== false) {
      params.desc = desc;
    } else if (name !== false) {
      params.name = name;
    }
    putapp(params).then((res) => {
      if (res.code === 200) {
      } else {
        this.getApiInfo(true);
        Notification.error({ content: res.msg });
      }
    });
  }
  onChangetitle(type, value) {
    //console.log(value, "value");
    var apiInfo = this.state.apiInfo;
    if (type === "desc") {
      if (value.length > 100) {
        apiInfo.app_desc = value.substring(0, 100);
        Notification.warning({ content: "修改内容超过100个字！" });
        return;
      }
      apiInfo.app_desc = value;
      this.setState({
        apiInfo: apiInfo,
      });
      this.isputapp(false, value);
    } else if (type === "name") {
      if (value.length > 20) {
        apiInfo.app_name = value.substring(0, 20);
        Notification.warning({ content: "修改标题超过20个字！" });
        return;
      }
      apiInfo.app_name = value;
      this.setState({
        apiInfo: apiInfo,
      });
      this.isputapp(value, false);
    }
  }

  golink() {
    window.open(this.state.view_url);
  }
  render() {
    const {
      apiInfo,
      isHservice,
      allCheckArr,
      file,
      fileList,
      api_prefix,
      CheckboxGroupValue,
      logo,
      view_url,
      record,
    } = this.state;
    const styles = {
      color: {
        width: "126px",
        height: "30px",
        borderRadius: "10px",
        background: `rgba(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b}, ${this.state.color.a})`,
      },
      swatch: {
        padding: "9px",
        background: "#ffffff",
        borderRadius: "14px",
        boxShadow: "0px 1px 2px 0px rgba(184,200,224,0.22)",
        display: "inline-block",
        border: "1px solid #d8e0f0",
        cursor: "pointer",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    };
    return (
      <div className="P-smarteditor header-edit" style={{ padding: 0 }}>
        <Layout style={{ height: "100%" }}>
          <Header id="smarteditorHeader">
            <div className="GroupTable-fixe GroupTable-fixe-end Header-smarteditor">
              <div>
                <div className="Header-init">
                  <div className="Header-title">
                    <Typography.Paragraph
                      className="Header-uptitle"
                      editable={
                        apiInfo.type === 0
                          ? null
                          : {
                              onChange: this.onChangetitle.bind(this, "name"),
                            }
                      }
                    >
                      {apiInfo.app_name}
                    </Typography.Paragraph>
                  </div>
                </div>
                <div className="Header-upParagraph">
                  <Typography.Paragraph
                    className="Header-uptitle"
                    editable={
                      apiInfo.type === 0
                        ? null
                        : {
                            onChange: this.onChangetitle.bind(this, "desc"),
                          }
                    }
                  >
                    {apiInfo.app_desc}
                  </Typography.Paragraph>
                </div>
              </div>

              <Space size={"medium"} align="center">
                {/* {apiInfo.type === 0 ? (
                  ''
                ) : (
                  <div>
                    <Button
                      className="custom-White-button"
                      onClick={CreateLink.bind(this, apiInfo)}
                    >
                      分享应用
                    </Button>
                  </div>
                )} */}
                <Typography.Text>概览链接</Typography.Text>
                <div className="copyable-box">
                  <Input
                    className="copyable-input"
                    value={view_url}
                    onClick={this.golink.bind(this)}
                    readOnly
                  />
                  <IconCopy
                    className="copyable-Button"
                    onClick={this.oncopy.bind(this)}
                  />
                </div>

                {/* <Typography.Paragraph ellipsis copyable>
                  {view_url}
                </Typography.Paragraph> */}
              </Space>
            </div>
          </Header>
          <Layout>
            <Content>
              <div className="Content-smarteditor" id="smarteditorbody">
                <div className="title-text" id="smarteditortitle">
                  <Space align={"center"}>
                    <Typography.Text>编辑器预览</Typography.Text>
                    {/* <IconQuestionCircle style={{ fontSize: 13, color: '#4E5969' }} /> */}
                  </Space>
                  <Divider />
                </div>
                <div id="iframeContent" style={{ height: 900 }}>
                  <iframe
                    src={view_url}
                    width="1390"
                    height="937"
                    id="oldCommunityId"
                    position="relative"
                    overflow="auto"
                    scrolling="no"
                    style={{
                      position: "absolute",
                      transformOrigin: "0 0",
                      border: 0,
                      left: "50%",
                    }}
                  />
                  {/* <div className="meng"></div> */}
                </div>
              </div>
            </Content>
            <Sider width={396} className={"ac-menu-wrapper"}>
              <div className="rightSider" id="rightSider">
                <>
                  <Space align={"center"}>
                    <Typography.Text>编辑配置</Typography.Text>
                    {/* <IconQuestionCircle style={{ fontSize: 13, color: '#4E5969' }} /> */}
                  </Space>
                  <Divider />
                </>
                <div className="Sider-Content">
                  <div className="Sider-item">
                    <div className="Sider-title">编辑模块</div>
                    <div className="Sider-Content-min">
                      <CheckboxGroup
                        options={this.state.options}
                        value={CheckboxGroupValue}
                        style={{ display: "block" }}
                        onChange={this.modulesCheckbox.bind(this)}
                      />
                    </div>
                  </div>
                  <div className="Sider-item">
                    <div className="Sider-title">版头</div>
                    <div className="Sider-Content-min">
                      <div className="Sider-title-min">LOGO</div>
                      <Upload
                        drag
                        multiple
                        fileList={fileList}
                        showUploadList={false}
                        accept=".jpg,.png"
                        onChange={this.setFile.bind(this)}
                        customRequest={(option) => {
                          this.handleUpload(option);
                        }}
                        onProgress={this.onProgress.bind(this)}
                        beforeUpload={this.beforeUpload.bind(this)}
                        className={"logoupload"}
                      >
                        <div
                          className={`${
                            file && file.status === "error" ? " is-error" : ""
                          }`}
                        >
                          {file && file.url ? (
                            <div className="arco-upload-list-item-picture custom-upload-avatar">
                              <Image src={file.url} width="100" height="100" />
                              <div className="arco-upload-list-item-picture-mask">
                                <IconEdit style={{ marginRight: 10 }} />
                                <IconDelete
                                  onClick={this.delUploadlogo.bind(this)}
                                />
                              </div>
                              {file.status === "uploading" &&
                                file.percent < 100 && (
                                  <Progress
                                    percent={file.percent}
                                    type="circle"
                                    size="mini"
                                    style={{
                                      position: "absolute",
                                      left: "50%",
                                      top: "50%",
                                      transform:
                                        "translateX(-50%) translateY(-50%)",
                                    }}
                                  />
                                )}
                            </div>
                          ) : (
                            <div className="arco-upload-trigger-picture">
                              <div className="arco-upload-trigger-picture-text">
                                <IconPlus />
                                <div
                                  style={{
                                    marginTop: 10,
                                    fontWeight: 400,
                                    fontSize: 12,
                                    color: "#4E5969",
                                  }}
                                >
                                  LOGO
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </Upload>
                      <div className="Sider-upload-tip">
                        （仅支持 JPG 和 PNG 格式，最大文件尺寸1MB）
                      </div>
                      <div className="Sider-title-min">颜色</div>
                      <div style={styles.swatch} onClick={this.handleClick}>
                        <div style={styles.color} />
                      </div>
                      {this.state.displayColorPicker ? (
                        <div style={styles.popover}>
                          <div
                            style={styles.cover}
                            onClick={this.handleClose}
                          />
                          <SketchPicker
                            color={this.state.color}
                            onChange={this.handleChange.bind(this)}
                            onChangeComplete={this.onChangeComplete.bind(this)}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="Sider-item">
                    <div className="Sider-title">字体</div>
                    <div className="Sider-Content-min">
                      <Select
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        value={this.state.selectfont}
                        placeholder=" 请选择字体"
                        style={{ width: 300 }}
                        onChange={this.setChangefont.bind(this)}
                      >
                        {this.state.Selectoptions.map((option, index) => (
                          <Option key={option} value={option}>
                            {option}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                  {isHservice ? (
                    <div className="Sider-item">
                      <div className="Sider-title">高级功能</div>
                      <div className="Sider-Content-min">
                        <div className="Sider-title-min">文档服务</div>
                        <>
                          {allCheckArr.map((item, key) => (
                            <div className="service-allCheckArr" key={key}>
                              <Checkbox
                                onChange={this.onChangecheckedList.bind(
                                  this,
                                  allCheckArr,
                                  item
                                )}
                                checked={item.checked}
                              >
                                <Row
                                  className="grid-demo"
                                  justify="space-between"
                                  align="center"
                                >
                                  <Col flex={6}>
                                    <Space align="center">
                                      <Typography.Text>
                                        {item.title}
                                      </Typography.Text>
                                    </Space>
                                  </Col>
                                  <Col flex={1}>
                                    <Space align="center">
                                      <Image
                                        className={"service-img"}
                                        width={15}
                                        height={15}
                                        src={item.url}
                                        loaderClassName="image-demo-loader-animate"
                                      />
                                      <Dropdown
                                        unmountOnExit={false}
                                        getPopupContainer={(triggerNode) =>
                                          triggerNode.parentNode
                                        }
                                        droplist={
                                          <Menu>
                                            <Menu.Item>
                                              <Upload
                                                accept="image/*,.pdf,.svg"
                                                onChange={this.onChangecheckedUpload.bind(
                                                  this,
                                                  item
                                                )}
                                                customRequest={(option) =>
                                                  this.handleUpload(option)
                                                }
                                                beforeUpload={this.beforeUpload.bind(
                                                  this
                                                )}
                                                showUploadList={false}
                                                className={"Uploadservice"}
                                              >
                                                <Button
                                                  type="text"
                                                  className="tihuanbtn"
                                                >
                                                  {" "}
                                                  <IconUpload />
                                                  {item.url
                                                    ? "替换图标"
                                                    : "上传图标"}
                                                </Button>
                                              </Upload>
                                            </Menu.Item>
                                            {item.url ? (
                                              <Menu.Item>
                                                <Button
                                                  type="text"
                                                  className="tihuanbtn"
                                                  onClick={this.deleteimg.bind(
                                                    this,
                                                    item
                                                  )}
                                                >
                                                  <IconDelete />
                                                  删除图标
                                                </Button>
                                              </Menu.Item>
                                            ) : (
                                              ""
                                            )}
                                          </Menu>
                                        }
                                        trigger="click"
                                      >
                                        <Button
                                          size="mini"
                                          type="text"
                                          className="Dropdownbtn"
                                          icon={<IconDown />}
                                        />
                                      </Dropdown>
                                    </Space>
                                  </Col>
                                </Row>
                              </Checkbox>
                            </div>
                          ))}
                        </>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="Sider-item">
                    <div className="Sider-title">api地址</div>
                    <div className="Sider-Content-min">
                      <Input
                        style={{ width: 300 }}
                        allowClear
                        placeholder=""
                        value={api_prefix}
                        onChange={this.onApi_prefix.bind(this)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Sider>
          </Layout>
          <Footer style={{ height: "18px" }} id="footer"></Footer>
        </Layout>
      </div>
    );
  }
}
export default SmartEditor;
