import React, { Component } from 'react'
import JQ from 'jquery'
import SiteUrl from 'envconfig/config'
import {
  Layout,
  Typography,
  Button,
  Space,
  Empty,
  Notification,
  Spin,
  Modal,
  Select,
  Input,
  Divider,
} from '@arco-design/web-react'
import Emptydata_img from 'asserts/Emptydata_img.svg'
import Generating_img from 'asserts/Generating_img.gif'
import errorIcon from 'asserts/error_icon.svg'
import { shareAppApi } from 'api/apis'
import history from 'common/utils/history'
import { debounce, getRandomArrayElements } from 'common/utils'
import UpgradeTips from 'components/UpgradeTips'
import '../../common/style/myapp/image2text.scss'
import { IconDownload, IconPlus, IconSearch } from '@arco-design/web-react/icon'
const Sider = Layout.Sider
const Content = Layout.Content
const Option = Select.Option
const TextArea = Input.TextArea
const jobsArray = [
  '财务经理',
  '系统工程师',
  '硬件维护工程师',
  '机器学习工程师',
  '运维开发工程师',
  '深度学习工程师',
  '游戏开发工程师',
  '网络工程师',
  '网络信息安全工程师',
  '前台',
  'IOS开发工程师',
  '会计师',
  '安全测试工程师',
  'Javascript开发工程师',
  'IT支持工程师',
  '游戏测试工程师',
  '游戏运营经理',
  '审计专员',
  '税务主管',
  '移动开发工程师',
  '秘书',
  '审计助理',
  '税务专员',
  '软件测试工程师',
  'Node.会计助理',
  '行政主管',
  '财务主管',
  '嵌入式软件开发工程师',
  '区域销售助理',
  '交互设计师',
  '会计主管',
  'HTML5开发工程师',
  '数据分析师',
  '审计经理',
  '客户经理',
  '会计经理',
  '电商运营经理',
  '开发工程师',
  'Android开发工程师',
  '运营主管',
  'Python开发工程师',
  '财务助理',
  '新媒体运营经理',
  '平面设计师',
  '美工',
  '行政经理',
  '数据挖掘工程师',
  '算法工程师',
  '产品专员',
  '审计主管',
  '产品设计',
  '会计',
  '税务助理',
  '产品运营经理',
  '行政助理',
  '视觉设计师',
  '数据开发工程师',
  '全栈开发工程师',
  '行政专员',
  '性能测试工程师',
  '会计文员',
  '税务经理',
  '测试开发工程师',
  'UI设计师',
  'C开发工程师',
  '信息技术专员工程师',
  'ERP技术开发工程师',
  'WEB前端开发工程师',
  '用户运营经理',
  '出纳员',
  '内容运营经理',
  '网站运营经理',
  '后勤',
  'GUI设计师',
  '移动前端开发工程师',
  '移动端测试工程师',
  '产品助理',
]
// 封装组件
class Image2Text extends Component {
  constructor(props) {
    super(props)
    this.state = {
      share_id: localStorage.getItem('share_ids'),
      no_data: false,
      selectValue: '产品运营经理',
      ContinuationLoading: true,
      record: JSON.parse(localStorage.getItem('shareRecord')),
      apiInfo: {
        model_id: '',
      },
      jobDesc: [
        {
          require: [
            '1.文案功底过硬，无论是一个banner的文案，还是一个活动页面的展示描述，都能有较好的呈现方式和文案思路',
            '2.2年以上互联网产品运营经验，有成功案例优先，有公众号、小程序运营经验者优先',
            '3.可与ui、开发沟通无障碍，能够独立完成产品运营、活动运营的方案、需求的输出，能够独立跟踪ui、开发完成运营需求的上线，并能在上线后持续跟踪数据效果，提出迭代建议和方案',
            '4.有责任心，逻辑思维能力强，抗压能力较好',
            '5.数据敏感度高，数据分析能力强，能够准确的通过数据分析找到产品功能、运营页面中存在的问题，并能结合数据找出解决的方法，会sql优先',
          ],
          desc: [
            '1.个模块的运营工作，能独立规划所负责的运营模块，完成追踪上线并不断优化',
            '2.负责水滴公司内、外部渠道的场景拉新及转化，高效利用渠道流量达成业务增长，利用多维度的ab测试等方法，找到最佳的水滴保引流转化方式，不断提升运营位以及落地页面的转化效率',
            '3.负责水滴保线上业务的增长运营工作，包括但不限于拉新、转化、活动、复购等模块的运营工作，能够负责其中',
            '4.与公司内部流量部门保持较好的沟通和关系，不断挖掘内部流量渠道适合的转化位置和场景，不断的增加流量开口',
            '5.负责水滴保用户画像的建立，并能根据不同用户情况，规划整理针对不同用户的精细化运营策略和方案。',
          ],
          job: '产品运营经理',
          welfare:
            '补充医疗保险、定期体检、年终奖、股票期权、带薪年假、员工旅游、节日福利、住房补贴',
          address: '广东珠海唐家湾镇创意云端1栋605',
          email: 'hr@yoojober.com',
          phone: '18578205080',
          qrcode: 'https://image.yoojober.com/share/yoo_qrcode.png',
          logo: 'https://image.yoojober.com/share/yoo_logo.png',
          keyword: '',
          salary: '30-60k·15薪',
        },
        {
          require: [
            '1.3年以上产品运营经理工作经验,具备一定的项目管理能力可以负责产品运营管理',
            '2.熟悉医疗行业,具备医疗平台类产品相关经验',
            '3.能够快速捕获用户需求,定位产品目标,并独立产出规范的产品原型或产品文档',
            '4.熟练掌握产品运营方法体系,具备较强的运营专业能力,包括但不限于运营策划能力、产品包装能力、数据分析能力',
            '5.擅长分析行业趋势紧跟行业动态具备敏锐的产品见解',
          ],
          desc: [
            '1.能够负责产品运营,熟悉处方流转环节,对医药行业有了解,具有较强的运营能力',
            '2.负责互联网医疗的相关产品市场调研、需求分析、产品功能设计',
            '3.独立完成产品推广、品牌和市场宣传工作',
            '4.负责产品生命周期的各个环节,能够独立完成产品的策划、原型、产品流程和交互设计,并完成需求文档的撰写',
          ],
          job: '产品运营经理',
          welfare:
            '五险一金、周末双休、14薪、弹性上班、免费午餐、年度旅游、年度体检、假日福利',
          address: '广东珠海唐家湾镇创意云端1栋605',
          email: 'hr@yoojober.com',
          phone: '18578205080',
          qrcode: 'https://image.yoojober.com/share/yoo_qrcode.png',
          logo: 'https://image.yoojober.com/share/yoo_logo.png',
          keyword: '',
          salary: '30-60k·15薪',
        },
      ],
      jobDescString: '',
      jobs: getRandomArrayElements(jobsArray, 5),
      requestMsg: '',
      requestMsgText: '您还未输入关键信息',
      visible: false,
      loading: false,
      loadingCount: 12,
      loadingIndex: 0,
      loadingImages: [],
      hrIframeUrl: SiteUrl.styleUrl + '/iframeHr.html?v=0412-6',
      isListenIframeMessage: false,
      currentClick: 0,
      scale: 0.144,
      isBreak: false,
      waterFall: [],
      optionVlue: '',
      input_describe: '输入或搜索生成招聘海报的岗位',
      visibleUpgrade: false,
      upgradetips: null,
      action: 'analyze',
    }
    this.isputapp = debounce(this.isputapp, 1000)
  }
  componentDidMount() {
    this.getApiInfo()
    this.inputValue(this.state.jobDesc)
  }
  componentWillUnmount() {
    localStorage.removeItem('props')
  }

  getApiInfo() {
    let list = this.state.record
    this.setState({
      apiInfo: list,
    })
    if (list.type === 0) {
      setTimeout(() => {
        this.setState({
          loadingIndex: 0,
          loadingImages: [],
          loading: true,
          ContinuationLoading: false,
          requestMsg: '',
        })
        this.createIframeStart()
      }, 500)
    } else {
      this.setState({
        loadingIndex: 0,
        loadingImages: [],
        loading: false,
        ContinuationLoading: false,
        requestMsg: '',
        requestMsgText: '点击生成海报生成内容',
      })
    }
  }
  onSearch(value) {
    this.setState({
      jobs: value ? jobsArray : getRandomArrayElements(jobsArray, 5),
    })
  }
  onChange(value) {
    this.setState({
      selectValue: value,
      jobs: getRandomArrayElements(jobsArray, 5),
    })
    setTimeout(() => {
      this.setRequest('write')
    }, 300)
  }
  isPhoneLegal = () => {
    this.setRequest('analyze')
    return null
    const jobDesc = this.dealTextArea()

    let state = {
      loading: true,
      loadingIndex: 0,
      loadingImages: [],
      ContinuationLoading: false,
      jobDesc: jobDesc,
      requestMsg: '',
      isBreak: true,
    }

    if (!jobDesc[0].job) {
      state.loading = false
      state.requestMsgText = '您还未输入关键信息'
      return
    }

    this.inputValue(jobDesc)
    this.setState(state)
    JQ('#Continuation-list').height(100)

    setTimeout(() => {
      this.createIframeStart()
      this.setState({
        isBreak: false,
      })
    }, 500)
  }

  shuffle(array) {
    let m = array.length,
      t,
      i
    while (m) {
      i = Math.floor(Math.random() * m--)
      t = array[m]
      array[m] = array[i]
      array[i] = t
    }
    return array
  }
  createIframeContent(iframe, clientId) {
    iframe.contentWindow.postMessage(
      {
        type: 'show',
        resume: this.state.jobDesc,
        styles: '',
        base64: 1,
        clientId: clientId,
      },
      '*'
    )
  }
  createIframeEnd() {
    this.setState({ loadingIndex: this.state.loadingIndex + 1 })

    let $listContainer = JQ('#Continuation-list')

    let iframe = document.createElement('iframe')
    iframe.src = this.state.hrIframeUrl
    iframe.style.transform =
      'scale(' + this.state.scale + ') translate3d(0,0,0) translateZ(0)'

    if (iframe.attachEvent) {
      iframe.attachEvent('onload', () => {
        this.createIframeContent(iframe, this.state.loadingIndex)
      })
    } else {
      iframe.onload = () => {
        this.createIframeContent(iframe, this.state.loadingIndex)
      }
    }

    $listContainer
      .find('.Continuation-item')
      .eq(this.state.loadingIndex - 1)
      .append(iframe)
  }
  createIframeStart() {
    if (!this.state.isListenIframeMessage) {
      this.setState({ isListenIframeMessage: true })
      window.addEventListener(
        'message',
        (event) => {
          if (
            event.data.data &&
            event.data.data.clientId &&
            !this.state.isBreak
          ) {
            const index = event.data.data.clientId - 1
            this.state.loadingImages[index] = event.data.data.base64
            const $container = JQ('#Continuation-list')
            let $item = $container.find('.Continuation-item').eq(index)

            const currentHeight = event.data.data.totalHeight * this.state.scale
            $item.height(currentHeight)
            $item.children('iframe').height(event.data.data.totalHeight)

            const [oneWidth, marginRight, marginBottom] = [162, 12, 25]
            const containerW = $container.width()
            const colsNum = Math.floor(containerW / (oneWidth + marginRight))
            const marginRight2 = Math.floor(
              (containerW - colsNum * (oneWidth + marginRight)) / colsNum
            )
            const marginRight3 = marginRight + marginRight2

            const index2 = index % colsNum
            let css = {
              top: 0,
              left: index2 * (oneWidth + marginRight3),
              opacity: 1,
            }

            if (index >= colsNum) {
              css.top = this.state.waterFall[index - colsNum] + marginBottom
            }

            this.state.waterFall[index] = css.top + currentHeight

            if ($container.height() < this.state.waterFall[index]) {
              $container.height(this.state.waterFall[index])
            }

            $item.css(css)
            // console.log(
            //   this.state.loadingIndex,
            //   this.state.loadingCount,
            //   this.state.loadingIndex % this.state.loadingCount
            // )
            if (
              this.state.loadingIndex > 0 &&
              this.state.loadingIndex % this.state.loadingCount === 0
            ) {
              this.setState({
                loading: false,
              })
            } else {
              this.createIframeEnd()
            }
          }
        },
        false
      )
    }

    this.createIframeEnd()
  }
  onKeyDown(event) {
    //console.log(event.keyCode, "键盘事件");
    if (event.shiftKey && event.keyCode === 13) {
      let e = this.state.TextAreaValue
      this.setState({
        TextAreaValue: e,
      })
    } else if (event.ctrlKey && event.keyCode === 13) {
      event.cancelBubble = true
      event.preventDefault()
      event.stopPropagation()
      event.returnValue = false
      this.setRequest('write')
      return false
    } else if (event.keyCode === 13) {
      event.cancelBubble = true
      event.preventDefault()
      event.stopPropagation()
      this.setRequest('write')
      event.returnValue = false
      return false
    }
  }
  setRequest(action, iscalculation) {
    const { apiInfo, loadingIndex } = this.state
    if (
      (action === 'write' && this.state.selectValue === '') ||
      (action === 'analyze' && this.state.jobDescString === '')
    ) {
      this.setState({
        // jobDesc: [],
        ContinuationLoading: false,
        loading: false,
        loadingIndex: 0,
        loadingImages: [],
        requestMsg: '',
        requestMsgText: '您还未输入关键信息',
      })
    } else {
      let params = {
        app_id: this.state.record.app_id,
        share_id: this.state.share_id,
        text:
          action === 'write'
            ? this.state.selectValue
            : this.state.jobDescString,
        num: 2,
        action: action,
        format: 'true',
        count_num: apiInfo.type === 0 ? 0 : this.state.loadingCount,
      }
      if (!iscalculation) {
        this.setState({
          loading: true,
          ContinuationLoading: true,
          action: action,
        })
      }
      shareAppApi(params).then((res) => {
        if (res.code === 200) {
          if (!iscalculation || loadingIndex === 0) {
            this.inputValue(res.data)

            this.setState({
              jobDesc: res.data,
              loadingIndex: 0,
              loadingImages: [],
              ContinuationLoading: false,
              requestMsg: '',
            })
            this.createIframeStart()
          } else {
            this.setState({
              ContinuationLoading: false,
            })
            this.createIframeEnd()
          }
        } else if (res.code === 400 || res.code === 3003) {
          this.setState({
            // jobDesc: [],
            jobDescString: '',
            ContinuationLoading: false,
            requestMsg: '',
            loadingIndex: 0,
            loadingImages: [],
            requestMsgText: res.msg,
            loading: false,
          })
        } else {
          this.setState({
            // jobDesc: [],
            jobDescString: '',
            loadingIndex: 0,
            loadingImages: [],
            ContinuationLoading: false,
            requestMsg: 'error',
            requestMsgText: res.msg,
            loading: false,
          })
        }
      })
    }
  }

  ChangeAbatch() {
    this.setState({
      loading: true,
      requestMsg: '',
    })
    setTimeout(() => {
      this.setRequest(this.state.action, true)
      // this.createIframeEnd();
    }, 300)
  }
  setdownload(visible) {
    this.setState({
      visible: visible,
    })
  }
  opendownload(item) {
    //console.log(item, this.state.loadingImages);
    this.setdownload(true)
    this.setState({
      currentClick: item,
    })
  }
  getUrlBase64() {
    let url = this.state.loadingImages[this.state.currentClick]

    fetch(url)
      .then(async (res) => await res.blob())
      .then((blob) => {
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = URL.createObjectURL(blob)
        a.download = '轻职图-' + Date.now() + '.jpg'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      })
  }

  onTextArea(value) {
    this.setState({
      jobDescString: value,
    })
  }
  dealTextArea() {
    const lines = this.state.jobDescString.split('\n')
    let jobDesc = this.state.jobDesc
    jobDesc[0].job = ''
    jobDesc[0].require = []
    jobDesc[0].desc = []

    const matches = {
      job: /^(招聘岗位|岗位|招聘职位|职位|岗位名称)[\:：]{1}(.*)$/,
      require:
        /^(岗位要求|要求|招聘职位|职位|招聘要求|任职资格|任职要求)[\:：]{1}(.*)$/,
      desc: /^(岗位职责|岗位描述|职责|描述|工作职责|职责描述|工作内容)[\:：]{1}(.*)$/,
      item: /^\d*[、\.：\:](.+)/,
    }

    let match = []
    let key = null
    let index = 0

    lines.forEach(function (line) {
      line = JQ.trim(line)

      if (!line) {
        return null
      }

      if (matches.job.test(line)) {
        match = line.match(matches.job)
        jobDesc[0]['job'] = match[2]
        key = 'job'
      } else if (matches.require.test(line)) {
        match = line.match(matches.require)
        key = 'require'
        index = 0

        if (match[2]) {
          index = 1
          jobDesc[0]['require'].push(index + '.' + match[2])
        }
      } else if (matches.desc.test(line)) {
        match = line.match(matches.desc)
        key = 'desc'
        index = 0

        if (match[2]) {
          index = 1
          jobDesc[0]['desc'].push(index + '.' + match[2])
        }
      } else if (key) {
        if (key === 'job') {
          jobDesc[0][key] = line
        } else {
          match = line.match(matches.item)

          if (match && match[1]) {
            index++
            jobDesc[0][key].push(index + '.' + match[1])
          } else {
            index++
            jobDesc[0][key].push(index + '.' + line)
          }
        }
      }
    })

    return jobDesc
  }

  inputPlaceHolder() {
    return '请以标签+内容的形式，输入海报相关信息\r\n例\n招聘岗位：产品经理\n岗位要求：...\n岗位职责：...'
  }
  inputValue(jobDesc) {
    let input = ''

    if (jobDesc.length > 0) {
      if (jobDesc[0].job) {
        input += '招聘岗位：' + jobDesc[0].job + '\n'
      }

      if (jobDesc[0].require.length > 0) {
        input += '\n' + '岗位要求：' + '\n'

        jobDesc[0].require.forEach(function (value) {
          input += value + '\n'
        })
      }

      if (jobDesc[0].desc.length > 0) {
        input += '\n' + '岗位描述：' + '\n'

        jobDesc[0].desc.forEach(function (value) {
          input += value + '\n'
        })
      }
    }

    this.setState({ jobDescString: input })
  }

  render() {
    const {
      input_describe,
      selectValue,
      loading,
      loadingIndex,
      jobDescString,
      ContinuationLoading,
      requestMsg,
      requestMsgText,
    } = this.state

    return (
      <div className="image2text">
        <Layout className="semanticprototype-Layout" id="layout_id">
          <Sider className="Sider-left">
            <div className="Sider-left-configure columnSpaceBetween">
              <div className="Continuation-input">
                <div id="selectinput">
                  <Select
                    placeholder={input_describe}
                    defaultValue={selectValue}
                    style={{ width: '100%' }}
                    suffixIcon={<IconSearch />}
                    showSearch
                    allowClear
                    onSearch={this.onSearch.bind(this)}
                    onChange={this.onChange.bind(this)}
                  >
                    {this.state.jobs.map((option, index) => (
                      <Option key={index} value={option}>
                        {option}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="jobDesc-content" id="jobdesc">
                  <TextArea
                    placeholder={this.inputPlaceHolder()}
                    style={{ width: '100%', height: '100%' }}
                    autoSize={false}
                    onChange={this.onTextArea.bind(this)}
                    onKeyDown={(event) => this.onKeyDown(event)}
                    value={jobDescString}
                  />
                </div>
              </div>
              <div className="Upload-pictures-bottom" id="start_generation">
                <Button
                  type="primary"
                  className="Start-generation"
                  onClick={this.isPhoneLegal.bind(this)}
                >
                  生成海报
                </Button>
              </div>
            </div>
          </Sider>
          <Content>
            <div className="experience-result" id="result">
              <div id="result_title" className="rowSpaceBetween result_title">
                <Typography>生成结果</Typography>
                <Typography.Paragraph className="result-remarks">
                  (内容基于韦尼克AI创作引擎生成，仅供参考)
                </Typography.Paragraph>
              </div>
              <div className="continuation-body" id="continuation">
                {loadingIndex > 0 || ContinuationLoading ? (
                  <div className="Paragraph-generate">
                    {ContinuationLoading ? (
                      <Empty
                        className="Continuation-loding"
                        icon={<img src={Generating_img} alt="" />}
                        description={
                          <Typography
                            style={{
                              color: '#4e5969',
                              marginTop: 21,
                              fontSize: 16,
                            }}
                          >
                            正在生成中...
                          </Typography>
                        }
                      />
                    ) : (
                      <div className="Continuation-result" id="continuation">
                        <div
                          className="Continuation-list"
                          id="Continuation-list"
                        >
                          {Array(loadingIndex)
                            .fill(0)
                            .map((item, index) => {
                              return (
                                <div
                                  className="Continuation-item"
                                  key={index}
                                  onClick={this.opendownload.bind(this, index)}
                                />
                              )
                            })}
                        </div>
                        {loading ? (
                          <Spin dot />
                        ) : (
                          <Button
                            icon={<IconPlus />}
                            id="iconSync"
                            className="IconSync-btn custom-White-border-button"
                            onClick={this.ChangeAbatch.bind(this)}
                          >
                            生成更多
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                ) : requestMsg === 'error' ? (
                  <Empty
                    className="Continuation-Empty Empty-error"
                    imgSrc={errorIcon}
                    description={
                      <Space direction="vertical">
                        <Typography
                          style={{
                            color: '#4e5969',
                            marginTop: 30,
                            fontSize: 16,
                          }}
                        >
                          抱歉，服务器生成错误
                        </Typography>
                        <Typography style={{ color: '#4e5969', fontSize: 16 }}>
                          可尝试
                          <span
                            style={{ color: '#406EFF', cursor: 'pointer' }}
                            onClick={this.ChangeAbatch.bind(this)}
                          >
                            重新生成
                          </span>
                        </Typography>
                      </Space>
                    }
                  />
                ) : (
                  <Empty
                    className="Continuation-Empty Empty-none"
                    imgSrc={Emptydata_img}
                    description={
                      <Space direction="vertical">
                        <Typography
                          style={{
                            color: '#4e5969',
                            marginTop: 30,
                            fontSize: 16,
                          }}
                        >
                          暂无生成结果
                        </Typography>
                        <Typography style={{ color: '#4e5969', fontSize: 16 }}>
                          {requestMsgText}
                        </Typography>
                      </Space>
                    }
                  />
                )}
              </div>
            </div>
          </Content>
        </Layout>
        <Modal
          style={{
            background: 'transparent',
            width: 396,
          }}
          onCancel={() => this.setdownload(false)}
          className="image2text-modal-content"
          title={null}
          visible={this.state.visible}
          autoFocus={false}
          footer={null}
        >
          <div className="image2text-modal">
            <div className="image2text-img">
              {
                <img
                  src={this.state.loadingImages[this.state.currentClick]}
                  alt="轻职图海报"
                />
              }
            </div>

            <div className="image2text-opera">
              <Button
                type="primary"
                className="download-btn"
                onClick={this.getUrlBase64.bind(this)}
              >
                <IconDownload />
                立即下载
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}
export default Image2Text
