import React, { Component } from "react";
import { Outlet } from "react-router-dom";

import "./enginefactory.scss";

// 封装组件
class EngineFactory extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}
  UNSAFE_componentDidUpdate() {}

  render() {
    return (
      <div className="main-content enginefactory">
        <Outlet />
      </div>
    );
  }
}
export default EngineFactory;
