import React, { Component } from "react";
import {
  Table,
  Card,
  Typography,
  Button,
  Divider,
  Select,
  Message,
  Progress,
  Space,
  Tag,
  Switch,
  Radio,
  Input,
  Empty,
  Spin,
  Checkbox,
  Modal,
} from "@arco-design/web-react";
import {
  IconCheck,
  IconMinusCircleFill,
  IconLoading,
  IconExclamationCircleFill,
  IconCheckCircleFill,
  IconSettings,
  IconClose,
  IconDelete,
} from "@arco-design/web-react/icon";
import { Link } from "react-router-dom";
import { timeConsuming, getCurrentDate } from "common/utils/index";
import SaasBreadcrumb from "components/SaasBreadcrumb";
import history from "common/utils/history";
import Emptydata_img from "asserts/Emptydata_img.svg";
import "../model.scss";
import {
  getmodelListVersion,
  putmodelversion,
  getdatumList,
  postmodelVersionTrain,
  deleteModelVersion,
} from "api/apis";
const Option = Select.Option;

class Management extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      all_count: 0,
      pagination: {
        sizeCanChange: false,
        showTotal: true,
        total: 0,
        pageSize: 10,
        current: 1,
        pageSizeChangeResetCurrent: true,
        showJumper: false,
      },
      loadingbody: true,
      loading: false,
      no_data: false,
      addkey: 3,
      pathSnippets: null,
      extraBreadcrumbItems: null,
      record: JSON.parse(localStorage.getItem("record")),
      options: [],
      train_status: "全部",
      positionList: ["全部", "训练成功", "训练中", "待训练", "训练失败"],
      columns: [
        {
          title: "版本号",
          dataIndex: "version",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
        {
          title: "激活状态",
          dataIndex: "is_active",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          render: (col, record, index) => (
            <span>
              <span
                style={{
                  width: 56,
                  display: "inline-block",
                  textAlign: "right",
                  color:
                    record.train_status !== "训练成功" ? "#D0D0D0" : "#0C0C0F",
                }}
              >
                {record.is_active ? "激活" : "未激活"}
              </span>
              {record.train_status === "训练成功" ? (
                <Switch
                  style={{
                    marginLeft: 17,
                    background: !record.is_active ? "#b9d0ff" : "#406EFF",
                  }}
                  onChange={
                    !record.is_active ? this.onOpenMode.bind(this, record) : ""
                  }
                  checked={record.is_active}
                />
              ) : (
                <Switch style={{ marginLeft: 17 }} checked={record.is_active} />
              )}
            </span>
          ),
        },
        {
          title: "数据集",
          dataIndex: "data_name",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
        {
          title: "状态",
          dataIndex: "train_status",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          render: (col, record, index) => (
            <span>
              {
                <Tag
                  color={
                    col === "训练成功"
                      ? "green"
                      : col === "训练中"
                      ? "arcoblue"
                      : col === "训练失败"
                      ? "gray"
                      : "orangered"
                  }
                  icon={
                    col === "训练成功" ? (
                      <IconCheckCircleFill style={{ color: "#00B42A" }} />
                    ) : col === "训练中" ? (
                      <IconLoading style={{ color: "#165dff" }} />
                    ) : col === "训练失败" ? (
                      <IconMinusCircleFill style={{ color: "#86909C" }} />
                    ) : (
                      <IconExclamationCircleFill style={{ color: "#ff7d00" }} />
                    )
                  }
                >
                  {col}
                </Tag>
              }
            </span>
          ),
        },
        {
          title: "训练时长",
          dataIndex: "train_duration",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          render: (col, record, index) => (
            <span>
              {record.train_status === "训练中" ? (
                <Progress
                  size={"large"}
                  percent={record.train_percent}
                  width="123px"
                />
              ) : record.train_status === "训练失败" ? (
                <Progress
                  size={"large"}
                  color="#C9CDD4"
                  percent={record.train_percent}
                  width="123px"
                />
              ) : (
                col
              )}
            </span>
          ),
        },
        {
          title: "创建时间",
          dataIndex: "submit_time",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          render: (col, record, index) => (
            <span>{getCurrentDate("-", ":", col)}</span>
          ),
        },
      ],
      capability: "",
      model_capability: "",
      checkbox: false,
      selectedRowKeys: [],
      type: "checkbox",
      checkAll: false,
      indeterminate: false,
      SelectcheckAll: false,
      visibledelete: false,
      confirmLoading: false,
    };
  }
  componentDidMount() {
    // this.getdatumList()
    if (
      JSON.parse(localStorage.getItem("userinfo")) &&
      JSON.parse(localStorage.getItem("userinfo")).group_permission
    ) {
      let identity = JSON.parse(
        localStorage.getItem("userinfo")
      ).group_permission;
      if (identity && identity !== "PERSONAL") {
        let columns = this.state.columns;
        let json = {
          title: "创建者",
          dataIndex: "nickname",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          render: (col, record, index) => (
            <span
              style={{ color: record.is_deleted !== 0 ? "#8d93a1" : "#0c0c0f" }}
            >
              {col}
            </span>
          ),
        };
        this.setState({
          columns: columns.concat(json),
        });
      }
    }
    this.getmodelListVersion();
    if (JSON.parse(localStorage.getItem("Selectoptions"))) {
      this.setState({
        capability: JSON.parse(localStorage.getItem("Selectoptions"))[
          this.state.record.model_capability
        ],
      });
    }
  }
  // 选择数据集
  getdatumList() {
    let page = {
      per_page: 10,
      page: 1,
      model_capability: this.state.record.model_capability,
    };
    this.setState({
      model_capability: this.state.record.model_capability,
    });
    getdatumList(page).then((res) => {
      let list = res.data;
      this.setState({
        options: list.data,
      });
      if (JSON.parse(localStorage.getItem("Selectoptions"))) {
        this.setState({
          capability: JSON.parse(localStorage.getItem("Selectoptions"))[
            this.state.record.model_capability
          ],
        });
      }
      //console.log(list, 'list')
    });
  }
  onDataChange(value) {
    //console.log(value, '选中')
  }
  onOpenMode(record) {
    //console.log(record)
    this.putmodelversion(record.id);
  }
  getmodelListVersion(loading) {
    this.setState({
      loading: loading,
    });
    let page = {
      per_page: this.state.pagination.pageSize,
      page: this.state.pagination.current,
      model_id: this.state.record.model_id
        ? this.state.record.model_id
        : this.state.record.id,
      train_status:
        this.state.train_status === "全部" ? "" : this.state.train_status,
    };
    getmodelListVersion(page).then((res) => {
      if (res.code === 200) {
        let list = res.data;
        let pagination = this.state.pagination;
        pagination.total = list.count;
        this.setState({
          pagination: pagination,
          all_count: list.all_count,
          data: list.data,
          loading: false,
          loadingbody: false,
        });
        //console.log(list, 'list')
      } else {
        this.setState({
          loading: false,
          loadingbody: false,
        });
      }
    });
  }
  putmodelversion(optionVlue) {
    let params = {
      id: optionVlue,
    };
    putmodelversion(params).then((res) => {
      if (res.code === 200) {
        this.getmodelListVersion();
      } else {
      }
    });
  }
  startTrain(json, index) {
    let params = {
      id: json.id,
      data_id: json.data_id,
    };
    postmodelVersionTrain(params).then((res) => {
      if (res.code === 200) {
        this.getmodelListVersion();
      } else {
      }
    });
  }
  PauseTraining(json, index) {
    let newdata = this.state.data;
    newdata.map((item, idx) => {
      if (item.key === index) {
        item.state = 0;
        clearInterval(item.timer);
        this.setState({
          data: newdata,
        });
      }
    });
  }
  addRow() {
    localStorage.removeItem("props");
    let record = this.state.record;
    record.version_count = this.state.all_count + 1;
    localStorage.setItem("record", JSON.stringify(record));
    history.push("/enginefactory/model/management/add");
    window.location.reload();
  }
  onChangeTable(pagination) {
    const { current, pageSize } = pagination;
    this.setState({
      loading: true,
    });

    let setPagination = this.state.pagination;
    setPagination.current = current;
    setPagination.pageSize = pageSize;
    this.setState({
      loading: false,
      pagination: setPagination,
    });
    this.getmodelListVersion();
  }
  componentWillUnmount() {
    clearInterval(this.state.timeout);
  }
  UNSAFE_componentWillMount() {
    const urlParams = new URL(window.location.href);
    const pathname = urlParams?.pathname;
    //console.log(history, 'history', pathname)
    history.listen((route) => {
      //console.log(route, 'route'); // 这个route里面有当前路由的各个参数信息
    });
  }
  goverision(record) {
    if (record.train_status === "训练成功") {
      history.push("/enginefactory/model/management/versiondetails", {
        record: record,
      });
      localStorage.setItem("record", JSON.stringify(record));
    } else {
      record.train_details = "详情";
      localStorage.setItem("record", JSON.stringify(record));
      localStorage.setItem("props", JSON.stringify(this.state));
      history.push("/enginefactory/model/management/add");
    }
    // else if (record.train_status === "训练中") {
    //     history.push('/enginefactory/model/management/add')
    // } else if (record.train_status === "训练失败") {
    //     history.push('/enginefactory/model/management/add')
    // }
    window.location.reload();
  }
  onsetPosition(value) {
    //console.log(value, 'value')
    let pagination = this.state.pagination;
    pagination.current = 1;
    this.setState({
      pagination: pagination,
      train_status: value,
    });
    this.state.train_status = value;
    this.getmodelListVersion();
  }
  setSelectedRowKeys(selectedRowKeys) {
    let selectkeys = [];
    this.state.data.forEach((i, index) => {
      //console.log(i.enable_del)
      if (i.enable_del) {
        selectkeys.push(i.id);
      }
    });
    this.setState({
      selectedRowKeys: selectedRowKeys,
      SelectcheckAll:
        selectedRowKeys.length === selectkeys.length ? true : false,
    });
  }
  onChangeAll(checked) {
    let selectkeys = [];
    if (checked) {
      this.state.data.forEach((i, index) => {
        if (i.enable_del) {
          selectkeys.push(i.id);
        }
      });
      this.setState({
        indeterminate: false,
        SelectcheckAll: true,
        selectedRowKeys: selectkeys,
      });
    } else {
      this.setState({
        indeterminate: false,
        SelectcheckAll: false,
        selectedRowKeys: selectkeys,
      });
    }
  }
  setVisibledelete(value) {
    this.setState({
      visibledelete: value,
      confirmLoading: false,
    });
  }
  deletemodel() {
    //console.log(this.state.selectedRowKeys, 'selectedRowKeysselectedRowKeys')
    this.setState({
      confirmLoading: true,
    });
    let datas = { ids: JSON.stringify(this.state.selectedRowKeys) };
    deleteModelVersion(datas).then((res) => {
      if (res.code === 200) {
        let pagination = this.state.pagination;
        pagination.current = 1;
        this.setState({
          selectedRowKeys: [],
          SelectcheckAll: false,
          confirmLoading: false,
          pagination: pagination,
          checkbox: false,
        });
        this.setVisibledelete(false);
        this.getmodelListVersion(true);
      } else {
        this.setState({
          confirmLoading: false,
        });
        Notification.error({ content: res.msg });
      }
    });
  }
  onSettings() {
    this.setState({
      checkbox: true,
    });
  }
  onClose() {
    this.setState({
      checkbox: false,
      SelectcheckAll: false,
      selectedRowKeys: [],
      confirmLoading: false,
    });
  }
  render() {
    const {
      loadingbody,
      capability,
      record,
      train_status,
      positionList,
      loading,
      data,
      pagination,
      no_data,
      columns,
      checkbox,
      SelectcheckAll,
      indeterminate,
      selectedRowKeys,
      type,
      checkAll,
      visibledelete,
      confirmLoading,
    } = this.state;
    return (
      <div className="model-mangement">
        <div className="Header-init">
          <div className="Header-title">模型管理</div>
        </div>
        {loadingbody ? (
          <Spin dot className="table-spin-loading" />
        ) : (
          <>
            <div className="custom-card">
              <div className="custom-table">
                <div className="custom-th AlibabaPuHuiTi-Medium">模型名称</div>
                <div className="custom-th AlibabaPuHuiTi-Medium">服务选项</div>
                <div className="custom-th AlibabaPuHuiTi-Medium">模型ID</div>
                <div
                  className="custom-th AlibabaPuHuiTi-Medium"
                  style={{ width: 490, textAlign: "left" }}
                >
                  模型描述
                </div>
              </div>
              <Divider style={{ margin: "3px 0" }} />
              <div className="custom-table">
                <div className="custom-td">
                  {record.model_name ? record.model_name : record.name}
                </div>
                <div className="custom-td">{capability.label}</div>
                <div className="custom-td">
                  <Typography.Paragraph copyable style={{ marginBottom: 0 }}>
                    {record.model_id ? record.model_id : record.id}
                  </Typography.Paragraph>
                </div>
                <div
                  className="custom-td"
                  style={{ width: 490, textAlign: "left" }}
                >
                  {record.desc}
                </div>
              </div>
            </div>
            <div className="GroupTable-fixe">
              {checkbox ? (
                <>
                  <div className="custom-checkAll">
                    <Checkbox
                      onChange={this.onChangeAll.bind(this)}
                      checked={SelectcheckAll}
                      indeterminate={indeterminate}
                    >
                      {SelectcheckAll ? "取消" : "全选"}
                    </Checkbox>
                  </div>
                  <Space size={"medium"}>
                    <Button
                      shape="circle"
                      className="custom-White-button"
                      disabled={selectedRowKeys.length === 0}
                      icon={<IconDelete />}
                      onClick={() => this.setVisibledelete(true)}
                    />
                    <Button
                      shape="circle"
                      className="custom-White-button"
                      icon={<IconClose />}
                      onClick={this.onClose.bind(this)}
                    />
                  </Space>
                </>
              ) : (
                <>
                  <Radio.Group
                    type="button"
                    name="direction"
                    value={train_status}
                    onChange={this.onsetPosition.bind(this)}
                    options={positionList}
                    className="GroupTable"
                  ></Radio.Group>
                  <div>
                    <Space size={"medium"}>
                      {/* <Button type='primary' className="primary-white">接口调试</Button>
                            <Button type='primary' className="primary-white">API 文档</Button> */}
                      <Button
                        type="primary"
                        className="Header-init-btn"
                        style={{ width: 118, height: 45, borderRadius: 10 }}
                        onClick={this.addRow.bind(this)}
                      >
                        创建版本
                      </Button>
                      <Button
                        shape="circle"
                        icon={<IconSettings />}
                        className="custom-White-button"
                        onClick={this.onSettings.bind(this)}
                      />
                    </Space>
                  </div>
                </>
              )}
            </div>
            {pagination.total === 0 ? (
              <Empty
                imgSrc={Emptydata_img}
                description={"暂无相关模型版本"}
                style={{ marginTop: 200 }}
              />
            ) : (
              <div className="Work-order-card">
                <Table
                  virtualized
                  no_data={no_data}
                  border={{ wrapper: false, cell: false }}
                  noDataElement={
                    <Empty
                      imgSrc={Emptydata_img}
                      description={"暂无相关模型版本"}
                    />
                  }
                  loading={false}
                  columns={columns.concat({
                    title: "操作",
                    dataIndex: "operation",
                    render: (col, record, index) => (
                      <Space className={"table-button"}>
                        <Button
                          type="outline"
                          size="small"
                          onClick={this.goverision.bind(this, record)}
                          style={{ width: 87, height: 32, borderRadius: 8 }}
                        >
                          查看详情
                        </Button>
                      </Space>
                    ),
                    fixed: "right",
                    width: 150,
                    align: "center",
                  })}
                  data={data}
                  pagination={pagination.total === 0 ? false : pagination}
                  onChange={this.onChangeTable.bind(this)}
                  renderPagination={(paginationNode) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: 10,
                      }}
                    >
                      {paginationNode}
                    </div>
                  )}
                  rowKey="id"
                  rowSelection={
                    checkbox && {
                      type,
                      selectedRowKeys,
                      checkAll: checkAll,
                      onChange: (selectedRowKeys, selectedRows) => {
                        //console.log('onChange:', selectedRowKeys, selectedRows);
                        this.setSelectedRowKeys(selectedRowKeys);
                      },
                      onSelect: (selected, record, selectedRows) => {
                        //console.log('onSelect:', selected, record, selectedRows)
                      },
                      checkboxProps: (record) => {
                        return {
                          disabled: !record.enable_del,
                        };
                      },
                    }
                  }
                />
              </div>
            )}
          </>
        )}
        <Modal
          style={{
            width: 472,
          }}
          className="deleteModal"
          title="设置提示"
          visible={visibledelete}
          okText="确认"
          cancelText="取消"
          confirmLoading={confirmLoading}
          onOk={this.deletemodel.bind(this)}
          onCancel={() => this.setVisibledelete(false)}
        >
          <p>是否确认删除选中内容？</p>
        </Modal>
      </div>
    );
  }
}

export default Management;
