import React, { Component } from "react";
import {
  Layout,
  Table,
  Link,
  Card,
  Upload,
  Modal,
  Form,
  Input,
  Select,
  Message,
  Typography,
  Space,
} from "@arco-design/web-react";
import ColorCard from "components/colorCard";
import {
  getIssueId,
  getIssueReplies,
  postIssueReplie,
  putIssue,
  fileUp,
} from "api/apis";
import axios from "axios";
import ALLURL from "envconfig/config";
import getRequestHeaders from "api/http";
import { b2ValueUnit } from "common/utils/index";
import "./details.scss";
import fileicon from "asserts/file_icon.png";
import user_img from "asserts/user_head.svg";

const FormItem = Form.Item;
const TextArea = Input.TextArea;
const Header = Layout.Header;
// 封装组件
class EchnicalSupportDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wokercard: {
        isback: true,
        titleH1: "工单列表",
        titleH2: "查看详情",
        leftBtn: false,
      },
      userinfo: JSON.parse(localStorage.getItem("userinfo")),
      colorcard: {},
      visible: false,
      confirmLoading: false,
      formItemLayout: {
        labelCol: {
          span: 4,
        },
        wrapperCol: {
          span: 20,
        },
      },
      defaultFileList: [],
    };
  }

  componentDidMount() {
    this.gitReplies();
  }
  Submitorder() {}
  gitReplies() {
    let recordID = localStorage.getItem("recordID");
    if (recordID) {
      getIssueId({ id: recordID }).then((res) => {
        let list = res.data;
        let Replylist = this.state.colorcard;
        Replylist = list;
        this.setState({
          colorcard: Replylist,
        });
        getIssueReplies({ id: recordID, per_page: 10 }).then((res) => {
          let list = res.data;
          //console.log(list, 'list')
          Replylist.Reply = list;
          this.setState({
            colorcard: Replylist,
          });
          //console.log(this.state.colorcard, 'Replylist')
        });
      });
    }
  }
  goEnd() {
    //console.log('结束')
    let recordID = localStorage.getItem("recordID");
    putIssue({ id: recordID, status: 3 }).then((res) => {
      if (res.code === 200) {
        Message.success(res.msg);
        this.gitReplies();
      } else {
        Message.error(res.msg);
      }
    });
  }
  setReplies() {
    this.setState({
      visible: true,
    });
    //console.log('回复')
  }

  onOk() {
    this.formRef
      .validate()
      .then((res) => {
        this.setState({
          confirmLoading: true,
        });
        let obj = res;
        obj.id = this.state.colorcard.show_id;
        //console.log(res, 'adddres')
        let lste = [];
        obj.attachment.map((key) => {
          if (key.response && key.status === "done") {
            lste.push(key.response);
          }
        });
        obj.attachment = JSON.stringify(lste);
        postIssueReplie(obj).then((res) => {
          if (res.code === 200) {
            Message.success(res.msg);
            this.setVisible(false);
            this.gitReplies();
            this.setState({
              confirmLoading: false,
            });
          } else {
            Message.error(res.msg);
            this.setState({
              confirmLoading: false,
            });
          }
        });
      })
      .catch((error) => {
        //console.log(error.name);
        //console.log(error.message);
        //console.log(error.errors);
      });
  }

  pictureUp(fileList, file) {
    let filetype =
      file.originFile.type === "image/jpg" ||
      file.originFile.type === "image/jpeg" ||
      file.originFile.type === "image/png";
    this.setState({
      defaultFileList: fileList,
    });
    if (!filetype) {
      file.url = fileicon;
    }
  }
  setVisible(value) {
    this.setState({
      visible: value,
    });
    this.formRef.resetFields();
  }
  changeUploadProgress(progress, checkpoint) {
    //console.log(progress, checkpoint, 'progress, checkpoint')
  }
  handleUpload(option) {
    //console.log(option)
    const { onProgress, onError, onSuccess, file } = option;
    let headers = getRequestHeaders();
    const formData = new FormData();
    formData.append("file", file);
    axios
      .post(ALLURL.baseURL + "/v2/file/up", formData, {
        headers,
        onUploadProgress: ({ total, loaded }) => {
          let percent;
          if (total > 0) {
            percent = (loaded / total) * 100;
          }
          onProgress(parseInt(percent, 10), file);
        },
      })
      .then(({ data: response }) => {
        if (response.code === 200) {
          onSuccess(response.data.fileKey, file);
        } else {
          onError(response);
          Message.error(response.msg);
        }
      })
      .catch(onError);
    return {
      abort() {},
    };
  }
  beforeUpload(file) {
    //console.log(b2ValueUnit(file.size))
    const isLt10M = !(
      b2ValueUnit(file.size)[1] === "B" ||
      b2ValueUnit(file.size)[1] === "KB" ||
      (b2ValueUnit(file.size)[1] === "MB" && b2ValueUnit(file.size)[0] <= 2)
    );
    return new Promise((resolve, reject) => {
      if (isLt10M) {
        Message.error("上传文件不能大于2MB");
        reject();
      } else {
        resolve();
      }
    });
  }
  render() {
    const {
      wokercard,
      colorcard,
      userinfo,
      visible,
      formItemLayout,
      confirmLoading,
      defaultFileList,
    } = this.state;
    return (
      <div className="echnicalsupport-details">
        <Header>
          <div className="Header-init">
            <div className="Header-title">
              <Space align={"center"}>
                <Typography.Text>工单详情</Typography.Text>
              </Space>
            </div>
          </div>
        </Header>
        <ColorCard
          colorcard={colorcard}
          useravatar={userinfo ? userinfo.avatar : user_img}
          setReplies={this.setReplies.bind(this)}
          goEnd={this.goEnd.bind(this)}
        />
        <Modal
          title="回复工单"
          okText="提交"
          visible={visible}
          onOk={this.onOk.bind(this)}
          confirmLoading={confirmLoading}
          onCancel={() => this.setVisible(false)}
          className="replyworder"
        >
          <Form
            {...formItemLayout}
            ref={(ref) => (this.formRef = ref)}
            labelCol={{ style: { flexBasis: 90 } }}
            wrapperCol={{ style: { flexBasis: "calc(100% - 90px)" } }}
            layout="vertical"
          >
            <FormItem
              className="Required"
              label="回复内容"
              required
              field="content"
              rules={[
                {
                  required: true,
                  message: "内容不能为空",
                },
                {
                  required: true,
                  type: "string",
                  minLength: 10,
                  message: "最小长度为 10 字符",
                },
              ]}
            >
              <TextArea placeholder="请数据问题具体详情" defaultValue="" />
            </FormItem>
            <FormItem
              extra="(文件大小不得超过2m)"
              label="相关附件"
              field="attachment"
              triggerPropName="fileList"
              initialValue={defaultFileList}
            >
              <Upload
                listType="picture-card"
                multiple
                limit={5}
                name="attachment"
                onChange={this.pictureUp.bind(this)}
                customRequest={(option) => {
                  this.handleUpload(option);
                }}
                beforeUpload={this.beforeUpload.bind(this)}
              />
            </FormItem>
          </Form>
        </Modal>
      </div>
    );
  }
}
export default EchnicalSupportDetails;
