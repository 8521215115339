import React, { Component } from "react";
import {
  Typography,
  Modal,
  Button,
  List,
  Space,
  Tabs,
  Divider,
  Spin,
} from "@arco-design/web-react";
import { IconRight } from "@arco-design/web-react/icon";

import icon1 from "asserts/Intelligentwriting_icon.svg";
import icon2 from "asserts/Intelligentgraphic_icon.svg";
import icon3 from "asserts/Smartdocument_icon.svg";
import icon4 from "asserts/Intelligentcreation_icon.svg";
import icon5 from "asserts/knowledge_graph.svg";
import "./index.scss";
const TabPane = Tabs.TabPane;
class OffModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Selectoptions: props.Selectoptions,
      visible: props.visible,
      activeTab: props.activeTab,
      listoff: props.listoff,
      officialList: props.officialList,
      TablLoading: props.TablLoading,
      TabscrollLoading: props.TabscrollLoading,
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      visible: nextProps.visible,
      TablLoading: nextProps.TablLoading,
      TabscrollLoading: nextProps.TabscrollLoading,
      activeTab: nextProps.activeTab,
      listoff: nextProps.listoff,
      officialList: nextProps.officialList,
      Selectoptions: nextProps.Selectoptions,
    });
  }

  onRadioGroup(value) {
    //console.log(value, this.state.GroupValue, 'onRadioGroup')
    this.setState({
      model_capability: value,
    });
  }
  render() {
    const {
      Selectoptions,
      visible,
      activeTab,
      listoff,
      officialList,
      TablLoading,
      TabscrollLoading,
    } = this.state;
    return (
      <Modal
        className="offmodel"
        title="体验官方应用"
        visible={visible}
        footer={null}
        onCancel={() => {
          this.props.setVisible(false);
        }}
      >
        <Tabs
          defaultActiveTab={activeTab}
          activeTab={activeTab}
          onClickTab={this.props.onClickTab.bind(this)}
        >
          {listoff.map((item, index) => {
            return (
              <TabPane
                key={index}
                title={
                  <span>
                    <img src={item.icon} /> {item.title}
                  </span>
                }
              >
                {TablLoading && officialList.length === 0 ? (
                  <Spin dot className="Spin-dot" />
                ) : (
                  <>
                    <List
                      grid={{
                        sm: 24,
                        md: 12,
                        lg: 8,
                        xl: 8,
                      }}
                      style={{ maxHeight: 620 }}
                      loading={TablLoading}
                      scrollLoading={TabscrollLoading}
                      onReachBottom={(currentPage) =>
                        this.props.fetchData(currentPage)
                      }
                      dataSource={officialList}
                      bordered={false}
                      render={(item, index) => (
                        <List.Item
                          key={index}
                          onClick={this.props.goOffdetails.bind(this, item)}
                        >
                          {
                            <div
                              className="ApplicationList-item grid-item columnSpaceBetween"
                              key={index}
                            >
                              <div className="grid-header">
                                <div
                                  className="grid-top"
                                  style={{
                                    backgroundColor: item.color,
                                    backgroundImage: item.image_url
                                      ? "url(" + item.image_url + ")"
                                      : "",
                                  }}
                                >
                                  <Space
                                    className="grid-top-title"
                                    direction="vertical"
                                  >
                                    <Typography.Title
                                      heading={5}
                                      ellipsis={true}
                                    >
                                      {item.name}
                                    </Typography.Title>
                                    <Typography.Paragraph ellipsis={true}>
                                      {item.desc}
                                    </Typography.Paragraph>
                                  </Space>
                                </div>
                              </div>
                              <div>
                                <Typography.Title heading={6} ellipsis={true}>
                                  <Divider
                                    type="vertical"
                                    className="divider-name"
                                    style={{ borderColor: item.color }}
                                  />
                                  {item.service_options}
                                </Typography.Title>
                                <Typography.Paragraph
                                  ellipsis={true}
                                  className="Typography-desc"
                                >
                                  {item.label}
                                </Typography.Paragraph>
                              </div>
                              <div className="grid-bottom">
                                <Divider />
                                <div className="rowSpaceBetween">
                                  <Space>
                                    <span
                                      className="dispose_icon"
                                      style={{
                                        backgroundImage:
                                          "url(" +
                                          (item.group_name === "智能写作"
                                            ? icon1
                                            : item.group_name === "智能图文"
                                            ? icon2
                                            : item.group_name === "智能文档"
                                            ? icon3
                                            : item.group_name === "智能创作"
                                            ? icon4
                                            : item.group_name === "知识图谱"
                                            ? icon5
                                            : "") +
                                          ")",
                                      }}
                                    ></span>
                                    <span>{item.group_name}</span>
                                  </Space>
                                  <Button
                                    type="outline"
                                    onClick={this.props.goOffdetails.bind(
                                      this,
                                      item
                                    )}
                                    className="open-now"
                                  >
                                    立即体验
                                    <IconRight />
                                  </Button>
                                </div>
                              </div>
                            </div>
                          }
                        </List.Item>
                      )}
                    />
                    {/* {TablLoading ? <Spin dot className="Spin-dot" /> : ''} */}
                  </>
                )}
              </TabPane>
            );
          })}
        </Tabs>
      </Modal>
    );
  }
}

export default OffModel;
