import React, { Component } from "react";
import axios from "axios";
import {
  Layout,
  Table,
  Upload,
  Modal,
  Form,
  Input,
  Select,
  Message,
  Button,
  Checkbox,
  Space,
  Radio,
  Tag,
  Empty,
  Typography,
  Spin,
} from "@arco-design/web-react";
import {
  IconPlus,
  IconSettings,
  IconDelete,
  IconClose,
} from "@arco-design/web-react/icon";
import { Link } from "react-router-dom";
import { getCurrentDate } from "common/utils";
import { getIssuesList, addIssues, deleteIssue, getconfig } from "api/apis";
import { b2ValueUnit } from "common/utils/index";
import ALLURL from "envconfig/config";
import getRequestHeaders from "api/http";
import Emptydata_img from "asserts/Emptydata_img.svg";
import "./workorder.scss";
import fileicon from "asserts/file_icon.png";
const FormItem = Form.Item;
const TextArea = Input.TextArea;
const Header = Layout.Header;

// 封装组件
class EchnicalWorkorder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          title: "工单编号",
          dataIndex: "show_id",
          align: "center",
          width: 200,
          headerCellStyle: {
            textAlign: "center",
          },
        },
        {
          title: "服务选项",
          dataIndex: "related_prod",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          width: 250,
          filters: this.filtersTable(),
          onFilter: (value, row) => row.related_prod.indexOf(value) > -1,
          filterMultiple: false,
        },
        {
          title: "问题描述",
          dataIndex: "content",
          ellipsis: true,
          width: 360,
          headerCellStyle: {
            textAlign: "center",
          },
        },
        {
          title: "状态",
          dataIndex: "status",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          render: (col, record, index) => (
            <span>
              <Tag
                color={
                  col === 1
                    ? "orangered"
                    : col === 2
                    ? "blue"
                    : col === 3
                    ? "green"
                    : "gray"
                }
              >
                {col === 1
                  ? "待处理"
                  : col === 2
                  ? "处理中"
                  : col === 3
                  ? "已完结"
                  : "深度对接"}
              </Tag>
            </span>
          ),
        },
        {
          title: "提交时间",
          dataIndex: "submit_time",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          width: "180px",
          render: (col, record, index) => (
            <span>{getCurrentDate("-", ":", col)}</span>
          ),
        },
      ],
      data: [],
      pagination: {
        sizeCanChange: false,
        showTotal: true,
        total: 0,
        pageSize: 10, //默认10条，最多20
        current: 1,
        pageSizeChangeResetCurrent: true,
      },
      loading: false,
      no_data: false,
      wokercard: {
        isback: false,
        titleH1: "工单列表",
        titleH2: "查看详情",
        leftBtn: true,
      },
      visible: false,
      confirmLoading: false,
      formItemLayout: {
        labelCol: {
          span: 4,
        },
        wrapperCol: {
          span: 20,
        },
      },
      relatedproductsOptions: [],
      productsOptions: [],
      showdle: true, //是否开放删除
      defaultFileList: [],
      group_name: "全部",
      positionList: ["全部", "待处理", "处理中", "已完结"],
      deployment: true,
      selectedRowKeys: [],
      type: "checkbox",
      checkbox: false,
      checkAll: false,
      visibledel: false,
      all_count: 0,
      loadingbody: true,
      group_lable: null,
      products: null,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.getPageList();
    this.gitconfig();
  }
  UNSAFE_componentDidUpdate() {}
  gitconfig() {
    getconfig({ key: "product-list" }).then((res) => {
      let list = res.data;
      this.setState({
        relatedproductsOptions: list.value,
      });
    });
  }
  filtersTable() {
    let Selectoptions = JSON.parse(localStorage.getItem("Selectoptions"));
    let productsOptions = [];
    Object.keys(Selectoptions ? Selectoptions : []).map((item, i) => {
      let Option = {
        text: Selectoptions[item].label,
        value: Selectoptions[item].label,
      };
      if (Selectoptions[item].status !== 2) {
        productsOptions.push(Option);
      }
    });
    return productsOptions;
  }
  onPressEnter(value) {
    let pagination = this.state.pagination;
    pagination.current = 1;
    this.setState({
      pagination: pagination,
      keyword: value,
      group_name: "全部",
    });
    this.setState({
      loading: true,
    });
    setTimeout(() => {
      this.getPageList();
    }, 500);
  }
  getPageList() {
    let page = {
      per_page: this.state.pagination.pageSize,
      page: this.state.pagination.current,
      search: this.state.keyword,
    };
    let status =
      this.state.group_name === "待处理"
        ? 1
        : this.state.group_name === "处理中"
        ? 2
        : this.state.group_name === "已完结"
        ? 3
        : "";
    if (status) {
      page.status = status;
    }
    getIssuesList(page).then((res) => {
      if (res.code === 200) {
        let list = res.data;
        let pagination = this.state.pagination;
        pagination.total = list.count;
        this.setState({
          all_count: list.all_count,
          pagination: pagination,
          data: list.data,
          loadingbody: false,
          loading: false,
        });
      } else {
        this.setState({
          loadingbody: false,
          loading: false,
        });
      }
    });
  }
  Submitorder() {}
  onChangeTable(pagination) {
    const { current, pageSize } = pagination;
    this.setState({
      loading: true,
    });
    setTimeout(() => {
      let setPagination = this.state.pagination;
      setPagination.current = current;
      setPagination.pageSize = pageSize;
      this.setState({
        loading: false,
        pagination: setPagination,
      });
      this.getPageList();
    }, 1000);
  }
  godetails(record) {
    localStorage.setItem("recordID", record.show_id);
  }
  addwork() {
    this.setState({
      visible: true,
    });
    setTimeout(() => {
      this.state.products = this.state.relatedproductsOptions[0];
      this.setState({
        products: this.state.relatedproductsOptions[0],
      });
      this.formRef.setFieldsValue({
        related_prod: this.state.relatedproductsOptions[0],
      });
      this.setProvince(this.state.relatedproductsOptions[0]);
    }, 100);
  }
  onOk() {
    this.formRef
      .validate()
      .then((res) => {
        this.setState({
          confirmLoading: true,
        });
        let obj = res;
        let lste = [];
        obj.attachment.map((key) => {
          if (key.response && key.status === "done") {
            lste.push(key.response);
          }
        });
        if (lste.length > 0) {
          obj.attachment = JSON.stringify(lste);
        } else {
          obj.attachment = "";
        }

        this.postAdd(obj);
      })
      .catch((error) => {
        //console.log(error.name);
        //console.log(error.message);
        //console.log(error.errors);
      });
  }
  postAdd(obj) {
    addIssues(obj).then((res) => {
      if (res.code === 200) {
        Message.success(res.msg);
        this.getPageList();
        this.setVisible(false);
        this.setState({
          confirmLoading: false,
        });
      } else {
        Message.error(res.msg);
        this.setState({
          confirmLoading: false,
        });
      }
    });
  }

  onDel() {
    //console.log(this.state.selectedRowKeys, 'selectedRowKeysselectedRowKeys')
    this.setState({
      confirmLoading: true,
    });
    let datas = { ids: JSON.stringify(this.state.selectedRowKeys) };
    deleteIssue(datas).then((res) => {
      if (res.code === 200) {
        Message.success(res.msg);
        this.getPageList();
        // this.setVisible(false);
        this.setVisibledel(false);
        let pagination = this.state.pagination;
        pagination.current = 1;
        this.setState({
          confirmLoading: false,
          selectedRowKeys: [],
          SelectcheckAll: false,
          pagination: pagination,
        });
      } else {
        Message.error(res.msg);
        this.setState({
          confirmLoading: false,
        });
      }
    });
    // deleteapp(datas).then(res => {
    //     if (res.code === 200) {
    //         this.setState({
    //             selectedRowKeys: [],
    //             SelectcheckAll: false,
    //             confirmLoading: false
    //         })
    //         this.setVisible(false)
    //         this.getlist()
    //     } else {
    //         this.setState({
    //             confirmLoading: false
    //         })
    //         Notification.error({ title: 'Error', content: res.msg })
    //     }
    // })
  }
  pictureUp(fileList, file) {
    //console.log(fileList, 'fileList')
    let filetype =
      file.originFile.type === "image/jpg" ||
      file.originFile.type === "image/jpeg" ||
      file.originFile.type === "image/png";
    this.setState({
      defaultFileList: fileList,
    });
    if (!filetype) {
      file.url = fileicon;
    }
  }

  handleUpload(option) {
    //console.log(option)
    const { onProgress, onError, onSuccess, file } = option;
    let headers = getRequestHeaders();
    const formData = new FormData();
    formData.append("file", file);
    axios
      .post(ALLURL.baseURL + "/v2/file/up", formData, {
        headers,
        onUploadProgress: ({ total, loaded }) => {
          let percent;
          if (total > 0) {
            percent = (loaded / total) * 100;
          }
          onProgress(parseInt(percent, 10), file);
        },
      })
      .then(({ data: response }) => {
        if (response.code === 200) {
          onSuccess(response.data.fileKey, file);
        } else {
          onError(response);
          Message.error(response.msg);
        }
      })
      .catch(onError);
    return {
      abort() {},
    };
  }
  beforeUpload(file) {
    //console.log(b2ValueUnit(file.size))
    const isLt10M = !(
      b2ValueUnit(file.size)[1] === "B" ||
      b2ValueUnit(file.size)[1] === "KB" ||
      (b2ValueUnit(file.size)[1] === "MB" && b2ValueUnit(file.size)[0] <= 2)
    );
    return new Promise((resolve, reject) => {
      if (isLt10M) {
        Message.error("上传文件不能大于5MB");
        reject();
      } else {
        resolve();
      }
    });
  }
  setVisible(value) {
    this.setState({
      visible: value,
      group_lable: null,
      productsOptions: [],
    });
    this.formRef.resetFields();
  }
  setVisibledel(value) {
    this.setState({
      group_lable: null,
      productsOptions: [],
      visibledel: value,
    });
  }
  changeUploadProgress(progress, checkpoint) {
    //console.log(progress, checkpoint, 'progress, checkpoint')
  }
  onsetPosition(value) {
    //console.log(value, 'value')
    let pagination = this.state.pagination;
    pagination.current = 1;
    this.setState({
      group_name: value,
      pagination: pagination,
    });
    this.state.group_name = value;
    this.getPageList();
  }
  setSelectedRowKeys(selectedRowKeys) {
    let selectkeys = [];
    this.state.data.forEach((i, index) => {
      if (i.enable_del) {
        selectkeys.push(i.show_id);
      }
    });
    //console.log('onChange:', selectedRowKeys, selectkeys);
    this.setState({
      selectedRowKeys: selectedRowKeys,
      SelectcheckAll:
        selectedRowKeys.length === selectkeys.length ? true : false,
    });
  }
  onChangeAll(checked) {
    let selectkeys = [];
    if (checked) {
      this.state.data.forEach((i, index) => {
        if (i.enable_del) {
          selectkeys.push(i.show_id);
        }
      });
      this.setState({
        indeterminate: false,
        SelectcheckAll: true,
        selectedRowKeys: selectkeys,
      });
    } else {
      this.setState({
        indeterminate: false,
        SelectcheckAll: false,
        selectedRowKeys: selectkeys,
      });
    }
    //console.log(selectkeys, 'selectedRowKeys')
  }
  onSettings() {
    this.setState({
      checkbox: true,
    });
  }
  onClose() {
    this.setState({
      checkbox: false,
      SelectcheckAll: false,
      selectedRowKeys: [],
    });
  }
  setProvince(value) {
    let Selectoptions = JSON.parse(localStorage.getItem("Selectoptions"));
    let productsOptions = [];
    Object.keys(Selectoptions ? Selectoptions : []).map((item, i) => {
      if (Selectoptions[item].group === value) {
        productsOptions.push(Selectoptions[item]);
      }
    });
    this.state.group_lable = productsOptions[0].id;
    this.setState({
      products: value,
      productsOptions: productsOptions,
      group_lable: productsOptions[0].id,
    });
    this.formRef.setFieldsValue({ model_capability: productsOptions[0].id });
    //console.log(productsOptions[0].id, '..', this.state.group_lable, this.formRef)
  }
  setProductsValue(value) {
    this.setState({
      group_lable: value,
    });
  }

  render() {
    const {
      group_lable,
      products,
      productsOptions,
      loadingbody,
      all_count,
      visibledel,
      group_name,
      positionList,
      indeterminate,
      type,
      checkAll,
      SelectcheckAll,
      selectedRowKeys,
      checkbox,
      loading,
      data,
      pagination,
      no_data,
      visible,
      formItemLayout,
      confirmLoading,
      relatedproductsOptions,
      columns,
      defaultFileList,
      keyword,
    } = this.state;
    return (
      <div className="workorder">
        <Header>
          <div className="Header-init">
            <div className="Header-title">
              <Space align={"center"}>
                <Typography.Text>技术支持</Typography.Text>
              </Space>
            </div>
          </div>
        </Header>
        {/* <Button type='primary' className='Header-init-btn' onClick={this.addwork.bind(this)}>新建工单</Button> */}
        {/* <WorkCard wokercard={wokercard} btnWork={this.addwork.bind(this)} /> */}
        {loadingbody ? (
          <Spin loading={loadingbody} dot className="loadingbody"></Spin>
        ) : (
          <>
            {all_count > 0 || keyword ? (
              <div className="GroupTable-fixe" id="result_title">
                {checkbox ? (
                  <>
                    <div className="custom-checkAll">
                      <Checkbox
                        onChange={this.onChangeAll.bind(this)}
                        checked={SelectcheckAll}
                        indeterminate={indeterminate}
                      >
                        {SelectcheckAll ? "取消" : "全选"}
                      </Checkbox>
                    </div>
                    <Space size={"medium"}>
                      <Button
                        shape="circle"
                        className="custom-White-button"
                        disabled={selectedRowKeys.length === 0}
                        icon={<IconDelete />}
                        onClick={() => this.setVisibledel(true)}
                      />
                      <Button
                        shape="circle"
                        className="custom-White-button"
                        icon={<IconClose />}
                        onClick={this.onClose.bind(this)}
                      />
                    </Space>
                  </>
                ) : (
                  <>
                    <Radio.Group
                      type="button"
                      name="direction"
                      value={group_name}
                      onChange={this.onsetPosition.bind(this)}
                      options={positionList}
                      className="GroupTable"
                    ></Radio.Group>
                    <div>
                      <Space size={"medium"}>
                        <Input.Search
                          style={{ width: 350 }}
                          placeholder="快速搜索工单"
                          className="GroupTable-Search"
                          onSearch={this.onPressEnter.bind(this)}
                        />
                        <Button
                          type="primary"
                          className="Header-init-btn"
                          onClick={this.addwork.bind(this)}
                        >
                          创建工单
                        </Button>
                        <Button
                          shape="circle"
                          className="custom-White-button"
                          icon={<IconSettings />}
                          disabled={pagination.total === 0}
                          onClick={this.onSettings.bind(this)}
                        />
                      </Space>
                    </div>
                  </>
                )}
              </div>
            ) : (
              ""
            )}
            {all_count > 0 ? (
              <>
                {pagination.total === 0 ? (
                  <Empty
                    imgSrc={Emptydata_img}
                    description={"暂无相关工单"}
                    style={{ marginTop: 200 }}
                  />
                ) : (
                  <Table
                    virtualized
                    rowKey="show_id"
                    noDataElement={
                      <Empty
                        imgSrc={Emptydata_img}
                        description={"暂无相关工单"}
                      />
                    }
                    no_data={no_data}
                    border={{ wrapper: false, cell: false }}
                    loading={loading}
                    columns={columns.concat({
                      title: "操作",
                      dataIndex: "operation",
                      render: (col, record, index) => (
                        <>
                          <Link
                            className="arco-link linkto"
                            to={"/echnicalsupport/details"}
                            onClick={this.godetails.bind(this, record)}
                          >
                            查看详情
                          </Link>
                          {/* {record.status === 3 && showdle ? <Button type='text' status='danger' size='mini' onClick={this.onDel.bind(this, record.show_id)}>
                                删除
                            </Button> : ''} */}
                        </>
                      ),
                      fixed: "right",
                      width: 150,
                      align: "center",
                    })}
                    data={data}
                    pagination={pagination.total === 0 ? false : pagination}
                    onChange={this.onChangeTable.bind(this)}
                    renderPagination={(paginationNode) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: 10,
                        }}
                      >
                        {paginationNode}
                      </div>
                    )}
                    rowSelection={
                      checkbox && {
                        type,
                        selectedRowKeys,
                        checkAll: checkAll,
                        onChange: (selectedRowKeys, selectedRows) => {
                          this.setSelectedRowKeys(selectedRowKeys);
                        },
                        onSelect: (selected, record, selectedRows) => {
                          //console.log('onSelect:', selected, record, selectedRows)
                        },
                        onSelectAll: (selected, record, selectedRows) => {
                          //console.log('onSelectAll:', selected, record, selectedRows)
                        },
                        checkboxProps: (record) => {
                          return {
                            disabled: !record.enable_del,
                          };
                        },
                      }
                    }
                  />
                )}
              </>
            ) : (
              <div className="Empty-absolute">
                <Empty
                  className="Empty-none"
                  imgSrc={Emptydata_img}
                  description={
                    <Space direction="vertical">
                      <Typography
                        style={{
                          color: "#4e5969",
                          marginTop: 30,
                          fontSize: 16,
                        }}
                      >
                        暂时没有工单数据{" "}
                      </Typography>
                      <Typography style={{ color: "#4e5969", fontSize: 16 }}>
                        点击按钮可以一键提交工单信息
                      </Typography>
                    </Space>
                  }
                />
                <div className="Emptydata-btns">
                  <Button
                    type="primary"
                    className="Emptydata-btn"
                    onClick={this.addwork.bind(this)}
                  >
                    <IconPlus />
                    提交工单
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
        <Modal
          className="addworder"
          title="新建工单"
          okText="提交"
          visible={visible}
          onOk={this.onOk.bind(this)}
          confirmLoading={confirmLoading}
          onCancel={() => this.setVisible(false)}
        >
          <Form
            {...formItemLayout}
            ref={(ref) => (this.formRef = ref)}
            labelCol={{ style: { flexBasis: 90 } }}
            wrapperCol={{ style: { flexBasis: "calc(100% - 90px)" } }}
            layout="vertical"
          >
            <FormItem className="Required" label="关联产品" required>
              {/* <Select options={relatedproductsOptions} placeholder='请选择服务类型' /> */}
              <Space size="large" className="listSelect">
                <FormItem
                  field="related_prod"
                  rules={[{ required: true, message: "请选择服务类型" }]}
                >
                  <Select
                    placeholder="请选择服务类型"
                    style={{ width: 230 }}
                    onChange={(value) => this.setProvince(value)}
                    value={products}
                  >
                    {relatedproductsOptions.map((option, index) => (
                      <Select.Option key={index} value={option}>
                        {option}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
                <FormItem
                  field="model_capability"
                  rules={[{ required: true, message: "请选择服务选项" }]}
                >
                  <Select
                    placeholder="请选择服务选项"
                    style={{ width: 230 }}
                    onChange={(value) => this.setProductsValue(value)}
                    value={group_lable}
                  >
                    {productsOptions.map((option, index) =>
                      option.status === 1 ? (
                        <Select.Option key={option.id} value={option.id}>
                          {option.label}
                        </Select.Option>
                      ) : (
                        ""
                      )
                    )}
                  </Select>
                </FormItem>
              </Space>
            </FormItem>
            <FormItem
              className="Required"
              label="问题描述"
              required
              field="content"
              rules={[
                {
                  required: true,
                  message: "内容不能为空",
                },
                {
                  required: true,
                  type: "string",
                  minLength: 10,
                  message: "最小长度为 10 字符",
                },
              ]}
            >
              <TextArea placeholder="请描述你的问题" defaultValue="" />
            </FormItem>
            <FormItem
              className="Required"
              label="手机号"
              field="mobile"
              // extra='*只作必要时联系使用'
              rules={[{ required: true, message: "内容不能为空" }]}
            >
              <Input placeholder="请填写联系方式（只作必要时联系使用）" />
            </FormItem>
            <FormItem
              label="相关附件"
              field="attachment"
              triggerPropName="fileList"
              initialValue={defaultFileList}
              extra="(文件大小不得超过2m)"
            >
              <Upload
                listType="picture-card"
                multiple
                limit={5}
                name="attachment"
                onChange={this.pictureUp.bind(this)}
                customRequest={(option) => {
                  this.handleUpload(option);
                }}
                beforeUpload={this.beforeUpload.bind(this)}
              />
            </FormItem>
          </Form>
        </Modal>
        <Modal
          style={{
            width: 472,
          }}
          className="deleteModal"
          title="设置提示"
          visible={visibledel}
          okText="确认"
          cancelText="取消"
          confirmLoading={confirmLoading}
          onOk={this.onDel.bind(this)}
          onCancel={() => this.setVisibledel(false)}
        >
          <p>是否确认删除选中内容？</p>
        </Modal>
      </div>
    );
  }
}
export default EchnicalWorkorder;
