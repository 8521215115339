import React, { Component } from "react";
import { Outlet } from "react-router-dom";
import "./index.scss";
// 封装组件
class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}
  UNSAFE_componentDidUpdate() {}
  render() {
    return (
      <div className="payment">
        <Outlet />
      </div>
    );
  }
}
export default Payment;
