import React, { Component } from "react";
import { Grid, Tag, Notification, Spin } from "@arco-design/web-react";
import Upgrade from "components/Upgrade";
import PaymentAmount from "components/PaymentAmount";
import ConfirmOrder from "components/ConfirmOrder";
import { sourceCancelClass } from "api/cancel-request";
import {
  getconfig,
  posttop_up,
  order_detail,
  saas_detail,
  getuserInfo,
} from "api/apis";
import $ from "jquery";
import history from "common/utils/history";
import JQ from "jquery";
import "./index.scss";
const Row = Grid.Row;
const Col = Grid.Col;

// 封装组件
class Privilege extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingbody: true,
      visibleUpgrade: false,
      privileges: {},
      packageInfo: null,
      vip_level: localStorage.getItem("userinfo")
        ? JSON.parse(localStorage.getItem("userinfo")).vip_level
        : 0,
      group_permission: localStorage.getItem("userinfo")
        ? JSON.parse(localStorage.getItem("userinfo")).group_permission
        : "",
      setIntervalTimer: "",
      power_info: {},
      power_info_hed: ["vip1", "vip2", "vip3"],
      type: 1,
      visible: false,
      timer: null,
      timerindex: null,
      timeindex: 3,
      listorder: [],
      pay_amount: 0,
      price: "",
      status: "",
      payment: {},
      object_id: "",
      retry_time: 10,
      payment_title: "",
      oldpay_url: "",
    };
  }

  componentDidMount() {
    clearInterval(this.state.timer);
    clearInterval(this.state.timerindex);
    this.gitconfig();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }
  componentWillUnmount() {
    clearInterval(this.state.setIntervalTimer);
    window.removeEventListener("resize", this.handleResize);
  }
  UNSAFE_componentDidUpdate() {}

  handleResize = () => {
    this.state.setIntervalTimer = setTimeout(() => {
      if (this.state.group_permission === "PERSONAL") {
        let height = JQ(".privilege-table").outerHeight(true) + 1;
        let Width = JQ(".personal-vip").outerWidth(true) + 2;
        let left = JQ(".personal-vip").position()?.left - 1;
        let top = JQ(".personal-vip").position()?.top - 1;
        //console.log(left, JQ('.privilege-title').outerWidth(true), JQ('.personal-vip').offset().left)
        JQ(".current-box").css({
          height: height + "px",
          width: Width + "px",
          left: left,
          top: top,
          opacity: 0.57,
        });
      } else if (
        this.state.group_permission &&
        this.state.group_permission !== "PERSONAL"
      ) {
        let height = JQ(".privilege-table").outerHeight(true) + 1;
        let Width = JQ(".group-vip").outerWidth(true) + 2;
        let left = JQ(".group-vip").position()?.left - 1;
        let top = JQ(".group-vip").position()?.top - 1;
        //console.log(left, JQ('.privilege-title').outerWidth(true), JQ('.group-vip').offset().left)
        JQ(".current-box").css({
          height: height + "px",
          width: Width + "px",
          left: left,
          top: top,
          opacity: 0.57,
        });
      }
      // clearInterval(this.state.setIntervalTimer)
    }, 500);
  };
  gitconfig() {
    getconfig({ key: "membership-privileges" }).then((res) => {
      if (res.code === 200) {
        let list = res.data;
        let obj = list.value.packageInfo;
        this.setState({
          privileges: obj,
          loadingbody: false,
        });
      } else {
        this.setState({
          loadingbody: false,
        });
      }
    });
    getconfig({ key: "wccg_user_power_info" }).then((res) => {
      if (res.code === 200) {
        let list = res.data;
        let obj = list.value;
        this.setState({
          power_info: obj,
        });
      }
    });
  }
  setvisibleUpgrade(value) {
    // this.setState({
    //   visibleUpgrade: value,
    // });
    history.push("/authentication?backename=privilege");
    history.go();
  }
  setbackground = (color) => {
    return color ? color : "transparent";
  };
  setvip = (res) => {
    let list = [];
    let isvip = "PERSONAL";
    let vip_grade = this.state.vip_level;
    if (!res[this.state.group_permission]) {
      isvip = "COMPANY";
    }
    list = res[isvip]["vip" + vip_grade];
    return list;
  };
  setPurchaseBtn = (res, index) => {
    let vip_kye = "vip" + index;
    let object_data = this.state.power_info[vip_kye].product[res.type];
    this.setState({
      object_id: object_data.object_id,
    });
    this.state.object_id = object_data.object_id;
    this.onposttop_up();
  };
  // 订单状态查询
  getorder_query() {
    if (this.state.retry_time <= 0) {
      var time = new Date().getTime();
      if (localStorage.getItem("pay_time")) {
        time = Number(localStorage.getItem("pay_time")) + 10;
        localStorage.setItem("pay_time", time);
      }
      let payment = this.state.payment;
      payment.pay_url = `${this.state.oldpay_url}&time=${time}`;
      this.setState({
        payment: payment,
        retry_time: 10,
      });
    } else {
      this.setState({ retry_time: this.state.retry_time - 1 });
    }
    let { payment } = this.state;
    order_detail({ order_sn: payment.order_sn }).then((res) => {
      if (res.code === 200) {
        // 'WAIT_PAID 待支付','PAID已支付','FINISH已完成','CANCEL已取消'
        if (res.data.status !== "WAIT_PAID" && res.data.status !== "CANCEL") {
          this.setState({
            status: "success",
          });
          this.settimeindex();
        } else {
          this.setState({
            status: "",
          });
        }
      } else {
        this.setState({
          status: "error",
        });
        this.settimeindex();
      }
    });
  }
  // 自动返回
  settimeindex() {
    this.state.timerindex = setInterval(() => {
      let index = this.state.timeindex - 1;
      this.setState({
        timeindex: index,
      });
      if (index === 0) {
        this.oncleartimer();
        this.onback();
      }
    }, 1000);
    this.setinfo();
  }
  oncleartimer() {
    sourceCancelClass();
    clearInterval(this.state.timer);
    clearInterval(this.state.timerindex);
  }
  onback() {
    history.go();
  }
  setinfo() {
    getuserInfo().then((res) => {
      if (res.code === 200) {
        let userinfodata = res.data;
        localStorage.setItem("userinfo", JSON.stringify(userinfodata));
      }
    });
  }
  setHight(id) {
    const childrenLen = $(`#${id}`).children().length;
    let height = "100%";
    if (childrenLen === 1) {
      let parent = $(`#${id}`).parent().parent();
      height = parent.get(0).offsetHeight + "px";
    }
    return height;
  }
  // 创建充值订单
  onposttop_up() {
    posttop_up({
      price: this.state.price,
      object_id: this.state.object_id,
    }).then((res) => {
      if (res.code === 200) {
        var oldpay_url = res.data.pay_url;
        var time = res.data.order_time;
        if (!time) {
          time = new Date().getTime();
        } else {
          localStorage.setItem("pay_time", time);
        }
        res.data.pay_url = `${res.data.pay_url}&time=${time}`;
        this.setState({
          payment: res.data,
          oldpay_url: oldpay_url,
          status: "",
          payment_title: res.data.title,
        });
        this.getsaas_detail();
      } else {
        Notification.error({ content: "创建订单失败!" });
      }
    });
  }
  getsaas_detail() {
    saas_detail({ order_sn: this.state.payment?.order_sn }).then((res) => {
      if (res.code === 200) {
        this.setState({
          listorder: res.data.data,
          pay_amount: res.data.price_info,
          type: 3,
        });
      } else {
        Notification.error({ content: res.msg });
      }
    });
  }
  onpayment() {
    this.setState({
      type: 2,
    });
    this.state.timer = setInterval(() => {
      this.getorder_query();
    }, 1000);
  }
  render() {
    const {
      loadingbody,
      visibleUpgrade,
      power_info_hed,
      privileges,
      packageInfo,
      group_permission,
      vip_level,
      type,
      timeindex,
      payment,
      status,
      pay_amount,
      listorder,
      payment_title,
      retry_time,
    } = this.state;
    return (
      <div className="privilege">
        <div className="Header-init">
          <div className="Header-title">会员特权</div>
        </div>
        {loadingbody ? (
          <Spin loading={loadingbody} dot className="loadingbody"></Spin>
        ) : (
          <>
            {type === 1 ? (
              <div className="privilege-card">
                <div className="Banner_privilege columnCenter">
                  <div className="Banner_privilege_title AlibabaPuHuiTi-Medium">
                    韦尼克智能创作平台会员服务说明
                  </div>
                  <div>
                    快速解锁更多特权，享受一站式高效内容创作，快速定制专属企业级AI应用
                  </div>
                </div>
                <div className="billing-list privilege-table">
                  <div className="billing-table">
                    <div
                      className="privilege-grid rowCenter"
                      style={{ "--height": "92px" }}
                    >
                      <div style={{ "--width": "27.2%" }}></div>
                      <div
                        className={`personal-vip AlibabaPuHuiTi-Medium rowCenter ${
                          group_permission === "PERSONAL" ? "bule-bg" : ""
                        }`}
                        style={{ "--width": "42.1%" }}
                      >
                        <span className="icon"></span>个人专业版
                        {/* {group_permission === "PERSONAL" ? (
                    <Tag
                      className="Tag-svp Upgrade-bule"
                      style={{ marginLeft: 18 }}
                      onClick={() => this.setvisibleUpgrade(true)}
                    >
                      续费升级
                    </Tag>
                  ) : (
                    ""
                  )} */}
                      </div>
                      <div
                        className={`group-vip AlibabaPuHuiTi-Medium rowCenter ${
                          group_permission === "PERSONAL" ? "" : "golden-bg"
                        }`}
                        style={{ "--width": "42%" }}
                      >
                        <span className="icon"></span>企业尊享版
                        {group_permission === "PERSONAL" ? (
                          <Tag
                            className="Tag-svp Upgrade-svp"
                            style={{ marginLeft: 18 }}
                            onClick={() => this.setvisibleUpgrade(true)}
                          >
                            立即开通
                          </Tag>
                        ) : null}
                      </div>
                    </div>
                    <div
                      className="privilege-grid vip-list rowCenter"
                      style={{ "--height": "52px" }}
                    >
                      <div
                        className="AlibabaPuHuiTi-Medium rowCenter"
                        style={{ "--width": "27.1%" }}
                      >
                        VIP等级
                      </div>
                      <div
                        className="personal-vip vip-list rowCenter"
                        style={{ "--width": "42%" }}
                      >
                        {power_info_hed.map((item, i) => {
                          return (
                            <div
                              className={`AlibabaPuHuiTi-Medium rowCenter ${
                                "vip" + vip_level === item &&
                                group_permission === "PERSONAL"
                                  ? "bule-bg vip-level"
                                  : ""
                              }`}
                              key={i}
                              style={{ width: 100 / 3 + "%" }}
                            >
                              <div className="rowCenter vip-btn">
                                <div className="AlibabaPuHuiTi-Medium">
                                  {item.toUpperCase()}
                                </div>
                              </div>
                              <div className="bule-tag ">当前</div>
                            </div>
                          );
                        })}
                      </div>
                      <div
                        className="group-vip vip-list rowCenter"
                        style={{ width: "42%" }}
                      >
                        {power_info_hed.map((item, i) => {
                          return (
                            <div
                              className={`AlibabaPuHuiTi-Medium rowCenter ${
                                "vip" + vip_level === item &&
                                group_permission !== "PERSONAL"
                                  ? "golden-bg vip-level"
                                  : ""
                              }`}
                              key={i}
                              style={{ width: 100 / 3 + "%" }}
                            >
                              <div className="AlibabaPuHuiTi-Medium rowCenter vip-btn">
                                <div className="AlibabaPuHuiTi-Medium">
                                  {item.toUpperCase()}
                                </div>
                              </div>
                              <div className="golden-tag">当前</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="table-bodys privilege-grid">
                      <div
                        className="table-hr"
                        style={{ "--width": "1px", "--left": "10%" }}
                      ></div>
                      {privileges &&
                        privileges.bodys &&
                        privileges.bodys.map((item, key) => {
                          let id = "th_id" + key;
                          return (
                            <div
                              className="table-th rowStart"
                              key={key}
                              id={id}
                            >
                              <div
                                className="table-td columnCenter"
                                style={{
                                  "--width": "10%",
                                }}
                              >
                                <div
                                  className="table-td-full columnCenter"
                                  style={{
                                    "--background":
                                      this.setbackground("#FAFAFA"),
                                  }}
                                >
                                  {item.type}
                                </div>
                              </div>
                              <div
                                className="table-td"
                                style={{
                                  "--width": "90%",
                                }}
                              >
                                {item.body &&
                                  item.body.map((item2, key2) => {
                                    return (
                                      <div
                                        className="table-th rowCenter"
                                        key={key2}
                                      >
                                        {[
                                          "16%",
                                          "30%",
                                          "44%",
                                          "58%",
                                          "72%",
                                          "86%",
                                        ].map((item, i) => {
                                          return (
                                            <div
                                              className="table-hr"
                                              style={{ "--left": item }}
                                              key={i}
                                            ></div>
                                          );
                                        })}
                                        {/* <div
                                          className="table-td"
                                          style={{
                                            "--width": "20%",
                                          }}
                                        >
                                          {item2.title}
                                        </div> */}
                                        <div
                                          className="table-td"
                                          style={{
                                            "--width": "16%",
                                          }}
                                        >
                                          <div className="table-th">
                                            {item2.model.map((item3, index) => {
                                              return (
                                                <div
                                                  className="table-th columnCenterStart"
                                                  style={{ paddingLeft: 30 }}
                                                  key={index}
                                                >
                                                  <div>{item3.name}</div>
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </div>
                                        <div
                                          className="table-td rowCenter"
                                          style={{
                                            "--width": "42%",
                                          }}
                                        >
                                          {item2.PERSONAL &&
                                            Object.keys(item2.PERSONAL).map(
                                              (item3, index) => {
                                                let PERSONALlist =
                                                  item2.PERSONAL[item3];
                                                let ids =
                                                  key +
                                                  "-" +
                                                  key2 +
                                                  "-" +
                                                  index;
                                                return (
                                                  <div
                                                    key={index}
                                                    className={`table-th ${
                                                      group_permission ===
                                                      "PERSONAL"
                                                        ? "bule-buy"
                                                        : ""
                                                    }`}
                                                    id={ids}
                                                    style={{
                                                      height:
                                                        this.setHight(ids),
                                                    }}
                                                  >
                                                    {PERSONALlist.map(
                                                      (item4, key3) => {
                                                        return (
                                                          <div
                                                            className={`table-th rowCenter ${
                                                              "vip" +
                                                                vip_level ===
                                                                item3 &&
                                                              group_permission ===
                                                                "PERSONAL"
                                                                ? "bule-bg"
                                                                : ""
                                                            }`}
                                                            key={key3}
                                                          >
                                                            <div className="buy-text">
                                                              {item4}
                                                            </div>
                                                            {item2.type ===
                                                              "buy" &&
                                                            item3 !== "vip1" ? (
                                                              <div
                                                                className="buy-btn Tag-svp Upgrade-bule"
                                                                onClick={() =>
                                                                  this.setPurchaseBtn(
                                                                    item2.model[
                                                                      key3
                                                                    ],
                                                                    index
                                                                  )
                                                                }
                                                              >
                                                                购买
                                                              </div>
                                                            ) : null}
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                );
                                              }
                                            )}
                                        </div>
                                        <div
                                          className="table-td rowCenter"
                                          style={{
                                            "--width": "42%",
                                          }}
                                        >
                                          {item2.COMPANY &&
                                            Object.keys(item2.COMPANY).map(
                                              (item3, index) => {
                                                let PERSONALlist =
                                                  item2.COMPANY[item3];
                                                let ids =
                                                  key +
                                                  "-" +
                                                  key2 +
                                                  "-" +
                                                  index;
                                                return (
                                                  <div
                                                    key={index}
                                                    className={`table-th ${
                                                      group_permission !==
                                                      "PERSONAL"
                                                        ? "golden-buy"
                                                        : ""
                                                    }`}
                                                    style={{
                                                      height:
                                                        this.setHight(ids),
                                                    }}
                                                  >
                                                    {PERSONALlist.map(
                                                      (item4, key3) => {
                                                        return (
                                                          <div
                                                            className={`table-th rowCenter ${
                                                              "vip" +
                                                                vip_level ===
                                                                item3 &&
                                                              group_permission !==
                                                                "PERSONAL"
                                                                ? "golden-bg"
                                                                : ""
                                                            }`}
                                                            key={key3}
                                                          >
                                                            <div className="buy-text">
                                                              {item4}
                                                            </div>
                                                            {item2.type ===
                                                              "buy" &&
                                                            item3 !== "vip1" ? (
                                                              <div
                                                                className="buy-btn Upgrade-svp"
                                                                onClick={() =>
                                                                  this.setPurchaseBtn(
                                                                    item2.model[
                                                                      key3
                                                                    ],
                                                                    index
                                                                  )
                                                                }
                                                              >
                                                                购买
                                                              </div>
                                                            ) : null}
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                );
                                              }
                                            )}
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            ) : type === 2 ? (
              <div className="addpadding promotion-payment">
                <PaymentAmount
                  timeindex={timeindex}
                  title={payment_title}
                  data={payment}
                  status={status}
                  time={retry_time}
                  onback={this.onback.bind(this)}
                />
              </div>
            ) : (
              <ConfirmOrder
                pay_amount={pay_amount}
                orderlist={listorder}
                order_sn={payment.order_sn}
                onpayment={this.onpayment.bind(this)}
              />
            )}
          </>
        )}
        <Upgrade
          visible={visibleUpgrade}
          onCancel={() => this.setvisibleUpgrade(false)}
        ></Upgrade>
      </div>
    );
  }
}
export default Privilege;
