// 自定义全局配置文件
const REACT_APP_PROXY_URL = process.env.REACT_APP_PROXY_URL;
const REACT_APP_ENV = process.env.REACT_APP_ENV;
let baseURL;
let loginURL;
let styleUrl;
if (REACT_APP_PROXY_URL === "prod") {
  //生产环境
  //console.log("生产环境", process.env);
  baseURL = "https://saas.api.yoo-ai.com";
  loginURL = "https://www.yoo-ai.com";
  styleUrl = "https://styles.yoojober.cn";
} else if (REACT_APP_PROXY_URL === "dev") {
  //测试环境
  //console.log("测试环境", process.env);
  baseURL = "https://test.saas.api.yoo-ai.com";
  loginURL = "https://test.yoo-ai.com";
  styleUrl = "https://styles.yoojober.cn";
} else {
  //console.log("其他环境", process.env);
  if (REACT_APP_ENV === "development") {
    baseURL = "https://test.saas.api.yoo-ai.com";
    loginURL = "https://test.yoo-ai.com";
  } else {
    baseURL = "https://saas.api.yoo-ai.com";
    loginURL = "https://www.yoo-ai.com";
  }
  styleUrl = "https://styles.yoojober.cn";
}

export default { baseURL, loginURL, styleUrl };
