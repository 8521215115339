import React, { Component } from "react";
import {
  IconNotification,
  IconExport,
  IconUser,
  IconFile,
  IconDown,
  IconLoading,
  IconDelete,
} from "@arco-design/web-react/icon";
import {
  Carousel,
  Space,
  Typography,
  Link,
  Button,
  Result,
  List,
  Empty,
} from "@arco-design/web-react";
import Emptydata_img from "asserts/Emptydata_img.svg";

import "./index.scss";

class TrainingStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.title,
      progress: props.progress,
      TextSet: props.TextSet,
      Code: props.Code,
      titleMessage: "训练失败",
      subTitle: props.subTitle,
      btnOktitle: props.btnOktitle,
      reciprocal: props.reciprocal,
      isToBeTrained: props.isToBeTrained,
      scrollLoading: props.scrollLoading,
      mockData: props.mockData,
      isRetraining: props.isRetraining,
      isshowlist: false,
      mockDataloding: props.mockDataloding,
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      mockDataloding: nextProps.mockDataloding,
      mockData: nextProps.mockData,
      scrollLoading: nextProps.scrollLoading,
      isToBeTrained: nextProps.isToBeTrained,
      btnOktitle: nextProps.btnOktitle,
      subTitle: nextProps.subTitle,
      title: nextProps.title,
      progress: nextProps.progress,
      TextSet: nextProps.TextSet,
      Code: nextProps.Code,
      reciprocal: nextProps.reciprocal,
      isRetraining: nextProps.isRetraining,
    });
  }
  UNSAFE_componentDidUpdate() {}
  componentDidMount() {}
  onColseistrained() {
    this.setState({
      isshowlist: !this.state.isshowlist,
    });
  }
  render() {
    const {
      isshowlist,
      btnOktitle,
      title,
      progress,
      TextSet,
      Code,
      titleMessage,
      subTitle,
      reciprocal,
      isToBeTrained,
      scrollLoading,
      mockDataloding,
      mockData,
      isRetraining,
    } = this.state;
    return (
      <div className="In-training">
        <span
          className={`colseadd arco-icon iconfont icon-a-bianzu7 `}
          onClick={this.props.onColseadd.bind(this)}
        ></span>
        {Code === "trained" ? (
          <div className="trained">
            <Result
              status="warning"
              title="已加入训练队列"
              subTitle="当前有正在训练的任务，稍后将为您自动进入训练状态"
              extra={null}
            ></Result>
            <Button
              type="primary"
              className="AlibabaPuHuiTi-Medium warningback"
              onClick={this.props.onOpenApp.bind(this)}
            >
              {btnOktitle}
            </Button>
          </div>
        ) : (
          <div className="In-training-content">
            <Space direction="vertical">
              {Code === "init" ? (
                <>
                  <div className="loding-img"></div>
                  <div className="training-text">
                    <Carousel
                      style={{
                        width: "100%",
                        height: 30,
                        margin: "auto",
                      }}
                      autoPlay={true}
                      showArrow="never"
                      direction="vertical"
                      indicatorType="never"
                      indicatorPosition="right"
                      animation="slide"
                      moveSpeed="500"
                    >
                      {TextSet.map((text, index) => (
                        <div key={index} className="Carousel-text">
                          {text}
                        </div>
                      ))}
                    </Carousel>
                  </div>
                </>
              ) : (
                <Result
                  status={Code}
                  title={
                    Code === "error"
                      ? titleMessage
                      : progress === 100
                      ? "训练成功"
                      : "配置完毕"
                  }
                  subTitle={
                    Code === "error"
                      ? subTitle
                      : progress === 100
                      ? "模型训练成功，立即开启智能创作"
                      : "模型配置完毕，" + reciprocal + "秒后开启智能创作"
                  }
                  extra={null}
                ></Result>
              )}
              <div className="training-loging">
                <Space className="training-loging-left">
                  <Space>
                    <div className="circular-loding"></div>
                    <Typography.Text ellipsis className="ellipsis-text">
                      {title}
                    </Typography.Text>
                  </Space>
                  {Code === "success" ? (
                    <Button
                      type="primary"
                      className="AlibabaPuHuiTi-Medium openAdd"
                      onClick={this.props.onOpenApp.bind(this)}
                    >
                      {btnOktitle}
                    </Button>
                  ) : Code === "error" ? (
                    isRetraining ? (
                      <Button
                        type="primary"
                        className="AlibabaPuHuiTi-Medium openAdd"
                        style={{ width: 147, height: 58, fontSize: 20 }}
                        onClick={this.props.onRetraining.bind(this)}
                      >
                        重新训练
                      </Button>
                    ) : (
                      ""
                    )
                  ) : (
                    <Typography.Text>{progress}％</Typography.Text>
                  )}
                </Space>
              </div>
            </Space>
          </div>
        )}
        {Code === "init" ? (
          <div className="In-training-foot">
            <Space direction="vertical">
              <div>
                模型训练中，可在模型中心查看
                <Link onClick={this.props.goLink.bind(this)}>训练详情</Link>
              </div>
              {/* <div>训练完成后可在操作界面中体验模型效果</div> */}
            </Space>
          </div>
        ) : null}
        {isToBeTrained ? (
          <div className="istrained-list">
            {isshowlist ? (
              <List
                noDataElement={
                  <Empty imgSrc={Emptydata_img} description="暂无任务" />
                }
                className="istrainedList"
                style={{ width: 433, maxHeight: 328 }}
                scrollLoading={scrollLoading}
                onReachBottom={(currentPage) =>
                  this.props.fetchData(currentPage)
                }
                dataSource={mockData}
                render={(item, index) => (
                  <List.Item
                    key={index}
                    className={`training-Item ${
                      item.id === mockDataloding ? "training-Iteming" : ""
                    }`}
                    onClick={() => this.props.openTraining(item)}
                  >
                    <div className="training-loging">
                      <Space align="center" className="training-loging-left">
                        <Space align="center">
                          <div className="circular-loding"></div>
                          <Typography.Text ellipsis className="ellipsis-text">
                            {item.model_name}
                          </Typography.Text>
                        </Space>
                        {item.train_status === "训练中" ? (
                          <Typography.Text>
                            {item.train_percent}％
                          </Typography.Text>
                        ) : (
                          <div className="istrained-Text">
                            {item.enable_del ? (
                              <>
                                <span>等待中…</span>
                                <IconDelete
                                  className="istrained-delete"
                                  onClick={(e) =>
                                    this.props.deleteModelVersion(e, item.id)
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <span style={{ color: "#AEAEAE" }}>
                                  等待中…
                                </span>
                                <IconDelete className="istrained-delete disabled" />
                              </>
                            )}
                          </div>
                        )}
                      </Space>
                    </div>
                  </List.Item>
                )}
              />
            ) : (
              ""
            )}
            <Button
              type="primary"
              className="istrained-btn"
              icon={<IconLoading />}
              onClick={this.onColseistrained.bind(this)}
            >
              训练队列
            </Button>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default TrainingStatus;
