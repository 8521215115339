import React, { Component } from "react";
import { Modal, Typography } from "@arco-design/web-react";
import history from "common/utils/history";
import "./index.scss";

class Upgrade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: props.visible,
      notification_status: props.notification_status,
      notification_message: props.notification_message,
      model_capability: props.model_capability,
      check_resourceobj: {
        // 资源包消耗完，且开通月结模式
        PACKAGE_FINISH: {
          title: "您的资源已消耗完毕，开始进入月结计费模式",
        },
        //资源包消过期，且开通月结模式,
        PACKAGE_EXPIRED: {
          title: "您的资源已过期，开始进入月结计费模式",
        },
        //资源包消耗完，且未开通月结模式
        PACKAGE_FINISH_NOT_SETTLEMENT: {
          cancelText: "自动月结",
          okText: "购买资源包",
        },
        //资源包过期，且未开通月结模式
        PACKAGE_EXPIRED_NOT_SETTLEMENT: {
          cancelText: "自动月结",
          okText: "购买资源包",
        },
        //账户余额不充足
        BALANCE_PACKAGE: {
          title: "",
          cancelText: "确定",
          okText: "账户充值",
        },
        //会员到期
        VIP_EXPIRED: {
          title: "",
          cancelText: "确定",
          okText: "续费会员",
        },
      },
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      visible: nextProps.visible,
      notification_status: nextProps.notification_status,
      notification_message: nextProps.notification_message,
      model_capability: nextProps.model_capability,
    });
  }
  /*
   * val--是否显示弹窗提示
   * false：关闭
   * true:显示
   * type--显示类型
   * PACKAGE_FINISH: 资源包消耗完，且开通月结模式
   * PACKAGE_EXPIRED: 资源包消过期，且开通月结模式
   * PACKAGE_FINISH_NOT_SETTLEMENT: 资源包消耗完，且未开通月结模式
   * PACKAGE_EXPIRED_NOT_SETTLEMENT: 资源包过期，且未开通月结模式
   * BALANCE_PACKAGE: 账户余额不充足
   * VIP_EXPIRED: 会员到期
   * button_type--按钮类型
   * 1：关闭（左）
   * 2：确认（右）
   */
  setNotificationVisible(val, type, button_type) {
    this.setState({
      visible: val,
    });
    if (type === "BALANCE_PACKAGE" && button_type === 2) {
      history.push("/payment/accountrecharge");
      history.go();
    } else if (type === "VIP_EXPIRED" && button_type === 2) {
      history.push("/privilege");
      history.go();
    } else if (
      (type === "PACKAGE_EXPIRED_NOT_SETTLEMENT" ||
        type === "PACKAGE_FINISH_NOT_SETTLEMENT") &&
      button_type === 2
    ) {
      let record = {
        model_capability: this.state.model_capability,
      };
      localStorage.setItem("record", JSON.stringify(record));
      history.push("/promotion");
      history.go();
    } else if (
      (type === "PACKAGE_EXPIRED_NOT_SETTLEMENT" ||
        type === "PACKAGE_FINISH_NOT_SETTLEMENT") &&
      button_type === 1
    ) {
      history.push("/payment");
      history.go();
    }
  }
  render() {
    const {
      visible,
      notification_status,
      notification_message,
      check_resourceobj,
    } = this.state;
    return (
      // <Modal
      //   style={{
      //     width: 472
      //   }}
      //   className="dissolution-tips"
      //   title='升级提示'
      //   visible={visible}
      //   footer={null}
      //   onCancel={() => this.props.onCancel()}
      // >
      //   <div style={{ textAlign: 'center' }}>
      //     <div className="serviceQRcode"></div>
      //     <div>模型服务套餐用量不足</div>
      //     <div>请扫码联系客服获取更多资源</div>
      //   </div>
      // </Modal>
      <Modal
        visible={visible}
        title={<div style={{ textAlign: "left", fontSize: 20 }}>提示</div>}
        cancelText={check_resourceobj[notification_status]?.cancelText}
        okText={check_resourceobj[notification_status]?.okText}
        className="Contactmodal global_prompt"
        maskStyle={{ background: "rgba(33,85,163,0.16)" }}
        onCancel={() => {
          this.setNotificationVisible(false, notification_status, 1);
        }}
        onOk={() => this.setNotificationVisible(false, notification_status, 2)}
      >
        <div style={{ textAlign: "center", fontSize: 18 }}>
          <Typography>{notification_message}</Typography>
          <div></div>
        </div>
      </Modal>
    );
  }
}

export default Upgrade;
