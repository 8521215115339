import React, { Component } from 'react'
import {
  Layout,
  Typography,
  Button,
  Space,
  Empty,
  Input,
  Modal,
  Image,
  Notification,
} from '@arco-design/web-react'
import { IconDownload } from '@arco-design/web-react/icon'
import Emptydata_img from 'asserts/Emptydata_img.svg'
import Generating_img from 'asserts/Generating_img.gif'
import errorIcon from 'asserts/error_icon.svg'
import { shareAppApi } from 'api/apis'
import { debounce } from 'common/utils'
import { sourceCancelClass } from 'api/cancel-request'
import $ from 'jquery'
import '../../common/style/myapp/resumeDocument.scss'
const Content = Layout.Content

// 封装组件
class ResumeDocument extends Component {
  constructor(props) {
    super(props)
    this.state = {
      share_id: localStorage.getItem('share_ids'),
      optionVlue: '',
      TextAreaValue: '',
      ParagraphContinuation: [],
      ContinuationLoding: false,
      record: JSON.parse(localStorage.getItem('shareRecord')),
      package: 'false',
      requestMsg: '',
      requestMsgText: '您还未输入简历关键信息',
      visible: false,
      visibleimg: null,
      loading: false,
      keyword: '',
      downloadLoading: false,
      height: 240,
      input_describe: '输入你想要的简历描述，例：产品经理 5年 蓝色',
      setChangeAbatchTime: '',
      setRequestTime: '',
    }
    this.isputapp = debounce(this.isputapp, 1000)
    this.isPhoneLegal = debounce(this.isPhoneLegal, 1000)
  }
  componentDidMount() {
    this.getApiInfo()
    window.addEventListener('resize', this.handleResize)
    if (document.getElementById('continuation')) {
      this.initSlider()
    }
  }
  componentWillUnmount() {
    localStorage.removeItem('props')
    clearTimeout(this.state.setRequestTime)
    clearTimeout(this.state.setChangeAbatchTime)
    sourceCancelClass()
  }

  handleResize = () => {
    if (document.getElementById('continuation')) {
      this.initSlider()
      $('#continuation').css({
        height: $('#shareContent').height() - 140 + 'px',
      })
    }
  }

  getApiInfo() {
    let list = this.state.record
    if (list.type === 0) {
      this.onTextArea(
        list.input_describe ? list.input_describe : '产品经理 原型设计 5年 蓝色'
      )
      this.ChangeAbatch('start')
    }
  }
  onPressEnter(e) {
    clearTimeout(this.state.setRequestTime)
    clearTimeout(this.state.setChangeAbatchTime)
    sourceCancelClass()
    this.setState({
      keyword: this.state.keyword,
      ParagraphContinuation: [],
      loading: false,
      ContinuationLoding: true,
      requestMsg: '',
    })
    this.state.ParagraphContinuation = []
    this.isPhoneLegal()
  }

  onTextArea(value) {
    this.setState({
      keyword: value,
    })
  }
  isPhoneLegal = () => {
    this.setRequest()
    //console.log(this.state.ParagraphContinuation, "isPhoneLegal请求");
  }
  setRequest() {
    clearTimeout(this.state.setRequestTime)
    clearTimeout(this.state.setChangeAbatchTime)
    if (this.state.keyword === '') {
      this.setState({
        ParagraphContinuation: [],
        ContinuationLoding: false,
        requestMsg: '',
        requestMsgText: '您还未输入简历关键信息',
      })
    } else {
      let params = {
        app_id: this.state.record.app_id,
        job: this.state.keyword,
        package: this.state.package,
        share_id: this.state.share_id,
      }

      const { ParagraphContinuation } = this.state

      this.setState({
        loading: true,
      })

      shareAppApi(params).then((res) => {
        if (res.code === 200) {
          let list = res.data
          this.setState({
            ParagraphContinuation: ParagraphContinuation.concat(list),
            ContinuationLoding: false,
            requestMsg: '',
          })
          if (this.state.ParagraphContinuation.length % 14 !== 0) {
            this.state.setRequestTime = setTimeout(() => {
              this.isPhoneLegal()
            }, 4000)
          } else {
            this.setState({
              loading: false,
            })
          }
          if (
            this.state.ParagraphContinuation.length === 1 &&
            list.length > 0
          ) {
            setTimeout(() => {
              this.handleResize()
            }, 100)
          }
        } else if (res.code === 400 || res.code === 503 || res.code === 3003) {
          this.setState({
            ContinuationLoding: false,
            requestMsg: '',
            loading: false,
          })
          if (ParagraphContinuation.length > 0) {
            Notification.warning({ content: res.msg })
          } else {
            this.setState({
              ParagraphContinuation: [],
              requestMsgText: res.msg,
            })
          }
        } else {
          this.setState({
            ContinuationLoding: false,
            requestMsg: '',
            loading: false,
          })
          if (ParagraphContinuation.length > 0) {
            Notification.warning({ content: res.msg })
          } else {
            this.setState({
              ParagraphContinuation: [],
              requestMsgText: res.msg,
              requestMsg: 'error',
            })
          }
        }
      })
    }
  }

  initSlider() {
    let screenWidth = $('.Continuation-item').width() //浏览器页面的宽度
    let height = screenWidth * 1.41 //14.2
    this.setState({
      height: height,
    })
    // $(".Continuation-img").height(height + 'px');//设置每一个div的宽度
  }

  ChangeAbatch(type) {
    sourceCancelClass()
    clearTimeout(this.state.setRequestTime)
    clearTimeout(this.state.setChangeAbatchTime)
    if (type === 'start') {
      this.setState({
        ParagraphContinuation: [],
        loading: false,
        ContinuationLoding: true,
        requestMsg: '',
      })
    } else {
      this.setState({
        loading: true,
        requestMsg: '',
      })
    }
    this.state.setChangeAbatchTime = setTimeout(() => {
      this.isPhoneLegal()
    }, 1000)
  }
  createTagsA = (tags, lightHighArr, wordArrAppend) => {
    //标签处理
    let html = ''
    tags.map((item, index) => {
      let className = ''

      for (let i in lightHighArr) {
        if (lightHighArr[i] === item) {
          className += 'hl-k '
          break
        }
      }

      for (let i in wordArrAppend) {
        if (wordArrAppend[i] === item) {
          className += 'hl-m '
          break
        }
      }

      if (className) {
        className = ' class = "' + className + '"'
      }

      html += '<span ' + className + '>' + item + '</span>'
    })
    let htmls = { __html: html }
    return <div class="Tags" dangerouslySetInnerHTML={htmls}></div>
  }
  setdownload(visible) {
    this.setState({
      visible: visible,
    })
  }
  opendownload(item) {
    this.setdownload(true)
    this.setState({
      visibleimg: item,
    })
  }
  getUrlBase64() {
    this.setState({
      downloadLoading: true,
    })
    const url = this.state.visibleimg.document // 获取图片地址
    // var a = document.createElement('a');          // 创建一个a节点插入的document
    // var event = new MouseEvent('click')           // 模拟鼠标click点击事件
    // a.download = '样机图'                  // 设置a节点的download属性值
    // a.target = "_blank"
    // a.href = url;                                 // 将图片的src赋值给a节点的href
    // a.dispatchEvent(event)
    fetch(url)
      .then(async (res) => await res.blob())
      .then((blob) => {
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = URL.createObjectURL(blob)
        a.download = '智能简历_' + url.substr(url.lastIndexOf('/') + 1)
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        this.setState({
          downloadLoading: false,
        })
      })
  }

  render() {
    const {
      input_describe,
      keyword,
      downloadLoading,
      loading,
      visibleimg,
      ParagraphContinuation,
      ContinuationLoding,
      requestMsg,
      requestMsgText,
    } = this.state
    return (
      <div className="resumeDocument">
        <div id="renderHeaders">
          <div className="Search-input">
            <Input
              placeholder={input_describe}
              className="Search"
              value={keyword}
              onChange={this.onTextArea.bind(this)}
              onPressEnter={this.onPressEnter.bind(this)}
            />
            <Button
              type="primary"
              className="Header-init-btn"
              loading={false}
              onClick={this.onPressEnter.bind(this)}
            >
              生成简历
            </Button>
          </div>
        </div>
        <Layout className="semanticprototype-Layout" id="layout_id">
          <Content>
            <div className="experience-result" id="result">
              <div id="result_title" className="rowSpaceBetween result_title">
                <Typography>生成结果</Typography>
                <Typography.Paragraph className="result-remarks">
                  (内容基于韦尼克AI创作引擎生成，仅供参考)
                </Typography.Paragraph>
              </div>
              {ParagraphContinuation.length > 0 || ContinuationLoding ? (
                <div className="Paragraph-generate">
                  {ContinuationLoding ? (
                    <Empty
                      className="Continuation-loding"
                      icon={<img src={Generating_img} alt="" />}
                      description={
                        <Typography
                          style={{
                            color: '#4e5969',
                            marginTop: 21,
                            fontSize: 16,
                          }}
                        >
                          正在生成中...
                        </Typography>
                      }
                    />
                  ) : (
                    <div className="Continuation-result" id="continuation">
                      <div className="Continuation-list">
                        {ParagraphContinuation.map((item, index) => {
                          return (
                            <div
                              className="Continuation-item"
                              key={index}
                              onClick={this.opendownload.bind(this, item)}
                            >
                              <div
                                className={`Continuation-img ${
                                  item.file_type === 'docx'
                                    ? `file-docx`
                                    : item.file_type === 'docx'
                                    ? 'file-pdf'
                                    : ``
                                }`}
                                style={{ height: this.state.height }}
                              >
                                {/* <Avatar shape='square'>
                                        <img
                                          alt='avatar'
                                          key={index}
                                          src={item.image[0]}
                                        />
                                      </Avatar> */}
                                <Image
                                  key={index}
                                  src={item.image[0]}
                                  alt={`lamp${index + 1}`}
                                  loaderClassName="loaderName"
                                />
                              </div>
                            </div>
                          )
                        })}
                      </div>
                      {loading ? (
                        <Empty
                          className="more-loding"
                          icon={<img src={Generating_img} alt="" />}
                          description={
                            <Typography
                              style={{
                                color: '#4e5969',
                                marginTop: 21,
                                fontSize: 16,
                              }}
                            >
                              正在生成中...
                            </Typography>
                          }
                        />
                      ) : (
                        <Button
                          id="iconSync"
                          className="IconSync-btn custom-White-border-button"
                          onClick={this.ChangeAbatch.bind(this)}
                        >
                          生成更多
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              ) : requestMsg === 'error' ? (
                <Empty
                  className="Continuation-Empty Empty-error"
                  imgSrc={errorIcon}
                  description={
                    <Space direction="vertical">
                      <Typography
                        style={{
                          color: '#4e5969',
                          marginTop: 30,
                          fontSize: 16,
                        }}
                      >
                        抱歉，服务器生成错误
                      </Typography>
                      <Typography style={{ color: '#4e5969', fontSize: 16 }}>
                        可尝试
                        <span
                          style={{ color: '#406EFF', cursor: 'pointer' }}
                          onClick={this.ChangeAbatch.bind(this, 'start')}
                        >
                          {' '}
                          重新生成
                        </span>
                      </Typography>
                    </Space>
                  }
                />
              ) : (
                <Empty
                  className="Continuation-Empty Empty-none"
                  imgSrc={Emptydata_img}
                  description={
                    <Space direction="vertical">
                      <Typography
                        style={{
                          color: '#4e5969',
                          marginTop: 30,
                          fontSize: 16,
                        }}
                      >
                        暂无生成结果
                      </Typography>
                      <Typography style={{ color: '#4e5969', fontSize: 16 }}>
                        {requestMsgText}
                      </Typography>
                    </Space>
                  }
                />
              )}
            </div>
          </Content>
        </Layout>
        <Modal
          style={{
            width: 479,
          }}
          onCancel={() => this.setdownload(false)}
          className="custom-modal-download-document"
          title={null}
          visible={this.state.visible}
          autoFocus={false}
          footer={
            <>
              <Button
                type="primary"
                className="download-btn"
                loading={downloadLoading}
                onClick={this.getUrlBase64.bind(this)}
              >
                {!downloadLoading && <IconDownload />}立即下载
              </Button>
            </>
          }
        >
          <div className="custom-modal-download-content">
            {visibleimg ? <img src={visibleimg.image[0]} alt="" /> : ''}
            {visibleimg
              ? this.createTagsA(
                  visibleimg.Tags.tags,
                  visibleimg.Tags.lightHighArr,
                  visibleimg.Tags.wordArrAppend
                )
              : ''}
          </div>
        </Modal>
      </div>
    )
  }
}
export default ResumeDocument
