import React, { Component } from 'react'
import {
  Layout,
  Typography,
  Button,
  Space,
  Empty,
  Input,
  Notification,
  Avatar,
  Spin,
  Modal,
  Select,
  Divider,
} from '@arco-design/web-react'
import Emptydata_img from 'asserts/Emptydata_img.svg'
import Generating_img from 'asserts/Generating_img.gif'
import errorIcon from 'asserts/error_icon.svg'
import {
  getApiInfo,
  getRequest,
  putapp,
  putmodelversion,
  check_resource,
} from 'api/apis'
import history from 'common/utils/history'
import { debounce } from 'common/utils'
import UpgradeTips from 'components/UpgradeTips'
import { CreateLink } from 'common/utils/business'
import './wordCloud.scss'
import { IconDownload, IconPlus } from '@arco-design/web-react/icon'
const Sider = Layout.Sider
const Content = Layout.Content
const TextArea = Input.TextArea
const Option = Select.Option

// 封装组件
class wordCloud extends Component {
  constructor(props) {
    super(props)

    this.state = {
      Selectoptions: JSON.parse(localStorage.getItem('Selectoptions')),
      no_data: false,
      optionVlue: '',
      TextAreaValue: '',
      versions: [],
      ParagraphContinuation: [],
      ContinuationLoding: false,
      record: JSON.parse(localStorage.getItem('record')),
      apiInfo: {
        model_id: '',
      },
      number: 6,
      requestMsg: '',
      requestMsgText: '您还未输入关键信息',
      attachment: [],
      fileList: [],
      visible: false,
      visibleimg: null,
      marginRight: 12,
      loading: false,
      input_describe: '请输入关键词或文段，生成云图',
      visibleUpgrade: false,
      upgradetips: null,
      identity: JSON.parse(localStorage.getItem('userinfo'))
        ? JSON.parse(localStorage.getItem('userinfo')).group_permission
        : '',
    }
    this.isputapp = debounce(this.isputapp, 1000)
  }
  componentDidMount() {
    this.getApiInfo()
    window.addEventListener('resize', this.handleResize)
    setTimeout(() => {
      this.handleResize()
    }, 300)
  }
  componentWillUnmount() {
    localStorage.removeItem('props')
    window.removeEventListener('resize', this.handleResize)
  }
  handleResize = () => {
    var winHeight = 0
    if (window.innerHeight) {
      winHeight = window.innerHeight
    } else if (document.body && document.body.clientHeight) {
      winHeight = document.body.clientHeight
    }
    if (document.documentElement && document.documentElement.clientHeight) {
      winHeight = document.documentElement.clientHeight
    }
    let layoutHeaders_h = document.getElementById('layoutHeaders').clientHeight
    if (layoutHeaders_h > winHeight) {
      layoutHeaders_h = 64
    }
    let height =
      winHeight -
      layoutHeaders_h -
      document.getElementById('renderHeaders').clientHeight -
      60
    document.getElementById('layout_id').style.height = height + 'px'
    let height2 =
      height - document.getElementById('result_title').clientHeight - 27
    document.getElementById('continuation').style.height = height2 - 30 + 'px'
  }

  getApiInfo() {
    let params = {
      app_id: this.state.record.id,
    }
    getApiInfo(params).then((res) => {
      if (res.code === 200) {
        let list = res.data
        if (res.data.model_params) {
          history.push('/myapp/enteringGinseng')
          history.go()
        } else {
          this.setState({
            apiInfo: list,
            versions: list.versions,
          })
          // document.title = list.app_name
          if (list.versions.length > 0) {
            this.setState({
              optionVlue: list.versions[0].id,
            })
            this.putmodelversion(list.versions[0].id)
          }
          if (list.type === 0) {
            this.onTextArea(
              list.input_describe
                ? list.input_describe
                : '工作职责：' +
                    ' 1.其他领导交代的工作' +
                    '2.固定资产和低值易耗品的登记和管理' +
                    '3.协助财会文件的准备、归档和保管' +
                    '4.现金及银行收付处理，制作记帐凭证，银行对帐，单据审核，开具与保管发票' +
                    '5.负责公司仓库进销存账务。' +
                    '任职资格：' +
                    '1.管理仓库进销存，记录应收应付。' +
                    '2.具有独立工作和学习的能力，工作认真细心。' +
                    '3.较好的会计基础知识和一定的英语能力，有财会工作经验者优先' +
                    '4.熟悉现金管理及银行结算，财务软件操作' +
                    '5.良好的职业操守及团队合作精神，较强的沟通、理解和分析能力'
            )
            this.isPhoneLegal()
          }
        }
        //console.log(res.data, "api服务");
      } else {
      }
    })
  }
  putmodelversion(optionVlue) {
    this.setState({
      ParagraphContinuation: [],
      loading: true,
      ContinuationLoding: true,
      requestMsg: '',
    })
    let params = {
      id: optionVlue,
    }
    putmodelversion(params).then((res) => {
      if (res.code === 200) {
        if (this.state.TextAreaValue) {
          this.isPhoneLegal()
        }
      } else {
        // Notification.warning({ content: '模型版本激活失败！' })
        this.setState({
          ParagraphContinuation: [],
          ContinuationLoding: false,
          requestMsg: '',
          requestMsgText: '模型版本激活失败',
          loading: false,
        })
      }
    })
  }
  onsetPosition(value) {
    //console.log(value, "value");
    this.setState({
      optionVlue: value,
    })
    this.putmodelversion(value)
  }
  onTextArea(value) {
    this.setState({
      TextAreaValue: value,
    })
  }
  onKeyDown(event) {
    //console.log(event.keyCode, "键盘事件");
    if (event.shiftKey && event.keyCode === 13) {
      var e = this.state.TextAreaValue
      this.setState({
        TextAreaValue: e,
      })
    } else if (event.ctrlKey && event.keyCode === 13) {
      event.cancelBubble = true
      event.preventDefault()
      event.stopPropagation()
      event.returnValue = false
      this.setState({
        ParagraphContinuation: [],
        ContinuationLoding: true,
        requestMsg: '',
      })
      this.isPhoneLegal()
      return false
    } else if (event.keyCode === 13) {
      this.setState({
        ParagraphContinuation: [],
        ContinuationLoding: true,
        requestMsg: '',
      })
      event.cancelBubble = true
      event.preventDefault()
      event.stopPropagation()
      this.isPhoneLegal()
      event.returnValue = false
      return false
    }
  }
  isPhoneLegal = () => {
    this.setState({
      ParagraphContinuation: [],
      loading: true,
      ContinuationLoding: true,
      requestMsg: '',
    })
    setTimeout(() => {
      this.setRequest()
    }, 1000)
  }
  setRequest() {
    const [oneWidth, marginRight] = [261, 12]
    const containerW = document.getElementById('continuation').clientWidth
    const colsNum = Math.floor(containerW / (oneWidth + marginRight))
    const marginRight2 = Math.floor(
      (containerW - colsNum * (oneWidth + marginRight)) / colsNum
    )

    this.setState({
      marginRight: marginRight + marginRight2,
    })

    if (this.state.TextAreaValue === '') {
      this.setState({
        ParagraphContinuation: [],
        ContinuationLoding: false,
        loading: false,
        requestMsg: '',
        requestMsgText: '您还未输入关键信息',
      })
    } else {
      let params = {
        app_id: this.state.record.id,
        text: this.state.TextAreaValue,
      }
      if (this.state.optionVlue) {
        params.version_id = this.state.optionVlue
      }
      const { ParagraphContinuation } = this.state

      this.setState({
        loading: true,
      })
      let that = this
      this.getCheckResource(function (result) {
        if (result.code === 200) {
          getRequest(params).then((res) => {
            if (res.code === 200) {
              that.setState({
                ParagraphContinuation: ParagraphContinuation.concat(res.data),
                ContinuationLoding: false,
                requestMsg: '',
              })

              if (that.state.ParagraphContinuation.length % 12 !== 0) {
                that.setRequest()
              } else {
                that.setState({
                  loading: false,
                })
              }
              if (
                that.state.ParagraphContinuation.length === 1 &&
                res.data.length > 0
              ) {
                setTimeout(() => {
                  that.handleResize()
                }, 100)
              }
            } else if (res.code === 400 || res.code === 3003) {
              that.setState({
                ContinuationLoding: false,
                requestMsg: '',
                loading: false,
              })
              if (ParagraphContinuation.length > 0) {
                Notification.warning({ content: res.msg })
              } else {
                that.setState({
                  ParagraphContinuation: [],
                  requestMsgText: res.msg,
                })
              }
            } else {
              that.setState({
                ContinuationLoding: false,
                requestMsg: '',
                loading: false,
              })
              if (ParagraphContinuation.length > 0) {
                Notification.warning({ content: res.msg })
              } else {
                that.setState({
                  ParagraphContinuation: [],
                  requestMsgText: res.msg,
                  requestMsg: 'error',
                })
              }
            }
          })
        } else {
          that.setState({
            ContinuationLoding: false,
            requestMsg: '',
            loading: false,
          })
          if (ParagraphContinuation.length > 0) {
            Notification.warning({ content: result.msg })
          } else {
            that.setState({
              ParagraphContinuation: [],
              requestMsgText: result.msg,
              requestMsg: 'error',
            })
          }
        }
      })
    }
  }

  getCheckResource(callback) {
    let params = { model_capability: this.state.apiInfo?.model_capability }
    let PACKAGES = ['PACKAGE_FINISH', 'PACKAGE_EXPIRED']
    check_resource(params).then((res) => {
      if (res.code === 200) {
        if (res.data) {
          let new_upgradetips = res.data
          new_upgradetips.model_capability = params.model_capability
          this.setState({
            upgradetips: new_upgradetips,
          })
          if (PACKAGES[new_upgradetips.notice_type]) {
            Notification.warning({ content: new_upgradetips.message })
          } else {
            setTimeout(() => {
              this.setState({
                visibleUpgrade: true,
              })
            }, 100)
          }
        }
        callback(res)
      } else {
        callback(res)
      }
    })
  }
  goResources() {
    history.push('/myapp/resources')
    localStorage.setItem('record', JSON.stringify(this.state.record))
    window.location.reload()
  }
  goInterfacetest() {
    history.push('/myapp/interfacetest')
    localStorage.setItem('record', JSON.stringify(this.state.record))
    window.location.reload()
  }
  ChangeAbatch(type) {
    if (type === 'start') {
      this.setState({
        ParagraphContinuation: [],
        loading: false,
        ContinuationLoding: true,
        requestMsg: '',
      })
    } else {
      this.setState({
        loading: true,
        requestMsg: '',
      })
    }
    setTimeout(() => {
      this.setRequest()
    }, 1000)
  }
  setdownload(visible) {
    this.setState({
      visible: visible,
    })
  }
  opendownload(item) {
    this.setdownload(true)
    this.setState({
      visibleimg: item,
    })
  }
  getUrlBase64() {
    let url = this.state.visibleimg
    fetch(url)
      .then(async (res) => await res.blob())
      .then((blob) => {
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = URL.createObjectURL(blob)
        a.download = '词云图-' + Date.now() + '.png'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      })
  }
  isputapp = (name, desc) => {
    this.putapp(name, desc)
  }
  putapp(name, desc) {
    let params = {
      id: this.state.record.id,
    }
    if (desc !== false) {
      params.desc = desc
    } else if (name !== false) {
      params.name = name
    }
    putapp(params).then((res) => {
      if (res.code === 200) {
      } else {
        this.getApiInfo(true)
        Notification.error({ content: res.msg })
      }
    })
  }
  onChangetitle(type, value) {
    //console.log(value, "value");
    var apiInfo = this.state.apiInfo
    if (type === 'desc') {
      if (value.length > 100) {
        apiInfo.app_desc = value.substring(0, 1000)
        Notification.warning({ content: '修改内容超过100个字！' })
        return
      }
      apiInfo.app_desc = value
      this.setState({
        apiInfo: apiInfo,
      })
      this.isputapp(false, value)
    } else if (type === 'name') {
      if (value.length > 20) {
        apiInfo.app_name = value.substring(0, 20)
        Notification.warning({ content: '修改标题超过20个字！' })
        return
      }
      apiInfo.app_name = value
      this.setState({
        apiInfo: apiInfo,
      })
      this.isputapp(value, false)
    }
  }
  goaddApp() {
    history.replace('/myapp/addmyapp')
    let state = {
      model_capability: this.state.apiInfo.model_capability,
      checkedtype: this.state.apiInfo.model_id,
      model_id: this.state.apiInfo.model_id,
      name: this.state.apiInfo.model_name,
    }
    localStorage.setItem('props', JSON.stringify(state))
    localStorage.removeItem('record')
    window.location.reload()
  }
  addRow() {
    localStorage.removeItem('props')
    localStorage.setItem('record', JSON.stringify(this.state.apiInfo))
    history.replace('/enginefactory/model/management/add')
    window.location.reload()
  }
  setvisibleUpgrade(value) {
    this.setState({
      visibleUpgrade: value,
    })
  }
  render() {
    const {
      visibleUpgrade,
      upgradetips,
      apiInfo,
      versions,
      optionVlue,
      input_describe,
      TextAreaValue,
      loading,
      visibleimg,
      marginRight,
      ParagraphContinuation,
      ContinuationLoding,
      requestMsg,
      requestMsgText,
    } = this.state
    const count = ParagraphContinuation.length

    return (
      <div className="wordCloud">
        <div id="renderHeaders">
          <div className="GroupTable-fixe GroupTable-fixe-end">
            <div className="edit-title">
              <div className="Header-init">
                <div className="Header-title">
                  <Typography.Paragraph
                    className="Header-uptitle"
                    editable={
                      apiInfo.type === 0
                        ? null
                        : {
                            onChange: this.onChangetitle.bind(this, 'name'),
                          }
                    }
                  >
                    {apiInfo.app_name}
                  </Typography.Paragraph>
                </div>
              </div>
              <div className="Header-upParagraph">
                <Typography.Paragraph
                  className="Header-uptitle"
                  editable={
                    apiInfo.type === 0
                      ? null
                      : {
                          onChange: this.onChangetitle.bind(this, 'desc'),
                        }
                  }
                >
                  {apiInfo.app_desc}
                </Typography.Paragraph>
              </div>
            </div>
            <Space size={'medium'} align="center">
              {apiInfo.type === 0 ? (
                <Button
                  className="primarybtn custom-White-button"
                  onClick={this.goaddApp.bind(this)}
                >
                  保存应用
                </Button>
              ) : (
                <>
                  {versions.length === 0 ? (
                    <div></div>
                  ) : (
                    <Select
                      value={optionVlue}
                      placeholder="版本选择"
                      style={{ width: 220 }}
                      className="versionsSelect"
                      onChange={this.onsetPosition.bind(this)}
                      dropdownRender={(menu) => (
                        <div>
                          {menu}
                          <Divider
                            style={{
                              margin: 0,
                              marginTop: 6,
                              borderColor: '#f2f2f2',
                            }}
                          />
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              padding: '5px 0px',
                            }}
                          >
                            <Button
                              style={{
                                width: '100%',
                                height: 41,
                                fontSize: 14,
                                padding: '0 6px',
                                borderRadius: 10,
                              }}
                              type="text"
                              onClick={this.addRow.bind(this)}
                            >
                              <IconPlus />
                              新增模型版本
                            </Button>
                          </div>
                        </div>
                      )}
                    >
                      {versions.map((option, index) => (
                        <Option key={index} value={option.id}>
                          {apiInfo.model_name}V{option.version}
                        </Option>
                      ))}
                    </Select>
                  )}
                  {apiInfo.type === 9 ? (
                    <div className="primary_model_name">
                      <div className="primary_model_name_fix">
                        <span className="primary_model_name_title">
                          模型
                          <Divider type="vertical" />
                        </span>
                        <Typography.Paragraph ellipsis>
                          {apiInfo.model_name}
                        </Typography.Paragraph>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {/* <Button
                    shape="circle"
                    className="custom-White-button"
                    onClick={this.goResources.bind(this)}
                  >
                    应用资源
                  </Button> */}
                  <Button
                    shape="circle"
                    className="custom-White-button share_button_wnk"
                    onClick={CreateLink.bind(this, apiInfo)}
                  >
                    分享应用
                  </Button>
                  <Button
                    style={{
                      display:
                        this.state.identity &&
                        this.state.identity !== 'PERSONAL'
                          ? 'inline-block'
                          : 'none',
                    }}
                    shape="circle"
                    className="custom-White-button"
                    onClick={this.goInterfacetest.bind(this)}
                  >
                    接口调用
                  </Button>
                </>
              )}
            </Space>
          </div>
        </div>
        <Layout className="semanticprototype-Layout" id="layout_id">
          <Sider className="Sider-left">
            <div className="Sider-left-configure columnSpaceBetween">
              <div className="Continuation-input">
                <TextArea
                  placeholder={input_describe}
                  style={{ width: '100%' }}
                  value={TextAreaValue}
                  onChange={this.onTextArea.bind(this)}
                  onKeyDown={(event) => this.onKeyDown(event)}
                />
              </div>
              <div className="Upload-pictures-bottom">
                <Button
                  type="primary"
                  className="Start-generation"
                  disabled={loading}
                  onClick={this.isPhoneLegal.bind(this)}
                >
                  生成词云
                </Button>
              </div>
            </div>
          </Sider>
          <Content>
            <div className="experience-result" id="result">
              <div id="result_title" className="rowSpaceBetween result_title">
                <Typography>生成结果</Typography>
                <Typography.Paragraph className="result-remarks">
                  (内容基于韦尼克AI创作引擎生成，仅供参考)
                </Typography.Paragraph>
              </div>
              <div className="continuation-body" id="continuation">
                {count > 0 || ContinuationLoding ? (
                  <div className="Paragraph-generate">
                    {ContinuationLoding ? (
                      <Empty
                        className="Continuation-loding"
                        icon={<img src={Generating_img} />}
                        description={
                          <Typography
                            style={{
                              color: '#4e5969',
                              marginTop: 21,
                              fontSize: 16,
                            }}
                          >
                            正在生成中...
                          </Typography>
                        }
                      />
                    ) : (
                      <div className="Continuation-result" id="continuation">
                        <div className="Continuation-list">
                          {ParagraphContinuation.map((item, index) => {
                            return (
                              <div
                                className="Continuation-item"
                                style={{ marginRight: marginRight }}
                                key={index}
                                onClick={this.opendownload.bind(this, item)}
                              >
                                <div className="Continuation-img">
                                  <Avatar shape="square">
                                    <img alt="avatar" key={index} src={item} />
                                  </Avatar>
                                  {/* <Image key={index} src={item} /> */}
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        {loading ? (
                          <Spin dot />
                        ) : (
                          <Button
                            type="primary"
                            icon={<IconPlus />}
                            id="iconSync"
                            className="IconSync-btn"
                            onClick={this.ChangeAbatch.bind(this)}
                          >
                            生成更多
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                ) : requestMsg === 'error' ? (
                  <Empty
                    className="Continuation-Empty Empty-error"
                    imgSrc={errorIcon}
                    description={
                      <Space direction="vertical">
                        <Typography
                          style={{
                            color: '#4e5969',
                            marginTop: 30,
                            fontSize: 16,
                          }}
                        >
                          抱歉，服务器生成错误
                        </Typography>
                        <Typography style={{ color: '#4e5969', fontSize: 16 }}>
                          可尝试
                          <span
                            style={{ color: '#406EFF', cursor: 'pointer' }}
                            onClick={this.ChangeAbatch.bind(this)}
                          >
                            {' '}
                            重新生成
                          </span>
                        </Typography>
                      </Space>
                    }
                  />
                ) : (
                  <Empty
                    className="Continuation-Empty Empty-none"
                    imgSrc={Emptydata_img}
                    description={
                      <Space direction="vertical">
                        <Typography
                          style={{
                            color: '#4e5969',
                            marginTop: 30,
                            fontSize: 16,
                          }}
                        >
                          暂无生成结果
                        </Typography>
                        <Typography style={{ color: '#4e5969', fontSize: 16 }}>
                          {requestMsgText}
                        </Typography>
                      </Space>
                    }
                  />
                )}
              </div>
            </div>
          </Content>
        </Layout>
        <Modal
          style={{
            background: 'transparent',
            width: 672,
          }}
          onCancel={() => this.setdownload(false)}
          className="wordCloud-modal-download"
          title={null}
          visible={this.state.visible}
          autoFocus={false}
          footer={null}
        >
          <div className="wordCloud-modal-download-content">
            <div className="wordCloud-img">
              {visibleimg ? <img src={visibleimg} /> : ''}
            </div>

            <div className="wordCloud-opera">
              <div className="select-type">
                <label>输出格式</label>
                <Select
                  value="png"
                  placeholder="格式选择"
                  style={{ width: 116 }}
                >
                  {['png'].map((option, index) => (
                    <Option key={option} value={option}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </div>
              <Button
                type="primary"
                className="download-btn"
                onClick={this.getUrlBase64.bind(this)}
              >
                <IconDownload />
                立即下载
              </Button>
            </div>
          </div>
        </Modal>
        <UpgradeTips
          visible={visibleUpgrade}
          notification_status={upgradetips?.notice_type}
          notification_message={upgradetips?.message}
          model_capability={upgradetips?.model_capability}
          onCancel={() => this.setvisibleUpgrade(false)}
        ></UpgradeTips>
      </div>
    )
  }
}
export default wordCloud
