import React, { Component } from 'react'
import {
  Layout,
  Typography,
  Button,
  Space,
  Empty,
  Input,
  Select,
  Notification,
  Divider,
  Upload,
  Message,
} from '@arco-design/web-react'
import { IconPlus } from '@arco-design/web-react/icon'
import Emptydata_img from 'asserts/Emptydata_img.svg'
import Generating_img from 'asserts/Generating_img.gif'
import errorIcon from 'asserts/error_icon.svg'
import {
  getApiInfo,
  getRequest,
  putmodelversion,
  putapp,
  check_resource,
} from 'api/apis'
import history from 'common/utils/history'
import { CreateLink } from 'common/utils/business'
import { debounce, b2ValueUnit } from 'common/utils'
import copy from 'copy-to-clipboard'
import { sourceCancelClass } from 'api/cancel-request'
import UpgradeTips from 'components/UpgradeTips'
import upload from 'api/uploads'

import $ from 'jquery'
import './emotionClass.scss'
const Sider = Layout.Sider
const Content = Layout.Content
const Option = Select.Option
const TextArea = Input.TextArea
const COLORS_CUSTOM = [
  '#DC2EF8',
  '#2049FF',
  '#00C7FF',
  '#FF8700',
  '#7FCA00',
  '#57DBD4',
  '#B18533',
  '#FF3D9F',
  '#A968FF',
  '#D2CD34',
  '#0FC6C2',
  '#F53F3F',
  '#7816FF',
  '#1d5ae6',
  '#319227',
  '#a6d8ff',
  '#423535',
  '#16efff',
  '#4916ff',
  '#16ffa3',
  '#ca1364',
  '#8a1114',
  '#538a11',
  '#b6ce13',
  '#ce6d13',
  '#fc6220',
]
// 封装组件
class EmotionClass extends Component {
  constructor(props) {
    super(props)
    this.state = {
      no_data: false,
      optionVlue: '',
      TextAreaValue: '',
      versions: [],
      ParagraphContinuation: [],
      ContinuationLoding: false,
      record: JSON.parse(localStorage.getItem('record')),
      apiInfo: {
        model_id: '',
      },
      number: 6,
      requestMsg: '',
      requestMsgText: '您还未输入语句',
      input_describe: '请输入文段，或导入文本文件进行分析',
      prompt: '',
      visibleUpgrade: false,
      upgradetips: null,
      jsonData: null,
      fileList: [],
      attachment: [],
      emotionData: [],
      identity: JSON.parse(localStorage.getItem('userinfo'))
        ? JSON.parse(localStorage.getItem('userinfo')).group_permission
        : '',
    }
    this.isPhoneLegal = debounce(this.isPhoneLegal, 1000)
    this.isputapp = debounce(this.isputapp, 1000)
  }
  componentDidMount() {
    this.getApiInfo()
    window.addEventListener('resize', this.handleResize)
    setTimeout(() => {
      this.handleResize()
    }, 300)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }
  handleResize = () => {
    var winHeight = 0
    if (window.innerHeight) {
      winHeight = window.innerHeight
    } else if (document.body && document.body.clientHeight) {
      winHeight = document.body.clientHeight
    }
    if (document.documentElement && document.documentElement.clientHeight) {
      winHeight = document.documentElement.clientHeight
    }
    let layoutHeaders_h = $('#layoutHeaders').innerHeight()
    if (layoutHeaders_h > winHeight) {
      layoutHeaders_h = 64
    }
    let height =
      winHeight -
      layoutHeaders_h -
      document.getElementById('renderHeaders').clientHeight -
      77

    document.getElementById('layout_id').style.height = height + 'px'
    document.getElementById('globalModleBox').style.height = height - 201 + 'px'
    $('.globalModle-left').css({
      height: $('#layout_id').height() - 80,
      overflowY: 'auto',
    })

    if (document.getElementById('continuation')) {
      let height2 =
        document.getElementById('layout_id').clientHeight -
        document.getElementById('result_title').clientHeight -
        document.getElementById('IconSynbtn').clientHeight
      document.getElementById('continuation').style.height =
        height2 - 110 + 'px'
    }
  }
  putmodelversion(optionVlue) {
    let params = {
      id: optionVlue,
    }
    sourceCancelClass()
    this.setState({
      ContinuationLoding: true,
      requestMsg: '',
    })
    putmodelversion(params).then((res) => {
      if (res.code === 200) {
        this.isPhoneLegal()
      } else {
        this.setState({
          ParagraphContinuation: null,
          ContinuationLoding: false,
          requestMsg: '',
          requestMsgText: '模型版本激活失败',
          loading: false,
        })
      }
    })
  }
  getApiInfo() {
    let params = {
      app_id: this.state.record.id,
    }
    getApiInfo(params).then((res) => {
      if (res.code === 200) {
        let list = res.data
        if (res.data.model_params) {
          history.push('/myapp/enteringGinseng')
          history.go()
        } else {
          this.setState({
            apiInfo: list,
            versions: list.versions,
          })
          // document.title = list.app_name
          if (list.versions.length > 0) {
            this.setState({
              optionVlue: list.versions[0].id,
            })
          }
          if (list.type === 0) {
            this.setState({
              ContinuationLoding: true,
              requestMsg: '',
            })
            this.onTextArea(list.input_describe ? list.input_describe : '')
            this.isPhoneLegal()
          }
        }
      } else {
      }
    })
  }
  onsetPosition(value) {
    this.setState({
      optionVlue: value,
    })
    this.putmodelversion(value)
  }
  onTextArea(value) {
    this.setState({
      ContinuationLoding: true,
    })
    this.setState({
      TextAreaValue: value,
    })
    this.isPhoneLegal()
  }
  isPhoneLegal = () => {
    this.setState({
      ContinuationLoding: true,
      requestMsg: '',
    })
    sourceCancelClass()
    this.setRequest()
  }
  ChangeAbatch() {
    sourceCancelClass()
    this.setState({
      ContinuationLoding: true,
      requestMsg: '',
    })
    setTimeout(() => {
      this.setRequest()
    }, 1000)
  }
  setRequest() {
    if (this.state.TextAreaValue.length < 0) {
      this.setState({
        ParagraphContinuation: null,
        ContinuationLoding: false,
        requestMsg: '',
        requestMsgText: '您还未输入语句',
      })
    } else {
      this.getCheckResource()
    }
  }

  sendRequest() {
    let params = {
      app_id: this.state.record.id,
      fileKey: JSON.stringify(this.state.attachment),
      text: this.state.TextAreaValue,
      num: this.state.number,
    }

    if (this.state.optionVlue) {
      params.version_id = this.state.optionVlue
    }

    getRequest(params).then((res) => {
      const code = [400, 3003, 204]
      if (res.code === 200) {
        let list = res.data
        this.setState({
          jsonData: list,
          ParagraphContinuation: list,
          ContinuationLoding: false,
          requestMsg: '',
          prompt: list.prompt,
        })
        this.handleResize()
      } else if (code.includes(res.code)) {
        this.setState({
          ParagraphContinuation: null,
          ContinuationLoding: false,
          requestMsg: '',
          requestMsgText: res.msg,
        })
      } else {
        this.setState({
          ParagraphContinuation: null,
          ContinuationLoding: false,
          requestMsg: 'error',
          requestMsgText: res.msg,
        })
      }
    })
  }
  getCheckResource() {
    let params = { model_capability: this.state.apiInfo?.model_capability }
    let PACKAGES = ['PACKAGE_FINISH', 'PACKAGE_EXPIRED']
    check_resource(params).then((res) => {
      if (res.code === 200) {
        if (res.data) {
          let new_upgradetips = res.data
          new_upgradetips.model_capability = params.model_capability
          this.setState({
            upgradetips: new_upgradetips,
          })
          if (PACKAGES[new_upgradetips.notice_type]) {
            Notification.warning({ content: new_upgradetips.message })
          } else {
            setTimeout(() => {
              this.setState({
                visibleUpgrade: true,
              })
            }, 100)
          }
        }

        this.sendRequest()
      }
    })
  }

  goResources() {
    history.push('/myapp/resources')
    localStorage.setItem('record', JSON.stringify(this.state.record))
    window.location.reload()
  }
  goInterfacetest() {
    history.push('/myapp/interfacetest')
    localStorage.setItem('record', JSON.stringify(this.state.record))
    window.location.reload()
  }
  oncopy(item) {
    let value = item.sentence
    if (copy(value)) {
      Notification.success({ content: '复制成功!' })
    } else {
      Notification.error({ content: '复制失败!' })
    }
  }
  isputapp = (name, desc) => {
    this.putapp(name, desc)
  }
  putapp(name, desc) {
    let params = {
      id: this.state.record.id,
    }
    if (desc !== false) {
      params.desc = desc
    } else if (name !== false) {
      params.name = name
    }
    putapp(params).then((res) => {
      if (res.code === 200) {
      } else {
        this.getApiInfo(true)
        Notification.error({ content: res.msg })
      }
    })
  }

  setContinuation = (item) => {
    let tispArr = []
    if (item.prediction_label && item.prediction_label.length > 0) {
      item.prediction_label.forEach((e) => {
        let obj = {}
        if (this.state.emotionData.indexOf(e) === -1) {
          this.state.emotionData.push(e)
          let index = this.state.emotionData.indexOf(e)
          obj = {
            name: e,
            color: COLORS_CUSTOM[index],
          }
        } else {
          let index = this.state.emotionData.indexOf(e)
          obj = {
            name: e,
            color: COLORS_CUSTOM[index],
          }
        }
        tispArr.push(obj)
      })
    }
    let tagsHtml = ''
    tispArr.forEach((e) => {
      tagsHtml += `<div class="rowStart tags-color"><div class="tag-color" style="--bgcolor: ${e.color}"></div>${e.name}</div>`
    })

    let tagslist = `<div class="result-li-tag rowStartWrap">${tagsHtml}</div>`
    let newHTML =
      `<div class="result-li">` + item.original + `</div>${tagslist}`
    let htmls = { __html: newHTML.replaceAll('\n', '<br/>') }
    return <div className="result-ul" dangerouslySetInnerHTML={htmls}></div>
  }

  onChangetitle(type, value) {
    var apiInfo = this.state.apiInfo
    if (type === 'desc') {
      if (value.length > 32) {
        apiInfo.app_desc = value.substring(0, 32)
        Notification.warning({ content: '修改内容超过32个字！' })
        return
      }
      apiInfo.app_desc = value
      this.setState({
        apiInfo: apiInfo,
      })
      this.isputapp(false, value)
    } else if (type === 'name') {
      if (value.length > 10) {
        apiInfo.app_name = value.substring(0, 10)
        Notification.warning({ content: '修改标题超过10个字！' })
        return
      }
      apiInfo.app_name = value
      this.setState({
        apiInfo: apiInfo,
      })
      this.isputapp(value, false)
    }
  }
  goaddApp() {
    history.replace('/myapp/addmyapp')
    let state = {
      model_capability: this.state.apiInfo.model_capability,
      checkedtype: this.state.apiInfo.model_id,
      model_id: this.state.apiInfo.model_id,
      name: this.state.apiInfo.model_name,
    }
    localStorage.setItem('props', JSON.stringify(state))
    localStorage.removeItem('record')
    window.location.reload()
  }
  addRow() {
    localStorage.removeItem('props')
    localStorage.setItem('record', JSON.stringify(this.state.apiInfo))
    history.replace('/enginefactory/model/management/add')
    window.location.reload()
  }
  setvisibleUpgrade(value) {
    this.setState({
      visibleUpgrade: value,
    })
  }

  handleUpload(option) {
    upload(option)
  }
  setFile(fileList, file) {
    this.setState({
      fileList: fileList,
    })
    if (file.status === 'done') {
      let key = []
      let fileList = this.state.fileList
      fileList.forEach((item, index) => {
        key.push(item.response.fileKey)
      })
      this.setState({
        attachment: key,
        fileList: fileList,
      })
    }
  }
  beforeUpload(file) {
    var AllImgExt = '.csv|'
    var extName = file.name.substring(file.name.lastIndexOf('.')).toLowerCase() //（把路径中的所有字母全部转换为小写）
    if (AllImgExt.indexOf(extName + '|') === -1) {
      let ErrMsg =
        '该文件类型不允许上传。请上传 .csv类型的文件，当前文件类型为' + extName
      Message.error(ErrMsg)
      return false
    }
    const isLt10M = !(
      b2ValueUnit(file.size)[1] === 'B' ||
      b2ValueUnit(file.size)[1] === 'KB' ||
      (b2ValueUnit(file.size)[1] === 'MB' && b2ValueUnit(file.size)[0] <= 5)
    )
    return new Promise((resolve, reject) => {
      if (isLt10M) {
        Message.error('上传文件不能大于5MB')
        reject()
      } else {
        resolve()
      }
    })
  }
  render() {
    const {
      visibleUpgrade,
      upgradetips,
      apiInfo,
      versions,
      input_describe,
      ParagraphContinuation,
      ContinuationLoding,
      TextAreaValue,
      optionVlue,
      requestMsg,
      requestMsgText,
      fileList,
    } = this.state
    return (
      <div className="product-emotionClass">
        <div id="renderHeaders">
          <div className="GroupTable-fixe GroupTable-fixe-end">
            <div className="edit-title">
              <div className="Header-init">
                <div className="Header-title">
                  <Typography.Paragraph
                    className="Header-uptitle"
                    editable={
                      apiInfo.type === 0
                        ? null
                        : {
                            onChange: this.onChangetitle.bind(this, 'name'),
                          }
                    }
                  >
                    {apiInfo.app_name}
                  </Typography.Paragraph>
                </div>
              </div>
              <div className="Header-upParagraph">
                <Typography.Paragraph
                  className="Header-uptitle"
                  editable={
                    apiInfo.type === 0
                      ? null
                      : {
                          onChange: this.onChangetitle.bind(this, 'desc'),
                        }
                  }
                >
                  {apiInfo.app_desc}
                </Typography.Paragraph>
              </div>
            </div>
            <Space size={'medium'} align="center">
              {apiInfo.type === 0 ? (
                <Button
                  className="primarybtn custom-White-button"
                  onClick={this.goaddApp.bind(this)}
                >
                  保存应用
                </Button>
              ) : (
                <>
                  {versions.length === 0 ? (
                    <div></div>
                  ) : (
                    <Select
                      value={optionVlue}
                      placeholder="版本选择"
                      style={{ width: 220 }}
                      className="versionsSelect"
                      onChange={this.onsetPosition.bind(this)}
                      dropdownRender={(menu) => (
                        <div>
                          {menu}
                          <Divider
                            style={{
                              margin: 0,
                              marginTop: 6,
                              borderColor: '#f2f2f2',
                            }}
                          />
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              padding: '5px 0px',
                            }}
                          >
                            <Button
                              style={{
                                width: '100%',
                                height: 41,
                                fontSize: 14,
                                padding: '0 6px',
                                borderRadius: 10,
                              }}
                              type="text"
                              onClick={this.addRow.bind(this)}
                            >
                              <IconPlus />
                              新增模型版本
                            </Button>
                          </div>
                        </div>
                      )}
                    >
                      {versions.map((option, index) => (
                        <Option key={index} value={option.id}>
                          {apiInfo.model_name}V{option.version}
                        </Option>
                      ))}
                    </Select>
                  )}
                  {apiInfo.type === 9 ? (
                    <div className="primary_model_name">
                      <div className="primary_model_name_fix">
                        <span className="primary_model_name_title">
                          模型
                          <Divider type="vertical" />
                        </span>
                        <Typography.Paragraph ellipsis>
                          {apiInfo.model_name}
                        </Typography.Paragraph>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {/* <Button
                    shape="circle"
                    className="custom-White-button"
                    onClick={this.goResources.bind(this)}
                  >
                    应用资源
                  </Button> */}
                  <Button
                    shape="circle"
                    className="custom-White-button share_button_wnk"
                    onClick={CreateLink.bind(this, apiInfo)}
                  >
                    分享应用
                  </Button>
                  <Button
                    style={{
                      display:
                        this.state.identity &&
                        this.state.identity !== 'PERSONAL'
                          ? 'inline-block'
                          : 'none',
                    }}
                    shape="circle"
                    className="custom-White-button"
                    onClick={this.goInterfacetest.bind(this)}
                  >
                    接口调用
                  </Button>
                </>
              )}
            </Space>
          </div>
        </div>
        <Layout className="semanticprototype-Layout" id="layout_id">
          <Sider className="Sider-left">
            <div className="upload-file">
              <div className="upload-file-title">导入批量文本</div>
              <div>
                <Upload
                  accept=".csv"
                  fileList={fileList}
                  renderUploadList={this.renderUploadList}
                  limit={1}
                  onChange={this.setFile.bind(this)}
                  customRequest={(option) => {
                    this.handleUpload(option)
                  }}
                  beforeUpload={this.beforeUpload.bind(this)}
                  onExceedLimit={() => {
                    Message.warning('超过上传数量限制！最多上传1个')
                  }}
                >
                  <div className="rowCenter trigger">
                    <span className="iconfont icon-icon_chuangjian_24"></span>
                    <div>点击此处上传文件</div>
                  </div>
                </Upload>
                <div className="Upload-pictures-tip">
                  （仅支持.CSV格式，最大文件尺寸5MB）
                </div>
              </div>
            </div>
            <div
              className="Sider-left-configure columnSpaceBetween"
              id="globalModleBox"
            >
              <div className="Continuation-input">
                <TextArea
                  placeholder={input_describe}
                  style={{ width: '100%' }}
                  value={TextAreaValue}
                  onChange={this.onTextArea.bind(this)}
                />
              </div>
            </div>
            <div className="Upload-pictures-bottom">
              <Button
                type="primary"
                className="Start-generation"
                loading={false}
                onClick={this.ChangeAbatch.bind(this, 'start')}
              >
                开始分析
              </Button>
            </div>
          </Sider>
          <Content>
            <div className="experience-result" id="result">
              <div id="result_title" className="rowSpaceBetween result_title">
                <Typography>生成结果</Typography>
                <Typography.Paragraph className="result-remarks">
                  (内容基于韦尼克AI创作引擎生成，仅供参考)
                </Typography.Paragraph>
              </div>
              {(ParagraphContinuation && ParagraphContinuation.length > 0) ||
              ContinuationLoding ? (
                <div className="Paragraph-generate">
                  {ContinuationLoding ? (
                    <Empty
                      className="Continuation-loding"
                      icon={<img src={Generating_img} alt="" />}
                      description={
                        <Typography
                          style={{
                            color: '#4e5969',
                            marginTop: 21,
                            fontSize: 16,
                          }}
                        >
                          正在生成中...
                        </Typography>
                      }
                    />
                  ) : (
                    <div className="result-list">
                      {ParagraphContinuation.length > 0 ? (
                        <>
                          {ParagraphContinuation.map((item, i) => {
                            return (
                              <div key={i}>{this.setContinuation(item)}</div>
                            )
                          })}
                        </>
                      ) : null}
                    </div>
                  )}
                </div>
              ) : requestMsg === 'error' ? (
                <Empty
                  className="Continuation-Empty Empty-error"
                  imgSrc={errorIcon}
                  description={
                    <Space direction="vertical">
                      <Typography
                        style={{
                          color: '#4e5969',
                          marginTop: 30,
                          fontSize: 16,
                        }}
                      >
                        抱歉，服务器生成错误
                      </Typography>
                      <Typography style={{ color: '#4e5969', fontSize: 16 }}>
                        可尝试
                        <span
                          style={{ color: '#406EFF', cursor: 'pointer' }}
                          onClick={this.ChangeAbatch.bind(this)}
                        >
                          重新生成
                        </span>
                      </Typography>
                    </Space>
                  }
                />
              ) : (
                <Empty
                  className="Continuation-Empty Empty-none"
                  imgSrc={Emptydata_img}
                  description={
                    <Space direction="vertical">
                      <Typography
                        style={{
                          color: '#4e5969',
                          marginTop: 30,
                          fontSize: 16,
                        }}
                      >
                        暂无生成结果
                      </Typography>
                      <Typography style={{ color: '#4e5969', fontSize: 16 }}>
                        {requestMsgText}
                      </Typography>
                    </Space>
                  }
                />
              )}
            </div>
          </Content>
        </Layout>
        <UpgradeTips
          visible={visibleUpgrade}
          notification_status={upgradetips?.notice_type}
          notification_message={upgradetips?.message}
          model_capability={upgradetips?.model_capability}
          onCancel={() => this.setvisibleUpgrade(false)}
        ></UpgradeTips>
      </div>
    )
  }
}
export default EmotionClass
