import React, { Component } from 'react'
import {
  Layout,
  Typography,
  Button,
  Space,
  Empty,
  Input,
  Notification,
  Avatar,
  Spin,
  Modal,
  Select,
} from '@arco-design/web-react'
import Emptydata_img from 'asserts/Emptydata_img.svg'
import Generating_img from 'asserts/Generating_img.gif'
import errorIcon from 'asserts/error_icon.svg'
import { shareAppApi } from 'api/apis'
import { debounce } from 'common/utils'
import '../../common/style/myapp/wordCloud.scss'
import { IconDownload, IconPlus } from '@arco-design/web-react/icon'
const Sider = Layout.Sider
const Content = Layout.Content
const TextArea = Input.TextArea
const Option = Select.Option

// 封装组件
class wordCloud extends Component {
  constructor(props) {
    super(props)

    this.state = {
      share_id: localStorage.getItem('share_ids'),
      optionVlue: '',
      TextAreaValue: '',
      ParagraphContinuation: [],
      ContinuationLoding: false,
      record: JSON.parse(localStorage.getItem('shareRecord')),
      requestMsg: '',
      requestMsgText: '您还未输入关键信息',
      visible: false,
      visibleimg: null,
      marginRight: 12,
      loading: false,
      input_describe: '请输入关键词或文段，生成云图',
    }
    this.isputapp = debounce(this.isputapp, 1000)
  }
  componentDidMount() {
    this.getApiInfo()
    setTimeout(() => {
      this.handleResize()
    }, 300)
  }
  componentWillUnmount() {
    localStorage.removeItem('props')
  }
  handleResize = () => {
    // let winHeight = 0
    // if (window.innerHeight) {
    //   winHeight = window.innerHeight
    // } else if (document.body && document.body.clientHeight) {
    //   winHeight = document.body.clientHeight
    // }
    // if (document.documentElement && document.documentElement.clientHeight) {
    //   winHeight = document.documentElement.clientHeight
    // }
    // let layoutHeaders_h = document.getElementById('layoutHeaders').clientHeight
    // if (layoutHeaders_h > winHeight) {
    //   layoutHeaders_h = 64
    // }
    // let height =
    //   winHeight -
    //   layoutHeaders_h -
    //   document.getElementById('renderHeaders').clientHeight -
    //   60
    // document.getElementById('layout_id').style.height = height + 'px'
    // let height2 =
    //   height - document.getElementById('result_title').clientHeight - 27
    // document.getElementById('continuation').style.height = height2 - 30 + 'px'
  }

  getApiInfo() {
    let list = this.state.record
    if (list.type === 0) {
      this.onTextArea(
        list.input_describe
          ? list.input_describe
          : '工作职责：' +
              ' 1.其他领导交代的工作' +
              '2.固定资产和低值易耗品的登记和管理' +
              '3.协助财会文件的准备、归档和保管' +
              '4.现金及银行收付处理，制作记帐凭证，银行对帐，单据审核，开具与保管发票' +
              '5.负责公司仓库进销存账务。' +
              '任职资格：' +
              '1.管理仓库进销存，记录应收应付。' +
              '2.具有独立工作和学习的能力，工作认真细心。' +
              '3.较好的会计基础知识和一定的英语能力，有财会工作经验者优先' +
              '4.熟悉现金管理及银行结算，财务软件操作' +
              '5.良好的职业操守及团队合作精神，较强的沟通、理解和分析能力'
      )
      this.isPhoneLegal()
    }
  }

  onTextArea(value) {
    this.setState({
      TextAreaValue: value,
    })
  }
  onKeyDown(event) {
    //console.log(event.keyCode, "键盘事件");
    if (event.shiftKey && event.keyCode === 13) {
      let e = this.state.TextAreaValue
      this.setState({
        TextAreaValue: e,
      })
    } else if (event.ctrlKey && event.keyCode === 13) {
      event.cancelBubble = true
      event.preventDefault()
      event.stopPropagation()
      event.returnValue = false
      this.setState({
        ParagraphContinuation: [],
        ContinuationLoding: true,
        requestMsg: '',
      })
      this.isPhoneLegal()
      return false
    } else if (event.keyCode === 13) {
      this.setState({
        ParagraphContinuation: [],
        ContinuationLoding: true,
        requestMsg: '',
      })
      event.cancelBubble = true
      event.preventDefault()
      event.stopPropagation()
      this.isPhoneLegal()
      event.returnValue = false
      return false
    }
  }
  isPhoneLegal = () => {
    this.setState({
      ParagraphContinuation: [],
      loading: true,
      ContinuationLoding: true,
      requestMsg: '',
    })
    setTimeout(() => {
      this.setRequest()
    }, 1000)
  }
  setRequest() {
    const [oneWidth, marginRight] = [261, 12]
    const containerW = document.getElementById('continuation').clientWidth
    const colsNum = Math.floor(containerW / (oneWidth + marginRight))
    const marginRight2 = Math.floor(
      (containerW - colsNum * (oneWidth + marginRight)) / colsNum
    )

    this.setState({
      marginRight: marginRight + marginRight2,
    })

    if (this.state.TextAreaValue === '') {
      this.setState({
        ParagraphContinuation: [],
        ContinuationLoding: false,
        loading: false,
        requestMsg: '',
        requestMsgText: '您还未输入关键信息',
      })
    } else {
      let params = {
        app_id: this.state.record.app_id,
        text: this.state.TextAreaValue,
        share_id: this.state.share_id,
      }
      if (this.state.optionVlue) {
        params.version_id = this.state.optionVlue
      }
      const { ParagraphContinuation } = this.state

      this.setState({
        loading: true,
      })
      shareAppApi(params).then((res) => {
        if (res.code === 200) {
          this.setState({
            ParagraphContinuation: ParagraphContinuation.concat(res.data),
            ContinuationLoding: false,
            requestMsg: '',
          })

          if (this.state.ParagraphContinuation.length % 12 !== 0) {
            this.setRequest()
          } else {
            this.setState({
              loading: false,
            })
          }
          if (
            this.state.ParagraphContinuation.length === 1 &&
            res.data.length > 0
          ) {
            setTimeout(() => {
              this.handleResize()
            }, 100)
          }
        } else if (res.code === 400 || res.code === 3003) {
          this.setState({
            ContinuationLoding: false,
            requestMsg: '',
            loading: false,
          })
          if (ParagraphContinuation.length > 0) {
            Notification.warning({ content: res.msg })
          } else {
            this.setState({
              ParagraphContinuation: [],
              requestMsgText: res.msg,
            })
          }
        } else {
          this.setState({
            ContinuationLoding: false,
            requestMsg: '',
            loading: false,
          })
          if (ParagraphContinuation.length > 0) {
            Notification.warning({ content: res.msg })
          } else {
            this.setState({
              ParagraphContinuation: [],
              requestMsgText: res.msg,
              requestMsg: 'error',
            })
          }
        }
      })
    }
  }

  ChangeAbatch(type) {
    if (type === 'start') {
      this.setState({
        ParagraphContinuation: [],
        loading: false,
        ContinuationLoding: true,
        requestMsg: '',
      })
    } else {
      this.setState({
        loading: true,
        requestMsg: '',
      })
    }
    setTimeout(() => {
      this.setRequest()
    }, 1000)
  }
  setdownload(visible) {
    this.setState({
      visible: visible,
    })
  }
  opendownload(item) {
    this.setdownload(true)
    this.setState({
      visibleimg: item,
    })
  }
  getUrlBase64() {
    let url = this.state.visibleimg
    fetch(url)
      .then(async (res) => await res.blob())
      .then((blob) => {
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = URL.createObjectURL(blob)
        a.download = '词云图-' + Date.now() + '.png'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      })
  }

  render() {
    const {
      input_describe,
      TextAreaValue,
      loading,
      visibleimg,
      marginRight,
      ParagraphContinuation,
      ContinuationLoding,
      requestMsg,
      requestMsgText,
    } = this.state
    const count = ParagraphContinuation.length

    return (
      <div className="wordCloud">
        <Layout className="semanticprototype-Layout" id="layout_id">
          <Sider className="Sider-left">
            <div className="Sider-left-configure columnSpaceBetween">
              <div className="Continuation-input">
                <TextArea
                  placeholder={input_describe}
                  style={{ width: '100%' }}
                  value={TextAreaValue}
                  onChange={this.onTextArea.bind(this)}
                  onKeyDown={(event) => this.onKeyDown(event)}
                />
              </div>
              <div className="Upload-pictures-bottom">
                <Button
                  type="primary"
                  className="Start-generation"
                  disabled={loading}
                  onClick={this.isPhoneLegal.bind(this)}
                >
                  生成词云
                </Button>
              </div>
            </div>
          </Sider>
          <Content>
            <div className="experience-result" id="result">
              <div id="result_title" className="rowSpaceBetween result_title">
                <Typography>生成结果</Typography>
                <Typography.Paragraph className="result-remarks">
                  (内容基于韦尼克AI创作引擎生成，仅供参考)
                </Typography.Paragraph>
              </div>
              <div className="continuation-body" id="continuation">
                {count > 0 || ContinuationLoding ? (
                  <div className="Paragraph-generate">
                    {ContinuationLoding ? (
                      <Empty
                        className="Continuation-loding"
                        icon={<img src={Generating_img} alt="" />}
                        description={
                          <Typography
                            style={{
                              color: '#4e5969',
                              marginTop: 21,
                              fontSize: 16,
                            }}
                          >
                            正在生成中...
                          </Typography>
                        }
                      />
                    ) : (
                      <div className="Continuation-result" id="continuation">
                        <div className="Continuation-list">
                          {ParagraphContinuation.map((item, index) => {
                            return (
                              <div
                                className="Continuation-item"
                                style={{ marginRight: marginRight }}
                                key={index}
                                onClick={this.opendownload.bind(this, item)}
                              >
                                <div className="Continuation-img">
                                  <Avatar shape="square">
                                    <img alt="avatar" key={index} src={item} />
                                  </Avatar>
                                  {/* <Image key={index} src={item} /> */}
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        {loading ? (
                          <Spin dot />
                        ) : (
                          <Button
                            type="primary"
                            icon={<IconPlus />}
                            id="iconSync"
                            className="IconSync-btn"
                            onClick={this.ChangeAbatch.bind(this)}
                          >
                            生成更多
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                ) : requestMsg === 'error' ? (
                  <Empty
                    className="Continuation-Empty Empty-error"
                    imgSrc={errorIcon}
                    description={
                      <Space direction="vertical">
                        <Typography
                          style={{
                            color: '#4e5969',
                            marginTop: 30,
                            fontSize: 16,
                          }}
                        >
                          抱歉，服务器生成错误
                        </Typography>
                        <Typography style={{ color: '#4e5969', fontSize: 16 }}>
                          可尝试
                          <span
                            style={{ color: '#406EFF', cursor: 'pointer' }}
                            onClick={this.ChangeAbatch.bind(this)}
                          >
                            {' '}
                            重新生成
                          </span>
                        </Typography>
                      </Space>
                    }
                  />
                ) : (
                  <Empty
                    className="Continuation-Empty Empty-none"
                    imgSrc={Emptydata_img}
                    description={
                      <Space direction="vertical">
                        <Typography
                          style={{
                            color: '#4e5969',
                            marginTop: 30,
                            fontSize: 16,
                          }}
                        >
                          暂无生成结果
                        </Typography>
                        <Typography style={{ color: '#4e5969', fontSize: 16 }}>
                          {requestMsgText}
                        </Typography>
                      </Space>
                    }
                  />
                )}
              </div>
            </div>
          </Content>
        </Layout>
        <Modal
          style={{
            background: 'transparent',
            width: 672,
          }}
          onCancel={() => this.setdownload(false)}
          className="wordCloud-modal-download"
          title={null}
          visible={this.state.visible}
          autoFocus={false}
          footer={null}
        >
          <div className="wordCloud-modal-download-content">
            <div className="wordCloud-img">
              {visibleimg ? <img src={visibleimg} alt="" /> : ''}
            </div>

            <div className="wordCloud-opera">
              <div className="select-type">
                <label>输出格式</label>
                <Select
                  value="png"
                  placeholder="格式选择"
                  style={{ width: 116 }}
                >
                  {['png'].map((option, index) => (
                    <Option key={option} value={option}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </div>
              <Button
                type="primary"
                className="download-btn"
                onClick={this.getUrlBase64.bind(this)}
              >
                <IconDownload />
                立即下载
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}
export default wordCloud
