import React, { Component } from "react";
import { Button, Statistic, Link } from "@arco-design/web-react";
import history from "common/utils/history";
import {
  IconWechat,
  IconAlipayCircle,
  IconArrowLeft,
} from "@arco-design/web-react/icon";
import { formatMoney } from "common/utils";
import "./index.scss";
class ConfirmOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderlist: props.orderlist,
      order_sn: props.order_sn,
      pay_amount: props.pay_amount,
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      orderlist: nextProps.orderlist,
      order_sn: nextProps.order_sn,
      pay_amount: nextProps.pay_amount,
    });
  }
  onbalck() {
    history.go();
  }
  render() {
    return (
      <div className="ConfirmOrder">
        <div className="confirm-order addpadding">
          <div className="order-lable AlibabaPuHuiTi-Medium">确认订单</div>
          <div className="confirm-info">
            <div className="ordersid">
              订单号：
              <span className="AlibabaPuHuiTi-Medium">
                {this.state.order_sn}
              </span>
            </div>
            <div className="order-info">
              {this.state.orderlist.map((item, index) => {
                return (
                  <div key={index}>
                    {index < 1 ? "" : <div className="order-hr"></div>}
                    <div className="from-table">
                      <div className="from-item rowStart">
                        <div className="from-item-lable">服务：</div>
                        <div className="from-item-value">
                          {item.service_option}
                        </div>
                      </div>
                      <div className="from-item rowStart">
                        <div className="from-item-lable">规格：</div>
                        <div className="from-item-value">{item.title}</div>
                      </div>
                      <div className="from-item rowStart">
                        <div className="from-item-lable">单价：</div>
                        <div className="from-item-value">
                          <span className="from-item-money">￥</span>
                          {formatMoney(item.price)}
                        </div>
                      </div>
                      <div className="from-item rowStart">
                        <div className="from-item-lable">数量：</div>
                        <div className="from-item-value">
                          <span className="from-item-nuit">x {item.num}</span>
                        </div>
                      </div>
                    </div>
                    <div className="total-amount">
                      总金额：
                      <span className="AlibabaPuHuiTi-Medium">
                        ¥ {formatMoney(item.total_price)}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="promotion-bottom">
          <div className="rowFlexEndEnd promotion-End">
            <Button
              icon={<IconArrowLeft />}
              className="promotion-back custom-step-button"
              onClick={this.onbalck.bind(this)}
            >
              返回
            </Button>
            <IconWechat />
            <IconAlipayCircle />
            <span>实付金额：</span>
            <Statistic
              value={this.state.pay_amount}
              prefix="¥"
              precision={2}
              groupSeparator
            />
            <Button
              type="primary"
              className="settlement"
              onClick={this.props.onpayment.bind(this)}
            >
              立即支付
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default ConfirmOrder;
