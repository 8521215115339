import React, { Component } from 'react'
import {
  Layout,
  Typography,
  Button,
  Space,
  Empty,
  Input,
} from '@arco-design/web-react'
import Emptydata_img from 'asserts/Emptydata_img.svg'
import Generating_img from 'asserts/Generating_img.gif'
import errorIcon from 'asserts/error_icon.svg'
import { shareAppApi } from 'api/apis'
import $ from 'jquery'
import { debounce } from 'common/utils'
import { sourceCancelClass } from 'api/cancel-request'
import '../../common/style/myapp/writing.scss'
const Sider = Layout.Sider
const Content = Layout.Content
const TextArea = Input.TextArea
// 封装组件
class Writing extends Component {
  constructor(props) {
    super(props)
    this.state = {
      share_id: localStorage.getItem('share_ids'),
      TextAreaValue: '',
      ParagraphContinuation: [],
      ContinuationLoding: false,
      record: JSON.parse(localStorage.getItem('shareRecord')),
      number: 6,
      requestMsg: '',
      requestMsgText: '您还未输入关键词',
      loading: false,
      input_describe: '请输入多个关键词，用空格间隔，开始生成文段',
    }
    this.isputapp = debounce(this.isputapp, 1000)
  }
  componentDidMount() {
    this.getApiInfo()

    // let list = '1.在外资机构工作四年，外语优势明显，先后担任过产品经理、项目主管职务及公司副总等职务。2.做美股操盘手一年，有丰富的销售经验和独特原型设计与分析技巧。'
    // //console.log(this.TrimNumber(list), '浮点')
  }
  componentWillUnmount() {
    localStorage.removeItem('props')
  }
  getApiInfo() {
    let list = Object.assign({}, this.state.record)
    if (list.type === 0) {
      this.onTextArea(
        list.input_describe ? list.input_describe : '产品经理 原型设计 用户调研'
      )
      this.isPhoneLegal()
    }
    //console.log(res.data, "api服务");
  }

  onKeyDown(event) {
    if (event.shiftKey && event.keyCode === 13) {
      let e = this.state.TextAreaValue
      this.setState({
        TextAreaValue: e,
      })
    } else if (event.ctrlKey && event.keyCode === 13) {
      event.cancelBubble = true
      event.preventDefault()
      event.stopPropagation()
      event.returnValue = false
      this.isPhoneLegal()
      return false
    } else if (event.keyCode === 13) {
      event.cancelBubble = true
      event.preventDefault()
      event.stopPropagation()
      this.isPhoneLegal()
      event.returnValue = false
      return false
    }
  }
  onTextArea(value) {
    this.setState({
      TextAreaValue: value,
    })
  }
  isPhoneLegal = () => {
    sourceCancelClass()
    this.setRequest()
  }
  setRequest() {
    this.setState({
      loading: true,
      ContinuationLoding: true,
      requestMsg: '',
    })
    let params = {
      app_id: this.state.record.app_id,
      text: this.state.TextAreaValue,
      num: 6,
      share_id: this.state.share_id,
    }
    shareAppApi(params).then((res) => {
      if (res.code === 200) {
        this.setState({
          ParagraphContinuation: this.uniqueList(res.data.generations) || [],
          ContinuationLoding: false,
          loading: false,
          requestMsg: '',
        })
      } else if (res.code === 400 || res.code === 3003) {
        this.setState({
          ParagraphContinuation: [],
          ContinuationLoding: false,
          requestMsg: '',
          requestMsgText: res.msg,
          loading: false,
        })
      } else if (res.code === 204) {
        this.setState({
          ParagraphContinuation: [],
          ContinuationLoding: false,
          loading: false,
          requestMsg: '',
          requestMsgText: '您还未输入关键词',
        })
      } else {
        this.setState({
          ParagraphContinuation: [],
          ContinuationLoding: false,
          requestMsg: 'error',
          requestMsgText: res.msg,
          loading: false,
        })
      }
    })
  }

  setNewVal(value) {
    //替换字符串中的所有特殊字符（包含空格）
    if (value !== '') {
      const pattern =
        /[`~!@#$^&*()=|{}':;',\\\[\]\.<>\/?~！@#￥……&*（）——|{}【】'；：""'。，、？\s]/g
      return value.replace(pattern, '')
    }
  }
  // 数组中对象的某个属性值进行去重
  uniqueList = (arr) => {
    const res = new Map()
    return arr.filter(
      (a) =>
        !res.has(this.setNewVal(a.sentence)) &&
        res.set(this.setNewVal(a.sentence), 1)
    )
  }
  Onreplace(newstr) {
    let titleAry = ['招聘岗位：', '岗位要求：']
    let TextAreaValue = this.state.TextAreaValue.toLowerCase()

    let strAry = TextAreaValue.split(/\s+/).filter(Boolean)
    //console.log(strAry, "strAry");
    strAry.map((item, index) => {
      let reg = item
      let html = `<span class='Preamble'>${reg}</span>`
      newstr = newstr.replaceAll(reg, html)
    })
    titleAry.map((item, index) => {
      let reg = item
      let html = `<span class='item-title AlibabaPuHuiTi-Medium'>${reg}</span>`
      newstr = newstr.replace(reg, html)
    })
    //console.log(strAry, "strAry");
    let htmls = { __html: newstr }
    return <div dangerouslySetInnerHTML={htmls}></div>
  }

  render() {
    const {
      input_describe,
      ParagraphContinuation,
      ContinuationLoding,
      TextAreaValue,
      requestMsg,
      requestMsgText,
    } = this.state
    return (
      <div className="writing">
        <Layout className="semanticprototype-Layout" id="layout_id">
          <Sider className="Sider-left">
            <div className="Sider-left-configure columnSpaceBetween">
              <div className="Continuation-input">
                <TextArea
                  placeholder={input_describe}
                  style={{ width: '100%' }}
                  value={TextAreaValue}
                  onChange={this.onTextArea.bind(this)}
                  onKeyDown={(event) => this.onKeyDown(event)}
                />
              </div>
              <div className="Upload-pictures-bottom">
                <Button
                  type="primary"
                  className="Start-generation"
                  loading={false}
                  onClick={this.isPhoneLegal.bind(this)}
                >
                  生成文段
                </Button>
              </div>
            </div>
          </Sider>
          <Content>
            <div className="experience-result" id="result">
              <div id="result_title" className="rowSpaceBetween result_title">
                <Typography>生成结果</Typography>
                <Typography.Paragraph className="result-remarks">
                  (内容基于韦尼克AI创作引擎生成，仅供参考)
                </Typography.Paragraph>
              </div>
              <div
                className="continuation-body"
                id="continuation"
                style={{ height: $('#shareContent').height() - 80 }}
              >
                {ParagraphContinuation.length || ContinuationLoding ? (
                  <div className="Paragraph-generate">
                    {ContinuationLoding ? (
                      <Empty
                        className="Continuation-loding"
                        icon={<img src={Generating_img} alt="" />}
                        description={
                          <Typography
                            style={{
                              color: '#4e5969',
                              marginTop: 21,
                              fontSize: 16,
                            }}
                          >
                            正在生成中...
                          </Typography>
                        }
                      />
                    ) : (
                      <div className="Continuation-result">
                        <div className="Continuation-list">
                          {ParagraphContinuation.map((item, index) => {
                            return (
                              <div className="Continuation-item" key={index}>
                                {this.Onreplace(item.sentence)}
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                ) : requestMsg === 'error' ? (
                  <Empty
                    className="Continuation-Empty Empty-error"
                    imgSrc={errorIcon}
                    description={
                      <Space direction="vertical">
                        <Typography
                          style={{
                            color: '#4e5969',
                            marginTop: 30,
                            fontSize: 16,
                          }}
                        >
                          抱歉，服务器生成错误
                        </Typography>
                        <Typography style={{ color: '#4e5969', fontSize: 16 }}>
                          可尝试
                          <span
                            style={{ color: '#406EFF', cursor: 'pointer' }}
                            onClick={this.ChangeAbatch.bind(this)}
                          >
                            {' '}
                            重新生成
                          </span>
                        </Typography>
                      </Space>
                    }
                  />
                ) : (
                  <Empty
                    className="Continuation-Empty Empty-none"
                    imgSrc={Emptydata_img}
                    description={
                      <Space direction="vertical">
                        <Typography
                          style={{
                            color: '#4e5969',
                            marginTop: 30,
                            fontSize: 16,
                          }}
                        >
                          暂无生成结果
                        </Typography>
                        <Typography style={{ color: '#4e5969', fontSize: 16 }}>
                          {requestMsgText}
                        </Typography>
                      </Space>
                    }
                  />
                )}
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    )
  }
}
export default Writing
