import React, { Component } from 'react'
import {
  Layout,
  Typography,
  Button,
  Space,
  Empty,
  Input,
  Notification,
} from '@arco-design/web-react'
import $ from 'jquery'
import { IconSync } from '@arco-design/web-react/icon'
import Emptydata_img from 'asserts/Emptydata_img.svg'
import Generating_img from 'asserts/Generating_img.gif'
import errorIcon from 'asserts/error_icon.svg'
import { shareAppApi } from 'api/apis'
import { debounce } from 'common/utils'
import { sourceCancelClass } from 'api/cancel-request'
import copy from 'copy-to-clipboard'
import '../../common/style/myapp/embroidery.scss'
const Sider = Layout.Sider
const Content = Layout.Content
const TextArea = Input.TextArea

// 封装组件
class Embroidery extends Component {
  constructor(props) {
    super(props)
    this.state = {
      optionVlue: '',
      TextAreaValue: '',
      versions: [],
      ParagraphContinuation: [],
      ContinuationLoding: false,
      share_id: localStorage.getItem('share_ids'),
      record: JSON.parse(localStorage.getItem('shareRecord')),
      number: 6,
      requestMsg: '',
      requestMsgText: '您还未输入文段',
      ner: null,
      input_describe: '请输入文段，开始润色',
    }
    this.isPhoneLegal = debounce(this.isPhoneLegal, 1000)
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
  }
  componentWillUnmount() {
    localStorage.removeItem('props')
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = () => {
    if (document.getElementById('continuation')) {
      $('#continuation').css({
        height: $('#result').height() - 140,
      })
    }
  }

  onKeyDown(event) {
    sourceCancelClass()
    //console.log(event.keyCode, "键盘事件");
    if (event.shiftKey && event.keyCode === 13) {
      var e = this.state.TextAreaValue
      this.setState({
        TextAreaValue: e,
      })
    } else if (event.ctrlKey && event.keyCode === 13) {
      event.cancelBubble = true
      event.preventDefault()
      event.stopPropagation()
      event.returnValue = false
      this.setState({
        ParagraphContinuation: [],
        ContinuationLoding: true,
        requestMsg: '',
      })
      this.isPhoneLegal()
      return false
    } else if (event.keyCode === 13) {
      this.setState({
        ParagraphContinuation: [],
        ContinuationLoding: true,
        requestMsg: '',
      })
      event.cancelBubble = true
      event.preventDefault()
      event.stopPropagation()
      this.isPhoneLegal()
      event.returnValue = false
      return false
    }
  }
  onTextArea(value) {
    this.setState({
      TextAreaValue: value,
    })

    //console.log(value);
  }
  goGeneration() {
    this.setState({
      ContinuationLoding: true,
    })
    sourceCancelClass()
    this.isPhoneLegal()
  }
  isPhoneLegal = () => {
    this.setState({
      ParagraphContinuation: [],
      ContinuationLoding: true,
      requestMsg: '',
    })
    this.setRequest()
  }
  ChangeAbatch() {
    this.setState({
      ContinuationLoding: true,
      requestMsg: '',
    })
    setTimeout(() => {
      this.setRequest()
    }, 1000)
  }
  setRequest() {
    let params = {
      app_id: this.state.record.app_id,
      text: this.state.TextAreaValue,
      num: this.state.number,
      share_id: this.state.share_id,
    }
    if (this.state.optionVlue) {
      params.version_id = this.state.optionVlue
    }
    shareAppApi(params).then((res) => {
      if (res.code === 200) {
        let list = res.data
        setTimeout(() => {
          this.setState({
            ner: list.ner,
            ParagraphContinuation: list.generations
              ? this.uniqueList(list.generations)
              : [],
            ContinuationLoding: false,
            requestMsg: '',
          })
          if (this.uniqueList(list.generations).length === 0) {
            this.setState({
              ParagraphContinuation: [],
              ContinuationLoding: false,
              requestMsg: '',
              requestMsgText: '请换个内容试试',
            })
          }
        }, 100)
        //console.log(res.data, "结果");
      } else if (res.code === 400 || res.code === 3003) {
        this.setState({
          ParagraphContinuation: [],
          ContinuationLoding: false,
          requestMsg: '',
          requestMsgText: res.msg,
        })
      } else if (res.code === 204) {
        this.setState({
          ParagraphContinuation: [],
          ContinuationLoding: false,
          requestMsg: '',
          requestMsgText: '请换个内容试试',
        })
      } else {
        this.setState({
          ParagraphContinuation: [],
          ContinuationLoding: false,
          requestMsg: 'error',
          requestMsgText: res.msg,
        })
      }
    })
  }

  // 根据下标替换字符
  oneReplace(str, index) {
    let arr = str.split('')
    return arr[index]
  }
  lengthReplace(text, start, stop) {
    let newstr = text.substring(start, stop + 1)
    return newstr
  }
  replaceAllText(newstr) {
    let nerlist = this.state.ner
    let oldnewstr = newstr
    Object.keys(nerlist ? nerlist : []).map((item, i) => {
      nerlist[item].map((key, index) => {
        let replaceText = key[0]
        // if (key[0].length === 1) {
        //   replaceText = this.oneReplace(oldnewstr, key[1][0])
        // } else {
        //   let endindex = key[1].length - 1
        //   let end = key[1][endindex] //结束下标
        //   let start = key[1][0] //开始小标
        //   replaceText = this.lengthReplace(oldnewstr, start, end)
        // }
        let rehtml = `<span style='color: #406EFF'>` + replaceText + `</span>`
        // let reg = key[0].toLowerCase()
        // var flagnew = key[0].replace(/[^a-z\d]/ig, "");
        // if (flagnew != null) {
        newstr = newstr.replaceAll(new RegExp(replaceText, 'ig'), rehtml)
        // }
      })
    })

    let html = { __html: newstr.replaceAll('\n', '<br/>') }
    return <div dangerouslySetInnerHTML={html}></div>
  }
  // 数组中对象的某个属性值进行去重
  uniqueList = (arr) => {
    const res = new Map()
    return arr.filter((a) => !res.has(a.sentence) && res.set(a.sentence, 1))
  }

  oncopy(item) {
    let value = item.sentence
    if (copy(value)) {
      Notification.success({ content: '复制成功!' })
    } else {
      Notification.error({ content: '复制失败!' })
    }
  }

  render() {
    const {
      input_describe,
      ParagraphContinuation,
      ContinuationLoding,
      TextAreaValue,

      requestMsg,
      requestMsgText,
    } = this.state
    return (
      <div className="embroidery">
        <Layout className="semanticprototype-Layout" id="layout_id">
          <Sider className="Sider-left">
            <div className="Sider-left-configure columnSpaceBetween">
              <div className="Continuation-input">
                <TextArea
                  value={TextAreaValue}
                  placeholder={input_describe}
                  style={{ width: '100%' }}
                  onChange={this.onTextArea.bind(this)}
                  onKeyDown={(event) => this.onKeyDown(event)}
                />
              </div>
              <div className="start-generation">
                <Button type="primary" onClick={this.goGeneration.bind(this)}>
                  开始润色
                </Button>
              </div>
            </div>
          </Sider>
          <Content>
            <div className="experience-result" id="result">
              <div id="result_title" className="rowSpaceBetween result_title">
                <Typography>生成结果</Typography>
                <Typography.Paragraph className="result-remarks">
                  (内容基于韦尼克AI创作引擎生成，仅供参考)
                </Typography.Paragraph>
              </div>
              {ParagraphContinuation.length > 0 || ContinuationLoding ? (
                <div className="Paragraph-generate">
                  {ContinuationLoding ? (
                    <Empty
                      className="Continuation-loding"
                      icon={<img src={Generating_img} alt="" />}
                      description={
                        <Typography
                          style={{
                            color: '#4e5969',
                            marginTop: 21,
                            fontSize: 16,
                          }}
                        >
                          正在生成中...
                        </Typography>
                      }
                    />
                  ) : (
                    <div className="Continuation-result">
                      <div
                        className="Continuation-list"
                        id="continuation"
                        style={{ height: $('#result').height() - 140 }}
                      >
                        {ParagraphContinuation.map((item, index) => {
                          return (
                            <div
                              className="Continuation-item"
                              key={index}
                              onClick={this.oncopy.bind(this, item)}
                            >
                              {this.replaceAllText(item.sentence)}
                            </div>
                          )
                        })}
                      </div>
                      <Button
                        icon={<IconSync />}
                        id="iconSync-btn"
                        className="IconSync-btn custom-White-border-button"
                        onClick={this.ChangeAbatch.bind(this)}
                      >
                        换一批
                      </Button>
                    </div>
                  )}
                </div>
              ) : requestMsg === 'error' ? (
                <Empty
                  className="Continuation-Empty Empty-error"
                  imgSrc={errorIcon}
                  description={
                    <Space direction="vertical">
                      <Typography
                        style={{
                          color: '#4e5969',
                          marginTop: 30,
                          fontSize: 16,
                        }}
                      >
                        抱歉，服务器生成错误
                      </Typography>
                      <Typography style={{ color: '#4e5969', fontSize: 16 }}>
                        可尝试
                        <span
                          style={{ color: '#406EFF', cursor: 'pointer' }}
                          onClick={this.ChangeAbatch.bind(this)}
                        >
                          {' '}
                          重新生成
                        </span>
                      </Typography>
                    </Space>
                  }
                />
              ) : (
                <Empty
                  className="Continuation-Empty Empty-none"
                  imgSrc={Emptydata_img}
                  description={
                    <Space direction="vertical">
                      <Typography
                        style={{
                          color: '#4e5969',
                          marginTop: 30,
                          fontSize: 16,
                        }}
                      >
                        暂无生成结果
                      </Typography>
                      <Typography style={{ color: '#4e5969', fontSize: 16 }}>
                        {requestMsgText}
                      </Typography>
                    </Space>
                  }
                />
              )}
            </div>
          </Content>
        </Layout>
      </div>
    )
  }
}
export default Embroidery
