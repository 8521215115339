import React from "react";
import { useRoutes } from "react-router-dom";
import routes from "./router/index";
import "./common/style/frame.scss";
import "./common/utils/em.js";
import "@arco-design/web-react/dist/css/arco.css";
import "default-passive-events";
export default function App() {
  const element = useRoutes(routes);
  return <>{element}</>;
}
