import React, { Component } from "react";
import { Modal, Typography } from "@arco-design/web-react";
import "./index.scss";

class Upgrade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: props.visible,
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ visible: nextProps.visible });
  }

  render() {
    const { visible } = this.state;
    return (
      <Modal
        title="联系客服"
        visible={visible}
        footer={null}
        onCancel={() => this.props.onCancel()}
        className="UpgradeModal"
      >
        <div className="rowSpaceBetween">
          <div style={{ textAlign: "center", color: "#0A1629" }}>
            <div className="serviceQRcode"></div>
            <Typography>请添加专属客服</Typography>
            <Typography>为你升级相关业务</Typography>
          </div>
          <div className="Upgrade-img">
            {/* <Typography.Title heading={4}>韦尼克企业尊享版</Typography.Title>
            <Typography.Paragraph>即接即用，用场景去释放AI创作的商业价值</Typography.Paragraph> */}
          </div>
        </div>
      </Modal>
    );
  }
}

export default Upgrade;
