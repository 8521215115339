import React, { Component } from "react";
import {
  Button,
  Statistic,
  Spin,
  List,
  Empty,
  Typography,
  Link,
  Tag,
  Modal,
  Notification,
} from "@arco-design/web-react";

import { allpackage, postopenpackage } from "api/apis";
import { getRgba } from "common/utils";
import history from "common/utils/history";
import Emptydata_img from "asserts/Emptydata_img.svg";
import "./index.scss";
class Engine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingbody: true,
      isautomatic: false,
      data: {},
      pagination: {
        sizeCanChange: false,
        showTotal: true,
        total: 0,
        pageSize: 10,
        current: 1,
        pageSizeChangeResetCurrent: true,
        showJumper: false,
      },
      scrollLoading: <Spin loading={true} />,
      loading: false,
      no_data: false,
      maxHeight: 549,
      productstyle: JSON.parse(localStorage.getItem("productstyle")),
      visible: false,
      opentype: "",
    };
  }
  componentDidMount() {
    this.getenginepackage();
  }
  UNSAFE_componentDidUpdate() {}
  getenginepackage() {
    allpackage().then((res) => {
      if (res.code === 200) {
        let list = res.data;
        if (list) {
          this.setState({
            data: list,
            loadingbody: false,
            no_data: false,
          });
        } else {
          this.setState({
            loadingbody: false,
            no_data: true,
          });
        }
      } else {
        this.setState({
          loadingbody: false,
          no_data: true,
        });
      }
    });
  }
  optionColor(color, val) {
    return color ? getRgba(color, "string", val) : "#ffffff";
  }
  iconsrc(icon) {
    return "url(" + icon + ")";
  }
  onpostopenpackage() {
    postopenpackage({ model_capability: this.state.model_capability }).then(
      (res) => {
        if (res.code === 200) {
          Notification.success({ content: "开通服务成功!" });
          this.getenginepackage();
        } else {
          Notification.warning({ content: res.msg });
        }
        this.setVisible(false);
      }
    );
  }
  setVisible(val) {
    this.setState({
      visible: val,
    });
  }
  onCancel() {
    this.setState({
      visible: false,
    });
  }
  onClickBtn(res) {
    if (res.status === 0) {
      this.setState({
        opentype: res.label,
        model_capability: res.id,
      });
      this.setVisible(true);
    } else {
      this.onPromotion(res);
    }
  }
  onPromotion(res) {
    history.push("/promotion");
    localStorage.setItem("record", JSON.stringify(res));
    history.go();
  }
  render() {
    const { loadingbody, productstyle, data, no_data, visible, opentype } =
      this.state;
    return (
      <div className="engine-services">
        {loadingbody ? (
          <Spin loading={loadingbody} dot className="loadingbody"></Spin>
        ) : (
          <>
            <div className="addpadding mag">
              <div className="Header-init">
                <div className="Header-title">
                  引擎服务
                  <Link
                    className="charging"
                    href="/engineservices/billingmethod"
                  >
                    查看计费方式
                  </Link>
                </div>
              </div>
              <div className="engine-banner rowCenter">
                <div className="engine-banner-box"></div>
                <div className="engine-banner-box"></div>

                <div className="engine-banner-text">
                  <h2>WERNICKE创作平台独家引擎服务</h2>
                  <p>一键开通服务，立即享受创作权益</p>
                  <div className="banner_img1"></div>
                  <div className="banner_img2"></div>
                </div>
              </div>
            </div>
            {no_data ? (
              <>
                <Empty
                  style={{ marginTop: 93 }}
                  imgSrc={Emptydata_img}
                  description={
                    <div className="Emptydata">
                      <Typography>暂时没有开发引擎服务</Typography>
                    </div>
                  }
                />
              </>
            ) : (
              <div className="addpadding">
                {Object.keys(data).map((item, i) => {
                  let styleobj = productstyle;
                  return (
                    <div className="services-package" key={i}>
                      <div className="addpadding">
                        <div
                          className="services-title AlibabaPuHuiTi-Medium"
                          style={{
                            "--backgroundImage":
                              " url(" + styleobj[item]?.banner + ")",
                          }}
                        >
                          {item}
                        </div>
                      </div>
                      <div className="services-package-ul">
                        {data[item]?.map((item2, index) => {
                          return (
                            <List.Item
                              key={index}
                              onClick={() => this.onClickBtn(item2)}
                            >
                              <div className="engine-resources">
                                <div
                                  className="resources"
                                  style={{
                                    borderColor: styleobj[item]?.color,
                                    "--gradientcolor":
                                      styleobj[item]?.gradientcolor,
                                  }}
                                >
                                  <div className="resources-box">
                                    {item2.is_free ? (
                                      <Statistic
                                        extra={item2.label}
                                        value="限时免费"
                                        groupSeparator
                                      />
                                    ) : (
                                      <Statistic
                                        extra={item2.label}
                                        value={
                                          item2.status === 0
                                            ? item2.basis_free
                                            : item2.opened_package.free
                                        }
                                        groupSeparator
                                        precision={0}
                                        suffix={item2.unit}
                                      />
                                    )}
                                    <div className="resources-type rowFlexEnd">
                                      {/* <span
                                        style={{
                                          color: styleobj[item].textcolor,
                                        }}
                                      >
                                        #{item}
                                      </span> */}
                                      <div
                                        className="resources-icon"
                                        style={{
                                          "--backgroundImage": this.iconsrc(
                                            item2.label_icon
                                          ),
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                  {item2.status && item2.status > 0 ? null : (
                                    <div className="resources-mantles">
                                      <div className="tag-mode-container">
                                        <div
                                          className="tag"
                                          style={{
                                            background: styleobj[item]?.color,
                                          }}
                                        >
                                          <span>未开通</span>
                                        </div>
                                      </div>
                                      <div className="resources-mantle"></div>
                                      <Tag
                                        style={{
                                          "--bgColor": this.optionColor(
                                            styleobj[item]?.color,
                                            1
                                          ),
                                          "--bgColorhover": this.optionColor(
                                            styleobj[item]?.color,
                                            0.7
                                          ),
                                          "--bgColordefault": this.optionColor(
                                            styleobj[item]?.color,
                                            0.9
                                          ),
                                        }}
                                        className="mantle-tag"
                                      >
                                        开通服务
                                      </Tag>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </List.Item>
                          );
                        })}
                      </div>
                      {/* <List
                        grid={{
                          sm: 24,
                          md: 12,
                          lg: 8,
                          xl: 6,
                        }}
                        bordered={false}
                        dataSource={data[item]}
                        render={(item2, index) => (
                          <List.Item
                            key={index}
                            onClick={() => this.onClickBtn(item2)}
                          >
                            <div className="engine-resources">
                              <div
                                className="resources"
                                style={{
                                  borderColor: styleobj[item].color,
                                  "--gradientcolor":
                                    styleobj[item].gradientcolor,
                                }}
                              >
                                <div className="resources-box">
                                  <div className="resources-title rowSpaceBetween AlibabaPuHuiTi-Medium">
                                    {item2.label}
                                    {item2.status && item2.status > 0 ? (
                                      <Tag
                                        icon={
                                          <IconCheck
                                            style={{
                                              "--tagColor":
                                                styleobj[item].textcolor,
                                            }}
                                          />
                                        }
                                        style={{
                                          border: 0,
                                          "--gradienticoncolor":
                                            styleobj[item].gradienticoncolor,
                                          "--tagColor":
                                            styleobj[item].textcolor,
                                        }}
                                      >
                                        已开通
                                      </Tag>
                                    ) : null}
                                  </div>
                                  {item2.is_free ? (
                                    <Statistic
                                      extra={null}
                                      value="限时免费"
                                      groupSeparator
                                    />
                                  ) : (
                                    <Statistic
                                      extra={
                                        item2.status === 0 ||
                                        item2.opened_package.balance === 0
                                          ? "免费可用次数"
                                          : "剩余可用次数"
                                      }
                                      value={
                                        item2.status === 0
                                          ? item2.basis_free
                                          : item2.opened_package.free
                                      }
                                      groupSeparator
                                      precision={0}
                                      suffix={item2.unit}
                                    />
                                  )}
                                  <div className="resources-type GroupTable-fixe-end">
                                    <span
                                      style={{
                                        color: styleobj[item].textcolor,
                                      }}
                                    >
                                      #{item}
                                    </span>
                                    <div
                                      className="resources-icon"
                                      style={{
                                        "--backgroundImage": this.iconsrc(
                                          item2.label_icon
                                        ),
                                      }}
                                    ></div>
                                  </div>
                                </div>
                                {item2.status && item2.status > 0 ? null : (
                                  <div className="resources-mantles">
                                    <div className="resources-mantle"></div>
                                    <Tag
                                      style={{
                                        "--bgColor": this.optionColor(
                                          styleobj[item].color,
                                          1
                                        ),
                                        "--bgColorhover": this.optionColor(
                                          styleobj[item].color,
                                          0.7
                                        ),
                                        "--bgColordefault": this.optionColor(
                                          styleobj[item].color,
                                          0.9
                                        ),
                                      }}
                                      className="mantle-tag"
                                    >
                                      开通服务
                                    </Tag>
                                  </div>
                                )}
                              </div>
                            </div>
                          </List.Item>
                        )}
                      /> */}
                    </div>
                  );
                })}
              </div>
            )}
            <Modal
              title="开通引擎服务"
              visible={visible}
              onCancel={() => this.onCancel()}
              footer={null}
              className="custom-modal open-engine"
            >
              <div className="custom-title AlibabaPuHuiTi-Medium">
                你即将开通韦尼克创作平台的
                <span className="AlibabaPuHuiTi-Medium">{opentype}</span> 服务
              </div>
              <div>
                你可以通过引擎训练你的内容创作模型，创建你的内容创建应用
              </div>
              <div>
                开通服务后，你可享用一定额度的免费资源包，后续可通过资源包购买增加资源权限
              </div>
              <Button type="primary" onClick={() => this.onpostopenpackage()}>
                开通服务
              </Button>
            </Modal>
          </>
        )}
      </div>
    );
  }
}
export default Engine;
