import React, { Component } from 'react'
import {
  Empty,
  Grid,
  DatePicker,
  Radio,
  Space,
  Card,
  Typography,
  Divider,
  Link,
  Input,
  Button,
  Select,
  Message,
  Layout,
  Tabs,
  Form,
  Descriptions,
  Upload,
  Notification,
  InputNumber,
  Spin,
} from '@arco-design/web-react'
import { IconCopy, IconUpload, IconDelete } from '@arco-design/web-react/icon'
import Emptydata_img from 'asserts/Emptydata_img.svg'
import copy from 'copy-to-clipboard'
import history from 'common/utils/history'
import upload from 'api/uploads'
import { getParams, postDebug, check_resource } from 'api/apis'
import ALLURL from 'envconfig/config'
import './interfacetest.scss'
import UpgradeTips from 'components/UpgradeTips'
const FormItem = Form.Item
const RadioGroup = Radio.Group
const Row = Grid.Row
const Col = Grid.Col
const RangePicker = DatePicker
const InputSearch = Input.Search
const Sider = Layout.Sider
const Content = Layout.Content
const TextArea = Input.TextArea
const TabPane = Tabs.TabPane

class Interfacetest extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loadingbody: true,
      SearchValue: ALLURL.baseURL + '/v2/api/request',
      Tablist: ['接口测试', '参数说明'],
      type: '接口测试',
      userinfo: JSON.parse(localStorage.getItem('userinfo')),
      fileList: null,
      attachment: [],
      record: JSON.parse(localStorage.getItem('record')),
      business_parameters: [],
      global_params: {},
      initialValues: {},
      visibleUpgrade: false,
      upgradetips: null,
      Specification: [
        {
          key: 'AccessSecret',
          required: true,
          type: 'text',
          desc: '授权令牌',
        },
        {
          key: 'AccessToken',
          required: true,
          type: 'text',
          desc: '授权密钥',
        },
        {
          key: 'app_id',
          required: true,
          type: 'text',
          desc: '应用id',
        },
      ],
      SpecificationList: [],
      RequestResponse: null,
      model_capability_name: '',
    }
    this.formRef = React.createRef()
  }
  handleResize = () => {
    var winHeight = 0
    if (window.innerHeight) {
      winHeight = window.innerHeight
    } else if (document.body && document.body.clientHeight) {
      winHeight = document.body.clientHeight
    }
    if (document.documentElement && document.documentElement.clientHeight) {
      winHeight = document.documentElement.clientHeight
    }
    let layoutHeaders_h = document.getElementById('layoutHeaders').clientHeight
    if (layoutHeaders_h > winHeight) {
      layoutHeaders_h = 64
    }
    const HeaderInitHeight = document.getElementById('Header_init')
      ? document.getElementById('Header_init').clientHeight
      : 0
    const hedSearchHeight = document.getElementById('hedSearch')
      ? document.getElementById('hedSearch').clientHeight
      : 0
    let height =
      winHeight - layoutHeaders_h - HeaderInitHeight - hedSearchHeight - 80
    if (document.getElementById('order_id')) {
      document.getElementById('order_id').style.height = height + 'px'
    }
  }
  componentDidMount() {
    this.getInterfaceParameter()

    window.addEventListener('resize', this.handleResize)
    setTimeout(() => {
      this.handleResize()
    }, 300)
  }
  componentWillUnmount() {
    localStorage.removeItem('props')
    window.removeEventListener('resize', this.handleResize)
  }
  booWho(bool) {
    return typeof bool === 'boolean'
  }
  ArrayConversion() {
    const { Specification, SpecificationList } = this.state
    Specification.map((item, i) => {
      const Preset_Data = [
        {
          label: '必填',
          value: item.required ? '是' : '否',
        },
        {
          label: '类型',
          value: item.type,
        },
        {
          label: '描述',
          value: item.desc,
        },
      ]
      let Specification_obj = {
        data: Preset_Data,
        title: item.key,
      }
      SpecificationList.push(Specification_obj)
    })
    this.setState({
      SpecificationList: SpecificationList,
    })
  }
  setInitialValues(arr) {
    let newArr = {}
    if (arr) {
      arr.map((item, i) => {
        if (item.key) {
          newArr[item.key] = item.default
          newArr[item.key] = String(item.default)
        }
      })
      return newArr
    }
  }
  getInterfaceParameter() {
    const { record, Specification } = this.state
    let params = {
      app_id: record.id,
    }
    getParams(params).then((res) => {
      if (res.code === 200) {
        if (res.data) {
          this.setState({
            business_parameters: res.data.business_params,
            global_params: res.data.global_params,
            SearchValue: res.data.interface_url,
            initialValues: this.setInitialValues(res.data.business_params),
            Specification: Specification.concat(res.data.business_params),
            model_capability_name: res.data.label,
            loadingbody: false,
          })
          this.formRef.setFieldsValue(
            this.setInitialValues(res.data.business_params)
          )
          this.ArrayConversion()
          setTimeout(() => {
            this.handleResize()
          }, 100)
        }
      } else {
        Notification.warning({ content: res.msg })
        this.setState({
          loadingbody: false,
        })
      }
    })
  }
  postDebug_Params(params) {
    postDebug(params).then((res) => {
      if (res.code === 200) {
        this.setState({
          RequestResponse: res.data,
        })
      } else {
        Notification.warning({ content: res.msg })
      }
    })
  }
  getFormRefFieldsValue() {
    let data = this.formRef.getFieldsValue()
    // ES6-使用js删除对象中带有null和undefined值的数据
    let obj = Object.keys(data)
      .filter(
        (key) =>
          data[key] !== null &&
          data[key] !== undefined &&
          data[key] !== '' &&
          data[key].length !== 0
      )
      .reduce((acc, key) => ({ ...acc, [key]: data[key] }), {})
    return obj
  }
  formRefDate() {
    const { record } = this.state
    // ES6-使用js删除对象中带有null和undefined值的数据
    let obj = this.getFormRefFieldsValue()
    obj.app_id = record.id
    let params = obj
    console.log(obj)
    this.postDebug_Params(params)
  }
  onsetPosition(value) {
    this.setState({
      type: value,
    })
    this.state.type = value
  }
  oncopy(value) {
    if (copy(value)) {
      Notification.success({ content: '复制成功!' })
    } else {
      Notification.error({ content: '复制失败!' })
    }
  }
  handleUpload(option) {
    upload(option)
  }
  setFile(Item, fileList, file) {
    this.setState({
      fileList: fileList,
    })
    if (file.status === 'done') {
      const newValues = this.getFormRefFieldsValue()
      let key = []
      key.push(file.response.fileKey)
      newValues[Item.key] = file.response.url
      this.setState({
        attachment: key,
        initialValues: newValues,
      })
      this.formRef.setFieldsValue(newValues)
    }
  }
  onIconDelete(Item) {
    const newValues = this.getFormRefFieldsValue()
    newValues[Item.key] = ''
    this.setState({
      fileList: null,
      attachment: [],
      initialValues: newValues,
    })
    this.formRef.setFieldsValue(newValues)
  }
  gomanagement() {
    history.push('/enginefactory/model/management')
    window.location.reload()
  }
  setTablist = () => {
    const { SpecificationList, type, RequestResponse } = this.state
    return type === '接口测试' ? (
      <div className="Tabsbody-ul">
        <div className="tabli">
          <Typography.Text>请求信息</Typography.Text>
          {RequestResponse?.header ? (
            <div className="tabText">
              <div className="tabText-overflow">
                <div>请求方法：{RequestResponse?.header.method}</div>
                <div>请求url：{RequestResponse?.header.url}</div>
                <div>请求header：</div>
                {Object.keys(RequestResponse.header.header).map((item, i) => {
                  return (
                    <div key={i}>
                      <span>{item}:</span>
                      <span>{RequestResponse.header.header[item]}</span>
                    </div>
                  )
                })}
              </div>
            </div>
          ) : (
            <Empty
              className="tabli-none"
              imgSrc={Emptydata_img}
              description={
                <Space direction="vertical">
                  <Typography
                    style={{ color: '#4e5969', marginTop: 30, fontSize: 16 }}
                  >
                    输入参数，获取请求信息
                  </Typography>
                </Space>
              }
            />
          )}
        </div>
        <div className="tabli">
          <Typography.Text>响应数据</Typography.Text>
          {RequestResponse ? (
            <div className="tabText">
              <div
                className="tabText-overflow"
                dangerouslySetInnerHTML={{
                  __html: JSON.stringify(RequestResponse.data),
                }}
              ></div>
            </div>
          ) : (
            <Empty
              className="tabli-none"
              imgSrc={Emptydata_img}
              description={
                <Space direction="vertical">
                  <Typography
                    style={{ color: '#4e5969', marginTop: 30, fontSize: 16 }}
                  >
                    输入参数，获取响应数据
                  </Typography>
                </Space>
              }
            />
          )}
        </div>
      </div>
    ) : type === '签名规范' ? (
      <div>
        <div className="tabli">
          <Typography.Text>js实现版</Typography.Text>
          <Empty
            className="tabli-none"
            imgSrc={Emptydata_img}
            description={
              <Space direction="vertical">
                <Typography
                  style={{ color: '#4e5969', marginTop: 30, fontSize: 16 }}
                >
                  输入参数，获取请求信息
                </Typography>
              </Space>
            }
          />
        </div>
        <div className="tabli">
          <Typography.Text>php实现版</Typography.Text>
          <Empty
            className="tabli-none"
            imgSrc={Emptydata_img}
            description={
              <Space direction="vertical">
                <Typography
                  style={{ color: '#4e5969', marginTop: 30, fontSize: 16 }}
                >
                  输入参数，获取请求信息
                </Typography>
              </Space>
            }
          />
        </div>
      </div>
    ) : type === '参数说明' ? (
      <div className="description">
        {SpecificationList.map((item, i) => {
          return (
            <Descriptions
              key={i}
              column={1}
              colon=" :"
              layout="inline-horizontal"
              title={item.title}
              data={item.data}
            />
          )
        })}
      </div>
    ) : null
  }
  getCheckResource() {
    let params = { model_capability: this.state.record?.model_capability }
    let PACKAGES = ['PACKAGE_FINISH', 'PACKAGE_EXPIRED']
    check_resource(params).then((res) => {
      if (res.code === 200) {
        if (res.data) {
          let new_upgradetips = res.data
          new_upgradetips.model_capability = params.model_capability
          this.setState({
            upgradetips: new_upgradetips,
          })
          if (PACKAGES[new_upgradetips.notice_type]) {
            Notification.warning({ content: new_upgradetips.message })
          } else {
            setTimeout(() => {
              this.setState({
                visibleUpgrade: true,
              })
            }, 100)
          }
        } else {
          this.formRefDate()
        }
      } else {
        Notification.warning({ content: res.msg })
      }
    })
  }
  setvisibleUpgrade(value) {
    this.setState({
      visibleUpgrade: value,
    })
  }
  openWiki() {
    window.open(
      'https://wiki.yoo-ai.com/#/wernicke/api/?id=' +
        this.state.model_capability_name
    )
  }
  render() {
    const {
      loadingbody,
      Tablist,
      SearchValue,
      type,
      userinfo,
      fileList,
      url,
      business_parameters,
      global_params,
      initialValues,
      visibleUpgrade,
      upgradetips,
      record,
    } = this.state
    return (
      <div className="interfacetest">
        {loadingbody ? (
          <Spin loading={loadingbody} dot className="loadingbody"></Spin>
        ) : (
          <>
            <div className="Header-init" id="Header_init">
              <div className="Header-title">接口调用</div>
            </div>
            <div className="GroupTable-fixe" id="hedSearch">
              <Radio.Group
                type="button"
                name="direction"
                value={type}
                onChange={this.onsetPosition.bind(this)}
                options={Tablist}
                className="GroupTable"
              ></Radio.Group>
              <div>
                <Space size={'large'}>
                  <Space align={'center'}>
                    <Typography.Text className="interfacetest-fixetitle">
                      请求方法：POST
                    </Typography.Text>
                    <div className="interfacetest-fixetitle interfacetest-api">
                      接口地址：
                      <Typography.Paragraph copyable>
                        {SearchValue}
                      </Typography.Paragraph>
                    </div>
                  </Space>
                  <Space align={'center'} size={'medium'}>
                    <Button
                      shape="round"
                      className="primary-white custom-White-button"
                      onClick={this.openWiki.bind(this)}
                    >
                      API文档
                    </Button>
                  </Space>
                </Space>
              </div>
            </div>
            <div id="order_id">
              <Layout style={{ height: '100%' }}>
                <Content
                  className={`Work-Content ${
                    type === `接口测试` ? `` : `set-margins`
                  }`}
                >
                  <div className="Tabsbody">{this.setTablist()}</div>
                </Content>
                <Sider className="Sider-left">
                  <div className="debugg-sider">
                    {/* 暂不开放 */}
                    {/* <div className="rowSpaceBetween">
                  <Typography.Text className="debugg-title">
                    模型版本
                  </Typography.Text>
                  <Button
                    type="outline"
                    className="outline"
                    onClick={this.gomanagement.bind(this)}
                  >
                    模型管理
                  </Button>
                </div> */}
                    <div className="debugg-sider-input tabText-overflow">
                      <Form
                        style={{ width: '100%' }}
                        ref={(ref) => (this.formRef = ref)}
                        layout="vertical"
                        initialValues={initialValues}
                      >
                        {/* 暂不开放 */}
                        {/* <Form.Item label="版本" field="version">
                      <Select
                        allowClear
                        placeholder="请选择模型版本"
                        options={["1.v", "2.v"]}
                      ></Select>
                    </Form.Item> */}
                        <FormItem className="FormItem-debugg-title">
                          <div className="debugg-title">全局参数</div>
                        </FormItem>
                        <FormItem label="AccessSecret" field="access_secret">
                          <Space>
                            <Input
                              placeholder=""
                              value={global_params.access_secret}
                              readOnly
                              className="readOnly"
                            />
                            <Button
                              className="Form-btnicon"
                              icon={<IconCopy />}
                              onClick={this.oncopy.bind(
                                this,
                                global_params.access_secret
                              )}
                            />
                          </Space>
                        </FormItem>
                        <FormItem label="AccessToken" field="access_token">
                          <Space>
                            <Input
                              placeholder=""
                              value={global_params.access_token}
                              readOnly
                              className="readOnly"
                            />
                            <Button
                              className="Form-btnicon"
                              shape="circle"
                              icon={<IconCopy />}
                              onClick={this.oncopy.bind(
                                this,
                                global_params.access_token
                              )}
                            />
                          </Space>
                        </FormItem>
                        <FormItem label="app_id" field="app_id">
                          <Space>
                            <Input
                              placeholder=""
                              value={record.id}
                              readOnly
                              className="readOnly"
                            />
                            <Button
                              className="Form-btnicon"
                              shape="circle"
                              icon={<IconCopy />}
                              onClick={this.oncopy.bind(this, record.id)}
                            />
                          </Space>
                        </FormItem>
                        <FormItem className="FormItem-debugg-title">
                          <div className="debugg-title">业务参数</div>
                        </FormItem>
                        {business_parameters?.map((Item, Item_index) => {
                          return (
                            <div key={Item_index}>
                              {Item['type-backend'] === 'text' &&
                              Item.key !== 'fileKey' ? (
                                <>
                                  <FormItem label={Item.key} field={Item.key}>
                                    <Input placeholder={Item.desc} />
                                  </FormItem>
                                </>
                              ) : Item['type-backend'] === 'number' ? (
                                <>
                                  <FormItem label={Item.key} field={Item.key}>
                                    <InputNumber
                                      min={0}
                                      placeholder={Item.desc}
                                      hideControl
                                    />
                                  </FormItem>
                                </>
                              ) : Item['type-backend'] === 'select' ? (
                                <>
                                  <FormItem label={Item.key} field={Item.key}>
                                    <Select
                                      allowClear
                                      placeholder={Item.desc}
                                      options={Item.select_backend}
                                      getPopupContainer={(triggerNode) =>
                                        triggerNode.parentNode
                                      }
                                    ></Select>
                                  </FormItem>
                                </>
                              ) : Item['type-backend'] === 'text' &&
                                Item.key === 'fileKey' ? (
                                <Space>
                                  <Form.Item label={Item.key} field={Item.key}>
                                    <Input
                                      placeholder={Item.desc}
                                      readOnly
                                      className="readOnly"
                                    />
                                  </Form.Item>
                                  <div className="field_upload">
                                    {initialValues[Item.key] ? (
                                      <Button
                                        icon={<IconDelete />}
                                        className="Form-btnicon"
                                        onClick={this.onIconDelete.bind(
                                          this,
                                          Item
                                        )}
                                      />
                                    ) : (
                                      <Upload
                                        multiple
                                        limit="1"
                                        showUploadList={false}
                                        fileList={fileList}
                                        accept=""
                                        onChange={this.setFile.bind(this, Item)}
                                        customRequest={(option) => {
                                          this.handleUpload(option)
                                        }}
                                        onExceedLimit={() => {
                                          Message.warning(
                                            '超过上传数量限制！最多上传1个'
                                          )
                                        }}
                                      >
                                        <Button
                                          icon={<IconUpload />}
                                          className="Form-btnicon"
                                        />
                                      </Upload>
                                    )}
                                  </div>
                                </Space>
                              ) : Item['type-backend'] === 'textarea' ? (
                                <>
                                  <FormItem label={Item.key} field={Item.key}>
                                    <TextArea placeholder={Item.desc} />
                                  </FormItem>
                                </>
                              ) : null}
                            </div>
                          )
                        })}
                      </Form>
                    </div>
                  </div>
                  <div className="request_button">
                    <Divider />
                    <Button
                      type="primary"
                      className="Initiate-request"
                      onClick={this.getCheckResource.bind(this)}
                    >
                      发起请求
                    </Button>
                  </div>
                </Sider>
              </Layout>
            </div>
          </>
        )}
        <UpgradeTips
          visible={visibleUpgrade}
          notification_status={upgradetips?.notice_type}
          notification_message={upgradetips?.message}
          model_capability={upgradetips?.model_capability}
          onCancel={() => this.setvisibleUpgrade(false)}
        ></UpgradeTips>
      </div>
    )
  }
}

export default Interfacetest
