import React, { Component } from "react";
import {
  Button,
  Divider,
  Space,
  Message,
  Upload,
  Card,
  Select,
  Input,
  Alert,
  Checkbox,
  Link,
  Notification,
} from "@arco-design/web-react";
import {
  IconCaretDown,
  IconRight,
  IconDelete,
  IconArrowLeft,
} from "@arco-design/web-react/icon";
import "./index.scss";
import { enterpriseAdd, checkaudit } from "api/apis";
import { b2ValueUnit, getUrlToken } from "common/utils";
import ALLURL from "envconfig/config"; //自定义多环境api请求路径
import upload from "api/uploads";
import docx_icon from "asserts/docx_icon.png";
import pdf_icon from "asserts/pdf_icon.png";
class UserAuthentication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      failTitle: "审核失败",
      lodingTitle: "已提交资料升级企业账户，正在审核中",
      steps: ["提交企业认证信息", "提交法人认证信息", "提交审核"],
      showTable: false,
      current: 0,
      status: 0, //1 加载中；2 成功；3 失败
      certificate_image: [],
      fileList: [],
      legal_id_front: [],
      fileList2: [],
      fileList4: [],
      legal_id_back: [],
      fileList3: [],
      optionsType: [
        {
          label: "营业执照",
          value: "BUSINESS",
        },
      ],
      countryList: [
        { label: "中国", value: "China" },
        { label: "中国香港", value: "Hong Kong" },
        { label: "中国澳門", value: "Macau" },
        { label: "中国台湾", value: "Taiwan" },
        { label: "日本", value: "Japan" },
        { label: "韩国", value: "South Korea" },
        { label: "美国", value: "USA or Canada" },
        { label: "阿富汗", value: "Afghanistan" },
        { label: "阿尔巴尼亚", value: "Albania" },
        { label: "阿尔及利亚", value: "Algeria" },
        { label: "萨摩亚", value: "American Samoa" },
        { label: "安道尔共和国", value: "Andorra" },
        { label: "安哥拉", value: "Angola" },
        { label: "安圭拉岛", value: "Anguilla" },
        { label: "南极洲", value: "Antarctica" },
        { label: "安提瓜和巴布达", value: "Antigua and Barbuda" },
        { label: "阿根廷", value: "Argentina" },
        { label: "亚美尼亚", value: "Armenia" },
        { label: "阿鲁巴", value: "Aruba" },
        { label: "澳大利亚", value: "Australia" },
        { label: "奥地利", value: "Austria" },
        { label: "阿塞拜疆", value: "Azerbaijan" },
        { label: "巴哈马", value: "Bahamas" },
        { label: "巴林", value: "Bahrain" },
        { label: "孟加拉国", value: "Bangladesh" },
        { label: "巴巴多斯", value: "Barbados" },
        { label: "白俄罗斯", value: "Belarus" },
        { label: "比利时", value: "Belgium" },
        { label: "伯利兹城", value: "Belize" },
        { label: "贝宁", value: "Benin" },
        { label: "百慕大", value: "Bermuda" },
        { label: "不丹", value: "Bhutan" },
        { label: "玻利维亚", value: "Bolivia" },
        {
          label: "波斯尼亚和黑塞哥维那",
          value: "Bosnia and Herzegovina",
        },
        { label: "博茨瓦纳", value: "Botswana" },
        { label: "巴西", value: "Brazil" },
        {
          label: "英属印度洋领地",
          value: "British Indian Ocean Territory",
        },
        { label: "文莱达鲁萨兰国", value: "Brunei Darussalam" },
        { label: "保加利亚", value: "Bulgaria" },
        { label: "布基纳法索", value: "Burkina Faso" },
        { label: "布隆迪", value: "Burundi" },
        { label: "柬埔寨", value: "Cambodia" },
        { label: "喀麦隆", value: "Cameroon" },
        { label: "佛得角", value: "Cape Verde" },
        { label: "开曼群岛", value: "Cayman Islands" },
        { label: "中非共和国", value: "Central African Republic" },
        { label: "乍得", value: "Chad" },
        { label: "智利", value: "Chile" },
        { label: "圣延岛", value: "Christmas Island" },
        { label: "科科斯群岛", value: "Cocos (Keeling) Islands" },
        { label: "哥伦比亚", value: "Colombia" },
        { label: "科摩罗", value: "Comoros" },
        { label: "刚果", value: "Congo" },
        {
          label: "刚果民主共和国",
          value: "Congo,The Democratic Republic Of The",
        },
        { label: "库克群岛", value: "Cook Islands" },
        { label: "哥斯达黎加", value: "Costa Rica" },
        { label: "科特迪瓦 ()", value: "Cote D Ivoire" },
        { label: "克罗地亚 ()", value: "Croatia" },
        { label: "古巴 ()", value: "Cuba" },
        { label: "塞浦路斯 ()", value: "Cyprus" },
        { label: "捷克 ()", value: "Czech Republic" },
        { label: "丹麦 ()", value: "Denmark" },
        { label: "吉布提 ()", value: "Djibouti" },
        { label: "多米尼克国 ()", value: "Dominica" },
        { label: "多米尼加共和国 (Dominican Republic)", value: "1849" },
        { label: "东帝汶 (East Timor)", value: "670" },
        { label: "厄瓜多尔 (Ecuador)", value: "593" },
        { label: "埃及 (Egypt)", value: "20" },
        { label: "萨尔瓦多 (El Salvador)", value: "503" },
        { label: "赤道几内亚 (Equatorial Guinea)", value: "240" },
        { label: "爱沙尼亚 (Estonia)", value: "372" },
        { label: "埃塞俄比亚 (Ethiopia)", value: "251" },
        { label: "福克兰群岛(Falkland Islands (Malvinas))", value: "500" },
        { label: "法罗群岛 (Faroe Islands)", value: "298" },
        { label: "斐济 (Fiji)", value: "679" },
        { label: "芬兰 (Finland)", value: "358" },
        { label: "法国 (France)", value: "33" },
        { label: "法国大都会 (France Metropolitan)", value: "33" },
        { label: "法属圭亚那 (French Guiana)", value: "594" },
        { label: "法属玻里尼西亚 (French Polynesia)", value: "689" },
        { label: "加蓬 (Gabon)", value: "241" },
        { label: "冈比亚 (Gambia)", value: "220" },
        { label: "格鲁吉亚 (Georgia)", value: "995" },
        { label: "德国 (Germany)", value: "49" },
        { label: "加纳 (Ghana)", value: "233" },
        { label: "直布罗陀 (Gibraltar)", value: "350" },
        { label: "希腊 (Greece)", value: "30" },
        { label: "格陵兰 (Greenland)", value: "45" },
        { label: "格林纳达 (Grenada)", value: "1473" },
        { label: "瓜德罗普岛 (Guadeloupe)", value: "590" },
        { label: "关岛 (Guam)", value: "1671" },
        { label: "危地马拉 (Guatemala)", value: "502" },
        { label: "几内亚 (Guinea)", value: "224" },
        { label: "几内亚比绍 (Guinea-Bissau)", value: "245" },
        { label: "圭亚那 (Guyana)", value: "592" },
        { label: "海地 (Haiti)", value: "509" },
        { label: "洪都拉斯 (Honduras)", value: "504" },
        { label: "匈牙利 (Hungary)", value: "36" },
        { label: "冰岛 (Iceland)", value: "354" },
        { label: "印度 (India)", value: "91" },
        { label: "印度尼西亚 (Indonesia)", value: "62" },
        { label: "伊朗 (Iran (Islamic Republic of))", value: "98" },
        { label: "伊拉克 (Iraq)", value: "964" },
        { label: "爱尔兰 (Ireland)", value: "353" },
        { label: "以色列 (Israel)", value: "972" },
        { label: "意大利 (Italy)", value: "39" },
        { label: "牙买加 (Jamaica)", value: "1876" },
        { label: "约旦 (Jordan)", value: "962" },
        { label: "哈萨克 (Kazakhstan)", value: "7" },
        { label: "肯尼亚 (Kenya)", value: "254" },
        { label: "科威特 (Kuwait)", value: "965" },
        { label: "吉尔吉斯 (Kyrgyzstan)", value: "996" },
        { label: "拉脱维亚 (Latvia)", value: "371" },
        { label: "黎巴嫩 (Lebanon)", value: "961" },
        { label: "莱索托 (Lesotho)", value: "266" },
        { label: "利比里亚 (Liberia)", value: "231" },
        { label: "利比亚 (Libyan Arab Jamahiriya)", value: "218" },
        { label: "列支敦士登 (Liechtenstein)", value: "423" },
        { label: "立陶宛 (Lithuania)", value: "370" },
        { label: "卢森堡 (Luxembourg)", value: "352" },
        { label: "马达加斯加 (Madagascar)", value: "261" },
        { label: "马拉维 (Malawi)", value: "265" },
        { label: "马来西亚 (Malaysia)", value: "60" },
        { label: "马尔代夫 (Maldives)", value: "960" },
        { label: "马里 (Mali)", value: "223" },
        { label: "马尔他 (Malta)", value: "356" },
        { label: "马提尼克岛 (Martinique)", value: "596" },
        { label: "毛里塔尼亚 (Mauritania)", value: "222" },
        { label: "毛里求斯(Mauritius)", value: "230" },
        { label: "马约特 (Mayotte)", value: "262" },
        { label: "墨西哥 (Mexico)", value: "52" },
        { label: "密克罗尼西亚 (Micronesia)", value: "691" },
        { label: "摩尔多瓦 (Moldova)", value: "373" },
        { label: "摩纳哥 (Monaco)", value: "377" },
        { label: "外蒙古 (Mongolia)", value: "976" },
        { label: "黑山共和国 (Montenegro)", value: "382" },
        { label: "蒙特塞拉特 (Montserrat)", value: "1664" },
        { label: "摩洛哥 (Morocco)", value: "212" },
        { label: "莫桑比克 (Mozambique)", value: "258" },
        { label: "缅甸 (Myanmar)", value: "95" },
        { label: "那米比亚 (Namibia)", value: "264" },
        { label: "瑙鲁 (Nauru)", value: "674" },
        { label: "尼泊尔 (Nepal)", value: "977" },
        { label: "荷兰 (Netherlands)", value: "31" },
        { label: "荷兰安的列斯群岛 (Netherlands Antilles)", value: "599" },
        { label: "新喀里多尼亚 (New Caledonia)", value: "687" },
        { label: "新西兰 (New Zealand)", value: "64" },
        { label: "尼加拉瓜 (Nicaragua)", value: "505" },
        { label: "尼日尔 (Niger)", value: "227" },
        { label: "尼日利亚 (Nigeria)", value: "234" },
        { label: "诺福克岛 (Norfolk Island)", value: "6723" },
        { label: "朝鲜 (North Korea)", value: "850" },
        {
          label: "北马里亚纳群岛 (Northern Mariana Islands)",
          value: "1670",
        },
        { label: "挪威 (Norway)", value: "47" },
        { label: "阿曼 (Oman)", value: "968" },
        { label: "巴基斯坦 (Pakistan)", value: "92" },
        { label: "帛琉 (Palau)", value: "680" },
        { label: "巴勒斯坦 (Palestine)", value: "970" },
        { label: "巴拿马 (Panama)", value: "507" },
        { label: "巴布亚新几内亚 (Papua New Guinea)", value: "675" },
        { label: "巴拉圭 (Paraguay)", value: "595" },
        { label: "秘鲁 (Peru)", value: "51" },
        { label: "菲律宾共和国 (Philippines)", value: "63" },
        { label: "皮特凯恩岛 (Pitcairn)", value: "64" },
        { label: "波兰 (Poland)", value: "48" },
        { label: "葡萄牙 (Portugal)", value: "351" },
        { label: "波多黎各 (Puerto Rico)", value: "1787" },
        { label: "卡塔尔 (Qatar)", value: "974" },
        { label: "留尼汪岛 (Reunion)", value: "262" },
        { label: "罗马尼亚 (Romania)", value: "40" },
        { label: "俄罗斯联邦 (Russian Federation)", value: "7" },
        { label: "卢旺达 (Rwanda)", value: "250" },
        { label: "美属萨摩亚 (Samoa)", value: "685" },
        { label: "圣马力诺共和国 (San Marino)", value: "378" },
        { label: "沙特阿拉伯 (Saudi Arabia)", value: "966" },
        { label: "塞内加尔 (Senegal)", value: "221" },
        { label: "塞尔维亚共和国 (Serbia)", value: "381" },
        { label: "塞舌尔 (Seychelles)", value: "248" },
        { label: "塞拉利昂 (Sierra Leone)", value: "232" },
        { label: "新加坡 (Singapore)", value: "65" },
        { label: "斯洛伐克 (Slovakia (Slovak Republic))", value: "421" },
        { label: "斯洛文尼亚 (Slovenia)", value: "386" },
        { label: "索罗门群岛 (Solomon Islands)", value: "677" },
        { label: "索马里 (Somalia)", value: "252" },
        { label: "南非 (South Africa)", value: "27" },
        { label: "西班牙 (Spain)", value: "34" },
        { label: "斯里兰卡 (Sri Lanka)", value: "94" },
        { label: "苏丹 (Sudan)", value: "249" },
        { label: "苏里南 (Suriname)", value: "597" },
        { label: "斯威士兰 (Swaziland)", value: "268" },
        { label: "瑞典 (Sweden)", value: "46" },
        { label: "瑞士 (Switzerland)", value: "41" },
        { label: "叙利亚 (Syrian Arab Republic)", value: "963" },
        { label: "塔吉克 (Tajikistan)", value: "992" },
        { label: "坦桑尼亚 (Tanzania)", value: "255" },
        { label: "泰国 (Thailand)", value: "66" },
        { label: "多哥 (Togo)", value: "228" },
        { label: "汤加 (Tonga)", value: "676" },
        { label: "特立尼达和多巴哥 (Trinidad and Tobago)", value: "1868" },
        { label: "突尼斯 (Tunisia)", value: "216" },
        { label: "土耳其 (Turkey)", value: "90" },
        { label: "土库曼 (Turkmenistan)", value: "993" },
        {
          label: "土克斯及开科斯群岛 (Turks and Caicos Islands)",
          value: "1809",
        },
        { label: "乌干达 (Uganda)", value: "256" },
        { label: "乌克兰 (Ukraine)", value: "380" },
        { label: "阿拉伯联合酋长国 (United Arab Emirates)", value: "971" },
        { label: "英国 (United Kingdom)", value: "44" },
        { label: "乌拉圭 (Uruguay)", value: "598" },
        { label: "乌兹别克斯坦 (Uzbekistan)", value: "998" },
        { label: "瓦努阿图 (Vanuatu)", value: "678" },
        { label: "梵蒂冈 (Vatican City State (Holy See))", value: "39" },
        { label: "委内瑞拉 (Venezuela)", value: "58" },
        { label: "越南 (Vietnam)", value: "84" },
        {
          label: "维尔京群岛(英国)",
          value: "Virgin Islands (British)",
        },
        { label: "维尔京群岛(美国)", value: "Virgin Islands (U.S.)" },
        { label: "西撒哈拉", value: "Western Sahara" },
        { label: "也门", value: "Yemen" },
        { label: "南斯拉夫", value: "Yugoslavia" },
        { label: "赞比亚", value: "Zambia" },
        { label: "津巴布韦", value: "Zimbabwe" },
        { label: "阿布哈兹", value: "the Republic of Abkhazia" },
        { label: "南奥赛梯", value: "the Republic of South Ossetia" },
        { label: "泽西岛", value: "Bailiwick of Jersey" },
        { label: "老挝", value: "Lao People s Democratic Republic" },
        { label: "马其顿", value: "The Republic of Macedonia" },
        {
          label: "圣基茨和尼维斯",
          value: "The Federation of Saint Kitts and Nevis",
        },
        { label: "圣卢西亚岛", value: "Santa Luzia Island" },
        {
          label: "圣文森特和格林纳丁斯",
          value: "Saint Vincent and the Grenadines",
        },
        { label: "圣多美和普林西比", value: "Sao Tome and Principe" },
        {
          label: "南苏丹共和国",
          value: "The Republic of South Sudan",
        },
      ],
      documentType: [{ label: "身份证", value: "ID" }],
      form: {
        name: "",
        address: "",
        social_code: "",
        certificate_type: "BUSINESS",
        certificate_image: "",
        legal_name: "",
        legal_id_type: "ID",
        legal_id_code: "",
        legal_id_front: "",
        legal_id_back: "",
        logo_key: "",
      },
      checked: false,
      url: getUrlToken("backename") ? getUrlToken("backename") : "",
    };
  }

  componentDidMount() {
    this.gitCheckaudit();
  }
  setVerification() {
    const { form, checked, current } = this.state;
    if (form.certificate_image === "" || form.certificate_image === undefined) {
      Notification.warning({
        content: "未上传企业证件!",
      });
      return false;
    } else if (form.logo_key === "" || form.logo_key === undefined) {
      Notification.warning({
        content: "未上传企业LOGO!",
      });
      return false;
    } else if (form.certificate_type === "") {
      Notification.warning({
        content: "未选择证件类型!",
      });
      return false;
    } else if (form.name === "") {
      Notification.warning({
        content: "未输入企业名称!",
      });
      return false;
    } else if (form.name.length < 4 || form.name.length > 20) {
      Notification.warning({
        content: "企业名称长度为4~20!",
      });
      return false;
    } else if (form.social_code === "") {
      Notification.warning({
        content: "未输入统一社会信用代码/组织机构代码!",
      });
      return false;
    } else if (form.address === "") {
      Notification.warning({
        content: "未输入注册地址!",
      });
      return false;
    } else if (
      (form.legal_id_front === "" || form.legal_id_front === undefined) &&
      current !== 1
    ) {
      Notification.warning({
        content: "未上传法定代表人证件人像面!",
      });
      return false;
    } else if (
      (form.legal_id_back === "" || form.legal_id_back === undefined) &&
      current !== 1
    ) {
      Notification.warning({
        content: "未上传法定代表人证件国徽面!",
      });
      return false;
    } else if (form.legal_name === "" && current !== 1) {
      Notification.warning({
        content: "未输入法定代表人姓名!",
      });
      return false;
    } else if (
      (form.legal_id_type === "" || form.legal_id_type === undefined) &&
      current !== 1
    ) {
      Notification.warning({
        content: "未选择法定代表人证件类型!",
      });
      return false;
    } else if (
      (form.legal_id_code === "" || form.legal_id_code === undefined) &&
      current !== 1
    ) {
      Notification.warning({
        content: "未输入法定代表人证件号码!",
      });
      return false;
    } else if (!checked && current !== 1) {
      Notification.warning({
        content: "请阅读并接受服务协议和隐私政策!",
      });
      return false;
    } else {
      return true;
    }
  }
  handleUpload(option) {
    upload(option);
  }
  setFile(current, fileList, file) {
    const { form } = this.state;
    switch (current) {
      case 1:
        this.setState({
          fileList: fileList,
        });
        if (file.status === "done") {
          let key = [];
          let fileList = this.state.fileList;
          fileList.map((item, index) => {
            key.push(item.response.url);
          });
          form.certificate_image = key[0];
          this.setState({
            certificate_image: key,
            fileList: fileList,
            form: form,
          });
        }
        break;
      case 2:
        this.setState({
          fileList2: fileList,
        });
        if (file.status === "done") {
          let key = [];
          let fileList = this.state.fileList2;
          fileList.map((item, index) => {
            key.push(item.response.url);
          });
          form.legal_id_front = key[0];
          this.setState({
            legal_id_front: key,
            fileList2: fileList,
            form: form,
          });
        }
        break;
      case 3:
        this.setState({
          fileList3: fileList,
        });
        if (file.status === "done") {
          let key = [];
          let fileList = this.state.fileList3;
          fileList.map((item, index) => {
            key.push(item.response.url);
          });
          form.legal_id_back = key[0];
          this.setState({
            legal_id_back: key,
            fileList3: fileList,
            form: form,
          });
        }
        break;
      case 4:
        this.setState({
          fileList4: fileList,
        });
        if (file.status === "done") {
          let key = [];
          let fileList = this.state.fileList4;
          fileList.map((item, index) => {
            key.push(item.response.url);
          });
          form.logo_key = key[0];
          this.setState({
            logo_key: key,
            fileList4: fileList,
            form: form,
          });
        }
        break;
      default:
        break;
    }
  }
  beforeUpload(file) {
    var AllImgExt = ".jpg|.jpeg|.png|";
    var extName = file.name.substring(file.name.lastIndexOf(".")).toLowerCase(); //（把路径中的所有字母全部转换为小写）
    if (AllImgExt.indexOf(extName + "|") === -1) {
      let ErrMsg = "该文件类型不允许上传。当前文件类型为" + extName;
      Message.error(ErrMsg);
      return false;
    }
    const isLt10M = !(
      b2ValueUnit(file.size)[1] === "B" ||
      b2ValueUnit(file.size)[1] === "KB" ||
      (b2ValueUnit(file.size)[1] === "MB" && b2ValueUnit(file.size)[0] <= 5)
    );
    return new Promise((resolve, reject) => {
      if (isLt10M) {
        Message.error("上传文件不能大于5M");
        reject();
      } else {
        resolve();
      }
    });
  }
  //判断文件是pdf
  pdfType(result2, file) {
    if (result2) {
      let type = file.originFile.type;
      // var index = url.lastIndexOf("\/");
      // url = url.substring(index + 1, url.length);
      //获取最后一个.的位置
      // var index = result2.lastIndexOf(".");
      // //获取后缀
      // var ext = result2.substr(index + 1);
      if (
        [
          "application/pdf",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/msword",
        ].indexOf(type.toLowerCase()) === -1
      ) {
        return false;
      } else {
        if (
          type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
          type === "application/msword"
        ) {
          return "docx";
        } else {
          return "pdf";
        }
      }
    }
  }
  renderUploadList = (filesList, props) => {
    return (
      <div className="rowStart" style={{ width: "100%" }}>
        {filesList.map((file) => {
          const url =
            file.url ||
            (file.originFile ? URL.createObjectURL(file.originFile) : "");
          return (
            <Card
              className="upload-demo-list"
              key={file.uid}
              hoverable
              bodyStyle={{ padding: "4px 8px" }}
              cover={
                <img
                  className={`${this.pdfType(url, file) ? "fileType" : ""}`}
                  src={
                    this.pdfType(url, file) === "pdf"
                      ? pdf_icon
                      : this.pdfType(url, file) === "docx"
                      ? docx_icon
                      : url
                  }
                  alt=""
                />
              }
              actions={[
                <div className="file-Delete">
                  <IconDelete
                    onClick={() => {
                      props.onRemove(file);
                    }}
                  />
                </div>,
              ]}
            ></Card>
          );
        })}
      </div>
    );
  };
  onNextStep() {
    let current = this.state.current;
    if (this.state.current === 1) {
      if (this.setVerification()) {
        this.setState({
          current: current + 1,
        });
      }
    } else {
      current = current - 1;
      this.setState({
        current: current,
      });
    }
  }
  onExamine() {
    if (this.setVerification()) {
      this.setState({
        current: this.state.current + 1,
        status: 1,
        lodingTitle: "成功提交，正在审核中",
      });
      enterpriseAdd(this.state.form).then((res) => {
        if (res.code === 200) {
          this.setState({
            status: 1,
          });
          this.gitCheckaudit();
        } else {
          this.setState({
            status: 3,
            failTitle: res.msg || "提交失败",
          });
        }
      });
    }
  }
  _setValue(name, val) {
    const { form } = this.state;
    form[name] = val;
    this.setState({
      form,
    });
    // console.log(form, "form");
  }
  onCheckbox(val) {
    this.setState({
      checked: val,
    });
  }
  goIntoUser() {
    const loginUrl = ALLURL.loginURL + "/login.html";
    localStorage.clear();
    window.location = loginUrl;
  }
  gitCheckaudit() {
    checkaudit().then((res) => {
      if (res.code === 200) {
        if (res.data) {
          // 审核状态 WAIT_AUDIT-待审核 AUDIT-已审核 FAIL-审核不通过 AUDITED-审核通过（第一次）
          let showTable = this.state.showTable;
          let current = 0;
          let status = 0;
          switch (res.data.status) {
            case "AUDIT":
              showTable = true;
              current = 1;
              status = 1;
              break;
            case "WAIT_AUDIT":
              current = 3;
              status = 1;
              break;
            case "FAIL":
              current = 3;
              status = 3;
              break;
            case "AUDITED":
              current = 3;
              status = 2;
              break;
            default:
              break;
          }
          this.setState({
            showTable: showTable,
            current: current,
            status: status,
          });
        } else {
          this.setState({
            current: 1,
            status: 1,
            showTable: true,
          });
        }
      } else {
        this.setState({
          status: 3,
          failTitle: "审核失败",
        });
      }
    });
  }
  render() {
    const {
      steps,
      current,
      fileList,
      fileList2,
      fileList3,
      fileList4,
      optionsType,
      documentType,
      status,
      form,
      checked,
      failTitle,
      lodingTitle,
      showTable,
      url,
    } = this.state;
    return (
      <div className="authentication">
        <div className="rowCenter authentication-head">
          <div className="arrowLeft">
            <Link href={"/" + url}>
              <IconArrowLeft />
              返回
            </Link>
          </div>
          <div className="authentication-bg"></div>
          <div className="AlibabaPuHuiTi-Medium authentication-text">
            升级韦尼克创作平台企业账户，畅享团队创作
            <IconCaretDown />
            <div className="authentication-img"></div>
          </div>
        </div>
        <div className="authentication-box">
          {showTable ? (
            <>
              <div className="authentication-steps rowStart">
                {steps?.map((item, i) => {
                  return (
                    <div
                      className={`rowStart authentication-step ${
                        current >= i + 1 ? `active` : ``
                      }`}
                      key={i}
                    >
                      {i > 0 ? <IconRight /> : null}
                      <div className="dot">{i + 1}</div>
                      <span className="AlibabaPuHuiTi-Medium">{item}</span>
                    </div>
                  );
                })}
              </div>
              <Divider />
            </>
          ) : null}
          <div className="authentication-form">
            {current === 1 ? (
              <>
                <div className="authentication-item">
                  <div className="authentication-lable">
                    <span>*</span>证件附件
                  </div>
                  <div className="authentication-value">
                    <Space>
                      <Upload
                        drag
                        multiple
                        accept=".jpeg,.JPG,.PNG"
                        fileList={fileList}
                        showUploadList={true}
                        renderUploadList={this.renderUploadList}
                        limit={1}
                        onChange={this.setFile.bind(this, 1)}
                        customRequest={(option) => {
                          this.handleUpload(option);
                        }}
                        beforeUpload={this.beforeUpload.bind(this)}
                        onExceedLimit={() => {
                          Message.warning("超过上传数量限制！最多上传1个");
                        }}
                      >
                        <div className="rowCenter trigger">
                          <Space direction="vertical">
                            <span className="iconfont icon-icon_chuangjian_24"></span>
                            <div>上传企业证件</div>
                          </Space>
                        </div>
                      </Upload>
                      <Upload
                        drag
                        multiple
                        accept=".jpeg,.JPG,.PNG"
                        fileList={fileList4}
                        showUploadList={true}
                        renderUploadList={this.renderUploadList}
                        limit={1}
                        onChange={this.setFile.bind(this, 4)}
                        customRequest={(option) => {
                          this.handleUpload(option);
                        }}
                        beforeUpload={this.beforeUpload.bind(this)}
                        onExceedLimit={() => {
                          Message.warning("超过上传数量限制！最多上传1个");
                        }}
                      >
                        <div className="rowCenter trigger">
                          <Space direction="vertical">
                            <span className="iconfont icon-icon_chuangjian_24"></span>
                            <div>上传企业logo</div>
                          </Space>
                        </div>
                      </Upload>
                    </Space>
                    <div className="authentication-remarks">
                      图片格式要求jpg、jpeg、png，不超过5M；文件名（包含后缀名）的最大长度为100个字符，如需水印请含有“珠海必优科技有限公司”字样。
                    </div>
                  </div>
                </div>
                <div className="authentication-item">
                  <div className="authentication-lable">
                    <span>*</span>证件类型
                  </div>
                  <div className="authentication-value">
                    <Select
                      placeholder="请选择"
                      style={{ width: 430 }}
                      value={form.certificate_type}
                      onChange={(value) => {
                        this._setValue("certificate_type", value);
                      }}
                    >
                      {optionsType.map((option) => (
                        <Select.Option key={option.value} value={option.value}>
                          {option.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className="authentication-item">
                  <div className="authentication-lable">
                    <span>*</span>企业名称
                  </div>
                  <div className="authentication-value">
                    <Input
                      style={{ width: 430 }}
                      allowClear
                      placeholder="请输入"
                      value={form.name}
                      minLength={4}
                      maxLength={20}
                      onChange={(value) => {
                        this._setValue("name", value);
                      }}
                    />
                  </div>
                </div>
                <div className="authentication-item">
                  <div className="authentication-lable">
                    <span>*</span>统一社会信用代码/组织机构代码
                  </div>
                  <div className="authentication-value">
                    <Input
                      style={{ width: 430 }}
                      allowClear
                      placeholder="请输入"
                      value={form.social_code}
                      onChange={(value) => {
                        this._setValue("social_code", value);
                      }}
                    />
                  </div>
                </div>
                <div className="authentication-item">
                  <div className="authentication-lable">
                    <span>*</span>注册地址
                  </div>
                  <div className="authentication-value">
                    <Space wrap>
                      <Input
                        style={{ width: 690 }}
                        allowClear
                        placeholder="请输入详细地址"
                        value={form.address}
                        onChange={(value) => {
                          this._setValue("address", value);
                        }}
                      />
                    </Space>
                  </div>
                </div>
              </>
            ) : current === 2 ? (
              <>
                <div className="authentication-item">
                  <div className="authentication-lable">
                    <span>*</span>证件附件
                  </div>
                  <div className="authentication-value">
                    <Space>
                      <Upload
                        drag
                        multiple
                        accept=".jpeg,.JPG,.PNG"
                        fileList={fileList2}
                        showUploadList={true}
                        renderUploadList={this.renderUploadList}
                        limit={1}
                        onChange={this.setFile.bind(this, 2)}
                        customRequest={(option) => {
                          this.handleUpload(option);
                        }}
                        beforeUpload={this.beforeUpload.bind(this)}
                        onExceedLimit={() => {
                          Message.warning("超过上传数量限制！最多上传1个");
                        }}
                      >
                        <div className="rowCenter trigger">
                          <Space direction="vertical">
                            <span className="iconfont icon-icon_chuangjian_24"></span>
                            <div>法定代表人证件人像面</div>
                          </Space>
                        </div>
                      </Upload>
                      <Upload
                        drag
                        multiple
                        accept=".jpeg,.JPG,.PNG"
                        fileList={fileList3}
                        showUploadList={true}
                        renderUploadList={this.renderUploadList}
                        limit={1}
                        onChange={this.setFile.bind(this, 3)}
                        customRequest={(option) => {
                          this.handleUpload(option);
                        }}
                        beforeUpload={this.beforeUpload.bind(this)}
                        onExceedLimit={() => {
                          Message.warning("超过上传数量限制！最多上传1个");
                        }}
                      >
                        <div className="rowCenter trigger">
                          <Space direction="vertical">
                            <span className="iconfont icon-icon_chuangjian_24"></span>
                            <div>法定代表人证件国徽面</div>
                          </Space>
                        </div>
                      </Upload>
                    </Space>
                    <div className="authentication-remarks">
                      图片格式要求jpg、jpeg、png，不超过5M；文件名（包含后缀名）的最大长度为100个字符，如需水印请含有“珠海必优科技有限公司”字样。
                    </div>
                  </div>
                </div>
                <div className="authentication-item">
                  <div className="authentication-lable">
                    <span>*</span>法定代表人姓名
                  </div>
                  <div className="authentication-value">
                    <Input
                      style={{ width: 430 }}
                      allowClear
                      placeholder="请输入"
                      value={form.legal_name}
                      onChange={(value) => {
                        this._setValue("legal_name", value);
                      }}
                    />
                  </div>
                </div>
                <div className="authentication-item">
                  <div className="authentication-lable">
                    <span>*</span>法定代表人证件类型
                  </div>
                  <div className="authentication-value">
                    <Select
                      placeholder="请选择"
                      style={{ width: 430 }}
                      defaultValue={form.legal_id_type}
                      value={form.legal_id_type}
                      onChange={(value) => {
                        this._setValue("legal_id_type", value);
                      }}
                    >
                      {documentType.map((option) => (
                        <Select.Option key={option.value} value={option.value}>
                          {option.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className="authentication-item">
                  <div className="authentication-lable">
                    <span>*</span>法定代表人证件号码
                  </div>
                  <div className="authentication-value">
                    <Input
                      style={{ width: 430 }}
                      allowClear
                      placeholder="请输入"
                      value={form.legal_id_code}
                      onChange={(value) => {
                        this._setValue("legal_id_code", value);
                      }}
                    />
                  </div>
                </div>
                <div className="authentication-item">
                  <div className="authentication-value">
                    <Checkbox
                      checked={checked}
                      onChange={this.onCheckbox.bind(this)}
                    >
                      已阅读并接受
                      <Link href="/agreement" target="_blank">
                        服务协议
                      </Link>
                      和
                      <Link href="/privacyAgreement" target="_blank">
                        隐私政策
                      </Link>
                    </Checkbox>
                  </div>
                </div>
              </>
            ) : null}
            {current === 1 || current === 2 ? (
              <>
                <Alert
                  type="warning"
                  content="请确保录入信息真实有效，以确保填写成功后我们能联系到您"
                />
                <div className="authentication-primary">
                  <Button
                    className="next-step"
                    onClick={() => this.onNextStep()}
                  >
                    {current === 1 ? "下一步" : "上一步"}
                  </Button>
                  {current === 2 ? (
                    <Button
                      type="primary"
                      className="submit-step"
                      onClick={() => this.onExamine()}
                    >
                      提交审核
                    </Button>
                  ) : null}
                </div>
              </>
            ) : (
              <>
                {current === 3 && status === 1 ? (
                  <div className="position authentication-wait">
                    <div className="wait-check"></div>
                    <div className="wait-text">
                      {lodingTitle}
                      <div className="ellipsis"></div>
                    </div>
                  </div>
                ) : status === 2 ? (
                  <>
                    <div className="position authentication-success">
                      <div className="success-check">
                        <div className="position icon-check">
                          <div style={{ "--height": "22px" }}></div>
                          <div style={{ "--height": "34px" }}></div>
                        </div>
                      </div>
                      <div>
                        <div>审核成功，恭喜您已成功开通企业账户</div>
                        <div>马上开启团队智能创作之旅</div>
                        <Button
                          type="primary"
                          onClick={() => this.goIntoUser()}
                        >
                          进入企业账户
                        </Button>
                      </div>
                    </div>
                  </>
                ) : status === 3 ? (
                  <>
                    <div className="position authentication-fail">
                      <div className="fail-check">
                        <div className="position icon-check">
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                      <div>
                        <div>{failTitle}</div>
                        <div>请仔细核对申请信息，确认无误后重新申请</div>
                        <div className="fail-steps">
                          <Button
                            className="next-step"
                            onClick={() => this.onNextStep()}
                          >
                            上一步
                          </Button>
                          <Link href="/">
                            <Button type="primary" className="next-back">
                              返回韦尼克创作平台
                            </Button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default UserAuthentication;
