import React, { Component } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Message,
  InputNumber,
  Select,
  Grid,
  Space,
  Upload,
  Typography,
  Link,
  Notification,
} from "@arco-design/web-react";
import {
  IconStarFill,
  IconDelete,
  IconLoading,
  IconCheckCircleFill,
  IconExclamationCircleFill,
  IconUpload,
} from "@arco-design/web-react/icon";
import { postdatum, getaddlist } from "api/apis";
import upload from "api/uploads";
import { b2ValueUnit } from "common/utils/index";
import history from "common/utils/history";
import fileicon from "asserts/file_icon.png";
import "./add.scss";
class NewDataset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Selectoptions: [],
      checkedtypeData: "",
      DataSelsectList: null,
      fileList: null,
      attachment: [],
      file: [],
      namedata: "",
      model_capability: "",
      fie_img: "",
    };
    let prem = JSON.parse(localStorage.getItem("props"));
    //console.log(prem, "prem", props, this.props);
  }

  handleResize = () => {
    var winHeight = 0;
    if (window.innerHeight) {
      winHeight = window.innerHeight;
    } else if (document.body && document.body.clientHeight) {
      winHeight = document.body.clientHeight;
    }
    if (document.documentElement && document.documentElement.clientHeight) {
      winHeight = document.documentElement.clientHeight;
    }
    let height =
      winHeight -
      document.getElementById("layoutHeaders").clientHeight -
      document.getElementById("Headertitle").clientHeight -
      50;
    document.getElementById("layoutbody").style.minHeight = height + "px";
    // //console.log(height, 'divHeight')
  };
  componentDidMount() {
    let record = JSON.parse(localStorage.getItem("record"));
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.getaddlist();
  }
  componentWillUnmount() {
    localStorage.removeItem("props");
    window.removeEventListener("resize", this.handleResize);
  }
  getaddlist() {
    getaddlist().then((res) => {
      if (res.code === 200) {
        let list = res.data;
        this.setState({
          Selectoptions: list,
        });
      }
    });
  }
  onChangeIput(value) {
    this.setState({
      namedata: value,
    });
  }
  onSelectoptions(value) {
    //console.log(value, "???????");
    Object.keys(this.state.Selectoptions).map((item) => {
      if (this.state.Selectoptions[item].id === value) {
        this.state.file = this.state.Selectoptions[item].file;
        this.setState({
          file: this.state.Selectoptions[item].file,
          model_capability: value,
          fie_img: this.state.Selectoptions[item].fie_img,
        });
        //console.log(this.state.file);
      }
    });
  }
  handleUpload(option) {
    upload(option, this.state.model_capability);
  }
  setFile(fileList, file) {
    this.setState({
      fileList: fileList,
    });
    if (file.status === "done") {
      let key = [];
      key.push(file.response.fileKey);
      this.setState({
        attachment: key,
      });
      if (this.state.namedata === "") {
        this.setState({
          namedata: file.name.substring(0, file.name.lastIndexOf(".")),
        });
      }
    } else if (file.status === "error") {
      Notification.error({ content: "导入失败!" });
    }
    //console.log(fileList, this.state.attachment, "setFile");
  }
  onIconDelete() {
    this.setState({
      fileList: null,
    });
  }
  beforeUpload(file) {
    //console.log(b2ValueUnit(file.size));
    var AllImgExt = ".csv|";
    var extName = file.name.substring(file.name.lastIndexOf(".")).toLowerCase(); //（把路径中的所有字母全部转换为小写）
    if (AllImgExt.indexOf(extName + "|") === -1) {
      let ErrMsg =
        "该文件类型不允许上传。请上传 .csv 类型的文件，当前文件类型为" +
        extName;
      Notification.warning({ content: ErrMsg });
      return false;
    }
    const isLt10M = !(
      b2ValueUnit(file.size)[1] === "B" ||
      b2ValueUnit(file.size)[1] === "KB" ||
      (b2ValueUnit(file.size)[1] === "MB" && b2ValueUnit(file.size)[0] <= 5)
    );
    return new Promise((resolve, reject) => {
      if (isLt10M) {
        Notification.warning({ content: "上传文件不能大于5MB" });
        reject();
      } else {
        resolve();
      }
    });
  }
  onback() {
    history.replace("/enginefactory/dataset");
    window.location.reload();
  }
  onsubmit() {
    let state = this.state;
    if (state.namedata === "") {
      Notification.warning({ content: "数据集名称不能为空！" });
    } else if (state.model_capability === "") {
      Notification.warning({ content: "服务类型不能为空！" });
    } else if (state.attachment.length === 0) {
      Notification.warning({ content: "未导入数据集！" });
    } else {
      let json = {
        model_capability: state.model_capability,
        name: state.namedata,
        attachment: JSON.stringify(state.attachment),
      };
      postdatum(json).then((res) => {
        if (res.code === 200) {
          this.onback();
        } else {
          Message.error(res.msg);
        }
      });
    }
  }
  render() {
    const {
      Selectoptions,
      fileList,
      DataSelsectList,
      checkedtype,
      namedata,
      checkedtypeData,
      file,
      fie_img,
    } = this.state;
    return (
      <div className="dataset-add">
        <div className="Header-init" id="Headertitle">
          <div className="Header-title">创建数据集</div>
        </div>
        <Card
          bordered={false}
          style={{ borderRadius: 22 }}
          className="cardform"
          id="layoutbody"
        >
          <Typography.Paragraph className="startParagraph">
            数据集配置
          </Typography.Paragraph>
          <div className="forminput">
            <div>
              <Space direction="vertical" style={{ marginBottom: 24 }}>
                <div className="formtitle">服务选项</div>
                <Select
                  placeholder="请选择服务类型"
                  onChange={this.onSelectoptions.bind(this)}
                  className="select-item"
                >
                  {Object.keys(Selectoptions ? Selectoptions : []).map(
                    (option, i) => {
                      return (
                        <Select.Option
                          key={option}
                          disabled={Selectoptions[option].status !== 1}
                          value={Selectoptions[option].id}
                        >
                          {Selectoptions[option].label}
                        </Select.Option>
                      );
                    }
                  )}
                </Select>
              </Space>
            </div>
            <Space direction="vertical">
              <div className="addData">
                <div>
                  <Space direction="vertical" style={{ marginBottom: 24 }}>
                    <div className="formtitle">导入数据</div>
                    <Upload
                      multiple
                      showUploadList={false}
                      limit={1}
                      fileList={fileList}
                      accept=".csv"
                      onChange={this.setFile.bind(this)}
                      customRequest={(option) => {
                        this.handleUpload(option);
                      }}
                      beforeUpload={this.beforeUpload.bind(this)}
                      onExceedLimit={() => {
                        Notification.warning({
                          content: "超过上传数量限制！最多上传1个",
                        });
                      }}
                    >
                      <Space
                        size="medium"
                        style={{ color: "#406EFF", fontSize: 16 }}
                      >
                        点击上传数据集文件
                        <IconUpload
                          style={{ color: "#4E5969", fontSize: 16 }}
                        />
                      </Space>
                    </Upload>
                    {fileList ? (
                      <Space
                        align="center"
                        style={{ fontSize: 16 }}
                        className="upload-Link"
                      >
                        {fileList.map((item, index) => {
                          return (
                            <Link
                              key={index}
                              href={item.response ? item.response.url : ""}
                              icon={
                                item.status === "error" ? (
                                  <IconExclamationCircleFill
                                    style={{ color: "#ff7d00", fontSize: 16 }}
                                  />
                                ) : item.status === "done" ? (
                                  <IconCheckCircleFill
                                    style={{ color: "#00B42A", fontSize: 16 }}
                                  />
                                ) : (
                                  <IconLoading
                                    style={{ color: "#406EFF", fontSize: 16 }}
                                  />
                                )
                              }
                            >
                              {item.name}
                            </Link>
                          );
                        })}
                        <IconDelete
                          style={{ color: "#4E5969", cursor: "pointer" }}
                          onClick={this.onIconDelete.bind(this)}
                        />
                      </Space>
                    ) : null}
                    <Input
                      style={{ marginTop: 16 }}
                      className="inputtext"
                      allowClear
                      value={namedata}
                      placeholder="请输入数据集名称（限20字符）"
                      onChange={this.onChangeIput.bind(this)}
                      maxLength={20}
                    />
                  </Space>
                </div>

                {file.length > 0 ? (
                  <div className="TipsData">
                    <Space align="start">
                      <IconStarFill style={{ color: "#FFD726" }} />
                      <Typography>
                        为了确保引擎的最终效果，请按以下模板提供标注数据
                      </Typography>
                    </Space>
                    <div className="TipsData-link">
                      {file.length > 0 &&
                        file.map((item, index) => {
                          return (
                            <span key={index}>
                              {index > 0 ? (
                                <Link href={item.url} target="_blank">
                                  {" "}
                                  或 {item.name}.{item.type}
                                </Link>
                              ) : (
                                <Link href={item.url} target="_blank">
                                  {" "}
                                  附：{item.name}.{item.type}
                                </Link>
                              )}
                            </span>
                          );
                        })}
                      {fie_img ? (
                        <div className="fie_img">
                          <img src={fie_img} />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Space>
          </div>
          <div className="dataset-btns">
            <Button
              className="dataset-btn colse custom-cancel-button"
              onClick={this.onback.bind(this)}
            >
              取消
            </Button>
            <Button
              type="primary"
              className="dataset-btn"
              onClick={this.onsubmit.bind(this)}
            >
              完成
            </Button>
          </div>
        </Card>
      </div>
    );
  }
}

export default NewDataset;
