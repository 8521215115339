import React, { Component } from 'react'
import {
  Layout,
  Typography,
  Button,
  Space,
  Empty,
  Input,
  Notification,
} from '@arco-design/web-react'
import Emptydata_img from 'asserts/Emptydata_img.svg'
import Generating_img from 'asserts/Generating_img.gif'
import errorIcon from 'asserts/error_icon.svg'
import { shareAppApi, shareKnowledgeJd, shareKnowledgeFreq } from 'api/apis'
import D3V5KG from '../../common/utils/d3.v5.kg'
import JQ from 'jquery'
import '../../common/style/myapp/knowledge.scss'
const Sider = Layout.Sider
const Content = Layout.Content

// 封装组件
class knowledge extends Component {
  constructor(props) {
    super(props)

    this.state = {
      optionVlue: '',
      versions: [],
      ParagraphContinuation: [],
      ContinuationLoding: false,
      share_id: localStorage.getItem('share_ids'),
      record: JSON.parse(localStorage.getItem('shareRecord')),
      apiInfo: {
        model_id: '',
      },
      hasList: false,
      inputVal: '',
      defaultNodes: [
        {
          id: '537205',
          name: '产品经理',
          type: '岗位',
        },
        {
          id: '44252',
          name: 'WEB前端开发工程师',
          type: '岗位',
        },
        {
          id: '71238',
          name: '精益工程师',
          type: '岗位',
        },
        {
          id: '146939',
          name: '运维主管',
          type: '岗位',
        },
        {
          id: '129637',
          name: '机器学习工程师',
          type: '岗位',
        },
        {
          id: '326',
          name: '3年',
          type: '时长',
        },
        {
          id: '8774',
          name: '语言表达',
          type: '能力素质',
        },
        {
          id: '126004',
          name: '团队协作',
          type: '能力素质',
        },
        {
          id: '650',
          name: '学习能力',
          type: '能力素质',
        },
        {
          id: '610',
          name: '网易集团',
          type: '企业',
        },
        {
          id: '652',
          name: '阿尔泰克',
          type: '企业',
        },
      ],
      requestMsg: '',
      requestMsgText: '您还未输入关键信息',
      loading: false,
      input_describe: '输入你的意向岗位或匹配条件',
    }
  }
  componentDidMount() {
    this.getApiInfo()
    setTimeout(() => {
      this.handleResize()
      this.init()
    }, 300)
    window.addEventListener('resize', this.handleResize)
  }
  componentWillUnmount() {
    localStorage.removeItem('props')
    window.removeEventListener('resize', this.handleResize)
  }
  handleResize = () => {
    JQ('#continuation-kg').css({
      height: JQ('#result-kg').height() - JQ('.result_title').height() - 30,
    })
    JQ('#continuation').css({
      height: JQ('#result-kg').height() - JQ('.result_title').height() - 30,
    })
  }

  init() {
    this.renderKg(
      {
        links: [],
        nodes: this.state.defaultNodes,
      },
      ''
    )

    let _this = this

    JQ('#continuation-kg')
      .on('mouseover', 'li', function () {
        let $self = JQ(this)
        let type = $self.attr('data-type')
        $self.css({
          opacity: '1',
          backgroundColor: D3V5KG.colorsMapNode[type].fill,
          color: D3V5KG.colorsMapNode[type].text,
        })
      })
      .on('mouseout', 'li', function () {
        let $self = JQ(this)
        let isHasActive = JQ('#continuation-kg').find('.active') > 0

        if (isHasActive) {
          if (!$self.hasClass('active')) {
            $self.css({
              opacity: '.2',
              backgroundColor: '#fff',
              color: '#000',
            })
          }
        } else {
          $self.css({ opacity: '1', backgroundColor: '#fff', color: '#000' })
        }
      })
      .on('click', 'li', function () {
        let $self = JQ(this)
        let $old = JQ('#continuation-kg').find('.active')
        let type = $self.attr('data-type')
        JQ('#continuation-kg').find('li').not($self).removeClass('active').css({
          opacity: '.2',
        })
        $self.addClass('active')
        $self.css({
          opacity: '1',
          backgroundColor: D3V5KG.colorsMapNode[type].fill,
          color: D3V5KG.colorsMapNode[type].text,
        })

        if ($old.length > 0) {
          if ($old.attr('data-type') !== type) {
            _this.filter($self, type, D3V5KG.colorsMapNode[type].stroke)
          } else {
            $self.removeClass('active')
            JQ('#continuation-kg')
              .find('li')
              .css({ opacity: '1', backgroundColor: '#fff', color: '#000' })
            _this.filter($self, '', D3V5KG.colorsMapNode[type].stroke)
          }
        } else {
          _this.filter($self, type, D3V5KG.colorsMapNode[type].stroke)
        }
      })

    JQ('#continuation').on('click', 'a', function () {
      let name = JQ(this).text()
      JQ('#kg-list').hide()
      _this.setState({
        hasList: false,
        inputVal: name,
        ContinuationLoading: true,
        loading: true,
      })
      _this.send(name, true)
    })

    JQ('#continuation').on('click', '.job-name i', function () {
      let $last = JQ('#continuation').find('li.active')
      let $self = JQ(this).parent('span').parent('h3').parent('li')
      let $other = JQ('#continuation').find('li').not($self)
      $other.removeClass('active')
      $other.find('.more').slideUp(800)

      if (!$self.hasClass('active')) {
        $self.addClass('active')
        $self.find('.more').slideDown(800)
        _this.info(JQ(this).attr('data-id'), $last)
      }
    })
  }

  createInstruct(data) {
    let html = ''

    for (let tag in data) {
      html +=
        '<li data-type="' +
        tag +
        '"><i style="background-color: ' +
        data[tag].fill +
        ';border-color: ' +
        data[tag].stroke +
        '"></i><b>' +
        tag +
        '</b></li>'
    }

    if (html) {
      JQ('#continuation-kg')
        .find('.kg-instruct')
        .show()
        .html('<ul>' + html + '</ul>')
    } else {
      JQ('#continuation-kg').find('.kg-instruct').hide().html('')
    }
  }
  lightHigh(content, items) {
    let regString = ''

    for (let i in items) {
      regString += '|' + items[i].name.replace(/(\|)/g, '\\$1')
    }

    if (!regString) {
      return content
    }

    regString = regString.substr(1)

    regString = regString.replace(/([\+\(\)\{\}\.\-\$\#\&\*\/])+/g, '\\$1')
    regString = '(' + regString + ')'
    let reg = new RegExp(regString, 'ig')

    content = content.replace(reg, '<a class="lh">$1</a>')

    return content
  }
  renderKg(data, text, after = false) {
    try {
      let $content = JQ('#result-kg')
      JQ('body').children('.more-info').length > 0 &&
        JQ('body').children('.more-info').remove()

      if (!after) {
        D3V5KG.clearNode()
      }

      D3V5KG.init('#continuation-kg', data, {
        width: $content.width(),
        height: $content.height() - 40,
        keyword: text,
        clickCallback: (data, index) => {
          this.setState({
            inputVal: data.name,
          })
          this.send('', true, false, data.id)
        },
      })

      this.createInstruct(D3V5KG.colorsMapNode)
    } catch (err) {
      console.error(err)
    }
  }

  createFrequencyLoading(borderColor) {
    return (
      '<svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">\n' +
      '    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">\n' +
      '        <g transform="translate(-1027.000000, -5525.000000)" stroke="' +
      borderColor +
      '">\n' +
      '            <g transform="translate(1028.450649, 5526.381275)">\n' +
      '                <path d="M11.264195,3.11872452 C10.2447155,1.26005063 8.26950806,0 6,0 C2.6862915,0 0,2.6862915 0,6 C0,9.3137085 2.6862915,12 6,12 C8.5186733,12 10.6748772,10.448084 11.5645408,8.24832289"></path>\n' +
      '                <polyline points="11.630406 0.38581436 11.630406 3.50453888 8.68436106 3.50453888"></polyline>\n' +
      '            </g>\n' +
      '        </g>\n' +
      '    </g>\n' +
      '</svg>'
    )
  }

  filter($self, type, borderColor) {
    if (!type) {
      if ($self.find('svg').length > 0) {
        $self.find('svg').remove()
        $self.find('i').show()
      }

      D3V5KG.filter(type, {})
      return
    }

    let arr = []

    for (let i in D3V5KG.nodes) {
      if (D3V5KG.nodes[i].type === type) {
        arr.push(D3V5KG.nodes[i].name)
      }
    }

    if (arr.length === 0) {
      return
    }

    let load = this.createFrequencyLoading(borderColor)

    $self.find('i').hide()
    $self.prepend(load)

    let items = {}
    items[type] = arr

    shareKnowledgeFreq({
      items: JSON.stringify(items),
    }).then((res) => {
      if (res.code === 200) {
        D3V5KG.filter(type, res.data[type])
      } else {
        Notification.warning({ content: res.msg })
      }
      $self.find('svg').remove()
      $self.find('i').show()
    })
  }
  info(id, $last) {
    shareKnowledgeJd({
      show_id: id,
    }).then((res) => {
      if (res.code === 200) {
        let desc = res.data.collection_jd[id].desc
        res.data.collection_jd = res.data.collection_jd[id].entities
        this.renderKg(res.data.graph, res.data.collection_jd['岗位'][0], true)

        desc = this.lightHigh(desc, res.data.graph.nodes)

        let name = this.lightHigh(
          res.data.collection_jd['岗位'][0],
          res.data.graph.nodes
        )
        let salary = this.lightHigh(
          res.data.collection_jd['薪制'][0],
          res.data.graph.nodes
        )
        let company = this.lightHigh(
          res.data.collection_jd['企业'][0],
          res.data.graph.nodes
        )
        let city = this.lightHigh(
          res.data.collection_jd['地点'][0],
          res.data.graph.nodes
        )

        let $item = JQ('#item-' + id)

        const height = $item.height()

        $item.find('.desc').html(desc.replace(/\r\n/g, '<br>'))
        $item.find('.job-name').html(name + '<i data-id="' + id + '"></i>')
        $item.find('.job-salary').html(salary)
        $item.find('.job-company').html(company)
        $item.find('.job-city').html(city)

        $item.animate(
          {
            scrollTop: height,
          },
          500
        )

        if ($last.length > 0) {
          $last.animate(
            {
              scrollTop: 0,
            },
            500
          )
        }
      } else {
        Notification.warning({ content: res.msg })
      }
    })
  }

  send(text, after = false, cut = true, nodesId = null) {
    let params = {
      app_id: this.state.record.app_id,
      items: text || JQ.trim(JQ('#kg-text').val()),
      nodes_id: nodesId ? '[' + nodesId + ']' : '',
      share_id: this.state.share_id,
    }

    if (!params.items && !params.nodes_id) {
      Notification.warning({ content: '关键词不能为空！' })
      return false
    }

    if (params.nodes_id) {
      params.items = ''
    }

    JQ('#kg-list').hide()

    this.setState({
      hasList: false,
      ContinuationLoading: true,
      loading: true,
    })

    if (params.items) {
      if (cut) {
        params.items = JSON.stringify(
          params.items
            .replace(/[、，\s]+/, ',')
            .replace(/,+/g, ',')
            .split(',')
        )
      } else {
        params.items = JSON.stringify([params.items])
      }
    }

    shareAppApi(params).then((res) => {
      this.setState({
        loading: false,
      })

      if (res.code === 200) {
        this.setState({
          hasList: true,
        })

        this.renderKg(res.data.graph, params.items, after)

        let html = ''
        let include = ['desc', '岗位', '企业', '薪制', '地点']

        for (let i in res.data.collection_jd) {
          let desc = this.lightHigh(
            res.data.collection_jd[i].desc,
            res.data.graph.nodes
          )
          let tmp = res.data.collection_jd[i].entities

          let name = this.lightHigh(tmp['岗位'][0], res.data.graph.nodes)
          let salary = this.lightHigh(tmp['薪制'][0], res.data.graph.nodes)
          let company = this.lightHigh(tmp['企业'][0], res.data.graph.nodes)
          let city = this.lightHigh(tmp['地点'][0], res.data.graph.nodes)

          html += '<li id="item-' + i + '">'
          html +=
            '    <h3 class="bold"><span class="job-name">' +
            name +
            '<i data-id="' +
            i +
            '"></i></span><span class="job-salary">' +
            salary +
            '</span></h3>'
          html +=
            '    <h4 class="bold"><span class="job-company">' +
            company +
            '</span><span class="job-city">' +
            city +
            '</span></h4>'
          html += '    <hr>'
          html +=
            '    <div class="desc">' + desc.replace(/\r\n/g, '<br>') + '</div>'
          html += '    <div class="more">'
          html += '         <hr>'

          for (let j in tmp) {
            if (!include.includes(j)) {
              if (j === '金额') {
                html += '<h5 class="bold">薪资待遇</h5>'
                html += '<div class="tags-a">'
                html += '<a>' + tmp[j][0] + '</a>'

                if (tmp[j][0] !== tmp[j][1]) {
                  html += ' 至 <a>' + tmp[j][1] + '</a>'
                }

                html += '</div>'
              } else {
                html += '<h5 class="bold">' + j + '</h5>'
                html += '<div class="tags-a">'

                for (let k in tmp[j]) {
                  html += '<a>' + tmp[j][k] + '</a>'
                }

                html += '</div>'
              }
            }
          }

          html += '    </div>'
          html += '</li>'
        }

        if (html) {
          setTimeout(() => {
            JQ('#kg-list').html(html).show()
            this.setState({
              ContinuationLoading: false,
            })
          }, 500)
        }
      } else if (res.code === 204) {
        this.setState({
          requestMsg: '',
          requestMsgText: '无法生成关联内容，请换个输入信息试试！',
          ContinuationLoading: false,
          loading: false,
        })
      } else if (res.code === 400 || res.code === 3003) {
        this.setState({
          requestMsg: '',
          requestMsgText: res.msg,
          ContinuationLoading: false,
          loading: false,
        })
      } else {
        this.setState({
          requestMsg: 'error',
          requestMsgText: res.msg,
          ContinuationLoading: false,
          loading: false,
        })
      }
    })
  }

  getApiInfo() {
    let list = this.state.record
    this.setState({
      apiInfo: list,
    })
    // document.title = list.app_name
    if (list.type === 0) {
      this.onTextArea(list.input_describe ? list.input_describe : '产品经理')
      this.isPhoneLegal()
    }
  }

  onTextArea(value) {
    this.setState({
      inputVal: value,
    })
  }
  isPhoneLegal = () => {
    setTimeout(() => {
      this.state.inputVal && !this.state.loading && this.send()
    }, 500)

    return false
  }
  ChangeAbatch(type) {
    if (type === 'start') {
      this.setState({
        ParagraphContinuation: [],
        loading: false,
        ContinuationLoding: true,
        requestMsg: '',
      })
    } else {
      this.setState({
        loading: true,
        requestMsg: '',
      })
    }
    setTimeout(() => {
      // this.setRequest()
    }, 1000)
  }

  render() {
    const {
      input_describe,
      inputVal,
      hasList,
      requestMsg,
      ContinuationLoading,
      requestMsgText,
      loading,
    } = this.state

    return (
      <div className="knowledge">
        <Layout className="semanticprototype-Layout" id="layout_id">
          {/* <Sider className="input-top">
            <div className="Sider-left-configure columnSpaceBetween">
              <div className="Continuation-input">
                <Input
                  placeholder='输入你的意向岗位或匹配条件'
                  style={{ width: '100%' }}
                  id="kg-text"
                  value={inputVal}
                  onChange={this.onTextArea.bind(this)}
                  onPressEnter={this.isPhoneLegal.bind(this)}
                />
              </div>
              <div className="Upload-pictures-bottom">
                <Button type='primary' className="Start-generation" disabled={loading || !inputVal} onClick={this.isPhoneLegal.bind(this)}>
                  开始探测
                </Button>
              </div>
            </div>
          </Sider> */}
          <Sider className="input-top">
            <div className="Search-input">
              <Input
                className="Search"
                placeholder={input_describe}
                id="kg-text"
                value={inputVal}
                onChange={this.onTextArea.bind(this)}
                onPressEnter={this.isPhoneLegal.bind(this)}
              />
              <Button
                type="primary"
                className="Start-generation"
                disabled={loading || !inputVal}
                onClick={this.isPhoneLegal.bind(this)}
              >
                搜索
              </Button>
            </div>
          </Sider>
          <Content>
            <div className="experience-result kg-result" id="result-kg">
              <div className="result_title">
                <Typography>知识图谱</Typography>
              </div>
              <div className="continuation-body" id="continuation-kg">
                <div className="kg-instruct" />
              </div>
            </div>
            <div className="experience-result kg-jd-list" id="result">
              <div className="rowSpaceBetween result_title">
                <Typography>生成关联内容</Typography>
                <Typography.Paragraph className="result-remarks">
                  (内容基于韦尼克AI创作引擎生成，仅供参考)
                </Typography.Paragraph>
              </div>
              <div className="continuation-body" id="continuation">
                {hasList || ContinuationLoading ? (
                  <>
                    <div id="kg-list" className="result-list" />
                    {ContinuationLoading && (
                      <Empty
                        className="Continuation-loding"
                        icon={<img src={Generating_img} />}
                        description={
                          <Typography
                            style={{
                              color: '#4e5969',
                              marginTop: 21,
                              fontSize: 16,
                            }}
                          >
                            正在生成中...
                          </Typography>
                        }
                      />
                    )}
                  </>
                ) : requestMsg === 'error' ? (
                  <Empty
                    className="Continuation-Empty Empty-error"
                    imgSrc={errorIcon}
                    description={
                      <Space direction="vertical">
                        <Typography
                          style={{
                            color: '#4e5969',
                            marginTop: 30,
                            fontSize: 16,
                          }}
                        >
                          抱歉，服务器生成错误
                        </Typography>
                        <Typography style={{ color: '#4e5969', fontSize: 16 }}>
                          可尝试
                          <span
                            style={{ color: '#406EFF', cursor: 'pointer' }}
                            onClick={this.ChangeAbatch.bind(this)}
                          >
                            {' '}
                            重新生成
                          </span>
                        </Typography>
                      </Space>
                    }
                  />
                ) : (
                  <Empty
                    className="Continuation-Empty Empty-none"
                    imgSrc={Emptydata_img}
                    description={
                      <Space direction="vertical">
                        <Typography
                          style={{
                            color: '#4e5969',
                            marginTop: 30,
                            fontSize: 16,
                          }}
                        >
                          暂无生成结果
                        </Typography>
                        <Typography style={{ color: '#4e5969', fontSize: 16 }}>
                          {requestMsgText}
                        </Typography>
                      </Space>
                    }
                  />
                )}
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    )
  }
}
export default knowledge
