import React, { Component } from "react";
import {
  Space,
  Button,
  Statistic,
  Divider,
  Link,
  Spin,
  Switch,
  Radio,
  List,
  Empty,
  Typography,
  Tooltip,
  Modal,
  Notification,
} from "@arco-design/web-react";
import history from "common/utils/history";
import {
  IconStarFill,
  IconRight,
  IconQuestionCircleFill,
} from "@arco-design/web-react/icon";
import {
  getenginepackage,
  getuserpackage,
  getbalance,
  open_settlement,
  close_settlement,
} from "api/apis";
import Emptydata_img from "asserts/Emptydata_img.svg";
import File from "asserts/File.png";
import Image from "asserts/Image.png";
import Text from "asserts/Text.png";
import PPT from "asserts/PPT.png";
import "./index.scss";
// 封装组件
class Paymentinformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingbody: false,
      isautomatic: false,
      available_limit:0, //可用余额
      balance: 0, //充值余额
      credit_limit:0, //信用余额
      give_limit:0, //赠送额度
      used_limit:0, //使用金额
      amount_in_arrears:0, //使用金额
      data: [], //通用资源
      specialData: [], //专用资源
      pagination: {
        sizeCanChange: false,
        showTotal: true,
        total: 0,
        pageSize: 20,
        current: 1,
        pageSizeChangeResetCurrent: true,
        showJumper: false,
      },
      scrollLoading: <Spin loading={true} />,
      loading: false,
      no_data: false, //通用资源是否有数据
      special_no_data: false, //专用资源是否有数据
      type_name: "全部资源",
      positionList: [
        "全部资源",
        "智能写作",
        "智能图文",
        "智能文档",
        "知识图谱",
      ],
      maxHeight: this.setmaxHeight(),
      productstyle: JSON.parse(localStorage.getItem("productstyle")),
      Selectoptions: JSON.parse(localStorage.getItem("Selectoptions")),
      visible: false,
      modaltext: "",
      imgList : {
        "File":File,
        "Image":Image,
        "Text":Text,
        "PPT":PPT,
        "TextFree":'https://saas.file.yoo-ai.com/20230324/f2023032-4120-7296-6164-c4d43cc31f35.png',
      },
      ModalContactService: false,
    };
  }
  componentDidMount() {
    // this.getenginepackage();
    this.getbalance();
    //请求用户资源包
    this.getuserpackage();
  }
  UNSAFE_componentDidUpdate() {}
  setmaxHeight() {
    let height = 600;
    var winHeight = 0;
    if (window.innerHeight) {
      winHeight = window.innerHeight;
    } else if (document.body && document.body.clientHeight) {
      winHeight = document.body.clientHeight;
    }
    if (document.documentElement && document.documentElement.clientHeight) {
      winHeight = document.documentElement.clientHeight;
    }
    height = winHeight - 615;
    return height + "px";
  }
  getbalance() {
    getbalance().then((res) => {
      if (res.code === 200) {
        this.setState({
          available_limit:res.data.available_limit, //可用余额
          balance:res.data.balance, //充值余额
          credit_limit:res.data.credit_limit, //信用余额
          give_limit:res.data.give_limit, //赠送额度
          used_limit:res.data.used_limit, //使用金额
          amount_in_arrears:res.data.amount_in_arrears, //使用金额
          isautomatic: res.data.settlement_status,
        });
      }
    });
  }
  getuserpackage() {
    getuserpackage().then((res) => {
      if (res.code === 200) {
        // console.log(res.data);
        let list = res.data;
          this.setState({
            data: list.common,
            specialData: list.special,
            loading: false,
            loadingbody: false,
            // no_data: list.common.length > 0 ? false : true,
            special_no_data: list.special.length > 0 ? false : true,
            scrollLoading: false,
          });

          //添加颜色
          this.state.data.map((item)=>{
            if(item.name == '文件资源包'){
              item['color'] = 'rgb(99, 188, 120)'
              item['bagcolor'] = 'linear-gradient(180deg, #C9F6CA 3%, rgba(255,255,255,0.00) 100%)'
            } else if(item.name == '图片资源包'){
              item['color'] = 'rgb(116, 203, 209)'
              item['bagcolor'] = 'linear-gradient(180deg, #DCF4F6 2%, #FFFFFF 98%)'
            } else if(item.name == '文字资源包'){
              item['color'] = 'rgb(178, 153, 228)'
              item['bagcolor'] = 'linear-gradient(180deg, #F1E9FF 0%, #FFFFFF 100%)'
            }
          })
          // this.state.specialData.map((item)=>{
          //   if(item.name == 'PPT生成资源包'){
          //     item['unit'] = '次'
          //   } 
          // })
          this.setState(prevState => ({
            specialData: prevState.specialData.filter(item => item.name !== '初始化')
          }));
      } else {
        this.setState({
          loading: false,
          loadingbody: false,
          no_data: true,
        });
      }
    });
    console.log(this.state.specialData);
  }

  getenginepackage() {
    let page = {
      // per_page: this.state.pagination.pageSize,
      // page: this.state.pagination.current,
      type: this.state.type_name === "全部资源" ? "" : this.state.type_name,
      card: true,
    };
    getenginepackage(page).then((res) => {
      if (res.code === 200) {
        let list = res.data;
        if (list.data.length !== 0) {
          this.setState({
            data: list.data,
            loading: false,
            loadingbody: false,
            no_data: list.data.length > 0 ? false : true,
            // no_data: true,
            scrollLoading: false,
          });
        } else {
          this.setState({
            loading: false,
            loadingbody: false,
            no_data: true,
          });
        }
      } else {
        this.setState({
          loading: false,
          loadingbody: false,
          no_data: true,
        });
      }
    });
  }
  onsetPosition(value) {
    this.setState({
      data: [],
      type_name: value,
      scrollLoading: <Spin loading={true} />,
    });
    setTimeout(() => {
      this.getenginepackage();
    }, 100);
  }
  support(e){
    e.stopPropagation()
    e.preventDefault()
    this.setState({
      ModalContactService: true,
    })
  }
  onCancelContact(value) {
    this.setState({
      ModalContactService: value,
    })
  }
  onClickBtn() {
    history.push("/engineservices");
    history.go();
  }
  onRechargen() {
    history.push("/payment/accountrecharge");
    history.go();
  }
  onPromotion(res) {
    history.push("/myapp/resources");
    localStorage.setItem("record", JSON.stringify(res));
    history.go();
  }
  onUpgrade(res, e) {
    e.stopPropagation();
    history.push("/promotion");
    localStorage.setItem("record", JSON.stringify(res));
    history.go();
  }
  openModal(val) {
    let title = "";
    if (this.state.balance < 100) {
      title =
        "<h4>余额不足，请充值</h4><span>开通自动月结功能，需有至少100元账户余额，请充值后再开通。</span>";
    } else {
      title = this.state.isautomatic
        ? "<h4>即将关闭月结计费模式</h4><span>自动月结关闭后，资源包消耗完毕，将自动暂停资源服务</span>"
        : "<h4>即将开启月结计费模式</h4><span>开启月结后，资源使用将按实时消耗计费，月底从账户余额扣除总额。</span>";
    }
    this.setState({
      visible: true,
      modaltext: title,
    });
  }
  //金额的千分位转化
  formatAmount = (amount) => {
    // 将金额转换为字符串
    let formattedAmount = String(amount);
  
    // 检查是否存在小数点
    const hasDecimal = formattedAmount.includes('.');
  
    // 如果没有小数点，则添加 .00
    if (!hasDecimal) {
      formattedAmount += '.00';
    }
  
    // 将金额按照千位分隔符分组
    formattedAmount = formattedAmount.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  
    // 添加货币符号
    formattedAmount = `${formattedAmount}`;
  
    return formattedAmount;
  };
  onCancel(val) {
    this.setState({
      visible: false,
    });
    if (val === 1) {
      if (this.state.balance < 100) {
        this.onRechargen();
      } else {
        if (this.state.isautomatic) {
          close_settlement().then((res) => {
            if (res.code === 200) {
              this.getbalance();
            } else {
              Notification.warning({ content: res.msg });
            }
          });
        } else {
          open_settlement().then((res) => {
            if (res.code === 200) {
              this.getbalance();
            } else {
              Notification.warning({ content: res.msg });
            }
          });
        }
      }
    }
  }
  render() {
    const {
      loadingbody,
      available_limit,
      balance,
      credit_limit,
      give_limit,
      used_limit, 
      amount_in_arrears,
      isautomatic,
      positionList,
      type_name,
      data,
      specialData,
      scrollLoading,
      maxHeight,
      no_data,
      special_no_data,
      Selectoptions,
      productstyle,
      visible,
      modaltext,
      imgList,
      ModalContactService
    } = this.state;
    return (
      <div className="payment-formation">
        {loadingbody ? (
          <Spin loading={loadingbody} dot className="loadingbody"></Spin>
        ) : (
          <>
            <div className="Header-init">
              <div className="Header-title">资源费用</div>
            </div>
            <div className="payment-balance columnSpaceStart">
              <div className="payment-label rowStartWrap">
                <svg
                  t="1652086423363"
                  className="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="2461"
                  width="18"
                  height="18"
                  data-spm-anchor-id="a313x.7781069.0.i5"
                >
                  <path
                    d="M512.512 47.616c-254.464 0-460.8 206.336-460.8 460.8s206.336 460.8 460.8 460.8 460.8-206.336 460.8-460.8-206.336-460.8-460.8-460.8z m144.896 445.952v46.592h-114.176v64.512h114.176v47.616h-114.176v93.696H484.352v-93.696H365.568v-47.616h118.272v-64.512H365.568v-46.592h100.864l-122.88-211.456H409.6c57.856 104.96 92.672 172.032 104.96 200.192h1.024c4.096-11.776 15.872-34.816 34.304-70.144l70.656-130.048h62.464l-124.416 211.968h98.816z"
                    fill="#DEAB4F"
                    p-id="2462"
                    data-spm-anchor-id="a313x.7781069.0.i4"
                    className="selected"
                  ></path>
                </svg>
                <span className="AlibabaPuHuiTi-Medium">可用余额</span>
              </div>
              <div className="payment-end GroupTable-fixe-end">
                <div className="rowFlexStartEnd">
                  <Statistic
                    extra=""
                    style={{ marginBottom: "-12px" }}
                    styleValue={{ fontSize: "44px" }}
                    // value={balance}
                    value={available_limit}
                    groupSeparator
                    precision={2}
                    prefix="¥"
                  />
                  <Button
                    type="primary"
                    className="btn-Recharge"
                    onClick={this.onRechargen}
                  >
                    我要充值
                  </Button>
                  <div className="monthly-settlement">
                    <Tooltip
                      className="monthly-tooltip"
                      color="#FFFFFF"
                      position="bl"
                      trigger="hover"
                      content="开通自动月结功能后，当资源包消耗完毕，系统计费将切入实时计费模式，
                      并在月底结算当月消耗费用，自动从账户余额中扣除"
                    >
                      <IconQuestionCircleFill />
                    </Tooltip>
                    <span>自动月结</span>
                    <Switch
                      checked={isautomatic}
                      onChange={this.openModal.bind(this)}
                    />
                  </div>
                  <Link href="/engineservices/billingmethod" onClick={this.support.bind(this)}>查看计费方式</Link>
                </div>
                <div>
                  <Space
                    split={<Divider type="vertical" />}
                    className="Link-btn"
                  >
                    <Link href="/payment/details" style={{color: 'gray',cursor: 'not-allowed',pointerEvents: 'none'}}>收支明细</Link>
                    <Link href="/payment/administration">发票管理</Link>
                    <Link href="/payment/order">订单管理</Link>
                  </Space>
                </div>
              </div>
              <div className="payment-prcieDetail">
                  <div className="payment-prcieDetail-lable AlibabaPuHuiTi-Medium">
                    <span><span className="total-money-symbol">
                    ¥</span>{this.formatAmount(available_limit)} </span> = <span className="money-symbol">
                    ¥</span>{this.formatAmount(balance)} + <span className="money-symbol">
                    ¥</span>{this.formatAmount(credit_limit)} + <span className="money-symbol">
                    ¥</span>{this.formatAmount(give_limit)} - <span className="money-symbol">
                    ¥</span>{this.formatAmount(used_limit)} - <span className="money-symbol">
                    ¥</span>{this.formatAmount(amount_in_arrears)} </div>
                  <div className="payment-prcieDetail-text AlibabaPuHuiTi-Regular">可用余额 (¥) = 充值余额 + 信用额度 + 附送额度 - 使用金额 - 欠费金额 </div>
              </div>
            </div>
            
            {/* <div className="GroupTable-fixe" id="result_title">
              <Radio.Group
                type="button"
                name="direction"
                value={type_name}
                onChange={this.onsetPosition.bind(this)}
                options={positionList}
                className="GroupTable"
              ></Radio.Group>
              <div>
                <Button
                  className="custom-White-button"
                  onClick={this.onClickBtn.bind(this)}
                >
                  全部引擎服务
                </Button>
              </div>
            </div> */}
            

          { special_no_data ? ( <></> ) : (

              <>
                <div className="Header-init">
                  <div className="Header-title">专用资源</div>
                </div>
                <div className="loadingList" >
                    <List
                      bordered={false}
                      style={{ minHeight: "100%" }}
                      scrollLoading={scrollLoading}
                      dataSource={specialData}
                      noDataElement={
                        <Empty
                          imgSrc={Emptydata_img}
                          description={"暂无引擎资源"}
                        />
                      }
                      render={(item, index) => (
                        <List.Item
                          key={index}
                          onClick={this.onPromotion.bind(this, item)}
                        >
                          <div className="engine-resources chappt-resources">
                            <div
                              className="resources"
                              style={{
                                borderColor: 'rgba(64, 110, 255, 1)',
                                "--gradientcolor":
                                  "linear-gradient(180deg, #F0F4FF 50%, rgba(255,255,255,0.00) 100%)",
                              }}
                            >
                              { item.model_capability =='PPT' ? 
                              (<div className="bluemodel">chatPPT</div>) :
                               (<div className="bluemodel">{item.name}</div>)}
                              <div className="resources-box">
                                {/* <div className="resources-title AlibabaPuHuiTi-Medium">
                                  {Selectoptions[item.model_capability].label}
                                </div> */}
                                {item.is_free ? (
                                  <Statistic
                                    extra={
                                      Selectoptions[item.model_capability].label
                                    }
                                    value="限时免费"
                                    groupSeparator
                                  />
                                ) : (
                                  <Statistic
                                    extra={
                                      item.name
                                    }
                                    value={item.balance}
                                    groupSeparator
                                    precision={0}
                                    suffix={item.unit}
                                  />
                                )}

                                <div className="resources-type GroupTable-fixe-end">
                                  <span
                                    className="resources-group"
                                    // style={{
                                    //   "--color": productstyle[item.group].textcolor,
                                    // }}
                                  >
                                    {/* #{item.group} */}
                                  </span>
                                  <div className="resources-icon">
                                    <img
                                      src={
                                        imgList[item.model_capability] ? imgList[item.model_capability] : imgList['TextFree']
                                      }
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="upgrade">
                                <span
                                  onClick={this.onUpgrade.bind(this, item)}
                                  // style={{
                                  //   "--color": productstyle[item.group].textcolor,
                                  // }}
                                >
                                  升级资源包
                                  <IconRight />
                                </span>
                              </div>
                            </div>
                          </div>
                        </List.Item>
                      )}
                    />
                </div>
              </>
          )}


            {/* 通用资源 */}
            
            { no_data ? ( <></> ) : (

              <>
                  <div className="Header-init">
                    <div className="Header-title">通用资源</div>
                  </div>
                  <div className="loadingList" >
                  <List
                        bordered={false}
                        style={{ minHeight: "100%" }}
                        scrollLoading={scrollLoading}
                        dataSource={data}
                        noDataElement={
                          <Empty
                            imgSrc={Emptydata_img}
                            description={"暂无引擎资源"}
                          />
                        }
                        render={(item, index) => (
                          <List.Item
                            key={index}
                            onClick={this.onPromotion.bind(this, item)}
                          >
                            <div className="engine-resources">
                              <div
                                className="resources"
                                style={{
                                  borderColor: item.color,
                                  "--gradientcolor":
                                    item.bagcolor,
                                }}
                              >
                                <div className="resources-box">
                                  {item.is_free ? (
                                    <Statistic
                                      extra={
                                        Selectoptions[item.model_capability].label
                                      }
                                      value="限时免费"
                                      groupSeparator
                                    />
                                  ) : (
                                    <Statistic
                                      extra={
                                        item.name
                                      }
                                      value={item.balance}
                                      groupSeparator
                                      precision={0}
                                      suffix={item.unit}
                                    />
                                  )}

                                  <div className="resources-type GroupTable-fixe-end">
                                    <span
                                      className="resources-group"
                                      // style={{
                                      //   "--color": productstyle[item.group].textcolor,
                                      // }}
                                    >
                                      {/* #{item.group} */}
                                    </span>
                                    <div className="resources-icon">
                                      <img
                                        src={
                                          imgList[item.model_capability] ? imgList[item.model_capability] : imgList['TextFree']
                                        }
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="upgrade">
                                  <span
                                    onClick={this.onUpgrade.bind(this, item)}
                                    // style={{
                                    //   "--color": productstyle[item.group].textcolor,
                                    // }}
                                  >
                                    升级资源包
                                    <IconRight />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </List.Item>
                        )}
                      />
                  </div>
              </>
               
            )
            }
           

            

            <div className="payment-explain">
              <div className="payment-explain-lable AlibabaPuHuiTi-Medium">
                <IconStarFill />
                使用说明
              </div>
              <div>
                1.创建应用或模型后，系统自动开启对应的引擎管理模块，并下发账户等级对应的免费基础资源包，基础资源包每月下发，额度当月有效，不可冻结或累加。
                <br />
                2.系统采用资源包调用和实时调用两种资源调用模式，资源包调用，需预购买资源包，使用时在资源包扣除对应的资源量，实时调用则以实际调用字符来核算调用资源，每月月底，根据当月字符调用量进行费用结算。
                <br />
                3.系统资源结算顺序为 基础资源包 &gt; 预付资源包 &gt;
                月结，即当资源包额度消耗完之后产生的调用额度，以实时调用方式计费，并在月底进行结算。若用户在购买资源包时，选择自动续费服务，则资源包额度消耗完后，将以基本用户开通服务的订单为据，自动为用户购买资源包，以预付方式继续使用调用服务。
                <br />
                4.自动月结和自动续费功能，需用户手动开通后生效。
              </div>
            </div>
          </>
        )}
        <Modal
          title="操作提示"
          visible={visible}
          onCancel={() => this.onCancel(0)}
          onOk={() => {
            this.onCancel(1);
          }}
          okText={this.state.balance < 100 ? "充值" : "确定"}
          cancelText="取消"
          className="custom-modal renew-modal"
        >
          <div className="custom-box">
            <div dangerouslySetInnerHTML={{ __html: modaltext }}></div>
          </div>
        </Modal>
        <Modal
          visible={ModalContactService}
          title={<div style={{ textAlign: 'left' }}>专属客服</div>}
          footer={null}
          className="Contactmodal"
          maskStyle={{ background: 'rgba(33,85,163,0.16)' }}
          onCancel={() => {
            this.onCancelContact(false)
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <div className="serviceQRcode"></div>
            <Typography>您好，请扫码添加专属客服</Typography>
          </div>
        </Modal>
      </div>
    );
  }
}
export default Paymentinformation;
