import * as d3 from "d3";

let D3V5KG = {
    nodesNameMap: {},
    nodes: {},
    links: [],
    containerId: '',
    config: {
        width: 1600,
        height: 800,
        keyword: '',
        circleRadius: 30,
        circleRadiusMin: 20,
        circleRadiusMax: 60,
        dbClickCallback: null,
        clickCallback: null
    },
    moreInfo: null, // hover提示层
    svg: null, // svg画布
    forceSimulation: null, // 力导向图
    currentNode: null, // 当前选中的节点
    centerNodeName: null, // 中间那个节点的名字
    edgesLine: null, // 边
    edgesText: null, // 边上文字
    entities: null, // 圆实体
    frequencyNodes: {}, // 当前激活的权重节点数据
    colorsMapNodePoint: 0, // 配色循环指针
    colorsMapLinkPoint: 0, // 配色循环指针
    colorsMapNode: {}, // 为node分配方案
    colorsMapLink: {}, // 为link分配配色方案
    nodeColors: [ // 默认的节点配色方案
        {fill: '#ffd7bd', stroke: '#cd895c', text: '#cd895c'},
        {fill: '#ffc6c6', stroke: '#d87473', text: '#b84443'},
        {fill: '#ffd6e9', stroke: '#bf688f', text: '#a43f6c'},
        {fill: '#e3eeff', stroke: '#90add8', text: '#4c6ea2'},
        {fill: '#e2dffe', stroke: '#8d87c6', text: '#6b65a8'},
        {fill: '#f1f2cf', stroke: '#b1b375', text: '#7f8139'},
        {fill: '#def5f8', stroke: '#a1c9cf', text: '#58929a'},
        {fill: '#e1f6e5', stroke: '#a0c2a6', text: '#608266'},
        {fill: '#efe3f8', stroke: '#b2a1be', text: '#7e668f'},
        {fill: '#f2f2f2', stroke: '#b6b5b5', text: '#7f7f7f'},
        {fill: '#f2e8e4', stroke: '#c3a397', text: '#8c6e62'},
        {fill: '#eae8f3', stroke: '#b4afcb', text: '#5f5a74'},
        {fill: '#f1e5ef', stroke: '#bda7b9', text: '#845d7e'},
        {fill: '#f0f9ff', stroke: '#baccd8', text: '#506370'},
    ],
    clearNode: function(){
        D3V5KG.currentNode = null;
        D3V5KG.nodesNameMap = {};
        D3V5KG.nodes = {};
        D3V5KG.links = [];
    },
    initColorsMap: function () {
        D3V5KG.colorsMapNode = {};
        D3V5KG.colorsMapLink = {};
        let length = D3V5KG.nodeColors.length;

        Object.keys(D3V5KG.nodes).forEach(function (key) {
            let type = !D3V5KG.nodes[key].type ? (D3V5KG.nodes[key].type = 'default') : D3V5KG.nodes[key].type;

            if (D3V5KG.colorsMapNode[type] === undefined) {
                D3V5KG.colorsMapNode[type] = D3V5KG.nodeColors[D3V5KG.colorsMapNodePoint];
                D3V5KG.colorsMapNodePoint = (D3V5KG.colorsMapNodePoint + 1) % length;
            }
        });

        Object.keys(D3V5KG.links).forEach(function (key) {
            let type = !D3V5KG.links[key].type ? (D3V5KG.links[key].type = 'default') : D3V5KG.links[key].type;

            if (D3V5KG.colorsMapLink[type] === undefined) {
                D3V5KG.colorsMapLink[type] = D3V5KG.nodeColors[D3V5KG.colorsMapLinkPoint];
                D3V5KG.colorsMapLinkPoint = (D3V5KG.colorsMapLinkPoint + 1) % length;
            }
        });
    },
    initData: function (data) {
        D3V5KG.nodes = data.nodes || [];
        D3V5KG.links = data.links || [];

        D3V5KG.centerNodeName = null;

        D3V5KG.links.forEach(function (link) {
            link.id = link.source + ':' + link.target;
            link.source = D3V5KG.nodes[link.source];
            D3V5KG.nodesNameMap[link.source.name] = link.source;
            link.target = D3V5KG.nodes[link.target];
            D3V5KG.nodesNameMap[link.target.name] = link.target;

            if(D3V5KG.centerNodeName === null){
                D3V5KG.centerNodeName = link.source.name;
            }
        });
    },
    init: function (containerId, data, config) {

        if(!d3){
            throw '加载类库失败！';
        }

        D3V5KG.containerId = containerId;
        Object.assign(D3V5KG.config, config);

        D3V5KG.initData(data);
        D3V5KG.initColorsMap();

        // 设置图形的中心位置
        let x = D3V5KG.config.width / 2;
        let y = D3V5KG.config.height / 2;

        D3V5KG.forceSimulation = d3.forceSimulation(d3.values(D3V5KG.nodes))
            .force('link', d3.forceLink(D3V5KG.links).distance(5 * D3V5KG.config.circleRadius))//  弹簧模型
            .force('charge', D3V5KG.links.length > 0 ? d3.forceManyBody().strength(-1000) : d3.forceManyBody().strength(0))//  节点之间模拟电荷作用力
            .force('center', d3.forceCenter(x, y))
            .force("collision", d3.forceCollide((D3V5KG.links.length > 0 ? 0.5 : 1.2) * D3V5KG.config.circleRadius)); //  避免重叠节点碰撞重叠


        // 添加svg元素并设置宽高与缩放
        if(d3.select(D3V5KG.containerId).select('svg').size() > 0){
            d3.select(D3V5KG.containerId).select('svg').remove();
        }
        D3V5KG.svg = d3.select(D3V5KG.containerId).append('svg')
            .attr('width', D3V5KG.config.width)
            .attr('height', D3V5KG.config.height)
            .call(d3.zoom().scaleExtent([0.05, 2]).on('zoom', function () {
                D3V5KG.svg.selectAll('.g-yy-first').attr('transform', 'translate(' + d3.event.transform.x + ',' + d3.event.transform.y + ') scale(' + d3.event.transform.k + ')');
            }));

        // 添加一个可引用的marker标签来绘制箭头
        D3V5KG.svg.append('marker')
            .attr('id', 'resolved')// 箭头id，用于其他标记进行引用时的url
            .attr('markerUnits', 'userSpaceOnUse')// 定义标记的坐标系统，userSpaceOnUse表示按照引用的元件来决定，strokeWidth按照用户单位决定
            .attr('viewBox', '0 -5 10 10')// 坐标系的区域
            .attr('refX', 35)// 箭头坐标
            .attr('refY', 0)
            .attr('markerWidth', 12)// 标识的大小
            .attr('markerHeight', 12)
            .attr('orient', 'auto')// 绘制方向，可设定为：auto（自动确认方向）和 角度值
            .attr('stroke-width', 3)// 箭头宽度
            .append('path')
            .attr('d', 'M0,-5L10,0L0,5')// 绘制箭头，路径为一个三角形
            .attr('fill', '#666');// 箭头颜色

        // 圆圈的提示文字 根据需要到数据库中进行读取数据
        if(d3.select('body').select('.more-info').size() === 0){
            D3V5KG.moreInfo = d3.select('body')
                .append('div')// 添加div并设置成透明
                .attr('class', 'more-info')
                .style('opacity', 0.0);
        }

        // 绘制边
        D3V5KG.edgesLine = D3V5KG.svg.append('g').attr('class', 'g-yy-first g-yy-edges-lines')
            .selectAll('.edgePath')
            .data(D3V5KG.links)
            .enter()
            .append('path')
            .attr('stroke', function (link) {
                return D3V5KG.colorsMapLink[link.type].text;
            })
            .attr('stroke-width', 1)
            .attr('d', function (d) {
                return 'M ' + d.source.x + ' ' + d.source.y + ' L ' + d.target.x + ' ' + d.target.y
            })
            .attr('class', 'edgePath')
            .attr('id', function (d, i) {
                return 'edgePath-' + i;
            })
            .attr('marker-end', 'url(#resolved)');// 根据箭头标记的id号引用箭头

        // 边上说明文字
        D3V5KG.edgesText = D3V5KG.svg.append('g').attr('class', 'g-yy-first g-yy-edges-texts')
            .selectAll('.edgeText')
            .data(D3V5KG.links)
            .enter()
            .append('text')
            .attr('class', 'edgeText')
            .attr('dx', 60)
            .attr('dy', -2);

        D3V5KG.edgesText.append('textPath')
            .attr('xlink:href', function (d, i) {
                return '#edgePath-' + i
            })
            .attr('fill', function (link) {
                return D3V5KG.colorsMapLink[link.type].text;
            })
            .style('pointer-events', 'none')
            .text(function (d) {
                return d.rela.substr(0, 10);
            })

        // 绘制实体节点
        D3V5KG.entities = D3V5KG.svg.append('g').attr('class', 'g-yy-first g-yy-circles')
            .selectAll('circle')
            .data(d3.values(D3V5KG.nodes))
            .enter()
            .append('g')
            .attr('transform', function (d, i) {
                return 'translate(' + d.x + ',' + d.y + ')';
            });

        D3V5KG.entities.on('click', function (node, i) {
            // 单击时让连接线加粗
            // 再次点击还原
            D3V5KG.edgesLine.style('stroke-width', function (line) {
                // 当与连接点连接时变粗
                if ((line.source.name === node.name || line.target.name === node.name)) {
                    if (line.focus && node.focus) {
                        line.focus = false;
                        return 1;
                    } else {
                        line.focus = true;
                        return 2.5;
                    }
                } else {
                    return 1;
                }

            });
            D3V5KG.entities.style('stroke-width', 1);// 所有的圆圈边框
            // 焦点取反
            node.focus = !node.focus
            // 判断是不是点击的同一个node
            if (D3V5KG.currentNode !== node && D3V5KG.currentNode != null) {
                D3V5KG.currentNode.focus = false
            }
            // 进行判断
            if (node.focus) {
                // 被选中的圆圈边框
                d3.select(this).style('stroke-width', 2.5);
            } else {
                d3.select(this).style('stroke-width', 1);
            }

            D3V5KG.currentNode = node;

            node.focus && typeof D3V5KG.config.clickCallback === 'function' && D3V5KG.config.clickCallback(node, i);
        }).on('dblclick', function (d, i) {
            // 双击节点时节点恢复拖拽
            d.fixed = false;

            typeof D3V5KG.config.dbClickCallback === 'function' && D3V5KG.config.dbClickCallback(d, i);
        }).on('mouseover', function (d) {
            let content = d.name;

            if (d.type) {

                if(D3V5KG.frequencyNodes[d.type] && D3V5KG.frequencyNodes[d.type][d.name]){
                    content += ' (' + D3V5KG.frequencyNodes[d.type][d.name] + ')';
                }else{
                    content += ' [' + d.type + ']';
                }
            }

            D3V5KG.moreInfo.html(content)
                .style('color', D3V5KG.colorsMapNode[d.type].text)
                .style('border-color', D3V5KG.colorsMapNode[d.type].stroke)
                .style('background-color', D3V5KG.colorsMapNode[d.type].fill)
                .style('left', (d3.event.pageX) + 'px')
                .style('top', (d3.event.pageY + 20) + 'px')
                .style('opacity', 1.0);

        })
            .on('mousemove', function (d) {
                D3V5KG.moreInfo.style('left', (d3.event.pageX) + 'px').style('top', (d3.event.pageY + 20) + 'px');
            })
            .on('mouseout', function (d) {
                D3V5KG.moreInfo.style('opacity', 0.0);
            })
            .call(d3.drag()
                .on('start', D3V5KG.started)
                .on('drag', D3V5KG.dragged)
                .on('end', D3V5KG.ended)
            );

        D3V5KG.dealForceDataAfter();

        D3V5KG.forceSimulation.on('tick', D3V5KG.tick);
    },
    filter: function(type, data){
        D3V5KG.frequencyNodes = {};
        let circleSet = {};

        if(type){
            D3V5KG.frequencyNodes[type] = data;
            const values = Object.values(data);
            const min = Math.min.apply(null, values);
            const max = Math.max.apply(null, values);


            let step = 0;

            if(max !== min && min !== -1 && max !== -1){
                step = (D3V5KG.config.circleRadiusMax - D3V5KG.config.circleRadiusMin) / (max - min);

                for (let i in data){
                    circleSet[i] = D3V5KG.config.circleRadiusMax - (max - data[i]) * step;
                }
            }
        }

        D3V5KG.svg.selectAll('circle').attr('r', function (node) {
            if(circleSet[node.name]){
                return circleSet[node.name];
            }else{
                return D3V5KG.config.circleRadius;
            }
        });

        D3V5KG.svg.selectAll('.g-yy-circles g').attr('opacity', function (node) {
            if(data[node.name] || D3V5KG.centerNodeName === node.name || !type){
                return 1;
            }else{
                return 0.2;
            }
        });

        D3V5KG.svg.selectAll('.g-yy-edges-lines path').attr('opacity', function (link) {
            let node = link.target;

            if(data[node.name] || !type){
                return 1;
            }else{
                return 0.2;
            }
        }).attr('marker-end', 'url(#resolved)');

        D3V5KG.svg.selectAll('.g-yy-edges-texts text').attr('opacity', function (link) {
            let node = link.target;

            if(data[node.name] || !type){
                return 1;
            }else{
                return 0.2;
            }
        });

        D3V5KG.forceSimulation.alphaTarget(0.8).restart();
    },
    tick: function(){// 同步线与节点数据

        D3V5KG.edgesLine.attr('d', function (d) { //连接线
            return 'M ' + d.source.x + ' ' + d.source.y + ' L ' + d.target.x + ' ' + d.target.y;
        });

        D3V5KG.edgesText.attr('transform', function (d, i) {
            if (d.target.x < d.source.x) {
                let bbox = this.getBBox();
                let rx = bbox.x + bbox.width / 2;
                let ry = bbox.y + bbox.height / 2;
                return 'rotate(180 ' + rx + ' ' + ry + ')';
            } else {
                return 'rotate(0)';
            }
        }).attr('dx', function (d, i) {
            //设置文字一直显示在线的中间
            return Math.sqrt(Math.pow(d.target.x - d.source.x, 2) + Math.pow(d.target.y - d.source.y, 2)) / 2 - 20;
        });

        D3V5KG.entities.attr('transform', function (d) {
            return 'translate(' + d.x + ',' + d.y + ')';
        });
    },
    dealForceDataAfter: function(){
        // 绘制节点
        D3V5KG.entities.append('circle')
            .attr('r', D3V5KG.config.circleRadius)
            .attr('fill', function (node) {
                return D3V5KG.colorsMapNode[node.type].fill;
            }).attr('stroke', function (node) {
            return D3V5KG.colorsMapNode[node.type].stroke;
        });

        // 节点文字
        D3V5KG.entities.append('text')
            .attr('x', -10)
            .attr('y', -20)
            .attr('dy', '.35em')//  文字下移
            .attr('text-anchor', 'middle')// 文字居中
            .style('fill', function (node) {
                return D3V5KG.colorsMapNode[node.type].text;
            })
            .attr('x', function (d) {
                let re_en = /^[a-zA-Z]+$/g;
                // 如果是全英文，不换行
                if (d.name.match(re_en)) {
                    d3.select(this).append('tspan')// 添加tspan用来方便时使用绝对或相对坐标来调整文本
                        .attr('x', 0)
                        .attr('y', 2)
                        .text(function () {
                            return d.name
                        });
                }
                // 如果小于8个字符，不换行
                else if (d.name.length <= 5) {
                    d3.select(this).append('tspan')
                        .attr('x', 0)
                        .attr('y', 2)
                        .text(function () {
                            return d.name
                        });
                } else if (d.name.length > 10) {// 大于10个字符时
                    let top = d.name.substring(0, 5);
                    let bot = d.name.substring(5, 10) + '...';

                    d3.select(this).text(function () {
                        return ''
                    });

                    d3.select(this).append('tspan')// 前n个字
                        .attr('x', 0)
                        .attr('y', -7)
                        .text(function () {
                            return top
                        });

                    d3.select(this).append('tspan')// 后n个字
                        .attr('x', 0)
                        .attr('y', 10)
                        .text(function () {
                            return bot
                        });

                } else {// 5-10字符分两行显示
                    let top = d.name.substring(0, 5);
                    let bot = d.name.substring(5, d.name.length);

                    d3.select(this).text(function () {
                        return ''
                    });

                    d3.select(this).append('tspan')
                        .attr('x', 0)
                        .attr('y', -7)
                        .text(function () {
                            return top
                        });

                    d3.select(this).append('tspan')
                        .attr('x', 0)
                        .attr('y', 10)
                        .text(function () {
                            return bot
                        });
                }
            });
    },
    started: function (d) {
        if (!d3.event.active) {
            D3V5KG.forceSimulation.alphaTarget(0.8).restart();
        }
        d.fx = d.x;
        d.fy = d.y;
    },
    dragged: function (d) {
        d.fixed = true;
        d.fx = d3.event.x;
        d.fy = d3.event.y;
    },
    ended: function (d) {

        if (!d3.event.active) {
            D3V5KG.forceSimulation.alphaTarget(0);
        }
        d.fx = d3.event.x;
        d.fy = d3.event.y;
        // d.fx = null;
        // d.fy = null;
    }
};

export default D3V5KG;