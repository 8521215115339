import React, { Component } from "react";
import { Button, Result } from "@arco-design/web-react";
import { IconWechat, IconAlipayCircle } from "@arco-design/web-react/icon";
import { formatMoney, watermarkText } from "common/utils";
import "./index.scss";
import QRCode from "qrcode.react";
class PaymentAmount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      status: props.status, //error、success
      timeindex: props.timeindex,
      title: props.title,
      time: props.time,
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      data: nextProps.data,
      status: nextProps.status,
      timeindex: nextProps.timeindex,
      title: nextProps.title,
      time: nextProps.time,
    });
  }
  componentDidMount() {
    let html = document.getElementById("watermark");
    watermarkText(html, "韦尼克创作平台", {
      width: 226,
      height: 226,
      start: -35,
      angle: -25,
    });
  }
  render() {
    return (
      <div className="paymentAmount">
        <div className="paymentAmount-box">
          {!this.state.status ? (
            <>
              <div className="paymentAmount-desc">
                订单号：
                <span className="paymentAmount-id AlibabaPuHuiTi-Medium">
                  {this.state.data.order_sn}
                </span>
                支付金额：
                <span className="paymentAmount-money AlibabaPuHuiTi-Medium">
                  {formatMoney(this.state.data.pay_amount)}
                </span>
                <span className="AlibabaPuHuiTi-Medium"> 元</span>
              </div>
              <div className="paymentAmount-QR-box">
                <div className="paymentAmount-QR-time">
                  二维码 {this.state.time} 秒后刷新
                </div>
                <div className="paymentAmount-QR">
                  <QRCode
                    value={this.state.data.pay_url}
                    size={198}
                    id="qrCode"
                    level="Q" // 二维码安全等级 默认值L 类型为string 一共有 L M Q H 四种值安全等级由低到高 安全等级越高生成的二维码越密集
                    includeMargin={true}
                    imageSettings={{
                      // 配置二维码中间出现的logo信息
                      src: "//image.yoojober.com/upload-m/2023-11/656494742574b.png", // logo的地址 可以是在线图片也可以是本地图片 没有默认值 类型为string
                      width: 50, // logo的宽度 默认值是整个二维码的10% 类型为number
                      height: 50, // logo的高度 默认值是整个二维码的10% 类型为number
                      excavate: false, // 是否是镂空状态 默认值false 类型为boolean
                    }}
                  />
                </div>
                {/* <div className="paymentAmount-QR-watermark"></div> */}
                {this.state.title ? (
                  <div className="paymentAmount-title">
                    购买服务：{this.state.title}
                  </div>
                ) : null}
              </div>
              <div>
                <IconWechat />
                <IconAlipayCircle />
              </div>
              <div className="paymentAmount-lable">支持微信/支付宝扫码支付</div>
              <Button
                type="primary"
                className="paymentAmount-cancel"
                onClick={this.props.onback.bind(this)}
              >
                稍后支付
              </Button>
            </>
          ) : (
            <>
              {this.state.status === "success" ? (
                <Result
                  status="success"
                  title="订单支付成功！"
                  subTitle={
                    `订单已支付完成，` + this.state.timeindex + `秒后自动返回`
                  }
                  extra={[
                    <Button
                      key="back"
                      type="primary"
                      onClick={this.props.onback.bind(this)}
                    >
                      返回
                    </Button>,
                  ]}
                ></Result>
              ) : (
                <Result
                  status="error"
                  title="订单支付失败！"
                  subTitle={
                    `订单支付失败，` + this.state.timeindex + `秒后自动返回`
                  }
                  extra={[
                    <Button
                      key="back"
                      type="primary"
                      onClick={this.props.onback.bind(this)}
                    >
                      返回
                    </Button>,
                  ]}
                ></Result>
              )}
            </>
          )}
        </div>
        <div className="payment_watermark" id="watermark"></div>
      </div>
    );
  }
}

export default PaymentAmount;
