import React, { Component } from "react";
import {
  Space,
  Button,
  Statistic,
  Divider,
  Link,
  Checkbox,
  Input,
  Radio,
  Modal,
  Table,
  Empty,
  Card,
  Tag,
  Switch,
  Notification,
  Spin,
} from "@arco-design/web-react";
import {
  IconClose,
  IconDelete,
  IconSettings,
  IconEyeInvisible,
  IconEye,
} from "@arco-design/web-react/icon";
import {
  order_list,
  delete_order,
  saas_cancel,
  saas_detail,
  order_detail,
  invoice_apply,
  invoice_info,
} from "api/apis";
import { addStar, formatMoney } from "common/utils";
import PaymentAmount from "components/PaymentAmount";
import ConfirmOrder from "components/ConfirmOrder";
import Emptydata_img from "asserts/Emptydata_img.svg";
import history from "common/utils/history";
import { sourceCancelClass } from "api/cancel-request";
import "./index.scss";
// 封装组件
class Paymentinorder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingbody: true,
      isautomatic: false,
      data: [],
      pagination: {
        sizeCanChange: false,
        showTotal: true,
        total: 0,
        pageSize: 10,
        current: 1,
        pageSizeChangeResetCurrent: true,
        showJumper: false,
      },
      loading: false,
      no_data: false,
      keyword: "",
      checkbox: false,
      selectedRowKeys: [],
      type: "checkbox",
      checkAll: false,
      indeterminate: false,
      SelectcheckAll: false,
      type_name: "全部订单", //模型类型；1-定制，9-标准
      positionList: ["全部订单", "待付款", "已付款", "已取消"],
      columns: [
        {
          title: "订单号",
          dataIndex: "order_sn",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
            height: 68,
          },
          width: 350,
        },
        {
          title: "产品服务",
          dataIndex: "product_service",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
        {
          title: "服务资源",
          dataIndex: "title",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
        {
          title: "订单金额",
          dataIndex: "price",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          render: (col, record, index) => formatMoney(col),
        },
        {
          title: "订单状态",
          dataIndex: "status", //'WAIT_PAID','PAID','CANCEL'
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          render: (col, record, index) => (
            <span>
              {
                <Tag
                  color={
                    col === "PAID"
                      ? "green"
                      : col === "WAIT_PAID"
                      ? "orange"
                      : "gray"
                  }
                >
                  {col === "PAID"
                    ? "已付款"
                    : col === "WAIT_PAID"
                    ? "待付款"
                    : "已取消"}
                </Tag>
              }
            </span>
          ),
        },
        {
          title: "创建时间",
          dataIndex: "created_at",
          align: "center",
          width: 200,
          headerCellStyle: {
            textAlign: "center",
          },
          render: (col, record, index) => <span>{col}</span>,
        },
      ],
      order: null,
      typeindex: 0,
      timeindex: 3,
      timer: null,
      timerindex: null,
      visible: false,
      status: "",
      pay_amount: 0,
      payment: {},
      retry_time: 10,
      payment_title: "",
      oldpay_url: "",
      listorder: [],
      order_sn: "",
      formLable: {
        type: "抬头类型",
        invoice_type2: "发票类型",
        invoice_name: "发票抬头",
        invoice_taxpayer_no: "纳税人识别号",
        invoice_bank_name: "基本开户银行",
        invoice_bank_no: "基本开户账号",
        invoice_company_address: "企业注册地址",
        invoice_phone: "企业注册电话",
      },
      formValue: {
        type: "",
        invoice_type2: "",
        invoice_name: "",
        invoice_taxpayer_no: "",
        invoice_bank_name: "",
        invoice_bank_no: "",
        invoice_company_address: "",
        invoice_phone: "",
      },
      visibleinfo: false,
      visiblenoinfo: false,
      isopeneye: true,
      description: "暂无相关订单",
    };
  }

  componentDidMount() {
    clearInterval(this.state.timer);
    clearInterval(this.state.timerindex);
    this.getorder_list();
  }
  UNSAFE_componentDidUpdate() {}

  getorder_list(loading) {
    this.setState({
      loading: loading,
    });
    let page = {
      per_page: this.state.pagination.pageSize,
      page: this.state.pagination.current,
      status:
        this.state.type_name === "已付款"
          ? "PAID"
          : this.state.type_name === "待付款"
          ? "WAIT_PAID"
          : this.state.type_name === "已取消"
          ? "CANCEL"
          : "",
    };
    order_list(page).then((res) => {
      if (res.code === 200) {
        let list = res.data;
        let pagination = this.state.pagination;
        pagination.total = list.count;
        this.setState({
          pagination: pagination,
          data: list.data,
          all_count: list.all_count,
          loading: false,
          paginationSwitch: list.all_count === 0 ? false : true,
          loadingbody: false,
          description: "暂无相关订单",
        });
        if (list.count === 0) {
          this.onClose();
        }
      } else {
        this.setState({
          loading: false,
          loadingbody: false,
          data: [],
          description: res.msg,
        });
      }
    });
  }
  onsetPosition(value) {
    //console.log(value, "value");
    let pagination = this.state.pagination;
    pagination.current = 1;
    this.setState({
      type_name: value,
      paginationSwitch: true,
      pagination: pagination,
      loading: false,
    });
    this.state.type_name = value;
    // setTimeout(() => {
    this.getorder_list();
    // }, 500)
  }
  setSelectedRowKeys(selectedRowKeys) {
    let selectkeys = [];
    this.state.data.forEach((i, index) => {
      if (i.enable_del && !i.enable_invoice) {
        selectkeys.push(i.order_sn);
      }
    });
    this.setState({
      selectedRowKeys: selectedRowKeys,
      SelectcheckAll:
        selectedRowKeys.length === selectkeys.length ? true : false,
    });
  }
  onChangeAll(checked) {
    let selectkeys = [];
    if (checked) {
      this.state.data.forEach((i, index) => {
        if (i.enable_del && !i.enable_invoice) {
          selectkeys.push(i.order_sn);
        }
      });
      this.setState({
        indeterminate: false,
        SelectcheckAll: true,
        selectedRowKeys: selectkeys,
      });
    } else {
      this.setState({
        indeterminate: false,
        SelectcheckAll: false,
        selectedRowKeys: selectkeys,
      });
    }
    //console.log(selectkeys, "selectedRowKeys");
  }
  onSettings() {
    this.setState({
      checkbox: true,
    });
    //console.log(this.state.type_name, " this.state.type_name");
  }
  onClose() {
    this.setState({
      checkbox: false,
      SelectcheckAll: false,
      selectedRowKeys: [],
    });
  }
  setalldelete() {
    this.setState({
      visibledelete: true,
      order: null,
    });
  }
  setVisibledelete(value, record) {
    this.onClose();
    this.setState({
      visibledelete: value,
      order: value ? record : null,
    });
    if (value && record) {
      this.setState({
        selectedRowKeys: [record.order_sn],
      });
    }
  }
  deleteuser() {
    let datas = {
      order_nos: JSON.stringify(this.state.selectedRowKeys),
    };
    this.setState({
      confirmLoading: true,
    });
    delete_order(datas).then((res) => {
      if (res.code === 200) {
        let setPagination = this.state.pagination;
        setPagination.current = 1;
        this.setState({
          selectedRowKeys: [],
          SelectcheckAll: false,
          confirmLoading: false,
          setPagination: setPagination,
        });
        this.onClose();
        this.setVisibledelete(false);
        this.getorder_list(true);
      } else {
        this.setState({
          confirmLoading: false,
        });
        Notification.error({ content: res.msg });
      }
    });
  }
  onChangeTable(pagination) {
    const { current, pageSize } = pagination;
    let setPagination = this.state.pagination;
    setPagination.current = current;
    setPagination.pageSize = pageSize;
    this.setState({
      loading: false,
      pagination: setPagination,
    });
    this.getorder_list();
  }
  setModalvisible(value, res) {
    this.setState({
      Modalvisible: value,
      order: res,
      visiblenoinfo: false,
      visibleinfo: false,
    });
  }
  getinvoice_info(value, res) {
    if (res) {
      let order_sn = [res.order_sn];
      this.onClose();
      this.setState({
        order: res,
        selectedRowKeys: order_sn,
      });
    }
    invoice_info().then((res) => {
      if (res.code === 200) {
        let obj = this.state.formValue;
        let isedit = false;
        if (res.data) {
          isedit = false;
          obj.type =
            res.data.type === 1
              ? "个人"
              : res.data.type === 2
              ? "企业"
              : res.data.type === 3
              ? "组织"
              : "";
          obj.invoice_type2 =
            res.data.invoice_type2 === 1
              ? "增值税普通发票"
              : res.data.invoice_type2 === 2
              ? "增值税专用发票"
              : "";
          obj.invoice_name = res.data.invoice_name;

          if (res.data.type === 1) {
            this.setState({
              formLable: {
                type: "抬头类型",
                invoice_type2: "发票类型",
                invoice_name: "发票抬头",
              },
              formValue: {
                type: obj.type,
                invoice_type2: obj.invoice_type2,
                invoice_name: obj.invoice_name,
              },
            });
          } else {
            obj.invoice_taxpayer_no = res.data.invoice_taxpayer_no;
            obj.invoice_bank_name = res.data.invoice_bank_name;
            obj.invoice_bank_no = res.data.invoice_bank_no;
            obj.invoice_company_address = res.data.invoice_company_address;
            obj.invoice_phone = res.data.invoice_phone;
            this.setState({
              formLable: {
                type: "抬头类型",
                invoice_type2: "发票类型",
                invoice_name: "发票抬头",
                invoice_taxpayer_no: "纳税人识别号",
                invoice_bank_name: "基本开户银行",
                invoice_bank_no: "基本开户账号",
                invoice_company_address: "企业注册地址",
                invoice_phone: "企业注册电话",
              },
              formValue: obj,
            });
          }
        } else {
          isedit = true;
        }
        this.setState({
          visiblenoinfo: isedit,
          visibleinfo: !isedit,
        });
      }
    });
  }
  setModalvisible_apply(value, res) {
    this.oninvoice_apply();
  }
  onViewDetails(record) {
    record.show_id = record.invoice_id;
    localStorage.setItem("record", JSON.stringify(record));
    history.push("/payment/invoicedetail");
    history.go();
  }
  godetailed() {
    history.push("/payment/invoiceinformation");
    history.go();
  }
  oninvoice_apply() {
    invoice_apply({
      order_nos: JSON.stringify(this.state.selectedRowKeys),
    }).then((res) => {
      if (res.code === 200) {
        Notification.success({ content: "申请成功！" });
        this.getorder_list();
        this.onClose();
        this.setModalvisible(false);
      } else {
        Notification.error({ content: res.msg });
      }
    });
  }
  openEye(val) {
    this.setState({
      isopeneye: val,
    });
  }
  // 取消订单
  onsaas_cancel() {
    saas_cancel({ order_sn: this.state.order?.order_sn }).then((res) => {
      if (res.code === 200) {
        Notification.success({ content: "取消成功!" });
        this.setModalvisible(false, null);
        this.getorder_list();
        this.setState({
          typeindex: 0,
        });
      } else {
        Notification.error({ content: res.msg });
      }
    });
  }
  onCancel(val) {
    if (val === 0) {
    } else {
      this.setState({
        visible: false,
      });
    }
  }
  settlement(res) {
    var oldpay_url = res.pay_url;
    var time = res.order_time;
    if (!time) {
      time = new Date().getTime();
    } else {
      localStorage.setItem("pay_time", time);
    }
    res.pay_url = `${res.pay_url}&time=${time}`;
    this.setState({
      payment: {
        pay_amount: res.price,
        order_sn: res.order_sn,
        pay_url: res.pay_url,
      },
      order: res,
      order_sn: res.order_sn,
      oldpay_url: oldpay_url,
      payment_title: res.title,
    });
    this.getsaas_detail(res.order_sn);
  }
  getsaas_detail(order_sn) {
    saas_detail({ order_sn: order_sn }).then((res) => {
      if (res.code === 200) {
        this.setState({
          typeindex: 1,
          listorder: res.data.data,
          pay_amount: res.data.price_info,
        });
      } else {
        Notification.error({ content: res.msg });
      }
    });
  }
  onpayment() {
    this.setState({
      typeindex: 2,
    });
    this.state.timer = setInterval(() => {
      this.getorder_query();
    }, 2000);
  }
  // 订单状态查询
  getorder_query() {
    let { payment } = this.state;
    if (this.state.retry_time <= 0) {
      var time = new Date().getTime();
      if (localStorage.getItem("pay_time")) {
        time = Number(localStorage.getItem("pay_time")) + 10;
        localStorage.setItem("pay_time", time);
      }
      let payment = this.state.payment;
      payment.pay_url = `${this.state.oldpay_url}&time=${time}`;
      this.setState({
        payment: payment,
        retry_time: 10,
      });
    } else {
      this.setState({ retry_time: this.state.retry_time - 1 });
    }
    order_detail({ order_sn: payment.order_sn }).then((res) => {
      if (res.code === 200) {
        // 'WAIT_PAID 待支付','PAID已支付','FINISH已完成','CANCEL已取消'
        if (res.data.status !== "WAIT_PAID" && res.data.status !== "CANCEL") {
          this.setState({
            status: "success",
          });
          this.settimeindex();
        } else {
          this.setState({
            status: "",
          });
        }
      } else {
        this.setState({
          status: "error",
        });
        this.settimeindex();
      }
    });
  }
  // 自动返回
  settimeindex() {
    this.state.timerindex = setInterval(() => {
      let index = this.state.timeindex - 1;
      this.setState({
        timeindex: index,
      });
      if (index === 0) {
        this.oncleartimer();
        this.onback();
      }
    }, 1000);
  }
  oncleartimer() {
    sourceCancelClass();
    clearInterval(this.state.timer);
    clearInterval(this.state.timerindex);
  }
  onback() {
    history.go();
  }
  render() {
    const {
      Modalvisible,
      positionList,
      type_name,
      visibledelete,
      confirmLoading,
      indeterminate,
      SelectcheckAll,
      loadingbody,
      isautomatic,
      checkbox,
      checkAll,
      type,
      selectedRowKeys,
      loading,
      columns,
      data,
      pagination,
      no_data,
      order,
      typeindex,
      timeindex,
      description,
      status,
      payment,
      order_sn,
      listorder,
      pay_amount,
      payment_title,
      retry_time,
    } = this.state;
    return (
      <div className="payment-orderlist">
        <div className="Header-init">
          <div className="Header-title">订单管理</div>
        </div>
        {loadingbody ? (
          <Spin loading={loadingbody} dot className="loadingbody"></Spin>
        ) : typeindex === 0 ? (
          <>
            <div className="GroupTable-fixe">
              {checkbox ? (
                <>
                  <div className="custom-checkAll">
                    <Checkbox
                      onChange={this.onChangeAll.bind(this)}
                      checked={SelectcheckAll}
                      indeterminate={indeterminate}
                    >
                      {SelectcheckAll ? "取消" : "全选"}
                    </Checkbox>
                  </div>
                  <Space size={"medium"}>
                    {type_name === "已付款" ? (
                      <Button
                        type="primary"
                        className="Header-init-btn"
                        disabled={selectedRowKeys.length === 0}
                        onClick={() => this.getinvoice_info(true)}
                      >
                        一键申请发票
                      </Button>
                    ) : type_name === "待付款" ? (
                      <Button
                        type="primary"
                        className="Header-init-btn"
                        disabled={selectedRowKeys.length === 0}
                        onClick={() => {
                          this.setModalvisible(true);
                        }}
                      >
                        合并支付
                      </Button>
                    ) : type_name === "已取消" || type_name === "全部订单" ? (
                      <Button
                        shape="circle"
                        className="custom-White-button"
                        disabled={selectedRowKeys.length === 0}
                        icon={<IconDelete />}
                        onClick={() => this.setalldelete(true)}
                      />
                    ) : (
                      ""
                    )}
                    <Button
                      shape="circle"
                      className="custom-White-button"
                      icon={<IconClose />}
                      onClick={this.onClose.bind(this)}
                    />
                  </Space>
                </>
              ) : (
                <>
                  <Radio.Group
                    type="button"
                    name="direction"
                    value={type_name}
                    onChange={this.onsetPosition.bind(this)}
                    options={positionList}
                    className="GroupTable"
                  ></Radio.Group>
                  <div>
                    <Space size={"medium"}>
                      <Button
                        shape="circle"
                        className="custom-White-button"
                        icon={<IconSettings />}
                        disabled={type_name === "待付款"}
                        onClick={this.onSettings.bind(this)}
                      />
                    </Space>
                  </div>
                </>
              )}
            </div>
            <Card
              bordered={false}
              hoverable
              style={{ marginBottom: 20, borderRadius: 20 }}
              className="Table-card"
            >
              <Table
                style={{ marginTop: 10 }}
                virtualized
                noDataElement={
                  <Empty imgSrc={Emptydata_img} description={description} />
                }
                no_data={no_data}
                border={{ wrapper: false, cell: false }}
                loading={loading}
                columns={columns.concat({
                  title: "操作",
                  dataIndex: "operation",
                  render: (col, record, index) => (
                    <div
                      className="columns-render"
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {record.status === "CANCEL" ? (
                        <Button
                          type="outline"
                          className="outline"
                          onClick={() => this.setVisibledelete(true, record)}
                        >
                          删除订单
                        </Button>
                      ) : record.status === "WAIT_PAID" ? (
                        <>
                          <Button
                            type="outline"
                            className="outline"
                            onClick={this.settlement.bind(this, record)}
                          >
                            支付订单
                          </Button>
                          <Button
                            type="outline"
                            className="outline"
                            style={{
                              marginLeft: 5,
                            }}
                            onClick={() => {
                              this.setModalvisible(true, record);
                            }}
                          >
                            取消订单
                          </Button>
                        </>
                      ) : record.status === "PAID" ? (
                        <Button
                          type="outline"
                          className="outline"
                          onClick={() =>
                            record.enable_invoice
                              ? this.onViewDetails(record)
                              : this.getinvoice_info(true, record)
                          }
                        >
                          {record.enable_invoice ? "发票详情" : "申请发票"}
                        </Button>
                      ) : (
                        ""
                      )}
                    </div>
                  ),
                  headerCellStyle: {
                    textAlign: "center",
                  },
                  fixed: "220",
                  align: "center",
                  width: 220,
                })}
                data={data}
                pagination={pagination.total === 0 ? false : pagination}
                onChange={this.onChangeTable.bind(this)}
                renderPagination={(paginationNode) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: 10,
                    }}
                  >
                    {paginationNode}
                  </div>
                )}
                rowKey="order_sn"
                rowSelection={
                  checkbox && {
                    type,
                    selectedRowKeys,
                    checkAll: checkAll,
                    onChange: (selectedRowKeys, selectedRows) => {
                      //console.log("onChange:", selectedRowKeys, selectedRows);
                      this.setSelectedRowKeys(selectedRowKeys);
                    },
                    onSelect: (selected, record, selectedRows) => {
                      //console.log("onSelect:", selected, record, selectedRows);
                    },
                    checkboxProps: (record) => {
                      return {
                        disabled: !record.enable_del || record.enable_invoice,
                      };
                    },
                  }
                }
              />
            </Card>
          </>
        ) : typeindex === 1 ? (
          <ConfirmOrder
            pay_amount={pay_amount}
            orderlist={listorder}
            order_sn={order_sn}
            onpayment={this.onpayment.bind(this)}
          />
        ) : (
          <div className="promotion-payment">
            <PaymentAmount
              status={status}
              timeindex={timeindex}
              data={payment}
              title={payment_title}
              time={retry_time}
              onback={this.onback.bind(this)}
            />
          </div>
        )}
        <Modal
          style={{
            width: 472,
          }}
          className="deleteModal"
          title="设置提示"
          visible={visibledelete}
          okText="确认"
          cancelText="取消"
          confirmLoading={confirmLoading}
          onOk={this.deleteuser.bind(this)}
          onCancel={() => this.setVisibledelete(false)}
        >
          <p>是否确认删除选中订单？</p>
        </Modal>
        <Modal
          style={{
            width: 472,
          }}
          className="Payment-order"
          title="操作提示"
          visible={Modalvisible}
          okText="确认"
          cancelText="取消"
          onCancel={() => this.setModalvisible(false)}
          onOk={this.onsaas_cancel.bind(this)}
        >
          <div style={{ textAlign: "center" }}>是否确认取消订单？</div>
        </Modal>
        <Modal
          style={{
            width: 472,
          }}
          className="Payment-order"
          title="操作提示"
          visible={this.state.visiblenoinfo}
          okText="填写开票信息"
          cancelText="取消"
          onCancel={() => this.setModalvisible(false)}
          onOk={this.godetailed.bind(this)}
        >
          <div style={{ textAlign: "center", marginTop: 20 }}>
            开票信息不完整，无法申请发票
          </div>
        </Modal>
        <Modal
          style={{
            width: 472,
          }}
          className="custom-modal custom-information"
          title="确认信息"
          visible={this.state.visibleinfo}
          footer={null}
          onCancel={() => this.setModalvisible(false)}
        >
          <div style={{ textAlign: "left" }}>
            <div className="Header-init">
              <div className="Header-title">开票信息</div>
            </div>
            <div className="custom-information-body">
              {Object.keys(this.state.formLable).map((item, key) => {
                return (
                  <div className="custom-form rowFlexStartStart" key={key}>
                    <div className="custom-form-lable">
                      {this.state.formLable[item]}：
                    </div>
                    <span className="custom-form-value">
                      {item === "invoice_bank_no" &&
                      this.state.formValue[item] ? (
                        <>
                          {this.state.isopeneye
                            ? addStar(this.state.formValue[item], 0, 4)
                            : this.state.formValue[item]}
                          {this.state.isopeneye ? (
                            <IconEye onClick={() => this.openEye(false)} />
                          ) : (
                            <IconEyeInvisible
                              onClick={() => this.openEye(true)}
                            />
                          )}
                        </>
                      ) : (
                        this.state.formValue[item]
                      )}
                    </span>
                  </div>
                );
              })}
            </div>
            <div className="custom-footer">
              <Button
                className="custom-btn-secondary"
                onClick={this.godetailed.bind(this)}
              >
                前往修改
              </Button>
              <Button
                type="primary"
                onClick={this.setModalvisible_apply.bind(this)}
              >
                确定
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
export default Paymentinorder;
