import React, { Component } from 'react'
import {
  Layout,
  Typography,
  Button,
  Space,
  Empty,
  Input,
  Divider,
  Badge,
} from '@arco-design/web-react'
import Emptydata_img from 'asserts/Emptydata_img.svg'
import Generating_img from 'asserts/Generating_img.gif'
import errorIcon from 'asserts/error_icon.svg'
import { shareAppApi } from 'api/apis'
import { debounce, getRgba } from 'common/utils'
import { sourceCancelClass } from 'api/cancel-request'
import $ from 'jquery'
import '../../common/style/myapp/entityExtraction.scss'
const Sider = Layout.Sider
const Content = Layout.Content
const TextArea = Input.TextArea
const COLORS_CUSTOM = [
  '#DC2EF8',
  '#2049FF',
  '#00C7FF',
  '#FF8700',
  '#7FCA00',
  '#57DBD4',
  '#B18533',
  '#FF3D9F',
  '#A968FF',
  '#D2CD34',
  '#0FC6C2',
  '#F53F3F',
  '#7816FF',
  '#1d5ae6',
  '#319227',
  '#a6d8ff',
  '#423535',
  '#16efff',
  '#4916ff',
  '#16ffa3',
  '#ca1364',
  '#8a1114',
  '#538a11',
  '#b6ce13',
  '#ce6d13',
  '#fc6220',
]
// 封装组件
class EntityExtraction extends Component {
  constructor(props) {
    super(props)
    this.state = {
      share_id: localStorage.getItem('share_ids'),
      TextAreaValue: '',
      ParagraphContinuation: {},
      ContinuationLoding: false,
      record: JSON.parse(localStorage.getItem('shareRecord')),
      number: 6,
      requestMsg: '',
      requestMsgText: '您还未输入文段',
      Loding: false,
      input_describe: '请输入文段，开始抽取知识',
      settimer1: '',
      settimer2: '',
    }
    this.isPhoneLegal = debounce(this.isPhoneLegal, 1000)
  }
  componentDidMount() {
    this.getApiInfo()
  }
  componentWillUnmount() {
    localStorage.removeItem('props')
    clearTimeout(this.state.settimer2)
    clearTimeout(this.state.settimer1)
    sourceCancelClass()
  }
  handleResize = () => {}

  getApiInfo() {
    let list = this.state.record
    if (list.type === 0) {
      this.onTextArea(
        list.input_describe
          ? list.input_describe
          : '1.本人个性随和，具有良好的沟通能力与团队合作精神。2.曾经实习过基本电脑维护的工作：能够给计算机重新安装系统，并且对操纵圆滑、数据线路感觉良好，为人自信大方不怕苦。3.8年IT开发领域的工作经验，曾在一家集团公司做过数十个大型项目和管理。4.热衷于技术研究，善长linux应用开发，掌握vhdl、Verilog HDL等相关工具的使命感和进取心。'
      )
      this.isPhoneLegal()
    }
  }
  onTextArea(value) {
    this.setState({
      TextAreaValue: value,
    })
    if (value.length === 0) {
      this.setState({
        ParagraphContinuation: {},
        ContinuationLoding: false,
        requestMsg: '',
        requestMsgText: '您还未输入文段',
      })
    } else if (value.length > 1600) {
      this.setState({
        ParagraphContinuation: {},
        ContinuationLoding: false,
        requestMsg: '',
        requestMsgText: '超出字符限制，请删减内容',
      })
    } else {
      this.setState({
        ParagraphContinuation: {},
        ContinuationLoding: false,
        requestMsg: '',
        requestMsgText: '可点击生成按钮生成结果',
      })
    }
    // this.isPhoneLegal()
    //console.log(value);
  }
  isPhoneLegal = () => {
    this.setState({
      ParagraphContinuation: {},
      ContinuationLoding: true,
      requestMsg: '',
    })
    clearTimeout(this.state.settimer2)
    clearTimeout(this.state.settimer1)
    sourceCancelClass()
    this.state.settimer2 = setTimeout(() => {
      this.setRequest()
    }, 1000)
  }
  ChangeAbatch() {
    clearTimeout(this.state.settimer2)
    clearTimeout(this.state.settimer1)
    sourceCancelClass()
    this.setState({
      ParagraphContinuation: {},
      ContinuationLoding: true,
      requestMsg: '',
    })
    this.state.settimer1 = setTimeout(() => {
      this.setRequest()
    }, 1000)
  }
  onKeyDown(event) {
    //console.log(event.keyCode, "键盘事件");
    if (event.shiftKey && event.keyCode === 13) {
      var e = this.state.TextAreaValue
      this.setState({
        TextAreaValue: e,
      })
    } else if (event.ctrlKey && event.keyCode === 13) {
      event.cancelBubble = true
      event.preventDefault()
      event.stopPropagation()
      event.returnValue = false
      this.ChangeAbatch()
      return false
    } else if (event.keyCode === 13) {
      event.cancelBubble = true
      event.preventDefault()
      event.stopPropagation()
      this.ChangeAbatch()
      event.returnValue = false
      return false
    }
  }
  setRequest() {
    if (this.state.TextAreaValue.length === 0) {
      this.setState({
        ParagraphContinuation: {},
        ContinuationLoding: false,
        requestMsg: '',
        requestMsgText: '您还未输入文段',
      })
    } else if (this.state.TextAreaValue.length > 1600) {
      this.setState({
        ParagraphContinuation: {},
        ContinuationLoding: false,
        requestMsg: '',
        requestMsgText: '超出字符限制，请删减内容',
      })
    } else {
      let params = {
        app_id: this.state.record.app_id,
        text: this.filterHTMLTag(this.state.TextAreaValue),
        share_id: this.state.share_id,
      }
      shareAppApi(params).then((res) => {
        if (res.code === 200) {
          let list = res.data
          this.setState({
            ParagraphContinuation: list,
            ContinuationLoding: false,
            requestMsg: '',
          })
          //console.log(res.data, "结果");
        } else if (res.code === 400 || res.code === 3003) {
          this.setState({
            ContinuationLoding: false,
            requestMsg: '',
            requestMsgText: res.msg,
          })
        } else if (res.code === 204) {
          this.setState({
            ParagraphContinuation: {},
            ContinuationLoding: false,
            requestMsg: 204,
          })
        } else {
          this.setState({
            ContinuationLoding: false,
            requestMsg: 'error',
            requestMsgText: res.msg,
          })
        }
        this.handleResize()
      })
    }
  }

  isEmptyObject = (obj) => {
    for (var n in obj) {
      return false
    }
    return true
  }
  setContinuation = () => {
    const { TextAreaValue, ParagraphContinuation } = this.state
    let sqlstring = []
    Object.keys(ParagraphContinuation ? ParagraphContinuation : []).map(
      (item, i) => {
        let obj = {
          key: i,
          item: ParagraphContinuation[item],
          type: item,
        }
        sqlstring.push(obj)
      }
    )
    let arr = []
    // //console.log(arr, "排序前");
    // //console.log(sqlstring, "sqlstring");
    for (let i = 0; i < sqlstring.length; i++) {
      let color = COLORS_CUSTOM[sqlstring[i].key]
      let key = sqlstring[i].type
      let subarr = sqlstring[i].item
      for (let j = 0; j < subarr.length; j++) {
        arr.push([...[key], ...[color], ...subarr[j]])
      }
    }
    // arr.sort((a, b) => a.at(-1).at(-1) - b.at(-1).at(-1));
    // .at()是ES2022的新语法，目前兼容性还不是很好，慎用！
    // .at()支持正索引和负索引
    arr.sort(function (a, b) {
      // 对sort方法进行重写，本质是冒泡排序
      // //console.log(a, b);
      let a_index = a[a.length - 1].slice(0)[0]
      let b_index = b[b.length - 1].slice(0)[0]
      return a_index - b_index
    })
    // //console.log(arr, "排序");
    return <div>{this.findHighlight(arr, TextAreaValue)}</div>
  }
  //str:原始字符串，start:开始位置,end:结束位置
  changeStr = (str, start, end) => {
    return str.substr(start, end)
  }
  filterHTMLTag = (msg) => {
    var msg = msg.replace(/<\/?[^>]*>/g, '') //去除HTML Tag
    msg = msg.replace(/<([a-zA-Z]+)\s*[^><]*>/g, '<$1>')
    msg = msg.replace(/[|]*\n/, '') //去除行尾空格
    msg = msg.replace(/&npsp;/gi, '') //去掉npsp
    return msg
  }

  generateRamStr(len, charSet) {
    const chars =
      charSet ||
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let randomStr = ''
    for (var i = 0; i < len; i++) {
      randomStr += chars.charAt(Math.floor(Math.random() * chars.length))
    }
    return randomStr
  }
  // 根据下标替换字符
  oneReplace(str, index, neWords) {
    let arr = str.split('')
    arr[index] = neWords
    return arr.join('')
  }
  lengthReplace(text, start, stop, replacetext) {
    let newstr =
      text.substring(0, start) + replacetext + text.substring(stop + 1)
    return newstr
  }
  //所有sql关键字
  sqlText = (sqlstring, newstr) => {
    // reg = /[~#^$@%&!?%<>/=:""''*]/gi
    // //console.log(sqlstring, newstr, "sqlstring, newstr, key, type");
    sqlstring.map((string, key) => {
      let reg
      let listitem = string.item
      //console.log(listitem, "listitem");
      let unique = []
      listitem.map((item) => {
        var containSpecial = RegExp(
          /[(\ )(\~)(\!)(\@)(\#)(\$)(\%)(\^)(\&)(\*)(\()(\))(\-)(\_)(\+)(\=)(\[)(\])(\{)(\})(\|)(\\)(\;)(\:)(\')(\")(\,)(\.)(\/)(\<)(\>)(\?)(\)]+/
        )
        var flag = new RegExp(
          "[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]"
        )
        let regNewstr = this.filterHTMLTag(this.state.TextAreaValue) //过滤掉所有HTML标签

        if (item[0].length === 1) {
          // //console.log(item[0]);
          if (!unique.includes(regNewstr[item[1][0]])) {
            if (
              containSpecial.test(regNewstr[item[1][0]]) ||
              flag.test(regNewstr[item[1][0]])
            ) {
              reg = ''
            } else {
              reg = regNewstr[item[1][0]]
              unique.push(reg.toLowerCase())
            }
          }
        } else {
          let endindex = item[1].length - 1
          let end = item[1][endindex] //结束下标
          let start = item[1][0] //开始小标
          if (!unique.includes(regNewstr.substring(start, end + 1))) {
            reg = regNewstr.substring(start, end + 1) //根据下标获取内容
            unique.push(reg.toLowerCase())
          }
        }

        // //console.log(regNewstr, '：过滤后标签')
        //进行内容替换操作
        if (reg !== '') {
          let regnew = reg.replace(/([\+\(\)\{\}\.\-\$\#\&\*\/]){1}/g, '\\$1')
          regnew = new RegExp(regnew + '(?![^<>]*>)', 'i')
          // //console.log(
          //   item[0] + "<-对比->",
          //   regnew + ":reg:" + reg,
          //   "总长：" + regNewstr.length
          // );
          let opacitybg = getRgba(COLORS_CUSTOM[key], '', 0.2)
          let html = `<span data="${string.type}" style="color: ${COLORS_CUSTOM[key]}" onMouseOver="this.style.background='${opacitybg}';this.style.textDecoration='underline'" onMouseOut="this.style.background='transparent';this.style.textDecoration='none'">${reg}<span class="Popover"><span class="em" style='background:${COLORS_CUSTOM[key]};'></span><span>${string.type}</span></span></span>`
          newstr = newstr.replace(regnew, html)
        }
        //这里替换的前后两个变量必须一样
      })
    })
    let html = { __html: newstr.replaceAll('\n', '<br/>') }
    return (
      <div className="Continuation-item" dangerouslySetInnerHTML={html}></div>
    )
  }
  findHighlight = (sqlstring, keyWord) => {
    let startindex = 0
    let replaceMap = []
    sqlstring.map((item, key) => {
      let reg
      let opacitybg = getRgba(item[1], '', 0.2)
      keyWord = this.filterHTMLTag(this.state.TextAreaValue) //过滤掉所有HTML标签
      //console.log(opacitybg);
      let endindex = item[3].length - 1
      let end = item[3][endindex] //结束下标
      let start = item[3][0] //开始小标
      let startindexKeyWord = keyWord.substring(startindex, end + 1)
      reg = keyWord.substring(start, end + 1) //根据下标获取内容
      let html = `<span data="${item[0]}" style="color: ${item[1]}" onMouseOver="this.style.background='${opacitybg}';this.style.textDecoration='underline'" onMouseOut="this.style.background='transparent';this.style.textDecoration='none'">${reg}<span class="Popover"><span class="em" style='background:${item[1]};'></span><span>${item[0]}</span></span></span>`
      startindexKeyWord = startindexKeyWord.replace(reg, html)
      replaceMap.push(startindexKeyWord)
      startindex = end + 1
      if (sqlstring.length === key + 1) {
        startindexKeyWord = keyWord.substring(startindex)
        replaceMap.push(startindexKeyWord)
      }
      // //console.log(replaceMap, "替换内容", keyWord.length);
    })
    let newHTML = replaceMap.join('')
    let htmls = { __html: newHTML.replaceAll('\n', '<br/>') }
    return (
      <div className="Continuation-item" dangerouslySetInnerHTML={htmls}></div>
    )
  }

  colorIndex(key, color) {
    let opacitybg = getRgba(color, '', 0.2)
    let style = `color:${color};background:${opacitybg};text-decoration:underline`
    $('span[data=' + key + ']').attr('style', style)
  }
  colorIndexOut(key, color) {
    let style = `color:${color};background:transparent;text-decoration:none`
    $('span[data=' + key + ']').attr('style', style)
  }

  render() {
    const {
      input_describe,
      ParagraphContinuation,
      ContinuationLoding,
      TextAreaValue,
      requestMsg,
      requestMsgText,
    } = this.state

    return (
      <div className="entityExtraction">
        <Layout className="semanticprototype-Layout" id="layout_id">
          <Sider className="Sider-left">
            <div className="Sider-left-configure columnSpaceBetween">
              <div className="Continuation-input">
                <TextArea
                  placeholder={input_describe}
                  style={{ width: '100%' }}
                  maxLength={{ length: 1600, errorOnly: true }}
                  showWordLimit
                  value={TextAreaValue}
                  onChange={this.onTextArea.bind(this)}
                  onKeyDown={(event) => this.onKeyDown(event)}
                />
              </div>
              <div className="generation-bottom">
                <Button
                  type="primary"
                  className="Start-generation"
                  onClick={this.isPhoneLegal.bind(this)}
                >
                  生成文段
                </Button>
              </div>
            </div>
          </Sider>
          <Content>
            <div className="experience-result" id="result">
              <div id="result_title" className="result_title">
                <div className="rowSpaceBetween">
                  <Typography>生成结果</Typography>
                  <Typography.Paragraph className="result-remarks">
                    内容基于韦尼克AI创作引擎生成，仅供参考
                  </Typography.Paragraph>
                </div>
                <Divider />
              </div>
              {!this.isEmptyObject(ParagraphContinuation) ||
              ContinuationLoding ||
              requestMsg === 204 ? (
                <div className="Paragraph-generate">
                  {ContinuationLoding ? (
                    <Empty
                      className="Continuation-loding"
                      icon={<img src={Generating_img} alt="" />}
                      description={
                        <Typography
                          style={{
                            color: '#4e5969',
                            marginTop: 21,
                            fontSize: 16,
                          }}
                        >
                          正在生成中...
                        </Typography>
                      }
                    />
                  ) : (
                    <div className="Continuation-result" id="resultok">
                      <div className="custom-Badge" id="badge">
                        {requestMsg === 204 ? (
                          <span style={{ color: '#586475', fontSize: 14 }}>
                            未识别到实体
                          </span>
                        ) : (
                          Object.keys(
                            ParagraphContinuation ? ParagraphContinuation : []
                          ).map((item, key) => {
                            return (
                              <Badge
                                key={key}
                                color={COLORS_CUSTOM[key]}
                                text={item}
                                style={{ marginRight: 26 }}
                                onMouseOut={this.colorIndexOut.bind(
                                  this,
                                  item,
                                  COLORS_CUSTOM[key]
                                )}
                                onMouseOver={this.colorIndex.bind(
                                  this,
                                  item,
                                  COLORS_CUSTOM[key]
                                )}
                              >
                                <span
                                  className="hover-Badge"
                                  style={{
                                    backgroundColor: COLORS_CUSTOM[key],
                                  }}
                                ></span>
                              </Badge>
                            )
                          })
                        )}
                      </div>
                      <div className="Continuation-list" id="content">
                        {requestMsg === 204
                          ? this.state.TextAreaValue
                          : this.setContinuation()}
                      </div>
                    </div>
                  )}
                </div>
              ) : requestMsg === 'error' ? (
                <Empty
                  className="Continuation-Empty Empty-error"
                  imgSrc={errorIcon}
                  description={
                    <Space direction="vertical">
                      <Typography
                        style={{
                          color: '#4e5969',
                          marginTop: 30,
                          fontSize: 16,
                        }}
                      >
                        抱歉，服务器生成错误
                      </Typography>
                      <Typography style={{ color: '#4e5969', fontSize: 16 }}>
                        可尝试
                        <span
                          style={{ color: '#406EFF', cursor: 'pointer' }}
                          onClick={this.ChangeAbatch.bind(this)}
                        >
                          {' '}
                          重新生成
                        </span>
                      </Typography>
                    </Space>
                  }
                />
              ) : (
                <Empty
                  className="Continuation-Empty Empty-none"
                  imgSrc={Emptydata_img}
                  description={
                    <Space direction="vertical">
                      <Typography
                        style={{
                          color: '#4e5969',
                          marginTop: 30,
                          fontSize: 16,
                        }}
                      >
                        暂无生成结果
                      </Typography>
                      <Typography style={{ color: '#4e5969', fontSize: 16 }}>
                        {requestMsgText}
                      </Typography>
                    </Space>
                  }
                />
              )}
            </div>
          </Content>
        </Layout>
      </div>
    )
  }
}
export default EntityExtraction
