import React, { Component } from 'react'
import {
  Layout,
  Typography,
  Button,
  Space,
  Empty,
  Input,
  Upload,
  Message,
} from '@arco-design/web-react'
import Emptydata_img from 'asserts/Emptydata_img.svg'
import Generating_img from 'asserts/Generating_img.gif'
import errorIcon from 'asserts/error_icon.svg'
import { shareAppApi } from 'api/apis'
import { debounce, b2ValueUnit } from 'common/utils'
import { sourceCancelClass } from 'api/cancel-request'
import JsonModel from '../../components/JsonModel'
import upload from 'api/uploads'

import $ from 'jquery'
import '../../common/style/myapp/globalModle.scss'
const Sider = Layout.Sider
const Content = Layout.Content
const TextArea = Input.TextArea

// 封装组件
class GlobalModle extends Component {
  constructor(props) {
    super(props)
    this.state = {
      share_id: localStorage.getItem('share_ids'),
      optionVlue: '',
      TextAreaValue: '',
      ParagraphContinuation: null,
      ContinuationLoding: false,
      record: JSON.parse(localStorage.getItem('shareRecord')),
      number: 6,
      requestMsg: '',
      requestMsgText: '您还未输入语句',
      input_describe: '请输入文段，或导入文本文件进行分析',
      jsonData: null,
      fileList: [],
      attachment: [],
    }
    this.isPhoneLegal = debounce(this.isPhoneLegal, 1000)
  }
  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
    setTimeout(() => {
      this.handleResize()
    }, 300)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }
  handleResize = () => {
    $('#globalModleBox').css({
      height: $('#shareContent').height() - 200 + 'px',
    })
    $('.text-left').css({
      height: $('#shareContent').height() - 80 + 'px',
      overflowY: 'auto',
    })
  }
  onTextArea(value) {
    this.setState({
      ContinuationLoding: true,
    })
    this.setState({
      TextAreaValue: value,
    })
    this.isPhoneLegal()
  }
  isPhoneLegal = () => {
    this.setState({
      ContinuationLoding: true,
      requestMsg: '',
    })
    sourceCancelClass()
    this.setRequest()
  }
  ChangeAbatch() {
    sourceCancelClass()
    this.setState({
      ContinuationLoding: true,
      requestMsg: '',
    })
    setTimeout(() => {
      this.setRequest()
    }, 1000)
  }
  setRequest() {
    if (this.state.TextAreaValue.length < 0) {
      this.setState({
        ParagraphContinuation: null,
        ContinuationLoding: false,
        requestMsg: '',
        requestMsgText: '您还未输入语句',
      })
    } else {
      this.sendRequest()
    }
  }

  sendRequest() {
    let params = {
      app_id: this.state.record.app_id,
      fileKey: JSON.stringify(this.state.attachment),
      text: this.state.TextAreaValue,
      num: this.state.number,
      share_id: this.state.share_id,
    }

    if (this.state.optionVlue) {
      params.version_id = this.state.optionVlue
    }
    shareAppApi(params).then((res) => {
      const code = [400, 3003, 204]
      if (res.code === 200) {
        let list = res.data
        this.setState({
          jsonData: list,
          ParagraphContinuation: list,
          ContinuationLoding: false,
          requestMsg: '',
        })
        this.handleResize()
      } else if (code.includes(res.code)) {
        this.setState({
          ParagraphContinuation: null,
          ContinuationLoding: false,
          requestMsg: '',
          requestMsgText: res.msg,
        })
      } else {
        this.setState({
          ParagraphContinuation: null,
          ContinuationLoding: false,
          requestMsg: 'error',
          requestMsgText: res.msg,
        })
      }
    })
  }

  handleUpload(option) {
    upload(option)
  }
  setFile(fileList, file) {
    this.setState({
      fileList: fileList,
    })
    if (file.status === 'done') {
      let key = []
      let fileList = this.state.fileList
      fileList.forEach((item, index) => {
        key.push(item.response.fileKey)
      })
      this.setState({
        attachment: key,
        fileList: fileList,
      })
    }
  }
  beforeUpload(file) {
    var AllImgExt = '.jpg|.pdf|.png|.docx|.doc|'
    var extName = file.name.substring(file.name.lastIndexOf('.')).toLowerCase() //（把路径中的所有字母全部转换为小写）
    if (AllImgExt.indexOf(extName + '|') === -1) {
      let ErrMsg =
        '该文件类型不允许上传。请上传 .jpg、.pdf、.png 、.docx、.doc类型的文件，当前文件类型为' +
        extName
      Message.error(ErrMsg)
      return false
    }
    const isLt10M = !(
      b2ValueUnit(file.size)[1] === 'B' ||
      b2ValueUnit(file.size)[1] === 'KB' ||
      (b2ValueUnit(file.size)[1] === 'MB' && b2ValueUnit(file.size)[0] <= 1)
    )
    return new Promise((resolve, reject) => {
      if (isLt10M) {
        Message.error('上传文件不能大于1M')
        reject()
      } else {
        resolve()
      }
    })
  }
  render() {
    const {
      input_describe,
      ParagraphContinuation,
      ContinuationLoding,
      TextAreaValue,
      requestMsg,
      requestMsgText,
      fileList,
    } = this.state
    return (
      <div className="product-globalModle">
        <Layout className="semanticprototype-Layout" id="layout_id">
          <Sider className="Sider-left">
            <div className="upload-file">
              <div className="upload-file-title">导入</div>
              <div>
                <Upload
                  accept=".PDF,.JPG,.PNG,.docx,.doc"
                  fileList={fileList}
                  renderUploadList={this.renderUploadList}
                  limit={1}
                  onChange={this.setFile.bind(this)}
                  customRequest={(option) => {
                    this.handleUpload(option)
                  }}
                  beforeUpload={this.beforeUpload.bind(this)}
                  onExceedLimit={() => {
                    Message.warning('超过上传数量限制！最多上传1个')
                  }}
                >
                  <div className="rowCenter trigger">
                    <span className="iconfont icon-icon_chuangjian_24"></span>
                    <div>上传文件</div>
                  </div>
                </Upload>
                <div className="Upload-pictures-tip">
                  （仅支持 PDF、docx、doc、JPG 和 PNG 格式，最大文件尺寸1MB）
                </div>
              </div>
            </div>
            <div
              className="Sider-left-configure columnSpaceBetween"
              id="globalModleBox"
            >
              <div className="Continuation-input">
                <TextArea
                  placeholder={input_describe}
                  style={{ width: '100%' }}
                  value={TextAreaValue}
                  onChange={this.onTextArea.bind(this)}
                />
              </div>
            </div>
            <div className="Upload-pictures-bottom">
              <Button
                type="primary"
                className="Start-generation"
                loading={false}
                onClick={this.ChangeAbatch.bind(this, 'start')}
              >
                开始生成
              </Button>
            </div>
          </Sider>
          <Content>
            <div className="experience-result" id="result">
              <div id="result_title" className="rowSpaceBetween result_title">
                <Typography>生成结果</Typography>
                <Typography.Paragraph className="result-remarks">
                  (内容基于韦尼克AI创作引擎生成，仅供参考)
                </Typography.Paragraph>
              </div>
              {ParagraphContinuation || ContinuationLoding ? (
                <div className="Paragraph-generate">
                  {ContinuationLoding ? (
                    <Empty
                      className="Continuation-loding"
                      icon={<img src={Generating_img} alt="" />}
                      description={
                        <Typography
                          style={{
                            color: '#4e5969',
                            marginTop: 21,
                            fontSize: 16,
                          }}
                        >
                          正在生成中...
                        </Typography>
                      }
                    />
                  ) : (
                    <div className="text-left">
                      <JsonModel data={this.state.jsonData}></JsonModel>
                    </div>
                  )}
                </div>
              ) : requestMsg === 'error' ? (
                <Empty
                  className="Continuation-Empty Empty-error"
                  imgSrc={errorIcon}
                  description={
                    <Space direction="vertical">
                      <Typography
                        style={{
                          color: '#4e5969',
                          marginTop: 30,
                          fontSize: 16,
                        }}
                      >
                        抱歉，服务器生成错误
                      </Typography>
                      <Typography style={{ color: '#4e5969', fontSize: 16 }}>
                        可尝试
                        <span
                          style={{ color: '#406EFF', cursor: 'pointer' }}
                          onClick={this.ChangeAbatch.bind(this)}
                        >
                          {' '}
                          重新生成
                        </span>
                      </Typography>
                    </Space>
                  }
                />
              ) : (
                <Empty
                  className="Continuation-Empty Empty-none"
                  imgSrc={Emptydata_img}
                  description={
                    <Space direction="vertical">
                      <Typography
                        style={{
                          color: '#4e5969',
                          marginTop: 30,
                          fontSize: 16,
                        }}
                      >
                        暂无生成结果
                      </Typography>
                      <Typography style={{ color: '#4e5969', fontSize: 16 }}>
                        {requestMsgText}
                      </Typography>
                    </Space>
                  }
                />
              )}
            </div>
          </Content>
        </Layout>
      </div>
    )
  }
}
export default GlobalModle
