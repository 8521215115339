import React, { Component } from 'react'
import { Button } from '@arco-design/web-react'
import $ from 'jquery'
import shareError from '../../asserts/shareError.png'
import '../../common/style/myapp/myapp.scss'
import { getUrlToken } from '../../common/utils/index'
// 封装组件
class ShareError extends Component {
  constructor(props) {
    console.log(props)
    super(props)
    this.state = {
      modeName: getUrlToken('modelName'),
      errorCode: props.code
    }
  }

  componentDidMount () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  }

  handleResize = () => {
    setTimeout(() => { }, 100)
  }
  goHome = () => {
    let name = this.state.modeName
    name = name ? name[0].toLowerCase() + name.slice(1) : ''
    if (!window.location.origin) {
      window.location.origin =
        window.location.protocol +
        '//' +
        window.location.hostname +
        (window.location.port ? ':' + window.location.port : '')
    }
    if (name) {
      window.open(`${window.location.origin}/myapp/${name}`, '_blank')
    } else {
      window.open(`${window.location.origin}`, '_blank')
    }
  }

  render () {
    const { errorCode } = this.state
    return (
      <div className="ShareError">
        <img src={shareError} alt="" />
        <div className="shareErrorTitle">{errorCode === 204 ? '体验权限已失效' : '加载出错'}</div>
        <p> {errorCode === 204 ? '您当前的体验权限已失效，请前往韦尼克平台解锁更多智能创作体验' : '当前页面请求出错，请重新加载或前往韦尼克官方创作平台'}</p>
        <div>
          {errorCode === 204 ? ''
            : <Button
              style={{ marginRight: 16 }}
              className="shareErrorReload"
              onClick={() => window.location.reload()}
            >
              重新加载
            </Button>
          }
          <Button type="primary" className="shareErrorGo" onClick={this.goHome}>
            {errorCode === 204 ? '立即解锁更多智能创作' : '前往韦尼克创作平台'}
          </Button>
        </div>
      </div>
    )
  }
}
export default ShareError
