/**
 * 重复请求取消上次请求
 */
import axios from "axios";
import cancelClass from "./cancelClass";
// 存储pengding请求容器
export const pendingList = new Map();
// 生成各个请求标识
export const getFetchKey = (config) => {
  if (config) {
    const { url, data, method, params } = config;
    let setparams = data ? data : params;
    let token;
    if (method === "get") {
      token = [method, url].join("&");
    } else {
      token = [method, url, JSON.stringify(setparams)].join("&");
    }
    return token;
  }
};
// 添加pengding请求
export const addPending = (config) => {
  const fetchKey = getFetchKey(config);
  if (fetchKey) {
    config.cancelToken =
      config.cancelToken ||
      new axios.CancelToken((cancel) => {
        if (!pendingList.has(fetchKey)) {
          pendingList.set(fetchKey, cancel);
        }
      });
  }
};
// 移除pengding请求
export const removePending = (config) => {
  const fetchKey = getFetchKey(config);
  if (fetchKey) {
    if (pendingList.has(fetchKey)) {
      pendingList.delete(fetchKey);
    }
  }
};
// 取消请求
export const cancelPending = (config) => {
  const fetchKey = getFetchKey(config);
  if (fetchKey) {
    if (pendingList.has(fetchKey)) {
      const cancel = pendingList.get(fetchKey);
      cancel("cancel");
      pendingList.delete(fetchKey);
    }
  }
};
//source主动取消请求
export const sourceCancelClass = () => {
  const CancelToken = axios.CancelToken;
  cancelClass.cancelRequest.source.cancel &&
    cancelClass.cancelRequest.source.cancel("请求取消");
  cancelClass.cancelRequest.source = axios.CancelToken.source();
};
