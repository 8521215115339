import React, { Component } from "react";
import { IconCloseCircleFill } from "@arco-design/web-react/icon";
import "./index.scss";
class CustomSteps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: props.current,
      list: props.list, //status:1-正常 2-错误
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      list: nextProps.list,
      current: nextProps.current,
    });
  }
  render() {
    const { list, current } = this.state;
    return (
      <div className="rowStart step-list">
        {list.map((item, index) => {
          let currentindex = index + 1;
          return (
            <div
              key={index}
              className={`step ${
                current === currentindex && currentindex !== list.length
                  ? `custom-steps-item-active`
                  : current < currentindex
                  ? `custom-steps-item-wait`
                  : ``
              }`}
            >
              <div className="step-title">{item.title}</div>
              <div className="rowStart step-dot">
                <div
                  className={`steps-item-tail ${
                    current >= currentindex ? `steps-item-tail-active` : ``
                  }`}
                ></div>
                {item.status === 2 ? (
                  <IconCloseCircleFill
                    style={{ color: "#F53F3F", fontSize: 15 }}
                  />
                ) : (
                  <div className="dot"></div>
                )}

                <div
                  className={`steps-item-tail ${
                    current > currentindex ? `steps-item-tail-active` : ``
                  }`}
                ></div>
              </div>
              <div className="description">{item.created_at}</div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default CustomSteps;
