import React, { Component } from "react";
// import { Link } from 'react-router-dom'
import {
  Layout,
  Radio,
  Grid,
  Popover,
  Typography,
  Button,
  Steps,
  Divider,
  Space,
  Input,
  Message,
  Upload,
  Link,
  Notification,
  Spin,
  Select
} from "@arco-design/web-react";
import {
  IconCheck,
  IconArrowLeft,
  IconArrowRight,
  IconUpload,
  IconStarFill,
  IconExclamationCircleFill,
  IconCheckCircleFill,
  IconLoading,
  IconDelete,
  IconLeft,
  IconRight,
} from "@arco-design/web-react/icon";
import {
  postmodelversion,
  getdatumList,
  postdatum,
  getmodelTrainProcess,
  postmodelVersionTrain,
  putmodelversion,
  getmodelListVersion,
  deleteModelVersion,
} from "api/apis";
import upload from "api/uploads";
import { b2ValueUnit, pageCount } from "common/utils/index";
import TrainingStatus from "components/TrainingStatus";
import history from "common/utils/history";
import JQ from "jquery";
import "../addmodel.scss";
const Row = Grid.Row;
const Col = Grid.Col;
const Step = Steps.Step;
const Sider = Layout.Sider;
const Content = Layout.Content;
const Option = Select.Option;
// 封装组件
class NewModelVersion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listTimer: "",
      isRetraining: false,
      mockData: [],
      paginationVersion: {
        total: 0,
        pageSize: 10,
        current: 1,
      },
      scrollLoading: <Spin loading={true} />,
      isToBeTrained: false,
      model_capability: "",
      current: 1,
      cardstepitem: null,
      model_id: "",
      data_id: "", //数据集id（语料配置）
      namedata: "",
      paginationdata: {
        total: 0,
        pageSize: 20,
        current: 1,
      },
      setingOk: false, //新建模型成功
      checkedtypeData: "",
      DataSelsectList: [],
      fileList: null,
      attachment: [],
      Code: "",
      progress: 0,
      title: "",
      setIntervalTimer: "",
      subTitle: "",
      newAddList: ["创建数据集"],
      record: JSON.parse(localStorage.getItem("record")),
      version_id: "",
      model_name: "",
      btnOktitle: "体验模型",
      train_status: "",
      app_id: "",
      texSetList: [
        "正在进行数据分包......",
        "正在计算模型偏差......",
        "正在优化模型......",
        "已完成0次模型迭代......",
      ],
      frequency: 0,
      version: 1,
      loading: false,
      PatternList: localStorage.getItem("PatternList")
        ? JSON.parse(localStorage.getItem("PatternList"))
        : "",
      checkedPattern: "",
    };
    this.myRef = React.createRef();
  }

  componentDidMount() {
    let props = JSON.parse(localStorage.getItem("props"));
    let record = JSON.parse(localStorage.getItem("record"));
    if (props) {
      this.setState({
        model_capability: record.model_capability,
        current: props.current ? props.current : 1,
        cardstepitem: JSON.parse(localStorage.getItem("Selectoptions"))[
          props.model_capability
        ],
        checkedtypeData: props.checkedtypeData ? props.checkedtypeData : "",
        fileList: props.fileList ? props.fileList : "",
        attachment: props.attachment ? props.attachment : "",
        namedata: props.namedata ? props.namedata : "",
        model_id: record.model_id ? record.model_id : record.id,
        model_name: record.model_name ? record.model_name : record.name,
        model_desc: record.model_desc ? record.model_desc : "",
        data_id: props.data_id ? props.data_id : "",
        setingOk: props.setingOk ? props.setingOk : false,
        DataSelsectList: props.DataSelsectList ? props.DataSelsectList : [],
        Code: props.Code ? props.Code : "",
        subTitle: props.subTitle ? props.subTitle : "",
        title: props.title ? props.title : "",
        version_id: props.id ? props.id : props.version_id,
        app_id: props.app_id ? props.app_id : "",
        btnOktitle:
          record.train_status === "待训练" ? "更多模型版本" : "体验模型",
        progress: props.progress ? props.progress : 0,
        isRetraining: props.isRetraining ? props.isRetraining : false,
        checkedPattern: props.checkedPattern ? props.checkedPattern : "",
      });
    }
    if (record) {
      this.setState({
        model_capability: record.model_capability,
        cardstepitem: JSON.parse(localStorage.getItem("Selectoptions"))[
          record.model_capability
        ],
        model_id: record.model_id ? record.model_id : record.id,
        model_name: record.model_name ? record.model_name : record.name,
        model_desc: record.model_desc ? record.model_desc : "",
      });
    }
    if (record.train_details === "详情") {
      this.setState({
        model_id: record.model_id ? record.model_id : "",
        setingOk: true,
        Code:
          record.train_status === "训练中"
            ? "init"
            : record.train_status === "训练失败"
            ? "error"
            : "trained",
        subTitle:
          record.train_status === "训练失败"
            ? "服务训练出现异常，建议您联系专属客服解决问题"
            : "",
        title: record.name ? record.name : record.model_name,
        btnOktitle:
          record.train_status === "待训练" ? "更多模型版本" : "体验模型",
        train_status: record.train_status,
        data_id: record.data_id ? record.data_id : "",
        version_id: record.id ? record.id : record.version_id,
        model_desc: record.model_desc ? record.model_desc : "",
      });
    }

    if (
      (props &&
        props.version_id &&
        (props.Code === "init" || props.Code === "trained")) ||
      (record.train_details === "详情" &&
        record.id &&
        (record.train_status === "训练中" ||
          record.train_status === "待训练" ||
          record.train_status === "训练失败"))
    ) {
      this.state.version_id = record.id ? record.id : props.version_id;
      this.getmodelTrainProcess(record.id ? record.id : props.version_id);
      if (
        (props && (props.Code === "init" || props.Code === "trained")) ||
        (record && record.train_status !== "训练失败")
      ) {
        this.setintervalData();
      }
    }
    this.getdatumList();
    window.addEventListener("resize", this.handleResize);
    setTimeout(() => {
      this.handleResize();
    }, 300);
  }
  handleResize = () => {
    var winHeight = 0;
    if (window.innerHeight) {
      winHeight = window.innerHeight;
    } else if (document.body && document.body.clientHeight) {
      winHeight = document.body.clientHeight;
    }
    if (document.documentElement && document.documentElement.clientHeight) {
      winHeight = document.documentElement.clientHeight;
    }
    if (document.getElementById("layoutbody")) {
      let height =
        winHeight -
        document.getElementById("layoutHeaders").clientHeight -
        document.getElementById("Headertitle").clientHeight -
        20 -
        40;
      document.getElementById("layoutbody").style.height = height + "px";
      setTimeout(() => {
        if (document.getElementById("layoutbody")) {
          let height2 =
            document.getElementById("layoutbody").clientHeight -
            document.getElementById("startParagraph").clientHeight -
            document.getElementById("selectFoot").clientHeight -
            32 -
            15;
          document.getElementById("forminput").style.height = height2 + "px";
        }
      }, 100);
    }
    document.getElementsByClassName("addmodel")[0].style.opacity = "1";
  };

  componentWillUnmount() {
    clearInterval(this.state.listTimer);
    clearInterval(this.state.setIntervalTimer);
    window.removeEventListener("resize", this.handleResize);
  }
  getmodelTrainProcess(version_id) {
    clearInterval(this.state.setIntervalTimer);
    let page = {
      version_id: version_id,
    };
    this.setState({
      setingOk: true,
      title: this.state.record.name
        ? this.state.record.name
        : this.state.model_name,
    });
    getmodelTrainProcess(page).then((res) => {
      let Code = "";
      let subTitle = "";
      if (res.code === 200) {
        if (res.data.train_status === "待训练") {
          Code = "trained";
          this.setState({
            btnOktitle: "更多模型版本",
            train_status: res.data.train_status,
          });
          this.state.setIntervalTimer = setInterval(() => {
            this.getmodelTrainProcess(version_id);
          }, 3000);
        } else if (
          res.data.train_status === "训练中" ||
          res.data.train_status === "训练成功"
        ) {
          if (
            res.data.train_percent >= 100 &&
            res.data.train_status === "训练成功"
          ) {
            clearInterval(this.state.setIntervalTimer);
            Code = "success";
            if (!res.data.app_id) {
              this.setState({
                btnOktitle: "创建应用",
              });
            }
          } else {
            Code = "init";
            this.state.setIntervalTimer = setInterval(() => {
              this.getmodelTrainProcess(version_id);
            }, 3000);
          }
          this.setState({
            progress: res.data.train_percent,
            title: res.data.model_name,
            app_id: res.data.app_id,
            train_status: res.data.train_status,
            version_id: res.data.version_id,
          });
        } else if (res.data.train_status === "训练失败") {
          Code = "error";
          subTitle = "服务器异常，请通过客服联系开发人员";
          // res.data.train_msg ? res.data.train_msg : res.msg
          clearInterval(this.state.setIntervalTimer);
        }
        let texSetList = this.state.texSetList;
        let count = res.data.iteration_count ? res.data.iteration_count : 0;
        let frequency = this.state.frequency + 1;
        // texSetList[0] = '正在进行第' + frequency + '次数据分包......'
        texSetList[3] = "已完成" + count + "次模型迭代......";
        this.setState({
          texSetList: texSetList,
          frequency: frequency,
          version: res.data.version,
          isRetraining: res.data.enable_edit,
          title: res.data.app_name ? res.data.app_name : res.data.model_name,
        });
        localStorage.setItem("props", JSON.stringify(this.state));
        localStorage.setItem("record", JSON.stringify(res.data));
        if (res.data.version_id) {
          this.state.version_id = res.data.version_id;
          this.setState({
            version_id: res.data.version_id,
          });
        }
      } else {
        Code = "error";
        subTitle = "服务器异常，请通过客服联系开发人员";
        // res.data.train_msg ? res.data.train_msg : res.msg
        clearInterval(this.state.setIntervalTimer);
      }

      this.setState({
        Code: Code,
        subTitle: subTitle,
      });
      localStorage.setItem("props", JSON.stringify(this.state));
    });
  }
  // 添加模型版本（新建）
  postmodelversion() {
    let page = {
      model_id: this.state.model_id,
      data_id: this.state.data_id,
    };
    if (this.state.checkedPattern) {
      page.train_mode = this.state.checkedPattern;
    }
    postmodelversion(page).then((res) => {
      // let Code = ''
      // let subTitle = ''
      if (res.code === 200) {
        // Code = 'init'
        this.getmodelTrainProcess(res.data.id);
        this.setState({
          current: this.state.current + 1,
          version_id: res.data.id,
          loading: false,
        });
        this.state.version_id = res.data.id;
        this.setintervalData();
        // localStorage.setItem('props', JSON.stringify(this.state))
      } else {
        // Code = 'error'
        // subTitle = res.msg
        this.setState({
          loading: false,
        });
      }
      // this.setState({
      //     setingOk: true,
      //     Code: Code,
      //     subTitle: subTitle,
      //     title: this.state.record.name
      // })
      localStorage.setItem("props", JSON.stringify(this.state));
    });
  }
  // 监听页面滚动
  handleOnScroll = () => {
    if (this.myRef) {
      const contentScrollTop = this.myRef.scrollTop; //滚动条距离顶部
      const clientHeight = this.myRef.clientHeight; //可视区域
      const scrollHeight = this.myRef.scrollHeight; //滚动条内容的总高度
      if (contentScrollTop + clientHeight >= scrollHeight) {
        if (
          this.state.DataSelsectList.length > 0 &&
          this.state.paginationdata.total !==
            this.state.DataSelsectList.length &&
          this.state.current === 1
        ) {
          let pagination = this.state.paginationdata;
          pagination.current = this.state.paginationdata.current + 1;
          this.setState({
            paginationdata: pagination,
          });
          this.getdatumList();
        }
      }
    }
  };
  setmoreSelect(type, direction) {
    if (type === "data") {
      let pagination = this.state.paginationdata;
      if (direction === "left") {
        pagination.current = this.state.paginationdata.current - 1;
      } else {
        pagination.current = this.state.paginationdata.current + 1;
      }
      JQ(".pageGroup").addClass("Group-fadeInUp");
      this.setState({
        paginationdata: pagination,
      });
      this.getdatumList(); // 获取数据的方法
    }
  }
  // 选择数据集
  getdatumList() {
    if (this.state.paginationdata.current === 1) {
      this.setState({
        DataSelsectList: [],
      });
    }
    let page = {
      per_page: this.state.paginationdata.pageSize,
      page: this.state.paginationdata.current,
      model_capability: this.state.record.model_capability,
    };
    getdatumList(page).then((res) => {
      let list = res.data;
      let pagination = this.state.paginationdata;
      pagination.total = list.count;
      this.setState({
        paginationdata: pagination,
        DataSelsectList: list.data,
      });
      localStorage.setItem("props", JSON.stringify(this.state));
      setTimeout(() => {
        JQ(".pageGroup").removeClass("Group-fadeInUp");
      }, 1000);
    });
  }
  // 添加数据集
  postdatum() {
    let page = {
      model_capability: this.state.model_capability,
      name: this.state.namedata,
      attachment: JSON.stringify(this.state.attachment),
    };
    postdatum(page).then((res) => {
      if (res.code === 200) {
        this.setState({
          data_id: res.data.id,
        });
        this.postmodelversion();
      } else if (res.code === 409) {
        Notification.error({ content: res.msg });
        this.setState({
          loading: false,
        });
      } else {
        this.setState({
          loading: false,
        });
      }
    });
  }
  onChangeIput(value) {
    this.setState({
      namedata: value,
    });
  }

  // 选择数据集
  onSelsectData(value) {
    this.setState({
      checkedtypeData: value,
    });
  }
  // 选择模型训练模式
  onSelsectPattern(value) {
    this.setState({
      checkedPattern: value,
    });
  }
  handleUpload(option) {
    upload(option, this.state.model_capability);
  }
  setFile(fileList, file) {
    this.setState({
      fileList: fileList,
    });
    if (file.status === "done") {
      let key = [];
      key.push(file.response.fileKey);
      this.setState({
        attachment: key,
      });
      if (this.state.namedata === "") {
        this.setState({
          namedata: file.name.substring(0, file.name.lastIndexOf(".")),
        });
      }
    }
  }
  onIconDelete() {
    this.setState({
      fileList: null,
      attachment: [],
    });
    localStorage.setItem("props", JSON.stringify(this.state));
  }
  beforeUpload(file) {
    var AllImgExt = ".csv|";
    var extName = file.name.substring(file.name.lastIndexOf(".")).toLowerCase(); //（把路径中的所有字母全部转换为小写）
    if (AllImgExt.indexOf(extName + "|") === -1) {
      let ErrMsg =
        "该文件类型不允许上传。请上传 .csv 类型的文件，当前文件类型为" +
        extName;
      Notification.warning({ content: ErrMsg });
      return false;
    }
    const isLt10M = !(
      b2ValueUnit(file.size)[1] === "B" ||
      b2ValueUnit(file.size)[1] === "KB" ||
      (b2ValueUnit(file.size)[1] === "MB" && b2ValueUnit(file.size)[0] <= 5)
    );
    return new Promise((resolve, reject) => {
      if (isLt10M) {
        Notification.warning({ content: "上传文件不能大于5MB" });
        reject();
      } else {
        resolve();
      }
    });
  }
  onChangeNext() {
    const {
      PatternList,
      checkedPattern,
      checkedtypeData,
      namedata,
      attachment,
    } = this.state;
    if (checkedtypeData === "创建数据集" && namedata === "") {
      Notification.warning({ content: "数据集名称不能为空!" });
    } else if (checkedtypeData === "创建数据集" && attachment.length === 0) {
      Notification.warning({ content: "数据集不能为空!" });
    } else if (checkedtypeData === "" && checkedtypeData !== "创建数据集") {
      Notification.warning({ content: "请选择新建方式！" });
    } else if (PatternList && checkedPattern === "") {
      Notification.warning({ content: "未选择模型训练模式 !" });
    } else {
      this.setState({
        loading: true,
      });
      if (this.state.current === 1) {
        if (checkedtypeData === "创建数据集") {
          this.postdatum();
        } else {
          this.state.data_id = checkedtypeData;
          this.setState({
            data_id: checkedtypeData,
          });
          this.postmodelversion();
        }
      }
      localStorage.setItem("props", JSON.stringify(this.state));
    }
  }
  onChangePrev() {
    const { current } = this.state;
    if (current === 1) {
      history.goBack();
    } else {
      this.setState({
        current: current - 1,
      });
    }
    localStorage.setItem("props", JSON.stringify(this.state));
  }
  onColseadd() {
    // model_id
    let record = {
      model_capability: this.state.model_capability,
      model_id: this.state.model_id,
      model_name: this.state.model_name,
      model_desc: this.state.model_desc,
    };
    localStorage.setItem("record", JSON.stringify(record));
    history.replace("/enginefactory/model/management");
    localStorage.removeItem("props");
    window.location.reload();
  }

  putmodelversion(optionVlue, add) {
    const { model_capability } = this.state;
    let params = {
      id: optionVlue,
    };

    localStorage.setItem("props", JSON.stringify(this.state));
    putmodelversion(params).then((res) => {
      if (res.code === 200) {
        if (add) {
          history.replace("/myapp/addmyapp");
          let state = {
            model_capability: this.state.model_capability,
            checkedtype: this.state.model_id,
            model_id: this.state.model_id,
            name: this.state.model_name,
          };
          localStorage.setItem("props", JSON.stringify(state));
          localStorage.removeItem("record");
          window.location.reload();
        } else {
          let record = {
            id: this.state.app_id,
            model_id: this.state.model_id,
            model_capability: model_capability,
          };
          let cardstepitem = JSON.parse(localStorage.getItem("Selectoptions"))[
            model_capability
          ];
          record.train_details = "详情";
          if (cardstepitem.type === 3 && model_capability === "ResumeEditor") {
            history.replace("/myapp/smarteditor");
          } else {
            if (model_capability === "Interaction") {
              history.replace("/myapp/question");
            } else if (model_capability === "Continuation") {
              history.replace("/myapp/productExperience");
            } else if (model_capability === "SemanticPrototype") {
              history.replace("/myapp/semanticprototype");
            } else if (model_capability === "Ner") {
              history.replace("/myapp/entityExtraction");
            } else if (model_capability === "SmartResume") {
              history.replace("/myapp/resumeDocument");
            } else if (model_capability === "textPolish") {
              history.replace("/myapp/embroidery");
            } else if (
              model_capability === "writing" ||
              model_capability === "textsummarization"
            ) {
              let moduleIdType = JSON.parse(
                localStorage.getItem("moduleIdType")
              );
              let arr = moduleIdType["writing"];
              if (arr.indexOf(record.model_id) !== -1) {
                history.push("/myapp/writingcurrency");
              } else {
                history.replace("/myapp/writing");
              }
            } else if (model_capability === "SemanticWordCloud") {
              history.replace("/myapp/wordCloud");
            } else if (model_capability === "KnowledgeJd") {
              history.replace("/myapp/knowledge");
            } else if (model_capability === "Classification") {
              history.replace("/myapp/wordClass");
            } else if (model_capability === "Correction") {
              history.replace("/myapp/wordErrorReset");
            } else if (model_capability === "SmartContract") {
              history.replace("/myapp/smartContract");
            } else if (model_capability === "SentimentAnalysis") {
              history.replace("/myapp/emotionClass");
            } else if (model_capability === "SensitiveWords") {
              history.replace("/myapp/sensitiveWords");
            } else if (model_capability === "Similarity") {
              history.replace("/myapp/similarity");
            } else if (model_capability === "KnowledgeReasoning") {
              history.replace("/myapp/knowledgereasoning");
            }
          }
          localStorage.setItem("record", JSON.stringify(record));
          window.location.reload();
        }
      } else {
        Notification.error({ content: res.msg });
      }
    });
  }
  OpenApp() {
    const { record, app_id, model_capability } = this.state;
    if (this.state.train_status === "待训练") {
      let record = {
        model_capability: this.state.model_capability,
        model_id: this.state.model_id,
        model_name: this.state.model_name,
        model_desc: this.state.model_desc,
      };
      localStorage.setItem("record", JSON.stringify(record));
      history.replace("/enginefactory/model/management");
      localStorage.removeItem("props");
      window.location.reload();
    } else {
      if (app_id) {
        this.putmodelversion(this.state.version_id);
        localStorage.setItem("props", JSON.stringify(this.state));
      } else {
        this.putmodelversion(this.state.version_id, true);
      }
    }
  }
  onRetraining() {
    let params = {
      id: this.state.version_id,
    };
    if (this.state.data_id) {
      params.data_id = this.state.data_id;
    }
    postmodelVersionTrain(params).then((res) => {
      if (res.code === 409 || res.code === 200) {
        if (this.state.version_id) {
          this.getmodelTrainProcess(this.state.version_id);
          this.setintervalData();
        }
      } else {
        this.setState({
          subTitle: res.msg,
        });
      }
    });
  }
  goLink() {
    this.onColseadd();
  }
  setintervalData() {
    this.gofetchData(1);
    clearInterval(this.state.listTimer);
    this.state.listTimer = setInterval(() => {
      this.gofetchData(1);
    }, 1000);
  }
  gofetchData(currentPage) {
    if (
      this.state.mockData.length === this.state.paginationVersion.total &&
      currentPage !== 1
    ) {
      this.setState({
        scrollLoading: "没有更多",
      });
    } else {
      this.getmodelListVersion(currentPage);
    }
  }
  getmodelListVersion(currentPage) {
    const { mockData, paginationVersion } = this.state;
    let page = {
      per_page: this.state.paginationVersion.pageSize,
      page: currentPage,
      train_status: '["训练中","待训练"]',
      sort: "desc",
      // filter_id: this.state.version_id
    };
    getmodelListVersion(page).then((res) => {
      if (res.code === 200) {
        let list = res.data;
        let pagination = this.state.paginationVersion;
        pagination.total = list.count;
        let newdata = list.data;
        // var b = [this.state.version_id];
        // var newdata = list.data.filter(item => { return !b.includes(item.id); });
        if (newdata.length > 0) {
          this.setState({
            paginationVersion: pagination,
            mockData: currentPage === 1 ? newdata : mockData.concat(newdata),
            isToBeTrained: true,
            scrollLoading: false,
          });
        }
        if (newdata.length === 0 && currentPage === 1) {
          this.setState({
            isToBeTrained: false,
          });
          clearInterval(this.state.listTimer);
        }
      } else {
        this.setState({
          isToBeTrained: false,
        });
        clearInterval(this.state.listTimer);
      }
    });
  }
  openTraining(record) {
    clearInterval(this.state.listTimer);
    clearInterval(this.state.setIntervalTimer);
    record.train_details = "详情";
    this.setState({
      version_id: record.id,
    });
    localStorage.setItem("record", JSON.stringify(record));
    localStorage.setItem("props", JSON.stringify(this.state));
    history.push("/enginefactory/model/management/add");
    window.location.reload();
  }
  deleteModelVersion(e, id) {
    e.stopPropagation();
    deleteModelVersion({ ids: JSON.stringify([id]) }).then((res) => {
      if (res.code === 200) {
        clearInterval(this.state.listTimer);
        this.setintervalData();
        Notification.success({ content: "删除成功!" });
      } else {
        Notification.error({ content: "删除失败!" });
      }
    });
  }
  render() {
    const {
      loading,
      version,
      isRetraining,
      mockData,
      scrollLoading,
      isToBeTrained,
      texSetList,
      btnOktitle,
      model_capability,
      model_name,
      subTitle,
      title,
      current,
      newAddList,
      record,
      cardstepitem,
      DataSelsectList,
      namedata,
      checkedtypeData,
      setingOk,
      fileList,
      Code,
      progress,
    } = this.state;
    return (
      <div className="addmodel">
        <div className="Header-init" id="Headertitle">
          <div className="Header-title">
            {model_name} v.
            {record.version_count ? record.version_count : version}
          </div>
        </div>
        {setingOk ? (
          <TrainingStatus
            mockDataloding={this.state.version_id}
            isRetraining={isRetraining}
            mockData={mockData}
            scrollLoading={scrollLoading}
            isToBeTrained={isToBeTrained}
            btnOktitle={btnOktitle}
            subTitle={subTitle}
            Code={Code}
            title={title}
            progress={progress}
            TextSet={texSetList}
            deleteModelVersion={(e, id) => this.deleteModelVersion(e, id)}
            openTraining={(item) => this.openTraining(item)}
            fetchData={(currentPage) => this.gofetchData(currentPage)}
            goLink={this.goLink.bind(this)}
            onRetraining={this.onRetraining.bind(this)}
            onOpenApp={this.OpenApp.bind(this)}
            onColseadd={this.onColseadd.bind(this)}
          ></TrainingStatus>
        ) : (
          <Layout className="addmodel-box" id="layoutbody">
            <Content>
              <div className="cardform">
                <Typography.Paragraph
                  className="startParagraph"
                  id="startParagraph"
                >
                  {cardstepitem ? cardstepitem.label : ""}模型
                </Typography.Paragraph>
                <div
                  className="forminput"
                  id="forminput"
                  ref={(ref) => (this.myRef = ref)}
                  // onScrollCapture={() => this.handleOnScroll()}
                >
                  {current === 1 ? (
                    <Space
                      direction="vertical"
                      style={{ width: "100%", textAlign: "left" }}
                    >
                      <div className="selsect-fix-label">新数据集</div>
                      <div className="Model-selsect-fix">
                        <Radio.Group
                          onChange={this.onSelsectData.bind(this)}
                          value={checkedtypeData}
                          className="custom-Group"
                        >
                          {newAddList.map((item, i) => {
                            return (
                              <Radio key={item} value={item}>
                                <Space
                                  align="start"
                                  className={`custom-radio-card ${
                                    checkedtypeData === item
                                      ? "custom-radio-card-checked"
                                      : ""
                                  }`}
                                >
                                  <div className="custom-radio-card-mask">
                                    <div className="custom-radio-card-mask-dot"></div>
                                  </div>
                                  <div>
                                    <Typography.Title
                                      heading={6}
                                      className="custom-radio-card-title"
                                    >
                                      {item}
                                    </Typography.Title>
                                  </div>
                                </Space>
                              </Radio>
                            );
                          })}
                        </Radio.Group>
                      </div>
                      {checkedtypeData === "创建数据集" ? (
                        <div className="addData">
                          <div>
                            <Space
                              direction="vertical"
                              style={{ marginBottom: 24 }}
                            >
                              {/* <div className="formtitle">创建数据集</div> */}
                              <Upload
                                multiple
                                showUploadList={false}
                                limit={1}
                                fileList={fileList}
                                accept=".csv"
                                onChange={this.setFile.bind(this)}
                                customRequest={(option) => {
                                  this.handleUpload(option);
                                }}
                                beforeUpload={this.beforeUpload.bind(this)}
                                onExceedLimit={() => {
                                  Notification.warning({
                                    content: "超过上传数量限制！最多上传1个",
                                  });
                                }}
                              >
                                <Space
                                  size="medium"
                                  style={{ color: "#406EFF", fontSize: 16 }}
                                  className="custom-upload-trigger-link"
                                >
                                  点击上传数据集文件
                                  <IconUpload
                                    style={{ color: "#4E5969", fontSize: 16 }}
                                  />
                                </Space>
                              </Upload>
                              {fileList ? (
                                <Space
                                  align="center"
                                  style={{ fontSize: 16 }}
                                  className="upload-Link"
                                >
                                  {fileList.map((item, index) => {
                                    return (
                                      <Link
                                        key={index}
                                        href={
                                          item.response ? item.response.url : ""
                                        }
                                        icon={
                                          item.status === "error" ? (
                                            <IconExclamationCircleFill
                                              style={{
                                                color: "#ff7d00",
                                                fontSize: 16,
                                              }}
                                            />
                                          ) : item.status === "done" ? (
                                            <IconCheckCircleFill
                                              style={{
                                                color: "#00B42A",
                                                fontSize: 16,
                                              }}
                                            />
                                          ) : (
                                            <IconLoading
                                              style={{
                                                color: "#406EFF",
                                                fontSize: 16,
                                              }}
                                            />
                                          )
                                        }
                                      >
                                        {item.name}
                                      </Link>
                                    );
                                  })}
                                  <IconDelete
                                    style={{
                                      color: "#4E5969",
                                      cursor: "pointer",
                                    }}
                                    onClick={this.onIconDelete.bind(this)}
                                  />
                                </Space>
                              ) : null}
                              <Input
                                className="forminput-item inputtext"
                                style={{ width: 464, height: 48 }}
                                allowClear
                                value={namedata}
                                placeholder="请输入数据集名称（限20字符）"
                                onChange={this.onChangeIput.bind(this)}
                                maxLength={20}
                              />
                            </Space>
                          </div>

                          <div className="TipsData">
                            <Space align="start">
                              <IconStarFill style={{ color: "#FFD726" }} />
                              <Typography>
                                为了确保引擎的最终效果，请按以下模板提供标注数据
                              </Typography>
                            </Space>
                            <div className="TipsData-link">
                              {cardstepitem &&
                                cardstepitem.file.map((item, index) => {
                                  return (
                                    <span key={index}>
                                      <Link href={item.url} target="_blank">
                                        {" "}
                                        附：{item.name}.{item.type}
                                      </Link>
                                      {index > 1 ? (
                                        <Link href={item.url} target="_blank">
                                          {" "}
                                          或 {item.name}.{item.type}
                                        </Link>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  );
                                })}
                              {cardstepitem.fie_img ? (
                                <div className="fie_img">
                                  <img src={cardstepitem.fie_img} />
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {DataSelsectList && DataSelsectList.length > 0 ? (
                        <Space direction="vertical">
                          <div className="selsect-fix-label">可用数据集</div>
                          <div className="Model-selsect-fix listloadMore">
                            <Radio.Group
                              onChange={this.onSelsectData.bind(this)}
                              className="custom-Group pageGroup"
                              value={checkedtypeData}
                            >
                              {DataSelsectList.length > 0 &&
                                DataSelsectList.map((item, i) => {
                                  return (
                                    <Radio key={item.id} value={item.id}>
                                      <Space
                                        align="start"
                                        className={`custom-radio-card ${
                                          checkedtypeData === item.id
                                            ? "custom-radio-card-checked"
                                            : ""
                                        }`}
                                      >
                                        <div className="custom-radio-card-mask">
                                          <div className="custom-radio-card-mask-dot"></div>
                                        </div>
                                        <div>
                                          <Typography.Title
                                            heading={6}
                                            className="custom-radio-card-title"
                                          >
                                            {item.name}
                                          </Typography.Title>
                                        </div>
                                      </Space>
                                    </Radio>
                                  );
                                })}
                            </Radio.Group>
                            {pageCount(
                              this.state.paginationdata.total,
                              this.state.paginationdata.pageSize
                            ) > 1 && this.state.paginationdata.current !== 1 ? (
                              <div
                                className="add-IconLeft"
                                onClick={this.setmoreSelect.bind(
                                  this,
                                  "data",
                                  "left"
                                )}
                              >
                                <IconLeft />
                              </div>
                            ) : (
                              ""
                            )}
                            {pageCount(
                              this.state.paginationdata.total,
                              this.state.paginationdata.pageSize
                            ) === this.state.paginationdata.current ? (
                              ""
                            ) : (
                              <div
                                className="add-IconRight"
                                onClick={this.setmoreSelect.bind(
                                  this,
                                  "data",
                                  "right"
                                )}
                              >
                                <IconRight />
                              </div>
                            )}
                          </div>
                        </Space>
                      ) : null}
                      {/* {this.state.PatternList &&
                      this.state.PatternList.length > 0 ? (
                        <>
                          <div className="selsect-fix-label">模型训练模式</div>
                          <div className="Model-selsect-fix">
                            <Radio.Group
                              onChange={this.onSelsectPattern.bind(this)}
                              value={this.state.checkedPattern}
                              className="custom-Group"
                            >
                              {this.state.PatternList.map((item, i) => {
                                return (
                                  <Radio key={item.key} value={item.key}>
                                    <Popover
                                      position="bl"
                                      content={item.desc}
                                      disabled={!item.desc}
                                      className="select-Popover"
                                    >
                                      <Space
                                        align="start"
                                        className={`custom-radio-card ${
                                          this.state.checkedPattern === item.key
                                            ? "custom-radio-card-checked"
                                            : ""
                                        }`}
                                      >
                                        <div className="custom-radio-card-mask">
                                          <div className="custom-radio-card-mask-dot"></div>
                                        </div>
                                        <div>
                                          <Typography.Title
                                            heading={6}
                                            className="custom-radio-card-title"
                                          >
                                            {item.lable}
                                          </Typography.Title>
                                        </div>
                                      </Space>
                                    </Popover>
                                  </Radio>
                                );
                              })}
                            </Radio.Group>
                          </div>
                        </>
                      ) : (
                        ""
                      )} */}
                    </Space>
                  ) : (
                    ""
                  )}
                </div>
                <div className="select-foot" id="selectFoot">
                  <Divider />
                  <Row>
                    <Col span={8}>
                      {current > 1 ? (
                        <Button
                          type="primary"
                          icon={loading ? "" : <IconArrowLeft />}
                          className="Prev custom-step-button"
                          loading={loading}
                          onClick={this.onChangePrev.bind(this)}
                        >
                          上一步
                        </Button>
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col span={4} offset={4} style={{ textAlign: "right" }}>
                    {
                            <Select
                              placeholder="选择训练模式"
                              onChange={this.onSelsectPattern.bind(this)}
                            >
                              {this.state.PatternList.map((option, index) => (
                                <Option key={option.key} value={option.key}>
                                  {option.lable}
                                </Option>
                              ))}
                            </Select>}
                    </Col>
                    <Col span={4} offset={3} style={{ textAlign: "right" }}>
                      {
                        <Button
                          type="primary"
                          className="Next"
                          loading={loading}
                          onClick={this.onChangeNext.bind(this)}
                        >
                          开始训练{loading ? "" : <IconArrowRight />}
                        </Button>
                      }
                    </Col>
                  </Row>
                </div>
              </div>
            </Content>
            <Sider
              style={{ borderRadius: 22, background: "#001a61" }}
              className="cardstep"
            >
              <div className="architecture_top_img"></div>
              <Space size={"large"} className="Space-large">
                <div className="mode-Typography">
                  <Typography.Title className="text-gradient">
                    韦尼克智能创作平台
                  </Typography.Title>
                  <Typography.Title className="text-gradient">
                    轻松创建自有模型
                  </Typography.Title>
                  <Typography style={{ marginTop: 21 }}>
                    导入数据即可开始训练模型
                  </Typography>
                </div>
              </Space>
              <div style={{ overflow: "hidden", textAlign: "center" }}>
                <Steps
                  direction="vertical"
                  current={current}
                  style={{ marginTop: 20 }}
                >
                  <Step icon={<IconCheck />} title="导入数据" />
                  <Step title="模型训练" />
                </Steps>
              </div>
              <div className="Health_coverages"></div>
            </Sider>
          </Layout>
        )}
      </div>
    );
  }
}
export default NewModelVersion;
