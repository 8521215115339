import React, { Component } from 'react'
import {
  Layout,
  Typography,
  Button,
  Space,
  Empty,
  Input,
} from '@arco-design/web-react'
import { IconSync } from '@arco-design/web-react/icon'
import Emptydata_img from 'asserts/Emptydata_img.svg'
import Generating_img from 'asserts/Generating_img.gif'
import errorIcon from 'asserts/error_icon.svg'
import { shareAppApi } from 'api/apis'
import { debounce } from 'common/utils'
import { sourceCancelClass } from 'api/cancel-request'
import $ from 'jquery'
import '../../common/style/myapp/experience.scss'

const Sider = Layout.Sider
const Content = Layout.Content
const TextArea = Input.TextArea

// 封装组件
class WordClass extends Component {
  constructor(props) {
    super(props)
    this.state = {
      optionVlue: '',
      TextAreaValue: '',
      versions: [],
      ParagraphContinuation: [],
      ContinuationLoding: false,
      share_id: localStorage.getItem('share_ids'),
      record: JSON.parse(localStorage.getItem('shareRecord')),
      number: 6,
      requestMsg: '',
      requestMsgText: '您还未输入语句',
      input_describe: '请输入内容，开始续写',
      prompt: '',
      visibleUpgrade: false,
      upgradetips: null,
    }
    this.isPhoneLegal = debounce(this.isPhoneLegal, 1000)
  }
  componentDidMount() {
    this.getApiInfo()
    window.addEventListener('resize', this.handleResize)
    setTimeout(() => {
      this.handleResize()
    }, 300)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }
  handleResize = () => {
    // document.getElementById('layout_id').style.height = height + 'px'
    // if (document.getElementById('continuation')) {
    //   let height2 =
    //     document.getElementById('layout_id').clientHeight -
    //     document.getElementById('result_title').clientHeight -
    //     document.getElementById('IconSynbtn').clientHeight
    //   document.getElementById('continuation').style.height =
    //     height2 - 110 + 'px'
    // }
  }
  getApiInfo() {
    let list = this.state.record
    if (list.type === 0 || list.type === 9) {
      this.setState({
        ContinuationLoding: false,
        requestMsg: '',
      })
      sourceCancelClass()
    }
  }
  onTextArea(value) {
    this.setState({
      TextAreaValue: value,
    })
    if (value.length === 0) {
      this.setState({
        ParagraphContinuation: [],
        ContinuationLoding: false,
        requestMsg: '',
        requestMsgText: '您还未输入内容',
      })
    } else if (value.length > 256) {
      this.setState({
        ParagraphContinuation: [],
        ContinuationLoding: false,
        requestMsg: '',
        requestMsgText: '超出字符限制，请删减内容',
      })
    } else {
      this.setState({
        ContinuationLoding: true,
      })
      this.isPhoneLegal()
    }
    //console.log(value);
  }
  isPhoneLegal = () => {
    this.setState({
      ContinuationLoding: true,
      requestMsg: '',
    })
    sourceCancelClass()
    this.setRequest()
  }
  ChangeAbatch() {
    sourceCancelClass()
    this.setState({
      ContinuationLoding: true,
      requestMsg: '',
    })
    setTimeout(() => {
      this.setRequest()
    }, 1000)
  }
  setRequest() {
    if (this.state.TextAreaValue.length < 0) {
      this.setState({
        ParagraphContinuation: [],
        ContinuationLoding: false,
        requestMsg: '',
        requestMsgText: '您还未输入语句',
      })
    } else if (this.state.TextAreaValue.length > 256) {
      this.setState({
        ParagraphContinuation: [],
        ContinuationLoding: false,
        requestMsg: '',
        requestMsgText: '超出字符限制，请删减内容',
      })
    } else {
      this.sendRequest()
    }
  }

  sendRequest() {
    let params = {
      app_id: this.state.record.app_id,
      text: this.state.TextAreaValue,
      num: this.state.number,
      share_id: this.state.share_id,
    }
    if (this.state.optionVlue) {
      params.version_id = this.state.optionVlue
    }
    shareAppApi(params).then((res) => {
      const code = [400, 3003, 204]
      if (res.code === 200) {
        let list = res.data
        this.setState({
          ParagraphContinuation: list.prediction_label
            ? list.prediction_label
            : [],
          ContinuationLoding: false,
          requestMsg: '',
          prompt: list.prompt,
        })
        this.handleResize()
      } else if (code.includes(res.code)) {
        this.setState({
          ParagraphContinuation: [],
          ContinuationLoding: false,
          requestMsg: '',
          requestMsgText: res.msg,
        })
      } else {
        this.setState({
          ParagraphContinuation: [],
          ContinuationLoding: false,
          requestMsg: 'error',
          requestMsgText: res.msg,
        })
      }
    })
  }

  render() {
    const {
      input_describe,
      ParagraphContinuation,
      ContinuationLoding,
      TextAreaValue,
      requestMsg,
      requestMsgText,
    } = this.state
    return (
      <div className="product-experience">
        <Layout className="semanticprototype-Layout" id="layout_id">
          <Sider className="Sider-left">
            <div className="Sider-left-configure columnSpaceBetween">
              <div className="Continuation-input">
                <TextArea
                  placeholder={input_describe}
                  style={{ width: '100%' }}
                  maxLength={{ length: 256, errorOnly: true }}
                  showWordLimit
                  value={TextAreaValue}
                  onChange={this.onTextArea.bind(this)}
                />
              </div>
            </div>
          </Sider>
          <Content>
            <div className="experience-result" id="result">
              <div id="result_title" className="rowSpaceBetween result_title">
                <Typography>生成结果</Typography>
                <Typography.Paragraph className="result-remarks">
                  (内容基于韦尼克AI创作引擎生成，仅供参考)
                </Typography.Paragraph>
              </div>
              {ParagraphContinuation.length > 0 || ContinuationLoding ? (
                <div className="Paragraph-generate">
                  {ContinuationLoding ? (
                    <Empty
                      className="Continuation-loding"
                      icon={<img src={Generating_img} alt="" />}
                      description={
                        <Typography
                          style={{
                            color: '#4e5969',
                            marginTop: 21,
                            fontSize: 16,
                          }}
                        >
                          正在生成中...
                        </Typography>
                      }
                    />
                  ) : (
                    <div className="Continuation-result" id="result">
                      <div
                        className="Continuation-list"
                        id="continuation"
                        style={{ height: $('#layout_id').height() - 180 }}
                      >
                        {ParagraphContinuation.map((item, index) => {
                          return (
                            <div className="Continuation-item" key={index}>
                              {item}
                            </div>
                          )
                        })}
                      </div>
                      <Button
                        icon={<IconSync />}
                        className="IconSync-btn custom-White-border-button"
                        id="IconSynbtn"
                        onClick={this.ChangeAbatch.bind(this)}
                      >
                        换一批
                      </Button>
                    </div>
                  )}
                </div>
              ) : requestMsg === 'error' ? (
                <Empty
                  className="Continuation-Empty Empty-error"
                  imgSrc={errorIcon}
                  description={
                    <Space direction="vertical">
                      <Typography
                        style={{
                          color: '#4e5969',
                          marginTop: 30,
                          fontSize: 16,
                        }}
                      >
                        抱歉，服务器生成错误
                      </Typography>
                      <Typography style={{ color: '#4e5969', fontSize: 16 }}>
                        可尝试
                        <span
                          style={{ color: '#406EFF', cursor: 'pointer' }}
                          onClick={this.ChangeAbatch.bind(this)}
                        >
                          {' '}
                          重新生成
                        </span>
                      </Typography>
                    </Space>
                  }
                />
              ) : (
                <Empty
                  className="Continuation-Empty Empty-none"
                  imgSrc={Emptydata_img}
                  description={
                    <Space direction="vertical">
                      <Typography
                        style={{
                          color: '#4e5969',
                          marginTop: 30,
                          fontSize: 16,
                        }}
                      >
                        暂无生成结果
                      </Typography>
                      <Typography style={{ color: '#4e5969', fontSize: 16 }}>
                        {requestMsgText}
                      </Typography>
                    </Space>
                  }
                />
              )}
            </div>
          </Content>
        </Layout>
      </div>
    )
  }
}
export default WordClass
