import React, { Component } from 'react'
import {
  Layout,
  Typography,
  Button,
  Space,
  Input,
  Select,
  Notification,
  Divider,
} from '@arco-design/web-react'
import { IconPlus } from '@arco-design/web-react/icon'

import {
  getApiInfo,
  getRequest,
  putmodelversion,
  putapp,
  check_resource,
} from 'api/apis'
import history from 'common/utils/history'
import { CreateLink } from 'common/utils/business'
import { debounce } from 'common/utils'
import { sourceCancelClass } from 'api/cancel-request'
import copy from 'copy-to-clipboard'
import UpgradeTips from 'components/UpgradeTips'
import './copydoneArticle.scss'
const Sider = Layout.Sider
const Content = Layout.Content
const Option = Select.Option
const TextArea = Input.TextArea

// 封装组件
class CopydoneArticle extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Selectoptions: JSON.parse(localStorage.getItem('Selectoptions')),
      no_data: false,
      optionVlue: '',
      TextAreaValue: '',
      versions: [],
      ParagraphContinuation: [],
      ContinuationLoding: false,
      record: JSON.parse(localStorage.getItem('record')),
      apiInfo: {
        model_id: '',
      },
      number: 6,
      requestMsg: '',
      requestMsgText: '您还未输入文段',
      ner: null,
      input_describe: '请输入文段，开始润色',
      visibleUpgrade: false,
      upgradetips: null,
      identity: JSON.parse(localStorage.getItem('userinfo'))
        ? JSON.parse(localStorage.getItem('userinfo')).group_permission
        : '',
    }
    this.isPhoneLegal = debounce(this.isPhoneLegal, 1000)
    this.isputapp = debounce(this.isputapp, 1000)
  }
  componentDidMount() {
    this.getApiInfo()
    window.addEventListener('resize', this.handleResize)
    setTimeout(() => {
      this.handleResize()
    }, 300)
  }
  componentWillUnmount() {
    localStorage.removeItem('props')
    window.removeEventListener('resize', this.handleResize)
  }
  handleResize = () => {
    var winHeight = 0
    if (window.innerHeight) {
      winHeight = window.innerHeight
    } else if (document.body && document.body.clientHeight) {
      winHeight = document.body.clientHeight
    }
    if (document.documentElement && document.documentElement.clientHeight) {
      winHeight = document.documentElement.clientHeight
    }
    let layoutHeaders_h = document.getElementById('layoutHeaders').clientHeight
    if (layoutHeaders_h > winHeight) {
      layoutHeaders_h = 64
    }
    let height =
      winHeight -
      layoutHeaders_h -
      document.getElementById('renderHeaders').clientHeight -
      70
    document.getElementById('layout_id').style.height = height + 'px'

    setTimeout(() => {
      if (document.getElementById('continuation')) {
        let height2 =
          height -
          document.getElementById('result_title').clientHeight -
          document.getElementById('iconSync-btn').clientHeight -
          33
        document.getElementById('continuation').style.height =
          height2 - 42 + 'px'
      }
    }, 100)
  }
  putmodelversion(optionVlue) {
    let params = {
      id: optionVlue,
    }
    this.setState({
      ContinuationLoding: true,
    })
    putmodelversion(params).then((res) => {
      if (res.code === 200) {
        this.isPhoneLegal()
      } else {
        this.setState({
          ParagraphContinuation: [],
          ContinuationLoding: false,
          requestMsg: '',
          requestMsgText: '模型版本激活失败',
        })
      }
    })
  }
  getApiInfo() {
    let params = {
      app_id: this.state.record.id,
    }
    getApiInfo(params).then((res) => {
      if (res.code === 200) {
        let list = res.data
        if (res.data.model_params) {
          history.push('/myapp/enteringGinseng')
          history.go()
        } else {
          this.setState({
            apiInfo: list,
            versions: list.versions,
          })
          // document.title = list.app_name
          if (list.versions.length > 0) {
            this.setState({
              optionVlue: list.versions[0].id,
            })
            // this.putmodelversion(list.versions[0].id)
          }
          //console.log(res.data, "api服务");
          if (list.type === 0) {
            this.setState({
              ContinuationLoding: true,
              requestMsg: '',
            })
            this.onTextArea(list.input_describe ? list.input_describe : '')
            this.isPhoneLegal()
          }
        }
      } else {
      }
    })
  }
  onsetPosition(value) {
    //console.log(value, "value");
    this.setState({
      optionVlue: value,
    })
    this.putmodelversion(value)
  }
  onKeyDown(event) {
    sourceCancelClass()
    //console.log(event.keyCode, "键盘事件");
    if (event.shiftKey && event.keyCode === 13) {
      var e = this.state.TextAreaValue
      this.setState({
        TextAreaValue: e,
      })
    } else if (event.ctrlKey && event.keyCode === 13) {
      event.cancelBubble = true
      event.preventDefault()
      event.stopPropagation()
      event.returnValue = false
      this.setState({
        ParagraphContinuation: [],
        ContinuationLoding: true,
        requestMsg: '',
      })
      this.isPhoneLegal()
      return false
    } else if (event.keyCode === 13) {
      this.setState({
        ParagraphContinuation: [],
        ContinuationLoding: true,
        requestMsg: '',
      })
      event.cancelBubble = true
      event.preventDefault()
      event.stopPropagation()
      this.isPhoneLegal()
      event.returnValue = false
      return false
    }
  }
  onTextArea(value) {
    this.setState({
      TextAreaValue: value,
    })

    //console.log(value);
  }
  goGeneration() {
    this.setState({
      ContinuationLoding: true,
    })
    sourceCancelClass()
    this.isPhoneLegal()
  }
  isPhoneLegal = () => {
    this.setState({
      ParagraphContinuation: [],
      ContinuationLoding: true,
      requestMsg: '',
    })
    this.setRequest()
  }
  ChangeAbatch() {
    this.setState({
      ContinuationLoding: true,
      requestMsg: '',
    })
    setTimeout(() => {
      this.setRequest()
    }, 1000)
  }
  setRequest() {
    let params = {
      app_id: this.state.record.id,
      text: this.state.TextAreaValue,
      num: this.state.number,
    }
    if (this.state.optionVlue) {
      params.version_id = this.state.optionVlue
    }

    let that = this
    this.getCheckResource(function (result) {
      if (result.code === 200) {
        getRequest(params).then((res) => {
          if (res.code === 200) {
            let list = res.data
            setTimeout(() => {
              that.setState({
                ner: list.ner,
                ParagraphContinuation: list.generations
                  ? that.uniqueList(list.generations)
                  : [],
                ContinuationLoding: false,
                requestMsg: '',
              })
              if (that.uniqueList(list.generations).length === 0) {
                that.setState({
                  ParagraphContinuation: [],
                  ContinuationLoding: false,
                  requestMsg: '',
                  requestMsgText: '请换个内容试试',
                })
              }
              that.handleResize()
            }, 100)
            //console.log(res.data, "结果");
          } else if (res.code === 400 || res.code === 3003) {
            that.setState({
              ParagraphContinuation: [],
              ContinuationLoding: false,
              requestMsg: '',
              requestMsgText: res.msg,
            })
          } else if (res.code === 204) {
            that.setState({
              ParagraphContinuation: [],
              ContinuationLoding: false,
              requestMsg: '',
              requestMsgText: '请换个内容试试',
            })
          } else {
            that.setState({
              ParagraphContinuation: [],
              ContinuationLoding: false,
              requestMsg: 'error',
              requestMsgText: res.msg,
            })
          }
        })
      } else {
        that.setState({
          ParagraphContinuation: [],
          ContinuationLoding: false,
          requestMsg: 'error',
          requestMsgText: result.msg,
        })
      }
    })
  }
  getCheckResource(callback) {
    let params = { model_capability: this.state.apiInfo?.model_capability }
    let PACKAGES = ['PACKAGE_FINISH', 'PACKAGE_EXPIRED']
    check_resource(params).then((res) => {
      if (res.code === 200) {
        if (res.data) {
          let new_upgradetips = res.data
          new_upgradetips.model_capability = params.model_capability
          this.setState({
            upgradetips: new_upgradetips,
          })
          if (PACKAGES[new_upgradetips.notice_type]) {
            Notification.warning({ content: new_upgradetips.message })
          } else {
            setTimeout(() => {
              this.setState({
                visibleUpgrade: true,
              })
            }, 100)
          }
        }
        callback(res)
      } else {
        callback(res)
      }
    })
  }
  // 根据下标替换字符
  oneReplace(str, index) {
    let arr = str.split('')
    return arr[index]
  }
  lengthReplace(text, start, stop) {
    let newstr = text.substring(start, stop + 1)
    return newstr
  }
  replaceAllText(newstr) {
    let nerlist = this.state.ner
    let oldnewstr = newstr
    Object.keys(nerlist ? nerlist : []).map((item, i) => {
      nerlist[item].map((key, index) => {
        let replaceText = key[0]
        // if (key[0].length === 1) {
        //   replaceText = this.oneReplace(oldnewstr, key[1][0])
        // } else {
        //   let endindex = key[1].length - 1
        //   let end = key[1][endindex] //结束下标
        //   let start = key[1][0] //开始小标
        //   replaceText = this.lengthReplace(oldnewstr, start, end)
        // }
        let rehtml = `<span style='color: #406EFF'>` + replaceText + `</span>`
        // let reg = key[0].toLowerCase()
        // var flagnew = key[0].replace(/[^a-z\d]/ig, "");
        // if (flagnew != null) {
        newstr = newstr.replaceAll(new RegExp(replaceText, 'ig'), rehtml)
        // }
      })
    })

    let html = { __html: newstr.replaceAll('\n', '<br/>') }
    return <div dangerouslySetInnerHTML={html}></div>
  }
  // 数组中对象的某个属性值进行去重
  uniqueList = (arr) => {
    const res = new Map()
    return arr.filter((a) => !res.has(a.sentence) && res.set(a.sentence, 1))
  }
  goResources() {
    history.push('/myapp/resources')
    localStorage.setItem('record', JSON.stringify(this.state.record))
    window.location.reload()
  }
  goInterfacetest() {
    history.push('/myapp/interfacetest')
    localStorage.setItem('record', JSON.stringify(this.state.record))
    window.location.reload()
  }
  oncopy(item) {
    let value = item.sentence
    if (copy(value)) {
      Notification.success({ content: '复制成功!' })
    } else {
      Notification.error({ content: '复制失败!' })
    }
  }
  onGomanagement() {
    history.push('/enginefactory/model/management')
    localStorage.removeItem('props')
    let apiInfo = this.state.apiInfo
    let record = {
      id: apiInfo.model_id,
      model_capability: apiInfo.model_capability,
      name: apiInfo.model_name,
    }
    localStorage.setItem('record', JSON.stringify(record))
    window.location.reload()
  }
  isputapp = (name, desc) => {
    this.putapp(name, desc)
  }
  putapp(name, desc) {
    let params = {
      id: this.state.record.id,
    }
    if (desc !== false) {
      params.desc = desc
    } else if (name !== false) {
      params.name = name
    }
    putapp(params).then((res) => {
      if (res.code === 200) {
      } else {
        this.getApiInfo(true)
        Notification.error({ content: res.msg })
      }
    })
  }
  onChangetitle(type, value) {
    //console.log(value, "value");
    var apiInfo = this.state.apiInfo
    if (type === 'desc') {
      if (value.length > 100) {
        apiInfo.app_desc = value.substring(0, 100)
        Notification.warning({ content: '修改内容超过100个字！' })
        return
      }
      apiInfo.app_desc = value
      this.setState({
        apiInfo: apiInfo,
      })
      this.isputapp(false, value)
    } else if (type === 'name') {
      if (value.length > 20) {
        apiInfo.app_name = value.substring(0, 20)
        Notification.warning({ content: '修改标题超过20个字！' })
        return
      }
      apiInfo.app_name = value
      this.setState({
        apiInfo: apiInfo,
      })
      this.isputapp(value, false)
    }
  }
  goaddApp() {
    history.replace('/myapp/addmyapp')
    let state = {
      model_capability: this.state.apiInfo.model_capability,
      checkedtype: this.state.apiInfo.model_id,
      model_id: this.state.apiInfo.model_id,
      name: this.state.apiInfo.model_name,
    }
    localStorage.setItem('props', JSON.stringify(state))
    localStorage.removeItem('record')
    window.location.reload()
  }
  addRow() {
    localStorage.removeItem('props')
    localStorage.setItem('record', JSON.stringify(this.state.apiInfo))
    history.replace('/enginefactory/model/management/add')
    window.location.reload()
  }
  setvisibleUpgrade(value) {
    this.setState({
      visibleUpgrade: value,
    })
  }
  render() {
    const { visibleUpgrade, upgradetips, apiInfo, versions, optionVlue } =
      this.state
    return (
      <div className="embroidery">
        <div id="renderHeaders">
          <div className="GroupTable-fixe GroupTable-fixe-end">
            <div className="edit-title">
              <div className="Header-init">
                <div className="Header-title">
                  <Typography.Paragraph
                    className="Header-uptitle"
                    editable={
                      apiInfo.type === 0
                        ? null
                        : {
                            onChange: this.onChangetitle.bind(this, 'name'),
                          }
                    }
                  >
                    {apiInfo.app_name}
                  </Typography.Paragraph>
                </div>
              </div>
              <div className="Header-upParagraph">
                <Typography.Paragraph
                  className="Header-uptitle"
                  editable={
                    apiInfo.type === 0
                      ? null
                      : {
                          onChange: this.onChangetitle.bind(this, 'desc'),
                        }
                  }
                >
                  {apiInfo.app_desc}
                </Typography.Paragraph>
              </div>
            </div>
            <Space size={'medium'} align="center">
              {apiInfo.type === 0 ? (
                <Button
                  className="primarybtn custom-White-button"
                  onClick={this.goaddApp.bind(this)}
                >
                  保存应用
                </Button>
              ) : (
                <>
                  {versions.length === 0 ? (
                    <div></div>
                  ) : (
                    <Select
                      value={optionVlue}
                      placeholder="版本选择"
                      style={{ width: 220 }}
                      className="versionsSelect"
                      onChange={this.onsetPosition.bind(this)}
                      dropdownRender={(menu) => (
                        <div>
                          {menu}
                          <Divider
                            style={{
                              margin: 0,
                              marginTop: 6,
                              borderColor: '#f2f2f2',
                            }}
                          />
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              padding: '5px 0px',
                            }}
                          >
                            <Button
                              style={{
                                width: '100%',
                                height: 41,
                                fontSize: 14,
                                padding: '0 6px',
                                borderRadius: 10,
                              }}
                              type="text"
                              onClick={this.addRow.bind(this)}
                            >
                              <IconPlus />
                              新增模型版本
                            </Button>
                          </div>
                        </div>
                      )}
                    >
                      {versions.map((option, index) => (
                        <Option key={index} value={option.id}>
                          {apiInfo.model_name}V{option.version}
                        </Option>
                      ))}
                    </Select>
                  )}
                  {apiInfo.type === 9 ? (
                    <div className="primary_model_name">
                      <div className="primary_model_name_fix">
                        <span className="primary_model_name_title">
                          模型
                          <Divider type="vertical" />
                        </span>
                        <Typography.Paragraph ellipsis>
                          {apiInfo.model_name}
                        </Typography.Paragraph>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {/* <Button
                    shape="circle"
                    className="custom-White-button"
                    onClick={this.goResources.bind(this)}
                  >
                    应用资源
                  </Button> */}
                  <Button
                    shape="circle"
                    className="custom-White-button share_button_wnk"
                    onClick={CreateLink.bind(this, apiInfo)}
                  >
                    分享应用
                  </Button>
                  <Button
                    style={{
                      display:
                        this.state.identity &&
                        this.state.identity !== 'PERSONAL'
                          ? 'inline-block'
                          : 'none',
                    }}
                    shape="circle"
                    className="custom-White-button"
                    onClick={this.goInterfacetest.bind(this)}
                  >
                    接口调用
                  </Button>
                </>
              )}
            </Space>
          </div>
        </div>
        <Layout className="semanticprototype-Layout" id="layout_id">
          <Sider className="Sider-left">
            <div style={{ color: 'black' }}>暂无页面</div>
          </Sider>
          <Content>
            <div className="experience-result" id="result">
              <div style={{ color: 'black' }}>暂无页面</div>
            </div>
          </Content>
        </Layout>
        <UpgradeTips
          visible={visibleUpgrade}
          notification_status={upgradetips?.notice_type}
          notification_message={upgradetips?.message}
          model_capability={upgradetips?.model_capability}
          onCancel={() => this.setvisibleUpgrade(false)}
        ></UpgradeTips>
      </div>
    )
  }
}
export default CopydoneArticle
