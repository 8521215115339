import React, { Component } from 'react'
import {
  Layout,
  Typography,
  Button,
  Message,
  Space,
  Empty,
  Input,
  Select,
  Notification,
  Divider,
  Spin
} from '@arco-design/web-react'
import { IconSync, IconPlus } from '@arco-design/web-react/icon'
import Emptydata_img from 'asserts/Emptydata_img.svg'
import Generating_img from 'asserts/Generating_img.gif'
import errorIcon from 'asserts/error_icon.svg'
import {
  getApiInfo,
  getRequest,
  putmodelversion,
  putapp,
  check_resource,
  getAuthorizationCode, 
  getAuthorizationToken
} from 'api/apis'
import history from 'common/utils/history'
import { debounce } from 'common/utils'
import copy from 'copy-to-clipboard'
import { sourceCancelClass } from 'api/cancel-request'
import UpgradeTips from 'components/UpgradeTips'
import { CreateLink } from 'common/utils/business'
import $ from 'jquery'
import './experience.scss'
const Sider = Layout.Sider
const Content = Layout.Content
const Option = Select.Option
const TextArea = Input.TextArea

// 封装组件
class AigcExperience extends Component {
  constructor(props) {
    super(props)
    this.state = {
      optionVlue: '',
      TextAreaValue: '',
      versions: [],
      ParagraphContinuation: [],
      ContinuationLoding: false,
      pagesPreview: false,
      record: JSON.parse(localStorage.getItem('record')),
      apiInfo: {
        model_id: '',
      },
      number: 6,
      requestMsg: '',
      requestMsgText: '您还未输入语句',
      input_describe: '请输入内容，开始续写',
      prompt: '',
      visibleUpgrade: false,
      upgradetips: null,
      identity: JSON.parse(localStorage.getItem('userinfo'))
        ? JSON.parse(localStorage.getItem('userinfo')).group_permission
        : '',
      token: '',
      iframeUrl: process.env.REACT_APP_ENV === 'development' ? "https://aigc.test.yoo-ai.com" : "https://aigc.yoo-ai.com",
      chatpptSrc: '',
      iframeHeight:''
    }
    this.isPhoneLegal = debounce(this.isPhoneLegal, 1000)
    this.isputapp = debounce(this.isputapp, 1000)
  }
  componentDidMount() {
    this.getApiInfo()
    this.getUserCode()
   
    window.addEventListener('resize', this.handleResize)
    setTimeout(() => {
      this.handleResize()
    }, 300)

    // 父页面监听子页面的消息
    window.addEventListener('message', this.toggleFullScreen, false);
    document.addEventListener('fullscreenchange', this.fullscreenchange)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener('message', this.toggleFullScreen)
    document.removeEventListener('fullscreenchange', this.fullscreenchange)
  }

  //获取用户的授权code和授权token
  getUserCode(){
    getAuthorizationCode().then((res) => {
      if (res.code === 200) {
        let code = res.data.code;
        getAuthorizationToken({code}).then((res) => {
          if (res.code === 200) {
            this.setState({
              token: res.data.token,
            })
            this.setState({
              chatpptSrc: this.state.iframeUrl + '/saaseditPPT?channel=yoo&urlKey=saas&tokens=' + this.state.token + '&appid=' + this.state.record.id,
              // chatpptSrc: 'http://192.168.10.18:8080' + '/saaseditPPT?channel=yoo&urlKey=saas&tokens=' + this.state.token + '&appid=' + this.state.record.id,
            })
            localStorage.setItem('saastoken', JSON.stringify(res.data.token))
          } else {
            Message.error(res.msg)
          }
        })
      }  else {
        Message.error(res.msg)
      }
    })
  }
  // 获取子组件信息 让父组件进入全屏模式
  toggleFullScreen = (event)=>{
    if (event.data.action === 'toggleFullScreen') {
        // requestFullScreen();
        this.setState({
          pagesPreview: event.data.data.pagesPreview
        })
        const myappDom = $('.main-content.myapp')[0]
        if(this.state.pagesPreview){
          myappDom.classList.add('myapp-fullscreen')
          this.enterFullScreen()
        } else {
          myappDom.classList.remove('myapp-fullscreen')
          this.exitFullScreen()
        }
    }
  }
       /**
     * 全屏事件监听
     */
      fullscreenchange = (e) => {
        // 检查是否退出了全屏模式
        if (!document.fullscreenElement) {
          // 注意：直接调用 enterFullScreen 可能因浏览器安全策略而不起作用
          this.setState({
            pagesPreview: false
          })
          //给子组件传递信息 取消全屏模式
          const sassIframe = $('.sass-semanticprototype-Layout')[0].contentWindow
          sassIframe.postMessage(false, "*");
        }
      }
      /**
     * 进入全屏模式
     */
   enterFullScreen = ()=> {
    let element = document.documentElement
    if (element.requestFullscreen) {
      element.requestFullscreen()
    } else if (element.mozRequestFullScreen) {
      /* Firefox */
      element.mozRequestFullScreen()
    } else if (element.webkitRequestFullscreen) {
      /* Chrome, Safari & Opera */
      element.webkitRequestFullscreen()
    } else if (element.msRequestFullscreen) {
      /* IE/Edge */
      element.msRequestFullscreen()
    }
  }
  /**
     * 退出全屏模式
     */
   exitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.mozCancelFullScreen) {
      /* Firefox */
      document.mozCancelFullScreen()
    } else if (document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      document.webkitExitFullscreen()
    } else if (document.msExitFullscreen) {
      /* IE/Edge */
      document.msExitFullscreen()
    }
  }
  handleResize = () => {
    var winHeight = 0
    if (window.innerHeight) {
      winHeight = window.innerHeight
    } else if (document.body && document.body.clientHeight) {
      winHeight = document.body.clientHeight
    }
    if (document.documentElement && document.documentElement.clientHeight) {
      winHeight = document.documentElement.clientHeight
    }
    let layoutHeaders_h = $('#layoutHeaders').innerHeight()
    if (layoutHeaders_h > winHeight) {
      layoutHeaders_h = 64
    }
    let height =
      winHeight -
      layoutHeaders_h -
      document.getElementById('renderHeaders').clientHeight -
      32 -
      45
      
      this.state.iframeHeight = height
    if(document.getElementById('layout_id')){
      document.getElementById('layout_id').style.height = height + 'px'
    }
  
    if (document.getElementById('continuation')) {
      let height2 =
        document.getElementById('layout_id').clientHeight -
        document.getElementById('result_title').clientHeight -
        document.getElementById('IconSynbtn').clientHeight
      document.getElementById('continuation').style.height =
        height2 - 110 + 'px'
    }
  }
  putmodelversion(optionVlue) {
    let params = {
      id: optionVlue,
    }
    sourceCancelClass()
    this.setState({
      ContinuationLoding: true,
      requestMsg: '',
    })
    putmodelversion(params).then((res) => {
      if (res.code === 200) {
        this.isPhoneLegal()
      } else {
        this.setState({
          ParagraphContinuation: [],
          ContinuationLoding: false,
          requestMsg: '',
          requestMsgText: '模型版本激活失败',
          loading: false,
        })
      }
    })
  }
  getApiInfo() {
    let params = {
      app_id: this.state.record.id,
    }
    getApiInfo(params).then((res) => {
      if (res.code === 200) {
        let list = res.data
        if (res.data.model_params) {
          history.push('/myapp/enteringGinseng')
          history.go()
        } else {
          this.setState({
            apiInfo: list,
            versions: list.versions,
          })
          // document.title = list.app_name
          if (list.versions.length > 0) {
            this.setState({
              optionVlue: list.versions[0].id,
            })
            // this.putmodelversion(list.versions[0].id)
          }
          if (list.type === 0 || list.type === 9) {
            this.onTextArea(
              list.input_describe ? list.input_describe : '负责公司全线产品'
            )
            this.isPhoneLegal()
          }
        }
        //console.log(res.data, "api服务");
      } else {
      }
    })
  }
  onsetPosition(value) {
    //console.log(value, "value");
    this.setState({
      optionVlue: value,
    })
    this.putmodelversion(value)
  }
  onTextArea(value) {
    this.setState({
      ContinuationLoding: true,
    })
    this.setState({
      TextAreaValue: value,
    })
    this.isPhoneLegal()
    //console.log(value);
  }
  isPhoneLegal = () => {
    this.setState({
      ContinuationLoding: true,
      requestMsg: '',
    })
    sourceCancelClass()
    this.setRequest()
  }
  ChangeAbatch() {
    sourceCancelClass()
    this.setState({
      ContinuationLoding: true,
      requestMsg: '',
    })
    setTimeout(() => {
      this.setRequest()
    }, 1000)
  }
  setRequest() {
    if (this.state.TextAreaValue.length < 0) {
      this.setState({
        ParagraphContinuation: [],
        ContinuationLoding: false,
        requestMsg: '',
        requestMsgText: '您还未输入语句',
      })
    } else {
      let params = {
        app_id: this.state.record.id,
        text: this.state.TextAreaValue,
        num: this.state.number,
      }
      if (this.state.optionVlue) {
        params.version_id = this.state.optionVlue
      }

      let that = this
      this.getCheckResource(function (result) {
        if (result.code === 200) {
          getRequest(params).then((res) => {
            if (res.code === 200) {
              let list = res.data
              that.setState({
                ParagraphContinuation: list.generations
                  ? that.uniqueList(list.generations)
                  : [],
                ContinuationLoding: false,
                requestMsg: '',
                prompt: list.prompt,
              })
              that.handleResize()
            } else if (res.code === 400 || res.code === 3003) {
              that.setState({
                ParagraphContinuation: [],
                ContinuationLoding: false,
                requestMsg: '',
                requestMsgText: res.msg,
              })
            } else {
              that.setState({
                ParagraphContinuation: [],
                ContinuationLoding: false,
                requestMsg: 'error',
                requestMsgText: res.msg,
              })
            }
          })
        } else {
          that.setState({
            ParagraphContinuation: [],
            ContinuationLoding: false,
            requestMsg: 'error',
            requestMsgText: result.msg,
          })
        }
      })
    }
  }
  getCheckResource(callback) {
    let params = { model_capability: this.state.apiInfo?.model_capability }
    let PACKAGES = ['PACKAGE_FINISH', 'PACKAGE_EXPIRED']
    check_resource(params).then((res) => {
      if (res.code === 200) {
        if (res.data) {
          let new_upgradetips = res.data
          new_upgradetips.model_capability = params.model_capability
          this.setState({
            upgradetips: new_upgradetips,
          })
          if (PACKAGES[new_upgradetips.notice_type]) {
            Notification.warning({ content: new_upgradetips.message })
          } else {
            setTimeout(() => {
              this.setState({
                visibleUpgrade: true,
              })
            }, 100)
          }
        }
        callback(res)
      } else {
        callback(res)
      }
    })
  }
  // 数组中对象的某个属性值进行去重
  uniqueList = (arr) => {
    const res = new Map()
    return arr.filter((a) => !res.has(a.sentence) && res.set(a.sentence, 1))
  }
  goResources() {
    history.push('/myapp/resources')
    localStorage.setItem('record', JSON.stringify(this.state.record))
    window.location.reload()
  }
  goInterfacetest() {
    localStorage.setItem('record', JSON.stringify(this.state.record))
    const newWindow = window.open('https://wiki.yoo-ai.com/#/wernicke/api/?id=chatppt%e7%94%9f%e6%88%90%e6%8e%a5%e5%8f%a3', '_blank');
    if (newWindow) newWindow.focus(); // 如果浏览器允许打开新窗口，聚焦到新窗口
  }
  oncopy(item) {
    let value = item.sentence
    if (copy(value)) {
      Notification.success({ content: '复制成功!' })
    } else {
      Notification.error({ content: '复制失败!' })
    }
  }
  Onreplace(newstr, finished) {
    let reg = this.state.prompt.toLowerCase()
    let html = `<span class='Preamble'>${reg}</span>`

    if (!finished) {
      let regFinished = /([^0-9a-zA-Z\u4e00-\u9fff])*$/
      newstr = newstr.replace(regFinished, '...')
    }
    newstr = newstr.replace(reg, html)
    let htmls = { __html: newstr }
    return <div dangerouslySetInnerHTML={htmls}></div>
  }
  isputapp = (name, desc) => {
    this.putapp(name, desc)
  }
  putapp(name, desc) {
    let params = {
      id: this.state.record.id,
    }
    if (desc !== false) {
      params.desc = desc
    } else if (name !== false) {
      params.name = name
    }
    putapp(params).then((res) => {
      if (res.code === 200) {
      } else {
        this.getApiInfo(true)
        Notification.error({ content: res.msg })
      }
    })
  }
  onChangetitle(type, value) {
    //console.log(value, "value");
    var apiInfo = this.state.apiInfo
    if (type === 'desc') {
      if (value.length > 32) {
        apiInfo.app_desc = value.substring(0, 32)
        Notification.warning({ content: '修改内容超过32个字！' })
        return
      }
      apiInfo.app_desc = value
      this.setState({
        apiInfo: apiInfo,
      })
      this.isputapp(false, value)
    } else if (type === 'name') {
      if (value.length > 10) {
        apiInfo.app_name = value.substring(0, 10)
        Notification.warning({ content: '修改标题超过10个字！' })
        return
      }
      apiInfo.app_name = value
      this.setState({
        apiInfo: apiInfo,
      })
      this.isputapp(value, false)
    }
  }
  goaddApp() {
    history.replace('/myapp/addmyapp')
    let state = {
      model_capability: this.state.apiInfo.model_capability,
      checkedtype: this.state.apiInfo.model_id,
      model_id: this.state.apiInfo.model_id,
      name: this.state.apiInfo.model_name,
    }
    localStorage.setItem('props', JSON.stringify(state))
    localStorage.removeItem('record')
    window.location.reload()
  }
  addRow() {
    localStorage.removeItem('props')
    localStorage.setItem('record', JSON.stringify(this.state.apiInfo))
    history.replace('/enginefactory/model/management/add')
    window.location.reload()
  }
  setvisibleUpgrade(value) {
    this.setState({
      visibleUpgrade: value,
    })
  }
  render() {
    const {
      visibleUpgrade,
      upgradetips,
      apiInfo,
      versions,
      input_describe,
      ParagraphContinuation,
      ContinuationLoding,
      TextAreaValue,
      optionVlue,
      requestMsg,
      requestMsgText,
      token,
      chatpptSrc,
      iframeHeight,
      pagesPreview
    } = this.state
    return (
      <div className="product-experience">
        <div id="renderHeaders">
          <div className="GroupTable-fixe GroupTable-fixe-end">
            <div className="edit-title">
              <div className="Header-init">
                <div className="Header-title">
                  <Typography.Paragraph
                    className="Header-uptitle"
                    editable={
                      apiInfo.type === 0
                        ? null
                        : {
                            onChange: this.onChangetitle.bind(this, 'name'),
                          }
                    }
                  >
                    {apiInfo.app_name}
                  </Typography.Paragraph>
                </div>
              </div>
              <div className="Header-upParagraph">
                <Typography.Paragraph
                  className="Header-uptitle"
                  editable={
                    apiInfo.type === 0
                      ? null
                      : {
                          onChange: this.onChangetitle.bind(this, 'desc'),
                        }
                  }
                >
                  {apiInfo.app_desc}
                </Typography.Paragraph>
              </div>
            </div>
            <Space size={'medium'} align="center">
              {apiInfo.type === 0 ? (
                <Button
                  className="primarybtn custom-White-button"
                  onClick={this.goaddApp.bind(this)}
                >
                  保存应用
                </Button>
              ) : (
                <>
                  {versions.length === 0 ? (
                    <div></div>
                  ) : (
                    <Select
                      value={optionVlue}
                      placeholder="版本选择"
                      style={{ width: 220 }}
                      className="versionsSelect"
                      onChange={this.onsetPosition.bind(this)}
                      dropdownRender={(menu) => (
                        <div>
                          {menu}
                          <Divider
                            style={{
                              margin: 0,
                              marginTop: 6,
                              borderColor: '#f2f2f2',
                            }}
                          />
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              padding: '5px 0px',
                            }}
                          >
                            <Button
                              style={{
                                width: '100%',
                                height: 41,
                                fontSize: 14,
                                padding: '0 6px',
                                borderRadius: 10,
                              }}
                              type="text"
                              onClick={this.addRow.bind(this)}
                            >
                              <IconPlus />
                              新增模型版本
                            </Button>
                          </div>
                        </div>
                      )}
                    >
                      {versions.map((option, index) => (
                        <Option key={index} value={option.id}>
                          {apiInfo.model_name}V{option.version}
                        </Option>
                      ))}
                    </Select>
                  )}
                  {/* {apiInfo.type === 9 ? ( */}
                    <div className="primary_model_name primary_model_name_aigc">
                      <div className="primary_model_name_fix">
                        <span className="primary_model_name_title">
                          URL
                          <Divider type="vertical" />
                        </span>
                        <Typography.Paragraph ellipsis copyable>
                          {chatpptSrc}
                        </Typography.Paragraph>
                        {/* <span class="arco-typography-operation-copy"><svg fill="none" stroke="currentColor" stroke-width="4" viewBox="0 0 48 48" width="1em" height="1em" class="arco-icon arco-icon-copy"><path d="M20 6h18a2 2 0 0 1 2 2v22M8 16v24c0 1.105.891 2 1.996 2h20.007A1.99 1.99 0 0 0 32 40.008V15.997A1.997 1.997 0 0 0 30 14H10a2 2 0 0 0-2 2Z"></path></svg></span> */}
                      </div>
                    </div>
                  {/* ) : (
                    ''
                  )} */}
       
                  <Button
                    shape="circle"
                    className="custom-White-button share_button_wnk"
                    onClick={CreateLink.bind(this, apiInfo)}
                  >
                    分享应用
                  </Button>
                  <Button
                    shape="circle"
                    className="custom-White-button"
                    onClick={this.goInterfacetest.bind(this)}
                  >
                    对接文档
                  </Button>
                </>
              )}
            </Space>
          </div>
        </div>
        {
          token  ? 
          <iframe src={chatpptSrc} height={iframeHeight} className={`semanticprototype-Layout sass-semanticprototype-Layout ${pagesPreview ? 'iframeFullScreen' : ''}`} id="layout_id"></iframe>
          : 
          <div className='iframe-loading'>
            <Spin size={60}/>
          </div>
        }
        {/* <UpgradeTips
          visible={visibleUpgrade}
          notification_status={upgradetips?.notice_type}
          notification_message={upgradetips?.message}
          model_capability={upgradetips?.model_capability}
          onCancel={() => this.setvisibleUpgrade(false)}
        ></UpgradeTips> */}
      </div>
    )
  }
}
export default AigcExperience
