import React, { Component } from "react";
import "./index.scss";

class Progress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cx: props.cx,
      cy: props.cx,
      rate: props.rate ? props.rate : 0,
      bgRingColor: props.bgRingColor ? props.bgRingColor : "#ebeef5",
      ringColor: props.ringColor ? props.ringColor : "#67C23A",
      strokeLinecap: props.strokeLinecap ? props.strokeLinecap : "round",
      strokeWidth: props.strokeWidth ? props.strokeWidth : 20,
      childrenSlot: props.childrenSlot,
      part: props.part ? props.part : 20,
      partGap: props.partGap ? props.partGap : 12,
      showGradient: props.showGradient ? props.showGradient : false,
      gradient: props.gradient
        ? props.gradient
        : {
            id: "svg-linear-gradient",
            x1: "0%",
            y1: "0%",
            x2: "0%",
            y2: "100%",
            colorStops: [
              {
                offset: "0%",
                color: "yellow",
              },
              {
                offset: "100%",
                color: "orange",
              },
            ],
          },
      SplitThinLines: props.SplitThinLines ? props.SplitThinLines : false,
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      cx: nextProps.cx,
      cy: nextProps.cx,
      rate: nextProps.rate ? nextProps.rate : 0,
      bgRingColor: nextProps.bgRingColor ? nextProps.bgRingColor : "#ebeef5",
      ringColor: nextProps.ringColor ? nextProps.ringColor : "#67C23A",
      strokeLinecap: nextProps.strokeLinecap
        ? nextProps.strokeLinecap
        : "round",
      strokeWidth: nextProps.strokeWidth ? nextProps.strokeWidth : 20,
      childrenSlot: nextProps.childrenSlot,
      part: nextProps.part ? nextProps.part : 20,
      partGap: nextProps.partGap ? nextProps.partGap : 12,
      showGradient: nextProps.showGradient ? nextProps.showGradient : false,
      gradient: nextProps.gradient
        ? nextProps.gradient
        : {
            id: "svg-linear-gradient",
            x1: "0%",
            y1: "0%",
            x2: "0%",
            y2: "100%",
            colorStops: [
              {
                offset: "0%",
                color: "yellow",
              },
              {
                offset: "100%",
                color: "orange",
              },
            ],
          },
      SplitThinLines: nextProps.SplitThinLines
        ? nextProps.SplitThinLines
        : false,
    });
  }
  radius() {
    return this.state.cx - this.state.strokeWidth / 2;
    //return 200 - this.strokeWidth / 2;
  }
  strokeDasharray() {
    let value = 0;
    /* 
      当stroke-linecap的值为round时，结尾处会向外延伸描边宽度的一半
      首尾两个端点，所以就是描边的宽度
    */
    if (this.state.strokeLinecap === "round") {
      value = 2 * Math.PI * this.radius() + this.state.strokeWidth;
    } else {
      value = 2 * Math.PI * this.radius();
    }
    return value;
    // return 2 * Math.PI * this.radius();
  }
  strokeDashoffset() {
    let val = 0;
    let rate = this.state.rate;
    if (rate > 1) {
      rate = 1;
    } else if (rate < 0) {
      rate = 0;
    }
    if (this.state.strokeLinecap === "round") {
      val = 2 * Math.PI * this.radius() * (1 - rate) + this.state.strokeWidth;
    } else {
      val = 2 * Math.PI * this.radius() * (1 - rate);
    }
    return val;
  }
  strokeDasharray1() {
    var partLength = (2 * Math.PI * this.radius()) / this.state.part;
    return `${this.state.partGap} ${partLength - this.state.partGap}`;
  }
  render() {
    const {
      cx,
      cy,
      bgRingColor,
      strokeWidth,
      ringColor,
      strokeLinecap,
      childrenSlot,
      gradient,
      showGradient,
      SplitThinLines,
    } = this.state;
    function Slot(props) {
      // 接收children属性
      const { children } = props;
      return <>{children}</>;
    }
    return (
      <div className="progress">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox={`0 0 ${cx * 2} ${cy * 2}`}
        >
          {/* <!-- 定义渐变色 --> */}
          {showGradient ? (
            <defs>
              <linearGradient
                id={gradient?.id}
                x1={gradient?.x1}
                y1={gradient?.y1}
                x2={gradient?.x2}
                y2={gradient?.y2}
              >
                {gradient?.colorStops.map((item, index) => {
                  return (
                    <stop
                      key={index}
                      offset={item.offset}
                      stopColor={item.color}
                    />
                  );
                })}
              </linearGradient>
            </defs>
          ) : null}
          {/* <!-- 底部背景圆环 --> */}
          <circle
            cx={cx}
            cy={cy}
            r={this.radius()}
            stroke={bgRingColor}
            strokeWidth={strokeWidth}
            fill="none"
          />
          {/* <!-- 进度条圆环 --> */}
          <circle
            className="progress-bar"
            ref="progressbar"
            cx={cx}
            cy={cy}
            r={this.radius()}
            stroke={showGradient ? `url(#${gradient?.id})` : ringColor}
            strokeWidth={strokeWidth}
            fill="none"
            strokeLinecap={strokeLinecap}
            transform={`rotate(-90, ${cx}, ${cy})`}
            strokeDasharray={this.strokeDasharray()}
            strokeDashoffset={this.strokeDashoffset()}
            style={{
              "--stroke-dashoffset": this.strokeDashoffset(),
            }}
          />
          {/* <!-- 环形分割细线 --> */}
          {SplitThinLines ? (
            <circle
              cx={cx}
              cy={cy}
              r={this.radius()}
              stroke=" #EAB36F"
              fill="transparent"
              strokeWidth={strokeWidth + 2}
              strokeDasharray={this.strokeDasharray1()}
              transform={`rotate(-90, ${cx}, ${cy})`}
            />
          ) : null}
        </svg>
        {/* <!-- 中间描述文字 --> */}
        <div className="progress-desc">
          <Slot>{childrenSlot}</Slot>
        </div>
        {/* 示例： */}
        {/*<div class="progress">
        <div class="svg-module">
        <div class="module-title">条纹进度条一</div>
        <div class="module-content">
            <svg-line
            :rate="0.3456"
            bg-ring-color="#25b489"
            ring-color="#ec6841"
            :stroke-width="20"
            stroke-linecap="butt"
            :part="20"
            :part-gap="12"
            >
            </svg-line>
        </div>
        </div>
        <div class="svg-module">
        <div class="module-title">条纹进度条二</div>
        <div class="module-content">
            <svg-line
            :rate="0.8825"
            bg-ring-color="#446224"
            ring-color="#a3fe49"
            :stroke-width="20"
            stroke-linecap="butt"
            :part="20"
            :part-gap="12"
            >
            <span>
                <span>{{ `${(0.8825 * 100).toFixed(2)}%` }}</span>
            </span>
            </svg-line>
        </div>
        </div>

        <div class="svg-module">
        <div class="module-title">条纹进度条三</div>
        <div class="module-content">
            <svg-line
            :rate="0.8888"
            bg-ring-color="#13387e"
            ring-color="#1cbd9c"
            :stroke-width="30"
            stroke-linecap="butt"
            :part="40"
            :part-gap="8"
            :show-gradient="true"
            :gradient="myGradient"
            >
            <span>
                <span>渐变进度条</span><br />
                <span>{{ `${(0.8888 * 100).toFixed(2)}%` }}</span>
            </span>
            </svg-line>
        </div>
        </div>

        <div class="svg-module">
        <div class="module-title">条纹进度条四</div>
        <div class="module-content">
            <svg-line
            :rate="0.5035"
            bg-ring-color="#13387e"
            ring-color="#00bbff"
            stroke-linecap="butt"
            :stroke-width="20"
            :part="50"
            :part-gap="20"
            >
            <span>
                <span>{{ `${(0.5035 * 100).toFixed(2)}%` }}</span>
                <br />
                <span>完成率</span>
            </span>
            </svg-line>
        </div>
        </div>

        <div class="svg-module">
        <div class="module-title">条纹进度条五</div>
        <div class="module-content">
            <svg-line
            :rate="0.7667"
            bg-ring-color="#13387e"
            ring-color="orange"
            :stroke-width="26"
            stroke-linecap="butt"
            :part="1"
            :part-gap="0"
            >
            <span>
                <span>普通进度条</span><br />
                <span>{{ `${(0.7667 * 100).toFixed(2)}%` }}</span>
            </span>
            </svg-line>
        </div>
        </div>

        <div class="svg-module">
        <div class="module-title">条纹进度条六</div>
        <div class="module-content">
            <svg-line
            :rate="0.7685"
            bg-ring-color="#13387e"
            ring-color="#00bbff"
            :stroke-width="20"
            :part="1"
            :part-gap="0"
            >
            <span>
                <span>带弧度进度条</span><br />
                <span>{{ `${(0.7685 * 100).toFixed(2)}%` }}</span>
            </span>
            </svg-line>
        </div>
        </div>

        <div class="svg-module">
        <div class="module-title">条纹进度条七</div>
        <div class="module-content">
            <svg-line
            :rate="0.3333"
            bg-ring-color="#13387e"
            ring-color="#00bbff"
            :stroke-width="20"
            :part="1"
            :part-gap="0"
            :show-gradient="true"
            >
            <span>
                <span>渐变进度条</span><br />
                <span>{{ `${(0.3333 * 100).toFixed(2)}%` }}</span>
            </span>
            </svg-line>
        </div>
        </div>

        <div class="svg-module">
        <div class="module-title">条纹进度条八</div>
        <div class="module-content">
            <svg-line
            :rate="0.5632"
            bg-ring-color="#ee6666"
            ring-color="#fac858"
            :stroke-width="200"
            stroke-linecap="butt"
            :part="1"
            :part-gap="0"
            >
            </svg-line>
        </div>
        </div>
    </div> */}
      </div>
    );
  }
}

export default Progress;
