import React, { Component } from 'react'
import {
  Layout,
  Grid,
  Typography,
  Button,
  Space,
  Empty,
  Input,
  Divider,
} from '@arco-design/web-react'
import Emptydata_img from 'asserts/Emptydata_img.svg'
import Generating_img from 'asserts/Generating_img.gif'
import errorIcon from 'asserts/error_icon.svg'
import { shareAppApi } from 'api/apis'
import { debounce } from 'common/utils'
import { sourceCancelClass } from 'api/cancel-request'
import $ from 'jquery'
import '../../common/style/myapp/entityExtraction.scss'
const Sider = Layout.Sider
const Content = Layout.Content
const TextArea = Input.TextArea

// 封装组件
class WordErrorReset extends Component {
  constructor(props) {
    super(props)
    this.state = {
      share_id: localStorage.getItem('share_ids'),
      optionVlue: '',
      TextAreaValue: '',
      ParagraphContinuation: {},
      ContinuationLoding: false,
      record: JSON.parse(localStorage.getItem('shareRecord')),
      number: 6,
      requestMsg: '',
      requestMsgText: '您还未输入文段',
      Loding: false,
      input_describe: '请输入文段，开始纠错',
      settimer1: '',
      settimer2: '',
      visibleUpgrade: false,
      upgradetips: null,
    }
    this.isPhoneLegal = debounce(this.isPhoneLegal, 1000)
    this.isputapp = debounce(this.isputapp, 1000)
  }
  componentDidMount() {
    this.getApiInfo()
    window.addEventListener('resize', this.handleResize)
  }
  componentWillUnmount() {
    localStorage.removeItem('props')
    window.removeEventListener('resize', this.handleResize)
    clearTimeout(this.state.settimer2)
    clearTimeout(this.state.settimer1)
    sourceCancelClass()
  }

  handleResize = () => {
    if (document.getElementById('resultok')) {
      $('#resultok').css({
        height: $('#shareContent').height() - 120,
        overflow: 'auto',
      })
    }
  }
  getApiInfo() {
    let list = this.state.record
    if (list?.type === 0) {
      this.setState({
        ParagraphContinuation: {},
        ContinuationLoding: true,
        requestMsg: '',
      })
      clearTimeout(this.state.settimer2)
      clearTimeout(this.state.settimer1)
      sourceCancelClass()
      // this.isPhoneLegal();
    }
  }
  onsetPosition(value) {
    //console.log(value, "value");
    clearTimeout(this.state.settimer2)
    clearTimeout(this.state.settimer1)
    sourceCancelClass()
    this.setState({
      optionVlue: value,
    })
  }
  onTextArea(value) {
    this.setState({
      TextAreaValue: value,
    })
    if (value.length === 0) {
      this.setState({
        ParagraphContinuation: {},
        ContinuationLoding: false,
        requestMsg: '',
        requestMsgText: '您还未输入文段',
      })
    } else if (value.length > 256) {
      this.setState({
        ParagraphContinuation: {},
        ContinuationLoding: false,
        requestMsg: '',
        requestMsgText: '超出字符限制，请删减内容',
      })
    } else {
      this.setState({
        ParagraphContinuation: {},
        ContinuationLoding: false,
        requestMsg: '',
        requestMsgText: '可点击生成按钮生成结果',
      })
    }
    // this.isPhoneLegal()
    //console.log(value);
  }
  isPhoneLegal = () => {
    this.setState({
      ParagraphContinuation: {},
      ContinuationLoding: true,
      requestMsg: '',
    })
    clearTimeout(this.state.settimer2)
    clearTimeout(this.state.settimer1)
    sourceCancelClass()
    this.state.settimer2 = setTimeout(() => {
      this.setRequest()
    }, 1000)
  }
  ChangeAbatch() {
    clearTimeout(this.state.settimer2)
    clearTimeout(this.state.settimer1)
    sourceCancelClass()
    this.setState({
      ParagraphContinuation: {},
      ContinuationLoding: true,
      requestMsg: '',
    })
    this.state.settimer1 = setTimeout(() => {
      this.setRequest()
    }, 1000)
  }
  onKeyDown(event) {
    //console.log(event.keyCode, "键盘事件");
    if (event.shiftKey && event.keyCode === 13) {
      var e = this.state.TextAreaValue
      this.setState({
        TextAreaValue: e,
      })
    } else if (event.ctrlKey && event.keyCode === 13) {
      event.cancelBubble = true
      event.preventDefault()
      event.stopPropagation()
      event.returnValue = false
      this.ChangeAbatch()
      return false
    } else if (event.keyCode === 13) {
      event.cancelBubble = true
      event.preventDefault()
      event.stopPropagation()
      this.ChangeAbatch()
      event.returnValue = false
      return false
    }
  }
  setRequest() {
    if (this.state.TextAreaValue.length === 0) {
      this.setState({
        ParagraphContinuation: {},
        ContinuationLoding: false,
        requestMsg: '',
        requestMsgText: '您还未输入文段',
      })
    } else if (this.state.TextAreaValue.length > 256) {
      this.setState({
        ParagraphContinuation: {},
        ContinuationLoding: false,
        requestMsg: '',
        requestMsgText: '超出字符限制，请删减内容',
      })
    } else {
      this.sendRequest()
    }
  }
  sendRequest() {
    let params = {
      app_id: this.state.record.app_id,
      text: this.filterHTMLTag(this.state.TextAreaValue),
      share_id: this.state.share_id,
    }
    if (this.state.optionVlue) {
      params.version_id = this.state.optionVlue
    }
    shareAppApi(params).then((res) => {
      const code = [400, 3003, 204]
      if (res.code === 200) {
        let list = res.data
        this.setState({
          ParagraphContinuation: list,
          ContinuationLoding: false,
          requestMsg: '',
        })
        //console.log(res.data, "结果");
      } else if (code.includes(res.code)) {
        this.setState({
          ContinuationLoding: false,
          requestMsg: '',
          requestMsgText: res.msg,
        })
      } else if (res.code === 204) {
        this.setState({
          ParagraphContinuation: {},
          ContinuationLoding: false,
          requestMsg: 204,
        })
      } else {
        this.setState({
          ContinuationLoding: false,
          requestMsg: 'error',
          requestMsgText: res.msg,
        })
      }
    })
  }

  isEmptyObject = (obj) => {
    for (var n in obj) {
      return false
    }
    return true
  }
  setContinuation = () => {
    const { TextAreaValue, ParagraphContinuation } = this.state
    let sqlstring = []

    if (ParagraphContinuation?.data?.length > 0) {
      ParagraphContinuation.data.map((item, index) => {
        sqlstring.push(item)
      })
    }
    let arr = JSON.parse(JSON.stringify(sqlstring))
    // arr.sort((a, b) => a.at(-1).at(-1) - b.at(-1).at(-1));
    // .at()是ES2022的新语法，目前兼容性还不是很好，慎用！
    // .at()支持正索引和负索引
    arr.sort(function (a, b) {
      // 对sort方法进行重写，本质是冒泡排序
      let a_index = a.ids[0]
      let b_index = b.ids[0]
      return a_index - b_index
    })
    // console.log(arr, "排序",TextAreaValue);
    return <div>{this.findHighlight(arr, TextAreaValue)}</div>
  }

  filterHTMLTag = (msg) => {
    var msg = msg.replace(/<\/?[^>]*>/g, '') //去除HTML Tag
    msg = msg.replace(/<([a-zA-Z]+)\s*[^><]*>/g, '<$1>')
    msg = msg.replace(/[|]*\n/, '') //去除行尾空格
    msg = msg.replace(/&npsp;/gi, '') //去掉npsp
    return msg
  }

  findHighlight = (sqlstring, keyWord) => {
    let startindex = 0
    let replaceMap = []
    sqlstring.map((item, key) => {
      let reg
      keyWord = this.filterHTMLTag(this.state.TextAreaValue) //过滤掉所有HTML标签
      let endindex = item.ids.length - 1
      let end = item['ids'][endindex] //结束下标
      let start = item['ids'][0] //开始小标
      let startindexKeyWord = keyWord.substring(startindex, end + 1)
      reg = keyWord.substring(start, end + 1) //根据下标获取内容
      let html = `<span style="color: red">${item.correct}</span>`
      startindexKeyWord = startindexKeyWord.replace(reg, html)
      replaceMap.push(startindexKeyWord)
      startindex = end + 1
      if (sqlstring.length === key + 1) {
        startindexKeyWord = keyWord.substring(startindex)
        replaceMap.push(startindexKeyWord)
      }
      // //console.log(replaceMap, "替换内容", keyWord.length);
    })
    let newHTML = replaceMap.join('')
    let htmls = { __html: newHTML.replaceAll('\n', '<br/>') }
    return (
      <div className="Continuation-item" dangerouslySetInnerHTML={htmls}></div>
    )
  }

  render() {
    const {
      input_describe,
      ParagraphContinuation,
      ContinuationLoding,
      TextAreaValue,
      requestMsg,
      requestMsgText,
    } = this.state

    return (
      <div className="entityExtraction">
        <Layout className="semanticprototype-Layout" id="layout_id">
          <Sider className="Sider-left">
            <div className="Sider-left-configure columnSpaceBetween">
              <div className="Continuation-input">
                <TextArea
                  placeholder={input_describe}
                  style={{ width: '100%' }}
                  maxLength={{ length: 256, errorOnly: true }}
                  showWordLimit
                  value={TextAreaValue}
                  onChange={this.onTextArea.bind(this)}
                  onKeyDown={(event) => this.onKeyDown(event)}
                />
              </div>
              <div className="generation-bottom">
                <Button
                  type="primary"
                  className="Start-generation"
                  onClick={this.isPhoneLegal.bind(this)}
                >
                  开始纠错
                </Button>
              </div>
            </div>
          </Sider>
          <Content>
            <div className="experience-result" id="result">
              <div id="result_title" className="result_title">
                <div className="rowSpaceBetween">
                  <Typography>生成结果</Typography>
                  <Typography.Paragraph className="result-remarks">
                    内容基于韦尼克AI创作引擎生成，仅供参考
                  </Typography.Paragraph>
                </div>
                <Divider />
              </div>
              {!this.isEmptyObject(ParagraphContinuation) ||
              ContinuationLoding ||
              requestMsg === 204 ? (
                <div className="Paragraph-generate">
                  {ContinuationLoding ? (
                    <Empty
                      className="Continuation-loding"
                      icon={<img src={Generating_img} alt="" />}
                      description={
                        <Typography
                          style={{
                            color: '#4e5969',
                            marginTop: 21,
                            fontSize: 16,
                          }}
                        >
                          正在生成中...
                        </Typography>
                      }
                    />
                  ) : (
                    <div
                      className="Continuation-result"
                      id="resultok"
                      style={{ height: $('#shareContent').height() - 120 }}
                    >
                      <div className="Continuation-list" id="content">
                        {requestMsg === 204
                          ? this.state.TextAreaValue
                          : this.setContinuation()}
                      </div>
                    </div>
                  )}
                </div>
              ) : requestMsg === 'error' ? (
                <Empty
                  className="Continuation-Empty Empty-error"
                  imgSrc={errorIcon}
                  description={
                    <Space direction="vertical">
                      <Typography
                        style={{
                          color: '#4e5969',
                          marginTop: 30,
                          fontSize: 16,
                        }}
                      >
                        抱歉，服务器生成错误
                      </Typography>
                      <Typography style={{ color: '#4e5969', fontSize: 16 }}>
                        可尝试
                        <span
                          style={{ color: '#406EFF', cursor: 'pointer' }}
                          onClick={this.ChangeAbatch.bind(this)}
                        >
                          {' '}
                          重新生成
                        </span>
                      </Typography>
                    </Space>
                  }
                />
              ) : (
                <Empty
                  className="Continuation-Empty Empty-none"
                  imgSrc={Emptydata_img}
                  description={
                    <Space direction="vertical">
                      <Typography
                        style={{
                          color: '#4e5969',
                          marginTop: 30,
                          fontSize: 16,
                        }}
                      >
                        暂无生成结果
                      </Typography>
                      <Typography style={{ color: '#4e5969', fontSize: 16 }}>
                        {requestMsgText}
                      </Typography>
                    </Space>
                  }
                />
              )}
            </div>
          </Content>
        </Layout>
      </div>
    )
  }
}
export default WordErrorReset
